import { PayloadAction } from '@reduxjs/toolkit'
import type { State } from './slice'

/**
 * @name cell/updateCurrentEditCellId
 * @param state
 * @param action
 */
export function updateCurrentEditCellId (state: State, action: PayloadAction<string>): void {
  state.currentEditCellId = action.payload
}


/**
 * @ignore
 */
export const reducers = {
  updateCurrentEditCellId
}
