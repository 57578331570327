import React, { useEffect, useState } from 'react'
import { Button } from 'antd'
import pick from 'lodash/pick'
import includes from 'lodash/includes'
import { useDispatch } from 'react-redux'
import Tree from 'src/components/common/Tree'
import useTree from 'src/hooks/common/useTree'
import { useDialogState } from 'src/hooks/common/useDialog'
import { useParameters } from 'src/hooks/common/useParameters'
import * as LayerNodeActions from 'src/state/layerNode/actions'
import { AppDispatch } from 'src/store'
import useSelector from 'src/hooks/common/useSelector'
import { LayerNode } from 'src/typings/mapTableNode'
import { TreeNode } from 'src/typings/tree'
import CreateLayerModal from 'src/components/map/CreateLayerModal/CreateLayerModal'
import { actions as infoWindowActions } from 'src/state/infoWindow/slice'
import { actions as MapActions } from 'src/state/map/slice'
import { actions as ProjectActions } from 'src/state/project/slice'
import { ROLE_ENTITY } from 'src/typings/workspace'
import { ProjectPermission } from 'src/constants/permissions'

import { useWorkspaceRoles } from 'src/hooks/workspace/useWorkspaceRoles'
import styles from './layerManagement.module.css'

interface Props {
  onLayerSettingClick: (layerId: string) => void;
  onLayerDeleted: () => void;
  onLayerSelected: (layerId: string) => void;
  selectedLayerId?: string | null;
}

const LayerManagement: React.FC<Props> = ({
  onLayerSettingClick,
  onLayerDeleted,
  onLayerSelected,
  selectedLayerId
}) => {
  const dispatch: AppDispatch = useDispatch()
  const { projectId } = useParameters()
  const poiDetail = useSelector(state => state.map.poiDetail)
  const [currMenu, setCurrMenu] = useState<TreeNode>()
  const layerData = useSelector(state => {
    return state.project.layerNodes[projectId]
  })
  const createLayerModal = useDialogState(false)
  const {
    treeData,
    updateTreeState,
    setTreeData,
    updateChildrenVisibility,
    updateParentVisibility,
    formatDataToTreeNode,
    getSerializableTreeData
  } = useTree()
  const { checkPermission } = useWorkspaceRoles()

  useEffect(() => {
    if (layerData) {
      setTreeData(formatDataToTreeNode(projectId, layerData))
    }
  }, [formatDataToTreeNode, layerData, setTreeData, projectId])

  useEffect(() => {
    dispatch(ProjectActions.updateZIndexMap({ projectID: projectId, treeData: getSerializableTreeData(treeData) }))
  }, [treeData, dispatch, projectId, getSerializableTreeData])

  const handleMenuClick = async (action: string, menu?: TreeNode) => {
    switch (action) {
    case 'createLayer':
      setCurrMenu(menu)
      handleCreateLayerClick()
      break
    case 'openLayerSetting':
      onLayerSettingClick(menu?.id as string)
      break
    case 'copyLayer':
      // TODO: version 2
      break
    }
  }

  function handleCreateLayerClick () {
    createLayerModal.open()
  }

  const handleToggleVisible = (node: TreeNode) => {
    if (node.children?.length && node.indeterminate && node.visible) {
      node.indeterminate = false
    } else {
      node.visible = !node.visible

      updateTreeState(node)

      updateParentVisibility(node.parentID as string)

      dispatch(MapActions.updateHiddenLayers({
        projectId,
        ids: [node.id as string],
        hidden: !node.visible
      }))
      if (!node.visible) {
        dispatch(infoWindowActions.removeByLayerID({
          ids: !node.children || !node.children.length ? [node.id as string] : node.children.map(n => n.id as string)
        }))
      }
    }

    node.children?.length && updateChildrenVisibility(node, ids => {
      dispatch(MapActions.updateHiddenLayers({
        projectId,
        ids,
        hidden: !node.visible
      }))
    })
  }

  const handleSelect = (node: TreeNode, keys: React.Key[]) => {
    if (node.type === 'map' && node.visible && includes(keys, node.id)) {
      dispatch(MapActions.updateEditor({
        enable: true,
        layerNodeID: node.id.toString()
      }))
    } else {
      dispatch(MapActions.updateEditor({
        enable: false,
        layerNodeID: undefined,
        isOpen: false
      }))
    }

    onLayerSelected(node.id as string)
  }

  function handleLayerAdd (node: TreeNode) {
    dispatch(MapActions.updateEditor({
      enable: true,
      layerNodeID: node.id.toString(),
      isOpen: true
    }))
  }

  function getLayerNodeParams (treeNode: TreeNode) {
    return pick(treeNode, ['id', 'name', 'type', 'taleNodeID', 'projectID'])
  }

  const handleNodeCreate = async (treeNode: TreeNode) => {
    await dispatch(LayerNodeActions.create(getLayerNodeParams(treeNode) as LayerNode))
  }

  const handleNodeUpdate = async (treeNode: TreeNode) => {
    await dispatch(LayerNodeActions.update(getLayerNodeParams(treeNode) as LayerNode))
  }

  const handleNodeDelete = async (treeNode: TreeNode) => {
    treeNode?.id && await dispatch(LayerNodeActions.deleteNode({
      id: treeNode.id as string,
      projectID: projectId
    }))

    dispatch(infoWindowActions.removeByLayerID({
      ids: !treeNode.children || !treeNode.children.length ? [treeNode.id as string] :
        treeNode.children.map(n => n.id as string)
    }))

    if (poiDetail?.visible && poiDetail?.layerNodeId === treeNode.id) {
      dispatch(MapActions.updatePoiDetail({
        visible: false
      }))
    }

    onLayerDeleted()
  }

  const handleNewLayerCreated = (newLayer: LayerNode): void => {
    const fakeId = Date.now()
    updateTreeState({
      ...newLayer,
      id: fakeId,
      title: newLayer.name,
      key: fakeId,
      visible: true,
      editing: false,
      creating: false
    } as TreeNode, true)
  }

  const handleDND = async (fromNode: TreeNode, toNode?: TreeNode, parentNode?: TreeNode) => {
    await dispatch(LayerNodeActions.move({
      id: fromNode.id,
      projectID: projectId,
      parentID: parentNode?.id,
      prevID: toNode?.id
    }))
  }

  return (
    <div className={styles.wrap}>
      <Tree
        title="地图图层"
        type="map"
        data={treeData}
        roleData={{
          id: projectId,
          type: ROLE_ENTITY.project,
          role: ProjectPermission.MapSiderPlusButton
        }}
        onMenuClick={handleMenuClick}
        onSelect={handleSelect}
        onCreate={handleNodeCreate}
        onDND={handleDND}
        onUpdate={handleNodeUpdate}
        onDelete={handleNodeDelete}
        onToggleVisible={handleToggleVisible}
        onLayerAdd={handleLayerAdd}
        empty={
          <div>
            <div className={styles.tip}>未添加地图图层, 点击按钮添加</div>
            <Button
              type="primary" onClick={handleCreateLayerClick}
              disabled={!checkPermission(projectId, ProjectPermission.NewMapLayer, ROLE_ENTITY.project)}>新建图层</Button>
          </div>
        }
        selectedKey={selectedLayerId as string}
      />
      <CreateLayerModal
        dialog={createLayerModal}
        parentID={currMenu?.id}
        onCreated={handleNewLayerCreated}
      />
    </div>
  )
}

export default LayerManagement
