import { createAsyncThunk } from '@reduxjs/toolkit'
import map from 'lodash/map'
import merge from 'lodash/merge'
import forEach from 'lodash/forEach'
import TableNodesAPI, { ImportProps, TableNodeForRequest, TableNodeDuplicateRequestData } from 'src/api/tablenodes'
import UploadAPI, { UploadType } from 'src/api/upload'
import { uploadToCOS } from 'src/utils/upload'
import { TableNode } from 'src/typings/mapTableNode'
import { DND } from 'src/typings/tree'
import { actions as tableNodeActions } from 'src/state/tableNode/slice'
import { TypeOptions, UpdateViewPayload, PasteCellsParams } from 'src/typings/tableNode'
import { getFileExt } from 'src/utils/attachment'
import { createAsyncThunkSyncWithWebSocket, createUndoRedoAction } from 'src/utils/actionCreator'
import type { APIResponse } from 'src/typings/common'
import type { FileParams } from 'src/api/tablenodes'

export const createTableNode = createAsyncThunk(
  'tableNode/create_async',
  async (tableNode: TableNodeForRequest) => {
    return await TableNodesAPI.create(tableNode)
  }
)

export const uploadTableNodeFile = createAsyncThunk(
  'tableNode/uploadTableNodeFile_async',
  async (payload: { file: File }) => {
    const { file } = payload
    const { detail } = await UploadAPI.getInfo('import/table')
    const { url } = await uploadToCOS(file, detail)
    return url
  }
)

export const importTableNode = createAsyncThunk(
  'tableNode/importTableNodeFile_async',
  async (payload: ImportProps) => {
    const { importOption, name, projectID, type, url } = payload
    return await TableNodesAPI.importData({
      importOption,
      name,
      projectID,
      type,
      url
    })
  }
)
export interface UpdateTableNodeNamePayload {
  id: number;
  projectID: number;
  name: string
}

const updateNameActions = createUndoRedoAction<APIResponse, UpdateTableNodeNamePayload>(
  'tableNode/updateName_async',
  async (payload: UpdateTableNodeNamePayload) => {
    const { id, name, projectID } = payload
    return await TableNodesAPI.update(id, { name, projectID })
  })()
export const updateTableNodeName = updateNameActions.action
export const redoUpdateTableNodeName = updateNameActions.redoAction
export const undoUpdateTableNodeName = updateNameActions.undoAction

export const updateTableNode = createAsyncThunk(
  'tableNode/update_async',
  async (payload: { id: number, tableNode: Partial<TableNode> }) => {
    const { id, tableNode } = payload
    return await TableNodesAPI.update(id, tableNode)
  }
)

export interface MoveTableNodeNamePayload {
  id: number;
  moveInfo: DND;
}

const moveTableNodeActions = createUndoRedoAction<APIResponse, MoveTableNodeNamePayload>(
  'tableNode/move_async',
  async (payload: MoveTableNodeNamePayload) => {
    const { id, moveInfo } = payload
    return await TableNodesAPI.move(id, moveInfo)
  })()
export const moveTableNode = moveTableNodeActions.action
export const redoMoveTableNode = moveTableNodeActions.redoAction
export const undoMoveTableNode = moveTableNodeActions.undoAction

export const deleteRecycleTableNode = createAsyncThunk(
  'tableNode/deleteRecycle_async',
  async (id: number) => {
    return await TableNodesAPI.deleteRecycle(id)
  }
)

const recoverTableNodeActionType = 'tableNode/recoverRecycle_async'
function createRecoverTableNodeAction (typePrefix: string) {
  return createAsyncThunk(
    typePrefix,
    async (id: number) => {
      return await TableNodesAPI.recover(id)
    }
  )
}
export const recoverTableNode = createRecoverTableNodeAction(recoverTableNodeActionType)

const deleteTableNodeActions = createUndoRedoAction<APIResponse, Pick<TableNode, 'id'>>(
  'tableNode/deleteNode_async',
  async (payload) => {
    const { id } = payload
    return await TableNodesAPI.deleteNode(id)
  }
)({
  undo: createRecoverTableNodeAction(`undo_${recoverTableNodeActionType}`)
})
export const deleteTableNode = deleteTableNodeActions.action
export const undoDeleteTableNode = deleteTableNodeActions.undoAction
export const redoDeleteTableNode = deleteTableNodeActions.redoAction

export const getTableNodeDetails = createAsyncThunk(
  'tableNode/getDetail_async',
  async (id: number) => {
    return await TableNodesAPI.getDetail(id)
  }
)
export const getAllTableNodes = createAsyncThunk(
  'tableNode/getAllTables',
  async (tableNodeIds: number[]) => {
    return await Promise.all(map(tableNodeIds, id => TableNodesAPI.getDetail(id)))
  }
)

const createRowType = 'tableNode/createRow_async'
function createCreateRowAction (typePrefix: string) {
  return createAsyncThunk(
    typePrefix,
    async (payload: { id: number, prevRowId?: string, cells?: Record<string, unknown> }) => {
      const { id, prevRowId, cells } = payload
      return await TableNodesAPI.postRow(id, prevRowId, cells)
    }
  )
}
export const createRow = createCreateRowAction(createRowType)

export const moveRow = createAsyncThunk(
  'tableNode/moveViewRow_async',
  async (payload: { id: number, viewId: string, rowIds: string[], prevRowId?: string }) => {
    const { id, viewId, rowIds, prevRowId } = payload
    return await TableNodesAPI.moveViewRow(id, viewId, rowIds, prevRowId)
  }
)

export interface DeleteRowsPayload { id: number, rowIDs: string[] }
export const deleteRowsActions = createUndoRedoAction<APIResponse, DeleteRowsPayload>(
  'tableNode/deleteRows_async',
  async (payload) => {
    const { id, rowIDs } = payload
    return await TableNodesAPI.deleteRows(id, rowIDs)
  }
)({
  undo: createCreateRowAction(`undo_${createRowType}`)
})
export const deleteRows = deleteRowsActions.action
export const undoDeleteRows = deleteRowsActions.undoAction
export const redoDeleteRows = deleteRowsActions.redoAction

export const updateViewColumn = createAsyncThunk(
  'tableNode/updateViewColumn_async',
  async (payload: { id: number, viewId: string, columnId: string, form: unknown }) => {
    const { id, viewId, columnId, form } = payload
    return await TableNodesAPI.updateViewColumn(id, viewId, columnId, form)
  }
)

export const unhideViewColumn = createAsyncThunk(
  'tableNode/unhideAllViewColumns_async',
  async (payload: { id: number, viewId: string }) => {
    const { id, viewId } = payload
    return await TableNodesAPI.unhideViewColumn(id, viewId)
  }
)

export const moveViewColumn = createAsyncThunk(
  'tableNode/moveViewColumn_async',
  async (payload: { id: number, viewId: string, columnId: string, prevColumnId?: string }) => {
    const { id, viewId, columnId, prevColumnId } = payload
    return await TableNodesAPI.moveViewColumn(id, viewId, columnId, prevColumnId)
  }
)
export const createColumn = createAsyncThunk(
  'tableNode/createColumn_async',
  async (payload: {
    id: number, name: string, type: string, prevColumnId?: string,
    typeOptions?: Record<string, unknown>
  }) => {
    const { id, name, type, prevColumnId, typeOptions } = payload
    return await TableNodesAPI.createColumn(id, name, type, prevColumnId, typeOptions)
  }
)

export const updateColumn = createAsyncThunk(
  'tableNode/updateColumn_async',
  async (payload: {
    id: number,
    columnId: string,
    name?: string,
    type?: string,
    typeOptions?: Partial<TypeOptions>
  }) => {
    const { id, columnId, name, type, typeOptions } = payload
    return await TableNodesAPI.updateColumn(id, columnId, name, type, typeOptions)
  }
)

export const deleteColumn = createAsyncThunk(
  'tableNode/deleteColumn_async',
  async (payload: { id: number, columnId: string }) => {
    const { id, columnId } = payload
    return await TableNodesAPI.deleteColumn(id, columnId)
  }
)

export interface UpdateCellsPayload {
  id: number;
  rowId: string;
  cells: Record<string, unknown>;
}

export const updateCellsActions = createUndoRedoAction(
  'tableNode/updateCells_async',
  async (payload: UpdateCellsPayload, thunkAPI) => {
    const { id, rowId, cells } = payload
    thunkAPI.dispatch(tableNodeActions.updateCells({
      id,
      rowID: rowId,
      updateCells: cells as Record<string, unknown>
    }))
    return await TableNodesAPI.patchCell(id, rowId, cells)
  })()
export const updateCells = updateCellsActions.action
export const redoUpdateCells = updateCellsActions.redoAction
export const undoUpdateCells = updateCellsActions.undoAction

export const updateView = createAsyncThunk(
  'tableNode/updateView_async',
  async (payload: { id: number, viewId: string, params: UpdateViewPayload['updatedFields'] }) => {
    const { id, viewId, params } = payload
    return await TableNodesAPI.updateView(id, viewId, params)
  }
)

export interface AddAttachmentPayload {
  id: number;
  rowId: string;
  columnId: string;
  file: File;
  type: UploadType
}

export const addAttachment = createAsyncThunkSyncWithWebSocket<
  Omit<FileParams, 'createTime'> & Pick<APIResponse, 'requestID'>, AddAttachmentPayload
>(
  'project/addAttachment_async',
  async (payload) => {
    const { id, rowId, columnId, file, type } = payload
    const { detail } = await UploadAPI.getInfo(type)
    const { url } = await uploadToCOS(file, detail)

    const fileParams = {
      fileID: detail.fileID,
      fileName: file.name,
      fileSize: file.size,
      fileExt: getFileExt(file.name),
      fileType: file.type || 'unknown',
      url
    }

    const res = await TableNodesAPI.addAttachment(id, rowId, columnId, fileParams)

    return {
      ...fileParams,
      requestID: res.requestID
    }
  }
)

export interface DeleteAttachmentPayload {
  id: number;
  rowId: string;
  columnId: string;
  fileId: string;
}

export const deleteAttachment = createAsyncThunkSyncWithWebSocket(
  'project/deleteAttachment_async',
  async (payload: DeleteAttachmentPayload) => {
    const { id, rowId, columnId, fileId } = payload

    const res = await TableNodesAPI.deleteAttachment(id, rowId, columnId, fileId)

    return {
      id,
      rowId,
      columnId,
      fileId,
      requestID: res.requestID
    }
  }
)

export const updateAttachments = createAsyncThunkSyncWithWebSocket(
  'project/updateAttachments_async',
  async (payload: UpdateCellsPayload, thunkAPI) => {
    const { id, rowId, cells } = payload

    thunkAPI.dispatch(tableNodeActions.updateCells({
      id,
      rowID: rowId,
      updateCells: cells as Record<string, unknown>
    }))
    return await TableNodesAPI.patchCell(id, rowId, cells)
  }
)

export interface UpdateAttachmentPayload {
  id: number;
  rowId: string;
  columnId: string;
  fileId: string;
  name: string;
}

export const updateAttachment = createAsyncThunkSyncWithWebSocket(
  'project/updateAttachment_async',
  async (payload: UpdateAttachmentPayload) => {
    const { id, rowId, columnId, fileId, name } = payload

    await TableNodesAPI.updateAttachment(id, rowId, columnId, fileId, name)

    return {
      id,
      rowId,
      columnId,
      fileId,
      name
    }
  }
)

export const pasteCells = createAsyncThunk(
  'tableNode/pasteCells_async',
  async (payload: { id: number, params: PasteCellsParams }) => {
    const { id, params } = payload
    return await TableNodesAPI.pasteCells(id, params)
  }
)

export const deleteCells = createAsyncThunk(
  'tableNode/deleteCells_async',
  async (payload: { id: number, params: Omit<PasteCellsParams, 'source' | 'sourceColumns'> }) => {
    const { id, params } = payload
    const source: PasteCellsParams['source'] = []
    forEach(params.destRowIDs, _ => {
      const values = map(params.destColumnIDs, _ => null)
      source.push(values)
    })
    return await TableNodesAPI.pasteCells(id, merge({}, params, { source, sourceColumns: [] }))
  }
)

export const getMembers = createAsyncThunk(
  'tableNode/getMembers_async',
  async (id: number) => {
    return (await TableNodesAPI.getMembers(id)).detail
  }
)

export const updateMember = createAsyncThunk(
  'tableNode/updateMember_async',
  async (payload: { id: number, members: { id: number, role: string }[] }) => {
    const { id, members } = payload
    return (await TableNodesAPI.updateMember(id, members)).detail
  }
)
export const deleteMember = createAsyncThunk(
  'tableNode/deleteMember_async',
  async (payload: { id: number, memberId: number }) => {
    const { id, memberId } = payload
    return (await TableNodesAPI.deleteMember(id, memberId)).detail
  }
)

export const getRows = createAsyncThunk(
  'tableNode/getRows_async',
  async (payload: {id: number, rowIds: string[]}) => {
    const { id, rowIds } = payload

    return (await TableNodesAPI.getRows(id, rowIds)).detail
  }
)

export const duplicateTableNode = createAsyncThunk(
  'tableNode/duplicate_async',
  async (payload: { data: TableNodeDuplicateRequestData }) => {
    const { data } = payload

    return await TableNodesAPI.copy(data.id, data)
  }
)

export const getSnapshots = createAsyncThunk(
  'tableNode/getSnapshots_async',
  async (payload: { id: number, projectID: number }) => {
    const { id, projectID } = payload

    return (await TableNodesAPI.getSnapshots(id, projectID)).detail
  }
)

export const createSnapshot = createAsyncThunk(
  'tableNode/createSnapshot_async',
  async (payload: { id: number, projectID: number, name: string}) => {
    const { id, projectID, name } = payload

    return (await TableNodesAPI.createSnapshot(id, { projectID, name }))
  }
)

export const restoreSnapshot = createAsyncThunk(
  'tableNode/restoreSnapshot_async',
  async (payload: { id: number, projectID: number, snapshotID: number }) => {
    const { id, projectID, snapshotID } = payload

    return (await TableNodesAPI.restoreSnapshot(id, projectID, snapshotID))
  }
)