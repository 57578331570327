export const DATE_FORMAT = {
  'year/month/day': 'YYYY/MM/DD',
  'month/day/year': 'MM/DD/YYYY',
  'detail': 'YYYY年M月DD日'
}

export const TIME_FORMAT = {
  '12-hour-clock': 'h:mm',
  '24-hour-clock': 'HH:mm',
  'hidden': ''
}

export const API_REQUEST_DATE_TIME_FORMAT = 'YYYY-MM-DDTHH:mm:ssZ'

export const NUMBER_FORMATS = [
  {
    value: 'number',
    label: '数字'
  },
  {
    value: 'percentage',
    label: '百分比（%）'
  },
  {
    value: 'commaNumber',
    label: '千分位（1,000）'
  }
]
export const NUMBER_PRECISIONS = [
  {
    value: 0,
    label: '1'
  },
  {
    value: 1,
    label: '1.0'
  },
  {
    value: 2,
    label: '1.00'
  },
  {
    value: 3,
    label: '1.000'
  },
  {
    value: 4,
    label: '1.0000'
  },
  {
    value: 5,
    label: '1.00000'
  }
]
