import React, { useEffect } from 'react'
import { Button, Switch } from 'antd'
import { List as MovableList } from 'react-movable'
import filter from 'lodash/filter'
import cx from 'clsx'
import { ViewColumn, Column } from 'src/typings/tableNode'
import Icon from 'src/components/common/Icon'
import useFieldList from 'src/hooks/map/useFieldList'
import styles from './fieldSettingModal.module.scss'

interface FieldListProps {
  columns: Column[]
  viewColumns: ViewColumn[]
  visibleIdsSorted?: string[]
  onColumnsUpdate?: (visibleIdsSorted: string[]) => void
  maxVisibleCount?: number
}
export const FieldList: React.FC<FieldListProps> = (
  { columns, viewColumns, visibleIdsSorted, onColumnsUpdate, maxVisibleCount }) => {

  const { 
    movableColumns, 
    handleMove, 
    handleSwitchVisibility 
  } = useFieldList(viewColumns, visibleIdsSorted, maxVisibleCount)
  useEffect(()=> {
    onColumnsUpdate?.(filter(movableColumns, c => !c.isHidden).map(c => c.id))
  }, [movableColumns, onColumnsUpdate])

  return (
    <div className={styles.fieldList}>
      {
        <MovableList
          lockVertically
          values={movableColumns}
          onChange={handleMove}
          renderList={({ children, props }) => <div {...props}>{children}</div>}
          renderItem={({ value, props }) => 
            <div {...props} className={cx(styles.movableItem, value.isHidden && styles.hidden)}>
              <Button
                className={styles.handle}
                type="text"
                size="small"
                data-movable-handle
                icon={<Icon type="movable" className={styles.icon} />}
              />
              <Icon
                type={columns?.find(c => c.id === value.id)?.type || 'singleLineText'}
                className={styles.columnIcon} />
              <div className={styles.columnName}>
                {columns?.find(c => c.id === value.id)?.name}
              </div>
              <Switch
                className={styles.switch}
                size="small" checked={!value.isHidden}
                onChange={(checked) => handleSwitchVisibility(value)} />
              <div className={styles.switchText}>{value.isHidden ? '隐藏' : '展示'}</div>
            </div>}
        />
      }
    </div>
  )
}