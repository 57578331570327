import AppConfig from 'src/config'
import { createInstance } from 'src/utils/fetch'

import { APIResponse } from 'src/typings/common'
import { CollaboratorParams } from 'src/typings/collaborator'

const fetch = createInstance({
  baseURL: `${AppConfig.baseApiURL}/collaborator/`
})

const CollaboratorAPI = {
  async update (projectId: number, selected: CollaboratorParams['selected'], sessionID: string): Promise<APIResponse> {
    const { data } = await fetch.patch(`update/`, {
      projectID: projectId,
      selected,
      sessionID
    })

    return data
  }
}

export default CollaboratorAPI
