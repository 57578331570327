import { createSlice, EntityState } from '@reduxjs/toolkit'
import { RootState } from 'src/store'
import { Workspace, WorkspaceDetail, WorkspaceRole } from 'src/typings/workspace'
import { reducers } from 'src/state/workspace/reducers'
import { actions as UserActions } from 'src/state/user/slice'
import { actions as OrgActions } from 'src/state/org/slice'
import { actions as ProjectActions } from 'src/state/project/slice'
import { actions as TableNodeActions } from 'src/state/tableNode/slice'
import { getWorkspaces, getWorkspaceDetail, getWorkspaceRoles } from './actions'
import workspaceAdapter from './entityAdapter'

export interface State extends EntityState<Workspace> {
  workspace?: WorkspaceDetail
  workspaceRoles?: WorkspaceRole[]
  workspaceRoleChanged?: Partial<Workspace>
}

const initialState = workspaceAdapter.getInitialState({
  workspace: undefined,
  workspaceRoles: undefined,
  workspaceRoleChanged: undefined
} as State)

const generateWorkspaceEntityId = (type: Workspace['type'], id: number) => {
  return `${type}_${id}`
}

const workspaceSlice = createSlice({
  name: 'workspace',
  initialState,
  reducers,
  extraReducers (builder) {
    builder.addCase(TableNodeActions.create, (state, action) => {
      const { wsWorkspace } = action.payload
      if (wsWorkspace && (wsWorkspace.type === state.workspace?.type)) {
        state.workspaceRoleChanged = { ...action.payload.wsWorkspace }
      }
    }).addCase(ProjectActions.create, (state, action) => {
      const { wsWorkspace } = action.payload
      if (wsWorkspace && (wsWorkspace.type === state.workspace?.type)) {
        state.workspaceRoleChanged = { ...action.payload.wsWorkspace }
      }
    }).addCase(OrgActions.create, (state, action) => {
      workspaceAdapter.addOne(state, action.payload)
    }).addCase(OrgActions.update, (state, action) => {
      const id = generateWorkspaceEntityId('org', action.payload.ID)
      const org = state.entities[id]
      if (org) {
        workspaceAdapter.updateOne(state, {
          id,
          changes: action.payload.updatedFields
        })
      }
    }).addCase(UserActions.update, (state, action) => {
      const id = generateWorkspaceEntityId('user', action.payload.userID)
      const user = state.entities[id]
      if (user) {
        workspaceAdapter.updateOne(state, {
          id,
          changes: action.payload.updatedFields
        })
      }
    }).addCase(ProjectActions.delete, (state, action) => {
      const { wsWorkspace, recycleProject } = action.payload
      if (state.workspace &&
        state.workspace.type === wsWorkspace?.type
        && state.workspace.id === wsWorkspace?.id) {
        if (state.workspace?.detail.recycleProjects === null) {
          state.workspace.detail.recycleProjects = [recycleProject]
        } else {
          state.workspace?.detail.recycleProjects?.push(recycleProject)
        }
      }
    }).addCase(ProjectActions.deleteRecycle, (state, action) => {
      Object.assign(state.workspace?.detail, {
        ...state.workspace?.detail,
        recycleProjects: state.workspace?.detail.recycleProjects?.filter(p => p.id !== action.payload.projectID)
      })
    }).addCase(ProjectActions.recover, (state, action) => {
      Object.assign(state.workspace?.detail, {
        ...state.workspace?.detail,
        recycleProjects: state.workspace?.detail.recycleProjects?.filter(p => p.id !== action.payload.project.id)
      })
    })
    builder.addCase(getWorkspaces.fulfilled, (state, { payload }) => {
      workspaceAdapter.setAll(state, payload)
    }).addCase(getWorkspaces.rejected, (state, action) => {
      throw(action.error)
    }).addCase(getWorkspaceDetail.fulfilled, (state, { payload }) => {
      state.workspace = payload
    }).addCase(getWorkspaceDetail.rejected, (state, action) => {
      throw(action.error)
    }).addCase(getWorkspaceRoles.fulfilled, (state, { payload }) => {
      state.workspaceRoles = payload
    }).addCase(getWorkspaceRoles.rejected, (state, action) => {
      throw(action.error)
    })
  }
})

export default workspaceSlice
export const actions = workspaceSlice.actions
export const workspaceSelectors = workspaceAdapter.getSelectors<RootState>(state => state.workspace)
