import React, { useRef, useState } from 'react'
import { ArrowRightOutlined } from '@ant-design/icons'
import { useHistory, Link } from 'react-router-dom'
import range from 'lodash/range'
import map from 'lodash/map'
import keys from 'lodash/keys'
import values from 'lodash/values'
import first from 'lodash/first'
import parseInt from 'lodash/parseInt'
import get from 'lodash/get'
import isNil from 'lodash/isNil'
import trim from 'lodash/trim'
import every from 'lodash/every'
import isEmpty from 'lodash/isEmpty'
import reduce from 'lodash/reduce'
import split from 'lodash/split'
import join from 'lodash/join'
import { Input, Row, Col, Button, Form } from 'antd'
import withLayout from 'src/hoc/withLayout'
import LoginForm from 'src/components/loginAndSignup/LoginForm/LoginForm'
import { validateInviteCode, login } from 'src/state/user/actions'
import useDispatch from 'src/hooks/common/useDispatch'
import LoginAndSignupPage from 'src/components/loginAndSignup/LoginAndSignupPage'
import { useDocumentTitle } from 'src/hooks/common/useDocumentTitle'
import styles from 'src/components/loginAndSignup/LoginAndSignupPage/loginAndSignupPage.module.scss'

const INVITE_CODE_LENGTH = 6

interface SignupFormProps {
  inviteCode: string;
}
const SignupForm: React.FC<SignupFormProps> = ({
  inviteCode
}) => {
  const dispatch = useDispatch()
  const history = useHistory()

  async function handleFormSubmit (values: Record<string, string>) {
    const { phoneNumber, code } = values
    await dispatch(login({
      phoneNumber,
      code,
      registrationCode: inviteCode
    }))

    history.push('/setup')
  }
  return (
    <LoginForm
      onSubmit={handleFormSubmit}
      submitButtonText="完成注册"
    />
  )
}

interface InviteCodeFormProps {
  onNext: (inviteCode: string) => void
}
const InviteCodeForm: React.FC<InviteCodeFormProps> = ({
  onNext
}) => {
  const dispatch = useDispatch()
  const [form] = Form.useForm()
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState<string | null>(null)
  const inputRefs = useRef<Array<Input | null>>([])

  async function handleFormSubmit () {
    setLoading(true)
    const code = join(values(form.getFieldsValue()), '')
    const { payload } = await dispatch(validateInviteCode(code))
    if (get(payload, 'valid')) {
      onNext(code)
    } else {
      setError('邀请码不存在，请输入正确的邀请码')
      setLoading(false)
    }
  }

  function handleFinishFailed () {
    setError('请输入邀请码')
  }

  function handleValueChange (changedValues: Record<string, string>) {
    const inputIndex = first(keys(changedValues))
    const value = first(values(changedValues))
    const nextInputIndex = isNil(inputIndex) ? 0 : parseInt(inputIndex) + 1
    const nextInputRef: Input = get(inputRefs.current, `[${nextInputIndex}]`)
    const fieldsValue = form.getFieldsValue()

    if (nextInputRef && value) {
      nextInputRef.focus()
    }

    if (every(fieldsValue, v => !isEmpty(v))) {
      handleFormSubmit()
    }
  }

  async function handleKeyDown (e: React.KeyboardEvent<HTMLInputElement>, index: number) {
    const inputRef: Input = get(inputRefs.current, `[${index}]`)
    const nextInputRef: Input = get(inputRefs.current, `[${index + 1}]`)
    const prevInputRef: Input = get(inputRefs.current, `[${index - 1}]`)
    const isDelete = e.key === 'Backspace' && inputRef
    const isInput = trim(inputRef.input.value) && nextInputRef
    const isPaste = (e.metaKey || e.ctrlKey) && e.key === 'v'

    if (isDelete) {
      inputRef.setValue('')
      setTimeout(() => {
        prevInputRef && prevInputRef.focus()
      })
    } else if (isInput) {
      nextInputRef.setValue(e.key)
      nextInputRef.focus()
    }
    if (isPaste) {
      const text = await navigator.clipboard.readText()
      const codes = reduce(split(trim(text).substr(0, INVITE_CODE_LENGTH), ''), (acc, code, index) => {
        acc[index] = code

        return acc
      }, {} as Record<string, string>)

      form.setFieldsValue(codes)
    }
  }
  return (
    <Form
      name="inviteCode"
      size="large"
      form={form}
      onFinish={handleFormSubmit}
      onValuesChange={handleValueChange}
      onFinishFailed={handleFinishFailed}
      validateTrigger="onSubmit"
      requiredMark={false}
      className={styles.form}
    >
      <Form.Item>
        <div className={styles.label}>请输入邀请码</div>
      </Form.Item>
      <Row gutter={8} className={styles.inviteCodes}>
        {
          map(range(INVITE_CODE_LENGTH), n => (
            <Col span={4} key={n}>
              <Form.Item
                name={n}
                noStyle
                label="code"
                rules={[
                  {
                    required: true
                  }
                ]}>
                <Input
                  ref={(el) => inputRefs.current[n] = el}
                  className={styles.inviteCodeInput}
                  maxLength={1}
                  autoComplete="off"
                  onKeyDown={(e) => handleKeyDown(e, n)}
                />
              </Form.Item>
            </Col>
          ))
        }
      </Row>
      <div className={styles.error}>{error}</div>
      <Form.Item>
        <Button
          loading={loading}
          type="primary"
          htmlType="submit"
          size="large"
          block
        >
          下一步
        </Button>
      </Form.Item>
    </Form>
  )
}

const Signup: React.FC = () => {
  const [step, setStep] = useState(0)
  const [inviteCode, setInviteCode] = useState<string>('')
  useDocumentTitle('注册')

  return (
    <LoginAndSignupPage>
      <div className={styles.heading}>
        已有账号，<Link to="/login">点击登录 <ArrowRightOutlined /></Link>
      </div>
      <div className={styles.sideContent}>
        <div className={styles.title}>注册账号</div>
        <div className={styles.message}>
          MapTable 正在内测，为保证测试质量，目前采用邀请注册方式。
        </div>
        { step === 0 && <InviteCodeForm
          onNext={(inviteCode) => {
            setStep(1)
            setInviteCode(inviteCode)
          }} />
        }
        { step === 1 && <SignupForm inviteCode={inviteCode} /> }
        <div className={styles.footer}></div>
      </div>
    </LoginAndSignupPage>
  )
}

export default withLayout('full')(Signup)
