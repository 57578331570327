import { useState, useCallback } from 'react'
import get from 'lodash/get'
import map from 'lodash/map'
import forEach from 'lodash/forEach'
import size from 'lodash/size'
import concat from 'lodash/concat'
import useDispatch from 'src/hooks/common/useDispatch'
import * as notificationActions from 'src/state/notification/actions'
import type { Mode, Notification } from 'src/api/notification'

interface UseNotificationReturn {
  notifications: Notification[];
  fetchNotifications: (page: number, mode: Mode) => void;
  isFirstFetching: boolean;
  markAsRead: (id: number) => void;
  markAllAsRead: () => void;
  loadMoreEnable: boolean;
  isFetching: boolean;
}

export function useNotification (): UseNotificationReturn {
  const dispatch = useDispatch()
  const [notifications, setNotifications] = useState<Notification[]>([])
  const [isFirstFetching, setIsFirstFetching] = useState(false)
  const [isFetching, setIsFetching] = useState(false)
  const [loadMoreEnable, setLoadMoreEnable] = useState(false)

  const fetchNotifications = useCallback(async (page: number, mode: Mode) => {
    try {
      if (page === 1) {
        setIsFirstFetching(true)
      }
      setIsFetching(true)

      const { payload } = await dispatch(notificationActions.getNotifications({
        page,
        mode
      }))

      const detail = get(payload, 'detail')
      const perPage = get(payload, 'extra.perPage')

      if (size(detail) === perPage) {
        setLoadMoreEnable(true)
      } else {
        setLoadMoreEnable(false)
      }

      setNotifications(prev => {
        if (page === 1) return detail
        return concat(prev, detail)
      })
    } finally {
      setIsFirstFetching(false)
      setIsFetching(false)
    }
  }, [dispatch])

  async function markAsRead (id: number) {
    await dispatch(notificationActions.markAsRead(id))

    setNotifications(prev => {
      forEach(prev, n => {
        if (n.id === id) {
          n.read = true
          return false
        }
      })

      return [...prev]
    })
  }

  async function markAllAsRead () {
    await dispatch(notificationActions.markAllAsRead())

    setNotifications(prev => {
      return map(prev, n => {
        n.read = true
        return n
      })
    })
  }

  return {
    notifications,
    fetchNotifications,
    isFirstFetching,
    markAsRead,
    markAllAsRead,
    loadMoreEnable,
    isFetching
  }
}
