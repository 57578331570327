import React, { useState, useRef, useEffect, useContext } from 'react'
import { useIntl } from 'react-intl'
import trim from 'lodash/trim'
import map from 'lodash/map'
import debounce from 'lodash/debounce'
import size from 'lodash/size'
import get from 'lodash/get'
import every from 'lodash/every'
import isEmpty from 'lodash/isEmpty'
import cloneDeep from 'lodash/cloneDeep'
import { Popover, Input } from 'antd'
import { getCenter } from 'qumap/packages/core/'
import ActionButton from 'src/components/project/ActionButton'
import Icon, { IconType } from 'src/components/common/Icon'
import { useSearch, SearchResult as SearchResultType } from 'src/hooks/map/useSearch'
import useDispatch from 'src/hooks/common/useDispatch'
import useSelector from 'src/hooks/common/useSelector'
import { OVERLAY_ICON_TYPES_MAPPING } from 'src/constants/overlay'
import { actions as InfoWindowActions } from 'src/state/infoWindow/slice'
import { InfoWindowPOIType } from 'src/typings/map'
import { MapContext } from 'src/context/mapContext'
import { openDetialPanelEmitter } from 'src/utils/emitter'
import styles from './search.module.scss'

const defaultInfoWindowPosition = {
  left: 600,
  top: 200
}

interface SearchInputProps {
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void; 
  onClose: () => void;
  query: string;
}

const SearchInput: React.FC<SearchInputProps> = ({
  onClose,
  onChange,
  query = ''
}) => {
  const inputRef = useRef<Input>(null)
  const intl = useIntl()

  function handleClose () {
    inputRef.current?.setValue('')
    onClose()
  }

  useEffect(() => {
    inputRef.current?.focus()
  }, [])

  return (
    <div className={styles.inputWrap}>
      <Input 
        defaultValue={query}
        ref={inputRef} 
        className={styles.input} 
        placeholder={intl.formatMessage({ id: 'search.map.placeholder' })}
        onChange={debounce(onChange, 600)} 
        maxLength={30}
        autoFocus
        allowClear
      />
      <Icon type="close" className={styles.iconClose} onClick={handleClose} />
    </div>
  ) 
}

interface SearchResultProps {
  results: SearchResultType[];
  hiddenPoiIds: Set<string>;
  onClick: (poi: InfoWindowPOIType) => void;
  query: string;
}
const SearchResult: React.FC<SearchResultProps> = ({
  results,
  query,
  onClick
}) => {
  const resultListRef = useRef<HTMLDivElement>(null)
  const intl = useIntl()
  function handleToggleClick (index: number) {
    const resultItem = resultListRef.current?.querySelectorAll(`.${styles.layerItem}`)[index]

    if (resultItem) {
      resultItem.classList.toggle(styles.collapsed)
    }
  }

  const isResultEmpty = every(results, r => isEmpty(r.result))

  return (
    <div className={styles.result}>
      <div className={styles.resultHeader}>{intl.formatMessage({ id: 'search.result' })}</div>
      <div className={styles.resultList} ref={resultListRef}>
        {
          !isResultEmpty
            ? map(results, (r, index) => {
              return (
                <div className={styles.layerItem} key={r.layerId}>
                  <div className={styles.layerHeader} onClick={() => handleToggleClick(index)}>
                    <div className={styles.layerTitle}>
                      {r.layerName}
                    </div>
                    <div className={styles.count}>
                      {intl.formatMessage({ id: 'search.resultCount' }, { count: size(r.result) })}
                      <Icon type="arrowLeft" className={styles.iconUp} />
                      <Icon type="arrowLeft" className={styles.iconDown} />
                    </div>
                  </div>
                  <div className={styles.layerResult}>
                    {map(r.result, poi => {
                      return (
                        <div 
                          className={styles.poi} 
                          key={poi.id} 
                          onClick={() => onClick(poi as InfoWindowPOIType)}
                        >
                          <div className={styles.poiTitle}>
                            <Icon 
                              type={get(OVERLAY_ICON_TYPES_MAPPING, poi.overlayType ?? '') as IconType} 
                              className={styles.iconOverlay} 
                            />
                            {poi.name}
                          </div>
                        </div>
                      )
                    })}
                  </div>
                </div>
              )
            })
            : <div className={styles.empty}>
              {intl.formatMessage({ id: 'search.notFound' }, { query })}
            </div>
        }
      </div>
    </div>
  )
}

interface SearchProps {
  projectId: number;
}
export const Search: React.FC<SearchProps> = ({
  projectId
}) => {
  const [visible, setVisible] = useState(false)
  const intl = useIntl()
  const queryRef = useRef('')
  const dispatch = useDispatch()
  const { quMap } = useContext(MapContext)
  const [showResultsPanel, setShowResultsPanel] = useState(false)
  const editorStatus = useSelector(state => state.map.editor)
  const { initialSearchIndex, search, results, clear, hiddenPoiIds } = useSearch(projectId)

  function handleClick () {
    const toggle = !visible
    if (toggle) {
      initialSearchIndex()
    }
    setVisible(toggle)
  }

  function handleClose () {
    setVisible(false)
    setShowResultsPanel(false)
    queryRef.current = ''
    clear()
  }

  function handleChange (e: React.ChangeEvent<HTMLInputElement>) {
    const query = trim(e.target.value)

    if (query.length >= 2) {
      setShowResultsPanel(true)
      search(query)
    } else {
      setShowResultsPanel(false)
      clear()
    }

    queryRef.current = query
  }

  function handleResultClick (poi: InfoWindowPOIType) {
    if (editorStatus.isOpen) {
      return
    }
    setVisible(false)

    const center = getCenter(cloneDeep(poi.coordinates), poi.overlayType as string)

    quMap?.amap.setZoomAndCenter(16, center as [number, number], true)
    
    dispatch(InfoWindowActions.add({
      ...poi,
      ...defaultInfoWindowPosition,
      center
    } as InfoWindowPOIType))

    setTimeout(() => {
      openDetialPanelEmitter.emit(poi.id)
    })
  }

  return (
    <Popover
      placement="bottomLeft"
      content={
        <div className={styles.wrap}>
          <SearchInput 
            query={queryRef.current}
            onClose={handleClose}
            onChange={handleChange}
          />
          {
            showResultsPanel && <SearchResult 
              results={results} 
              hiddenPoiIds={hiddenPoiIds} 
              query={queryRef.current}
              onClick={handleResultClick} 
            />
          }
        </div>
      }
      trigger="click"
      visible={visible}
      destroyTooltipOnHide
    >
      
      <ActionButton
        icontype="search"
        onClick={handleClick}
      >
        {intl.formatMessage({ id: 'search.title' })}
      </ActionButton>
    </Popover>
  )
}

export default Search