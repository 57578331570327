import { useEffect, useRef, useState } from 'react'
import { message } from 'antd'
import isNil from 'lodash/isNil'
import isEqual from 'lodash/isEqual'
import { useHistory } from 'react-router-dom'
import { createOrganizationWorkspaceWS, createUserWorkspaceWS } from 'src/ws'
import { useWorkspaces } from 'src/hooks/workspace/useWorkspaces'
import { useParameters } from 'src/hooks/common/useParameters'
import useSelector from 'src/hooks/common/useSelector'
import useDispatch from 'src/hooks/common/useDispatch'
import { actions as appActions } from 'src/state/app/slice'
import { actions as workspaceActions } from 'src/state/workspace/slice'
import { WSReadyState } from 'src/state/app/reducers'
import useRouter from 'src/hooks/common/useRouter'
import { getWorkspaceRoles as fetchWorkspaceRoles } from 'src/state/workspace/actions'
import { Workspace } from 'src/typings/workspace'


function isWorkspaceReady (
  workspaceType: string, 
  userReadyState: WSReadyState | null, 
  organizationReadyState: WSReadyState | null
) {
  const isOrgWorkspace = workspaceType === 'w'

  if (isOrgWorkspace) {
    return userReadyState === WSReadyState.OPEN && organizationReadyState === WSReadyState.OPEN
  } else {
    return userReadyState === WSReadyState.OPEN
  }
}

interface UseReadyStateReturn {
  appInitialized: boolean;
  isReady: boolean;
}
export function useReadyState (): UseReadyStateReturn {
  const dispatch = useDispatch()
  const { workspaceId, workspaceType } = useParameters()
  const userReadyState = useSelector(state => state.app.userReadyState)
  const organizationReadyState = useSelector(state => state.app.organizationReadyState)
  const appInitialized = useSelector(state => state.app.initialized)
  const { workspace } = useWorkspaces(workspaceType, workspaceId)
  const isReady = !isNil(workspace) && isWorkspaceReady(workspaceType, userReadyState, organizationReadyState)

  useEffect(() => {
    if (!appInitialized && isReady) {
      dispatch(appActions.appInitialized(isReady))
    }
  }, [dispatch, appInitialized, isReady])

  return {
    appInitialized,
    isReady
  }
}

export function useNoAuthEffect (): void {
  const { logout } = useRouter()
  const history = useHistory()
  const { workspaceType } = useParameters()
  const userReadyState = useSelector(state => state.app.userReadyState)
  const organizationReadyState = useSelector(state => state.app.organizationReadyState)

  useEffect(() => {
    if (workspaceType === 'w' && organizationReadyState === WSReadyState.NO_AUTH) {
      message.error('没有权限访问该工作区')
      history.push('/')
    }
    if (workspaceType === 'uw' && userReadyState === WSReadyState.NO_AUTH) {
      logout()
    }
  }, [history, logout, organizationReadyState, userReadyState, workspaceType])
}

export function useWorkspaceWebSocketEffect (): void {
  const { workspaceId, workspaceType } = useParameters()
  const { workspace, userWorkspaceId } = useWorkspaces(workspaceType, workspaceId)
  const orgWSClose = useRef<() => void>()

  useEffect(() => {
    if (!isNil(userWorkspaceId)) {
      createUserWorkspaceWS({
        workspace: {
          type: 'user',
          id: userWorkspaceId
        }
      })
    }
  }, [userWorkspaceId])

  useEffect(() => {
    if (workspaceType === 'uw') {
      orgWSClose.current?.()
    } else {
      if (workspace?.type === 'org') {
        const { close } = createOrganizationWorkspaceWS({
          workspace: {
            type: workspace?.type,
            id: workspace?.id
          } 
        })
        orgWSClose.current = close
      }
    }
  }, [workspace?.type, workspace?.id, workspaceType])
}

export function useWorkspaceRolesEffect (): void {
  const dispatch = useDispatch()
  const { workspaceId, workspaceType } = useParameters()
  const { workspace } = useWorkspaces(workspaceType, workspaceId)
  const [currentWorkspace, setCurrentWorkspace] = useState<Workspace | undefined>()
  const roleChanged = useSelector(state => state.workspace.workspaceRoleChanged)

  useEffect(() => {
    if (workspace && !isEqual(workspace, currentWorkspace)) {
      dispatch(fetchWorkspaceRoles(workspace as Workspace))
      setCurrentWorkspace(workspace)
    }
  }, [dispatch, workspace, currentWorkspace])

  useEffect(() => {
    const updateRoles = async () => {
      if (roleChanged) {
        await dispatch(fetchWorkspaceRoles(roleChanged as Workspace))
        dispatch(workspaceActions.clearRoleChange())
      }
    }
    updateRoles()
  }, [dispatch, roleChanged])
}