import React, { useImperativeHandle } from 'react'
import dayjs from 'dayjs'
import map from 'lodash/map'
import { Form, Select } from 'antd'
import type { FormInstance } from 'antd/lib/form/hooks/useForm'
import { DATE_FORMAT } from 'src/constants/field'

interface Props {
  form?: React.Ref<FormInstance>;
  config?: Record<string, unknown>;
}

function getDateOptions () {
  return [
    {
      value: 'year/month/day',
      label: `年/月/日（${dayjs().format(DATE_FORMAT['year/month/day'])}）`
    },
    {
      value: 'month/day/year',
      label: `月/日/年（${dayjs().format(DATE_FORMAT['month/day/year'])})`
    },
    {
      value: 'detail',
      label: `详细格式（${dayjs().format(DATE_FORMAT.detail)}）`
    }
  ]
}
const timeOptions = [
  {
    value: '12-hour-clock',
    label: '12小时制'
  },
  {
    value: '24-hour-clock',
    label: '24小时制'
  },
  {
    value: 'hidden',
    label: '不显示'
  }
]

export const DatetimeConfig: React.FC<Props> = ({
  form,
  config
}) => {
  const [innerForm] = Form.useForm()
  const initialValues = {
    dateFormat: 'year/month/day',
    timeFormat: '12-hour-clock',
    ...config
  }

  useImperativeHandle(form, () => innerForm)

  return (
    <Form
      name="datetime"
      form={innerForm}
      labelAlign="left"
      labelCol={{ span: 7 }}
      wrapperCol={{ span: 17 }}
      initialValues={initialValues}
    >
      <Form.Item
        label="格式"
        name="dateFormat"
      >
        <Select>
          {
            map(getDateOptions(), o => (
              <Select.Option key={o.value} value={o.value}>{o.label}</Select.Option>
            ))
          }
        </Select>
      </Form.Item>
      <Form.Item
        label="显示时间"
        name="timeFormat"
      >
        <Select>
          {
            map(timeOptions, o => (
              <Select.Option key={o.value} value={o.value}>{o.label}</Select.Option>
            ))
          }
        </Select>
      </Form.Item>
    </Form>
  )
}
