import find from 'lodash/find'
import get from 'lodash/get'
import forEach from 'lodash/forEach'
import isEmpty from 'lodash/isEmpty'
import type { ICellRendererParams } from 'ag-grid-community'
import type { ComponentSelectorResult } from 'ag-grid-community/dist/lib/components/framework/userComponentFactory'
import { tableNodeSelectors } from 'src/state/tableNode/slice'
import { selectCellCollaborators } from 'src/selectors/cellCollaboratorsSelector'


const CELL_RENDERER_TYPE_MAPPING = {
  singleLineText: 'singleLineTextCellRenderer',
  singleChoice: 'selectCellRenderer',
  multiChoice: 'selectCellRenderer',
  number: 'numberCellRenderer',
  datetime: 'datetimeCellRenderer',
  coordinate: 'coordinateCellRenderer',
  formula: 'formulaCellRenderer',
  multiLineText: 'multiLineTextCellRenderer',
  attachment: 'attachmentCellRenderer',
  table: 'tableCellRenderer',
  boolean: 'checkboxCellRenderer'
}

export function cellRendererSelector (params: ICellRendererParams): ComponentSelectorResult {
  const { context, column, colDef } = params
  const colId = column.getColId()
  const rowId = params?.data.id
  const state = context.store.getState()
  const tableNode = tableNodeSelectors.selectById(state, context.tableNodeId)
  const realValue = params.getValue().realValue
  const tableNodeColumn = find(tableNode?.columns, c => c.id === colId)
  const cellId = `${colId},${rowId}`
  const collaboratorName: string[] = []
  let collaboratorColor = 'transparent'
  const collaborators = selectCellCollaborators(state, { cellId })
  forEach(collaborators, collaborator => {
    collaboratorName.push(collaborator.name)
    collaboratorColor = collaborator.color
  })

  const cellRendererParams = {
    realValue,
    editable: colDef.editable,
    typeOptions: tableNodeColumn?.typeOptions,
    type: tableNodeColumn?.type,
    name: tableNodeColumn?.name,
    inGrid: true,
    tableNodeId: context.tableNodeId,
    viewId: context.viewId,
    collaborator: isEmpty(collaboratorName) ? undefined : {
      name: collaboratorName,
      color: collaboratorColor
    }
  }

  const type = tableNodeColumn?.type

  const cellComponent = get(CELL_RENDERER_TYPE_MAPPING, type ?? '')

  return {
    component: cellComponent,
    params: cellRendererParams
  }
}

export function tableGridCellRendererSelector (params: ICellRendererParams): ComponentSelectorResult {
  const { column } = params
  let realValue = params.getValue().realValue
  const { headerComponentParams } = column.getColDef()

  const type = headerComponentParams?.type
  
  if (type === 'number') {
    realValue = realValue ? parseFloat(realValue) : realValue
  }

  const cellRendererParams = {
    realValue,
    type,
    typeOptions: headerComponentParams.typeOptions,
    name: headerComponentParams.name,
    inGrid: true
  }


  const cellComponent = get(CELL_RENDERER_TYPE_MAPPING, type ?? '')

  return {
    component: cellComponent,
    params: cellRendererParams
  }
}