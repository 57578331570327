import React, { useState, useEffect } from 'react'
import { Button, Select, Tag, Layout, Table, Radio, Tooltip } from 'antd'
import cx from 'clsx'
import concat from 'lodash/concat'
import find from 'lodash/find'
import filter from 'lodash/filter'
import map from 'lodash/map'
import uniq from 'lodash/uniq'
import { validatePhoneNumber } from 'src/utils/validators'
import Avatar from 'src/components/common/Avatar'
import { default as PageContainer, PageContainerTabs } from 'src/components/common/PageContainer'
import TableSider from 'src/components/project/Sider'
import ProjectActions from 'src/components/project/ProjectActions'
import { useProjectMember, Member } from 'src/hooks/project/useProjectMember'
import { useMatchUser, UserInfo } from 'src/hooks/project/useMatchUser'
import Icon from 'src/components/common/Icon'
import DebounceSelect from 'src/components/common/DebounceSelect'
import { CustomTagProps } from 'src/components/field/Select/SelectEditor'
import { RoleType, ROLE_NAME, ProjectPermission, TableNodePermisson } from 'src/constants/permissions'
import InvitationsTable from 'src/components/common/Invitations'
import { ProjectPermissionWrapper } from 'src/components/common/PermissionWrapper'
import { useWorkspaceRoles } from 'src/hooks/workspace/useWorkspaceRoles'
import { useDocumentTitle } from 'src/hooks/common/useDocumentTitle'
import { useConfirmDialog } from 'src/hooks/common/useDialog'
import styles from './projectMember.module.scss'

const { Option } = Select
const { Column } = Table

const ProjectMember: React.FC = () => {
  const [selectRole, setSelectRole] = useState<RoleType>('admin')
  const [currentTab, setCurrentTab] = useState('invite')

  const {
    isOrgWorkspace,
    project,
    currentUser,
    members,
    roleInherited,
    isSameUser,
    fetchMembers,
    removeMember,
    changeMemberRole,
    inviteMembersInUserWorkspace,
    changeRoleInherited
  } = useProjectMember()

  useDocumentTitle('协作成员', project?.name)
  const confirmDialog = useConfirmDialog()

  const { checkProjectPermission } = useWorkspaceRoles()
  const {
    getMatchUser,
    getMatchUsersInOrg,
    matchedUsers,
    setMatchedUsers,
    selectedUsers,
    setSelectedUsers
  } = useMatchUser()
  useEffect(() => {
    fetchMembers()
  }, [fetchMembers])

  const validateSearch = (q: string) => q.length >= 2

  function handleSelectUser (v: number[]) {
    setSelectedUsers(v)
  }

  function handleRemoveTag (id: number) {
    setSelectedUsers(filter(selectedUsers, v => v !== id))
  }

  function handleInviteNewUser (phone: string) {
    if (!validatePhoneNumber(phone)) {
      return
    }
    const match = find(matchedUsers, u => u.phone === phone)
    if (match) {
      setSelectedUsers(uniq([...selectedUsers, match.id]))
    } else {
      const id = -performance.now()
      setMatchedUsers(concat(matchedUsers, {
        id,
        phone,
        notRegistered: true
      }))

      setSelectedUsers(concat(selectedUsers, id))
    }
  }

  function handlePermissionTypeChange (roleInherited: boolean) {
    confirmDialog({
      title: '修改项目协作权限',
      className: cx(styles.removeConfirm, styles.warning),
      okType: 'danger',
      okText: '修改权限',
      content: roleInherited ?
        <div className={styles.confirmContent}>确定将项目权限修改为 <span className={styles.dialogHighlight}>继承团队权限</span>？
          <div>修改后将按照团队设置中的成员权限决定各成员对该项目的访问权限。</div>
        </div>
        : <div className={styles.confirmContent}> 确定将项目权限修改为 <span className={styles.dialogHighlight}>指定成员可见</span>？
          <div>修改后需要为该项目设置单独的访问权限，可指定项目对特定成员可见。</div>
        </div>
    }).then(() => {
      changeRoleInherited(roleInherited)
    })
  }

  async function handleAddButtonClick () {
    if (isOrgWorkspace) {
      await changeMemberRole(selectedUsers.map(id => ({ id, role: selectRole } as Member)))
    } else {
      const members = selectedUsers.map(v => {
        return {
          phone: find(matchedUsers, u => u.id === v)?.phone as string,
          role: selectRole
        }
      })
      await inviteMembersInUserWorkspace(members)
    }
    setSelectedUsers([])
    setMatchedUsers([])
  }

  async function handleRemoveMember (member: Member) {
    const isSameMember = isSameUser(member)

    confirmDialog({
      title: isSameMember ? '退出项目协作' : '移除成员',
      className: styles.removeConfirm,
      okType: 'danger',
      okText: isSameMember ? '退出协作' : '移除',
      content: isSameMember ?
        <div className={styles.confirmContent}>确定退出项目 <span className={styles.dialogHighlight}>{project?.name}</span>？
          <div>退出后将无法访问该项目。</div>
        </div>
        : <div className={styles.confirmContent}> 确定将移除成员 <span className={styles.dialogHighlight}>{member.name}</span>？
          <div>移除后对方将无法访问该项目。</div>
        </div>
    }).then(() => {
      removeMember(member)
    })
  }

  function handleRoleChange (v: string) {
    setSelectRole(v as RoleType)
  }

  function getDisabledPermission (member: Member) {
    const isSameMember = isSameUser(member)
    if (member.role === 'admin' && member.inherited) {
      return {
        change: true,
        remove: true
      }
    }
    if (member.role === 'admin' && isSameMember) {
      return {
        change: true,
        remove: true
      }
    }
    if (currentUser && currentUser.role !== 'admin') {
      return {
        change: true,
        remove: !isSameMember
      }
    }
  }

  const customTagRenderer = (props: CustomTagProps) => {
    const id = props.value as number
    const matchedUser = find(matchedUsers, v => v.id === id)
    return (
      <Tag
        className={styles.tag}
        color="#EBECF0"
        closable={true}
        onClose={() => handleRemoveTag(id)}
      >
        <div className={styles.searchResult}>
          {matchedUser?.notRegistered ?
            <div className={styles.name}>
              {matchedUser?.phone}
            </div>
            : <>
              <Avatar src={matchedUser?.avatar} size={16} />
              <div className={styles.name}>
                {matchedUser?.name}
              </div>
            </>
          }
        </div>
      </Tag>
    )
  }

  const customOptionRenderer = (options: UserInfo[]) => {
    return options.map(item => (
      <Select.Option key={item.id} value={item.id}>
        <div className={styles.searchResult}>
          <Avatar src={item?.avatar} />
          <div className={styles.name}>
            {item?.name}
          </div>
          <div className={styles.phone}>
            {item.phone}
          </div>
        </div>
      </Select.Option>
    ))
  }

  const customNotFoundContent = (s: string) => (
    <div className={styles.inviteMember} onClick={() => handleInviteNewUser(s)}>
      该用户未注册，<span className={styles.press}>点击</span>邀请成员加入
    </div>
  )

  const customTitle = (
    <PageContainerTabs
      currentTab={currentTab}
      onTabClick={setCurrentTab}
      tabs={isOrgWorkspace ? [{ title: '协作成员', key: 'invite' }] :
        [
          { title: '协作成员', key: 'invite' },
          ...(
            checkProjectPermission(project?.id, TableNodePermisson.MemeberInvite) ?
              [{ title: '待加入成员', key: 'members' }] : [])
        ]}
    />
  )

  const roleItem = (inherited = false) => {
    if (inherited) {
      return <span className={styles.permissionItem}>
              继承团队权限
        <Tooltip title={'按照团队设置中的成员权限决定各成员对该项目的访问权限'}>
          <Icon type="help" className={styles.help} />
        </Tooltip>
      </span>
    } else {
      return <span className={styles.permissionItem}>指定成员可见
        <Tooltip title={'将为该项目设置单独的访问权限，可指定项目对特定成员可见'}>
          <Icon type="help" className={styles.help} />
        </Tooltip>
      </span>
    }
  }

  return (
    <>
      <ProjectActions />
      <Layout className={styles.layout}>
        <TableSider project={project} activeMenuItems={['member']} />
        <PageContainer title={customTitle} className={styles.container}>
          {currentTab === 'invite' && <>
            {
              isOrgWorkspace &&
              <>
                <div className={styles.header}>
                  <span className={styles.projectPermission}>项目权限</span>
                  <span className={styles.projectPermissionType}>
                    {currentUser?.role === 'admin' ? <Radio.Group
                      value={roleInherited}
                      onChange={e => handlePermissionTypeChange(e.target.value)}>
                      <Radio value={false}>{roleItem(false)}</Radio>
                      <Radio value={true}>{roleItem(true)}</Radio>
                    </Radio.Group> : roleItem(roleInherited)}
                  </span>
                </div>
                <div className={styles.divider} />
              </>
            }
            <div className={styles.membersHeader}>成员列表({members.length})</div>
            {
              !roleInherited && <ProjectPermissionWrapper
                id={project?.id}
                role={ProjectPermission.CollaboratorInvite}
              >
                <div className={styles.search}>
                  {
                    isOrgWorkspace ?
                      <DebounceSelect
                        className={styles.select} mode="multiple" value={selectedUsers}
                        validator={validateSearch}
                        fetchOptions={getMatchUsersInOrg}
                        onChange={handleSelectUser}
                        customOptionRenderer={customOptionRenderer}
                        tagRender={customTagRenderer}
                        searchNotFoundContent={() => '未找到团队成员，请确认用户已注册并加入团队'}
                        placeholder="请输入团队成员姓名或注册手机号码"
                      />
                      :
                      <DebounceSelect
                        onPressEnter={handleInviteNewUser}
                        className={styles.select} mode="multiple" value={selectedUsers}
                        validator={validatePhoneNumber}
                        fetchOptions={getMatchUser}
                        onChange={handleSelectUser}
                        customOptionRenderer={customOptionRenderer}
                        tagRender={customTagRenderer}
                        searchNotFoundContent={customNotFoundContent}
                        placeholder="请输入对方 MapTable 注册手机号码"
                      />
                  }
                  <Select onChange={handleRoleChange} value={selectRole} className={styles.roleSelect}>
                    {
                      map(ROLE_NAME, (val, key) => <Option key={key} value={key}>{val}</Option>)
                    }
                  </Select>
                  <Button
                    type="primary" className={styles.addButton}
                    onClick={handleAddButtonClick} disabled={selectedUsers.length < 1}>添加</Button>
                </div>
              </ProjectPermissionWrapper>
            }
            <Table
              className={styles.table}
              scroll={{ y: 'calc(100vh - 400px)' }}
              size="small"
              rowKey="id" pagination={false}
              dataSource={members}
            >
              <Column
                width={45}
                title="成员"
                dataIndex="avatar"
                render={(v: string) => (<Avatar src={v} />)} />
              <Column
                dataIndex="name"
                render={(name: string, v: Member) => (
                  isOrgWorkspace && !roleInherited ?
                    <div>{name}
                      {v.inherited && v.role === 'admin' &&
                      <span className={styles.teamAdminLabel}>团队管理员</span>
                      }
                    </div> : name
                )}
              />
              <Column title="手机号" dataIndex="phone" width={100} />
              <Column
                width={120}
                title="权限"
                dataIndex="role"
                align="center"
                render={(role: RoleType, v: Member) => (
                  (roleInherited ||
                    (project && !checkProjectPermission(project?.id, ProjectPermission.CollaboratorEdit))) ?
                    ROLE_NAME[role] :
                    <Select
                      value={role}
                      className={styles.roleSelect}
                      size="small"
                      onChange={(value) => changeMemberRole([{ ...v, role: value }])}
                      disabled={
                        getDisabledPermission(v)?.change
                      }
                    >
                      {
                        map(ROLE_NAME, (val, key) => <Option key={key} value={key}>{val}</Option>)
                      }
                    </Select>
                )} />
              {
                !roleInherited &&
                <Column
                  width={45}
                  align="center"
                  title="操作"
                  dataIndex="id"
                  render={(id: number, v: Member) => {
                    if (!getDisabledPermission(v)?.remove) {
                      return <Tooltip title={isSameUser(v) ? '退出协作' : '移除成员'}>
                        <Icon type="close" className={styles.close} onClick={() => handleRemoveMember(v)} />
                      </Tooltip>
                    } else {
                      return <Tooltip title={isSameUser(v) ? '项目管理员不可退出协作' : '项目管理员不可移除'}>
                        {currentUser?.role === 'admin' &&
                        <Icon type="close" className={cx(styles.close, styles.disabled)} />}
                      </Tooltip>
                    }
                  }} />
              }
            </Table>
          </>}
          {currentTab === 'members' && <InvitationsTable type={'project'} />}
        </PageContainer>
      </Layout>
    </>
  )
}

export default ProjectMember
