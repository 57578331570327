import React from 'react'
import { UserOutlined } from '@ant-design/icons'
import { Avatar as AntdAvatar } from 'antd'
import cx from 'clsx'
import { getThumbnailUrl } from 'src/utils/image'
import styles from './avatar.module.scss'

interface Props {
  src?: string
  size?: number
  ringWidth?: number
  flagColor?: string
}

const Avatar: React.FC<Props> = ({ src, size = 24, ringWidth = 2, flagColor }) => {
  const ringSize = size + ringWidth * 2
  return (
    <div className={styles.avatar} style={{ width: ringSize, height: ringSize }}>
      {src ? <AntdAvatar
        size={size}
        src={getThumbnailUrl(src, size)}
      /> : <AntdAvatar
        size={size}
        icon={<UserOutlined />}
      />}
      {flagColor && <span
        className={styles.flag}
        style={{
          backgroundColor: flagColor
        }}
      />}
    </div>
  )
}

interface AvatarProps {
  title?: string
  size?: number
  src?: string
  className?: string
}

export const OrgAvatar: React.FC<AvatarProps> = ({ title, size = 32, src, className }) => {
  return (
    <AntdAvatar
      size={size}
      gap={6}
      src={getThumbnailUrl(src, size)}
      className={cx(className, styles.orgAvatar)}
    > {title?.charAt(0)} </AntdAvatar>
  )
}

export default Avatar
