import { useCallback, useEffect, useRef } from 'react'
import { ICellRendererParams, RowNode } from 'ag-grid-community'
import type { CellFocusedEvent } from 'ag-grid-community/dist/lib/events'
import debounce from 'lodash/debounce'
import forEach from 'lodash/forEach'
import values from 'lodash/values'
import curry from 'lodash/curry'
import { CellCollaboratorsType, CollaboratorType } from 'src/typings/collaborator'
import { collaboratorSelectors } from 'src/state/collaborator/slice'
import { useParameters } from 'src/hooks/common/useParameters'
import useSelector from 'src/hooks/common/useSelector'
import * as collaboratorActions from 'src/state/collaborator/actions'
import { AppDispatch } from 'src/store'
import { ADD_COLUMN_ID, INDEX_COLUMN_ID } from 'src/constants/grid'
import { selectCellCollaborators } from 'src/selectors/cellCollaboratorsSelector'
import useDispatch from 'src/hooks/common/useDispatch'
import { WSReadyState } from 'src/state/app/reducers'

export interface CollaboratorReturn {
  collaborators: CollaboratorType[];
  otherCollaborators: CollaboratorType[];
  cellCollaborators: CellCollaboratorsType;
  getCellCollaborators: (props?: ICellRendererParams) => { name: string, color: string };
  updateCollaborator: (columnId?: string, rowId?: string, viewId?: string) => void;
  checkIsCollaboratorRow: (row: RowNode) => boolean;
  onCellFocused: (e: CellFocusedEvent) => void;
}

interface UseCellCollaboratorsReturn {
  name: string[];
  color: string;
}

export function useCellCollaborators (columnId: string, rowId: string): UseCellCollaboratorsReturn {
  const k = `${columnId},${rowId}`
  const cellId = k
  const collaborators = useSelector(state => selectCellCollaborators(state, { cellId }))
  const name: string[] = []
  let color = 'transparent'

  forEach(collaborators, collaborator => {
    name.push(collaborator.name)
    color = collaborator.color
  })

  return {
    name,
    color
  }
}

export default function useCollaborator (viewId?: string): CollaboratorReturn {
  const dispatch: AppDispatch = useDispatch()
  const { tableNodeId, projectId } = useParameters()
  const collaborators = useSelector(collaboratorSelectors.selectAll)
  const cellCollaborators: CellCollaboratorsType = useSelector(state => state.collaborator.cellCollaborators)
  const collaboratorReadyState = useSelector(state => state.app.collaboratorReadyState)
  const sessionID = useSelector(state => state.collaborator.sessionID)
  const otherCollaborators: CollaboratorType[] = useSelector(state => values(state.collaborator.otherCollaborators))
  const cellCollaboratorsRef = useRef(cellCollaborators)
  const currentFocusedCell = useRef<string | null>(null)

  useEffect(() => {
    cellCollaboratorsRef.current = cellCollaborators
  }, [cellCollaborators])

  const updateCollaborator = useCallback(async (columnId?: string, rowId?: string, viewId?: string) => {
    if (sessionID && collaboratorReadyState === WSReadyState.OPEN) {
      const params = {
        projectID: projectId,
        selected: columnId ? {
          columnID: columnId,
          rowID: rowId,
          tableNodeID: tableNodeId,
          viewID: viewId
        } : null,
        sessionID
      }

      dispatch(collaboratorActions.update(params))
    }
  }, [dispatch, projectId, sessionID, tableNodeId, collaboratorReadyState])

  const getCellCollaborators = (props?: ICellRendererParams) => {
    const name: string[] = []
    let color = ''
    const cells: CellCollaboratorsType = cellCollaborators
    if (props && cells) {
      const columnId = props.colDef?.field
      const rowId = props.data.id
      const k = `${columnId},${rowId}`
      forEach(cells?.[k], collaborator => {
        name.push(collaborator.name)
        color = collaborator.color
      })
    }

    return {
      name: name.join(', '),
      color
    }
  }

  const checkIsCollaboratorRow = useCallback((row: RowNode) => {
    let isCollaboratorRow = false
    forEach(cellCollaboratorsRef.current, cells => {
      forEach(cells, cell => {
        if (cell.rowID === row.data.id) {
          isCollaboratorRow = true
        }
      })
    })

    return isCollaboratorRow
  }, [cellCollaboratorsRef])

  const debouncedUpdateCollaborator = debounce(updateCollaborator, 1000)
  const onCellFocused = curry((viewId: string | null, e: CellFocusedEvent) => {
    const gridApi = e.api
    if (viewId) {
      const cellRanges = gridApi?.getCellRanges()?.[0]
      const rowIndex = cellRanges?.startRow?.rowIndex
      const startColumn = cellRanges?.startColumn
      const colId = startColumn?.getColId()
      /**
       * 以下情况需要排除：
       * headerName !== 'index-column'：第一列单元格被选中的情况（拖拽，checkbox）
       * startColumn?.getColId() !== ADD_COLUMN_ID：最后一列（增加列）单元格
       */
      if (typeof rowIndex !== 'undefined' &&
        startColumn?.getColId() !== INDEX_COLUMN_ID &&
        startColumn?.getColId() !== ADD_COLUMN_ID) {
        const row = gridApi?.getDisplayedRowAtIndex(rowIndex)
        const id = `${colId}_${row?.id}`

        if (currentFocusedCell.current !== id) {
          currentFocusedCell.current = id
          debouncedUpdateCollaborator(colId, row?.id, viewId)
        }
      }
    }
  })(viewId ?? null)

  return {
    collaborators,
    otherCollaborators,
    cellCollaborators,
    getCellCollaborators,
    updateCollaborator,
    checkIsCollaboratorRow,
    onCellFocused
  }
}
