import React, { useCallback, useMemo, useState } from 'react'
import { Button, message } from 'antd'
import filter from 'lodash/filter'
import useDispatch from 'src/hooks/common/useDispatch'
import Dialog from 'src/components/common/Dialog'
import { DialogStateReturn as DialogInterface } from 'src/hooks/common/useDialog'
import { AppDispatch } from 'src/store'
import useSelector from 'src/hooks/common/useSelector'
import { tableNodeSelectors } from 'src/state/tableNode/slice'
import { updateTableNode } from 'src/state/tableNode/actions'
import { useParameters } from 'src/hooks/common/useParameters'
import { projectSelectors } from 'src/state/project/slice'
import styles from './fieldSettingModal.module.scss'
import { FieldList } from './FieldList'

const MAX_VISIBLE_COUNT = 5

interface InfoWindowSettingModalProps {
  tableNodeId?: number,
  dialog: DialogInterface;
}

export const InfoWindowSettingModal: React.FC<InfoWindowSettingModalProps> = ({
  tableNodeId,
  dialog
}) => {
  const { projectId } = useParameters()
  const dispatch: AppDispatch = useDispatch()
  const [columns, viewColumns] = useSelector(state => {
    const tableNode = tableNodeSelectors.selectById(state, tableNodeId as number)
    return [
      tableNode?.columns,
      tableNode?.views[0].columns
    ]
  })
  const visibleIdsSorted = useSelector(state => {
    const project = projectSelectors.selectById(state, projectId)
    return project?.tableNodes?.find(t => t.id === tableNodeId)?.infoWindowConfig?.columnIDs
  })
  const [columnIds, setColumnIds] = useState<string[]>([])
  const viewColumnsFiltered = useMemo(() =>
    filter(viewColumns, col => columns?.find(c => c.id === col.id) !== undefined)
  ,[columns, viewColumns])
  const handleColumnsUpdate = useCallback((c: string[]) => {
    setColumnIds(c)
  }, [setColumnIds])
  async function handleSubmitClick () {
    await dispatch(updateTableNode(
      {
        id: tableNodeId as number,
        tableNode: {
          projectID: projectId,
          infoWindowConfig: {
            columnIDs: columnIds
          }
        }
      }
    ))
    message.success('保存成功')
    dialog.close()
  }

  async function handleReset () {
    await dispatch(updateTableNode(
      {
        id: tableNodeId as number,
        tableNode: {
          projectID: projectId,
          infoWindowConfig: {
            columnIDs: []
          }
        }
      }
    ))
    message.success('已经恢复默认配置')
    dialog.close()
  }

  return (
    <Dialog
      width="400px" okText="保存"
      title='配置信息窗口展示内容'
      visible={dialog.visible} onCancel={dialog.close} onOk={handleSubmitClick}>
      <div>信息窗口中展示内容支持自定义配置，您可根据需要调整展示字段及顺序，最多支持展示 {MAX_VISIBLE_COUNT} 个字段。<b>配置将会对项目中所有 POI 信息窗口生效。</b></div>
      {columns && viewColumns &&
      <FieldList
        columns={columns} viewColumns={viewColumnsFiltered}
        visibleIdsSorted={visibleIdsSorted}
        onColumnsUpdate={handleColumnsUpdate}/>}
      <Button type="link" className={styles.resetButton} onClick={handleReset}>恢复默认配置</Button>
    </Dialog>
  )
}
