import { PayloadAction } from '@reduxjs/toolkit'
import forEach from 'lodash/forEach'
import { State } from 'src/state/status/slice'
import { WSReadyState } from 'src/state/app/reducers'
import { REQUEST_EXPIRED_TIME } from 'src/constants/status'
import statusAdapter from './entityAdapter'

/**
 * @name ignore
 */
export function requestStart (state: State, action: PayloadAction<string>): void {
  const requestID = action.payload
  if (state.requestFinishIds[requestID]) {
    delete state.requestFinishIds[requestID]
    statusAdapter.removeOne(state, requestID)
  } else {
    statusAdapter.upsertOne(state, {
      id: requestID,
      expired: Date.now() + REQUEST_EXPIRED_TIME
    })
  }

  const requests = statusAdapter.getSelectors().selectAll(state)
  state.idle = !requests.length
  if (!state.idle) {
    state.checkRequestExpiredTimestamp = Date.now()
  }
}

/**
 * @name ignore
 */
export function networkStatus (state: State, action: PayloadAction<string>): void {
  if (action.payload) {
    state.networkStatus = action.payload
  }
}

/**
 * @name ignore
 */
export function clearNetworkStatus (state: State): void {
  state.networkStatus = ''
}

/**
 * @name ignore
 */
export function updateUserWsStatus (state: State, action: PayloadAction<number>): void {
  const { payload } = action
  state.userWsStatus = payload
  if (payload === WSReadyState.OPEN && !state.userWsInitialized) {
    state.userWsInitialized = true
  }
}

/**
 * @name ignore
 */
export function updateOrgWsStatus (state: State, action: PayloadAction<number>): void {
  const { payload } = action
  state.orgWsStatus = payload
  if (payload === WSReadyState.OPEN && !state.orgWsInitialized) {
    state.orgWsInitialized = true
  }
}

/**
 * @name ignore
 */
export function checkExpiredRequest (state: State): void {
  const requests = statusAdapter.getSelectors().selectAll(state)
  if (requests.length) {
    const expired = Date.now()
    forEach(requests, request => {
      if (request.expired < expired) {
        statusAdapter.removeOne(state, request.id)
      }
    })

    state.checkRequestExpiredTimestamp = expired
  } else {
    state.idle = true
    state.checkRequestExpiredTimestamp = 0
  }
}


/**
 * @ignore
 */
export const reducers = {
  requestStart,
  checkExpiredRequest,
  networkStatus,
  clearNetworkStatus,
  updateUserWsStatus,
  updateOrgWsStatus
}
