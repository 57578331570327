import { useRef, useCallback, useEffect, useState } from 'react'
import type { Polyline, LngLatLike } from 'qumap/packages/core/types/types'
import { QUMap } from 'qumap/packages/core'
import { transformToTopLeft, getInfoWindowAnchorPosition } from 'src/utils/infoWindow'
import useTableCells from 'src/hooks/project/useTableCells'
import { CONNECTED_POLYLINE_Z_INDEX, getConnectedPolylineStyles } from 'src/constants/map'
import type { InfoWindowPOIType } from 'src/typings/map'
import type { Cell } from 'src/typings/tableNode'

interface Return {
  cells?: Cell[];
  connectedLine?: Polyline;
  windowRef: React.RefObject<HTMLDivElement>
}

export default function useInfoWindow (poi: InfoWindowPOIType, mapInstance: QUMap): Return {
  const windowRef = useRef<HTMLDivElement>(null)
  const {
    cells
  } = useTableCells(poi.extData?.tableNodeID as number, poi.id)
  const [connectedLine, setConnectedline] = useState<Polyline>()

  const adjustConnectionPolyline = useCallback((connectedPolyline: Polyline) => {
    if (windowRef.current && connectedPolyline) {
      connectedPolyline.setPath([
        getInfoWindowAnchorPosition(transformToTopLeft(windowRef.current), windowRef.current, mapInstance.amap),
        poi.center
      ] as LngLatLike[])
    }
  }, [poi.center, mapInstance])

  useEffect(() => {
    if (poi && windowRef.current && mapInstance) {
      const center = getInfoWindowAnchorPosition({
        top: poi.top,
        left: poi.left
      }, windowRef.current, mapInstance.amap)
      if (connectedLine) {
        connectedLine.setPath([
          center,
          poi.center
        ] as LngLatLike[])
      } else {
        const connectedPolyline = new mapInstance.AMap.Polyline({
          path: [
            center,
            poi.center
          ],
          ...getConnectedPolylineStyles(),
          zIndex: CONNECTED_POLYLINE_Z_INDEX
        })

        mapInstance.amap.add(connectedPolyline)
        setConnectedline(connectedPolyline)
        mapInstance.amap.on('zoomchange', () => adjustConnectionPolyline(connectedPolyline))
        mapInstance.amap.on('mapmove', () => adjustConnectionPolyline(connectedPolyline))
      }
    }
  }, [poi, windowRef, connectedLine, mapInstance, adjustConnectionPolyline])

  useEffect(() => {
    return () => {
      connectedLine && connectedLine.remove()
    }
  }, [mapInstance, connectedLine])

  return {
    cells,
    connectedLine,
    windowRef
  }
}
