import { createSelector } from '@reduxjs/toolkit'
import filter from 'lodash/filter'
import isEmpty from 'lodash/isEmpty'
import type { RootState } from 'src/store'
import { tableNodeSelectors } from 'src/state/tableNode/slice'
import { getFilterRelations } from 'src/constants/filter'
import { Column } from 'src/typings/tableNode'

export const selectColumnsForFilter = createSelector<
  RootState, 
  number, 
  Column[] | undefined, 
  number, 
  Column[]
>(
  (state, tableNodeId) => tableNodeSelectors.selectById(state, tableNodeId)?.columns,
  (_, tableNodeId) => tableNodeId,
  (columns) => {
    return filter(columns, c => !isEmpty(getFilterRelations(c.type)))
  } 
)