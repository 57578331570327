import { PayloadAction } from '@reduxjs/toolkit'
import { State } from 'src/state/workspace/slice'
import { WorkspaceDataType } from 'src/ws'

/**
 * @ignore
 */
function clearRoleChange (state: State): void {
  state.workspaceRoleChanged = undefined
}

/**
 * @name workspace/roleChange
 * @param state
 * @param action payload {@link workspace}
 */
function roleChange (state: State, action: PayloadAction<{ wsWorkspace: WorkspaceDataType['workspace'] }>): void {
  const { payload } = action
  const { workspace } = state
  if (payload?.wsWorkspace && payload.wsWorkspace.id && payload.wsWorkspace.type === workspace?.type) {
    state.workspaceRoleChanged = {
      type: payload.wsWorkspace.type,
      id: payload.wsWorkspace.id
    }
  }
}

/**
 * @ignore
 */
export const reducers = {
  roleChange,
  clearRoleChange
}
