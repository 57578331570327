import React, { useEffect } from 'react'
import { Form, Input, message } from 'antd'
import { useDispatch } from 'react-redux'
import { createProject } from 'src/state/project/actions'
import Dialog from 'src/components/common/Dialog'
import { DialogStateReturn as DialogInterface } from 'src/hooks/common/useDialog'
import { AppDispatch } from 'src/store'
import { useParameters } from 'src/hooks/common/useParameters'

interface Props {
  dialog: DialogInterface;
  type?: string
}

const CreateLayerModal: React.FC<Props> = ({
  dialog,
  type
}) => {
  const { workspaceId } = useParameters()
  const dispatch: AppDispatch = useDispatch()
  const [form] = Form.useForm()
  useEffect(() => {
    if (dialog.visible) {
      form.resetFields()
    }
  }, [dialog, form])

  async function handleSubmitClick () {
    await form.validateFields()
    const projectName = form.getFieldValue('projectName')
    await dispatch(createProject({
      projectName,
      organizationId: type === 'org' ? workspaceId : undefined }))
    message.success('新建成功')
    dialog.close()
  }

  return (
    <Dialog
      width="400px" okText="新建" title="新建项目"
      visible={dialog.visible} onCancel={dialog.close} onOk={handleSubmitClick} >
      <Form
        form={form}
      >
        <Form.Item
          name="projectName" label="项目名称" colon={false}
          rules={[
            {
              required: true,
              message: '请输入项目名称'
            }
          ]} >
          <Input />
        </Form.Item>
      </Form>
    </Dialog>
  )
}

export default CreateLayerModal
