import pick from 'lodash/pick'
import AppConfig from 'src/config'
import { createInstance } from 'src/utils/fetch'

import { APIResponse } from 'src/typings/common'
import { LayerNode, DeleteNode } from 'src/typings/mapTableNode'
import { DND } from 'src/typings/tree'

const fetch = createInstance({
  baseURL: `${AppConfig.baseApiURL}/layernodes/`
})

const LayerNodesAPI = {
  async create (params: LayerNode): Promise<APIResponse> {
    const { data } = await fetch.post('', params)

    return data
  },
  async move (params: DND): Promise<APIResponse> {
    const { data } = await fetch.patch(`${params.id}/move/`, pick(params, ['parentID', 'prevID', 'projectID']))

    return data
  },
  async update (params: Partial<LayerNode>): Promise<APIResponse> {
    const { data } = await fetch.patch(`/${params.id}/`, pick(params, [
      'mapInfo',
      'name',
      'projectID'
    ]))

    return data
  },
  async deleteNode (params: DeleteNode): Promise<string> {
    await fetch.delete(`/${params.id}/?projectID=${params.projectID}`)

    return params.id
  },
  async getDetail (id: string): Promise<APIResponse> {
    const { data } = await fetch.get(`/${id}/`)

    return data
  }
}

export default LayerNodesAPI
