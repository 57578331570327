import { createAsyncThunk, MiddlewareAPI, AnyAction } from '@reduxjs/toolkit'
import { actions as undoRedoActions } from 'src/state/undoRedo/slice'
import updateCellsUndoRedo from 'src/features/undoRedo/updateCells'
import deleteTableNodeUndoRedo from 'src/features/undoRedo/deleteTableNode'
import updateTableNodeNameUndoRedo from 'src/features/undoRedo/updateTableNodeName'
import moveTableNodeUndoRedo from 'src/features/undoRedo/moveTableNode'
import addAttachmentUndoRedo from 'src/features/undoRedo/addAttachment'
import deleteAttachmentUndoRedo from 'src/features/undoRedo/deleteAttachment'

import type { RootState } from 'src/store'

export interface UndoRedo {
  action: ReturnType<typeof createAsyncThunk>;
  undoAction: ReturnType<typeof createAsyncThunk>;
  redoAction: ReturnType<typeof createAsyncThunk>;
  getUndoPayload: (arg: unknown, state: RootState) => unknown;
  getFulfilledUndoPayload?: (pendingUndoPayload: unknown, state: RootState) => unknown;
  getRedoPayload: (arg: unknown, state: RootState) => unknown;
}

const pendingPayload = new Map()

export const undoRedoList = [
  updateCellsUndoRedo,
  deleteTableNodeUndoRedo,
  updateTableNodeNameUndoRedo,
  moveTableNodeUndoRedo,
  addAttachmentUndoRedo,
  deleteAttachmentUndoRedo
]

function watchUndoRedoAction (
  action: AnyAction,
  undoRedo: UndoRedo,
  middlewareAPI: MiddlewareAPI
) {
  const { dispatch, getState } = middlewareAPI
  const undoableAction = undoRedo.action

  if (undoableAction.pending.match(action)) {
    const payload = undoRedo.getUndoPayload(action.meta.arg, getState())
    pendingPayload.set(action.meta.requestId, payload)
    dispatch(undoRedoActions.clearRedo())
  }

  if (undoableAction.fulfilled.match(action)) {
    if (pendingPayload.has(action.meta.requestId)) {
      const pendingUndoPayload = pendingPayload.get(action.meta.requestId)
      const undoPayload = undoRedo.getFulfilledUndoPayload 
        ? undoRedo.getFulfilledUndoPayload(pendingUndoPayload, getState()) : pendingUndoPayload
      const redoPayload = undoRedo.getRedoPayload(action.meta.arg, getState())

      dispatch(undoRedoActions.appendUndo({
        undoAction: undoRedo.undoAction,
        redoAction: undoRedo.redoAction,
        undoPayload: undoPayload,
        redoPayload: redoPayload,
        pathname: document.location.pathname
      }))
      
      pendingPayload.delete(action.meta.requestId)
    } 
  }

  if (undoableAction.rejected.match(action)) {
    pendingPayload.delete(action.meta.requestId)
  } 
}

export function watchUndoRedoActions (action: AnyAction, api: MiddlewareAPI): void {
  undoRedoList.forEach((item) => {
    watchUndoRedoAction(action, item, api)
  }) 
}