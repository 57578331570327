import React, { useState } from 'react'
import {
  DownloadOutlined,
  DeleteOutlined,
  LoadingOutlined,
  EditOutlined
} from '@ant-design/icons'
import cx from 'clsx'
import type { FileParams } from 'src/api/tablenodes'
import { checkAbleToPreview, download } from 'src/utils/attachment'
import { getThumbnailUrl } from 'src/utils/image'
import styles from './attachmentItem.module.scss'
import iconStyles from './icons.module.scss'

interface Props {
  attachment: FileParams;
  onClick: (fileId: string) => void;
  onDeleteClick: (fileId: string) => void;
  onEditClick: (fileId: string, fileName: string) => void;
}

const AttachmentItem: React.FC<Props> = ({
  attachment,
  onClick,
  onDeleteClick,
  onEditClick
}) => {
  const { fileID, fileName, url, fileExt } = attachment
  const [isDownloading, setIsDownloading] = useState(false)
  const ableToView = checkAbleToPreview(fileExt)
  const thumbnail = getThumbnailUrl(url, 134)

  function handleImageClick () {
    onClick(fileID)
  }

  async function handleDownloadClick () {
    if (isDownloading) return

    setIsDownloading(true)

    try {
      await download(url, fileName)
    } finally {
      setIsDownloading(false)
    }
  }

  function handleDeleteClick () {
    onDeleteClick(fileID)
  }

  function handleEditClick () {
    onEditClick(fileID, fileName)
  }

  return (
    <div className={styles.item}>
      <div className={styles.preview}>
        {
          ableToView
            ? <div
              className={styles.image}
              style={{ backgroundImage: `url(${thumbnail})` }}
              title={fileName}
              onClick={handleImageClick}
            />
            : <div title={fileName} className={cx(iconStyles.fileIcon, iconStyles[fileExt] ?? iconStyles.unknown)} />
        }
        <div className={styles.actions}>
          <div className={styles.action} onClick={handleEditClick}>
            <EditOutlined />
          </div>
          <div className={styles.action} onClick={handleDownloadClick}>
            {isDownloading ? <LoadingOutlined /> : <DownloadOutlined />}
          </div>
          <div className={cx(styles.action, styles.actionDelete)} onClick={handleDeleteClick}>
            <DeleteOutlined />
          </div>
        </div>
      </div>
      <div className={styles.name}>{fileName}</div>
    </div>
  )
}

export default AttachmentItem
