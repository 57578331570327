import { ICellRendererParams, ICellEditorParams } from 'ag-grid-community'
import { TypeOptions } from 'src/typings/tableNode'
import { Field } from 'src/components/grid/GridFieldConfig'
import { useColumn } from 'src/hooks/common/useTableNode'
import { useParameters } from 'src/hooks/common/useParameters'

interface CellRendererParamsReturn {
  type: string;
  typeOptions?: TypeOptions;
  name: string;
  isFirstColumn: boolean;
}

export function useCellRendererParams (props: ICellRendererParams): CellRendererParamsReturn {
  const { tableNodeId } = useParameters()
  const params = props.column?.getColDef().headerComponentParams || props
  const { column = params as Field } = useColumn(tableNodeId, props.colDef?.field)
  return {
    type: column.type,
    typeOptions: column.typeOptions,
    name: column.name,
    isFirstColumn: params.isFirstColumn
  }
}

interface CellEditorParamsReturn {
  type: string
  typeOptions: TypeOptions
  inGrid: boolean
}

export function useCellEditorParams (props: ICellEditorParams): CellEditorParamsReturn {
  const { tableNodeId } = useParameters()
  const params = props.column?.getColDef().cellEditorParams || props
  const { column = params } = useColumn(tableNodeId, props.colDef?.field)
  return {
    typeOptions: column.typeOptions,
    type: column.type,
    inGrid: params.inGrid
  }
}
