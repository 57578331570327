import { useState, useEffect, RefObject } from 'react'
import join from 'lodash/join'
import map from 'lodash/map'
import find from 'lodash/find'
import replace from 'lodash/replace'
import { indexOf, traverse } from 'src/utils/formula'
import useFormula from './useFormula'

interface Return {
  range: Range | undefined
  autoCompleteText: string
  formulaExpression: string
  insertNode: (html: string, ignoreAutoCompleteText?: boolean) => void
  removeNode: () => void
}

const getRange = (): Range | undefined => {
  let sel
  let range
  if (window.getSelection) {
    sel = window.getSelection()
    if (sel && sel.rangeCount) {
      range = sel.getRangeAt(0)
    }
  }

  return range
}

const getAutoCompleteText = (editor: HTMLDivElement) => {
  let sel
  let range
  let caretPos = 0
  let autoCompleteText = ''
  let currentNode: Node | undefined = undefined
  if (window.getSelection) {
    sel = window.getSelection()
    if (sel && sel.rangeCount) {
      range = sel.getRangeAt(0)
      if (range.commonAncestorContainer.parentNode === editor) {
        caretPos = range.endOffset
      }

      const getSelection = document.getSelection()
      const node = getSelection?.anchorNode
      if (node) {
        autoCompleteText = node.textContent?.substr(0, caretPos) || ''
        currentNode = node
      }
    }
  }

  // FIXME: think about more special characters
  const splitter = new RegExp('，|,|\\)|\\(|\\+|\\/|-|\\*|\\.', 'g')
  return {
    text: autoCompleteText?.toLowerCase().split(splitter).pop() || '',
    node: currentNode
  }
}

const handleRemoveText = (commonNode: HTMLElement | null, range: Range) => {
  if (commonNode) {
    const isTextNode = commonNode.nodeType === 3
    const length = commonNode.textContent?.length
    if (!isTextNode && !(commonNode as HTMLElement).isContentEditable) {
      range.setStart(commonNode, 0)
      range.setEnd(commonNode, 0)
      commonNode.remove()
    } else {
      if (length) {
        range.setStart(commonNode, length - 1)
        range.setEnd(commonNode, length)
      } else {
        if (commonNode.previousSibling) {
          handleRemoveText(commonNode.previousSibling as HTMLElement, range)
        }

        range.setStart(commonNode, 0)
        range.setEnd(commonNode, 0)
        commonNode.remove()
      }
    }
  }
}

const removeNode = (range: Range | undefined, currentNode: Node | null) => {
  let sel

  if (window.getSelection && range) {
    sel = window.getSelection()
    if (sel && sel.getRangeAt && sel.rangeCount && currentNode) {
      const commonNode = range.commonAncestorContainer

      // 光标在Text节点内
      if (commonNode.nodeType === 3) {
        if (!range.endOffset) {
          // 光标在Text节点的最左侧
          // eg: ↓这是一段文本
          handleRemoveText(commonNode.previousSibling as HTMLElement, range)
        } else if (!commonNode.textContent) {
          // 光标所在的节点的文本内容为空
          // eg: ↓
          handleRemoveText(commonNode.previousSibling as HTMLElement, range)
        } else {
          // eg: 这是一段↓文本
          range.setStart(currentNode, range.endOffset - 1)
        }
      } else {
        // 光标不在节点的最左侧
        if (range.endOffset) {
          if (range.startOffset === range.endOffset) {
            const node = commonNode.childNodes[range.endOffset - 1]
            // 判断当前节点是否为Text节点
            if (node.nodeType === 3) {
              handleRemoveText(node as HTMLElement, range)
            } else {
              range.setStart(currentNode, range.endOffset - 1)
            }
          }
        }
      }

      range.deleteContents()
      sel.removeAllRanges()
      sel.addRange(range)
    }
  }
}

const insertNode = (
  html: string,
  range: Range | undefined,
  currentNode: Node | null,
  autoCompleteText = '',
  ignoreAutoCompleteText = false
) => {
  let sel
  const leftOffset = ignoreAutoCompleteText ? 0 : autoCompleteText.length
  if (window.getSelection && range) {
    sel = window.getSelection()
    if (sel && sel.getRangeAt && sel.rangeCount) {
      range.deleteContents()

      const el = document.createElement('div')
      el.innerHTML = html
      const frag = document.createDocumentFragment()
      let node
      let lastNode
      while ((node = el.firstChild)) {
        lastNode = frag.appendChild(node)
      }
      if (leftOffset) {
        const textContent = currentNode?.textContent
        if (currentNode && textContent) {
          range.setStart(currentNode, range.endOffset - leftOffset)
          range.deleteContents()
          sel.removeAllRanges()
          sel.addRange(range)
        }
      }

      range.insertNode(frag)

      if (lastNode) {
        range = range.cloneRange()
        if (lastNode.textContent === '()') {
          range.setStart(lastNode, 1)
        } else {
          range.setStartAfter(lastNode)
        }
        range.collapse(true)
        sel.removeAllRanges()
        sel.addRange(range)
      }
    }
  }
}

export default function useContentEditor (
  editorRef: RefObject<HTMLDivElement>,
  selection: number,
  formula = ''
): Return {
  const {
    formulaNames,
    computableColumns,
    getFieldNode,
    getFormulaNode
  } = useFormula()
  const [currentNode, setCurrentNode] = useState<Node | null>(null)
  const [formulaExpression, setFormulaExpression] = useState(formula)
  const [editorRange, setEditorRange] = useState<Range | undefined>()
  const [initialized, setInitialized] = useState(false)
  const [autoCompleteText, setAutoCompleteText] = useState('')
  useEffect(() => {
    if (editorRef.current && document.activeElement === editorRef.current) {
      setEditorRange(getRange())
    }
  }, [editorRef, selection])

  useEffect(() => {
    if (
      !initialized &&
      editorRef.current &&
      editorRange &&
      formula &&
      computableColumns.length
    ) {
      const formulaItems = formula.split(' ')
      const elements: string[] = map(formulaItems, item => {
        if (indexOf(item, '$c_') === 0) {
          const column = find(computableColumns, col => col.id === replace(item, '$c_', ''))
          return getFieldNode(column)
        }

        if (formulaNames[item]) {
          return getFormulaNode(item)
        }

        return item
      })

      insertNode(elements.join(''), editorRange, currentNode, autoCompleteText)
      setInitialized(true)
    }
  }, [
    getFieldNode,
    getFormulaNode,
    initialized,
    editorRef,
    editorRange,
    formula,
    computableColumns,
    formulaNames,
    autoCompleteText,
    currentNode
  ])

  useEffect(() => {
    if (editorRef.current) {
      const { text, node } = getAutoCompleteText(editorRef.current)
      setAutoCompleteText(text)
      node && setCurrentNode(node)
      const currentFormula = getFormattedFormulaExpression(editorRef.current)
      if (currentFormula !== formulaExpression) {
        setFormulaExpression(currentFormula)
      }
    }
  }, [editorRef, editorRange, selection, formulaExpression])

  const getFormattedFormulaExpression = (editor: HTMLDivElement): string => {
    return replace(join(traverse(editor.childNodes), ' '), '，', ',')
  }

  return {
    range: editorRange,
    autoCompleteText,
    formulaExpression,
    insertNode: (html: string, ignoreAutoCompleteText = false) => insertNode(
      html,
      editorRange,
      currentNode,
      autoCompleteText,
      ignoreAutoCompleteText
    ),
    removeNode: () => removeNode(editorRange, currentNode)
  }
}
