export const LOGIN_VERIFICATION_CODE = 'MT_VERIFICATION_CODE_TIMESTAMP'
export const TOKEN = 'MT_TOKEN'
export const REFRESH_TOKEN = 'MT_REFRESH_TOKEN'
export const LAST_REFRESH_TOKEN_TIMESTAMP = 'MT_LAST_REFRESH_TOKEN_TIMESTAMP'
export const IS_AUTH = 'MT_IS_AUTH'
export const HIDDEN_LAYER_IDS = 'MT_HIDDEN_LAYER_IDS'
export const HIDDEN_CHANGE_FIELD_TYPE_CONFIRM_MODAL = 'MT_HIDDEN_CHANGE_FIELD_TYPE_CONFIRM_MODAL'
export const COPY_CACHE = 'MT_COPY_CACHE'
export const COPY_CACHE_RAW = 'MT_COPY_CACHE_RAW'
export const MAP_FILTERS = 'MT_MAP_FILTERS'
export const HIDDEN_REFRESH_CONFIRM_MODAL = 'MT_HIDDEN_REFRESH_CONFIRM_MODAL'
