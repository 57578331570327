import React, { useState, useEffect, useCallback, useRef } from 'react'
import { Popover, Button, Input, message } from 'antd'
import { useIntl } from 'react-intl'
import ActionButton from 'src/components/project/ActionButton'
import Icon from 'src/components/common/Icon'
import useDispatch from 'src/hooks/common/useDispatch'
import { createSnapshot, getSnapshots, restoreSnapshot } from 'src/state/tableNode/actions'
import { useParameters } from 'src/hooks/common/useParameters'
import { formatDateTimeToLocal } from 'src/utils/moment'
import { useConfirmDialog } from 'src/hooks/common/useDialog'
import useSelector from 'src/hooks/common/useSelector'
import { projectSelectors } from 'src/state/project/slice'
import styles from './snapshot.module.scss'

interface SnapshotItem { 
  id: number;
  name: string;
  createTime: string;
}

interface Props {
  onFinish?: () => void
}

const SnapshotContent: React.FC<Props> = (props) => {
  const { onFinish } = props
  const { tableNodeId, projectId } = useParameters()
  const dispatch = useDispatch()
  const intl = useIntl()
  const [isEditing, setIsEditing] = useState(false)
  const [inputName, setInputName] = useState('')
  const [list, setList] = useState<SnapshotItem[]>([])
  const wrapRef = useRef<HTMLDivElement>(null)
  const confirmDialog = useConfirmDialog({
    messageIds: {
      title: 'snapshot.modal.title',
      content: 'snapshot.modal.content'
    }
  })

  const tableNodeName = useSelector(state => 
    projectSelectors.selectById(state, projectId)?.tableNodes?.find(t => t.id === tableNodeId)?.name)
  
  const loadSnapShot = useCallback(async () => {
    const { payload } = await dispatch(getSnapshots({ id: tableNodeId, projectID: projectId }))
    setList(payload as SnapshotItem[])
  }, [dispatch, projectId, tableNodeId])

  useEffect(() => {
    loadSnapShot()
  }, [loadSnapShot])

  const handleAddButtonClick = () => {
    setIsEditing(true)
  }

  const handleCreateButtonClick = () => {
    dispatch(createSnapshot({ id: tableNodeId, projectID: projectId, name: inputName }))
    message.info(intl.formatMessage({ id: 'snapshot.message.create' }))
    onFinish?.()
  }

  const handleItemClick = (item: SnapshotItem) => {
    confirmDialog({
      getContainer: wrapRef.current,
      messageValues: {
        tableName: tableNodeName ?? '',
        snapshotName: item.name
      },
      okText: intl.formatMessage({ id: 'snapshot.restore' }),
      closable: false
    }).then(() => {
      dispatch(restoreSnapshot({ id: tableNodeId, projectID: projectId, snapshotID: item.id }))
      message.info(intl.formatMessage({ id: 'snapshot.message.restore' }))
      onFinish?.()
    })
  }

  return <div className={styles.wrap}>
    <div className={styles.header}>{intl.formatMessage({ id: 'snapshot.header' })}</div>
    { isEditing ?
      <div className={styles.row}>
        <Input
          className={styles.input} 
          placeholder={intl.formatMessage({ id: 'snapshot.placeholder' })}
          value={inputName} 
          onChange={e => setInputName(e.target.value)}
          onKeyDown={(ev) => ev.key === 'Enter' && inputName.length !== 0 && handleCreateButtonClick()}
        />
        <Button type="primary" onClick={handleCreateButtonClick} disabled={inputName.length === 0}>
          {intl.formatMessage({ id: 'snapshot.create' })}
        </Button>
      </div> :
      <Button className={styles.addButton} type="link" block onClick={handleAddButtonClick}>
        <div className={styles.addButtonContent}>
          <Icon type="plainPlus" />
          {intl.formatMessage({ id: 'snapshot.edit' })}
        </div>
      </Button>
    }
    <div className={styles.listWrap} ref={wrapRef}>
      <div className={styles.list}>
        {list.length === 0 && <div className={styles.empty}>{intl.formatMessage({ id: 'snapshot.empty' })}</div>}
        {list.map(item => 
          <div className={styles.item} key={item.id} onClick={() =>handleItemClick(item)}>
            <div>{item.name}</div>
            <div className={styles.timestamp}>{formatDateTimeToLocal(item.createTime)}</div>
          </div>)}
      </div>
    </div>
    <div className={styles.hint}>
      {intl.formatMessage({ id: 'snapshot.hint' })}
    </div>
  </div>

} 

const Snapshot: React.FC = () => {  
  const [visible, setVisible] = useState(false)
  const intl = useIntl()
  return (
    <Popover
      placement="bottomLeft"
      content={<SnapshotContent
        onFinish={() => setVisible(false)}
      />}
      trigger="click"
      visible={visible}
      onVisibleChange={(visible) => setVisible(visible)}
      destroyTooltipOnHide
    >
      <ActionButton icontype="snapshot" >
        {intl.formatMessage({ id: 'snapshot.title' })}
      </ActionButton>
    </Popover>
  )
}

export default Snapshot
