import React, { useState } from 'react'
import cx from 'clsx'
import map from 'lodash/map'
import includes from 'lodash/includes'
import filter from 'lodash/filter'
import { Modal, Carousel } from 'antd'
import {
  LeftOutlined,
  RightOutlined,
  DownloadOutlined,
  DeleteOutlined,
  LoadingOutlined,
  EditOutlined
} from '@ant-design/icons'
import type { DialogStateReturn as Dialog } from 'src/hooks/common/useDialog'
import type { FileParams } from 'src/api/tablenodes'
import { useAttachmentPreview } from 'src/hooks/common/useAttachment'
import { checkAbleToPreview, download } from 'src/utils/attachment'
import Icon from 'src/components/common/Icon'
import styles from './attachmentPreview.module.scss'

interface HeaderProps {
  title: string;
  onClose: () => void;
}
const Header: React.FC<HeaderProps> = ({
  title,
  onClose
}) => {
  return (
    <div className={styles.header}>
      <Icon type="close" className={styles.closeIcon} onClick={onClose} />
      <h3 className={styles.title}>{title}</h3>
    </div>
  )
}

interface Props {
  dialog: Dialog;
  attachments: FileParams[];
  currentPreviewId?: string;
  onDeleteClick?: (fileId: string) => void;
  onEditClick?: (fileId: string, fileName: string) => void;
  onClose?: () => void;
}

const AttachmentPreview: React.FC<Props> = ({
  dialog,
  attachments,
  currentPreviewId,
  onDeleteClick,
  onEditClick,
  onClose
}) => {
  const [downloadingList, setDownloadingList] = useState<string[]>([])
  const {
    carouselWrap,
    carousel,
    currentAttachment,
    index,
    setIndex
  } = useAttachmentPreview(attachments, styles.previewItem, currentPreviewId)

  function prevClick () {
    if (index === 0) return

    carousel.current?.prev()
  }

  function nextClick () {
    if (index === (attachments.length - 1)) return

    carousel.current?.next()
  }

  function handleChange (current: number, next: number) {
    setIndex(next)
  }

  function handleEditClick () {
    if (currentAttachment) {
      onEditClick?.(currentAttachment.fileID, currentAttachment.fileName)
    }
  }

  async function handleDownloadClick () {
    if (!currentAttachment) return

    setDownloadingList((prev) => [...prev, currentAttachment.url])

    try {
      await download(currentAttachment.url, currentAttachment.fileName)
    } finally {
      setDownloadingList((prev) => filter(prev, p => p !== currentAttachment.url))
    }
  }

  function handleDeleteClick () {
    if (currentAttachment) {
      onDeleteClick?.(currentAttachment?.fileID)
    }
  }

  function handleCancel () {
    setIndex(-1)
    dialog.close()
    onClose?.()
  }

  return (
    <Modal
      className={styles.modal}
      closable={false}
      width="100%"
      maskStyle={{
        backgroundColor: 'rgba(0, 0, 0, 0.9)'
      }}
      visible={dialog.visible}
      onCancel={handleCancel}
      title={
        <Header title={currentAttachment?.fileName ?? ''} onClose={handleCancel} />
      }
      footer={null}
    >
      <div className={styles.content}>
        <div ref={carouselWrap} className={styles.carouselWrap}>
          <div className={styles.prev} onClick={prevClick}>
            <LeftOutlined />
          </div>
          <div className={styles.next} onClick={nextClick}>
            <RightOutlined />
          </div>
          <Carousel
            ref={carousel}
            dots={false}
            infinite={false}
            beforeChange={handleChange}
          >
            {
              map(attachments, a => {
                const ableToView = checkAbleToPreview(a.fileExt)
                return (
                  ableToView
                    ? <div key={a.fileID} className={cx(styles.imageWrap, styles.previewItem)}>
                      <img data-src={a.url} alt={a.fileName} className={styles.image} />
                    </div>
                    : <div key={a.fileID} className={styles.previewItem} >
                      <div className={styles.noPreview}>
                        暂不支持预览
                      </div>
                    </div>
                )
              })
            }
          </Carousel>
        </div>
        <div className={styles.footer}>
          <div className={styles.actions}>
            {
              onEditClick &&
              <div className={styles.action} onClick={handleEditClick}>
                <EditOutlined />
              </div>
            }
            <div className={styles.action} onClick={handleDownloadClick}>
              {includes(downloadingList, currentAttachment?.url) ? <LoadingOutlined /> : <DownloadOutlined />}
            </div>
            {
              onDeleteClick && 
              <div className={cx(styles.action, styles.actionDelete)} onClick={handleDeleteClick}>
                <DeleteOutlined />
              </div>
            }
          </div>
        </div>
      </div>
    </Modal>
  )
}

export default AttachmentPreview
