import { createAsyncThunk } from '@reduxjs/toolkit'
import OrganizationAPI, { Member } from 'src/api/orgs'
import InvitationAPI from 'src/api/invitations'
import UploadAPI from 'src/api/upload'
import { uploadToCOS } from 'src/utils/upload'

export const createTeam = createAsyncThunk(
  'org/create_async',
  async (teamName: string) => {
    return await OrganizationAPI.create(teamName)
  }
)
export const updateTeam = createAsyncThunk(
  'org/update_async',
  async (payload: { id: number, name: string, avatar?: string }) => {
    const { id, name, avatar } = payload
    return await OrganizationAPI.update(id, { name, avatar })
  }
)
export const uploadTeamAvatar = createAsyncThunk(
  'org/uploadAvatar',
  async (payload: { id: number, avatar: File }) => {
    const { id, avatar } = payload
    const { detail } = await UploadAPI.getInfo('org/avatar', { orgID: id })
    const { url } = await uploadToCOS(avatar, detail)
    return url
  }
)

export const getMembers = createAsyncThunk(
  'org/getMembers',
  async (payload: number) => {
    const { detail } = await OrganizationAPI.getMembers(payload)

    return detail
  }
)

export const updateMember = createAsyncThunk(
  'org/updateMember',
  async (payload: { id: number, info: Partial<Member> }) => {
    const { id, info } = payload

    return await OrganizationAPI.updateMember(id, info)
  }
)

export const deleteMember = createAsyncThunk(
  'org/deleteMember',
  async (payload: { id: number, memberId: number }) => {
    const { id, memberId } = payload

    return await OrganizationAPI.deleteMember(id, memberId)
  }
)

export const getInvitations = createAsyncThunk(
  'org/getInvitations',
  async (id: number) => {
    const { detail } = await InvitationAPI.getInvitations(id, 'org')
    return detail
  }
)

export const inviteMember = createAsyncThunk(
  'org/inviteMember',
  async (payload: { id: number, members: Partial<Member>[] }) => {
    const { id, members } = payload

    return await InvitationAPI.inviteMember(id, 'org', members)
  }
)

export const deleteInvitation = createAsyncThunk(
  'org/deleteInvitation',
  async (payload: { id: number, invitationID: number }) => {
    const { id, invitationID } = payload

    return await InvitationAPI.deleteInvitation(id, 'org', invitationID)
  }
)

export const resendInvitation = createAsyncThunk(
  'org/resendInvitation',
  async (payload: { id: number, invitationID: number }) => {
    const { id, invitationID } = payload

    return await InvitationAPI.resendInvitation(id, 'org', invitationID)
  }
)
