import React, { useState } from 'react'
import flowRight from 'lodash/flowRight'
import { Button, Layout, Row, Form, Upload, message, Input } from 'antd'
import type { UploadRequestOption } from 'rc-upload/lib/interface'
import Header from 'src/components/workspace/WorkspaceHeader'
import withWebSocket from 'src/hoc/withWebSocket'
import withLayout from 'src/hoc/withLayout'
import { AppDispatch } from 'src/store'
import useDispatch from 'src/hooks/common/useDispatch'
import Avatar from 'src/components/common/Avatar'
import { updateUser, uploadUserAvatar } from 'src/state/user/actions'
import { useWorkspaces, useWorkspacesEffect } from 'src/hooks/workspace/useWorkspaces'
import { useDocumentTitle } from 'src/hooks/common/useDocumentTitle'
import styles from './user.module.scss'

const { Content } = Layout

const UserPage: React.FC = () => {
  useDocumentTitle('个人设置')
  const [form] = Form.useForm()
  const dispatch: AppDispatch = useDispatch()
  const { workspace } = useWorkspaces('uw')
  const [avatarUrl, setAvatarUrl] = useState(workspace?.avatar)
  useWorkspacesEffect()

  async function handleFinished () {
    await dispatch(updateUser({
      name: form.getFieldValue('name'),
      avatar: avatarUrl
    }))
    message.success('保存成功')
  }

  async function handleUpload (options: UploadRequestOption) {
    const { payload } = await dispatch(uploadUserAvatar({
      avatar: options.file as File
    }))
    setAvatarUrl(payload as string)
  }

  return (
    <Layout>
      <Layout.Header>
        <Header />
      </Layout.Header>
      <Layout className={styles.main}>
        <Content className={styles.content}>
          <Row align="middle" justify="space-between">
            <h2 className={styles.title}>个人设置</h2>
          </Row>
          <div className={styles.divider} />
          <Row>
            <Form
              form={form}
              requiredMark={false}
              colon={false}
              initialValues={{ name: workspace?.name }}
              onFinish={handleFinished}
            >
              <Form.Item
                className={styles.upload}
              >
                <div className={styles.uploadField}>
                  <Avatar size={64} src={avatarUrl} ringWidth={4} />
                  <div className={styles.uploadLabel}>
                    头像
                    <Upload
                      customRequest={handleUpload}
                      showUploadList={false}
                    >
                      <Button type="link">{avatarUrl ? '重新上传' : '上传图片'}</Button>
                    </Upload>
                  </div>
                </div>
              </Form.Item>
              <Form.Item
                label="昵称"
                name="name"
                rules={[{ required: true, message: '请输入昵称' }]}
              >
                <Input />
              </Form.Item>
              <Button type="primary" htmlType="submit" className={styles.saveButton}>
                保存
              </Button>
            </Form>
          </Row>
        </Content>
      </Layout>
    </Layout>
  )
}

export default flowRight(
  withLayout('full'),
  withWebSocket
)(UserPage)
