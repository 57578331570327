import findIndex from 'lodash/findIndex'
import type {
  CellClassParams
} from 'ag-grid-community'
import type { RootState } from 'src/store'

export function highlightCell (params: CellClassParams): boolean {
  const { context, node, colDef } = params
  const state: RootState = context.store.getState()

  const isHighlightSearchResult = findIndex(state.tableNode.searchResults, r => {
    return r.colId === colDef?.field && r.rowIndex === node.rowIndex
  }) !== -1

  return isHighlightSearchResult
}

const cellClassRuels = {
  'highlight-cell': highlightCell
}

export default cellClassRuels