import { createSlice } from '@reduxjs/toolkit'
import { reducers } from './reducers'
import { deleteNode } from './actions'

const initialState = {}

const layerNodesSlice = createSlice({
  name: 'layerNode',
  initialState,
  reducers,
  extraReducers (builder) {
    builder.addCase(deleteNode.rejected, (_, action) => {
      throw(action.error)
    })
  }
})

export default layerNodesSlice
export const actions = layerNodesSlice.actions
