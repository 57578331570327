import React from 'react'
import { Carousel } from 'antd'
import { ReactComponent as LogoTitle } from 'src/assets/images/title.svg'
import slide0 from 'src/assets/images/intro-slide-0.jpg'
import slide1 from 'src/assets/images/intro-slide-1.jpg'
import slide2 from 'src/assets/images/intro-slide-2.jpg'
import slide3 from 'src/assets/images/intro-slide-3.jpg'
import styles from './loginAndSignupPage.module.scss'

const LoginAndSignupPage: React.FC = ({
  children
}) => {
  return (
    <div className={styles.pageContainer}>
      <div className={styles.page}>
        <div className={styles.main}>
          <div className={styles.header}>
            <LogoTitle className={styles.logo} />
            <div className={styles.subTitle}>空间数据协作云平台</div>
          </div>
          <div className={styles.intro}>
            <Carousel dotPosition="right" autoplay>
              <div className={styles.slide}>
                <img src={slide0} alt="地图：可视化呈现" />
                <div className={styles.introItem}>
                  <div className={styles.introTitle}>地图：可视化呈现</div>
                  <p>丰富的可视化效果，简单一点即可生成地图</p>
                </div>
              </div>
              <div className={styles.slide}>
                <img src={slide1} alt="表格：数据协作" />
                <div className={styles.introItem}>
                  <div className={styles.introTitle}>表格：数据协作</div>
                  <p>数据收集整理不再繁琐，团队协作效率倍增！</p>
                </div>
              </div>
              <div className={styles.slide}>
                <img src={slide2} alt="数据市场：拓展边界" />
                <div className={styles.introItem}>
                  <div className={styles.introTitle}>数据市场：拓展边界</div>
                  <p>海量数据支持，让数据不再成为限制</p>
                </div>
              </div>
              <div className={styles.slide}>
                <img src={slide3} alt="分析：获得洞察" />
                <div className={styles.introItem}>
                  <div className={styles.introTitle}>分析：获得洞察</div>
                  <p>完整分析工具，助您洞察数据背后的真相</p>
                </div>
              </div>
            </Carousel>
          </div>
        </div>
        <div className={styles.side}>
          {children}
        </div>
      </div>
    </div>
  )
}

export default LoginAndSignupPage
