import React from 'react'
import { Select } from 'antd'
import map from 'lodash/map'
import noop from 'lodash/noop'
import Icon, { IconType } from 'src/components/common/Icon'
import { FIELD_TYPES } from 'src/constants/fieldType'
import useFormula from 'src/hooks/common/useFormula'
import styles from './fieldSelector.module.scss'

const { Option } = Select

interface Props {
  onChange?: (id: string) => void
}

const FieldSelector: React.FC<Props> = (props) => {
  const { onChange = noop } = props
  const { computableColumns } = useFormula()

  const handleSelectChange = (val: string) => {
    onChange(val)
  }

  const renderColumns = () => {
    return map(computableColumns, col => {
      return (
        <Option
          key={col.id}
          value={col.id}
          label={col.name}
        >
          <div className={styles.option}>
            <Icon className={styles.icon} type={FIELD_TYPES[col.type]?.icon as IconType} />
            {col.name}
          </div>
        </Option>
      )
    })
  }

  return (
    <div className={styles.search}>
      <Select
        placeholder={'选择一列'}
        defaultActiveFirstOption={false}
        onChange={handleSelectChange}
      >
        {renderColumns()}
      </Select>
    </div>
  )
}

export default FieldSelector
