import findIndex from 'lodash/findIndex'
import forEach from 'lodash/forEach'
import { PayloadAction } from 'src/typings/common'
import type { State, SearchResult } from 'src/state/tableNode/slice'
import type { DND } from 'src/typings/tree'
import { TableNode as MapTableNode } from 'src/typings/mapTableNode'
import {
  TableNode,
  MoveViewRowPayload,
  MoveViewColumnPayload,
  TableNodePatch,
  TableNodeRowPatch,
  Row,
  Column,
  View,
  TableNodeColumnPatch,
  ViewColumnPatch,
  TableNodeCells,
  UpdateViewPayload,
  UpdatedColumnPayload,
  TypeOptions
} from 'src/typings/tableNode'
import type { FileParams } from 'src/api/tablenodes'
import { RecycleTableNode } from 'src/typings/project'
import type { LayerNode } from 'src/typings/mapTableNode'
import tableNodeAdapter from './entityAdapter'

/**
 * @ignore
 * @private
 */
function _createRow (tableNode: TableNode, payload: TableNodeRowPatch): void {
  const newRow: Row = {
    id: payload.id,
    cells: payload.cells ?? {}
  }

  tableNode.rows.push(newRow)
  const tableNodeView = tableNode.views.find((t: View) => t.id === payload.addedViewRows[0].viewID)
  tableNodeView?.rows.push(payload.addedViewRows[0])
}

/**
 * @ignore
 * @private
 */
function _createColumn (tableNode: TableNode, payload: TableNodeColumnPatch) {
  const addedViewColumn = payload.addedViewColumns[0]

  tableNode.columns.push({
    id: payload.id,
    name: payload.name,
    type: payload.type,
    typeOptions: payload.typeOptions,
    isPrimary: false
  })

  const tableNodeView = tableNode.views.find((t: View) => t.id === addedViewColumn.viewID)
  tableNodeView?.columns.push(addedViewColumn)

  const updatedCells = payload.updatedCells
  if (updatedCells) {
    forEach(tableNode?.rows, row => {
      Object.assign(row.cells, updatedCells[row.id])
    })
  }
}

/**
 * @ignore
 * @private
 */
function _updateCells (tableNode: TableNode, payload: TableNodeCells) {
  const row = tableNode.rows.find((r: Row) => r.id === payload.rowID)
  if (row?.cells) {
    Object.assign(row.cells, payload.updateCells)
  }
}

/**
 * @ignore
 * @private
 */
function _updateColumn (tableNode: TableNode, payload: Partial<Column>) {
  const col = tableNode?.columns?.find(column => column.id === payload.id)
  if (col) {
    Object.assign(col, payload)
  }
}

/**
 * @name tableNode/setDetail
 * @param state
 * @param action payload {@link TableNode}
 */
export function setDetail (state: State, action: PayloadAction<TableNode>): void {
  const { payload } = action
  const tableNode = state.entities[payload.id]
  if (!tableNode) {
    tableNodeAdapter.addOne(state, payload)
  }
}

/**
 * @name tableNode/createRow
 * @param state
 * @param action payload {@link TableNodeRowPatch}
 */
export function createRow (state: State, action: PayloadAction<TableNodeRowPatch>): void {
  const { payload } = action
  const tableNode = state.entities[payload.tableNodeID]
  if (tableNode) {
    _createRow(tableNode, payload)
  }
}

/**
 * @name tableNode/deleteRows
 * @param state
 * @param action
 */
export function deleteRows (state: State, action: PayloadAction<{
  deletedRowIDs: string[],
  id: number,
  projectID: number
}>): void {
  const { payload } = action
  const tableNode = state.entities[payload.id]
  if (tableNode) {
    forEach(payload.deletedRowIDs, id => {
      const index = findIndex(tableNode.rows, (r: Row) => r.id === id)

      if (index !== -1) {
        tableNode?.rows.splice(index, 1)
      }
    })
  }
}

/**
 * @name tableNode/createColumn
 * @param state
 * @param action payload {@link TableNodeColumnPatch}
 */
export function createColumn (state: State, action: PayloadAction<TableNodeColumnPatch>): void {
  const { payload } = action
  if (payload.tableNodeID) {
    const tableNode = state.entities[payload.tableNodeID]
    if (tableNode) {
      _createColumn(tableNode, payload)
    }
  }
}

/**
 * @name tableNode/updateViewColumn
 * @param state
 * @param action payload {@link ViewColumnPatch}
 */
export function updateViewColumn (state: State, action: PayloadAction<ViewColumnPatch>): void {
  const { payload } = action
  if (payload.tableNodeID) {
    const viewColumn = state.entities[payload.tableNodeID]
      ?.views.find((t: View) => t.id === payload.viewID)
      ?.columns.find(c => c.id === payload.columnID)

    if (viewColumn) {
      Object.assign(viewColumn, payload.updatedFields)
    }
  }
}

/**
 * @name tableNode/unhideAllViewColumns
 * @param state
 * @param action payload {@link ViewColumnPatch}
 */
export function unhideAllViewColumns (state: State, action: PayloadAction<ViewColumnPatch>): void {
  const { payload } = action
  if (payload.tableNodeID) {
    const view = state.entities[payload.tableNodeID]
      ?.views.find((t: View) => t.id === payload.viewID)

    if (view) {
      Object.assign(view, {
        columns: view?.columns.map(c => ({ ...c, isHidden: false }))
      })
    }
  }
}

/**
 * @name tableNode/moveViewColumn
 * @param state
 * @param action payload {@link MoveViewColumnPayload}
 */
export function moveViewColumn (state: State, action: PayloadAction<MoveViewColumnPayload>): void {
  const { payload } = action
  if (payload.tableNodeID) {
    const tableNode = state.entities[payload.tableNodeID]
    if (tableNode) {
      const column = tableNode.views.find((t: View) => t.id === payload.viewID)
        ?.columns.find(c => c.id === payload.columnID)
      if (column) {
        column.order = payload.order
      }
    }
  }
}

/**
 * @name tableNode/updateColumn
 * @param state
 * @param action
 */
export function updateColumn (state: State, action: PayloadAction<UpdatedColumnPayload>): void {
  const { payload } = action

  const { tableNodeID, columnID, updatedFields, updatedCells, invalidedColumns } = payload
  if (tableNodeID) {
    const tableNode = state.entities[tableNodeID]

    if (tableNode) {
      _updateColumn(tableNode, { id: columnID, ...updatedFields })
    }

    if (updatedCells) {
      forEach(tableNode?.rows, row => {
        Object.assign(row.cells, updatedCells[row.id])
      })
    }

    forEach(invalidedColumns, id => {
      const index = findIndex(tableNode?.columns, c => c.id === id)
      const typeOptions = tableNode?.columns[index]?.typeOptions
      if (index !== -1 && typeOptions) {
        Object.assign(typeOptions, {
          ...typeOptions,
          valid: false
        })
      }
    })
  }
}

/**
 * @name tableNode/deleteColumn
 * @param state
 * @param action
 */
export function deleteColumn (state: State, action: PayloadAction<{ tableNodeID: number, columnID: string }>): void {
  const { payload } = action
  if (payload.tableNodeID) {
    const tableNode = state.entities[payload.tableNodeID]
    if (tableNode?.columns) {
      tableNode.columns = tableNode.columns.filter((column: Column) => column.id !== payload.columnID)
    }
  }
}

/**
 * @name tableNode/updateCells
 * @param state
 * @param action payload {@link TableNodeCells}
 */
export function updateCells (state: State, action: PayloadAction<TableNodeCells>): void {
  const { payload } = action
  if (payload.id) {
    const tableNode = state.entities[payload.id]
    if (tableNode) {
      _updateCells(tableNode, payload)
    }
  }
}

/**
 * @name tableNode/create
 * @param state
 * @param action payload {@link MapTableNode}
 */
export function create (state: State, action: PayloadAction<MapTableNode>): void {
  //
}

/**
 * @name tableNode/update
 * @param state
 * @param action payload {@link TableNodePatch}
 */
export function update (state: State, action: PayloadAction<TableNodePatch>): void {
  //
}

/**
 * @name tableNode/move
 * @param state
 * @param action payload {@link DND}
 */
export function move (state: State, action: PayloadAction<DND>): void {
  //
}

/**
 * @name tableNode/deleteRecycle
 * @param state
 * @param action payload
 */
export function deleteRecycle (state: State, action: PayloadAction<{
  projectID: number
  tableNodeID: number
}>): void {
  //
}

/**
 * @name tableNode/recoverRecycle
 * @param state
 * @param action payload {@link Project}
 */

export function recoverRecycle (
  state: State,
  action: PayloadAction<{
    projectID: number,
    recycleID: number,
    tableNodes: MapTableNode[],
    layerNodes: LayerNode[]
  }>
): void {
  //
}

/**
 * @name tableNode/moveViewRow
 * @param state
 * @param action payload {@link MoveViewRowPayload}
 */
export function moveViewRow (state: State, action: PayloadAction<MoveViewRowPayload>): void {
  const { payload } = action
  if (payload.tableNodeID) {
    const tableNode = state.entities[payload.tableNodeID]
    if (tableNode) {
      const view = tableNode.views.find((t: View) => t.id === payload.viewID)
      const newViewRows =
        view?.rows?.map(r => {
          const newOrder = payload.results[r.id]
          return { ...r, order: newOrder !== undefined ? newOrder : r.order }
        })
      Object.assign(view?.rows, newViewRows)
    }
  }
}

/**
 * @name tableNode/delete
 * @param state
 * @param action
 */
export function deleteNode (
  state: State,
  action: PayloadAction<{
    ids: number[],
    projectID: number,
    recycleTableNode: RecycleTableNode,
    layerNodeIDs: string[]
  }>): void {
  const { ids } = action.payload

  tableNodeAdapter.removeMany(state, ids)
  // TODO: handle delete for recycle
}

/**
 * @name tableNode/updateView
 * @param state
 * @param action payload {@link UpdateViewPayload}
 */
export function updateView (state: State, action: PayloadAction<UpdateViewPayload>): void {
  const { payload } = action
  if (payload.tableNodeID) {
    const view = state.entities[payload.tableNodeID]
      ?.views.find((t: View) => t.id === payload.viewID)

    if (view) {
      Object.assign(view, payload.updatedFields)
    }
  }
}

interface AttachmentPayload {
  tableNodeID: number;
  projectID: number;
  columnID: string;
  rowID: string;
  attachment: FileParams;
  fileID: string;
  name: string;
}

/**
 * @name tableNode/appendCellAttachment
 * @param state
 * @param action payload {@link AttachmentPaylad}
 */
export function appendCellAttachment (state: State, action: PayloadAction<AttachmentPayload>): void {
  const { tableNodeID, rowID, columnID, attachment } = action.payload
  const tableNode = state.entities[tableNodeID]
  const row = tableNode?.rows.find((r) => r.id === rowID)

  if (!row) return

  const cell = row?.cells[columnID] as FileParams[]

  if (cell) {
    cell.push(attachment)
  } else {
    row.cells[columnID] = [attachment]
  }
}

/**
 * @name tableNode/deleteCellAttachment
 * @param state
 * @param action payload {@link AttachmentPaylad}
 */
export function deleteCellAttachment (
  state: State,
  action: PayloadAction<AttachmentPayload>
): void {
  const { tableNodeID, rowID, columnID, fileID } = action.payload
  const tableNode = state.entities[tableNodeID]
  const row = tableNode?.rows.find((r) => r.id === rowID)

  if (!row) return

  const cell = row?.cells[columnID] as FileParams[]
  const index = findIndex(cell, c => c.fileID === fileID)

  if (index !== -1) {
    cell.splice(index, 1)
  }
}

/**
 * @name tableNode/updateAttachment
 * @param state
 * @param action payload {@link AttachmentPaylad}
 */
export function updateAttachment (state: State, action: PayloadAction<AttachmentPayload>): void {
  const { tableNodeID, rowID, columnID, fileID, name } = action.payload
  const tableNode = state.entities[tableNodeID]
  const row = tableNode?.rows.find((r) => r.id === rowID)

  if (!row) return

  const cell = row?.cells[columnID] as FileParams[]
  const index = findIndex(cell, c => c.fileID === fileID)

  if (index !== -1) {
    cell[index].fileName = name
  }
}


interface PasteCellsPayload {
  addedColumns: TableNodeColumnPatch[];
  updatedColumns?: {
    id: string;
    typeOptions: TypeOptions;
  }[];
  addedRows: TableNodeRowPatch[];
  tableNodeID: number;
  updatedRows: {
    id: string;
    cells: Record<string, unknown>
  }[]
}

/**
 * @name tableNode/pasteCells
 * @param state
 * @param action payload {@link PasteCellsPayload}
 */
export function pasteCells (state: State, action: PayloadAction<PasteCellsPayload>): void {
  const { updatedRows, tableNodeID, addedRows, addedColumns, updatedColumns } = action.payload
  const tableNode = state.entities[tableNodeID]

  if (!tableNode) return

  if (addedRows) {
    forEach(addedRows, r => {
      _createRow(tableNode, r)
    })
  }

  if (addedColumns) {
    forEach(addedColumns, c => {
      _createColumn(tableNode, c)
    })
  }

  if (updatedColumns) {
    forEach(updatedColumns, c => {
      _updateColumn(tableNode, c)
    })
  }

  forEach(updatedRows, updatedRow => {
    _updateCells(tableNode, {
      id: tableNodeID,
      rowID: updatedRow.id,
      updateCells: updatedRow.cells
    })
  })
}

/**
 * @ignore
 */
function updateSearchResults (state: State, action: PayloadAction<SearchResult[]>): void {
  state.searchResults = action.payload
}

/**
 * @ignore
 */
export const reducers = {
  setDetail,
  createRow,
  deleteRows,
  createColumn,
  updateColumn,
  deleteColumn,
  updateViewColumn,
  updateCells,
  create,
  update,
  move,
  moveViewRow,
  moveViewColumn,
  unhideAllViewColumns,
  delete: deleteNode,
  deleteRecycle,
  recoverRecycle,
  updateView,
  appendCellAttachment,
  deleteCellAttachment,
  updateAttachment,
  pasteCells,
  updateSearchResults
}

// TODO:
// 1. Normalize views
// 2. Normalize columns
// 3. Normalize rows
