import React from 'react'
import get from 'lodash/get'
import range from 'lodash/range'
import compact from 'lodash/compact'
import cx from 'clsx'
import { DatetimeRenderer } from 'src/components/field/Datetime'
import { NumberRenderer } from 'src/components/field/Number'
import { SingleLineTextRenderer } from 'src/components/field/SingleLineText'
import { transformToStandardTypeOptions } from 'src/hooks/field/useTableGrid'
import { TypeOptions } from 'src/typings/tableNode'
import Icon from 'src/components/common/Icon'
import { Props } from './TableRenderer'
import styles from './tablePreviewCellRenderer.module.scss'

// TODO: refactor renderField
export const renderField = (
  type: 'datetime' | 'number' | 'singleLineText', 
  value: string, 
  typeOptions: Partial<TypeOptions>
) : React.ReactElement => {
  switch (type) {
  case 'datetime':
    return <DatetimeRenderer value={value} typeOptions={typeOptions} />
  case 'number':
    return <NumberRenderer value={parseFloat(value)} typeOptions={typeOptions} />
  case 'singleLineText':
    return <SingleLineTextRenderer value={value} truncateLength={200}/>
  default:
    return (
      <div>
        {value as string}
      </div>
    )
  }
}

function getTableCellData (
  data: Record<string, unknown>[], 
  columns: TypeOptions['columns'],
  columnIndex: number, 
  rowIndex: number) {
  const column = columns[columnIndex]
  const typeOptions = transformToStandardTypeOptions(column)
  const type = column.type
  const header = column.name
  const value = get(data, `[${rowIndex}].${column.id}`)
  return {
    header,
    type,
    typeOptions,
    value
  }

}
export const TablePreviewCellRenderer: React.FC<Props> = (props) => {
  const firstRowData = compact(range(0, 5).map(columnIndex => {
    return props.typeOptions.columns[columnIndex] ? {
      ...getTableCellData(props.value, props.typeOptions.columns, columnIndex, 0)
    } : undefined
  }))
  const firstCell = firstRowData[0]

  return (
    <div className={styles.cellWrap}>
      { props.value &&
        <div className={styles.cell}>
          <Icon type='table2' className={styles.cellIcon}/>
          {renderField(firstCell.type, firstCell.value, firstCell.typeOptions)}
          {
            <div
              data-table="openModal"
              className={cx(styles.button, styles.rendererButton)}
            >
              <Icon type="expandArrow" />
            </div>
          }
        </div>
      }
      { props.value &&
      <div className={styles.previewContainer}>
        <div className={styles.preview}>
          {
            firstRowData.map((cell, index) => (
              <div className={styles.column} key={index}>
                <div className={styles.header}>
                  {cell.header}
                </div>
                <div className={styles.previewCell}>
                  {renderField(cell.type, cell.value, cell.typeOptions)}
                </div>
              </div>
            ))
          }
        </div>
      </div>
      }
    </div>
  )
}
