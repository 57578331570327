import { useRef } from 'react'
import cloneDeep from 'lodash/cloneDeep'
import omitBy from 'lodash/omitBy'
import isNil from 'lodash/isNil'
import find from 'lodash/find'
import { nanoid } from '@reduxjs/toolkit'
import findIndex from 'lodash/findIndex'
import type { TypeOptions } from 'src/typings/tableNode'
import type { Field } from 'src/components/grid/GridFieldConfig/GridFieldConfig'

type Column = TypeOptions['columns'][0]

export function transformToStandardTypeOptions (column: Column): Partial<Field['typeOptions']> {
  const { datetimeDateFormat, datetimeTimeFormat, numberPrecision, numberFormat } = column
  const typeOptions = {
    dateFormat: datetimeDateFormat,
    timeFormat: datetimeTimeFormat,
    precision: numberPrecision,
    format: numberFormat
  }

  return omitBy(typeOptions, isNil)
}

function transformToTableGrieTypeOptions (field: Field) {
  const { typeOptions } = field

  if (field.type === 'number') {
    return {
      numberPrecision: typeOptions.precision,
      numberFormat: typeOptions.format
    }
  } else if (field.type === 'datetime') {
    return {
      datetimeDateFormat: typeOptions.dateFormat,
      datetimeTimeFormat: typeOptions.timeFormat
    }
  } else {
    return typeOptions
  }
}

interface Return {
  addColumn: (prevColumnId: string, field: Field) => void;
  updateColumn: (columnId: string, field: Field) => void;
  transformToStandardTypeOptions: (column: Column) => ReturnType<typeof transformToStandardTypeOptions>;
  getColumns: () => Column[];
  insertColumn: (prevColumnId?: string) => void;
  removeColumn: (columnId: string) => void;
  moveColumn: (columnId: string, toIndex: number) => void;
}

export function useTableGridColumns (columnData: Column[]): Return {
  const internalColumns = useRef(cloneDeep(columnData))

  function addColumn (prevColumnId: string, field: Field, insert?: boolean) {
    const newColumn = Object.assign({
      id: nanoid(),
      name: field.name,
      type: field.type as TypeOptions['columns'][0]['type']
    }, transformToTableGrieTypeOptions(field))

    if (insert) {
      if (prevColumnId) {
        const index = findIndex(internalColumns.current, c => c.id === prevColumnId)
        index !== -1 && internalColumns.current.splice(index + 1, 0, newColumn)
      } else {
        internalColumns.current.unshift(newColumn)
      }
    } else {
      internalColumns.current.push(newColumn)
    }
  }

  function updateColumn (columnId: string, field: Field) {
    const index = findIndex(internalColumns.current, c => c.id === columnId)

    if (index !== -1) {
      const type = field.type ?? internalColumns.current[index].type
      Object.assign(internalColumns.current[index], {
        name: field.name,
        type
      }, transformToTableGrieTypeOptions({ ...field, type }))
    }
  }

  function insertColumn (prevColumnId?: string) {
    addColumn(prevColumnId as string, {
      name: '未命名',
      type: 'singleLineText'
    } as Field, true)
  }

  function removeColumn (columnId: string) {
    const index = findIndex(internalColumns.current, c => c.id === columnId)
    index !== -1 && internalColumns.current.splice(index, 1)
  }

  function moveColumn (columnId: string, toIndex: number) {
    const column = find(internalColumns.current, c => c.id === columnId)
    const toColumn = internalColumns.current[toIndex]

    if (!toColumn) {
      return
    }

    if (column) {
      removeColumn(columnId)
      internalColumns.current.splice(toIndex, 0, column)
    }
  }

  return {
    getColumns () {
      return [...internalColumns.current]
    },
    addColumn,
    transformToStandardTypeOptions,
    updateColumn,
    insertColumn,
    removeColumn,
    moveColumn
  }
}
