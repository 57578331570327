import { PayloadAction } from '@reduxjs/toolkit'
import { State } from 'src/state/user/slice'

export type IsAuth = boolean;

interface UserPatch {
  userID: number,
  updatedFields: {
    name: string,
    avatar?: string
  }
}

/**
 * @name user/setAuth
 * @param action payload {@link IsAuth}
 */
export function setAuth (state: State, action: PayloadAction<IsAuth>): State {
  return {
    ...state,
    isAuth: action.payload
  }
}

/**
 * @name org/update
 * @param state
 * @param action payload {@link UserPatch}
 */
export function update (state: unknown, action: PayloadAction<UserPatch>): void {
  //
}

/**
 * @ignore
 */
export const reducers = {
  setAuth,
  update
}
