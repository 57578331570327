import { TableNodePermisson } from 'src/constants/permissions'
import { ProjectPermission } from './permissions'

export const CATALOG__MAP_GROUP_NEW = [{
  action: 'createLayer',
  key: 'createLayer',
  name: '新建图层',
  permission: ProjectPermission.NewMapLayer
}, {
  action: 'createGroup',
  key: 'createGroup',
  name: '新建文件夹',
  permission: ProjectPermission.NewMapFolder
}]

export const CATALOG__MAP_GROUP_MENU = [{
  action: 'createLayer',
  key: 'createLayer',
  name: '新建图层',
  permission: ProjectPermission.NewMapLayer
}, {
  action: 'editGroup',
  key: 'editGroup',
  name: '修改名称',
  permission: ProjectPermission.MapFolderRename
}, {
  withDivider: true,
  action: 'deleteGroup',
  key: 'deleteGroup',
  name: '删除文件夹',
  type: 'danger',
  permission: ProjectPermission.MapFolderDelete
}]

export const CATALOG__MAP_ITEM_MENU = [{
  action: 'editItem',
  key: 'editItem',
  name: '修改名称',
  permission: ProjectPermission.MapLayerRename
}, {
  action: 'openLayerSetting',
  key: 'openLayerSetting',
  name: '图层设置',
  permission: ProjectPermission.MapLayerSetting
  // TODO: Version 2
  // }, {
  //   action: 'copyLayer',
  //   key: 'copyLayer',
  //   name: '复制图层'
}, {
  withDivider: true,
  action: 'deleteItem',
  key: 'deleteItem',
  name: '删除图层',
  type: 'danger',
  permission: ProjectPermission.MapLayerDelete
}]

export const CATALOG__TABLE_GROUP_NEW = [{
  action: 'createTableNode',
  key: 'createTableNode',
  name: '新建数据表',
  permission: ProjectPermission.NewTable
}, 
// {
//   action: 'createTableNodeFromTemplate',
//   key: 'createTableNodeFromTemplate',
//   name: '从模版新建',
//   permission: ProjectPermission.NewTableFromTemplate
// }, 
{
  action: 'createGroup',
  key: 'createGroup',
  name: '新建文件夹',
  permission: ProjectPermission.NewFolder
}, {
  withDivider: true,
  action: 'import',
  key: 'import',
  name: '智能导入',
  permission: ProjectPermission.ImportData
}]

export const CATALOG__TABLE_GROUP_MENU = [{
  action: 'editGroup',
  key: 'editGroup',
  name: '修改名称',
  permission: ProjectPermission.TableFolderRename
}, {
  action: 'createTableNode',
  key: 'createTableNode',
  name: '新建数据表',
  permission: ProjectPermission.NewTable
}, {
  withDivider: true,
  action: 'deleteGroup',
  key: 'deleteGroup',
  name: '删除文件夹',
  type: 'danger',
  permission: ProjectPermission.TableFolderDelete
}]

export const CATALOG__TABLE_ITEM_MENU = [{
  action: 'editItem',
  key: 'editItem',
  name: '修改名称',
  permission: TableNodePermisson.MenuItemRename
}, {
  action: 'editPermission',
  key: 'editPermission',
  name: '协作成员',
  permission: TableNodePermisson.MenuItemMember
}, 
// {
//   action: 'shareTableNode',
//   key: 'shareTableNode',
//   name: '分享',
//   permission: TableNodePermisson.MenuItemShare
// }, 
{
  action: 'duplicate',
  key: 'duplicate',
  name: '复制数据表',
  permission: TableNodePermisson.MenuItemDuplicate
}, 
{
  withDivider: true,
  action: 'deleteItem',
  key: 'deleteItem',
  name: '删除数据表',
  type: 'danger',
  permission: TableNodePermisson.MenuItemDelete
}]

export const PROJECT_SETTING_MENU = [{
  action: 'rename',
  key: 'rename',
  name: '修改名称',
  permission: ProjectPermission.ProjectRename
}, {
  action: 'cooperator',
  key: 'cooperator',
  name: '协作成员',
  permission: ProjectPermission.Collaborator
},
{ 
  action: 'duplicate',
  key: 'duplicate',
  name: '复制项目',
  permission: ProjectPermission.ProjectDuplicate
}, 
{
  type: 'divider',
  permission: ProjectPermission.ProjectDelete
}, {
  action: 'delete',
  key: 'delete',
  name: '删除',
  type: 'danger',
  permission: ProjectPermission.ProjectDelete
}]

export const PROJECT_BATCH_MENU = [
  {
    action: 'compare',
    key: 'compare',
    icon: 'gridCompare',
    name: '对比',
    disabled: false
  }, 
  // {
  //   action: 'duplicate',
  //   key: 'duplicate',
  //   icon: 'duplicate',
  //   name: '复制',
  //   permission: TableNodePermisson.DataEdit
  // }, 
  {
    withDivider: true,
    action: 'delete',
    key: 'delete',
    icon: 'recycleBin',
    permission: TableNodePermisson.DataEdit,
    name: '删除',
    type: 'danger'
  }]

export const PROJECT_LINE_HEIGHT_MENU = [{
  action: 'lineHeightSM',
  key: 'lineHeightSM',
  icon: 'lineHeightSM',
  name: '低'
}, {
  action: 'lineHeightMD',
  key: 'lineHeightMD',
  icon: 'lineHeightMD',
  name: '中等'
}, {
  action: 'lineHeightLG',
  key: 'lineHeightLG',
  icon: 'lineHeightLG',
  name: '高'
}, {
  action: 'lineHeightXL',
  key: 'lineHeightXL',
  icon: 'lineHeightXL',
  name: '超高'
}]
