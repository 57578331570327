import React, { useState } from 'react'
import filter from 'lodash/filter'
import last from 'lodash/last'
import includes from 'lodash/includes'
import { Tooltip, Popover, message } from 'antd'
import { PlusOutlined } from '@ant-design/icons'
import { IHeaderParams } from 'ag-grid-community'
import GridFieldConfig, { Field } from 'src/components/grid/GridFieldConfig/GridFieldConfig'
import { ADD_ROW_ID, ADD_COLUMN_ID, INDEX_COLUMN_ID } from 'src/constants/grid'
import type { ColumnType } from 'src/typings/tableNode'
import styles from './girdColumnAdder.module.scss'

interface Props extends IHeaderParams {
  onColumnAdd: (prevColumnId: string, field: Field) => void;
  enableFieldTypes?: ColumnType[];
}
const GridColumnAdder: React.FC<Props> = ({
  columnApi, 
  onColumnAdd,
  enableFieldTypes
}) => {
  const [visible, setVisible] = useState(false)

  function handleClick () {
    setVisible(true)
  }

  function handleSubmit (field: Field) {
    if (!field.name) {
      return message.error('请输入字段名称')
    }

    if (!field.type) {
      return message.error('请选择字段类型')
    }
    const prevColumnId = last(filter(columnApi.getAllGridColumns(), c => {
      return !includes([ADD_ROW_ID, ADD_COLUMN_ID, INDEX_COLUMN_ID], c.getColId())
    }))?.getColId()

    prevColumnId && onColumnAdd(prevColumnId, field)

    setVisible(false)
  }

  function handleCancel () {
    setVisible(false)
  }

  return (
    <Popover
      placement="bottom"
      content={
        <GridFieldConfig
          onSubmit={handleSubmit}
          onCancel={handleCancel}
          enableFieldTypes={enableFieldTypes}
        />
      }
      trigger="click"
      visible={visible}
      destroyTooltipOnHide={true}
    >
      <Tooltip placement="top" title={'添加字段'}>
        <div className={styles.column} onClick={handleClick}>
          <PlusOutlined className={styles.button} />
        </div>
      </Tooltip>
    </Popover>
  )
}

export default GridColumnAdder
