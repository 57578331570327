import React from 'react'
import { ArrowRightOutlined } from '@ant-design/icons'
import { useDispatch } from 'react-redux'
import { useHistory, Link } from 'react-router-dom'

import withLayout from 'src/hoc/withLayout'
import { login } from 'src/state/user/actions'
import { AppDispatch } from 'src/store'
import LoginAndSignupPage from 'src/components/loginAndSignup/LoginAndSignupPage'
import LoginForm from 'src/components/loginAndSignup/LoginForm/LoginForm'
import { useDocumentTitle } from 'src/hooks/common/useDocumentTitle'
import styles from 'src/components/loginAndSignup/LoginAndSignupPage/loginAndSignupPage.module.scss'

const LoginPage: React.FC = () => {
  const dispatch: AppDispatch = useDispatch()
  const history = useHistory()
  useDocumentTitle('登录')

  async function handleFormSubmit (values: Record<string, string>) {
    const { phoneNumber, code } = values
    await dispatch(login({
      phoneNumber,
      code
    }))

    history.push('/uw/')
  }

  return (
    <LoginAndSignupPage>
      <div className={styles.heading}>
        没有账号？<Link to="/signup">点击注册 <ArrowRightOutlined /></Link>
      </div>
      <div className={styles.sideContent}>
        <div className={styles.title}>登录系统</div>
        <div className={styles.message}>
          欢迎回到 MapTable，输入注册手机号码和验证码登录系统。
        </div>
        <LoginForm
          onSubmit={handleFormSubmit}
          submitButtonText="登录"
        />
        <div className={styles.footer}>
          注册即代表同意《服务条款》
        </div>
      </div>
    </LoginAndSignupPage>
  )
}

export default withLayout('full')(LoginPage)
