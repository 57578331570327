// action error https://redux-toolkit.js.org/api/createAsyncThunk#handling-thunk-errors
export class AuthError extends Error {
  code: string

  constructor (message: string) {
    super(message)

    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, AuthError)
    }
    this.name = 'AuthError'
    this.code = '401'
  }
}

export class NetworkError extends Error {
  code: string

  constructor (message: string) {
    super(message)

    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, NetworkError)
    }
    this.name = 'NetworkError'
    this.code = '503'
  }
}
