import { createSelector } from '@reduxjs/toolkit'
import type { RootState } from 'src/store'
import { CellCollaboratorType } from 'src/typings/collaborator'

export const selectCellCollaborators = createSelector<
  RootState,
  { cellId: string },
  Record<string, CellCollaboratorType[]>,
  string,
  CellCollaboratorType[]
>(
  (state, _) => state.collaborator.cellCollaborators,
  (_, props) => props.cellId,
  (cellCollaborators, cellId) => {
    return cellCollaborators[cellId]
  }
)
