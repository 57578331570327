import React, { forwardRef, useImperativeHandle, useState, useEffect, useRef } from 'react'
import noop from 'lodash/noop'
import type { ICellEditorParams } from 'ag-grid-community'
import { Input } from 'antd'

export interface Props extends ICellEditorParams {
  onBlur?: (value: number) => void;
  onPressEnter?: (value: string) => void;
  onChange?: (value: string) => void;
  inGrid?: boolean;
  inDetailPanel: boolean;
}

export const SingleLineTextEditor = forwardRef((props: Partial<Props>, ref) => {
  const { inGrid = false, inDetailPanel = false } = props
  const [value, setValue] = useState<string>(props.value)
  const { onBlur = noop, onPressEnter = noop, onChange } = props
  const inputRef = useRef<Input>(null)
  useImperativeHandle(ref, () => {
    return {
      getValue () {
        return value
      },
      afterGuiAttached () {
        if(props.charPress){
          setValue(props.charPress)
        }
        else if (props.value) {
          setValue(value)
        }
        inputRef.current?.focus()
      }
    }
  })

  useEffect(() => {
    onChange?.(value)
  }, [value, onChange])

  function handleBlur () {
    onBlur(value)
    props.stopEditing?.()
  }

  function handlePressEnter () {
    onPressEnter(value)
  }

  return (
    <Input
      ref={inputRef}
      style={{ width: '100%' }}
      autoFocus={inGrid || inDetailPanel}
      bordered={!inGrid}
      autoComplete="off"
      value={value}
      onChange={e => setValue(e.target.value)}
      onBlur={handleBlur}
      onPressEnter={handlePressEnter}
      onMouseDown={ev => ev.stopPropagation()}
    />
  )
})
