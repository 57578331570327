import { createAsyncThunk } from '@reduxjs/toolkit'
import last from 'lodash/last'
import { history } from 'src/utils/history'
import type { RootState } from 'src/store'

interface UndoRedoPayload {
  pathname: string;
}

export const undo = createAsyncThunk(
  'undo_async',
  async (payload: UndoRedoPayload, thunkAPI) => {
    const state = thunkAPI.getState() as RootState
    const undo = last(state.undoRedo.undo)

    if (undo?.undoAction) {
      await thunkAPI.dispatch(undo?.undoAction(undo.undoPayload))

      if (undo.pathname !== payload.pathname) {
        history.push(undo.pathname)
      }
    }
  }
)

export const redo = createAsyncThunk(
  'redo_async',
  async (payload: UndoRedoPayload, thunkAPI) => {
    const state = thunkAPI.getState() as RootState
    const redo = last(state.undoRedo.redo)

    if (redo?.redoAction) {
      await thunkAPI.dispatch(redo?.redoAction(redo.redoPayload))

      if (redo.pathname !== payload.pathname) {
        history.push(redo.pathname)
      }
    }
  }
)