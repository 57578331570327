import React, { useCallback, useEffect } from 'react'
import Draggable, { DraggableEventHandler } from 'react-draggable'
import cx from 'clsx'
import { useDispatch } from 'react-redux'
import type { LngLatLike } from 'qumap/packages/core/types/types'
import { QUMap as QUMapClass } from 'qumap/packages/core'
import useSelector from 'src/hooks/common/useSelector'
import { AppDispatch } from 'src/store'
import { InfoWindowPOIType } from 'src/typings/map'
import Icon from 'src/components/common/Icon'
import { actions as InfoWindowActions } from 'src/state/infoWindow/slice'
import { transformToTopLeft, getInfoWindowAnchorPosition } from 'src/utils/infoWindow'
import useInfoWindow from 'src/hooks/map/useInfoWindow'
import { actions as mapActions } from 'src/state/map/slice'
import { projectSelectors } from 'src/state/project/slice'
import { useParameters } from 'src/hooks/common/useParameters'
import FieldRender from 'src/components/detailPanel/FieldRender'
import { useOverlayEditor } from 'src/hooks/map/useMap'
import { TablePermissionWrapper } from 'src/components/common/PermissionWrapper'
import { TableNodePermisson } from 'src/constants/permissions'
import CompareButton from 'src/components/common/CompareButton'
import SmallDivider from 'src/components/common/SmallDivider'
import { useDraggableBounds } from 'src/hooks/common/useDraggable'
import { openDetialPanelEmitter } from 'src/utils/emitter'
import styles from './infoWindow.module.scss'

interface InfoWindowPorps {
  poi: InfoWindowPOIType
  quMap: React.RefObject<QUMapClass>
  onOpenSetting: (tableNodeId: number) => void
}

const InfoWindow: React.FC<InfoWindowPorps> = (props) => {
  const { poi, quMap, onOpenSetting } = props
  const dispatch: AppDispatch = useDispatch()
  const mapInstance = quMap.current as QUMapClass
  const { connectedLine, windowRef, cells } = useInfoWindow(poi, mapInstance)
  const {
    setIsEditorOpen
  } = useOverlayEditor()
  const { projectId } = useParameters()
  const { bounds, updateBounds } = useDraggableBounds()
  const visibleIdsSorted = useSelector(state => {
    const project = projectSelectors.selectById(state, projectId)
    return project?.tableNodes?.find(t => t.id === poi.extData?.tableNodeID)?.infoWindowConfig?.columnIDs
  })
  const layerNodeId = poi.extData?.layerID as string
  const rowId = poi.extData?.rowID as string
  const tableNodeId = poi.extData?.tableNodeID as number
  const cellsSorted = visibleIdsSorted?.map(id => cells?.find(c => c.id === id))
  const cellsForInfowWindow = cellsSorted && cellsSorted?.length > 0 ? cellsSorted : cells?.slice(0, 5)

  const handleClick = () => {
    dispatch(InfoWindowActions.add(poi))
  }

  const handleDrag = () => {
    if (windowRef.current && mapInstance) {
      const position = transformToTopLeft(windowRef.current)
      const center = getInfoWindowAnchorPosition(position, windowRef.current, mapInstance.amap)
      if (connectedLine) {
        connectedLine.setPath([
          center,
          poi.center
        ] as LngLatLike[])
      }
    }
  }
  const handleDrop = () => {
    if (windowRef.current) {
      const position = transformToTopLeft(windowRef.current)
      const newPoi = {
        ...poi,
        top: position.top,
        left: position.left
      }
      dispatch(InfoWindowActions.updatePoi(newPoi))
    }
  }
  const handleClose = (poi: InfoWindowPOIType) => {
    dispatch(InfoWindowActions.remove(poi.id))
    connectedLine && connectedLine.remove()
  }

  const handleOpenDetail = useCallback(() => {
    dispatch(mapActions.updatePoiDetail({
      visible: true,
      id: poi.id,
      poi,
      rowId: poi.extData?.rowID as string,
      tableNodeId: poi.extData?.tableNodeID as number,
      labelFieldId: poi.extData?.labelFieldID,
      layerNodeId: layerNodeId
    }))
  }, [dispatch, layerNodeId, poi])

  const handleEditOverlay = () => {
    setIsEditorOpen(true, poi)
  }

  const handleDragStart: DraggableEventHandler = (_, draggableData) => {
    updateBounds('.qu-map', draggableData, windowRef.current) 
    dispatch(InfoWindowActions.add(poi))
  }

  const simulateOpenDetail = useCallback((id: string) => {
    if (id === poi.id) {
      handleOpenDetail()
    } 
  }, [poi, handleOpenDetail])

  useEffect(() => {
    openDetialPanelEmitter.on(simulateOpenDetail)
    return () => openDetialPanelEmitter.off(simulateOpenDetail)
  })

  return (
    <Draggable
      bounds={bounds}
      handle={'.handle'}
      onStart={handleDragStart}
      onDrag={handleDrag}
      onStop={handleDrop}
      defaultPosition={{ x: poi.left || 100, y: poi.top || 100 }}
    >
      <div
        ref={windowRef}
        className={styles.infoWindow}
        onClick={handleClick}
        style={{
          zIndex: poi.order
        }}
      >
        <div className={styles.header}>
          <div className={cx(styles.title, 'handle')}>{poi.name}</div>
          <div className={styles.actions}>
            <TablePermissionWrapper
              id={poi.extData?.tableNodeID as number}
              role={TableNodePermisson.InfoWindowConfig}>
              <Icon type={'infoWindowSetting'} onClick={() => onOpenSetting(poi.extData?.tableNodeID as number)} />
              <SmallDivider light mh10/>
            </TablePermissionWrapper>
            <div
              onClick={(event) => {
                event.stopPropagation()
                handleClose(poi)
              }}>
              <Icon type={'close'} className={styles.close} />
            </div>
          </div>
        </div>
        <div className={styles.content}>
          {cellsForInfowWindow?.map((cell, index) => cell &&
              <div className={styles.item} key={index}>
                <div className={styles.label}>{cell.name}:</div>
                <FieldRender
                  className={cx(styles.text, {
                    [styles.select]: cell.type === 'singleChoice' || cell.type === 'multiChoice'
                  })}
                  item={cell}
                  withTooltip={cell.type === 'singleLineText' || cell.type === 'coordinate'}
                />
              </div>)}
        </div>
        <footer className={styles.footer}>
          <TablePermissionWrapper id={poi.extData?.tableNodeID as number} role={TableNodePermisson.DataEdit}>
            <div className={styles.footerItem} onClick={handleEditOverlay}>
              <Icon className={styles.icon} type={'coordinate'} />修改
            </div>
          </TablePermissionWrapper>
          {/* <div className={styles.footerItem}><Icon className={styles.icon} type={'star'} />收藏</div> */}
          <CompareButton 
            className={styles.footerItem} 
            layerNodeId={layerNodeId}
            rowId={rowId}
            tableNodeId={tableNodeId}
          />
          {/*<div className={styles.footerItem}><Icon className={styles.icon} type={'share2'} />分享</div>*/}
          <div className={cx(styles.footerItem, styles.detail)} onClick={handleOpenDetail}>查看详情
            <Icon
              className={styles.icon}
              type={'arrowRight2'}
            />
          </div>
        </footer>
        <div className={styles.anchor} />
      </div>
    </Draggable>
  )
}

export default InfoWindow
