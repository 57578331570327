import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import isEmpty from 'lodash/isEmpty'
import find from 'lodash/find'
import { getWorkspaces } from 'src/state/workspace/actions'
import { Workspace } from 'src/typings/workspace'
import useSelector from 'src/hooks/common/useSelector'
import { selectWorkspace } from 'src/selectors/workspaceSelector'
import type { WorkspaceType } from 'src/hooks/common/useParameters'

import { AppDispatch } from 'src/store'
import { workspaceSelectors } from 'src/state/workspace/slice'

interface Return {
  workspaces?: Workspace[];
  workspace?: Workspace;
  userWorkspaceId?: number;
}

export function useWorkspaces (workspaceType: WorkspaceType, workspaceId?: number): Return {
  const dispatch: AppDispatch = useDispatch()
  const history = useHistory()
  const workspaces = useSelector(workspaceSelectors.selectAll)
  const isAuth = useSelector(state => state.user.isAuth)
  const workspace = useSelector(state => selectWorkspace(state, {
    workspaceId,
    workspaceType
  }))
  const userWorkspaceId = useSelector(state => {
    return find(workspaceSelectors.selectAll(state), w => w.type === 'user')?.id
  })

  useEffect(() => {
    if (isEmpty(workspaces) && isAuth) {
      dispatch(getWorkspaces())
    }
  }, [dispatch, workspaces, isAuth])

  useEffect(() => {
    if (!isEmpty(workspaces) && !workspace) {
      history.push('/')
    }
  }, [workspace, workspaces, history])

  return {
    workspaces,
    workspace,
    userWorkspaceId
  }
}

export function useWorkspacesEffect (): void {
  const dispatch: AppDispatch = useDispatch()
  const workspaceRoles = useSelector(state => state.workspace.workspaceRoles)

  useEffect(() => {
    if (workspaceRoles) {
      dispatch(getWorkspaces())
    }
  }, [dispatch, workspaceRoles])
}
