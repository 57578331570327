import React, { useCallback } from 'react'
import { useHotkeys } from 'react-hotkeys-hook'
import { useLocation } from 'react-router'
import type { GridApi } from 'ag-grid-community'
import Sorting from 'src/components/project/Sorting'
import FieldConfig from 'src/components/project/FieldConfig'

import { Filter as FilterType } from 'src/constants/filter'
import { deleteRows } from 'src/state/tableNode/actions'
import { useParameters } from 'src/hooks/common/useParameters'
import useDispatch from 'src/hooks/common/useDispatch'
import { useGrid } from 'src/hooks/project/useGrid'
import { PROJECT_BATCH_MENU } from 'src/constants/menu'
import { TablePermissionWrapper } from 'src/components/common/PermissionWrapper'
import { TableNodePermisson } from 'src/constants/permissions'
import BatchActions from 'src/components/common/BatchActions'
import { useRowCompare } from 'src/hooks/common/useRowCompare'
import Search from 'src/components/grid/Search'
import SmallDivider from 'src/components/common/SmallDivider'
import ActionButton from 'src/components/project/ActionButton'
import useSelector from 'src/hooks/common/useSelector'
import * as undoRedoActions from 'src/state/undoRedo/actions'
import { UNDO, REDO } from 'src/constants/hotkeys'

interface Props {
  onFilterChange?: (filters: FilterType[]) => void;
  selectedRows?: string[];
  gridApi?: GridApi;
}

export const GridActions: React.FC<Props> = ({
  selectedRows,
  gridApi,
  children
}) => {
  const { tableNodeId, projectId } = useParameters()
  const location = useLocation()
  const { clearSelectionAndFocus } = useGrid()
  const dispatch = useDispatch()
  const { gotoComparePage } = useRowCompare()
  const undoItems = useSelector(state => state.undoRedo.undo)
  const redoItems = useSelector(state => state.undoRedo.redo)

  const removeSelection = () => {
    clearSelectionAndFocus(gridApi)
    selectedRows && dispatch(deleteRows({
      id: tableNodeId,
      rowIDs: selectedRows
    }))
  }

  const handleBatchMenuItemClick = (action: string) => {
    switch (action) {
    case 'delete':
      return removeSelection()
    case 'compare': 
      return gotoComparePage(tableNodeId, selectedRows)
    }
  }

  const undo = useCallback(() => {
    if (undoItems.length) {
      dispatch(undoRedoActions.undo({
        pathname: location.pathname
      }))
    }
  }, [undoItems.length, dispatch, location.pathname])

  const redo = useCallback(() => {
    if (redoItems.length) {
      dispatch(undoRedoActions.redo({
        pathname: location.pathname
      }))
    }
  }, [redoItems.length, dispatch, location.pathname])

  function handleUndoClick () {
    undo()
  }

  function handleRedoClick () {
    redo()
  }

  useHotkeys(UNDO, () => {
    undo()
  }, [projectId, undo])

  // TODO:
  // in cell editing should use browser default behaviour
  useHotkeys(REDO, (e) => {
    e.preventDefault()
    redo()
  }, [projectId, redo])

  return (
    <>
      <BatchActions 
        tableNodeId={tableNodeId}
        onActionClick={handleBatchMenuItemClick}
        actions={PROJECT_BATCH_MENU}
        selectedText={`已选中 ${selectedRows?.length} 行`}
        selectedItems={selectedRows}
      />
      <FieldConfig />
      {children}
      <TablePermissionWrapper role={TableNodePermisson.GridSorting}>
        <Sorting />
      </TablePermissionWrapper>
      {/* <TablePermissionWrapper role={TableNodePermisson.GridGrouping}>
        <ActionButton
          icontype={'grouping'}
          text={'分组'}
        />
      </TablePermissionWrapper> */}
      {/* <TablePermissionWrapper role={TableNodePermisson.GridRowHeight}>
        <DropdownMenu
          trigger={['hover']}
          actions={PROJECT_LINE_HEIGHT_MENU}>
          <ActionButton
            icontype={'lineHeight'}
            text={'行高'}
          />
        </DropdownMenu>
      </TablePermissionWrapper> */}
      <TablePermissionWrapper role={TableNodePermisson.DataEdit}>
        <SmallDivider light mh15 />
        <ActionButton
          disabled={undoItems.length === 0}
          iconbutton
          icontype={'undo'}
          onClick={handleUndoClick}
        />
        <ActionButton
          disabled={redoItems.length === 0}
          iconbutton
          icontype={'redo'}
          onClick={handleRedoClick}
        />
        <SmallDivider light mh15 />
      </TablePermissionWrapper>
      <TablePermissionWrapper role={TableNodePermisson.GridSearch}>
        <Search tableNodeId={tableNodeId} gridApi={gridApi} />
      </TablePermissionWrapper>
    </>
  )
}

export default GridActions
