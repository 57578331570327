export const collaboratorColors = [
  '#0376C2',
  '#07594A',
  '#076A66',
  '#077CB0',
  '#077E7A',
  '#078E82',
  '#07B195',
  '#098EC4',
  '#22B6ED',
  '#29409A',
  '#3384FF',
  '#33C6E1',
  '#4BA946',
  '#4C7020',
  '#4DC7EC',
  '#505F79',
  '#5EC298',
  '#69686D',
  '#6DADE2',
  '#7572A7',
  '#75C7B9',
  '#79BCE7',
  '#7ABF45',
  '#8476CD',
  '#89D2E3',
  '#9597CA',
  '#A7C299',
  '#A84D18',
  '#A8B7D8',
  '#B2103E',
  '#B295C5',
  '#B2D68C',
  '#B5A87F',
  '#B7E1FA',
  '#BBBFC2',
  '#C077AF',
  '#C41832',
  '#C7A7D2',
  '#D16F20',
  '#D2174A',
  '#D7DF3F',
  '#E55D3C',
  '#E9A519',
  '#EF342A',
  '#F45F7C',
  '#F57125',
  '#F68F26',
  '#F79D8B',
  '#F7B1BF',
  '#F8D29D',
  '#F99C45',
  '#FBAF37',
  '#FCAE62',
  '#FCBA5D',
  '#FFD233'
]
