import { useState } from 'react'
import isString from 'lodash/isString'
import type { DraggableData, DraggableBounds } from 'react-draggable'

interface Return {
  bounds?: DraggableBounds;
  updateBounds: (bound: HTMLElement | string, draggableData: DraggableData, target: HTMLUnknownElement | null) => void;
}
export function useDraggableBounds (): Return {
  const [bounds, setBounds] = useState<DraggableBounds>()

  function updateBounds (bound: HTMLElement | string, draggableData: DraggableData, target: HTMLUnknownElement | null) {
    const boundary = isString(bound) ? document.querySelector(bound) : bound
    const boundaryRect = boundary?.getBoundingClientRect()
    const targetRect = target?.getBoundingClientRect()

    if (targetRect && boundaryRect) {
      setBounds({
        left: boundaryRect.left + (-targetRect?.left + draggableData?.x),
        right: boundaryRect.right - (targetRect?.right - draggableData?.x),
        top: boundaryRect.top + (-targetRect?.top + draggableData?.y),
        bottom: boundaryRect.bottom - (targetRect?.bottom - draggableData?.y)
      })
    }
  }

  return {
    bounds,
    updateBounds
  }
}