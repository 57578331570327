import { useState, useCallback, useRef } from 'react'
import forEach from 'lodash/forEach'
import map from 'lodash/map'
import find from 'lodash/find'
import compact from 'lodash/compact'
import take from 'lodash/take'
import concat from 'lodash/concat'
import { useLayers } from 'src/hooks/map/useLayers'
import { GroupLayers, InfoWindowPOIType } from 'src/typings/map'
// eslint-disable-next-line @typescript-eslint/no-var-requires
const { Index } = require('flexsearch')

const LIMIT_RESULT_SIZE = 10

interface Return {
  search: (query: string) => void;
  initialSearchIndex: () => void;
  clear: () => void;
  results: SearchResult[];
  hiddenPoiIds: Set<string>;
}

interface RawSearchResult {
  id: string; 
  result: string[];
}

export interface SearchResult {
  layerId: string;
  layerName?: string;
  result: Array<{
    id?: string;
    name?: string;
    overlayType?: string;
    coordinates?: number[] | number[][];
    extData?: InfoWindowPOIType['extData']
  }>
}

interface SearchIndex {
  searchAsync: (query: string) => Promise<string[]>; 
  addAsync: (id: string, content: string) => void; 
}

function transformToSearchResults (rawResults: RawSearchResult[], layers: GroupLayers): SearchResult[] {
  return compact(map(rawResults, r => {
    const layer = find(layers.data, l => l.id === r.id)

    if (!layer) return
    
    const result = take(compact(map(r.result, poiId => {
      const poi = find(layer?.data, d => d.id === poiId)

      if (poi) {
        return {
          id: poi.id,
          name: poi.name,
          overlayType: poi.overlayType,
          coordinates: poi.coordinates,
          extData: poi.extData as InfoWindowPOIType['extData']
        }
      }
    })), LIMIT_RESULT_SIZE)

    return {
      layerId: r.id,
      layerName: layer?.name,
      result
    }
  }))
}

export function useSearch (projectId: number): Return {
  const [results, setResults] = useState<SearchResult[]>([])
  const searchIndex = useRef<Map<string, SearchIndex>>(new Map())
  const { layers, hiddenPoiIds } = useLayers(projectId)

  const initialSearchIndex = useCallback(() => {
    forEach(layers.data, layerData => {
      const index = new Index({
        tokenize: 'full',
        // more options https://github.com/nextapps-de/flexsearch#index-options
        // https://github.com/nextapps-de/flexsearch#cjk-word-break-chinese-japanese-korean
        encode: (str: string) => {
          // eslint-disable-next-line no-control-regex
          const cjk = str.replace(/[\x00-\x7F]/g, '').split('')
          const latin = str.toLowerCase().split(/[\p{Z}\p{S}\p{P}\p{C}]+/u)

          return concat(cjk, latin)
        }
      })

      forEach(layerData.data, d => {
        index.addAsync(d.id, d.name)
      })

      searchIndex.current.set(layerData.id, index)
    })
  }, [layers])

  const singleIndexSearch = async (index: SearchIndex, id: string, query: string) => {
    const result = await index.searchAsync(query)

    return {
      id,
      result
    }
  }

  const search = async (query: string) => {
    const searchIndexs: Promise<RawSearchResult>[] = []
    searchIndex.current.forEach((index, id) => {
      searchIndexs.push(singleIndexSearch(index, id, query))
    })

    const results = await Promise.all(searchIndexs)

    setResults(transformToSearchResults(results, layers))
  }

  const clear = () => {
    setResults([])
  }

  return {
    initialSearchIndex,
    search,
    clear,
    results,
    hiddenPoiIds
  }
}