import type { Props as QUMapProps } from 'qumap/packages/react/types/quMap/QUMap'
import { getCSSVariable } from 'src/utils/dom'

export const MAPOPTIONS: QUMapProps = {
  token: process.env.REACT_APP_GD_TOKEN,
  style: {
    height: '100%',
    minHeight: '400px'
  },
  options: {
    center: [113.936487, 22.516381],
    mapStyle: 'normal',
    zoom: 13
  }
}

export const MAX_INFOWINDOW_COUNT = 10
export const CONNECTED_POLYLINE_Z_INDEX = 400
export const CONNECTED_POLYLINE_STYLES = {
  strokeWeight: 1,
  strokeColor: '#0065ff',
  strokeOpacity: 1,
  isOutline: true,
  borderWeight: 0.5,
  outlineColor: '#ffffff'
}

export function getConnectedPolylineStyles (): Record<string, unknown> {
  return {
    ...CONNECTED_POLYLINE_STYLES,
    strokeColor: getCSSVariable('--primary-color')
  }
}
