import { flatten } from 'flat'
import chart from './chart.json'
import errors from './errors.json'
import project from './project.json'
import table from './table.json'
import map from './map.json'
import common from './common.json'
import search from './search.json'
import snapshot from './snapshot.json'


const chartMessages = flatten(chart) as Record<string, string>
const errorsMessages = flatten(errors) as Record<string, string>
const projectMessages = flatten(project) as Record<string, string>
const tableMessages = flatten(table) as Record<string, string>
const mapMessages = flatten(map) as Record<string, string>
const commonMessages = flatten(common) as Record<string, string>
const searchMessages = flatten(search) as Record<string, string>
const snapshotMessages = flatten(snapshot) as Record<string, string>

const message = {
  ...chartMessages,
  ...errorsMessages,
  ...projectMessages,
  ...tableMessages,
  ...mapMessages,
  ...commonMessages,
  ...searchMessages,
  ...snapshotMessages
}

export default message
