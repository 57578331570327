import React, { useState } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { Button, Form, Upload, Input } from 'antd'
import type { UploadRequestOption } from 'rc-upload/lib/interface'
import Avatar from 'src/components/common/Avatar'
import withLayout from 'src/hoc/withLayout'
import useDispatch from 'src/hooks/common/useDispatch'
import { updateUser, uploadUserAvatar } from 'src/state/user/actions'
import { useDocumentTitle } from 'src/hooks/common/useDocumentTitle'
import styles from './setup.module.scss'

const Setup: React.FC = () => {
  const [form] = Form.useForm()
  const history = useHistory()
  const dispatch = useDispatch()
  const [avatarUrl, setAvatarUrl] = useState<string>()
  useDocumentTitle('设置')

  async function handleFinish () {
    await dispatch(updateUser({
      name: form.getFieldValue('name'),
      avatar: avatarUrl
    }))
    history.push('/uw')
  }

  async function handleUpload (options: UploadRequestOption) {
    const { payload } = await dispatch(uploadUserAvatar({
      avatar: options.file as File
    }))
    setAvatarUrl(payload as string)
  }
  return (
    <div className={styles.page}>
      <div className={styles.head}>
        <div className={styles.title}>欢迎来到 MapTable</div>
        <div className={styles.subTitle}>设置头像和昵称，让大家认识你</div>
      </div>
      <div className={styles.content}>
        <Form
          form={form}
          requiredMark={false}
          colon={false}
          onFinish={handleFinish}
        >
          <Form.Item>
            <div className={styles.uploadField}>
              <Upload
                customRequest={handleUpload}
                showUploadList={false}
              >
                <Avatar size={120} src={avatarUrl} ringWidth={4}/>
                <Button type="link">{avatarUrl ? '重新上传' : '上传图片'}</Button>
              </Upload>
            </div>
          </Form.Item>
          <Form.Item name="name">
            <Input placeholder="输入昵称" autoComplete="off" size="large" />
          </Form.Item>
          <Form.Item className={styles.footer}>
            <Button block size="large" type="primary" htmlType="submit">
              进入 MapTable
            </Button>
            <Link to="/uw" className={styles.link}>跳过</Link>
          </Form.Item>
        </Form>
      </div>
    </div>
  )
}

export default withLayout('slim')(Setup)
