import { createAsyncThunk } from '@reduxjs/toolkit'
import FormulaAPI from 'src/api/formula'

export const getFormulaDocs = createAsyncThunk(
  'formula/docs_async',
  async () => {
    return await FormulaAPI.docs()
  }
)

export const checkFormula = createAsyncThunk(
  'formula/check_async',
  async (source: string) => {
    return await FormulaAPI.check(source)
  }
)
