import React from 'react'
import map from 'lodash/map'
import slice from 'lodash/slice'
import { Popover, Tooltip } from 'antd'
import useCollaborator from 'src/hooks/collaborator/useCollaborator'
import SmallDivider from 'src/components/common/SmallDivider'
import Avatar from 'src/components/common/Avatar'
import styles from './collaborator.module.scss'

const SHOW_COLLABORATOR_COUNT = 4
const Collaborator: React.FC = () => {
  const { otherCollaborators } = useCollaborator()

  const renderMoreCollaborators = () => {
    return map(slice(otherCollaborators, SHOW_COLLABORATOR_COUNT), collaborator => {
      return (
        <li key={collaborator.userID} className={styles.moreCollaboratorItem}>
          <div className={styles.avatar}>
            <Avatar src={collaborator.avatar} flagColor={collaborator.color} />
          </div>
          <span className={styles.name}>{collaborator.name}</span>
        </li>
      )
    })
  }

  return (
    <>
      {map(slice(otherCollaborators, 0, SHOW_COLLABORATOR_COUNT), collaborator =>
        <Tooltip key={collaborator.id} placement="bottom" title={collaborator.name}>
          <div className={styles.avatar}>
            <Avatar src={collaborator.avatar} flagColor={collaborator.color} />
          </div>
        </Tooltip>
      )}
      {otherCollaborators.length > SHOW_COLLABORATOR_COUNT && <div className={styles.avatarMore}>
        <Popover
          overlayClassName={styles.collaboratorsPopover}
          placement="bottom"
          content={
            <ul className={styles.collaboratorsList}>
              <li className={styles.header}>协作用户 <span>{otherCollaborators.length} 人</span></li>
              {renderMoreCollaborators()}
            </ul>
          }
        >+{otherCollaborators.length - SHOW_COLLABORATOR_COUNT}</Popover>
      </div>}
      {otherCollaborators && !!otherCollaborators.length && <div className={styles.divider}><SmallDivider /></div>}
    </>
  )
}

export default Collaborator
