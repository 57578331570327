import React from 'react'
import noop from 'lodash/noop'
import { QUMap as QUMapClass } from 'qumap/packages/core'

interface MapContextInterface {
  quMap: QUMapClass | null,
  setQuMap: React.Dispatch<React.SetStateAction<QUMapClass | null>>
}
export const MapContext = React.createContext<MapContextInterface>({
  quMap: null,
  setQuMap: noop
})