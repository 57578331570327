import React, { useState, useEffect } from 'react'
import { Button, Spin } from 'antd'
import { CheckOutlined } from '@ant-design/icons'
import flowRight from 'lodash/flowRight'
import map from 'lodash/map'
import cx from 'clsx'
import withLayout from 'src/hoc/withLayout'
import withWebSocket from 'src/hoc/withWebSocket'
import NotificationItem from 'src/components/common/NotificationItem'
import Header from 'src/components/workspace/WorkspaceHeader/Header'
import type { Mode } from 'src/api/notification'
import { useNotification } from 'src/hooks/common/useNotification'
import { useDocumentTitle } from 'src/hooks/common/useDocumentTitle'
import noNotification from 'src/assets/images/no-notification.svg'
import styles from './notification.module.scss'

export const Notification: React.FC = () => {
  useDocumentTitle('全部通知')
  const [mode, setMode] = useState<Mode>('newestFirst')
  const [page, setPage] = useState(1)
  const {
    notifications,
    fetchNotifications,
    isFirstFetching,
    markAsRead,
    markAllAsRead,
    loadMoreEnable,
    isFetching
  } = useNotification()

  useEffect(() => {
    fetchNotifications(page, mode)
  }, [page, mode, fetchNotifications])

  function handleSortClick (mode: Mode) {
    setPage(1)
    setMode(mode)
  }

  function handleMarkAllAsRead () {
    markAllAsRead()
  }

  function handleLoadMore () {
    const nextPage = page + 1

    setPage(nextPage)
  }

  function handleMarkAsReadClick (id: number) {
    markAsRead(id)
  }

  return (
    <div className={styles.page}>
      <Header />
      <div className={styles.wrap}>
        <div className={styles.header}>
          <div className={styles.title}>全部通知</div>
        </div>
        <div className={styles.content}>
          <div className={styles.notiHead}>
            <div className={styles.actions}>
              <div
                className={cx(styles.action, { [styles.active]: mode === 'newestFirst' })}
                onClick={() => handleSortClick('newestFirst')}
              >
                最新通知在前
              </div>
              <div
                className={cx(styles.action, { [styles.active]: mode === 'unreadFirst' })}
                onClick={() => handleSortClick('unreadFirst')}
              >
                未读通知在前
              </div>
            </div>
            <div className={styles.actions}>
              <div className={styles.notiHeaderLink} onClick={handleMarkAllAsRead}>
                <CheckOutlined />
                全部标记为已读
              </div>
            </div>
          </div>
          <div className={styles.notiContent}>
            {
              isFirstFetching
                ? <div className={styles.spin}><Spin size="small" /></div>
                : map(notifications, n => {
                  return <NotificationItem
                    key={n.id}
                    item={n}
                    className={styles.notiItem}
                    onMarkAsReadClick={handleMarkAsReadClick}
                  />
                })
            }
            {
              !isFirstFetching && !notifications?.length && <div className={styles.noNotification}>
                <img src={noNotification} alt="noNotification" />
                暂无通知
              </div>
            }
          </div>
          {
            loadMoreEnable && <div className={styles.notiFooter}>
              <Button
                className="gray-btn"
                type="default"
                onClick={handleLoadMore}
                disabled={isFetching}
              >
                加载更多通知
              </Button>
            </div>
          }
        </div>
      </div>
    </div>
  )
}

export default flowRight(
  withLayout('full'),
  withWebSocket
)(Notification)
