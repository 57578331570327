import React, { useEffect, useRef, useState } from 'react'
import findIndex from 'lodash/findIndex'
import { Menu } from 'antd'
import { SettingOutlined, ExclamationCircleOutlined } from '@ant-design/icons'
import { useParameters } from 'src/hooks/common/useParameters'
import { useDialogState, useConfirmDialog } from 'src/hooks/common/useDialog'
import Sider from 'src/components/common/Sider'
import MapSettingModal from 'src/components/map/MapSettingModal/MapSettingModal'
import LayerManagement from 'src/components/map/LayerManagement/LayerManagement'
import LayerSettingPanel from 'src/components/map/LayerSettingPanel'
import type { Project } from 'src/typings/project'
import useSelector from 'src/hooks/common/useSelector'
import { projectSelectors } from 'src/state/project/slice'
import { ProjectPermission } from 'src/constants/permissions'
import { useWorkspaceRoles } from 'src/hooks/workspace/useWorkspaceRoles'
import styles from './sider.module.scss'

interface Props {
  project?: Project
}

interface LayerSettingPanelRef {
  handleIgnoreChanges: () => void;
  handleFormSubmit: () => void;
}

const MapSider: React.FC<Props> = () => {
  const [dialogOpenedOnce, setDialogOpenedOnce] = useState(false)
  const { projectId } = useParameters()
  const { checkProjectPermission } = useWorkspaceRoles()
  const mapSettingModal = useDialogState(false)
  const [layerSettingVisible, setLayerSettingVisible] = useState(false)
  const [selectedLayerId, setSelectedLayerId] = useState<string | null>()
  const [isLayerSettingChanged, setLayerSettingChanged] = useState(false)
  const settingRef = useRef<LayerSettingPanelRef>(null)
  const mapConfig = useSelector(state => {
    return projectSelectors.selectById(state, projectId)?.mapConfig
  })
  const siderVisible = useSelector(state => state.project.siderVisible)
  const layerNodes = useSelector(state => state.project.layerNodes[projectId])
  const confirmDialog = useConfirmDialog({
    title: '图层设置未保存，是否保存修改内容？',
    icon: <ExclamationCircleOutlined />,
    okText: '保存',
    cancelText: '放弃保存',
    closable: false
  })

  useEffect(() => {
    if (checkProjectPermission(projectId, ProjectPermission.MapSetting)) {
      if (mapConfig && !mapConfig.isSet && !dialogOpenedOnce) {
        mapSettingModal.open()
        setDialogOpenedOnce(true)
      }
    }
  }, [projectId, checkProjectPermission, mapConfig, mapSettingModal, dialogOpenedOnce])

  useEffect(() => {
    const layerIndex = findIndex(layerNodes, n => n.id === selectedLayerId)
    if (layerIndex === -1) {
      setSelectedLayerId(null)
    }
  }, [layerNodes, selectedLayerId])

  const handleMapSettingClick = () => {
    mapSettingModal.open()
  }

  function handleLayerSettingClick (layerId: string) {
    setLayerSettingVisible(true)
    handleLayerSelect(layerId)
  }

  function handleLayerSelect (layerId: string) {
    /** will reset LayerSettingPanel state
     * HACK: Tower 任务: #前端 地图图层设置中修改填充样式的「颜色数量」会影响其他使用了同一数据表创建的其他图层 ( https://tower.im/teams/858915/todos/5751 )
     */
    function switchLayer () {
      setLayerSettingChanged(false)
      setSelectedLayerId(null)
      setTimeout(() => {
        setSelectedLayerId(layerId)
      }, 0)
    }

    if (selectedLayerId !== layerId && isLayerSettingChanged) {
      confirmDialog().then(async () => {
        await settingRef.current?.handleFormSubmit()
        switchLayer()
      }).catch(() => {
        settingRef.current?.handleIgnoreChanges()
        switchLayer()
      })
    } else {
      switchLayer()
    }
  }

  return (
    <>
      <Sider isFloat={true}>
        <LayerManagement
          onLayerSettingClick={handleLayerSettingClick}
          onLayerDeleted={() => setSelectedLayerId(null)}
          onLayerSelected={handleLayerSelect}
          selectedLayerId={selectedLayerId}
        />
        <div className={styles.sideBottom}>
          <div className={styles.divider} />
          <Menu selectable={false} className={styles.menu}>
            {checkProjectPermission(projectId, ProjectPermission.MapSetting) &&
            <Menu.Item icon={<SettingOutlined />} onClick={handleMapSettingClick}>
              地图设置
            </Menu.Item>}
            {/* {checkProjectPermission(projectId, ProjectPermission.SiderHelp) &&
             <Menu.Item icon={<QuestionCircleOutlined />}>
             使用帮助
             </Menu.Item>} */}
          </Menu>
        </div>
        <MapSettingModal
          dialog={mapSettingModal}
        />
      </Sider>
      {
        selectedLayerId && <LayerSettingPanel
          ref={settingRef}
          layerId={selectedLayerId}
          siderVisible={siderVisible}
          visible={layerSettingVisible}
          onClose={() => setLayerSettingVisible(false)}
          onChange={(cancelChange?: boolean) => setLayerSettingChanged(!cancelChange)}
        />
      }
    </>
  )
}

export default MapSider
