import React, { ReactElement, useState } from 'react'
import cx from 'clsx'
import { UploadChangeParam } from 'antd/lib/upload/interface'
import isEmpty from 'lodash/isEmpty'
import split from 'lodash/split'
import { Modal, Input, Button, Checkbox, Upload } from 'antd'
import {
  LeftOutlined
} from '@ant-design/icons'
import type { CheckboxChangeEvent } from 'antd/lib/checkbox'
import type { UploadRequestOption } from 'rc-upload/lib/interface'
import type { DialogStateReturn as Dialog } from 'src/hooks/common/useDialog'
import upload from 'src/assets/images/upload.png'
import uploadCloud from 'src/assets/images/uploadCloud.svg'
import csv from 'src/assets/images/csv.svg'
import xlsx from 'src/assets/images/xlsx.svg'
import geojson from 'src/assets/images/geojson.svg'
import shapefile from 'src/assets/images/shapefile.svg'
import copy from 'src/assets/images/copy.png'
import { importTableNode, uploadTableNodeFile } from 'src/state/tableNode/actions'
import useDispatch from 'src/hooks/common/useDispatch'
import { useParameters } from 'src/hooks/common/useParameters'
import { ImportProps } from 'src/api/tablenodes'
import Icon from 'src/components/common/Icon'
import LoadingImg from 'src/assets/icons/loading.svg'
import styles from './dataImportModal.module.scss'

const { TextArea } = Input

interface ChoiceProps {
  onCopyClick: () => void;
  onUpload: () => void;
}

type UploadProps = Pick<ImportProps, 'importOption' | 'name' | 'type' | 'url'>

const importTypes: Record<string, string> = {
  xlsx: 'excel',
  xls: 'excel',
  csv: 'csv',
  geojson: 'geoJSON'
}

const Choice: React.FC<ChoiceProps> = ({
  onCopyClick,
  onUpload
}) => {
  return (
    <div className={styles.choices}>
      <div className={styles.choice} onClick={onUpload}>
        <img src={upload} alt="upload" />
        <div className={styles.choiceTitle}>上传本地文件</div>
        <div>（支持CSV、XLSX、GeoJSON、Shapefile格式）</div>
      </div>
      <div className={styles.choice} onClick={onCopyClick}>
        <img src={copy} alt="copy" />
        <div className={styles.choiceTitle}>粘贴表格数据</div>
        <div>适用于导入部分数据</div>
      </div>
    </div>
  )
}

interface UploadFileProps {
  onBackClick: () => void;
  onImportClick: (importProps: UploadProps) => void;
  onCancelClick: () => void;
}

const DataInput: React.FC<UploadFileProps> = ({
  onBackClick,
  onImportClick,
  onCancelClick
}) => {
  const dispatch = useDispatch()
  const [input, setInput] = useState<string>('')
  const [checked, setChecked] = useState(true)

  function handleInputChange (e: React.ChangeEvent<HTMLTextAreaElement>) {
    setInput(e.target.value)
  }

  function handleCheckBoxChange (e: CheckboxChangeEvent) {
    setChecked(e.target.checked)
  }

  async function handleImport () {
    const blob = new Blob(['\ufeff', input])
    const { payload } = await dispatch(uploadTableNodeFile({ file: blob as File }))
    onImportClick({
      name: '新建数据表',
      type: 'csv',
      importOption: {
        firstRowAsHeader: checked,
        csvCommaType: 'tab'
      },
      url: payload as string
    })
  }

  return (
    <div className={styles.dataInput}>
      <header className={styles.header}>
        <div className={styles.back} onClick={onBackClick}><LeftOutlined /> 返回</div>
      </header>
      <div>复制表格数据，粘贴（ctrl + v）到下方快速导入</div>
      <TextArea className={styles.textarea} onChange={handleInputChange} />
      <Checkbox checked={checked} onChange={handleCheckBoxChange}>以粘贴数据的第一行作为字段标题</Checkbox>
      <div className={styles.dataInputFooter}>
        <Button type="primary" onClick={handleImport} disabled={isEmpty(input)}>导入</Button>
        <Button onClick={onCancelClick}>取消</Button>
      </div>
    </div>
  )
}

const UploadFile: React.FC<UploadFileProps> = ({
  onBackClick,
  onImportClick,
  onCancelClick
}) => {
  const dispatch = useDispatch()
  const [checked, setChecked] = useState(true)
  const [uploading, setUploading] = useState(false)
  const [fileCount, setFileCount] = useState(0)
  const [importParams, setImportParams] = useState<UploadProps>()

  function handleCheckBoxChange (e: CheckboxChangeEvent) {
    setChecked(e.target.checked)
    setImportParams({
      ...importParams,
      importOption: {
        firstRowAsHeader: e.target.checked
      }
    } as UploadProps)
  }

  async function handleCustomRequest (options: UploadRequestOption) {
    const file = options.file as File
    const name = split(file.name, '.')
    const extName = name.pop() || 'csv'

    setUploading(true)
    const { payload } = await dispatch(uploadTableNodeFile({ file }))
    setUploading(false)

    // FIXME: 根据需求，如果上传的是excel，需要将name设置为excel内第一个sheet的sheetname。
    //  由于需要引入第三方库来读取这个sheetname，暂时先直接使用文件名代替。
    //  将来需要导入多个sheet的时候再做修改。
    setImportParams({
      importOption: {
        firstRowAsHeader: checked
      },
      name: name.join('.'),
      type: importTypes[extName],
      url: payload as string
    })
  }

  async function handleUpload () {
    importParams && onImportClick(importParams)
  }

  function handleUploadChange (info: UploadChangeParam) {
    setFileCount(info.fileList.length)
  }

  function handleItemRender (originNode: ReactElement) {
    return <div className={styles.fileItem}>
      <span className={styles.progress}>
        {uploading && <Icon type={'operationSaving'} className={styles.loading} />}
        <img src={csv} alt="csv" />
      </span>
      <span className={styles.fileName}>{originNode}</span>
    </div>
  }

  return (
    <div className={styles.uploadFile}>
      <header className={styles.header}>
        <div className={styles.back} onClick={onBackClick}><LeftOutlined /> 返回</div>
      </header>
      <div className={styles.checkbox}>
        <Checkbox checked={checked} onChange={handleCheckBoxChange}>将第一行数据作为字段标题</Checkbox>
      </div>
      <div className={cx(styles.uploadWrapper, { [styles.uploading]: uploading || fileCount })}>
        <Upload.Dragger
          className={styles.upload}
          accept=".csv,.xlsx,.geojson,.json"
          customRequest={handleCustomRequest}
          showUploadList={{
            showPreviewIcon: true
          }}
          itemRender={handleItemRender}
          multiple={false}
          onChange={handleUploadChange}
        >
          <div className={styles.uploadContent}>
            <div className={styles.acceptList}>
              <img src={csv} alt="csv" />
              <img src={xlsx} alt="xlsx" />
              <img src={geojson} alt="geojson" />
              <img src={shapefile} alt="shapefile" />
            </div>
            <div className={styles.choiceTitle}>
              <img src={uploadCloud} alt="上传" />点击上传或拖拽文件在此处
            </div>
            <div>支持CSV、XLSX、GeoJSON、Shapefile格式</div>
          </div>
        </Upload.Dragger>
      </div>
      {!!fileCount && <div className={styles.dataInputFooter}>
        <Button
          type="primary"
          onClick={handleUpload}
          disabled={uploading}
        >确定</Button>
        <Button onClick={onCancelClick}>取消</Button>
      </div>}
    </div>
  )
}

interface Props {
  dialog: Dialog
}

const DataImportModal: React.FC<Props> = ({
  dialog
}) => {
  const dispatch = useDispatch()
  const { projectId } = useParameters()
  const [step, setStep] = useState(0)
  const [loading, setLoading] = useState(false)

  function handleCopyClick () {
    setStep(4)
  }

  function handleClose () {
    setStep(0)
  }

  function handleBackClick () {
    setStep(0)
  }

  async function handleImportClick (importProps: UploadProps) {
    setLoading(true)
    if (importProps.url) {
      const { payload } = await dispatch(importTableNode({ ...importProps, projectID: projectId } as ImportProps))

      if (payload) {
        dialog.close()
      }
    }
    setLoading(false)
  }

  function handleUpload () {
    setStep(3)
  }

  function handleCancelClick () {
    dialog.close()
  }

  return (
    <Modal
      afterClose={handleClose}
      onCancel={() => dialog.close()}
      visible={dialog.visible}
      title={null}
      width={500}
      closable={false}
      footer={null}
    >
      {step === 0 && <header className={styles.header}>智能导入</header>}
      {
        step === 0 && <Choice
          onUpload={handleUpload}
          onCopyClick={handleCopyClick}
        />
      }
      {
        step === 3 && <UploadFile
          onBackClick={handleBackClick}
          onImportClick={handleImportClick}
          onCancelClick={handleCancelClick}
        />
      }
      {
        step === 4 && <DataInput
          onBackClick={handleBackClick}
          onImportClick={handleImportClick}
          onCancelClick={handleCancelClick}
        />
      }
      {loading && <div className={styles.importing}><img src={LoadingImg} alt="Loading..." /></div>}
    </Modal>
  )
}

export default DataImportModal
