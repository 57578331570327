import React, { ComponentType } from 'react'
import Loading from 'src/components/common/Loading'
import { useInfiniteRenderDebug } from 'src/hooks/common/useDebug'
import {
  useReadyState,
  useNoAuthEffect,
  useWorkspaceWebSocketEffect,
  useWorkspaceRolesEffect
} from 'src/hooks/workspace/useWorkspaceWebsocket'

function withWebSocket (Comp: ComponentType): ComponentType {
  const ComponentWithWebSocket: ComponentType = (props) => {
    const { 
      appInitialized,
      isReady
    } = useReadyState()

    useNoAuthEffect()

    useWorkspaceWebSocketEffect()

    useWorkspaceRolesEffect()

    useInfiniteRenderDebug('ComponentWithWebSocket')

    return appInitialized ? <><Comp {...props} /><Loading hide={isReady} /></> : <Loading />
  }

  return ComponentWithWebSocket
}

export default withWebSocket
