import { useDispatch as useReduxDispatch } from 'react-redux'
import type { AppDispatch } from 'src/store'

function useDispatch (): AppDispatch {
  const dispatch: AppDispatch = useReduxDispatch()

  return dispatch
}


export default useDispatch
