import { PayloadAction } from '@reduxjs/toolkit'
import { Workspace } from 'src/typings/workspace'

interface OrgPatch {
  ID: number,
  updatedFields: {
    name: string,
    avatar?: string
  }
}

/**
 * @name org/create
 * @param state
 * @param action payload {@link Workspace}
 */
export function create (state: unknown, action: PayloadAction<Workspace>): void {
  //
}

/**
 * @name org/update
 * @param state
 * @param action payload {@link OrgPatch}
 */
export function update (state: unknown, action: PayloadAction<OrgPatch>): void {
  //
}


/**
 * @ignore
 */
export const reducers = {
  create,
  update
}
