import React, { useEffect, useImperativeHandle, useState, useRef } from 'react'
import { Form, Input, Button, Popover } from 'antd'
import type { FormInstance } from 'antd/lib/form/hooks/useForm'
import { List as MovableList } from 'react-movable'
import type { IItemProps } from 'react-movable'
import { PlusOutlined } from '@ant-design/icons'
import noop from 'lodash/noop'
import sample from 'lodash/sample'
import isEmpty from 'lodash/isEmpty'
import trim from 'lodash/trim'
import cx from 'clsx'
import { PICKER_COLORS, Color } from 'src/constants/colors'
import Icon from 'src/components/common/Icon'
import styles from './selectConfig.module.scss'

export interface ChoiceItem {
  name: string;
  color: string
}

interface Props {
  selectOptions?: ChoiceItem[]
  form?: React.Ref<FormInstance>
}

interface ColorPickerProps {
  value?: Color
  onChange?: (value: Color) => void
}

const ColorPickerPopover: React.FC<ColorPickerProps> = (Props) => {
  const { onChange = noop, value } = Props
  const [visible, setVisible] = useState(false)
  const [currentColor, setCurrentColor] = useState<Color>(value)
  useEffect(() => {
    setCurrentColor(value)
  }, [value])

  function handleSelectColor (colorName: Color) {
    setCurrentColor(colorName)
    onChange(colorName)
    setVisible(false)
  }

  const ColorPickerContent = () => {
    const colorsArray = Object.entries(PICKER_COLORS).map(item => ({ name: item[0] as Color, color: item[1] }))
    return (
      <div className={styles.colorPalette}>
        {
          colorsArray.map((item, index) => {
            return <React.Fragment key={index}>
              <div
                className={cx(styles.color, item.name === currentColor && styles.active)} 
                style={{ background: item.color }}
                onClick={() => handleSelectColor(item.name)} />
              {index === 9 && <div className={styles.blankRow}/>}
            </React.Fragment>
          }
          )
        }
      </div>
    )
  }
  return (
    <Popover
      content={ColorPickerContent}
      placement="left"
      visible={visible}
      trigger={'click'}
      onVisibleChange={setVisible}
    >
      <div
        className={styles.colorPickerButton}
        style={{ background: currentColor && PICKER_COLORS[currentColor] }}
        onClick={() => setVisible(true)}
      >
        <div className={styles.colorPickerDropdown}></div>
      </div>
    </Popover>
  )
}

interface MovableItemProps extends IItemProps {
  children: React.ReactElement
}

const MovableItem = React.forwardRef(({ children, ...props }: MovableItemProps, ref: React.Ref<HTMLDivElement>) => (
  <div ref={ref} {...props} className={styles.movableItem}>
    {children}
  </div>
))
export const SelectConfig: React.FC<Props> = ({
  selectOptions,
  form
}) => {
  const [innerForm] = Form.useForm()
  const formList = useRef<HTMLElement>()
  useEffect(() => {
    if (!isEmpty(selectOptions)) {
      innerForm.setFieldsValue({
        choices: selectOptions
      })
    }
  }, [innerForm, selectOptions])

  function getInput (index: number) {
    return formList.current?.querySelectorAll('input')?.[index]
  }
  // TODO: form validation
  useImperativeHandle(form, () => innerForm)

  return (
    <Form form={innerForm} autoComplete="off">
      <Form.List name="choices" initialValue={[{ color: 'redLight', name: '' }]}>
        {(fields, { add, remove, move }) => (
          <>
            <MovableList
              lockVertically
              values={fields}
              onChange={({ oldIndex, newIndex }) =>
                move(oldIndex, newIndex)
              }
              renderList={({ children, props }) => {
                formList.current = props.ref.current
                return <ul {...props} className={styles.list}>
                  {children}
                </ul>
              }}
              renderItem={({ value, props }) =>
                <MovableItem {...props}>
                  <>
                    <Button
                      type="text"
                      size="small"
                      data-movable-handle
                      icon={<Icon type="movable" className={styles.icon} />}
                    />
                    <Form.Item
                      noStyle
                      name={[value.name, 'color']}
                      fieldKey={[value.fieldKey, 'color']}
                    >
                      <ColorPickerPopover />
                    </Form.Item>
                    <Form.Item
                      noStyle
                      name={[value.name, 'name']}
                      fieldKey={[value.fieldKey, 'name']}
                    >
                      <Input 
                        placeholder="请输入选项" 
                        className={styles.input} 
                        onPressEnter={(e) => {
                          if(isEmpty(trim(e.currentTarget.value))) return

                          const nextInputIndex = (props?.key ?? 0) + 1
                          const nextInput = getInput(nextInputIndex)

                          if (nextInput) {
                            nextInput.focus()
                          } else {
                            add({ color: sample(Object.keys(PICKER_COLORS)) })

                            setTimeout(() => {
                              const nextInput = getInput(nextInputIndex)
                              nextInput?.focus()
                            })
                          }
                        }} 
                      />
                    </Form.Item>
                    <Button
                      type="text"
                      size="small"
                      onClick={() => remove(value.name)}
                      icon={<Icon type="close" className={styles.icon} />}
                    />
                  </>
                </MovableItem>
              }>
            </MovableList>
            <Form.Item>
              <Button
                icon={<PlusOutlined />}
                size="small"
                type="link"
                onClick={() => add({ color: sample(Object.keys(PICKER_COLORS)) })}
              >
                新建选项
              </Button>
            </Form.Item>
          </>
        )}
      </Form.List>
    </Form>
  )
}
