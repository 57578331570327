import { ReactText, useState } from 'react'
import { DataNode, EventDataNode } from 'antd/lib/tree'
import { TreeNodeProps } from 'rc-tree/lib/TreeNode'
import { TreeNode } from 'src/typings/tree'

type NodeDragEventParams = {
  event: React.MouseEvent<HTMLDivElement>;
  node: EventDataNode & {
    type?: string
    props?: TreeNodeProps
  }
}
type infoType = NodeDragEventParams & {
  dragNode: EventDataNode;
  dragNodesKeys: React.Key[];
  dropPosition: number;
  dropToGap: boolean;
}

interface Return {
  onDrop: (info: infoType) => void
  onDragStart: (info: NodeDragEventParams) => void
  allowDrop: (options: {
    dropNode: DataNode;
    dropPosition: -1 | 0 | 1;
  }) => boolean
}

export default function useDragDrop (
  treeData: TreeNode[] | undefined,
  setTreeData: React.Dispatch<React.SetStateAction<TreeNode[]>>,
  onDND?: (fromNode: TreeNode, toNode?: TreeNode, parentNode?: TreeNode) => void
): Return {
  const [draggingType, setDraggingType] = useState<TreeNode['type']>()
  const onDrop = async (info: infoType) => {
    const fromNode = info.dragNode as unknown as TreeNode
    const toNode = info.node as NodeDragEventParams['node']

    if (!info.dropToGap) {
      // Drop on the content
      if (fromNode.type === 'group' || (fromNode.type === toNode.type)) {
        return false
      }
    }
    const dropKey = toNode.key
    const dragKey = fromNode.key
    const dropPos = info.node.pos.split('-')
    const dropPosition = info.dropPosition - Number(dropPos[dropPos.length - 1])

    const loop = (
      data: TreeNode[],
      key: ReactText,
      callback: (item: TreeNode, index: number, arr: TreeNode[], parentNode?: TreeNode) => void,
      parentNode?: TreeNode
    ) => {
      for (let i = 0; i < data.length; i++) {
        if (data[i].key === key) {
          return callback(data[i], i, data, parentNode)
        }
        if (data[i].children) {
          loop(data[i].children || [], key, callback, data[i])
        }
      }
    }

    const data = treeData ? [...treeData] : []

    // Find dragObject
    let dragObj: TreeNode | undefined = undefined
    loop(data, dragKey, (item, index, arr) => {
      arr.splice(index, 1)
      dragObj = item
    })

    if (!info.dropToGap) {
      // Drop on the content
      loop(data, dropKey, async item => {
        item.children = item.children || []
        // where to insert 示例添加到头部，可以是随意位置
        if (dragObj) {
          item.children.unshift(dragObj)
          onDND?.(fromNode, undefined, item)
        }
      })
    } else if (
      (toNode.props?.children as unknown as TreeNode[] || []).length > 0 && // Has children
      toNode.props?.expanded && // Is expanded
      dropPosition === 1 // On the bottom gap
    ) {
      loop(data, dropKey, async item => {
        item.children = item.children || []
        // where to insert 示例添加到尾部，可以是随意位置
        if (dragObj) {
          item.children.unshift(dragObj)
          onDND?.(fromNode, [...item.children].pop(), item)
        }
        // in previous version, we use item.children.push(dragObj) to insert the
        // item to the tail of the children
      })
    } else {
      let ar: TreeNode[] = []
      let parent: TreeNode | undefined = undefined
      let i = NaN

      loop(data, dropKey, (item, index, arr, parentNode) => {
        ar = arr
        i = index
        parent = parentNode
      })

      if (ar && !isNaN(i) && dragObj) {
        if (dropPosition === -1) {
          ar.splice(i, 0, dragObj)
          let prevNode = undefined
          if (!i) {
            prevNode = ar[i - 1]
          }
          onDND?.(fromNode, prevNode, parent)
        } else {
          ar.splice(i + 1, 0, dragObj)
          onDND?.(fromNode, toNode as unknown as TreeNode, parent)
        }
      }
    }

    setTreeData(data)
  }
  const onDragStart = (info: NodeDragEventParams) => {
    setDraggingType((info.node as unknown as TreeNode).type)
  }
  const allowDrop = (options: {
    dropNode: DataNode;
    dropPosition: -1 | 0 | 1;
  }) => {
    const { dropNode, dropPosition } = options
    const data = dropNode as TreeNode
    if (draggingType === 'group' && (data.type === 'group' || data.parentID) && dropPosition === 0) {
      return false
    }
    if (data.type !== 'group' && dropPosition === 0) {
      return false
    }
    return true
  }
  return {
    onDrop,
    onDragStart,
    allowDrop
  }
}
