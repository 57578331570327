import React, { useRef, useState, useEffect, useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { Popover } from 'antd'
import map from 'lodash/map'
import keys from 'lodash/keys'
import noop from 'lodash/noop'
import get from 'lodash/get'
import cx from 'clsx'
import { checkFormula } from 'src/state/formula/actions'
import useContentEditor from 'src/hooks/common/useContentEditor'
import useFormula from 'src/hooks/common/useFormula'
import { Column } from 'src/typings/tableNode'
import { FormulaType } from 'src/typings/formula'
import Icon, { IconType } from 'src/components/common/Icon'
import styles from './formulaExpressionEditor.module.scss'

interface Props {
  formula?: string
  onChange?: (formula: string) => void
}

const FormulaExpressionEditor: React.FC<Props> = (props) => {
  const dispatch = useDispatch()
  const { formula = '', onChange = noop } = props
  const editorRef = useRef<HTMLDivElement>(null)
  const [selection, setSelection] = useState(0)
  const {
    range,
    autoCompleteText,
    formulaExpression,
    insertNode,
    removeNode
  } = useContentEditor(editorRef, selection, formula)
  const [expression, setExpression] = useState(formula)
  const {
    formulaGroups,
    computableColumns,
    getFieldNode,
    getFormulaNode
  } = useFormula(autoCompleteText)
  const [isValid, setIsValid] = useState(true)
  const [errorMessage, setErrorMessage] = useState(true)
  const [isEmpty, setIsEmpty] = useState(!formula)

  const checkFormulaExpression = useCallback(async () => {
    const data = await dispatch(checkFormula(formulaExpression))
    const message = get(data, 'payload.message', '当前公式无效，请检查您的公式格式')
    const valid = message === 'OK'
    setIsValid(valid)
    setErrorMessage(message)
    onChange(valid ? formulaExpression : '')
  }, [onChange, formulaExpression, dispatch])

  useEffect(() => {
    if (formulaExpression) {
      if (formulaExpression !== expression) {
        setExpression(formulaExpression)
        checkFormulaExpression()
      } else {
        onChange(formulaExpression)
      }
      setIsEmpty(false)
    } else {
      setIsEmpty(true)
      onChange('')
    }
  }, [formulaExpression, checkFormulaExpression, expression, onChange])

  const handleSelectionChanged = () => {
    if (document.activeElement === editorRef.current) {
      setSelection(Date.now())
    }
  }

  const handleFormulaOperatorClick = (operator: string, ev: React.MouseEvent<HTMLElement>) => {
    ev.preventDefault()
    if (operator === 'clear') {
      if (editorRef.current) {
        editorRef.current.textContent = ''
      }
    } else if (operator === 'delete') {
      removeNode()
    } else {
      insertNode(operator, true)
    }
  }

  useEffect(() => {
    if (editorRef.current && !range) {
      editorRef.current.focus()
    }
    document.onselectionchange = handleSelectionChanged
  }, [editorRef, range])

  const handleColumnSelect = (column: Column) => {
    insertNode(getFieldNode(column))
    setIsEmpty(false)
  }
  const handleFormulaSelect = (formula: FormulaType) => {
    insertNode(getFormulaNode(formula.name, true))
    setIsEmpty(false)
  }

  const renderFunctionPopover = (formula: FormulaType) => {
    return (
      <div className={styles.popover}>
        <div className={styles.popoverTitle}>{formula.declaration}</div>
        <div className={styles.popoverDescription}>{formula.description}</div>
        <div className={styles.popoverItem}>
          <div className={styles.itemTitle}>示例</div>
          <div className={styles.itemValue}>{formula.example}</div>
        </div>
        {map(formula.args, arg => <div key={arg.name} className={styles.item}>
          <div className={styles.itemTitle}>{arg.name}</div>
          <div className={styles.itemValue}>{arg.description}</div>
        </div>)}
      </div>
    )
  }

  const renderFormulaGroups = () => {
    return map(keys(formulaGroups), key => {
      const groupItems = formulaGroups[key]
      if (groupItems && groupItems.length) {
        return (
          <React.Fragment key={key}>
            <li className={styles.groupTitle}>{key}</li>
            {map(groupItems, item => (
              <Popover
                key={item.name}
                placement="left"
                content={renderFunctionPopover(item)}
              >
                <li onClick={() => handleFormulaSelect(item)}>{item.name}</li>
              </Popover>
            ))}
          </React.Fragment>
        )
      }
    })
  }
  return (
    <>
      <div className={styles.editorWrapper}>
        <div
          ref={editorRef}
          className={cx(styles.editor, isEmpty ? styles.empty : '', isValid ? '' : styles.invalid)}
          contentEditable={true}
          suppressContentEditableWarning={true}
          onKeyDown={handleSelectionChanged}
          onMouseDown={handleSelectionChanged}
        />
        {isEmpty && <div className={styles.emptyMessage}>请插入一个列或公式</div>}
      </div>
      <div className={styles.error}>{!isEmpty && !isValid && <><Icon type={'info'} />{errorMessage}</>}</div>
      <div className={styles.formulaOperators}>
        <span onClick={(ev) => handleFormulaOperatorClick('+', ev)}>+</span>
        <span onClick={(ev) => handleFormulaOperatorClick('-', ev)}>-</span>
        <span onClick={(ev) => handleFormulaOperatorClick('*', ev)}>&times;</span>
        <span onClick={(ev) => handleFormulaOperatorClick('/', ev)}>÷</span>
        <span onClick={(ev) => handleFormulaOperatorClick(',', ev)}>,</span>
        <span onClick={(ev) => handleFormulaOperatorClick('clear', ev)}>清空</span>
        <span onClick={(ev) => handleFormulaOperatorClick('delete', ev)}>退格</span>
      </div>
      <ul className={styles.groupContainer}>
        {computableColumns?.length > 0 && <li className={styles.groupTitle}>数据引用</li>}
        {map(computableColumns, (column: Column) => <li
          key={column.id}
          onClick={() => handleColumnSelect(column)}
        ><Icon type={column.type as IconType} /> {column.name}</li>)}
        {renderFormulaGroups()}
      </ul>
    </>
  )
}

export default FormulaExpressionEditor
