import React, { useEffect, useRef, useState } from 'react'
import { Link } from 'react-router-dom'
import { Spin } from 'antd'
import { CheckOutlined } from '@ant-design/icons'
import isEmpty from 'lodash/isEmpty'
import map from 'lodash/map'
import NotificationItem from 'src/components/common/NotificationItem'
import { useNotification } from 'src/hooks/common/useNotification'
import useSelector from 'src/hooks/common/useSelector'
import styles from './notificationPanel.module.scss'

interface Props {
  visible: boolean;
}
const NotificationPanel: React.FC<Props> = ({
  visible
}) => {
  const unreadCount = useSelector(state => state.notification.unreadCount)
  const currentUnreadCount = useRef(unreadCount)
  const [isShowLoad, setIsShowLoad] = useState(false)
  const { notifications, fetchNotifications, isFirstFetching, markAsRead, markAllAsRead } = useNotification()

  function handleMarkAllReadClick () {
    markAllAsRead()
    currentUnreadCount.current = 0
  }

  function handleMarkAsReadClick (id: number) {
    markAsRead(id)
    currentUnreadCount.current = currentUnreadCount.current - 1
  }

  function handleLoadClick () {
    fetchNotifications(1, 'unreadOnly')
    setIsShowLoad(false)
  }

  useEffect(() => {
    if (visible) {
      fetchNotifications(1, 'unreadOnly')
    }
  }, [visible, fetchNotifications])

  useEffect(() => {
    if (!visible) {
      currentUnreadCount.current = unreadCount
      setIsShowLoad(false)
    }
  }, [visible, unreadCount])

  useEffect(() => {
    if (currentUnreadCount.current !== unreadCount) {
      setIsShowLoad(true)
    } else {
      setIsShowLoad(false)
    } 
  }, [unreadCount])


  return (
    <div className={styles.notiPanel}>
      <div className={styles.notiHeader}>
        <div>通知</div>
        { 
          unreadCount > 0 && <div className={styles.notiHeaderLink} onClick={handleMarkAllReadClick}>
            <CheckOutlined />
            全部标记为已读
          </div>
        }
      </div>
      <div className={styles.notiContent}>
        {isShowLoad && <div className={styles.load} onClick={handleLoadClick}>点击加载新通知</div> }
        {
          isFirstFetching 
            ? <div className={styles.spin}><Spin size="small" /> </div>
            : !isEmpty(notifications) 
              ? map(notifications, n => {
                return <NotificationItem 
                  key={n.id} 
                  item={n} 
                  className={styles.notiItem} 
                  onMarkAsReadClick={handleMarkAsReadClick} 
                />
              })
              : <div className={styles.notiEmpty}>- 没有新通知 -</div>
        }
      </div>
      <Link to="/notification" className={styles.notiFooter}>
        查看全部通知
      </Link>
    </div>
  )
}


export default NotificationPanel