import { useRef, useEffect } from 'react'

const MAX_RENDERING = 300
const SAFE_RENDER_INTERVAL = 1000

export function useInfiniteRenderDebug (componentName: string): void {
  const count = useRef(0)
  const timer = useRef<ReturnType<typeof setTimeout>>()

  useEffect(() => {

    if (count.current > MAX_RENDERING) {
      if (process.env.REACT_APP_SERVER_ENV === 'production') {
        throw new Error('Infinite Rendering')
      } else {
        alert(`DEBUG: ${componentName} 组件可能出现多次重复渲染，请不要关闭当前页面并联系开发同事`)
        count.current = 0
      }
    }

    timer.current && clearTimeout(timer.current)

    count.current++ 

    timer.current = setTimeout(() => {
      count.current = 0
    }, SAFE_RENDER_INTERVAL) 
  })
}