import React from 'react'
import { PlusOutlined } from '@ant-design/icons'
import cx from 'clsx'
import noop from 'lodash/noop'
import Icon from 'src/components/common/Icon'
import styles from './actionContent.module.scss'

interface Props {
  name: string
  count?: number
  maxCount?: number
  onAdd?: () => void
}

const ActionContent: React.FC<Props> = ({
  name,
  count = 0,
  maxCount = 0,
  onAdd = noop,
  children
}) => {
  return (
    <div className={styles.action}>
      <div className={styles.alert}>
        <Icon type={'info'} />
        你的{name}会同步给他人。
      </div>
      <div className={styles.actionOptions}>
        {children}
      </div>
      {!count && <div className={cx(styles.actionOptions, styles.noOptions)}>暂无{name}条件</div>}
      {count < maxCount &&
      <div className={styles.addAction} onClick={onAdd}>
        <PlusOutlined /> 添加{name}条件
      </div>}
    </div>
  )
}

export default ActionContent
