import React, { useEffect } from 'react'
import { Form, Radio } from 'antd'
import { InfoCircleOutlined } from '@ant-design/icons'
import type { FormInstance } from 'rc-field-form/es/interface'
import isEmpty from 'lodash/isEmpty'
import { useMessages } from 'src/hooks/chart/useChart'
import styles from './chartOptionsForm.module.scss'

interface Props {
  name: string;
  dependency?: string;
}

interface FormItemProps extends Props {
  form: FormInstance;
}
const FormItem = (props: FormItemProps) => {
  const { form, dependency, name } = props
  const { label, tooltip } = useMessages(name)
  const statsMethod = dependency && form.getFieldValue(dependency)

  useEffect(() => {
    const { setFieldsValue, getFieldValue } = form
    if (statsMethod === 'count') {
      setFieldsValue({
        [name]: null
      })
    } else if (isEmpty(getFieldValue(name))) {
      setFieldsValue({
        [name]: 'sum'
      })
    }
  }, [name, statsMethod, form])

  return statsMethod !== 'count' ? <Form.Item
    name={name}
    label={label}
    tooltip={{ title: tooltip, icon: <InfoCircleOutlined /> }}
  >
    <Radio.Group
      size="small" className={styles.aggregator}
    >
      <Radio.Button value="sum">求和</Radio.Button>
      <Radio.Button value="avg">平均值</Radio.Button>
      <Radio.Button value="max">最大值</Radio.Button>
      <Radio.Button value="min">最小值</Radio.Button>
    </Radio.Group>
  </Form.Item> : null 
}

export const AggregatorFormItem: React.FC<Props> = (props) => {
  return <Form.Item
    noStyle
    dependencies={[props.dependency as string]}
  >
    {(form) => <FormItem form={form} {...props} />}
  </Form.Item>
}

export default AggregatorFormItem