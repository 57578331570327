import React, { useState } from 'react'
import { Modal } from 'antd'
import type { DialogStateReturn as Dialog } from 'src/hooks/common/useDialog'
import type { TypeOptions } from 'src/typings/tableNode'
import TableGrid from './TableGrid'
import styles from './tableConfig.module.scss'

type Columns = TypeOptions['columns']

const Header = () => {
  return (
    <header className={styles.header}>
      <div className={styles.title}>表格设置</div>
    </header>
  )
}

interface Props {
  dialog: Dialog;
  onOk: (columnData: Columns) => void;
  columns: Columns;
  rows: Record<string, unknown>[];
  getContainer?: () => HTMLElement;
}
export const TableConfigModal: React.FC<Props> = ({
  dialog,
  onOk,
  columns,
  rows,
  getContainer = () => document.body
}) => {
  const [internalColumns, setInternalColumns] = useState(columns)

  function handleColumnDataChange (columnData: Columns) {
    setInternalColumns(columnData)
  }

  function handleOk () {
    onOk(internalColumns)
  }

  return (
    <Modal
      className={styles.modal}
      visible={dialog.visible}
      closable={false}
      width={570}
      title={
        <Header />
      }
      onCancel={() => dialog.close()}
      onOk={handleOk}
      getContainer={() => getContainer() || document.body}
      destroyOnClose={true}
    >
      <div className={styles.content}>
        <TableGrid
          columnEditable
          columnData={internalColumns}
          rowData={rows}
          onColumnDataChange={handleColumnDataChange}
          className={styles.gridInConfig}
        />
      </div> 
    </Modal>
  )
}

export default TableConfigModal