import mitt from 'mitt'

const emitter = mitt()

export default emitter

function generateEmitter (eventName: string) {
  return {
    on (cb: unknown) {
      emitter.on(eventName, cb as () => void)
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    emit (args?: any) {
      emitter.emit(eventName, args)
    },
    off (cb: unknown) {
      emitter.off(eventName, cb as () => void)
    }
  }
}

export const createTableNodeEmitter = generateEmitter('createTableNode')
export const previewAttachmentEmitter = generateEmitter('previewAttachment')
export const openDetialPanelEmitter = generateEmitter('openDetailPanel')