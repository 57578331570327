import { useParameters } from 'src/hooks/common/useParameters'
import useSelector from 'src/hooks/common/useSelector'
import type { Compare } from 'src/state/map/slice'

interface Return {
  mapCompare: Compare,
  gotoComparePage: (tableNodeId: number, selectedRows?: string[]) => void
}
export function useRowCompare (): Return {
  const { workspaceId, workspaceType, projectId } = useParameters()
  const compare = useSelector(state => state.map.compare)

  function gotoComparePage (tableNodeId: number, selectedRows?: string[]) {
    const pathname = `/${workspaceType}/${workspaceId}/p/${projectId}/compare`
    const search = `t=${tableNodeId}&r=${selectedRows?.join()}`
    const url = `${window.location.origin}${pathname}?${search}`

    window.open(url)
  }

  return {
    mapCompare: compare,
    gotoComparePage
  }
}