import React, { useCallback, useState, useRef } from 'react'
import { Modal, Button, message } from 'antd'
import { useIntl } from 'react-intl'
import type { FormInstance } from 'rc-field-form/es/interface'
import type { DialogStateReturn as Dialog } from 'src/hooks/common/useDialog'
import type { ChartType } from 'src/typings/chart'
import Chart from 'src/components/common/Chart'
import {
  transformToTypeOptionCharts,
  transformFormToOption
} from 'src/modules/chart/transformers'
import { ChartOption } from 'src/components/common/Chart/Chart'
import { updateColumn } from 'src/state/tableNode/actions'
import useDispatch from 'src/hooks/common/useDispatch'
import type { Props as TableRendererProps } from 'src/components/field/Table/TableRenderer'
import ChartConfigForm from 'src/components/chart/ChartConfigForm'
import styles from './chartConfigModal.module.scss'

interface Props extends TableRendererProps {
  dialog: Dialog;
}

export const ChartConfigModal: React.FC<Props> = ({
  tableNodeId,
  columnId,
  dialog,
  value,
  name,
  typeOptions
}) => {
  const { columns } = typeOptions
  const [chartType, setChartType] = useState<ChartType>(typeOptions.activeChart ?? 'bar')
  const [previewOption, setPreviewOption] = useState<ChartOption>()
  const chartsRef = useRef<Record<ChartType, Record<string, unknown>>>()
  const chartConfigForm = useRef<{ 
    getCharts: () => Record<ChartType, Record<string, unknown>>,
    validateFields: FormInstance['validateFields']
  }>()
  const dispatch = useDispatch()
  const intl = useIntl()

  async function handleOk () {
    if (tableNodeId && columnId && chartConfigForm.current) {
      try {
        await chartConfigForm.current.validateFields()
        dispatch(updateColumn({
          id: tableNodeId,
          columnId,
          typeOptions: {
            ...typeOptions,
            activeChart: chartType,
            charts: transformToTypeOptionCharts(chartsRef.current)
          }
        }))
        dialog.close()
      } catch (err) {
        message.error(intl.formatMessage({ id: 'errors.form.general' }))
      }
    }
  }

  const handleClose = useCallback(() => {
    dialog.close()
  }, [dialog])

  const handleValuesChange = useCallback((
    activeChartType: ChartType, 
    formFieldsValue: Record<ChartType, Record<string, unknown>>
  ) => {
    setPreviewOption(transformFormToOption(
      formFieldsValue[activeChartType],
      value,
      columns,
      activeChartType
    ))
    setChartType(activeChartType)
    chartsRef.current = formFieldsValue
  }, [columns, value])

  const handleLoad = useCallback((
    activeChartType: ChartType, 
    formFieldsValue: Record<ChartType, Record<string, unknown>>
  ) => {
    setPreviewOption(transformFormToOption(
      formFieldsValue[activeChartType],
      value,
      columns,
      activeChartType
    ))
    chartsRef.current = formFieldsValue
  }, [columns, value])

  return (
    <Modal
      className={styles.modal}
      visible={dialog.visible}
      width={800}
      closable={false}
      title={undefined}
      onCancel={handleClose}
      onOk={handleOk}
      destroyOnClose={true}
      maskClosable={false}
      footer={
        <div className={styles.footer}>
          <div className={styles.chartTitle}>配置图表：{name}</div>
          <div className={styles.actions}>
            <Button type="primary" onClick={handleOk}>保存</Button>
            <Button onClick={handleClose}>取消</Button>
          </div>
        </div>
      }
    >
      <div className={styles.content}>
        <div className={styles.chartPreview}>
          <Chart option={previewOption} />
        </div>
        <div className={styles.config}>
          <ChartConfigForm 
            ref={chartConfigForm}
            typeOptions={typeOptions} 
            onClose={handleClose}
            onChange={handleValuesChange}
            onLoad={handleLoad}
          /> 
        </div>
      </div>
    </Modal>
  )
}

export default ChartConfigModal
