import React from 'react'
import cx from 'clsx'
import Icon from 'src/components/common/Icon'
import { useStatus } from 'src/hooks/common/useStatus'
import { useParameters } from 'src/hooks/common/useParameters'
import styles from './status.module.scss'

const Status: React.FC = () => {
  const {
    isSaving,
    isNetworkBroken,
    isNetworkReconnected,
    isUserWsConnecting,
    isOrgsWsConnecting
  } = useStatus()
  const { workspaceType } = useParameters()
  
  const renderStatus = () => {
    if (isNetworkBroken) {
      return (
        <div className={cx(styles.status, styles.networkBroken)}>
          <Icon type={'networkBroken'} /> 网络断开，正在重连
        </div>
      )
    } else if ((isUserWsConnecting && workspaceType === 'uw') || (isOrgsWsConnecting && workspaceType === 'w')) {
      return (
        <div className={styles.status}>
          <Icon type={'networkConnecting'} /> 网络连接不稳定
        </div>
      )
    } else if (isNetworkReconnected) {
      return (
        <div className={cx(styles.status, styles.networkConnected)}>
          <Icon type={'networkConnected'} /> 网络已连接
        </div>
      )
    } else if (isSaving) {
      return (
        <div className={styles.status}>
          <Icon type={'operationSaving'} /> 正在保存
        </div>
      )
    } else {
      return <></>
    }
  }

  return renderStatus()
}

export default Status
