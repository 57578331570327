import { createSlice, EntityState } from '@reduxjs/toolkit'
import { InfoWindowPOIType } from 'src/typings/map'
import { RootState } from 'src/store'
import { reducers } from './reducers'
import infoWindowAdapter from './entityAdapter'

export interface State extends EntityState<InfoWindowPOIType> {
  order: number
}

const initialState = infoWindowAdapter.getInitialState({
  order: 0
})

const infoWindowsSlice = createSlice({
  name: 'infoWindows',
  initialState,
  reducers: {
    remove: infoWindowAdapter.removeOne,
    clear: infoWindowAdapter.removeAll,
    ...reducers
  }
})

export default infoWindowsSlice
export const actions = infoWindowsSlice.actions
export const infoWindowSelectors = infoWindowAdapter.getSelectors<RootState>(state => state.infoWindow)
