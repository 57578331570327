import find from 'lodash/find'
import { 
  deleteAttachment,
  updateAttachments,
  DeleteAttachmentPayload,
  UpdateCellsPayload
} from 'src/state/tableNode/actions'
import { tableNodeSelectors } from 'src/state/tableNode/slice'
import type { UndoRedo } from 'src/features/undoRedo/undoRedo'
import type { RootState } from 'src/store'
import { FileParams } from 'src/api/tablenodes'

function getPayload (arg: DeleteAttachmentPayload, state: RootState): UpdateCellsPayload {
  const { id, columnId, rowId } = arg
  const tableNode = tableNodeSelectors.selectById(state, id)
  const row = find(tableNode?.rows, r => r.id === rowId)
  const attachments = row?.cells[columnId] as FileParams[]

  return {
    id,
    rowId,
    cells: {
      [columnId]: attachments
    }
  }
}

const deleteAttachmentUndoRedo: UndoRedo = {
  action: deleteAttachment,
  undoAction: updateAttachments,
  redoAction: updateAttachments,
  getUndoPayload: getPayload,
  getRedoPayload: getPayload
} as UndoRedo

export default deleteAttachmentUndoRedo