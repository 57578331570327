import { createSlice, EntityState } from '@reduxjs/toolkit'
import { reducers } from 'src/state/status/reducers'
import { RootState } from 'src/store'
import { RequestType } from 'src/typings/status'
import statusAdapter from './entityAdapter'
import { requestFinish } from './actions'

export type State = EntityState<RequestType> & {
  idle: boolean;
  networkStatus: string;
  checkRequestExpiredTimestamp: number;
  requestFinishIds: Record<string, boolean>;
  userWsInitialized: boolean;
  userWsStatus: number;
  orgWsInitialized: boolean;
  orgWsStatus: number;
}

const initialState = statusAdapter.getInitialState({
  idle: true,
  networkStatus: '',
  checkRequestExpiredTimestamp: 0,
  requestFinishIds: {},
  userWsInitialized: false,
  userWsStatus: 0,
  orgWsInitialized: false,
  orgWsStatus: 0
} as State)

const statusSlice = createSlice({
  name: 'status',
  initialState,
  reducers,
  extraReducers (builder) {
    builder.addCase(requestFinish, (state, action) => {
      const requestId = action.payload?.requestID
      const request = state.entities[requestId]
      if (request) {
        statusAdapter.removeOne(state, requestId)
      } else {
        state.requestFinishIds[requestId] = true
      }

      const requests = statusAdapter.getSelectors().selectAll(state)
      state.idle = !requests.length
      if (!state.idle) {
        state.checkRequestExpiredTimestamp = Date.now()
      }
    })
  }
})

export default statusSlice
export const actions = statusSlice.actions
export const statusSelectors = statusAdapter.getSelectors<RootState>(state => state.status)
