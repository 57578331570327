import React, { FormEvent, ReactText, useEffect, useState, useCallback } from 'react'
import cx from 'clsx'
import noop from 'lodash/noop'
import includes from 'lodash/includes'
import isNil from 'lodash/isNil'
import isNaN from 'lodash/isNaN'
import { Divider, Input, Tree as AntdTree } from 'antd'
import { PlusOutlined } from '@ant-design/icons'
import { DataNode, EventDataNode } from 'antd/lib/tree'
import { TreeNode } from 'src/typings/tree'
import useDragDrop from 'src/hooks/common/useDragDrop'
import Icon from 'src/components/common/Icon'
import Menu, { Catalog } from 'src/components/common/Menu'
import useTree from 'src/hooks/common/useTree'
import { useDeleteConfirmDialog } from 'src/hooks/common/useDialog'
import { createTableNodeEmitter } from 'src/utils/emitter'

import { useParameters } from 'src/hooks/common/useParameters'
import { WorkspaceRole } from 'src/typings/workspace'
import { useWorkspaceRoles } from 'src/hooks/workspace/useWorkspaceRoles'
import { ProjectPermission, TableNodePermisson } from 'src/constants/permissions'
import { useTableNodesInProject } from 'src/hooks/common/useTableNode'
import { LayerNode } from 'src/typings/mapTableNode'
import { PermissionWrapper } from '../PermissionWrapper'
import styles from './tree.module.scss'

interface Props {
  title: string;
  type: 'map' | 'table';
  data?: TreeNode[];
  roleData?: Partial<WorkspaceRole>
  onMenuClick?: (action: string, menu?: TreeNode) => void;
  onSelect?: (treeNode: TreeNode, keys: React.Key[]) => void;
  onCreate?: (treeNode: TreeNode) => Promise<void>;
  onDND?: (fromNode: TreeNode, toNode?: TreeNode, parentNode?: TreeNode) => void;
  onUpdate?: (treeNode: TreeNode) => Promise<void>;
  onDelete?: (treeNode: TreeNode) => Promise<void>;
  onToggleVisible?: (treeNode: TreeNode) => void;
  onLayerAdd?: (treeNode: TreeNode) => void;
  onDuplicateClick?: (node: TreeNode) => void;
  empty?: React.ReactNode;
  isNoSelection?: boolean;
  selectedKey?: React.Key;
}

const Tree: React.FC<Props> = (props) => {
  const {
    title,
    type,
    data,
    roleData,
    onSelect = noop,
    onCreate,
    onDND,
    onUpdate,
    onDelete,
    onMenuClick,
    onToggleVisible = noop,
    onLayerAdd = noop,
    onDuplicateClick = noop,
    empty,
    isNoSelection,
    selectedKey
  } = props
  const { projectId, tableNodeId } = useParameters()
  const [menuName, setMenuName] = useState<string | undefined>()
  const [isEditingOrCreating, setIsEditingOrCreating] = useState(false)
  // TODO: combine dialog deletion messages
  const deleteFolderConfirmDialog = useDeleteConfirmDialog({
    messageIds: {
      title: `${type}.delete.folder.title`,
      content: `${type}.delete.folder.content`
    }
  })
  const deleteItemConfirmDialog = useDeleteConfirmDialog({
    messageIds: {
      title: `${type}.delete.title`,
      content: `${type}.delete.content`
    }
  })
  const deleteConfirmDialog = useCallback(() => ({
    'delete.folder': deleteFolderConfirmDialog,
    'delete': deleteItemConfirmDialog
  }), [deleteFolderConfirmDialog, deleteItemConfirmDialog])
  const {
    treeData,
    setTreeData,
    updateTreeState,
    insertNodeToTree,
    expandedKeys,
    setExpandedKeys,
    refreshTreeData
  } = useTree()
  const { onDrop, allowDrop, onDragStart } = useDragDrop(treeData, setTreeData, onDND)
  const { checkPermission } = useWorkspaceRoles()
  const { getTableNode } = useTableNodesInProject(projectId)

  const [selectedKeys, setSelectedKeys] = useState<React.Key[]>([])
  const MENU_TYPE = type === 'map' ?
    {
      groupMenu: Catalog.MAP_GROUP_MENU,
      groupNew: Catalog.MAP_GROUP_NEW,
      itemMenu: Catalog.MAP_ITEM_MENU
    } :
    {
      groupMenu: Catalog.TABLE_GROUP_MENU,
      groupNew: Catalog.TABLE_GROUP_NEW,
      itemMenu: Catalog.TABLE_ITEM_MENU
    }
  useEffect(() => {
    if (isNoSelection) {
      setSelectedKeys([])
    } else {
      if (!isNil(tableNodeId) && !isNaN(tableNodeId)) {
        setSelectedKeys([tableNodeId.toString()])
      } else if (!isNil(selectedKey)) {
        setSelectedKeys([selectedKey])
      }
    }
  }, [isNoSelection, tableNodeId, selectedKey])
  useEffect(() => {
    if (data) {
      setTreeData(data)
    }
  }, [data, setTreeData])

  const handleNewFolderChange = (ev: FormEvent<HTMLInputElement>) => {
    setMenuName((ev.target as HTMLInputElement).value)
  }

  const handleMenuClick = useCallback((action: string, node?: TreeNode) => {
    const handler = () => {
      onMenuClick && onMenuClick(action, node)
    }
    let originalMenu: TreeNode
    switch (action) {
    case 'createTableNode':
      setMenuName('')
      insertNodeToTree({
        placeholder: '新建数据表',
        parentID: node?.id,
        type: 'table',
        creating: true
      })
      if (node) {
        setExpandedKeys([...expandedKeys, node.key])
      }

      setIsEditingOrCreating(true)
      break
    case 'createGroup':
      insertNodeToTree({
        placeholder: '新建文件夹',
        parentID: node?.id,
        type: 'group',
        creating: true
      })
      setMenuName('')
      setIsEditingOrCreating(true)
      break
    case 'editGroup':
    case 'editItem':
      if (node) {
        originalMenu = { ...node }
        originalMenu.editing = true
        setMenuName(originalMenu.title)
        updateTreeState(originalMenu)
        setIsEditingOrCreating(true)
      }
      break
    case 'deleteGroup':
    case 'deleteItem':
      if (node && node.id) {
        deleteConfirmDialog()[node.type === 'group' ? 'delete.folder' : 'delete']({
          messageValues: {
            name: node.name
          }
        }).then(async () => {
          await onDelete?.(node)
          node.removed = true
          updateTreeState(node)
        })
      }
      break
    case 'duplicate':
      onDuplicateClick(node)
      break
    default:
      handler()
    }
  }, [
    onDelete,
    deleteConfirmDialog,
    expandedKeys,
    insertNodeToTree,
    onMenuClick,
    setExpandedKeys,
    updateTreeState,
    onDuplicateClick
  ])

  const manualTriggerCreateTableNode = useCallback(() => {
    handleMenuClick('createTableNode')
  }, [handleMenuClick])

  useEffect(() => {
    createTableNodeEmitter.on(manualTriggerCreateTableNode)
    return () => createTableNodeEmitter.off(manualTriggerCreateTableNode)
  }, [manualTriggerCreateTableNode])


  const handleSave = async (nodeData: DataNode) => {
    const node: TreeNode = nodeData as TreeNode
    if (menuName?.trim()) {
      node.name = menuName
      node.title = node.name
      if (node.creating) {
        node.projectID = projectId
        await onCreate?.(node)
      } else {
        node.projectID = projectId
        await onUpdate?.(node)
      }
    } else {
      if (node.creating) {
        node.removed = true
        updateTreeState(node)
      }
    }
    node.creating = false
    node.editing = false
    refreshTreeData()

    setMenuName(undefined)
    setIsEditingOrCreating(false)
  }

  const renderTableInNode = (nodeData: TreeNode) => {
    if (type === 'map' && includes(selectedKeys, nodeData.key)) {
      const node: LayerNode = nodeData as LayerNode
      if (node.mapInfo?.tableNodeID) {
        const tableNode = getTableNode(node.mapInfo.tableNodeID)
        if (tableNode) {
          return <div className={styles.relatedTable}>
            <Icon type="union" className={styles.union} />
            <Icon type="table" /> {tableNode?.name}</div>
        }
      }
    }
  }

  const getRoleDataForMenu = (node: TreeNode) => {
    return node.type === 'group' || type === 'map' ? roleData
      : { id: node.id as number, type: 'table' } as WorkspaceRole
  }

  // TODO: extract as child component
  const renderTitle = (nodeData: DataNode) => {
    const node: TreeNode = nodeData as TreeNode
    if (node.editing || node.creating) {
      return (
        <div className={styles.catalogItem}>
          <Input
            value={menuName}
            autoFocus={true}
            className={styles.input}
            placeholder={node.creating ? node.placeholder : '修改名称'}
            onInput={handleNewFolderChange}
            onKeyDown={(ev) => ev.key === 'Enter' && handleSave(node)}
            onBlur={() => handleSave(node)} />
        </div>
      )
    } else {
      return (
        <>
          <div className={styles.catalogItem}>
            <div className={styles.itemName}>{node.title}</div>
            <div className={styles.actions}>
              <PermissionWrapper id={node.id as number} type="table" role={TableNodePermisson.DataEdit}>
                {
                  type === 'map'
                  && node.type !== 'group'
                  && selectedKeys[0] === node.key
                  && node.visible
                  && <PlusOutlined
                    className={styles.addButton}
                    onClick={(e) => {
                      e.stopPropagation()
                      onLayerAdd(node)
                    }}
                  />
                }
              </PermissionWrapper>
              <Menu
                type={node.type === 'group' ? MENU_TYPE.groupMenu : MENU_TYPE.itemMenu}
                node={node}
                roleData={getRoleDataForMenu(node)}
                onMenuClick={handleMenuClick}
                trigger={['click', 'hover']}
              />
              {
                type === 'map' &&
                <>
                  <Divider type="vertical" />
                  <Icon
                    className={cx(styles.visibleIcon, { [styles.indeterminate]: node.indeterminate })}
                    type={node.visible ? 'visibleActive' : 'invisible'}
                    onClick={e => {
                      e?.stopPropagation()
                      onToggleVisible?.(node)
                    }}
                  />
                </>
              }
            </div>
          </div>
          {renderTableInNode(node)}
        </>
      )
    }
  }

  const handleNodeSelect = (keys: ReactText[], { node }: { node: DataNode }) => {
    onSelect(node, [node.key])
    setSelectedKeys([node.key])
  }
  const handleNodeExpand = (keys: React.Key[]) => {
    setExpandedKeys(keys)
  }
  const handleNodeClick = (e: React.MouseEvent<HTMLSpanElement>, node: EventDataNode) => {
    if (node.isLeaf) {
      return
    }
    if (!node.expanded) {
      setExpandedKeys([...expandedKeys, node.key])
    } else {
      setExpandedKeys(expandedKeys.filter(i => i !== node.key))
    }
  }

  const renderPlusButton = () => {
    if (roleData) {
      return (
        <PermissionWrapper id={roleData.id} type={roleData.type} role={roleData.role}>
          <div className={styles.plusButton}>
            <Menu
              type={MENU_TYPE.groupNew}
              onMenuClick={handleMenuClick}
              roleData={roleData}
            />
          </div>
        </PermissionWrapper>
      )
    }

    return (
      <div className={styles.plusButton}>
        <Menu
          type={MENU_TYPE.groupNew}
          onMenuClick={handleMenuClick}
        />
      </div>
    )
  }

  const checkDraggable = () => {
    if (roleData) {
      return checkPermission(roleData.id, ProjectPermission.TreeItemDraggable, roleData.type)
    }

    return !isEditingOrCreating
  }

  return (
    <>
      <div className={styles.tree}>
        <div className={styles.catalogTitle}>
          {title}
          {renderPlusButton()}
        </div>
        <div className={styles.folder}>
          <AntdTree
            autoExpandParent
            allowDrop={allowDrop}
            onDragStart={onDragStart}
            showIcon
            className="draggable-tree"
            draggable={() => checkDraggable()}
            blockNode
            selectedKeys={selectedKeys}
            expandedKeys={expandedKeys}
            switcherIcon={<Icon className={styles.caret} type="caretBottom" />}
            titleRender={renderTitle}
            onDrop={onDrop}
            onExpand={handleNodeExpand}
            onSelect={handleNodeSelect}
            onClick={handleNodeClick}
            treeData={treeData}
          />
          {
            (treeData && treeData.length === 0) && <div className={styles.empty}>
              {empty}
            </div>
          }
        </div>
      </div>
    </>
  )
}

export default Tree
