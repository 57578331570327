import React from 'react'
import { Button, Table, Tooltip } from 'antd'
import cx from 'clsx'
import { AppDispatch } from 'src/store'
import useDispatch from 'src/hooks/common/useDispatch'
import { RecycleProject } from 'src/typings/project'
import { formatDate, convertToDays } from 'src/utils/moment'
import Icon from 'src/components/common/Icon'
import { useDeleteConfirmDialog, useConfirmDialog } from 'src/hooks/common/useDialog'
import { deleteRecycleProject, recoverProject } from 'src/state/project/actions'
import PageContainer from 'src/components/common/PageContainer'
import { OrgPermission, ProjectPermission } from 'src/constants/permissions'
import { useParameters } from 'src/hooks/common/useParameters'
import { Workspace } from 'src/typings/workspace'
import { useWorkspaces } from 'src/hooks/workspace/useWorkspaces'
import { useWorkspaceRoles } from 'src/hooks/workspace/useWorkspaceRoles'
import { useDocumentTitle, getWorkspaceTitle } from 'src/hooks/common/useDocumentTitle'
import styles from './recycleBin.module.scss'

interface Props {
  recycleProjects?: RecycleProject[]
  type?: Workspace['type']
}

const RecycleBin: React.FC<Props> = ({ recycleProjects, type }) => {
  const dispatch: AppDispatch = useDispatch()
  const { workspaceId, workspaceType } = useParameters()
  const { workspace } = useWorkspaces(workspaceType, workspaceId)
  const { checkProjectPermission } = useWorkspaceRoles()
  const deleteConfirmDialog = useDeleteConfirmDialog({
    messageIds: {
      title: 'project.delete.permanently.title',
      content: 'project.delete.permanently.content',
      okText: 'project.delete.permanently.okText'
    }
  })
  const confirmDialog = useConfirmDialog()
  useDocumentTitle('回收站', getWorkspaceTitle(workspace))

  function handleRecoverProject (project: RecycleProject) {
    confirmDialog({
      title: '恢复项目',
      closable: false,
      content: (<>
        <div>确定恢复项目 <span className={styles.highlight}>{project.name}</span> ？</div>
        <div>恢复后项目将出现在项目列表中。</div>
      </>)
    }).then(() => {
      dispatch(recoverProject(project.id))
    })
  }

  function handleRemoveProject (project: RecycleProject) {
    deleteConfirmDialog({
      messageValues: {
        name: project.name
      }
    }).then(() => {
      dispatch(deleteRecycleProject(project.id))
    })
  }

  const checkActionPermission = (p: RecycleProject, name: 'RecycleBinDelete' | 'RecycleBinRecover') => {
    return checkProjectPermission(p.id, ProjectPermission[name]) ||
      (type === 'org' && checkProjectPermission(workspaceId, OrgPermission[name]))
  }
  const columns = [
    {
      title: '项目名',
      dataIndex: 'name',
      width: 100,
      render: (v: string) => <div className={styles.projectName}>
        <Icon type="projectList" className={styles.projectIcon} />
        {v}
      </div>
    },
    {
      title: '剩余天数',
      dataIndex: 'timeRemaining',
      width: 100,
      render: (v: number) => <>
        {convertToDays(v)} 天
      </>
    },
    {
      title: '删除时间',
      dataIndex: 'deletedAt',
      width: 100,
      render: (v: string) => <>
        {formatDate(v)}
      </>
    },
    {
      title: '操作',
      width: 100,
      render: (_: unknown, p: RecycleProject) => {
        return <>
          {checkActionPermission(p, 'RecycleBinRecover') ?
            <Button type="link" className={styles.actionButton} onClick={() => handleRecoverProject(p)}>恢复项目</Button> :
            <Tooltip placement="top" title={'没有权限'}>
              <Button type="link" className={cx(styles.actionButton, styles.disableStyle)}>恢复项目</Button>
            </Tooltip>}
          {checkActionPermission(p, 'RecycleBinDelete') ?
            <Button
              type="link" danger className={styles.actionButton}
              onClick={() => handleRemoveProject(p)}>彻底删除</Button> :
            <Tooltip placement="top" title={'没有权限'}>
              <Button
                type="link" danger className={cx(styles.actionButton, styles.disableStyle)}>彻底删除</Button>
            </Tooltip>}
        </>
      }
    }
  ]

  return <PageContainer title={<>回收站 <span className={styles.hint}>回收站数据将保留 30 天</span></>}>
    <div className={styles.table}>
      <Table
        columns={columns} dataSource={recycleProjects}
        pagination={false} scroll={{ y: 'calc(100vh - 250px)' }} rowKey="id" />
    </div>
  </PageContainer>
}

export default RecycleBin
