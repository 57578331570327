import { useEffect, useState, useCallback } from 'react'
import find from 'lodash/find'
import { Filter } from 'src/constants/filter'
import useSelector from 'src/hooks/common/useSelector'
import useDispatch from 'src/hooks/common/useDispatch'
import { actions as mapActions } from 'src/state/map/slice'
import { useParameters } from 'src/hooks/common/useParameters'
import { useWorkspaces } from 'src/hooks/workspace/useWorkspaces'
import { FiltersWithIdType } from 'src/hooks/project/useFilter'

interface Return {
  filtersWithId?: FiltersWithIdType
  tableNodeId?: number
  updateMapFilters: (mapFilters?: Filter[], id?: string) => void
  getLayerFilters: (layerNodeId: string) => Filter[]
}

export default function useMapFilter (layerNodeID?: string): Return {
  const { projectId } = useParameters()
  const dispatch = useDispatch()
  const { userWorkspaceId } = useWorkspaces('uw')
  const mapLayerFilterId = `${userWorkspaceId}_${layerNodeID}`
  const originalMapFilters = useSelector(state => state.map.mapFilters[mapLayerFilterId])
  const allMapFilters = useSelector(state => state.map.mapFilters)
  const [filtersWithId, setFilterWithId] = useState<FiltersWithIdType>()
  const layerNode = useSelector((state) => {
    return find(state.project.layerNodes[projectId], (val) => val.id === layerNodeID)
  })

  const updateMapFilters = async (mapFilters?: Filter[]) => {
    await dispatch(mapActions.updateMapFilters({
      id: mapLayerFilterId,
      filters: mapFilters || []
    }))
  }

  useEffect(() => {
    if (layerNodeID) {
      setFilterWithId({
        id: mapLayerFilterId,
        filters: originalMapFilters || []
      })
    }
  }, [layerNodeID, mapLayerFilterId, originalMapFilters])

  const getLayerFilters = useCallback((id: string) => {
    return allMapFilters[`${userWorkspaceId}_${id}`] || []
  }, [userWorkspaceId, allMapFilters])

  return {
    filtersWithId,
    tableNodeId: layerNode?.mapInfo?.tableNodeID,
    updateMapFilters,
    getLayerFilters
  }
}
