import React from 'react'
import { formatDateByRule } from 'src/utils/format'

export interface Props {
  value: string;
  typeOptions: {
    dateFormat?: string;
    timeFormat?: string;
  }
}

export const DatetimeRenderer: React.FC<Partial<Props>> = (props) => {
  const { typeOptions } = props

  return (
    <div>
      {props.value && formatDateByRule(props.value, typeOptions)}
    </div>
  )
}
