import AppConfig from 'src/config'
import { createInstance } from 'src/utils/fetch'
import { APIResponse } from 'src/typings/common'

const fetch = createInstance({
  baseURL: `${AppConfig.baseApiURL}/`
})

export interface Invitation {
  id: number;
  phone: string;
  role: string;
  createTime: string
}

const InvitationAPI = {
  async getInvitations (id: number, type: string): Promise<APIResponse> {
    const { data } = await fetch.get(`${type}/${id}/invitations/`)

    return data
  },
  async inviteMember (id: number, type: string, members: Partial<Invitation>[]): Promise<APIResponse> {
    const { data } = await fetch.post(`${type}s/${id}/members/invitations/`, { members })

    return data
  },
  async deleteInvitation (id: number, type: string, invitationID: number): Promise<APIResponse> {
    const { data } = await fetch.delete(`${type}/${id}/invitations/${invitationID}/`)

    return data
  },
  async resendInvitation (id: number, type: string, invitationID: number): Promise<APIResponse> {
    const { data } = await fetch.post(`${type}/${id}/invitations/${invitationID}/resend/`)

    return data
  }
}

export default InvitationAPI
