import React, { forwardRef } from 'react'
import { ICellEditorParams } from 'ag-grid-community'
import { useCellCollaborators } from 'src/hooks/collaborator/useCollaborator'
import { useCellEditorParams } from 'src/hooks/field/useCellParams'
import { AttachmentEditor } from 'src/components/field/Attachment'
import { CoordinateEditor } from 'src/components/field/Coordinate'
import { DatetimeEditor } from 'src/components/field/Datetime'
import { MultiLineTextEditor } from 'src/components/field/MultiLineText'
import { SingleLineTextEditor } from 'src/components/field/SingleLineText'
import { FormulaEditor } from 'src/components/field/Formula'
import { NumberEditor } from 'src/components/field/Number'
import { SelectEditor } from 'src/components/field/Select'
import { TypeOptions } from 'src/typings/tableNode'
import { TableEditor } from '../Table/TableEditor'

export interface Props extends ICellEditorParams {
  type: string;
  viewId: string;
  typeOptions: TypeOptions;
}

const CellEditorContainer = forwardRef((props: Props, ref) => {
  const { type } = useCellEditorParams(props)
  const { name, color } = useCellCollaborators(props.column.getColId(), props.data.id)
  const propsRealValue = { ...props, value: props.value.realValue }

  const renderField = () => {
    switch (type) {
    case 'table':
      return <TableEditor {...propsRealValue} ref={ref} />
    case 'attachment':
      return <AttachmentEditor {...propsRealValue} ref={ref} />
    case 'coordinate':
      return <CoordinateEditor {...propsRealValue} ref={ref} />
    case 'datetime':
      return <DatetimeEditor {...propsRealValue} ref={ref} />
    case 'multiLineText':
      return <MultiLineTextEditor {...propsRealValue} ref={ref} />
    case 'number':
      return <NumberEditor {...propsRealValue} ref={ref} />
    case 'singleChoice':
    case 'multiChoice':
      return <SelectEditor {...propsRealValue} ref={ref} />
    case 'formula':
      return <FormulaEditor {...propsRealValue} ref={ref} />
    case 'singleLineText':
      return <SingleLineTextEditor {...propsRealValue} ref={ref} />
    }
  }
  return (
    <>
      <div
        className={'ag-grid-collaborator-cell'}
        data-collaborator-cell={!!name}
        style={{
          borderColor: color
        }}
      >
        <span
          style={{
            backgroundColor: color
          }}
        >{name}</span>
      </div>
      {renderField()}
    </>
  )
})

export default CellEditorContainer
