import React, { useEffect } from 'react'
import { Form, Input, message } from 'antd'
import { useDispatch } from 'react-redux'
import { createTeam } from 'src/state/org/actions'
import Dialog from 'src/components/common/Dialog'
import { DialogStateReturn as DialogInterface } from 'src/hooks/common/useDialog'
import { AppDispatch } from 'src/store'

interface Props {
  dialog: DialogInterface;
}

const CreateLayerModal: React.FC<Props> = ({
  dialog
}) => {
  const dispatch: AppDispatch = useDispatch()
  const [form] = Form.useForm()
  useEffect(() => {
    if (dialog.visible) {
      form.resetFields()
    }
  }, [dialog, form])

  async function handleSubmitClick () {
    await form.validateFields()
    const teamName = form.getFieldValue('teamName')
    await dispatch(createTeam(teamName))
    message.success('新建成功')
    dialog.close()
  }

  return (
    <Dialog
      width="400px" okText="新建" title="新建团队"
      visible={dialog.visible} onCancel={dialog.close} onOk={handleSubmitClick} >
      <Form
        form={form}
      >
        <Form.Item
          name="teamName" label="团队名称" colon={false}
          rules={[
            {
              required: true,
              message: '请输入团队名称'
            }
          ]} >
          <Input />
        </Form.Item>
      </Form>
    </Dialog>
  )
}

export default CreateLayerModal
