import React, { ComponentType } from 'react'
import { Layout } from 'antd'
import styles from 'src/styles/layout.module.css'

const { Content } = Layout

type LayoutType = 'slim' | 'wide' | 'full'

const withLayout = (layout: LayoutType) => {
  return (Comp: ComponentType): ComponentType => {
    const ComponentWithLayout: ComponentType = (props) => {
      return (
        <Layout className={styles[layout]}>
          <Content>
            <Comp {...props} />
          </Content>
        </Layout>
      )
    }

    return ComponentWithLayout
  }
}

export default withLayout
