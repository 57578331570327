import { useEffect, useState, useRef } from 'react'
import map from 'lodash/map'
import find from 'lodash/find'
import filter from 'lodash/filter'
import isEmpty from 'lodash/isEmpty'
import compact from 'lodash/compact'
import findIndex from 'lodash/findIndex'
import type { Layer as LayerType } from 'qumap/packages/react/types/controls/Switch'
import useSelector from 'src/hooks/common/useSelector'
import useMapFilter from 'src/hooks/map/useMapFilter'
import { useOverlayEditor } from 'src/hooks/map/useMap'
import { parse } from 'src/utils/wkt'
import { actions as InfoWindowActions, infoWindowSelectors } from 'src/state/infoWindow/slice'
import { tableNodeSelectors } from 'src/state/tableNode/slice'
import { selectLayerNodes } from 'src/selectors/layerNodeSelector'
import { InfoWindowPOIType, GroupLayers, GroupLayer } from 'src/typings/map'
import { OVERLAY_TYPES_MAPPING } from 'src/constants/overlay'
import { filterData } from 'src/utils/filter'
import { PICKER_COLORS } from 'src/constants/colors'
import useDispatch from 'src/hooks/common/useDispatch'

interface Return {
  layers: GroupLayers;
  layersForLabelControl: LayerType[];
  setLayersForLabelControl: (l: LayerType[]) => void;
  hiddenPoiIds: Set<string>;
}

// FIXME: effect trigger mutiple times when update fillSettings
export function useLayers (projectId: number): Return {
  const dispatch = useDispatch()
  const hiddenPoiIds = useRef<Set<string>>(new Set())
  const {
    selectedMapLayer
  } = useOverlayEditor()
  const currentMapFilterLayerNodeId = selectedMapLayer?.layerNodeID
  const { filtersWithId, getLayerFilters } = useMapFilter(currentMapFilterLayerNodeId)
  const layers = useSelector(state => selectLayerNodes(state, projectId))
  const previewMapInfos = useSelector(state => state.project.previewMapInfos)
  const zIndexMap = useSelector(state => state.project.zIndexMap)
  const tableNodes = useSelector(tableNodeSelectors.selectAll)
  const [groupLayers, setGroupLayers] = useState<GroupLayers>({
    data: [],
    config: {}
  })
  const [layersForLabelControl, setLayersForLabelControl] = useState<LayerType[]>([])
  const infoWindowsIds = useSelector(state => infoWindowSelectors.selectIds(state))
  useEffect(() => {
    hiddenPoiIds.current.clear()

    const data = map(filter(layers, l => l.type === 'map'), (l) => {
      const mapInfo = previewMapInfos[l.id] || l.mapInfo
      const tableNode = find(tableNodes, t => t.id === mapInfo?.tableNodeID)
      const layerFilters = getLayerFilters(l.id)
      const data = compact(map(tableNode?.rows, r => {
        if (!isEmpty(layerFilters) && !filterData(r.cells, layerFilters)) {
          hiddenPoiIds.current.add(r.id)
          
          return undefined
        }

        const coordinateField = r.cells[mapInfo?.coordinateFieldID as string]
        if (!coordinateField) return false

        const column = find(tableNode?.columns, c => c.id === mapInfo?.labelFieldID)
        const columnType = column?.type
        const typeOptions = column?.typeOptions

        try {
          const { coordinates, type } = parse(coordinateField as string)

          let labelField = r.cells[mapInfo?.labelFieldID as string]
          if (columnType === 'singleChoice') {
            labelField = find(typeOptions?.choices, c => c.id === labelField)?.name
          }

          const poi = {
            id: r.id,
            overlayType: OVERLAY_TYPES_MAPPING[type],
            name: r.cells[mapInfo?.labelFieldID as string],
            labelField: labelField ?? '',
            fillField: r.cells[mapInfo?.fillFieldID as string],
            radiusField: r.cells[mapInfo?.radiusFieldID as string] ?? r.id,
            extData: {
              tableNodeID: tableNode?.id,
              rowID: r.id,
              cellID: mapInfo?.coordinateFieldID,
              layerID: l.id,
              labelFieldID: mapInfo?.labelFieldID
            },
            coordinates
          }
          return poi
        } catch (error) {
          // eslint-disable-next-line no-console
          console.error(error)
          return false
        }
      }))
      return {
        id: l.id,
        name: l.name,
        zIndex: zIndexMap[l.id],
        data,
        mapInfo
      }
    })

    setLayersForLabelControl(map(data, d => {
      return {
        id: d.id,
        label: d.name,
        active: d.mapInfo?.labelConfig.visible
      }
    }))
    setGroupLayers({
      data: data as unknown as GroupLayer[],
      config: {}
    })
  }, [getLayerFilters, filtersWithId, layers, tableNodes, zIndexMap, previewMapInfos])

  useEffect(() => {
    // FIXME: diffrent layer infowindow with same id conflict
    groupLayers.data.forEach(d => {
      infoWindowsIds.forEach(id =>{
        const poi = find(d.data, (r: InfoWindowPOIType) => r.id === id)
        if (poi) {
          dispatch(InfoWindowActions.updatePoi(poi as unknown as InfoWindowPOIType))
        }
      })
    })

  }, [infoWindowsIds, groupLayers, dispatch])
  useEffect(() => {

    const config = {
      layers: map(groupLayers.data, d => {
        const tableNode = find(tableNodes, t => t.id === d.mapInfo?.tableNodeID)
        const fillColumn = find(tableNode?.columns, c => c.id === d.mapInfo?.fillFieldID)
        const isFillWithSingleChoice = fillColumn?.type === 'singleChoice'

        return {
          id: d.id,
          dataId: d.id,
          field: 'coordinates',
          isVisible: true,
          zIndex: d.zIndex,
          labels: [{
            field: 'labelField',
            ...d.mapInfo?.labelConfig,
            visible: findIndex(layersForLabelControl, l => !!l.active && l.id === d.id) !== -1
          }],
          fillConfig: {
            field: d.mapInfo?.fillFieldID ? 'fillField' : undefined,
            enable: true,
            fillOpacity: d.mapInfo?.fillConfig.fillOpacity,
            colorScale: isFillWithSingleChoice ? 'ordinal' : d.mapInfo?.fillConfig.fillMethod ?? 'quantize',
            colorRange: {
              ordinalDomain: isFillWithSingleChoice ? fillColumn?.typeOptions?.choices?.map(c => c.id) : undefined,
              colors: isFillWithSingleChoice ? 
                fillColumn?.typeOptions?.choices?.map(c => PICKER_COLORS[c.color]) 
                : d.mapInfo?.fillConfig.colors,
              reversed: d.mapInfo?.fillConfig.reverse
            }
          },
          outlineConfig: {
            enable: true,
            ...d.mapInfo?.outlineConfig
          },
          radiusConfig: {
            field: 'radiusField',
            ...d.mapInfo?.radiusConfig
          }
        }
      })
    }
    setGroupLayers((prev) => ({
      ...prev,
      config
    }))
  }, [groupLayers.data, layersForLabelControl, previewMapInfos, tableNodes])


  return {
    layers: groupLayers,
    layersForLabelControl,
    setLayersForLabelControl,
    hiddenPoiIds: hiddenPoiIds.current
  }
}
