import omit from 'lodash/omit'
import values from 'lodash/values'
import { 
  XAxis, 
  YAxis, 
  Style, 
  Stats,
  ChartOptions
} from 'src/typings/chart'
import { COLOR_PALETTE_WITH_STEP } from 'src/constants/colors'

export const COLORS_LENGTH = 10

const DEFAULT_STYLE = {
  colors: values(COLOR_PALETTE_WITH_STEP[COLORS_LENGTH])[0],
  enableShowNullAndZeroValue: false,
  enableShowValue: true,
  enableShowPanelExample: true,
  enableShowPercentage: true
} as Style

const DEFAULT_CONFIG_XAXIS = {
  groupBy: null,
  sortMethod: 'listAsc'
} as XAxis

const DEFAULT_CONFIG_STATS = {
  method: 'count',
  fields: [],
  aggregator: 'sum'
} as Stats

const DEFAULT_CONFIG_YAXIS = {
  stats: DEFAULT_CONFIG_STATS
} as YAxis

export const DEFAULT_BAR_FIELDS = {
  config: {
    xAxis: DEFAULT_CONFIG_XAXIS,
    yAxis: DEFAULT_CONFIG_YAXIS
  },
  style: omit(DEFAULT_STYLE, ['enableShowPercentage'])
} as ChartOptions['params']

export const DEFAULT_LINE_FIELDS = {
  config: {
    xAxis: DEFAULT_CONFIG_XAXIS,
    yAxis: DEFAULT_CONFIG_YAXIS
  },
  style: omit(DEFAULT_STYLE, ['enableShowPercentage'])
} as ChartOptions['params']

export const DEFAULT_PIE_FIELDS = {
  config: {
    groupBy: null,
    stats: DEFAULT_CONFIG_STATS
  },
  style: DEFAULT_STYLE
} as ChartOptions['params']

export const DEFAULT_CHART_PARAMS = {
  bar: DEFAULT_BAR_FIELDS,
  line: DEFAULT_LINE_FIELDS,
  pie: DEFAULT_PIE_FIELDS
}