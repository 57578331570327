import React from 'react'
import { useHistory } from 'react-router-dom'
import cx from 'clsx'
import Header from 'src/components/common/Header'
import styles from './header.module.scss'

interface Props {
  username?: string
  onGoHome?: () => void
}

const WorkspaceHeader: React.FC<Props> = (props) => {
  const history = useHistory()
  function handleGoHome () {
    if (props.onGoHome) {
      props.onGoHome()
    }
    else {
      history.push('/')
    }
  }

  return (
    <Header
      headerLeft={
        <ul className={styles.navs}>
          <li className={cx(styles.active)}>
            <span onClick={handleGoHome}>
        我的项目
            </span>
          </li>
          {/* <li>模版中心</li> */}
          {/* <li>数据市场</li> */}
        </ul>
      }>
    </Header>
  )
}

export default WorkspaceHeader
