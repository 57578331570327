import React, { useEffect } from 'react'
import { useIntl } from 'react-intl'
import { Form } from 'antd'
import isEmpty from 'lodash/isEmpty'
import isString from 'lodash/isString'
import type { FormInstance } from 'rc-field-form/es/interface'
import FieldTypeSelector from 'src/components/common/FieldTypeSelector'
import { useMessages } from 'src/hooks/chart/useChart'
import { Column } from 'src/typings/tableNode'

interface Props {
  columns?: Omit<Column, 'isPrimary'>[],
  name: string;
  dependency?: string;
}

interface FormItemProps extends Props {
  form: FormInstance;
}
const FormItem = (props: FormItemProps) => {
  const { form, dependency, name, columns } = props
  const statsMethod = dependency && form.getFieldValue(dependency)
  const { label } = useMessages(name)
  const intl = useIntl()

  useEffect(() => {
    const { getFieldValue, setFieldsValue } = form
    const value = getFieldValue(name)

    if (statsMethod === 'count') {
      setFieldsValue({
        [name]: []
      })
    } else {
      if (isEmpty(value)) {
        setFieldsValue({
          [name]: [columns?.[0].id]
        })
      }
    }
  }, [name, columns, form, statsMethod])

  return statsMethod !== 'count' ? <Form.Item
    name={name}
    label={label}
    rules={[
      () => ({
        validator (_, value) {
          return (isEmpty(value) && statsMethod === 'multiField') 
            ? Promise.reject(intl.formatMessage({ id: 'errors.chart.stats.fields' })) 
            : Promise.resolve()
        }
      })
    ]}
    normalize={(value) => {
      if (isString(value) && !isEmpty(value)) return [value]
      
      return value
    }}
  >
    <FieldTypeSelector
      options={columns}
      multiple={statsMethod === 'multiField'}
    />
  </Form.Item> : null  
}

export const FieldsFormItem: React.FC<Props> = (props) => {
  return <Form.Item
    noStyle
    dependencies={[props.dependency as string]}
  >
    {(form) => <FormItem form={form} {...props} />}
  </Form.Item>
}

export default FieldsFormItem