import React, { useState } from 'react'
import get from 'lodash/get'
import includes from 'lodash/includes'
import storage from 'store2'
import toString from 'lodash/toString'
import find from 'lodash/find'
import cloneDeep from 'lodash/cloneDeep'
import { MapTableNode } from 'src/typings/mapTableNode'
import { TreeNode } from 'src/typings/tree'
import TreeNodeIcon from 'src/components/common/TreeNodeIcon'
import { HIDDEN_LAYER_IDS } from 'src/constants/storage'

type MappedNodeType = MapTableNode & { children?: MappedNodeType[] }

const formatData = (projectId: number, data?: MapTableNode[]): TreeNode[] => {
  const hiddenLayers = get(storage.get(HIDDEN_LAYER_IDS, {}), projectId, [])

  if (data) {
    return data.map(d => {
      const node: TreeNode = {
        ...d,
        title: d.name,
        key: toString(d.id),
        children: formatData(projectId, d.children)
      }
      if ('mapInfo' in d) {
        node.visible = !includes(hiddenLayers, node.id)
        if (node.children?.length) {
          node.children?.length && updateIndeterminate(node.id as string, [node])
        }
      }
      node.icon = TreeNodeIcon
      if (node.type === 'group') {
        node.selectable = false
      }
      return node
    })
  } else {
    return []
  }
}

const sortTree = (tree: MapTableNode[]) => {
  if (tree.length === 1 && tree[0].children) {
    sortTree(tree[0].children)
  } else {
    tree.sort((prev, next) => {
      prev.children && sortTree(prev.children)
      next.children && sortTree(next.children)
      return prev.order - next.order
    })
  }
}

const toTree = (arr: MapTableNode[]) => {
  if (!arr) {
    return []
  }
  const tree = []
  const mappedArr: Record<string, MappedNodeType> = {}
  let arrElem: MapTableNode
  let mappedElem: MapTableNode

  arr.forEach(a => {
    arrElem = a
    mappedArr[toString(arrElem.id)] = { ...arrElem }
    mappedArr[toString(arrElem.id)]['children'] = []
  })

  for (const key in mappedArr) {
    mappedElem = mappedArr[key]
    if (mappedElem.parentID) {
      const children = mappedArr[mappedElem['parentID']]['children'] || []
      children.push(mappedElem)
    } else {
      tree.push(mappedElem)
    }
  }

  sortTree(tree)
  return tree
}

const formatDataToTreeNode = (projectId: number, arr: MapTableNode[]): TreeNode[] => {
  return formatData(projectId, toTree(arr)) || []
}

const updateIndeterminate = (parentId: string, tree: TreeNode[]) => {
  tree.forEach(t => {
    if (t.id === parentId || t.children?.length) {
      let visibleCount = 0
      t.children?.forEach(child => visibleCount += child.visible ? 1 : 0)
      if (t.children?.length === visibleCount) {
        t.visible = true
        t.indeterminate = false
      } else if (!visibleCount) {
        t.visible = false
        t.indeterminate = false
      } else {
        t.visible = true
        t.indeterminate = true
      }
    }

    t.children && updateIndeterminate(parentId, t.children)
  })
}

const removeIconFromNode = (nodes: TreeNode[]): TreeNode[] => {
  return nodes.map((node: TreeNode) => {
    delete node.icon
    if (node.children) {
      node.children = removeIconFromNode(node.children)
    }
    return node
  })
}
const getSerializableTreeData = (treeData: TreeNode[]): TreeNode[] => {
  return removeIconFromNode(cloneDeep(treeData))
}


interface Return {
  updateTreeState: (node: TreeNode, isNewLayer?: boolean) => void
  treeData: TreeNode[]
  expandedKeys: React.Key[]
  setExpandedKeys: React.Dispatch<React.SetStateAction<React.Key[]>>
  setTreeData: React.Dispatch<React.SetStateAction<TreeNode[]>>
  updateChildrenVisibility: (node: TreeNode, cb?: (ids: string[]) => void) => void
  updateParentVisibility: (parentId?: string) => void
  formatDataToTreeNode: (projectId: number, arr: MapTableNode[]) => TreeNode[]
  insertNodeToTree: (node: Partial<TreeNode>) => void
  refreshTreeData: () => void
  getSerializableTreeData: (treeData: TreeNode[]) => TreeNode[]
}

export function findFirstDataNode (treeToFind?: TreeNode[]): TreeNode | undefined {
  const dataNodes = treeToFind?.filter(node => node.type !== 'group')
  if (dataNodes?.[0]) {
    return dataNodes[0]
  }
  const groupNodes = treeToFind?.filter(node => node.type === 'group')
  if (groupNodes) {
    for (const groupNode of groupNodes) {
      const firstDataNode = findFirstDataNode(groupNode.children)
      if (firstDataNode) {
        return firstDataNode
      }
    }
  }
}

export default function useTree (): Return {
  const [treeData, setTreeData] = useState<TreeNode[]>([])
  const [expandedKeys, setExpandedKeys] = useState<React.Key[]>([])

  const refreshTreeData = () => setTreeData([...treeData])

  function insertNodeToTree (node: Partial<TreeNode>) {
    const targetNode = find(treeData, { id: node.parentID })
    node.icon = TreeNodeIcon
    if (targetNode) {
      targetNode.children?.push(node as TreeNode)
      refreshTreeData()
    } else {
      setTreeData([...treeData, node as TreeNode])
    }
  }

  const updateParentVisibility = (parentId?: string) => {
    if (parentId) {
      updateIndeterminate(parentId, treeData)
      setTreeData([...treeData])
    }
  }

  const updateChildrenVisibility = (node: TreeNode, cb?: (ids: string[]) => void) => {
    const ids: string[] = []

    function update (tree: TreeNode[], visible = false) {
      tree.forEach(t => {
        if (t.parentID === node.id) {
          if (t.visible !== visible) {
            t.visible = visible
            ids.push(t.id as string)
          }
        } else {
          t.children && update(t.children, visible)
        }
      })
    }

    update(treeData, node.visible)

    cb?.(ids)
    setTreeData([...treeData])
  }

  // TODO: need refactor, atomic update
  const updateTreeState = (node: TreeNode, isNewLayer?: boolean) => {
    function update (tree: TreeNode[], node: TreeNode, isNewLayer?: boolean) {
      if (node && node.type === 'map' && !node.parentID && isNewLayer) {
        tree.push(node)
      } else {
        tree.forEach((t, idx) => {
          if (isNewLayer) {
            if (t.id === node.parentID) {
              t.children = [...(t.children || []), node]
            }
          } else {
            if (t.id === node.id) {
              if (node.removed) {
                tree.splice(idx, 1)
              } else {
                t.title = node.title
                t.creating = node.creating
                t.editing = node.editing
              }
            }
          }

          t.children && update(t.children, node, isNewLayer)
        })
      }
    }

    update(treeData, node, isNewLayer)

    setTreeData([...treeData])
  }

  return {
    updateTreeState,
    treeData,
    expandedKeys,
    setExpandedKeys,
    setTreeData,
    updateChildrenVisibility,
    updateParentVisibility,
    formatDataToTreeNode,
    insertNodeToTree,
    refreshTreeData,
    getSerializableTreeData
  }
}
