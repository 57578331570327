import React, { useEffect, useRef, useState } from 'react'
import * as echarts from 'echarts/core'
import {
  BarChart,
  BarSeriesOption,
  PieChart,
  PieSeriesOption,
  LineChart,
  LineSeriesOption
} from 'echarts/charts'
import {
  LegendComponent,
  LegendComponentOption,
  TooltipComponent,
  TooltipComponentOption,
  GridComponent,
  GridComponentOption
} from 'echarts/components'
import { CanvasRenderer } from 'echarts/renderers'
import { EChartsType } from 'echarts/core'

import styles from './chart.module.scss'

export type ChartOption = echarts.ComposeOption<BarSeriesOption |
  PieSeriesOption |
  LineSeriesOption |
  GridComponentOption |
  TooltipComponentOption |
  LegendComponentOption>

echarts.use([
  BarChart,
  PieChart,
  LineChart,
  LegendComponent,
  TooltipComponent,
  GridComponent,
  CanvasRenderer
])

export interface Props {
  option?: ChartOption
  onError?: () => void
  onFinish?: () => void
}

const Chart: React.FC<Props> = ({ option, onError, onFinish }) => {
  const chartContainer = useRef<HTMLDivElement>(null)
  const chart = useRef<EChartsType>()
  const [chartError, setChartError] = useState(false)

  useEffect(() => {
    try {
      if (chartContainer.current) {
        if (!chart.current) {
          chart.current = echarts.init(chartContainer.current)
        }

        option && chart.current?.setOption(option, {
          notMerge: true
        })
        setChartError(false)
        onFinish?.()
      }
    } catch (error) {
      setChartError(true)
      onError?.()
      throw error
    }
  }, [onError, onFinish, option])

  return (
    <div className={styles.chartContainer}>
      {chartError && <div className={styles.chartErrorMask}>
        图表配置有误，无法预览
      </div>}
      <div ref={chartContainer} className={styles.chart} />
    </div>
  )
}

export default Chart
