import cx from 'clsx'
import React from 'react'
import styles from './checkbox.module.scss'

export interface Props {
  value: true | null;
  onChange: (v: true | null) => void;
}

export const CheckboxRenderer: React.FC<Partial<Props>> = (props) => {
  const { value, onChange } = props

  function handleChange (e: React.ChangeEvent<HTMLInputElement>) {
    onChange?.(e.target.value ? true : null) 
  }

  return (
    <div className={cx(styles.wrap, value && styles.checked)} data-checkbox="check">
      <div className={styles.inputWrap}>
        <input
          className="ag-input-field-input ag-checkbox-input" type="checkbox" 
          checked={value ?? undefined} onChange={handleChange}></input>
      </div>

    </div>
  )
}
