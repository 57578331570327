import find from 'lodash/find'
import last from 'lodash/last'
import filter from 'lodash/filter'
import { 
  addAttachment,
  updateAttachments,
  deleteAttachment,
  DeleteAttachmentPayload,
  AddAttachmentPayload,
  UpdateCellsPayload
} from 'src/state/tableNode/actions'
import { tableNodeSelectors } from 'src/state/tableNode/slice'
import type { UndoRedo } from 'src/features/undoRedo/undoRedo'
import type { RootState } from 'src/store'
import { FileParams } from 'src/api/tablenodes'

const addAttachmentUndoRedo = {
  action: addAttachment,
  undoAction: deleteAttachment,
  redoAction: updateAttachments,
  getUndoPayload: (arg: AddAttachmentPayload) => {
    return arg
  },
  getFulfilledUndoPayload (pendingUndoPayload: AddAttachmentPayload, state: RootState): DeleteAttachmentPayload {
    const { id, columnId, rowId } = pendingUndoPayload

    const tableNode = tableNodeSelectors.selectById(state, id)
    const userId = state.user.userId
    const row = find(tableNode?.rows, r => r.id === rowId)
    const file = last(filter(row?.cells[columnId] as FileParams[], f => f.uploaderID === userId))

    return {
      id,
      rowId,
      columnId,
      fileId: file?.fileID as string
    }
  },
  getRedoPayload: (arg: AddAttachmentPayload, state: RootState): UpdateCellsPayload => {
    const { id, columnId, rowId } = arg
    const tableNode = tableNodeSelectors.selectById(state, id)
    const row = find(tableNode?.rows, r => r.id === rowId)
    const attachments = row?.cells[columnId] as FileParams[]

    return {
      id,
      rowId,
      cells: {
        [columnId]: attachments
      }
    }
  }
} as UndoRedo

export default addAttachmentUndoRedo