import pick from 'lodash/pick'
import type { TypeOptions, ColumnType } from 'src/typings/tableNode'

export const MAX_FILTER_COUNT = 6

export interface Condition {
  key: string;
  value: string;
  name: string;
}

export interface Relation {
  key: string;
  mark?: string;
  name: string;
}

export interface Filter {
  id?: number | string;
  condition?: Condition;
  field?: {
    id: string;
    type: ColumnType;
    name: string;
    typeOptions: TypeOptions
  };
  relation?: Relation;
  value?: string | number;
}

export enum CONDITIONS_ENUM {
  WHEN = 'WHEN',
  OR = 'OR',
  AND = 'AND'
}

export const CONDITIONS: Record<string, Condition> = {
  WHEN: {
    key: 'WHEN',
    value: 'WHEN',
    name: '当'
  },
  OR: {
    key: 'OR',
    value: 'OR',
    name: '或者'
  },
  AND: {
    key: 'AND',
    value: 'AND',
    name: '并且'
  }
}
export const RELATIONS: Record<string, Relation> = {
  is: {
    key: 'is',
    name: '是'
  },
  isNot: {
    key: 'isNot',
    name: '不是'
  },
  equals: {
    key: 'equals',
    mark: '=',
    name: '等于'
  },
  notEqual: {
    key: 'notEqual',
    mark: '≠',
    name: '不等于'
  },
  startsWith: {
    key: 'startsWith',
    name: '开头为'
  },
  endsWith: {
    key: 'endsWith',
    name: '结尾为'
  },
  lessThan: {
    key: 'lessThan',
    mark: '<',
    name: '小于'
  },
  lessThanOrEqual: {
    key: 'lessThanOrEqual',
    mark: '≤',
    name: '小于等于'
  },
  greaterThan: {
    key: 'greaterThan',
    mark: '>',
    name: '大于'
  },
  greaterThanOrEqual: {
    key: 'greaterThanOrEqual',
    mark: '≥',
    name: '大于等于'
  },
  contains: {
    key: 'contains',
    name: '包括'
  },
  notContains: {
    key: 'notContains',
    name: '不包括'
  },
  empty: {
    key: 'empty',
    name: '为空'
  },
  isNull: {
    key: 'isNull',
    name: '为空'
  },
  isNotNull: {
    key: 'isNotNull',
    name: '不为空'
  }
}

/**
 * 集成ag-grid内置的filter功能, 以提高性能.
 * https://www.ag-grid.com/documentation/javascript/filter-provided-simple/#simple-filter-options
 */
export enum RELATIONS_ENUM {
  is = 'is',
  isNot = 'isNot',
  equals = 'equals',
  notEqual = 'notEqual',
  startsWith = 'startsWith',
  endsWith = 'endsWith',
  lessThan = 'lessThan',
  lessThanOrEqual = 'lessThanOrEqual',
  greaterThan = 'greaterThan',
  greaterThanOrEqual = 'greaterThanOrEqual',
  contains = 'contains',
  notContains = 'notContains',
  empty = 'empty',
  inRange = 'inRange', // ag-grid提供该功能, 但是mt暂时没有对应的UI支持
  isNull = 'isNull', // ag-grid未提供isNull
  isNotNull = 'isNotNull' // ag-grid未提供isNotNull
}

export const USE_MARK_RELATIONS = ['number']
export const NULLABLE_RELATIONS = ['empty', 'notEmpty', 'isNull', 'isNotNull']
export const RELATION_KEYS: string[] = Object.keys(RELATIONS)
export const isNullableRelation = (key?: string): boolean => {
  if (key) {
    return NULLABLE_RELATIONS.indexOf(key) > -1
  } else {
    return false
  }
}
export const getFilterRelations = (fieldType: string): Record<string, unknown> | [] => {
  switch (fieldType) {
  case 'singleLineText':
  case 'multiLineText':
    return pick(RELATIONS,
      ['equals', 'notEqual', 'startsWith', 'endsWith', 'contains', 'notContains', 'isNull', 'isNotNull']
    )
  case 'number':
    return pick(RELATIONS,
      ['equals', 'notEqual', 'lessThan', 'lessThanOrEqual', 'greaterThan', 'greaterThanOrEqual', 'isNull', 'isNotNull']
    )
  case 'singleChoice':
  case 'multiChoice':
    return pick(RELATIONS, ['equals', 'notEqual', 'contains', 'notContains', 'isNull', 'isNotNull'])
  case 'datetime':
    return pick(RELATIONS, [
      'is', 'isNot', 'lessThan', 'greaterThan', 'lessThanOrEqual', 'greaterThanOrEqual', 'isNull', 'isNotNull']
    )
  default:
    return []
  }
}
export const getRelationName = (fieldType: string, o: Relation): string => {
  return USE_MARK_RELATIONS.indexOf(fieldType) > -1 ? o.mark || o.name : o.name
}
