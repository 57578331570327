import AppConfig from 'src/config'
import { createInstance } from 'src/utils/fetch'
import { APIResponse } from 'src/typings/common'

const fetch = createInstance({
  baseURL: `${AppConfig.baseApiURL}/users/`
})

const UserAPI = {
  async update (form: unknown): Promise<APIResponse> {
    const { data } = await fetch.patch('info/', form)

    return data.detail
  },
  async match (phone: string): Promise<APIResponse> {
    const { data } = await fetch.get('match/', { params: { phone }, ignoreError: true })

    return data
  },
  async search (q: string, orgID: number): Promise<APIResponse> {
    const { data } = await fetch.get('search/', { params: { q, orgID } })

    return data
  }
}

export default UserAPI
