import map from 'lodash/map'
import isNil from 'lodash/isNil'
import { FillConfig } from 'qumap/packages/core/types/types'
import { getScaleFunction } from 'qumap/packages/core/'
import { GroupLayer, MapLegendItem } from 'src/typings/map'
import { TypeOptions } from 'src/typings/tableNode'
import { PICKER_COLORS } from 'src/constants/colors'
import { formatNumberWithPrecision } from './format'


export default function getLegends (
  layer: GroupLayer, 
  fillConfig?: FillConfig, 
  isSingleChoice?: boolean,
  typeOptions?: TypeOptions
): MapLegendItem[] {
  const mapInfo = layer.mapInfo
  let colors = fillConfig?.colorRange.colors || []

  if (mapInfo.fillFieldID) {
    if(!layer.data.length) {
      return []
    }
    let min = Number.MAX_SAFE_INTEGER
    let max = 0
    const legends: MapLegendItem[] = []
    let withoutValuePois = 0

    const data = map(layer.data, d => {
      if(!isNil(d.fillField)){
        const fieldValue = parseFloat(d.fillField)
        min = fieldValue < min ? fieldValue : min
        max = fieldValue > max ? fieldValue : max
      }
      else {
        withoutValuePois++
      }
      return d.fillField
    })
    const scaleType = fillConfig?.colorScale 
    const range = scaleType === 'quantize' ? [min,max] : data
    const reversed = fillConfig?.colorRange.reversed

    if (reversed && scaleType !== 'ordinal') {
      colors = [...colors].reverse()
    }
    if (withoutValuePois) {
      legends.push({
        color: 'transparent',
        name: `无数据(${withoutValuePois})`
      })
    }
    if(scaleType) {
      if (isSingleChoice) {
        legends.push(...map(colors, (c, index) => {
          return {
            color: c,
            name: typeOptions?.choices.find( choice => PICKER_COLORS[choice.color] === c)?.name ?? ''
          }
        }))
      }
      else if(scaleType === 'quantile' || scaleType === 'quantize') {
        const scaleFunction = getScaleFunction(scaleType, colors, range as number[])
        legends.push(...map(colors, c => {
          const range = scaleFunction.invertExtent(c)
          return {
            color: c,
            name: (!isNil(range[0]) && !isNil(range[1])) ? 
              `${formatNumberWithPrecision(range[0], 3)}-${formatNumberWithPrecision(range[1], 3)}`
              : ''
          }
        }))
      }
    }
    return legends
  }
  else {
    return [{
      color: layer.mapInfo.fillConfig?.colors?.[0],
      name: '点位分布'
    }]
  }
}
