import { Middleware } from '@reduxjs/toolkit'
import { actions as statusActions } from 'src/state/status/slice'

const responseHandler: Middleware = ({ dispatch }) => (next) => (action) => {
  if (action.payload?.requestID && action.type !== 'request/finish') {
    if (action.payload.__method !== 'get') {
      dispatch(statusActions.requestStart(action.payload.requestID))
    }
  }

  return next(action)
}

export default responseHandler
