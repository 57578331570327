import React, { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import cx from 'clsx'
import { PlusOutlined, CheckOutlined, UserOutlined } from '@ant-design/icons'
import { Layout, Button, Dropdown, Menu } from 'antd'
import CreateProjectModal from 'src/components/workspace/CreateProjectModal'
import { useWorkspaces } from 'src/hooks/workspace/useWorkspaces'
import { useParameters } from 'src/hooks/common/useParameters'
import Icon, { IconType } from 'src/components/common/Icon/Icon'
import { useDialogState } from 'src/hooks/common/useDialog'
import { OrgAvatar } from 'src/components/common/Avatar'
import { Workspace } from 'src/typings/workspace'
import CreateTeamModal from 'src/components/workspace/CreateTeamModal'
import { OrgPermission, RoleType } from 'src/constants/permissions'
import { useWorkspaceRoles } from 'src/hooks/workspace/useWorkspaceRoles'
import styles from './sider.module.scss'

const { Sider } = Layout

interface Props {
  activeMenu?: string;
  onMenuChange: (menu: string) => void;
}

const WorkspaceSider: React.FC<Props> = ({
  activeMenu = 'projects',
  onMenuChange
}) => {
  const createProjectDialog = useDialogState()
  const { workspaceId, workspaceType, setWorkspaceIdAndType } = useParameters()
  const { workspace, workspaces, userWorkspaceId } = useWorkspaces(workspaceType, workspaceId)
  const { checkOrgPermission } = useWorkspaceRoles()
  const userWorkspace = workspaces?.find(w => w.type === 'user')
  const orgWorkspaces = workspaces?.filter(w => w.type !== 'user')
  const CreateTeamDialog = useDialogState()
  const history = useHistory()
  useEffect(() =>{
    if(workspaceType === 'uw' && !workspaceId && userWorkspaceId) {
      setWorkspaceIdAndType(userWorkspaceId, 'uw')
    }
  }, [workspaceType, workspaceId, userWorkspaceId, setWorkspaceIdAndType])
  function handleSwitchWorkspace (workspace: Workspace) {
    onMenuChange('projects')
    if (workspace.type === 'user') {
      setWorkspaceIdAndType(workspace.id, 'uw')
    } else {
      setWorkspaceIdAndType(workspace.id, 'w')
    }
  }

  const renderWorkspaceMenuItemText = (text: string) => {
    return (
      <div className={styles.workspaceMenuItemTitle}>
        <div className={styles.workspaceMenuItemText}>{text}</div>
        <CheckOutlined className={styles.workspaceMenuCheck} />
      </div>
    )
  }

  const workspaceDropdownMenu = (
    <div className={styles.workspaceDropdownMenu}>
      <div>
        <Menu mode="inline" defaultSelectedKeys={[`${workspaceType}_${workspaceId}`]}>
          {
            orgWorkspaces?.map(w => (
              <Menu.Item
                className={styles.workspaceMenuItem}
                key={`w_${w.id}`} onClick={() => handleSwitchWorkspace(w)}
                icon={<OrgAvatar title={w.name} src={w.avatar} />}>
                {renderWorkspaceMenuItemText(w.name)}
              </Menu.Item>
            ))
          }
          {
            userWorkspace &&
            <Menu.Item
              className={styles.workspaceMenuItem}
              key={`uw_${userWorkspace.id}`} onClick={() => handleSwitchWorkspace(userWorkspace)}
              icon={<UserOutlined className={styles.userIcon} />}>
              {renderWorkspaceMenuItemText('个人桌面')}
            </Menu.Item>
          }
        </Menu>
      </div>
      <Button
        icon={<PlusOutlined />} type="text"
        className={styles.createWorkspaceButton} onClick={CreateTeamDialog.open}>
        新建团队
      </Button>
      <CreateTeamModal dialog={CreateTeamDialog} />
    </div>
  )

  const sideMenu = [{
    key: 'projects',
    title: '项目列表',
    iconName: 'projectList' as IconType,
    type: ['user', 'org'],
    extra: (workspace?.type === 'user' || checkOrgPermission(workspaceId, OrgPermission.ProjectCreate as RoleType)) &&
      <>
        <div onClick={ev => ev.stopPropagation()}>
          <CreateProjectModal dialog={createProjectDialog} type={workspace?.type} />
        </div>
        <Button
          type="primary"
          className={styles.plusButton}
          icon={<PlusOutlined />}
          onClick={createProjectDialog.open}
        />
      </>,
    permission: OrgPermission.ProjectPage
  }, {
    key: 'members',
    type: ['org'],
    title: '成员列表',
    iconName: 'members' as IconType,
    permission: OrgPermission.MemberPage
  }, {
    key: 'invitations',
    type: ['org'],
    title: '邀请成员',
    iconName: 'inviteMember' as IconType,
    permission: OrgPermission.MemberInvite
  }, {
    key: 'settings',
    type: ['org'],
    title: '团队设置',
    iconName: 'settings' as IconType,
    permission: OrgPermission.SettingPage
  }, {
    key: 'recycle',
    type: ['user', 'org'],
    title: '回收站',
    iconName: 'recycleBin' as IconType,
    permission: OrgPermission.RecycleBinPage
  } 
  // ,{
  //   key: 'help',
  //   title: '使用帮助',
  //   type: ['user', 'org'],
  //   iconName: 'help' as IconType,
  //   permission: OrgPermission.HelpPage
  // }
  ]

  function handleMenuItemClick (key: string) {
    const wid = workspace?.id ?? workspaceId
    if (key === 'projects') {
      history.push(`/${workspaceType}/${wid}`)
    } else {
      history.push(`/${workspaceType}/${wid}/${key}`)
    }
    onMenuChange(key)
  }

  return (
    <Sider className={styles.side} width={220}>
      <div className={styles.dropdown}>
        <Dropdown overlay={workspaceDropdownMenu} trigger={['click']}>
          <Button block type="text" className={styles.workspaceDropdownButton}>
            {
              workspace && (
                workspace.type === 'user' ?
                  '个人桌面' :
                  <div className={styles.workspaceOrgName}>
                    <OrgAvatar title={workspace.name} src={workspace.avatar} />
                    <span className={styles.workspaceOrgText}>{workspace.name}</span>
                  </div>
              )
            }
            <Icon type="arrowExpand" className={styles.expandIcon} />
          </Button>
        </Dropdown>
      </div>
      <ul className={styles.menu}>
        {
          sideMenu.map(item =>
            item.type.find(t => t === workspace?.type) &&
            (workspace?.type === 'user' || checkOrgPermission(workspaceId, item.permission as RoleType)) &&
            <li
              key={item.key} onClick={() => handleMenuItemClick(item.key)}
              className={cx(styles.menuItem, item.key === activeMenu ? styles.active : '')}
            >
              <Icon type={item.iconName} className={styles.menuIcon} />
              <div className={styles.menuText}>{item.title}</div>
              {item.extra}
            </li>)
        }
      </ul>
    </Sider>
  )
}

export default WorkspaceSider
