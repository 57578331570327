import omit from 'lodash/omit'
import AppConfig from 'src/config'
import { createInstance } from 'src/utils/fetch'
import { APIResponse } from 'src/typings/common'

const fetch = createInstance({
  baseURL: `${AppConfig.baseApiURL}/orgs/`
})

export interface Member {
  avatar: string;
  email: string;
  id: number;
  name: string;
  phone: string;
  role: string;
}

const OrganizationAPI = {
  async create (name: string): Promise<APIResponse> {
    const { data } = await fetch.post('', { name })

    return data
  },
  async update (id: number, form: unknown): Promise<APIResponse> {
    const { data } = await fetch.patch(`${id}/`, form)

    return data
  },
  async getMembers (id: number): Promise<{ detail: Member[] }> {
    const { data } = await fetch.get(`${id}/members/`)

    return data
  },
  async updateMember (id: number, info: Partial<Member>): Promise<APIResponse> {
    const { data } = await fetch.patch(`${id}/members/${info.id}/`, omit(info, 'id'))

    return data
  },
  async deleteMember (id: number, memberId: number): Promise<APIResponse> {
    const { data } = await fetch.delete(`${id}/members/${memberId}/`)

    return data
  }
}

export default OrganizationAPI
