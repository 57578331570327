import React, { forwardRef, useImperativeHandle, useState } from 'react'
import { ICellEditorParams } from 'ag-grid-community'
import { useDialogState } from 'src/hooks/common/useDialog'
import { Props } from './TableRenderer'
import { TablePreviewCellRenderer } from './TablePreviewCellRenderer'
import TableEditorModal from './TableEditorModal'

type RowData = Record<string, unknown>[]
interface EditorProps extends Props, ICellEditorParams {
  value: RowData
}
export const TableEditor = forwardRef((props: EditorProps, ref) => {
  const dialog = useDialogState()
  const [rowsData, setRowsData] = useState<RowData | undefined | null>(props.value)
  const [isCancel, setIsCancel] = useState(true)
  useImperativeHandle(ref, () => {
    return {
      getValue () {
        return rowsData ?? null
      },
      isCancelAfterEnd (){
        return isCancel
      },
      afterGuiAttached () {
        setRowsData(props.value)
        dialog.open()
      }
    }
  })
  function handleEdtiorSave (rows?: RowData | null) {
    setRowsData(rows)
    setIsCancel(false)
    setTimeout(() =>{
      props.stopEditing()
    })
  }
  function handleEdtiorCancel () {
    setIsCancel(true)
    setTimeout(() =>{
      props.stopEditing()
    })
  }

  return (
    <div style={{ height: '100%' }}>
      {
        props.typeOptions?.columns && <TableEditorModal 
          dialog={dialog} 
          onOk={handleEdtiorSave}
          onCancel={handleEdtiorCancel}
          columns={props.typeOptions?.columns}
          rows={rowsData}
        />
      }
      {
        props.value && props.typeOptions &&
        <TablePreviewCellRenderer value={props.value} typeOptions={props.typeOptions} />
      }
    </div>
  )
})
