import React, { useEffect, useState } from 'react'
import noop from 'lodash/noop'
import { ChromePicker, ColorResult } from 'react-color'
import { Popover } from 'antd'
import styles from './colorPicker.module.scss'


export interface Props {
  color?: string;
  onChange?: (color: string) => void
}

const ColorPicker: React.FC<Props> = ({
  color,
  onChange = noop
}) => {
  const [innerColor, setInnerColor] = useState(color)

  function handleChange (color: ColorResult) {
    setInnerColor(color.hex)
  }

  useEffect(() => {
    setInnerColor(color)
  }, [color])

  return (
    <Popover
      placement="bottomLeft"
      overlayClassName={styles.popover}
      content={
        <div className={styles.picker}>
          <ChromePicker
            color={innerColor}
            disableAlpha={true}
            onChange={handleChange}
            onChangeComplete={(color: ColorResult) => {
              onChange(color.hex)
            }}
          />
        </div>
      }
      trigger="click">

      <div className={styles.currentColor} style={{ backgroundColor: innerColor }} />
    </Popover>
  )
}

export default ColorPicker
