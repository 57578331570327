import React from 'react'
import ReactDOM from 'react-dom/server'
import CellRenderer from 'src/components/field/CellRenderer/CellRenderer'
import { FormulaRenderer } from './FormulaRenderer'

export class FormulaCellRenderer extends CellRenderer {
  init (p: CellRenderer['params']): void {
    this.value = p?.realValue
    this.typeOptions = p?.typeOptions
    this.params = p
  }

  getGui (): HTMLElement {
    const el = this.createEL()
    el.innerHTML = ReactDOM.renderToStaticMarkup(<FormulaRenderer value={this.value} typeOptions={this.typeOptions} />)

    return this.withCollaborators(el, this.params?.collaborator)
  }

  refresh (): boolean {
    return false
  }
}
