import React from 'react'
import { Col, Radio, Row } from 'antd'
import Icon from 'src/components/common/Icon'
import type { ChartType } from 'src/typings/chart'
import styles from './chartTypeSelector.module.scss'

interface Props {
  value?: ChartType;
  onChange?: (v: ChartType) => void;
}

const ChartTypeSelector: React.FC<Props> = ({
  value,
  onChange
}) => {
  return <Radio.Group
    className={styles.radioGroup}
    value={value}
    onChange={e => onChange?.(e.target.value)}
  >
    <Row gutter={12}>
      <Col span={8}>
        <Radio value="bar">
          <div className={styles.item}>
            <Icon type="barChart" className={styles.itemIcon} />
          </div>
          <div className={styles.itemTitle}>柱状图</div>
        </Radio>
      </Col>
      <Col span={8}>
        <Radio value="pie">
          <div className={styles.item}>
            <Icon type="pieChart" className={styles.itemIcon} />
          </div>
          <div className={styles.itemTitle}>饼图</div>
        </Radio>
      </Col>
      <Col span={8}>
        <Radio value="line">
          <div className={styles.item}>
            <Icon type="lineChart" className={styles.itemIcon} />
          </div>
          <div className={styles.itemTitle}>折线图</div>
        </Radio>
      </Col>
    </Row>
  </Radio.Group>
}

export default ChartTypeSelector
