import React from 'react'
import { Radio, Row, Col, Tooltip } from 'antd'
import type { RadioChangeEvent } from 'antd/lib/radio/interface'
import Icon from 'src/components/common/Icon'
import { OVERLAY_EDITOR_TYPE_SELECT_TOOLTIP } from 'src/constants/tooltip'
import styles from './overlayTypeSelector.module.scss'

interface Props {
  value: string;
  isEditing?: boolean;
  disabled?: boolean;
  onChange: (e: RadioChangeEvent) => void
}

const OverlayTypeSelector: React.FC<Props> = ({
  value = 'marker',
  isEditing = false,
  disabled = true,
  onChange
}) => {
  const getTooltipTitle = (type: string) => {
    return value !== type && isEditing && OVERLAY_EDITOR_TYPE_SELECT_TOOLTIP
  }
  return (
    <Radio.Group className={styles.radioGroup} value={value} onChange={onChange}>
      <Row gutter={12}>
        <Col span={8} className={styles.col}>
          <Radio value="marker" disabled={disabled ? false : value !== 'marker'}>
            <Tooltip title={getTooltipTitle('marker')}>
              <div className={styles.item}>
                <Icon type="point" className={styles.itemIcon} />
              点
              </div>
            </Tooltip>
          </Radio>
        </Col>
        <Col span={8}>
          <Radio value="polyline" disabled={disabled ? false : value !== 'polyline'}>
            <Tooltip title={getTooltipTitle('polyline')}>
              <div className={styles.item}>
                <Icon type="line" className={styles.itemIcon} />
              线
              </div>
            </Tooltip>
          </Radio>
        </Col>
        <Col span={8}>
          <Radio value="polygon" disabled={disabled ? false : value !== 'polygon'}>
            <Tooltip title={getTooltipTitle('polygon')}>
              <div className={styles.item}>
                <Icon type="polygon" className={styles.itemIcon} />
              面
              </div>
            </Tooltip>
          </Radio>
        </Col>
      </Row>
    </Radio.Group>
  )
}

export default OverlayTypeSelector
