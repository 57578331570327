import React, { useState, useEffect, useRef } from 'react'
import { useDispatch } from 'react-redux'
import { Input } from 'antd'
import trim from 'lodash/trim'
import { MultiLineTextEditor } from 'src/components/field/MultiLineText'
import { DatetimeEditor } from 'src/components/field/Datetime'
import { NumberEditor } from 'src/components/field/Number'
import { SelectEditor } from 'src/components/field/Select'
import { FormulaEditor } from 'src/components/field/Formula'
import { AttachmentEditor } from 'src/components/field/Attachment'
import type { Cell, Column } from 'src/typings/tableNode'
import { updateCells } from 'src/state/tableNode/actions'
import { CoordinateEditor } from 'src/components/field/Coordinate'

export interface Item extends Cell {
  typeOptions?: Column['typeOptions']
}

interface FieldEditorProps {
  item: Item;
  onEndEdit: () => void;
}

interface EditorRef {
  getValue: () => string | number | string[];
}

const FieldEditor: React.FC<FieldEditorProps> = (props) => {
  const { item, onEndEdit } = props
  const dispatch = useDispatch()
  const [value, setValue] = useState<unknown>(item.value)
  const editor = useRef<EditorRef>()

  useEffect(() => {
    setValue(item?.value as string)
  }, [item?.value])

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValue(trim(e.target.value))
  }
  const saveField = (val: unknown) => {
    if (item.tableNodeId && item.rowId && val !== undefined) {
      dispatch(updateCells({
        id: item.tableNodeId,
        rowId: item.rowId,
        cells: {
          [item.id]: val
        }
      }))
    }
  }

  const handleSaveField = () => {
    saveField(value)

    onEndEdit()
  }

  function handleEditorSave () {
    if (editor.current?.getValue() !== undefined) {
      setValue(editor.current?.getValue())
      saveField(editor.current?.getValue())
    }

    onEndEdit()
  }

  const renderFieldEditor = () => {
    switch (item.type) {
    case 'coordinate':
      return <CoordinateEditor
        ref={editor}
        data={{ id: item.rowId, tableNodeId: item.tableNodeId }}
        colDef={{ field: item.id }}
        value={value}
        onBlur={() => onEndEdit()}
      />
    case 'datetime':
      return <DatetimeEditor
        ref={editor}
        typeOptions={item.typeOptions}
        value={value}
        onBlur={handleEditorSave}
      />
    case 'multiLineText':
      return <MultiLineTextEditor
        ref={editor}
        value={value}
        onBlur={handleEditorSave}
        onPressEnter={handleEditorSave}
      />
    case 'number':
      return <NumberEditor
        ref={editor}
        typeOptions={item.typeOptions}
        value={value}
        onBlur={handleEditorSave}
        onPressEnter={handleEditorSave}
      />
    case 'singleChoice':
    case 'multiChoice':
      return <SelectEditor
        ref={editor}
        typeOptions={item.typeOptions}
        value={value}
        onBlur={handleEditorSave}
        type={item.type}
      />
    case 'formula':
      return <FormulaEditor
        ref={editor}
        typeOptions={item.typeOptions}
        value={value}
      />
    case 'attachment':
      return <AttachmentEditor
        ref={editor}
        data={{ id: item.rowId }}
        colDef={{ field: item.id }}
        value={value}
        onBlur={() => onEndEdit()}
        tableNodeId={item.tableNodeId}
        inDetailPanel={true}
      />
    default:
      return (
        <div>
          <Input
            value={value as string}
            onChange={handleInputChange}
            onBlur={handleSaveField}
            onKeyDown={(ev) => ev.key === 'Enter' && handleSaveField()}
          />
        </div>
      )
    }
  }

  return (
    <>
      {renderFieldEditor()}
    </>
  )
}

export default FieldEditor
