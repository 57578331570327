import React, { useEffect } from 'react'
import startsWith from 'lodash/startsWith'
import type { FormInstance } from 'rc-field-form/es/interface'
import { Form, Select } from 'antd'
import { InfoCircleOutlined } from '@ant-design/icons'
import { useMessages } from 'src/hooks/chart/useChart'

const { Option } = Select

interface Props {
  name: string;
  dependency?: string;
}

interface FormItemProps extends Props {
  form: FormInstance;
}
const FormItem = (props: FormItemProps) => {
  const { dependency, form, name } = props
  const statsMethod = dependency && form.getFieldValue(dependency)
  const { label, tooltip } = useMessages(name)

  useEffect(() => {
    const { getFieldValue, setFieldsValue } = form
    if (statsMethod === 'multiField' && startsWith(getFieldValue(name), 'yAxis')) {
      setFieldsValue({
        [name]: 'listAsc'
      })  
    }
  }, [name, statsMethod, form])

  return (
    <Form.Item
      name={name}
      label={label}
      tooltip={{ title: tooltip, icon: <InfoCircleOutlined /> }}
    >
      <Select>
        <Option value="listAsc">按列表顺序-正序</Option>
        <Option value="listDesc">按列表顺序-倒序</Option>
        {
          statsMethod !== 'multiField' &&
          <>
            <Option value="yAxisAsc">按 Y 轴数值从小到大</Option>
            <Option value="yAxisDesc">按 Y 轴数值从大到小</Option>
          </>
        }
      </Select>
    </Form.Item>
  )  
}

export const SortMethodFormItem: React.FC<Props> = (props) => {
  return <Form.Item
    noStyle
    dependencies={[props.dependency as string]}
  >
    {(form) => <FormItem form={form} {...props} />}
  </Form.Item>  
}

export default SortMethodFormItem