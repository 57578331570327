import React from 'react'
import { Form, Select } from 'antd'
import type { FormInstance } from 'rc-field-form/es/interface'
import { InfoCircleOutlined } from '@ant-design/icons'
import filter from 'lodash/filter'
import map from 'lodash/map'
import { useMessages } from 'src/hooks/chart/useChart'

const { Option } = Select

interface Props {
  options?: { value: string, label: string }[];
  name: string;
  dependency?: string;
}

interface FormItemProps extends Props {
  form: FormInstance
}
const FormItem = (props: FormItemProps) => {
  const { dependency, form, name, options } = props
  const { label, tooltip } = useMessages(name)
  const chartType = dependency && form.getFieldValue(dependency)
  const isPieChart = chartType === 'pie'
  const filteredOptions = isPieChart ? filter(options, o => o.value !== 'multiField') : options

  return <Form.Item
    name={name}
    label={label}
    tooltip={{ title: tooltip, icon: <InfoCircleOutlined /> }}
  >
    <Select>
      {map(filteredOptions, (o, i) => <Option value={o.value} key={i}>{o.label}</Option>)}
    </Select>
  </Form.Item>
}
export const MethodFormItem: React.FC<Props> = (props) => {
  return <Form.Item
    noStyle
    dependencies={[props.dependency as string]}
  >
    {(form) => <FormItem form={form} {...props} />}
  </Form.Item>
}

export default MethodFormItem