import AppConfig from 'src/config'
import { createInstance } from 'src/utils/fetch'
import { FormulaType } from 'src/typings/formula'
import { APIResponse } from 'src/typings/common'

const fetch = createInstance({
  baseURL: `${AppConfig.baseApiURL}/formula/`
})

const FormulaAPI = {
  async docs (): Promise<APIResponse<FormulaType[]>> {
    const { data } = await fetch.get('docs/')

    return data
  },
  async check (source?: string): Promise<APIResponse> {
    const { data } = await fetch.post('check/', {
      source
    })

    return data
  }
}

export default FormulaAPI
