import React, { useImperativeHandle, useRef } from 'react'
import { Button, Form, Input } from 'antd'
import { nanoid } from '@reduxjs/toolkit'
import type { FormInstance } from 'antd/lib/form/hooks/useForm'
import { useDialogState } from 'src/hooks/common/useDialog'
import type { TypeOptions } from 'src/typings/tableNode'
import TableConfigModal from './TableConfigModal'
import styles from './tableConfig.module.scss'

type Columns = TypeOptions['columns']

const initialColumnData = [
  {
    id: nanoid(),
    type: 'singleLineText',
    name: '标题'
  }
] as Columns

interface Props {
  form?: React.Ref<FormInstance>;
  config?: TypeOptions;
  isNewColumn: boolean;
}
export const TableConfig: React.FC<Props> = ({
  form,
  config,
  isNewColumn
}) => {
  const [innerForm] = Form.useForm()
  const dialog = useDialogState()
  const columndData = isNewColumn ? initialColumnData : config?.columns ?? []
  const demoRows: Record<string, unknown>[] = isNewColumn ? [{
    [initialColumnData[0].id]: '示例数据' 
  }] : []
  const wrapEl = useRef(null)

  useImperativeHandle(form, () => innerForm)

  function handleConfigClick () {
    dialog.open()
  }

  function handleOkClick (columns: TypeOptions['columns']) {
    dialog.close()
    innerForm.setFieldsValue({
      columns 
    })
  }

  return (
    <div className={styles.wrap} ref={wrapEl}>
      <Button className="gray-btn" type="default" block onClick={handleConfigClick}>设置表格</Button>
      <TableConfigModal 
        dialog={dialog} 
        onOk={handleOkClick}
        columns={columndData}
        rows={demoRows}
        getContainer={() => wrapEl.current || document.body}
      />
      <Form 
        name="table" 
        form={innerForm} 
        hidden
        initialValues={{
          columns: initialColumnData
        }}
      >
        <Form.Item name="columns">
          <Input />
        </Form.Item>
      </Form>
    </div>
  )
}
