import { useCallback, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import forEach from 'lodash/forEach'
import isEqual from 'lodash/isEqual'
import trim from 'lodash/trim'
import compact from 'lodash/compact'
import map from 'lodash/map'
import find from 'lodash/find'
import replace from 'lodash/replace'
import template from 'lodash/template'
import { formulaSelectors } from 'src/state/formula/slice'
import useSelector from 'src/hooks/common/useSelector'
import useFieldConfig from 'src/hooks/project/useFieldConfig'
import { FormulaType } from 'src/typings/formula'
import { getFormulaDocs } from 'src/state/formula/actions'
import { FIELD_TYPES } from 'src/constants/fieldType'
import { Column } from 'src/typings/tableNode'

type GroupType = Record<string, FormulaType[]>

interface Return {
  formulas: FormulaType[]
  formulaGroups: GroupType
  formulaNames: Record<string, FormulaType>
  computableColumns: Column[]
  getFieldNode: (column: Column | undefined) => string
  getFormulaNode: (formula: string, isNew?: boolean) => string
}

export default function useFormula (filterText = ''): Return {
  const dispatch = useDispatch()
  const formulas = useSelector(formulaSelectors.selectAll)
  const [computableColumns, setComputableColumns] = useState<Column[]>([])
  const [formulaGroups, setFormulaGroups] = useState<GroupType>({})
  const [formulaNames, setFormulaNames] = useState<Record<string, FormulaType>>({})
  const { columns, viewColumns } = useFieldConfig()
  const getFormulas = useCallback(() => {
    if (!formulas.length) {
      dispatch(getFormulaDocs())
    }
  }, [formulas, dispatch])

  useEffect(() => {
    getFormulas()
  }, [getFormulas])

  useEffect(() => {
    const comCols = compact(map(viewColumns, column => {
      if (!column.isHidden) {
        const col = find(columns, c => c.id === column.id)
        if (col && col.id === column.id) {
          if (col && FIELD_TYPES[col.type]?.computable && col.name.toLowerCase().indexOf(filterText) > -1) {
            return col
          }
        }
      }
    }))

    if (!isEqual(comCols, computableColumns)) {
      setComputableColumns(comCols)
    }
  }, [computableColumns, columns, viewColumns, filterText])

  useEffect(() => {
    const groups: GroupType = {}
    const names: Record<string, FormulaType> = {}
    forEach(formulas, formula => {
      if (!groups[formula.group]) {
        groups[formula.group] = []
      }

      if (formula.name.toLowerCase().indexOf(trim(filterText)) > -1) {
        groups[formula.group].push(formula)
      }

      names[replace(formula.name, '()', '')] = formula
    })

    if (!isEqual(groups, formulaGroups)) {
      setFormulaGroups(groups)
    }
    if (!isEqual(names, formulaNames)) {
      setFormulaNames(names)
    }
  }, [formulaGroups, formulas, filterText, formulaNames])

  const getFieldNode = (column: Column | undefined) => {
    if (column) {
      const html = column.name
      const compiled = template('<%- value %>')
      // eslint-disable-next-line max-len
      return `<span contentEditable='false' class='variable' data-id='${column.id}'>{<span>${compiled({ value: html })}</span>}</span>`
    } else {
      return `<span contentEditable='false' class='variable error' data-id='undefined'>{<span>无效字段</span>}</span>`
    }
  }
  const getFormulaNode = (formula: string, isNew = false) => {
    if (isNew) {
      return `<span contentEditable='false'>${replace(formula, '()', '')}</span>()`
    } else {
      return `<span contentEditable='false'>${formula}</span>`
    }
  }

  return {
    formulas,
    formulaGroups,
    formulaNames,
    computableColumns,
    getFieldNode,
    getFormulaNode
  }
}
