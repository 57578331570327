import forEach from 'lodash/forEach'
import isArray from 'lodash/isArray'
import replace from 'lodash/replace'
import lodashIndexOf from 'lodash/indexOf'

export function isFormula (type: string): boolean {
  return type === 'formula'
}

export function traverse (nodes: NodeList): string[] {
  const operators: string[] = []
  forEach(nodes, node => {
    if (node.childNodes && node.childNodes.length) {
      const columnId = (node as HTMLElement).getAttribute('data-id')
      if (columnId) {
        operators.push(`$c_${columnId}`)
      } else {
        return operators.push(...traverse(node.childNodes))
      }
    } else {
      const content = node.textContent || ''
      // https://github.com/markedjs/marked/issues/363
      content && operators.push(replace(content, /\r\n|\r|\t|\u00a0|\u2424/g, ''))
    }
  })

  return operators
}

export function indexOf (operand: string | unknown[], value: string | unknown): number {
  if (isArray(operand)) {
    return lodashIndexOf(operand, value)
  } else {
    return operand.indexOf(value as string)
  }
}
