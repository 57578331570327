import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { reducers } from './reducers'
import { undo, redo } from './actions'

export interface UndoRedo {
  undoAction: ReturnType<typeof createAsyncThunk>;
  redoAction: ReturnType<typeof createAsyncThunk>;
  undoPayload: unknown;
  redoPayload: unknown;
  pathname: string;
}

export interface State {
  undo: UndoRedo[];
  redo: UndoRedo[];
}

const initialState = {
  undo: [],
  redo: []
} as State

const undoRedoSlice = createSlice({
  name: 'undoRedo',
  initialState,
  reducers,
  extraReducers (builder) {
    builder.addCase(undo.fulfilled, (state) => {
      const lastItem = state.undo.pop()

      if (lastItem) {
        state.redo.push(lastItem) 
      }
    })

    builder.addCase(redo.fulfilled, (state) => {
      const lastItem = state.redo.pop()

      if (lastItem) {
        state.undo.push(lastItem)
      }
    })
  }
})

export default undoRedoSlice
export const actions = undoRedoSlice.actions