import { useDispatch } from 'react-redux'
import forEach from 'lodash/forEach'
import { GridReadyEvent, RowDragEvent } from 'ag-grid-community'
import { RowDropZoneEvents } from 'ag-grid-community/dist/lib/gridPanel/rowDragFeature'
import useSelector from 'src/hooks/common/useSelector'
import { moveRow } from 'src/state/tableNode/actions'
import { tableNodeSelectors } from 'src/state/tableNode/slice'
import useCollaborator from 'src/hooks/collaborator/useCollaborator'
import { ADD_ROW_ID } from 'src/constants/grid'

interface UseMoveRowReturn {
  addGridDropZone: (params: GridReadyEvent) => void;
  onRowDragMove: (e: RowDragEvent) => void;
  onRowDragEnd: (e: RowDragEvent) => void;
}

const isMoveDown = (rowTop = 0, rowHeight = 0, y = 0): boolean => {
  return (rowTop + rowHeight / 2) < y
}

const cleanUpRowHighlight = () => {
  const aboveRows = document.getElementsByClassName('ag-row-highlight-above')
  const belowRows = document.getElementsByClassName('ag-row-highlight-below')
  Array.from(aboveRows || []).forEach(element => {
    element.classList.remove('ag-row-highlight-above')
  })

  Array.from(belowRows || []).forEach(element => {
    element.classList.remove('ag-row-highlight-below')
  })
}

export default function useMoveRows (tableNodeId: number): UseMoveRowReturn {
  const dispatch = useDispatch()
  const { updateCollaborator } = useCollaborator()
  const currentView = useSelector(state => {
    const tableNode = tableNodeSelectors.selectById(state, tableNodeId)
    return tableNode?.views[0]
  })

  const addGridDropZone = (params: GridReadyEvent) => {
    params.api.addRowDropZone(params.api?.getRowDropZoneParams({} as RowDropZoneEvents))
  }

  const onRowDragMove = (e: RowDragEvent) => {
    cleanUpRowHighlight()
    const dragNode = e.node
    const overNode = e.overNode

    if (
      !dragNode ||
      !dragNode.data ||
      (overNode?.parent && dragNode.parent && overNode?.parent.id !== dragNode.parent.id)
    ) {
      return
    }

    if (dragNode !== overNode) {
      let overNodeId = overNode?.id
      let moveDown = isMoveDown(overNode?.rowTop as number, overNode?.rowHeight as number, e.y)
      if (!overNode || overNode.id === ADD_ROW_ID) {
        overNodeId = e.api.getDisplayedRowAtIndex(e.api.getLastDisplayedRow() - 1)?.data.id
        moveDown = true
      }
      forEach(document.querySelectorAll(`[row-id="${overNodeId}"]`), element => {
        if (moveDown) {
          element.classList.add('ag-row-highlight-below')
          element.classList.remove('ag-row-highlight-above')
        } else {
          element.classList.add('ag-row-highlight-above')
          element.classList.remove('ag-row-highlight-below')
        }
      })
    }
  }

  const onRowDragEnd = async (e: RowDragEvent) => {
    const dragNode = e.node
    const overNode = e.overNode
    const { overIndex } = e
    let moveDown = true
    const gridApi = e.api
    if (dragNode.parent?.childrenAfterFilter) {
      let prevRowNode
      if (!overNode || overNode.lastChild) {
        prevRowNode = gridApi.getDisplayedRowAtIndex(gridApi.getLastDisplayedRow() - 1)
      } else if (overNode.firstChild) {
        prevRowNode = undefined
      } else {
        if (overNode.rowTop && overNode.rowHeight) {
          moveDown = isMoveDown(overNode.rowTop, overNode.rowHeight, e.y)
        }
        if (!moveDown) {
          prevRowNode = gridApi.getDisplayedRowAtIndex(overIndex - 1)
        } else {
          prevRowNode = overNode
        }
      }

      if (currentView) {
        await dispatch(moveRow({
          id: tableNodeId,
          viewId: currentView.id,
          rowIds: e.nodes.map(n => n.id) as string[],
          prevRowId: prevRowNode?.id
        }))

        await updateCollaborator()
      }
    }

    cleanUpRowHighlight()
  }

  return {
    addGridDropZone,
    onRowDragMove,
    onRowDragEnd
  }
}
