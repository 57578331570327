import React from 'react'
import { Button, Form, Input, message } from 'antd'
import storage from 'store2'
import useDispatch from 'src/hooks/common/useDispatch'
import { LOGIN_VERIFICATION_CODE } from 'src/constants/storage'
import { getCode } from 'src/state/user/actions'
import { validatePhoneNumber } from 'src/utils/validators'
import useLogin from 'src/hooks/login/useLogin'
import styles from './loginForm.module.scss'

interface Props {
  onSubmit: (fieldValues: Record<string, string>) => void;
  submitButtonText: string;
}
const LoginForm: React.FC<Props> = ({
  onSubmit,
  submitButtonText
}) => {
  const dispatch = useDispatch()
  const { count, setCount, isRequestCodeClick, setIsRequestCodeClick } = useLogin()
  const [form] = Form.useForm()

  async function handleRequestCodeClick () {
    const code = form.getFieldValue('phoneNumber')

    if (validatePhoneNumber(code)) {
      setCount(60)
      await dispatch(getCode(code))
      setIsRequestCodeClick(true)
      storage.set(LOGIN_VERIFICATION_CODE, Date.now())
      message.success('验证码已发送至您的手机，请注意查收')
    } else {
      message.error('请确认您的手机号码是否输入正确')
    }
  }

  async function handleFormSubmit () {
    onSubmit(form.getFieldsValue())
  }

  return (
    <Form
      name="login"
      size="large"
      form={form}
      onFinish={handleFormSubmit}
    >
      <Form.Item
        name="phoneNumber"
        validateTrigger="onBlur"
        rules={[
          {
            validator (_, val) {
              return validatePhoneNumber(val) ? Promise.resolve() : Promise.reject('请确认您的手机号码是否输入正确')
            }
          }
        ]}
      >
        <Input placeholder="手机号码" />
      </Form.Item>
      <Form.Item
        name="code"
        rules={[
          {
            required: true,
            message: '请输入验证码'
          }
        ]}
      >
        <Input
          placeholder="验证码" autoComplete="off" suffix={
            <Button
              size="small"
              className={styles.captchaButton}
              block
              type="primary"
              onClick={handleRequestCodeClick}
              disabled={count > 0}
            >
              {count > 0 ? count :
                (isRequestCodeClick ? '重发' : '发送')
              }
            </Button>
          } />
      </Form.Item>
      <Form.Item>
        <Button
          className={styles.button}
          type="primary"
          htmlType="submit"
          size="large"
          block
        >
          {submitButtonText}
        </Button>
      </Form.Item>
    </Form>
  )
}

export default LoginForm
