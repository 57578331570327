import React, { useCallback, useState, useEffect } from 'react'
import { Layout, Button } from 'antd'
import type { GridApi } from 'ag-grid-community'
import Grid from 'src/components/grid/Grid'
import { useTableNode, useUpdateTableNodeIdEffect } from 'src/hooks/common/useTableNode'
import { useProject } from 'src/hooks/project/useProject'
import { useParameters } from 'src/hooks/common/useParameters'
import { useWorkspaces } from 'src/hooks/workspace/useWorkspaces'
import { createTableNodeEmitter } from 'src/utils/emitter'
import TableSider from 'src/components/project/Sider'
import { useTableFilter, useTableView } from 'src/hooks/project/useTableView'
import useTree from 'src/hooks/common/useTree'
import ProjectActions from 'src/components/project/ProjectActions'
import { Filter as FilterType } from 'src/constants/filter'
import GridActions from 'src/components/project/GridActions'
import ActionButton from 'src/components/project/ActionButton'
import Filter from 'src/components/project/Filter'
import { useGrid } from 'src/hooks/project/useGrid'

import { TablePermissionWrapper, ProjectPermissionWrapper } from 'src/components/common/PermissionWrapper'
import { PermissionPages, TableNodePermisson, ProjectPermission } from 'src/constants/permissions'
import { useWorkspaceRoles } from 'src/hooks/workspace/useWorkspaceRoles'
import useCollaborator from 'src/hooks/collaborator/useCollaborator'
import { useInfiniteRenderDebug } from 'src/hooks/common/useDebug'
import usePagePermission from 'src/hooks/workspace/usePagePermission'
import { useDocumentTitle, getWorkspaceTitle } from 'src/hooks/common/useDocumentTitle'
import Snapshot from 'src/components/project/Snapshot'
import styles from './table.module.scss'
import TableSkelton from './TableSkeleton'

const { Content } = Layout

const Table: React.FC = () => {
  const { projectId, workspaceId, tableNodeId, workspaceType } = useParameters()
  const [selectedRows, setSelectedRows] = useState<string[]>([])
  const [gridApi, setGridApi] = useState<GridApi>()
  const { workspace } = useWorkspaces(workspaceType, workspaceId)
  const { project } = useProject(workspace, projectId)
  const { tableNode } = useTableNode(tableNodeId)
  const { filtersWithId } = useTableFilter(tableNodeId)
  const { formatDataToTreeNode } = useTree()
  const { exportExcel } = useGrid()
  const { updateTableView } = useTableView(tableNodeId)
  const defualtTableViewId = tableNode?.views[0].id
  const tableNodes = project?.tableNodes ?? []
  const { checkPermission } = useWorkspaceRoles()
  const editable = checkPermission(tableNodeId, TableNodePermisson.DataEdit, 'table')
  const { updateCollaborator } = useCollaborator()
  
  useDocumentTitle(project?.name, getWorkspaceTitle(workspace))

  usePagePermission(PermissionPages.table)

  const handleSelectionChange = (rowIds: string[]) => {
    setSelectedRows(rowIds)
  }

  const handleFilterChange = useCallback((filters: FilterType[]) => {
    if (defualtTableViewId) {
      updateTableView(defualtTableViewId, filters)
    }
  }, [updateTableView, defualtTableViewId])

  const handleExportClick = () => {
    exportExcel(gridApi)
  }

  useUpdateTableNodeIdEffect(
    formatDataToTreeNode(projectId, tableNodes),
    tableNodes
  )

  useEffect(() => {
    updateCollaborator()
    return () => {
      updateCollaborator()
    }
  }, [updateCollaborator, tableNodeId])

  useInfiniteRenderDebug('Table')

  return (
    <>
      <ProjectActions
        extra={
          <>
            <TablePermissionWrapper role={TableNodePermisson.GridSnapshot}>
              <Snapshot />
            </TablePermissionWrapper>
            <TablePermissionWrapper role={TableNodePermisson.GridExport}>
              <ActionButton
                icontype={'export'}
                onClick={handleExportClick}
                text={'导出'}
              />
            </TablePermissionWrapper>
            {/* <TablePermissionWrapper role={TableNodePermisson.GridShare}>
              <ActionButton
                icontype={'share'}
                text={'分享'}
              />
            </TablePermissionWrapper> */}
          </>
        }
      >
        <GridActions
          selectedRows={selectedRows}
          gridApi={gridApi}
        >
          <TablePermissionWrapper role={TableNodePermisson.GridFitler}>
            <Filter onChange={handleFilterChange} filtersWithId={filtersWithId} tableNodeId={tableNodeId} />
          </TablePermissionWrapper>
        </GridActions>
      </ProjectActions>
      <Layout>
        <TableSider
          project={project}
        />
        <Content className={styles.content}>
          {
            (tableNodeId ? <>
              {tableNode ? <Grid
                editable={editable}
                data={tableNode}
                filters={filtersWithId.filters}
                onSelectionChange={handleSelectionChange}
                onGridReady={setGridApi}
              /> : <TableSkelton />}
            </> : <div className={styles.blank}>
              <ProjectPermissionWrapper 
                role={ProjectPermission.NewTable} 
                unauthorizedContent={<div>项目下未创建数据表</div>}
              >
                <div>
                  <p>项目下未创建数据表，点击按钮新建</p>
                  <Button type="primary" onClick={() => createTableNodeEmitter.emit()}>新建数据表</Button>
                </div>
              </ProjectPermissionWrapper>
            </div>)
          }
        </Content>
      </Layout>
    </>
  )
}

export default Table
