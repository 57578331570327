import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { Form, Input, Select } from 'antd'
import map from 'lodash/map'
import Dialog from 'src/components/common/Dialog'
import { DialogStateReturn as DialogInterface } from 'src/hooks/common/useDialog'
import { AppDispatch } from 'src/store'
import * as layerNodeActions from 'src/state/layerNode/actions'
import { LayerNode } from 'src/typings/mapTableNode'
import { useParameters } from 'src/hooks/common/useParameters'
import { useTableNodesForLayerCreate } from 'src/hooks/common/useTableNode'

const { Option } = Select

interface Props {
  dialog: DialogInterface;
  parentID?: React.Key;
  onCreated?: (node: LayerNode) => void;
}

const CreateLayerModal: React.FC<Props> = ({
  dialog,
  parentID,
  onCreated
}) => {
  const dispatch: AppDispatch = useDispatch()
  const { projectId } = useParameters()
  const [form] = Form.useForm<LayerNode['mapInfo'] & { name: string }>()
  const {
    tableNodes,
    coordinateOptions,
    labelOptions,
    setSelectedTableId
  } = useTableNodesForLayerCreate(projectId)

  useEffect(() => {
    if (dialog.visible) {
      form.resetFields()
    }
  }, [dialog, form])

  async function handleSubmitClick () {
    await form.validateFields()

    const fieldsValue = form.getFieldsValue()
    const layerNodeParams = {
      type: 'map',
      name: fieldsValue?.name,
      projectID: projectId,
      parentID: parentID,
      mapInfo: {
        tableNodeID: fieldsValue?.tableNodeID,
        coordinateFieldID: fieldsValue?.coordinateFieldID,
        labelFieldID: fieldsValue?.labelFieldID
      }
    } as LayerNode

    dispatch(layerNodeActions.create(layerNodeParams))

    onCreated && onCreated(layerNodeParams)
    dialog.close()
  }

  function handleTableSelect (tableId: number) {
    setSelectedTableId(tableId)
  }

  return (
    <Dialog
      width="400px"
      okText="新建"
      title="新建图层"
      visible={dialog.visible}
      onCancel={() => dialog.close()}
      onOk={handleSubmitClick}
    >
      <Form
        colon={false}
        labelAlign="left"
        labelCol={{ span: 6 }}
        requiredMark={false}
        form={form}
      >
        <Form.Item
          name="name"
          label="图层名称"
          rules={[
            {
              required: true,
              message: '请输入图层名称'
            }
          ]}
        >
          <Input placeholder="输入图层名称" autoComplete="off" />
        </Form.Item>
        <Form.Item
          name="tableNodeID"
          label="数据来源"
          tooltip="选择一张数据表作为地图数据来源"
          rules={[
            {
              required: true,
              message: '请选择数据来源'
            }
          ]}
        >
          <Select placeholder="请选择数据表" onSelect={handleTableSelect}>
            {
              map(tableNodes, t => (
                <Option key={t.id} value={t.id}>{t.name}</Option>
              ))
            }
          </Select>
        </Form.Item>
        <Form.Item
          name="coordinateFieldID"
          label="POI 坐标"
          tooltip="选择一个字段作为 POI 坐标"
          rules={[
            {
              required: true,
              message: '请选择 POI 坐标'
            }
          ]}
        >
          <Select placeholder="请选择字段">
            {map(coordinateOptions, o => (
              <Option key={o.id} value={o.id}>{o.name}</Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          name="labelFieldID"
          label="POI 标签"
          tooltip="选中一个字段作为 POI 标签"
          rules={[
            {
              required: true,
              message: '请选择 POI 标签'
            }
          ]}
        >
          <Select placeholder="请选择字段">
            {map(labelOptions, o => (
              <Option key={o.id} value={o.id}>{o.name}</Option>
            ))}
          </Select>
        </Form.Item>
      </Form>
    </Dialog>
  )
}

export default CreateLayerModal
