import React from 'react'
import { Form, Switch } from 'antd'
import withVisible from 'src/hoc/withVisible'
import ColorRangeSelector from 'src/components/common/ColorRangeSelector'
import { COLORS_LENGTH } from 'src/constants/chart'
import styles from './chartStyleFormItems.module.scss'

const ChartStyleFormItems = withVisible(() => {
  return <>
    <Form.Item
      label="图表颜色"
    >
      <div className={styles.colorRangeContainer}>
        <Form.Item
          name="params.style.colors"
          valuePropName="colors"
          noStyle
        >
          <ColorRangeSelector step={COLORS_LENGTH} />
        </Form.Item>
      </div>
    </Form.Item>
    <Form.Item
      label="展示设置"
    >
      <div className={styles.displayFieldDetail}>
        <div className={styles.switchItem}>
          展示图例
          <Form.Item
            name="params.style.enableShowPanelExample"
            valuePropName="checked"
            noStyle
          >
            <Switch size="small" />
          </Form.Item>
        </div>
        <div className={styles.switchItem}>
          展示空值
          <Form.Item
            name="params.style.enableShowNullAndZeroValue"
            valuePropName="checked"
            noStyle
          >
            <Switch size="small" />
          </Form.Item>
        </div>
        <div className={styles.switchItem}>
          图表上展示数值
          <Form.Item
            name="params.style.enableShowValue"
            valuePropName="checked"
            noStyle
          >
            <Switch size="small" />
          </Form.Item>
        </div>
        <Form.Item
          noStyle
          dependencies={['type']}
        >
          {({ getFieldValue }) => {
            const chartType = getFieldValue('type')
            return chartType === 'pie' ? <div className={styles.switchItem}>
              展示百分比
              <Form.Item
                name="params.style.enableShowPercentage"
                valuePropName="checked"
                noStyle
              >
                <Switch size="small" />
              </Form.Item>
            </div> : null
          }}
        </Form.Item>
      </div>
    </Form.Item>
  </>
})

export default ChartStyleFormItems
