import React from 'react'
import { ROLE_ENTITY, RoleEntityType } from 'src/typings/workspace'
import { RoleType } from 'src/constants/permissions'
import { useWorkspaceRoles } from 'src/hooks/workspace/useWorkspaceRoles'
import { useParameters } from 'src/hooks/common/useParameters'

interface PermissionWrapperProps {
  id?: number;
  role?: RoleType;
  type?: RoleEntityType;
  useDisableStyle?: boolean;
  tooltip?: string;
  unauthorizedContent?: React.ReactElement;
}

// TODO: useDisableStyle && tooltip
export const PermissionWrapper: React.FC<PermissionWrapperProps> = ({
  id,
  role,
  type,
  useDisableStyle = false,
  tooltip = '没有权限',
  children,
  unauthorizedContent
}) => {
  const { checkPermission } = useWorkspaceRoles()
  const shouldRender = checkPermission(id, role, type)

  return <>
    {shouldRender ? children : unauthorizedContent}
  </>
}

export const ProjectPermissionWrapper: React.FC<PermissionWrapperProps> = ({ id, ...props }) => {
  const { projectId } = useParameters()
  return <PermissionWrapper {...props} id={id ?? projectId} type={ROLE_ENTITY.project} />
}

export const OrgPermissionWrapper: React.FC<PermissionWrapperProps> = (props) => {
  return <PermissionWrapper {...props} type={ROLE_ENTITY.org} />
}

export const TablePermissionWrapper: React.FC<PermissionWrapperProps> = ({ id, ...props }) => {
  const { tableNodeId } = useParameters()
  return <PermissionWrapper {...props} id={id ?? tableNodeId} type={ROLE_ENTITY.table} />
}
