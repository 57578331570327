import { useCallback, useState } from 'react'

export interface Return {
  id: number
  visible: boolean;
  open: (id: string) => void;
  close: () => void;
}

function useDrawer (initialValue = false): Return {
  const [visible, setVisible] = useState(initialValue)
  const [id, setId] = useState(0)
  const open = useCallback((id) => {
    setId(id)
    setVisible(true)
  }, [setVisible])

  const close = useCallback(() => {
    setVisible(false)
  }, [setVisible])

  return {
    id,
    visible,
    open,
    close
  }
}

export default useDrawer
