import React, { useState } from 'react'
import { Drawer, Form, Tabs, Tooltip, Modal } from 'antd'
import cx from 'clsx'
import noop from 'lodash/noop'
import { AlignCenterOutlined } from '@ant-design/icons'
import { Return as DrawerInterface } from 'src/hooks/common/useDrawer'
import Icon from 'src/components/common/Icon'
import { Cell } from 'src/typings/tableNode'
import FieldItem from 'src/components/detailPanel/FieldItem'
import { useDialogState } from 'src/hooks/common/useDialog'
import { DetailPanelSettingModal } from 'src/components/map/FieldSettingModal'
import useSelector from 'src/hooks/common/useSelector'
import { projectSelectors } from 'src/state/project/slice'
import { useParameters } from 'src/hooks/common/useParameters'
import { TablePermissionWrapper } from 'src/components/common/PermissionWrapper'
import { TableNodePermisson } from 'src/constants/permissions'
import SmallDivider from 'src/components/common/SmallDivider'
import styles from './detailPanel.module.scss'

const { TabPane } = Tabs

interface SidePanelProps {
  editable?: boolean;
  tableNodeId: number;
  drawer: DrawerInterface;
  title?: string;
  cells?: Cell[];
  onClose?: () => void;
  onDelete?: () => void;
  inGrid?: boolean;
  layerNodeId?: string;
  rowId?: string;
}

const SidePanel: React.FC<SidePanelProps> = (props) => {
  const {
    drawer,
    cells,
    title,
    onClose,
    tableNodeId,
    onDelete = noop,
    inGrid,
    editable
  } = props
  const [activeKey, setActiveKey] = useState('0')
  const [form] = Form.useForm()
  const initialValues = {}
  const detailPanelSettingDialog = useDialogState()
  const { projectId } = useParameters()
  const ALL_FIELDS_TAB_KEY = '-1'
  const tabConfigs = useSelector(state => {
    const project = projectSelectors.selectById(state, projectId)
    return project?.tableNodes?.find(t => t.id === tableNodeId)?.detailPanelConfig?.tabs
  })
  const tabCellsSorted = tabConfigs?.map(tab => tab.columnIDs?.map(id => cells?.find(c => c.id === id)))

  function handleTabExtraClick () {
    setActiveKey(ALL_FIELDS_TAB_KEY)
  }

  function handleClose () {
    onClose ? onClose() : drawer.close()
  }

  function handleDelete () {
    Modal.confirm({
      title: '删除数据',
      content: <div>
        <p>确定删除该条数据？</p>
        <p>删除操作无法恢复，删除后将无法访问该数据。</p>
      </div>,
      okText: '删除数据',
      okButtonProps: {
        danger: true,
        type: 'primary'
      },
      cancelText: '取消',
      onOk () {
        onDelete()
      }
    })
  }

  const renderTitle = () => {
    return (
      <div className={styles.header}>
        <div className={styles.title}>
          <div className={cx(styles.content, 'handle')}>{title}</div>
          <div className={styles.actions}>
            <TablePermissionWrapper id={tableNodeId} role={TableNodePermisson.DetailPanelConfig}>
              <Icon type={'infoWindowSetting'} onClick={detailPanelSettingDialog.open} />
              <SmallDivider light mh10 />
            </TablePermissionWrapper>
            <Icon type={'close'} onClick={handleClose} className={styles.close} />
          </div>
        </div>
        <div className={styles.tools}>
          {/* <div className={styles.toolsItem}><Icon className={styles.icon} type={'star'} />收藏</div>
           {
           !inGrid && <CompareButton
           className={styles.toolsItem}
           layerNodeId={layerNodeId as string}
           rowId={rowId as string}
           tableNodeId={tableNodeId}
           />
           } */}
          {/* {
           editable &&
           <div className={styles.toolsItem}><Icon className={styles.icon} type={'share2'} />分享</div>
           } */}
          {
            !inGrid && editable && <div className={cx(styles.toolsItem, styles.delete)} onClick={handleDelete}>
              <Icon className={styles.icon} type={'delete'} />删除
            </div>
          }
        </div>
      </div>
    )
  }
  const renderAllFields = () => {
    return (
      <Form
        colon={false}
        labelAlign="left"
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 16 }}
        requiredMark={false}
        form={form}
        initialValues={initialValues}
      >
        {
          cells?.map((cell: Cell, idx: number) => {
            return <FieldItem
              editable={editable}
              drawerId={drawer.id}
              drawerVisible={drawer.visible}
              item={cell}
              key={idx}
            />
          })
        }
      </Form>
    )
  }
  const renderExtraButton = () => {
    return (
      <Tooltip placement="top" title="全部字段">
        <div
          className={cx(styles.tabAll, activeKey === ALL_FIELDS_TAB_KEY && styles.activeTab)}
          onClick={handleTabExtraClick}><AlignCenterOutlined /></div>
      </Tooltip>
    )
  }
  return (
    <Drawer
      title={renderTitle()}
      placement="right"
      closable={false}
      onClose={handleClose}
      visible={drawer.visible}
      mask={false}
      maskClosable={false}
      className={cx('detail-panel', styles.drawer)}
    >
      {
        tabConfigs && tabConfigs.length > 0 ?
          <Tabs
            className={styles.tablist} activeKey={activeKey} type="card" size="small"
            tabBarExtraContent={renderExtraButton()}
            onTabClick={key => setActiveKey(key)}
          >
            <TabPane key={ALL_FIELDS_TAB_KEY}>
              {renderAllFields()}
            </TabPane>
            {
              tabConfigs?.map((tab, index) => {
                return <TabPane tab={tab.name} key={index}>
                  <Form
                    colon={false}
                    labelAlign="left"
                    labelCol={{ span: 6 }}
                    wrapperCol={{ span: 18 }}
                    requiredMark={false}
                    form={form}
                    initialValues={initialValues}
                  >
                    {
                      tabCellsSorted?.[index]?.map((cell, idx: number) => {
                        return cell && <FieldItem
                          editable={editable}
                          drawerId={drawer.id}
                          drawerVisible={drawer.visible}
                          item={cell}
                          key={idx}
                        />
                      })
                    }
                  </Form>
                </TabPane>
              })
            }
          </Tabs>
          :
          <div className={styles.fields}>
            {renderAllFields()}
          </div>
      }
      <DetailPanelSettingModal dialog={detailPanelSettingDialog} tableNodeId={tableNodeId} />
    </Drawer>
  )
}

export default SidePanel
