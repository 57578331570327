import dayjs from 'dayjs'

const SECONDS_IN_ONE_DAY = 60 * 60 * 24

export function formatDate (time: string): string {
  return dayjs(time).format('YYYY.MM.DD HH:mm')
}

export function formatDateTimeToLocal (time: string): string {
  const date = dayjs(time)
  const isSameYear = date.isSame(dayjs(), 'year')
  return isSameYear ? date.format('M月D日 HH:mm') : date.format('YYYY年M月D日 HH:mm') 
}
export function convertToDays (timeInSecond: number): number {
  return Math.floor(timeInSecond / SECONDS_IN_ONE_DAY) 
}