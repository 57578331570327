import { useEffect } from 'react'
import find from 'lodash/find'
import isNil from 'lodash/isNil'
import useDrawer, { Return as Drawer } from 'src/hooks/common/useDrawer'
import useSelector from 'src/hooks/common/useSelector'
import useDispatch from 'src/hooks/common/useDispatch'
import useTableCells from 'src/hooks/project/useTableCells'
import { actions as mapActions } from 'src/state/map/slice'
import { actions as InfoWindowActions } from 'src/state/infoWindow/slice'
import { deleteRows } from 'src/state/tableNode/actions'
import type { Cell } from 'src/typings/tableNode'
import { InfoWindowPOIType } from 'src/typings/map'

interface Return {
  poiDetail: { title: string; cells?: Cell[], tableNodeId?: number, rowId?: string, layerNodeId?: string };
  detailPanel: Drawer;
  poi?: InfoWindowPOIType;
  handleDetailPanelClose: () => void;
  handleDelete: () => void;
}

export default function usePoiDetailPanel (closeOnDestory?: boolean): Return {
  const dispatch = useDispatch()
  const poiDetail = useSelector(state => state.map.poiDetail)
  const detailPanel = useDrawer(poiDetail.visible)
  const {
    cells
  } = useTableCells(poiDetail.tableNodeId as number, poiDetail.id as string)

  function handleDetailPanelClose () {
    dispatch(mapActions.updatePoiDetail({
      visible: false
    }))
  }

  async function handleDelete () {
    const { tableNodeId, rowId, poi } = poiDetail
    if (poi?.id) {
      dispatch(InfoWindowActions.remove(poi.id))
    }
    if (!isNil(tableNodeId) && !isNil(rowId)) {
      await dispatch(deleteRows({
        id: tableNodeId,
        rowIDs: [rowId]
      }))
      dispatch(mapActions.clearPoiDetail())
    }
  }

  useEffect(() => {
    if (poiDetail.visible) {
      detailPanel.open(poiDetail.id as string)
    } else {
      detailPanel.close()
    }
  }, [poiDetail, detailPanel])

  useEffect(() => {
    return () => {
      closeOnDestory && dispatch(mapActions.clearPoiDetail())
    }
  }, [closeOnDestory, dispatch])

  return {
    poiDetail: {
      tableNodeId: poiDetail.tableNodeId,
      rowId: poiDetail.rowId,
      title: find(cells, c => c.id === poiDetail.labelFieldId)?.value as string ?? '',
      cells,
      layerNodeId: poiDetail.layerNodeId
    },
    poi: poiDetail.poi,
    detailPanel,
    handleDetailPanelClose,
    handleDelete
  }
}
