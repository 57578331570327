import React from 'react'
import cx from 'clsx'
import styles from './smallDivider.module.scss'

interface Props {
  className?: string
  light?: boolean
  mh10?: boolean
  mh15?: boolean
  mr15?: boolean
}

const SmallDivider: React.FC<Props> = ({
  className,
  light = false,
  mh10 = false,
  mh15 = false,
  mr15 = false
}) => {
  return (
    <span
      className={cx(
        styles.smallDivider,
        className,
        light ? styles.light : '',
        mh10 ? styles.mh10 : '',
        mh15 ? styles.mh15 : '',
        mr15 ? styles.mr15 : ''
      )} />
  )
}

export default SmallDivider
