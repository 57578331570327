import { PayloadAction } from '@reduxjs/toolkit'
import { State } from 'src/state/app/slice'

export enum WSReadyState {
  CONNECTING,
  OPEN,
  CLOSING,
  CLOSED,
  NO_AUTH
}

/**
 * @ignore
 */
export function updateCollaboratorReadyState (state: State, action: PayloadAction<WSReadyState>): void {
  state.collaboratorReadyState = action.payload
}

/**
 * @ignore
 */
export function updateUserReadyState (state: State, action: PayloadAction<WSReadyState>): void {
  state.userReadyState = action.payload
}

/**
 * @ignore
 */
export function updateOrganizationReadyState (state: State, action: PayloadAction<WSReadyState>): void {
  state.organizationReadyState = action.payload
}

/**
 * @ignore
 */
export function appInitialized (state: State, action: PayloadAction<boolean>): void {
  state.initialized = state.initialized || action.payload
}

/**
 * @ignore
 */
export function addFetching (state: State, action: PayloadAction<string>): void {
  if (state.fetchingList.findIndex(id => id === action.payload) === -1) {
    state.fetchingList.push(action.payload)
  }
}

/**
 * @ignore
 */
export function removeFetching (state: State, action: PayloadAction<string>): void {
  const index = state.fetchingList.findIndex(id => id === action.payload)
  if (index !== -1) {
    state.fetchingList.splice(index, 1)
  }
}


/**
 * @ignore
 */
export const reducers = {
  updateCollaboratorReadyState,
  updateUserReadyState,
  updateOrganizationReadyState,
  appInitialized,
  addFetching,
  removeFetching
}
