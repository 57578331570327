import React from 'react'
import { Select } from 'antd'
import map from 'lodash/map'
import Icon, { IconType } from 'src/components/common/Icon'
import { FIELD_TYPES } from 'src/constants/fieldType'
import { Column } from 'src/typings/tableNode'
import styles from './fieldTypeSelector.module.scss'

const { Option } = Select

interface Props {
  onChange?: (value: string) => void;
  options?: Omit<Column, 'isPrimary'>[];
  placeholder?: string;
  value?: string;
  allowClear?: boolean;
  multiple?: boolean
}
export const FieldTypeSelector: React.FC<Props> = ({
  onChange,
  options,
  placeholder,
  value,
  allowClear,
  multiple
}) => {
  return (
    <Select
      placeholder={placeholder}
      defaultActiveFirstOption={false}
      onChange={onChange}
      value={value}
      allowClear={allowClear}
      mode={multiple ? 'multiple' : undefined}
    >
      {
        map(options, o => (
          <Option
            key={o.id}
            value={o.id}
          >
            <div className={styles.option}>
              <Icon className={styles.icon} type={FIELD_TYPES[o.type]?.icon as IconType} />
              {o.name}
            </div>
          </Option>
        ))
      }
    </Select>
  )
}

export default FieldTypeSelector
