import { createSlice } from '@reduxjs/toolkit'
import { reducers, WSReadyState } from 'src/state/app/reducers'

export interface State {
  userReadyState: WSReadyState | null;
  organizationReadyState: WSReadyState | null;
  collaboratorReadyState: WSReadyState | null;
  initialized: boolean;
  fetchingList: string[]
}

const initialState = {
  userReadyState: null,
  organizationReadyState: null,
  collaboratorReadyState: null,
  initialized: false,
  fetchingList: []
} as State

const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers
})

export default appSlice
export const actions = appSlice.actions
