import React from 'react'
import ReactDOM from 'react-dom/server'
import CellRenderer from 'src/components/field/CellRenderer/CellRenderer'
import { SingleLineTextRenderer } from './SingleLineTextRenderer'

export class SingleLineTextCellRenderer extends CellRenderer {
  init (p: CellRenderer['params']): void {
    this.value = p?.realValue
    this.params = p
    const el = this.createEL()

    el.innerHTML = ReactDOM.renderToStaticMarkup(<SingleLineTextRenderer value={this.value} truncateLength={200} />)

    this.eGui = this.withCollaborators(el, this.params?.collaborator)
  }

  getGui (): HTMLElement {
    return this.eGui
  }

  refresh (): boolean {
    return false
  }
}
