import React, { forwardRef, useImperativeHandle, useState, useEffect, useCallback } from 'react'
import type { ICellEditorParams } from 'ag-grid-community'
import cx from 'clsx'
import noop from 'lodash/noop'
import { parse } from 'src/utils/wkt'
import useDispatch from 'src/hooks/common/useDispatch'
import { actions as projectActions } from 'src/state/project/slice'
import useSelector from 'src/hooks/common/useSelector'
import Icon from 'src/components/common/Icon'
import type { CoordinateEditor as CoordinateEditorType } from 'src/state/project/reducers'
import { BACKSPACE } from 'src/constants/keyCode'
import styles from './coordinate.module.scss'

type UseDeletePresseEffectDeps = [boolean]
function useDeletePresseEffect (effect: () => void, deps: UseDeletePresseEffectDeps): void {
  const [isDeletePressed] = deps

  useEffect(() => {
    if (isDeletePressed) {
      setTimeout(() => {
        effect()
      })
    }
  }, [isDeletePressed, effect])
}

interface UseCoordinateEditorModalDeps {
  tableNodeId: number;
  rowId: string;
  cellId: string;
  coordinate: string;
  isDeletePressed: boolean;
}
function useOpenCoordinateEditorModalEffect (deps: UseCoordinateEditorModalDeps): void {
  const { tableNodeId, rowId, cellId, coordinate, isDeletePressed } = deps
  const dispatch = useDispatch()

  useEffect(() => {
    if (!isDeletePressed) {
      dispatch(projectActions.updateCoordinateEditor({
        visible: true,
        trigger: 'open',
        data: {
          id: tableNodeId,
          rowId,
          cells: {
            [cellId]: coordinate
          }
        }
      }))
    }

    return () => {
      dispatch(projectActions.updateCoordinateEditor({
        trigger: null
      }))
    }
  }, [dispatch, tableNodeId, rowId, cellId, coordinate, isDeletePressed])
}

interface UseCloseCoordinateEditorModalDeps {
  coordinateEditor: CoordinateEditorType,
  stopEditing: () => void;
  inGrid?: boolean;
  onBlur: () => void;
}
function useCloseCoordinateEditorModalEffect (deps: UseCloseCoordinateEditorModalDeps): void {
  const { coordinateEditor, stopEditing, inGrid, onBlur } = deps

  useEffect(() => {
    if (!coordinateEditor.visible && coordinateEditor.trigger === 'close') {
      if (inGrid) {
        stopEditing()
      } else {
        onBlur()
      }
    }
  }, [coordinateEditor, stopEditing, inGrid, onBlur])
}

export interface Props extends ICellEditorParams {
  onBlur?: () => void;
  inGrid?: boolean;
  inDetailPanel: boolean;
}
export const CoordinateEditor = forwardRef((props: Partial<Props>, ref) => {
  const [coordinate, setCoordinate] = useState<string>(props.value)
  const coordinateEditor = useSelector(state => state.project.coordinateEditor)
  const rowId = props.data?.id
  const cellId = props.colDef?.field as string
  const tableNodeId = props.data?.tableNodeId || props.colDef?.cellEditorParams.tableNodeId
  const isDeletePressed = props.keyPress === BACKSPACE
  const { stopEditing = noop, onBlur = noop, inGrid } = props

  useImperativeHandle(ref, () => {
    return {
      getValue () {
        return coordinate
      },
      isCancelAfterEnd () {
        if (coordinate !== '') {
          try {
            parse(coordinate)
          } catch {
            return true
          }
        }
      },
      afterGuiAttached () {
        if (props.value) {
          setCoordinate(props.value)
        }
      }
    }
  })

  useDeletePresseEffect(useCallback(() => {
    setCoordinate('')
    stopEditing()
  }, [stopEditing]), [isDeletePressed])

  useOpenCoordinateEditorModalEffect({ tableNodeId, rowId, cellId, coordinate, isDeletePressed })

  useCloseCoordinateEditorModalEffect({ coordinateEditor, stopEditing, inGrid, onBlur })

  return (
    <div className={styles.editor}>
      <div className={styles.text}>
        {coordinate ? coordinate : <span className={styles.placeholder}>点击标记</span>}
      </div>
      <Icon type="mapPin" className={cx(styles.mapPin, styles.active)} />
    </div>
  )
})
