// TODO: implement like below
// router.push({
//   name: 'projects',
//   query: {},
//   params: {},
//   state: {}
// })
import { useCallback } from 'react'
import Cookies from 'js-cookie'
import { useDispatch } from 'react-redux'
import { actions as userActions } from 'src/state/user/slice'
import { AppDispatch } from 'src/store'
import { IS_AUTH, LAST_REFRESH_TOKEN_TIMESTAMP, TOKEN, REFRESH_TOKEN } from 'src/constants/storage'

interface Return {
  logout: () => void
}

function useRouter (): Return {
  const dispatch: AppDispatch = useDispatch()

  const logout = useCallback(() => {
    dispatch(userActions.setAuth(false))
    Cookies.set(IS_AUTH, 'false')
    Cookies.remove(TOKEN)
    Cookies.remove(REFRESH_TOKEN)
    Cookies.remove(LAST_REFRESH_TOKEN_TIMESTAMP)
    window.location.href = '/'
  }, [dispatch])

  return {
    logout
  }
}

export default useRouter
