import React, { useCallback, useRef, useState, useContext, useEffect } from 'react'
import map from 'lodash/map'
import { message } from 'antd'
import cx from 'clsx'
import {
  QUMap,
  GroupLayer,
  Controls,
  Zoom,
  TileLayer,
  Distance,
  Editor,
  Event,
  Switch
} from 'qumap/packages/react'
import type { Props as EditorProps } from 'qumap/packages/react/types/editor/Editor'
import type { Layer as LayerType } from 'qumap/packages/react/types/controls/Switch'
import MarkerEditor from 'qumap/packages/core/types/markerEditor'
import PolylineEditor from 'qumap/packages/core/types/polylineEditor'
import PolygonEditor from 'qumap/packages/core/types/polygonEditor'
import { getCenter } from 'qumap/packages/core/'
import InfoWindow from 'src/components/map/InfoWindow'
import { useMap, useOverlayEditor, useOverlay } from 'src/hooks/map/useMap'
import { useParameters } from 'src/hooks/common/useParameters'
import useSelector from 'src/hooks/common/useSelector'
import { useTableNodesForMapView } from 'src/hooks/common/useTableNode'
import { useLayers } from 'src/hooks/map/useLayers'
import OverlayEditorPanel from 'src/components/map/OverlayEditorPanel'
import { actions as InfoWindowActions, infoWindowSelectors } from 'src/state/infoWindow/slice'
import useDispatch from 'src/hooks/common/useDispatch'
import { getInfoWindowPosition } from 'src/utils/infoWindow'
import { MAX_INFOWINDOW_COUNT } from 'src/constants/map'
import type { POIClickEventType } from 'src/typings/map'
import Legend from 'src/components/map/Legend'
import { InfoWindowSettingModal } from 'src/components/map/FieldSettingModal'
import { useDialogState } from 'src/hooks/common/useDialog'
import useLegend from 'src/hooks/map/useLegend'
import { SearchPOIType } from 'src/components/common/SearchPOI'
import { MapContext } from 'src/context/mapContext'
import styles from './map.module.scss'

interface Props {
  drawerVisible?: boolean
}

const MapContainer: React.FC<Props> = ({
  drawerVisible = false
}) => {
  const dispatch = useDispatch()
  const { projectId } = useParameters()
  const {
    mapConfig,
    mapOptions,
    quMap,
    handleMapLoad,
    isMapReady
  } = useMap(projectId)
  const {
    editorType,
    setEditorType,
    isEditorOpen,
    setIsEditorOpen,
    enable,
    isNewPOI,
    preData,
    updateOverlay
  } = useOverlayEditor()
  const {
    addOverlay
  } = useOverlay()
  const { setQuMap } = useContext(MapContext)
  useTableNodesForMapView(projectId)

  const { layers, layersForLabelControl, setLayersForLabelControl } = useLayers(projectId)
  const editor = useRef<MarkerEditor | PolylineEditor | PolygonEditor>(null)
  const infoWindows = useSelector(infoWindowSelectors.selectAll)
  const infoWindowIds = useSelector(infoWindowSelectors.selectIds)
  const infoWindowSettingDialog = useDialogState()
  const [fieldSettingTableNodeId, setFieldSettingTableNodeId] = useState<number>()
  const editorStatus = useSelector(state => state.map.editor)
  const isDetailPanelVisible = useSelector(state => state.map.poiDetail.visible)
  const [isEditing, setIsEditing] = useState(false)
  const { mapLegends } = useLegend(layers)
  const handlePOIClick = (e: POIClickEventType) => {
    if (editorStatus.isOpen) {
      return
    }
    if (infoWindows.length >= MAX_INFOWINDOW_COUNT && infoWindowIds.indexOf(e.poi.id) <= -1) {
      message.error(`最多只能打开${MAX_INFOWINDOW_COUNT}信息窗口`)
    } else {
      dispatch(InfoWindowActions.add({
        ...e.poi,
        ...getInfoWindowPosition(e.event.pixel),
        center: getCenter(e.poi.coordinates, e.poi.overlayType as string)
      }))
    }
  }

  function handleEditorClose () {
    editor.current?.close(true)
    setIsEditorOpen(false)
    setIsEditing(false)
  }

  async function handleOverlayEditorFinish () {
    const overlayGeoJSON = editor.current?.getOverlaysGeoJSON()

    if (!overlayGeoJSON) {
      return message.warning('请在地图上绘制图形')
    }

    if (isNewPOI) {
      await addOverlay(overlayGeoJSON)
    } else {
      await updateOverlay(overlayGeoJSON)
    }

    handleEditorClose()
  }

  function handleLabelChange (layers: LayerType[]) {
    setLayersForLabelControl(layers)
  }

  function handleFieldSettingOpen (tableNodeId: number) {
    setFieldSettingTableNodeId(tableNodeId)
    infoWindowSettingDialog.open()
  }

  async function handleSearchPOISelected (poi: SearchPOIType) {
    if (!poi) return

    // FIXME: 为支持添加新的「线」和「面」时支持搜索功能，需先将editor的类型设置为「marker」，设置完成后再设置为原来的类型。
    const currentType = editorType
    if (currentType !== 'marker') {
      await setEditorType('marker')
    }
    const center = [poi.location.lng, poi.location.lat] as [number, number]
    editor.current?.reset()
    editor.current?.load(center)
    quMap?.current?.amap.setCenter(center)
    if (currentType !== 'marker') {
      await setEditorType(currentType)
    }
  }

  const handleEdtiorFinish = useCallback(() => {
    setIsEditing(true)
    editor.current?.next()
  }, [])

  const handleEditorOpen = useCallback(() => {
    preData && setIsEditing(true)
  }, [preData])

  function handleEditorTypeChange (editorType: string) {
    setIsEditing(false)
    setEditorType(editorType as EditorProps['type'])
  }

  function handleEditorReset () {
    editor.current?.reset()
    setIsEditing(false)
  }

  useEffect(() => {
    if (isMapReady) {
      setQuMap(quMap.current) 
    }
  }, [isMapReady, setQuMap, quMap])

  return (
    <>
      {
        mapConfig && mapOptions && 
        <QUMap plugins={['AMap.PlaceSearch']} {...mapOptions} quMapRef={quMap} onLoad={handleMapLoad}>
          {map(infoWindows, w =>
            <InfoWindow quMap={quMap} poi={w} key={w.id} onOpenSetting={handleFieldSettingOpen} />)}
          {
            <GroupLayer name={projectId.toString()} data={layers}>
              <Event onClick={handlePOIClick} />
            </GroupLayer>
          }
          <Controls 
            defaultActiveControl={mapLegends.length ? 'LegendControl': undefined}
            className={cx(styles.controls, { [styles.offset]: isDetailPanelVisible })}
          >
            <Zoom />
            <TileLayer
              tileLayer={mapConfig.baseMap}
              defaultTileLayer={mapConfig.baseMap}
            />
            <Switch
              title="标签开关"
              layers={layersForLabelControl}
              onChange={handleLabelChange}
            />
            <Legend 
              mapLegends={mapLegends} 
              name="LegendControl" 
            />
            {mapConfig.ranging && <Distance />}
          </Controls>
          {
            isEditorOpen && <Editor
              preData={preData}
              ref={editor}
              type={editorType as EditorProps['type']}
              onFinish={handleEdtiorFinish}
              onOpen={handleEditorOpen}
            />
          }
        </QUMap>
      }
      {
        enable && <OverlayEditorPanel
          mapInstance={quMap}
          isNewPOI={isNewPOI}
          isEditing={isEditing}
          editorType={editorType}
          drawerVisible={drawerVisible}
          onClose={handleEditorClose}
          onReset={handleEditorReset}
          onChange={handleEditorTypeChange}
          onFinish={handleOverlayEditorFinish}
          onSearchPOISelected={handleSearchPOISelected}
        />
      }
      <InfoWindowSettingModal dialog={infoWindowSettingDialog} tableNodeId={fieldSettingTableNodeId} />
    </>
  )
}

export default MapContainer
