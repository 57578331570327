import React from 'react'
import isArray from 'lodash/isArray'
import find from 'lodash/find'
import { PICKER_COLORS, Color } from 'src/constants/colors'
import styles from './selectRenderer.module.scss'

interface Choice {
  color: Color,
  id: string,
  name?: string
}

export interface Props {
  value: string | string[]
  typeOptions: {
    choices: Choice[]
  }
}

export const SelectRenderer: React.FC<Partial<Props>> = ((props) => {
  const { value, typeOptions } = props

  let choices: Choice[] = []
  if (value) {
    if (isArray(value)) {
      choices = (value as string[]).flatMap(id => {
        const choice = find(typeOptions?.choices, (c: Choice) => c.id === id)
        return choice ? [choice] : []
      })
    } else {
      const choice = find(typeOptions?.choices, (c: Choice) => c.id === value)
      choices = choice ? [choice] : []
    }
  }
  return <div className={styles.renderer}>
    {
      choices.filter(c => c.name !== undefined).map((choice, index) => {
        return (
          <span
            key={index} className={styles.tag}
            style={{ backgroundColor: choice?.color ? PICKER_COLORS[choice.color] : undefined }}
          >
            {choice.name}
          </span>
        )
      })
    }
  </div>
})
