import React from 'react'
import ReactDOM from 'react-dom/server'
import cx from 'clsx'
import Icon from 'src/components/common/Icon'
import CellRenderer, { Params as CellRendererParams } from 'src/components/field/CellRenderer/CellRenderer'
import styles from './gridRowIndex.module.scss'

interface Params extends CellRendererParams {
  onOpenDetail?: (id: string) => void;
}
export default class GridRowIndexRenderer extends CellRenderer {
  id?: string;
  onOpenDetail?: (id: string) => void;
  tooltip!: HTMLElement;

  init (p: Params): void {
    this.value = p?.value
    this.id = p.node.id
    this.onOpenDetail = p.onOpenDetail

    const el = this.createEL()
    el.innerHTML = ReactDOM.renderToStaticMarkup(
      <div className={styles.container}>
        <div className={cx('ag-row-index', styles.index)}>{this.value}</div>
        {
          this.onOpenDetail &&
          <div className={cx('ag-expand-button', styles.expandButton)}>      
            <Icon type="expandArrow" />
          </div>
        }
      </div>
    )
    
    const expandButton = el.querySelector('.ag-expand-button')
    expandButton?.addEventListener('click', this.showDetail)
    expandButton?.addEventListener('mouseenter', this.showTooltip)
    expandButton?.addEventListener('mouseleave', this.hideTooltip)

    this.eGui = el
  }
  getGui (): HTMLElement {
    return this.eGui
  }
  refresh (): boolean {
    return false
  }
  destroy (): void {
    const expandButton = this.eGui.querySelector('.ag-expand-button')
    expandButton?.removeEventListener('click', this.showDetail)
    expandButton?.removeEventListener('mouseenter', this.showTooltip)
    expandButton?.removeEventListener('mouseleave', this.hideTooltip)
    this.hideTooltip()
  }
  showDetail = (): void => {
    this.onOpenDetail?.(this.id as string)
  }
  showTooltip = (): void => {
    const expandButton = this.eGui.querySelector('.ag-expand-button')
    const tooltip = document.createElement('div')
    tooltip.className = 'mt-grid-cell-tooltip'
    tooltip.textContent = '展开数据'
    if (expandButton && tooltip) {
      const { top, left } = expandButton.getBoundingClientRect()

      tooltip.style.top = `${top - 30}px`
      tooltip.style.left = `${left - 20}px`
      tooltip.style.display = 'block'

      this.tooltip = tooltip
      document.body.appendChild(this.tooltip)
    }
  }

  hideTooltip = (): void => {
    if (this.tooltip) {
      this.tooltip.remove()
    }
  }
}
