import React, { useState } from 'react'
import map from 'lodash/map'
import { Table } from 'antd'
import cx from 'clsx'
import { TypeOptions } from 'src/typings/tableNode'
import useDispatch from 'src/hooks/common/useDispatch'
import { transformToStandardTypeOptions } from 'src/hooks/field/useTableGrid'
import ActionButton from 'src/components/project/ActionButton'
import Icon from 'src/components/common/Icon'
import SmallDivider from 'src/components/common/SmallDivider'
import { useDialogState } from 'src/hooks/common/useDialog'
import { transformTypeOptionsToChartOptions } from 'src/modules/chart/transformers'
import Chart from 'src/components/common/Chart'
import ChartConfigModal from 'src/components/field/TableChart/ChartConfigModal'
import TableEditorModal from 'src/components/field/Table/TableEditorModal'
import { updateCells } from 'src/state/tableNode/actions'
import styles from './tableRenderer.module.scss'
import { renderField } from './TablePreviewCellRenderer'

export interface Props {
  tableNodeId?: number;
  columnId?: string;
  rowId?: string;
  name?: string;
  value: Record<string, unknown>[];
  typeOptions: {
    columns: TypeOptions['columns'];
    charts: TypeOptions['charts'];
    activeChart: TypeOptions['activeChart'];
  }
}

type ViewMode = 'table' | 'chart'
export const TableRenderer: React.FC<Props> = ({
  tableNodeId,
  columnId,
  name,
  value,
  typeOptions,
  rowId
}) => {
  const { columns, charts } = typeOptions
  const [viewMode, setViewMode] = useState<ViewMode>('table')
  const dialog = useDialogState()
  const tableDialog = useDialogState()
  const dispatch = useDispatch()
  const tableColumns = map(columns, c => {
    return {
      key: c.id,
      title: c.name,
      dataIndex: c.id,
      render: (value: string) => {
        const typeOptions = transformToStandardTypeOptions(c)
        return <div className={styles.cell}>
          {renderField(c.type, value, typeOptions)}
        </div>
      }
    }
  })
  const dataSource = map(value, (v, index) => {
    return {
      key: index,
      ...v
    }
  })

  function handleConfigureChart () {
    dialog.open()
  }

  function handleEditClick () {
    tableDialog.open()
  }

  function handleTableSave (val: unknown) {
    dispatch(updateCells({
      id: tableNodeId as number,
      rowId: rowId as string,
      cells: {
        [columnId as string]: val
      }
    }))

    tableDialog.close()
  }

  const previewOption = transformTypeOptionsToChartOptions(typeOptions, value)

  return (
    <div className={styles.wrap}>
      <div className={styles.actions}>
        <ActionButton
          className={styles.actionButton}
          icontype="edit"
          text="编辑"
          onClick={handleEditClick}
        />
        <ActionButton
          className={styles.actionButton}
          icontype={'infoWindowSetting'}
          text={'配置图表'}
          onClick={handleConfigureChart}
        />
        <div className={styles.viewMode}>
          <Icon
            type="table2" onClick={() => setViewMode('table')}
            className={cx(viewMode === 'table' && styles.active)}
          />
          <SmallDivider light mh10 />
          <Icon
            type="chart" onClick={() => setViewMode('chart')}
            className={cx(viewMode === 'chart' && styles.active)}
          />
        </div>
      </div>
      {
        viewMode === 'table' &&
        <Table
          className={styles.table}
          rowClassName={styles.row}
          dataSource={dataSource}
          columns={tableColumns}
          size="small"
          bordered
          pagination={{ pageSize: 5, hideOnSinglePage: true }}
          scroll={{ x: 'max-content' }}
        />
      }
      {
        viewMode === 'chart' &&
        <>
          {
            charts === null ?
              <div className={styles.emptyChart}>
                <div>
                  未配置图表，<span className={styles.press} onClick={handleConfigureChart}>点击配置</span>
                </div>
              </div>
              :
              <div className={styles.chartContainer}>
                <Chart option={previewOption} />
              </div>
          }
        </>
      }
      <ChartConfigModal
        dialog={dialog}
        name={name}
        value={value}
        typeOptions={typeOptions}
        tableNodeId={tableNodeId}
        columnId={columnId}
      />
      <TableEditorModal 
        dialog={tableDialog} 
        onOk={handleTableSave}
        onCancel={() => tableDialog.close()}
        columns={typeOptions?.columns}
        rows={value}
      />
    </div>
  )
}
