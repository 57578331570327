import { PayloadAction } from 'src/typings/common'
import { State } from 'src/state/project/slice'
import { LayerNode } from 'src/typings/mapTableNode'
import { TreeNode } from 'src/typings/tree'
import { Project, ProjectPatch, RecycleProject } from 'src/typings/project'
import { Workspace } from 'src/typings/workspace'
import projectAdapter from './entityAdapter'

const loopTree = (state: State, treeData: TreeNode[], currOrder: { count: number }) => {
  treeData.forEach(t => {
    if (t.type === 'map') {
      state.zIndexMap[t.id] = currOrder.count--
    }
    t.children && loopTree(state, t.children, currOrder)
  })
}

const isSameWorkspace = (w1?: Partial<Workspace>, w2?: Partial<Workspace>) => {
  return w1 && w2 && w1.type === w2.type && w1.id === w2.id
}

/**
 * @name project/create
 * @param state
 * @param action payload {@link Project}
 */
export function create (state: State, action: PayloadAction<Project>): void {
  const { wsWorkspace, ...project } = action.payload
  if (isSameWorkspace(state.currentWorkspace, wsWorkspace)) {
    projectAdapter.addOne(state, project)
  }
}

/**
 * @name project/recover
 * @param state
 * @param action payload {@link Project}
 */

export function recover (state: State, action: PayloadAction<{ project: Project }>): void {
  const { wsWorkspace, project } = action.payload
  if (isSameWorkspace(state.currentWorkspace, wsWorkspace)) {
    projectAdapter.addOne(state, project)
  }
}

/**
 * @name project/update
 * @param state
 * @param action payload {@link ProjectPatch}
 */
export function update (state: State, action: PayloadAction<ProjectPatch>): void {
  const { projectID, updatedFields } = action.payload
  projectAdapter.updateOne(state, {
    id: projectID,
    changes: updatedFields
  })
}

/**
 * @name project/delete
 * @param state
 * @param action
 */
export function deleteProject (state: State,
  action: PayloadAction<{ id: number, recycleProject: RecycleProject }>): void {
  projectAdapter.removeOne(state, action.payload.id)
}

/**
 * @name project/deleteRecycle
 * @param state
 * @param action
 */

export function deleteRecycle (state: State, action: PayloadAction<{ projectID: number }>): void {
  //
}

/**
 * @name project/setLayerNodes
 * @param state
 * @param action payload {@link LayerNode}
 */
export function setLayerNodes (state: State, action: PayloadAction<{ id: number, layerNodes: LayerNode[] }>): void {
  state.layerNodes[action.payload.id] = action.payload.layerNodes
}

/**
 * @ignore
 */
export function updateZIndexMap (
  state: State,
  action: PayloadAction<{ projectID: number, treeData: TreeNode[] }>
): void {
  const { payload } = action
  let mapNodesCount = 0
  if (state.layerNodes[payload.projectID]) {
    state.layerNodes[payload.projectID]?.forEach(node => {
      if (node.type === 'map') {
        mapNodesCount++
      }
    })

    loopTree(state, payload.treeData, { count: mapNodesCount })
  }

  state.zIndexMap = { ...state.zIndexMap }
}

/**
 * @ignore
 */
function setProjects (state: State, action: PayloadAction<{ workspace: Workspace, projects: Project[] }>): void {
  projectAdapter.removeAll(state)
  projectAdapter.addMany(state, action.payload.projects)
  state.currentWorkspace = action.payload.workspace
}


export interface CoordinateEditor {
  visible: boolean;
  trigger: 'open' | 'close' | null;
  data?: {
    id: number;
    rowId: string;
    cells: Record<string, unknown>
  }
}

/**
 * @ignore
 */
export function updateCoordinateEditor (
  state: State,
  action: PayloadAction<Partial<CoordinateEditor>>
): void {
  Object.assign(state.coordinateEditor, action.payload)
}

export interface MultiLineTextEditor {
  visible: boolean;
  trigger: 'open' | 'close' | null;
  openFrom: 'renderer' | 'editor';
  data?: {
    id: number;
    rowId: string;
    cellId: string;
    value: string;
  }
}

/**
 * @ignore
 */
export function updateMultiLineTextEditor (
  state: State,
  action: PayloadAction<Partial<MultiLineTextEditor>>
): void {
  Object.assign(state.multiLineTextEditor, action.payload)
}

/**
 * @ignore
 */
export function updateSiderVisible (
  state: State,
  action: PayloadAction<boolean>
): void {
  state.siderVisible = action.payload
}


/**
 * @ignore
 */
export const reducers = {
  create,
  recover,
  update,
  setProjects,
  setLayerNodes,
  updateZIndexMap,
  delete: deleteProject,
  deleteRecycle,
  updateCoordinateEditor,
  updateMultiLineTextEditor,
  updateSiderVisible
}

// TODO:
// 1. Normalize layerNodes
