import React from 'react'
import { Dropdown, Menu, Tooltip } from 'antd'
import cx from 'clsx'
import { MenuInfo } from 'rc-menu/lib/interface'
import compact from 'lodash/compact'
import Icon, { IconType } from 'src/components/common/Icon'
import { RoleType } from 'src/constants/permissions'
import { WorkspaceRole } from 'src/typings/workspace'
import { useWorkspaceRoles } from 'src/hooks/workspace/useWorkspaceRoles'
import styles from './dropdownMenu.module.scss'

export type ActionItemType = {
  key?: string;
  action?: string;
  name?: string;
  icon?: string;
  type?: string;
  permission?: RoleType;
  withDivider?: boolean;
  disabled?: boolean;
  disabledTooltip?: string | null;
}

interface DropdownMenuProps {
  actions: ActionItemType[];
  onClick?: (action: string) => void;
  trigger?: ('contextMenu' | 'click' | 'hover')[] | undefined;
  className?: string;
  roleData?: Partial<WorkspaceRole>
}
// FIXME:  Each child in a list should have a unique "key" prop. Check the render method of `Trigger`.
const DropdownMenu: React.FC<DropdownMenuProps> = (props) => {
  const { actions, roleData, onClick, trigger = ['click'], className, children } = props
  const { checkPermission } = useWorkspaceRoles()
  const handleCatalogMenuClick = (ev: MenuInfo, action?: string) => {
    ev.domEvent.stopPropagation()
    onClick && action && onClick(action)
  }
  const menuItems = compact(actions.map((item: ActionItemType, idx: number) => {
    const shouldRender = !item.permission || checkPermission(roleData?.id, item.permission, roleData?.type)
    return shouldRender && <>
      {item.withDivider && <Menu.Divider key={idx+'divider'} />}
      <Menu.Item
        onClick={(ev) => handleCatalogMenuClick(ev, item.action)}
        key={idx}
        className={cx(styles.item, item.type === 'danger' ? styles.danger : '')}
        disabled={item.disabled}
      >
        <Tooltip title={item.disabledTooltip}>
          {item.icon && <Icon type={item.icon as IconType} className={styles.icon} />}
          {item.name}
        </Tooltip>
      </Menu.Item>
    </>
  }))

  const renderMenu = () => {
    return (
      <Menu className={cx(styles.dropdownMenu, className)}>
        {menuItems}
      </Menu>
    )
  }
  return (
    menuItems.length ? <Dropdown trigger={trigger} overlay={renderMenu} placement='bottomRight'>
      {children}
    </Dropdown> : null

  )
}

export default DropdownMenu
