import { createAsyncThunk } from '@reduxjs/toolkit'
import Cookies from 'js-cookie'
import AuthAPI from 'src/api/auth'
import UploadAPI from 'src/api/upload'
import UserAPI from 'src/api/users'
import { TOKEN, IS_AUTH, REFRESH_TOKEN } from 'src/constants/storage'
import { uploadToCOS } from 'src/utils/upload'
import { DEFAULT_TOKEN_EXPIRES } from 'src/constants/network'


export const login = createAsyncThunk(
  'user/login',
  async (payload: { phoneNumber: string, code: string, registrationCode?: string }) => {
    const { phoneNumber, code, registrationCode } = payload
    const res = await AuthAPI.login(phoneNumber, code, registrationCode)

    Cookies.set(TOKEN, res.token, { expires: DEFAULT_TOKEN_EXPIRES })
    Cookies.set(REFRESH_TOKEN, res.refreshToken, { expires: DEFAULT_TOKEN_EXPIRES })
    Cookies.set(IS_AUTH, 'true', { expires: DEFAULT_TOKEN_EXPIRES })

    return res
  }
)

export const getCode = createAsyncThunk(
  'user/getCode',
  async (phoneNumber: string) => {
    const res = await AuthAPI.getCode(phoneNumber)

    return res
  }
)

export const matchUser = createAsyncThunk(
  'user/matchPhoneNumber',
  async (phoneNumber: string) => {
    return (await UserAPI.match(phoneNumber)).detail
  }
)

export const searchUsers = createAsyncThunk(
  'user/search',
  async (payload: { query: string, orgId: number }) => {
    const { query, orgId } = payload
    return (await UserAPI.search(query, orgId)).detail
  }
)

export const updateUser = createAsyncThunk(
  'user/update_async',
  async (payload: { name: string, avatar?: string }) => {
    const { name, avatar } = payload
    return await UserAPI.update({ name, avatar })
  }
)

export const uploadUserAvatar = createAsyncThunk(
  'user/uploadAvatar',
  async (payload: { avatar: File }) => {
    const { avatar } = payload
    const { detail } = await UploadAPI.getInfo('user/avatar')
    const { url } = await uploadToCOS(avatar, detail)
    return url
  }
)

export const validateInviteCode = createAsyncThunk(
  'user/validateInviteCode',
  async (payload: string): Promise<{ valid: boolean }> => {
    const { detail } = await AuthAPI.validateInviteCode(payload)

    return {
      valid: detail
    }
  }
)
