import React, { forwardRef, useImperativeHandle, useState, useRef } from 'react'
import type { ICellEditorParams } from 'ag-grid-community'
import { Select, Tag } from 'antd'
import type { RefSelectProps } from 'antd/lib/select'
import noop from 'lodash/noop'
import isArray from 'lodash/isArray'
import { PICKER_COLORS } from 'src/constants/colors'
import { useCellEditorParams } from 'src/hooks/field/useCellParams'
import type { TypeOptions } from 'src/typings/tableNode'
import styles from './selectEditor.module.scss'

export interface Props extends ICellEditorParams {
  type: string;
  typeOptions: TypeOptions;
  onBlur?: (value: string) => void;
  inGrid?: boolean;
  inDetailPanel: boolean;
}

export type CustomTagProps = {
  label: React.ReactNode;
  value: unknown;
  disabled: boolean;
  onClose: (event?: React.MouseEvent<HTMLElement, MouseEvent>) => void;
  closable: boolean;
};
export const SelectEditor = forwardRef((props: Partial<Props>, ref) => {
  const { inGrid = false, inDetailPanel = false } = props
  const [value, setValue] = useState<string | undefined>(props.value || undefined)
  const { type, onBlur = noop } = props
  const { typeOptions } = useCellEditorParams(props as ICellEditorParams) as unknown as Props
  const selectRef = useRef<RefSelectProps>(null)
  const [open, setOpen] = useState(false)
  const tagRender = (props: CustomTagProps) => {
    const { value, closable, onClose } = props
    const choice = typeOptions?.choices?.find(c => c.id === value)

    if (choice?.name) {
      return (
        <Tag
          className={styles.tag}
          color={choice?.color ? PICKER_COLORS[choice.color] : undefined}
          closable={closable} onClose={onClose}>
          <span className={styles.tagName}>{choice.name}</span>
        </Tag>
      )
    } else {
      return <></>
    }
  }

  useImperativeHandle(ref, () => {
    return {
      getReactContainerStyle () {
        return {
          width: props.eGridCell?.clientWidth + 'px',
          minHeight: props.eGridCell?.clientHeight + 'px'
        }
      },
      getReactContainerClasses () {
        return [styles.popup]
      },
      isPopup () {
        return true
      },
      getValue () {
        return value ? value : null
      },
      afterGuiAttached () {
        if (props.value) {
          if (isArray(props.value)) {
            setValue(props.value as unknown as string)
          } else {
            const choice = typeOptions?.choices?.find(c => c.id === props.value)
            if (choice?.name) {
              setValue(props.value)
            } else {
              setValue(undefined)
            }
          }
        }

        selectRef.current?.focus()
      }
    }
  })

  function handleChange (value: string) {
    setValue(value)
    if (type === 'singleChoice') {
      setTimeout(() => props.stopEditing?.(), 0)
    }
  }

  function handleBlur () {
    onBlur(value)
    props.stopEditing?.()
  }

  return (
    <Select
      ref={selectRef}
      value={value}
      open={open}
      className={styles.select}
      bordered={!inGrid}
      autoFocus={inGrid || inDetailPanel}
      placeholder={type === 'multiChoice' ? '搜索' : '请选择'}
      filterOption={(input, option) => {
        return option?.title && (option.title as string).indexOf(input) !== -1
      }}
      showSearch={type === 'multiChoice'}
      tagRender={tagRender}
      mode={type === 'multiChoice' ? 'multiple' : undefined}
      onBlur={handleBlur}
      onChange={handleChange}
      allowClear={type === 'singleChoice'}
      notFoundContent="无数据"
      getPopupContainer={triggerNode => triggerNode.parentElement}
      onFocus={() => {
        setOpen(true)
      }}
    >
      {typeOptions?.choices.map(item => (
        <Select.Option key={item.id} value={item.id} title={item.name}>
          <div className={styles.option}>
            <Tag
              className={styles.tag}
              color={item.color ? PICKER_COLORS[item.color] : undefined}>
              {item.name}
            </Tag>
          </div>
        </Select.Option>
      ))}
    </Select>
  )
})
