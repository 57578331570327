import React from 'react'
import filter from 'lodash/filter'
import includes from 'lodash/includes'
import find from 'lodash/find'
import map from 'lodash/map'
import withVisible from 'src/hoc/withVisible'
import { TypeOptions } from 'src/typings/tableNode'
import AggregatorFormItem from './AggregatorFormItem'
import FieldsFormItem from './FieldsFormItem'
import GroupByFormItem from './GroupByFormItem'
import MethodFormItem from './MethodFormItem'
import SortMethodFormItem from './SortMethodFormItem'

interface ChartOptionsFormProps {
  fields: string[];
  columns: TypeOptions['columns'];
}

const ChartBasicFormItems = withVisible<ChartOptionsFormProps>(({
  columns,
  fields
}) => {
  const numericColumns = filter(columns, c => {
    return c.type === 'number'
  })
  const statsMethodOptions = [{
    value: 'count',
    label: '计数统计 (统计出现次数)'
  }, {
    value: 'singleField',
    label: '统计单个字段'
  }, {
    value: 'multiField',
    label: '统计多个字段'
  }] 

  const statsMethodFieldName = find(fields, f => includes(f, 'stats.method'))

  function renderFormItem (fieldName: string, statsMethodFieldName?: string) {
    switch (fieldName) {
    case 'params.config.xAxis.groupBy':
    case 'params.config.groupBy':
      return <GroupByFormItem 
        key={fieldName} 
        name={fieldName} 
        columns={columns} 
      />
    case 'params.config.yAxis.stats.method':
    case 'params.config.stats.method':
      return <MethodFormItem 
        key={fieldName}
        name={fieldName} 
        options={statsMethodOptions}
        dependency="type"
      />
    case 'params.config.yAxis.stats.fields':
    case 'params.config.stats.fields':
      return <FieldsFormItem 
        key={fieldName}
        name={fieldName} 
        columns={numericColumns} 
        dependency={statsMethodFieldName}
      />
    case 'params.config.yAxis.stats.aggregator':
    case 'params.config.stats.aggregator':
      return <AggregatorFormItem 
        key={fieldName}
        name={fieldName} 
        dependency={statsMethodFieldName}
      />
    case 'params.config.xAxis.sortMethod':
      return <SortMethodFormItem 
        key={fieldName}
        name={fieldName} 
        dependency={statsMethodFieldName}
      />
    default:
      return null
    }
  }

  return (
    <>
      {map(fields, name => renderFormItem(name, statsMethodFieldName))}
    </>
  )
})

export default ChartBasicFormItems
