import React, { useState } from 'react'
import { Popover } from 'antd'
import noop from 'lodash/noop'
import ActionContent from 'src/components/project/ActionContent/ActionContent'
import ActionCountAndClear from 'src/components/project/ActionCountAndClear'
import ActionButton from 'src/components/project/ActionButton'

interface FilterProps {
  name: string;
  type: string;
  count?: number;
  maxCount?: number;
  onAdd?: () => void;
  onClear?: () => void;
  onOpen?: () => void;
  onClose?: () => void;
}

const ActionContainer: React.FC<FilterProps> = ({
  name,
  type,
  count = 0,
  maxCount = 0,
  onAdd = noop,
  onClear = noop,
  children,
  onOpen = noop,
  onClose = noop
}) => {
  const [visible, setVisible] = useState(false)

  function handleVisibleChange (visible: boolean) {
    if (visible) {
      onOpen()
    } else {
      onClose()
    }
    setVisible(visible)
  }

  return (
    <Popover
      placement="bottomLeft"
      content={<ActionContent
        name={name}
        count={count}
        maxCount={maxCount}
        onAdd={onAdd}
      >
        {children}
      </ActionContent>}
      trigger="click"
      visible={visible}
      onVisibleChange={handleVisibleChange}
    >
      <ActionButton
        active={!!count}
        icontype={type}
      >
        {name} <ActionCountAndClear count={count} onClear={onClear} />
      </ActionButton>
    </Popover>
  )
}

export default ActionContainer
