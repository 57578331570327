import AppConfig from 'src/config'
import { createInstance } from 'src/utils/fetch'
import type { APIResponse } from 'src/typings/common'

const fetch = createInstance({
  baseURL: `${AppConfig.baseApiURL}/upload/`
})

export type UploadType = 'org/avatar' | 'user/avatar' | 'attachment' | 'import/table'

export interface InfoResponse extends APIResponse {
  detail: {
    credentials: {
      TmpSecretId: string;
      TmpSecretKey: string;
      Token: string;
    };
    fileID: string;
    filePath: string;
    prefix: string;
  }
}

const UploadAPI = {
  async getInfo (type: UploadType, extra?: unknown): Promise<InfoResponse> {
    const { data } = await fetch.post('/info/', {
      type,
      extra
    })

    return data
  }
}

export default UploadAPI
