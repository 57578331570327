import { Column } from 'src/typings/tableNode'

export function isCoordinateFormula (column: Column): boolean {
  return column.type === 'formula' && column.typeOptions?.expectedType === 'coordinate'
}

export function isStringFormula (column: Column): boolean {
  return column.type === 'formula' && column.typeOptions?.expectedType === 'singleLineText'
}

export function isNumberFormula (column: Column): boolean {
  return column.type === 'formula' && column.typeOptions?.expectedType === 'number'
}