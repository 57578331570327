import React from 'react'
import get from 'lodash/get'
import isEqual from 'lodash/isEqual'

import ReactDOM from 'react-dom/server'
import CellRenderer from 'src/components/field/CellRenderer/CellRenderer'
import { CheckboxRenderer } from './CheckboxRenderer'

let lastClickInfo: unknown
export class CheckboxCellRenderer extends CellRenderer {
  private inputEl?: Element | null
  private wrapEL?: Element | null

  init (p: CellRenderer['params']): void {
    this.value = p?.realValue
    this.params = p
    const el = this.createEL()

    el.innerHTML = ReactDOM.renderToStaticMarkup(<CheckboxRenderer value={this.value} />)
    this.inputEl = el.querySelector('input[type="checkbox"]')
    this.inputEl?.addEventListener('change', this.handleChange)
    this.wrapEL = el.querySelector('[data-checkbox="check"]')
    this.wrapEL?.addEventListener('click', this.handleClick)

    this.eGui = this.withCollaborators(el, this.params?.collaborator)
  }

  handleClick = (): void => {
    const clickInfo = this.getClickInfo()
    if (!isEqual(lastClickInfo, clickInfo)) {
      lastClickInfo = clickInfo
    }
    else {
      const value = !get(this.inputEl, 'checked')
      this.params?.setValue(value ? value : null)
    }
  }

  getClickInfo (): unknown {
    const cell = this.params?.api.getFocusedCell()
    return {
      index: cell?.rowIndex,
      columnId: cell?.column.getColId()
    }
  }

  handleChange = (e: Event): void => {
    const value = get(e.target, 'checked')
    this.params?.setValue(value ? value : null)
  }

  getGui (): HTMLElement {
    return this.eGui
  }

  refresh (): boolean {
    return false
  }

  destroy (): void {
    this.inputEl?.removeEventListener('change', this.handleChange)
  }
}
