import get from 'lodash/get'
import type { Dispatch } from 'redux'
import { createAsyncThunk, AsyncThunkPayloadCreator, AsyncThunkOptions, AsyncThunk } from '@reduxjs/toolkit'
import type { GetState } from 'src/store'
import { DEFAULT_TIMEOUT } from 'src/constants/network'
import { actions as appActions } from 'src/state/app/slice'

// internal type from @reduxjs/toolkit/dist/createAsyncThunk.d.ts
declare type AsyncThunkConfig = {
  state?: unknown;
  dispatch?: Dispatch;
  extra?: unknown;
  rejectValue?: unknown;
  serializedErrorType?: unknown;
  pendingMeta?: unknown;
  fulfilledMeta?: unknown;
  rejectedMeta?: unknown;
}

const CHECK_FETCHING_STATUS_INTERVAL = 300

export function createAsyncThunkSyncWithWebSocket<
  Returned, 
  ThunkArg = void, 
  ThunkApiConfig extends AsyncThunkConfig = Record<string, never>
> (
  typePrefix: string, 
  payloadCreator: AsyncThunkPayloadCreator<Returned, ThunkArg, ThunkApiConfig>, 
  options?: AsyncThunkOptions<ThunkArg, ThunkApiConfig>
): AsyncThunk<Returned, ThunkArg, ThunkApiConfig> {
  async function payloadCreatorSyncWithWebSocket (...params: Parameters<typeof payloadCreator>) {
    const thunkAPI = params[1]
    const res = await payloadCreator(...params)
    const requestID = get(res, 'requestID')

    if (requestID) {
      thunkAPI.dispatch(appActions.addFetching(requestID))

      await checkFetchingStatus(requestID, thunkAPI.getState as GetState)

      return Object.assign(res, {
        requestID: null
      })
    }

    return res
  }

  return createAsyncThunk(
    typePrefix,
    payloadCreatorSyncWithWebSocket,
    options
  )
}

interface CreateUndoRedoActionReturn<R, ThunkArg, C> {
  action: AsyncThunk<R, ThunkArg, C>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  redoAction: AsyncThunk<R, any, C>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  undoAction: AsyncThunk<R, any, C>;
}
interface CreateUndoRedoActionReturnCreateParams<R, C> {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  undo?: AsyncThunk<R, any, C>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  redo?: AsyncThunk<R, any, C>; 
  syncWithWebSocket?: boolean;
}

export function createUndoRedoAction<
  Returned, 
  ThunkArg = void, 
  ThunkApiConfig extends AsyncThunkConfig = Record<string, never>
> (
  typePrefix: string, 
  payloadCreator: AsyncThunkPayloadCreator<Returned, ThunkArg, ThunkApiConfig>, 
  options?: AsyncThunkOptions<ThunkArg, ThunkApiConfig>
): typeof create {
  function create (
    params: CreateUndoRedoActionReturnCreateParams<Returned, ThunkApiConfig> = {}
  ): CreateUndoRedoActionReturn<Returned, ThunkArg, ThunkApiConfig> {
    const undoTypePrefix = `undo_${typePrefix}`
    const redoTypePrefix = `redo_${typePrefix}`
    const undo = createAsyncThunk(undoTypePrefix, payloadCreator, options)
    const redo = createAsyncThunk(redoTypePrefix, payloadCreator, options)

    return {
      action: params.syncWithWebSocket 
        ? createAsyncThunkSyncWithWebSocket(typePrefix, payloadCreator, options)
        : createAsyncThunk(typePrefix, payloadCreator, options),
      undoAction: params.undo ?? undo,
      redoAction: params.redo ?? redo
    }
  }

  return create
}

async function checkFetchingStatus (requestID: string, getState: GetState, waited = 0) {
  return new Promise((resolve, reject) => {
    const rootState = getState()
    
    const isFetching = rootState.app.fetchingList.findIndex(id => id === requestID) !== -1
    let timer = null

    if (waited >= DEFAULT_TIMEOUT) {
      reject(new Error('timeout'))
    }

    if (isFetching) {
      timer = setTimeout(() => {
        resolve(checkFetchingStatus(requestID, getState, waited + CHECK_FETCHING_STATUS_INTERVAL))
      }, CHECK_FETCHING_STATUS_INTERVAL)
    } else {
      timer && clearTimeout(timer)
      return resolve(timer)
    }
  })
}