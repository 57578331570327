import React from 'react'
import cx from 'clsx'
import LoadingImg from 'src/assets/icons/loading.svg'
import styles from './loading.module.scss'

interface Props {
  hide?: boolean
}

const Loading: React.FC<Props> = ({ hide = false }) => {
  return (
    <div className={cx(styles.loading, { [styles.hide]: hide })}>
      <img src={LoadingImg} alt="Loading..." />
    </div>
  )
}

export default Loading
