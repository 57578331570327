import React from 'react'
import cx from 'clsx'
import { ReactComponent as MapIcon } from 'src/assets/icons/map.svg'
import { ReactComponent as UndoIcon } from 'src/assets/icons/undo.svg'
import { ReactComponent as RedoIcon } from 'src/assets/icons/redo.svg'
import { ReactComponent as FilterIcon } from 'src/assets/icons/filter.svg'
import { ReactComponent as GroupingIcon } from 'src/assets/icons/grouping.svg'
import { ReactComponent as SortingIcon } from 'src/assets/icons/sorting.svg'
import { ReactComponent as LineHeightIcon } from 'src/assets/icons/line-height.svg'
import { ReactComponent as SearchIcon } from 'src/assets/icons/search.svg'
import { ReactComponent as SettingsIcon } from 'src/assets/icons/settings.svg'
import { ReactComponent as ExportIcon } from 'src/assets/icons/export.svg'
import { ReactComponent as ShareIcon } from 'src/assets/icons/share.svg'
import { ReactComponent as Share2Icon } from 'src/assets/icons/share2.svg'
import { ReactComponent as ArrowExpandIcon } from 'src/assets/icons/arrow-expand.svg'
import { ReactComponent as ProjectListIcon } from 'src/assets/icons/project-list.svg'
import { ReactComponent as RecycleBinIcon } from 'src/assets/icons/recycle-bin.svg'
import { ReactComponent as NotificationIcon } from 'src/assets/icons/notification.svg'
import { ReactComponent as HelpIcon } from 'src/assets/icons/help.svg'
import { ReactComponent as TableIcon } from 'src/assets/icons/table.svg'
import { ReactComponent as ArrowRightIcon } from 'src/assets/icons/arrow-right.svg'
import { ReactComponent as ArrowRight2Icon } from 'src/assets/icons/arrow-right-2.svg'
import { ReactComponent as ArrowLeftIcon } from 'src/assets/icons/arrow-left.svg'
import { ReactComponent as FolderIcon } from 'src/assets/icons/folder.svg'
import { ReactComponent as LayerIcon } from 'src/assets/icons/layer.svg'
import { ReactComponent as MoreIcon } from 'src/assets/icons/more.svg'
import { ReactComponent as MoreOutlineIcon } from 'src/assets/icons/more-outline.svg'
import { ReactComponent as PlusIcon } from 'src/assets/icons/menu-plus.svg'
import { ReactComponent as CaretRightIcon } from 'src/assets/icons/caret-right.svg'
import { ReactComponent as CaretBottomIcon } from 'src/assets/icons/caret-bottom.svg'
import { ReactComponent as EyeIcon } from 'src/assets/icons/eye.svg'
import { ReactComponent as EyeCloseIcon } from 'src/assets/icons/eye-close.svg'
import { ReactComponent as CloseIcon } from 'src/assets/icons/close.svg'
import { ReactComponent as CloseFilledIcon } from 'src/assets/icons/close-filled.svg'
import { ReactComponent as InfoWindowSettingIcon } from 'src/assets/icons/info-window-setting.svg'
import { ReactComponent as StarIcon } from 'src/assets/icons/star.svg'
import { ReactComponent as CompareIcon } from 'src/assets/icons/compare.svg'
import { ReactComponent as GridCompareIcon } from 'src/assets/icons/grid-compare.svg'
import { ReactComponent as Point } from 'src/assets/icons/point.svg'
import { ReactComponent as Line } from 'src/assets/icons/line.svg'
import { ReactComponent as Polygon } from 'src/assets/icons/polygon.svg'
import { ReactComponent as SingleLineText } from 'src/assets/icons/col-type-single-line-text.svg'
import { ReactComponent as MultiLineText } from 'src/assets/icons/col-type-multi-line-text.svg'
import { ReactComponent as Coordinate } from 'src/assets/icons/col-type-coordinate.svg'
import { ReactComponent as Number } from 'src/assets/icons/col-type-number.svg'
import { ReactComponent as SingleChoice } from 'src/assets/icons/col-type-single-choice.svg'
import { ReactComponent as MultiChoice } from 'src/assets/icons/col-type-multi-choice.svg'
import { ReactComponent as Datetime } from 'src/assets/icons/col-type-date-time.svg'
import { ReactComponent as Gallery } from 'src/assets/icons/col-type-gallery.svg'
import { ReactComponent as Attachment } from 'src/assets/icons/col-type-attachment.svg'
import { ReactComponent as Formula } from 'src/assets/icons/col-type-formula.svg'
import { ReactComponent as DOC } from 'src/assets/icons/attachment-type-doc.svg'
import { ReactComponent as XLS } from 'src/assets/icons/attachment-type-xls.svg'
import { ReactComponent as ZIP } from 'src/assets/icons/attachment-type-zip.svg'
import { ReactComponent as Delete } from 'src/assets/icons/delete.svg'
import { ReactComponent as Download } from 'src/assets/icons/download.svg'
import { ReactComponent as Edit } from 'src/assets/icons/edit.svg'
import { ReactComponent as Mark } from 'src/assets/icons/mark.svg'
import { ReactComponent as Info } from 'src/assets/icons/info.svg'
import { ReactComponent as Movable } from 'src/assets/icons/movable.svg'
import { ReactComponent as Config } from 'src/assets/icons/config.svg'
import { ReactComponent as ExpandArrow } from 'src/assets/icons/expand-arrow.svg'
import { ReactComponent as MapPin } from 'src/assets/icons/map-pin.svg'
import { ReactComponent as MapCenter } from 'src/assets/icons/map-center.svg'
import { ReactComponent as Legend } from 'src/assets/icons/legend.svg'
import { ReactComponent as Feedback } from 'src/assets/icons/feedback.svg'
import { ReactComponent as PlusOperator } from 'src/assets/icons/plus.svg'
import { ReactComponent as MinusOperator } from 'src/assets/icons/minus.svg'
import { ReactComponent as MultiplyOperator } from 'src/assets/icons/multiply.svg'
import { ReactComponent as DivideOperator } from 'src/assets/icons/divide.svg'
import { ReactComponent as CatalogIcon } from 'src/assets/icons/catalog.svg'
import { ReactComponent as BatchIcon } from 'src/assets/icons/batch.svg'
import { ReactComponent as DuplicateIcon } from 'src/assets/icons/duplicate.svg'
import { ReactComponent as LineHeightSMIcon } from 'src/assets/icons/line-height-sm.svg'
import { ReactComponent as LineHeightMDIcon } from 'src/assets/icons/line-height-md.svg'
import { ReactComponent as LineHeightLGIcon } from 'src/assets/icons/line-height-lg.svg'
import { ReactComponent as LineHeightXLIcon } from 'src/assets/icons/line-height-xl.svg'
import { ReactComponent as OperationSavingIcon } from 'src/assets/icons/operation-saving.svg'
import { ReactComponent as NetworkBrokenIcon } from 'src/assets/icons/network-broken.svg'
import { ReactComponent as NetworkConnectedIcon } from 'src/assets/icons/network-connected.svg'
import { ReactComponent as NetworkConnectingIcon } from 'src/assets/icons/network-connecting.svg'
import { ReactComponent as MembersIcon } from 'src/assets/icons/members.svg'
import { ReactComponent as InviteMemberIcon } from 'src/assets/icons/invite-member.svg'
import { ReactComponent as PlainPlusIcon } from 'src/assets/icons/plain-plus.svg'
import { ReactComponent as UnionIcon } from 'src/assets/icons/union.svg'
import { ReactComponent as TrashIcon } from 'src/assets/icons/trash.svg'
import { ReactComponent as TableIcon2 } from 'src/assets/icons/table-2.svg'
import { ReactComponent as ChartIcon } from 'src/assets/icons/chart.svg'
import { ReactComponent as BarChartIcon } from 'src/assets/icons/bar-chart.svg'
import { ReactComponent as PieChartIcon } from 'src/assets/icons/pie-chart.svg'
import { ReactComponent as LineChartIcon } from 'src/assets/icons/line-chart.svg'
import { ReactComponent as ShrinkIcon } from 'src/assets/icons/shrink.svg'
import { ReactComponent as CheckboxIcon } from 'src/assets/icons/checkbox.svg'
import { ReactComponent as SnapshotIcon } from 'src/assets/icons/snapshot.svg'

import styles from './icon.module.scss'

export type IconType =
  'map' |
  'undo' |
  'redo' |
  'filter' |
  'grouping' |
  'sorting' |
  'lineHeight' |
  'search' |
  'settings' |
  'export' |
  'share' |
  'share2' |
  'arrowExpand' |
  'projectList' |
  'recycleBin' |
  'help' |
  'table' |
  'arrowRight' |
  'arrowRight2' |
  'arrowLeft' |
  'folder' |
  'layer' |
  'more' |
  'moreHover' | 'moreActive' |
  'plus' | 'plusActive' |
  'caretRight' | 'caretBottom' |
  'visible' | 'visibleActive' |
  'invisible' | 'invisibleActive' |
  'close' | 'closeFilled' |
  'infoWindowSetting' |
  'star' |
  'compare' |
  'gridCompare' |
  'point' |
  'line' |
  'polygon' |
  'datetime' |
  'singleLineText' |
  'multiLineText' |
  'coordinate' |
  'number' |
  'singleChoice' |
  'multiChoice' |
  'gallery' |
  'attachment' |
  'formula' |
  'doc' |
  'xls' |
  'zip' |
  'delete' |
  'download' |
  'edit' |
  'mark' |
  'info' |
  'movable' |
  'config' |
  'expandArrow' |
  'mapPin' |
  'mapCenter' |
  'legend' |
  'notification' |
  'feedback' |
  'plusOperator' |
  'minusOperator' |
  'multiplyOperator' |
  'divideOperator' |
  'catalog' |
  'batch' |
  'duplicate' |
  'lineHeightSM' |
  'lineHeightMD' |
  'lineHeightLG' |
  'lineHeightXL' |
  'operationSaving' |
  'networkBroken' | 'networkConnected' | 'networkConnecting' |
  'members' |
  'inviteMember' |
  'plainPlus' |
  'union' |
  'trash' |
  'table2' |
  'chart' |
  'barChart' |
  'pieChart' |
  'lineChart' |
  'shrink' |
  'checkbox' | 
  'boolean' |
  'snapshot'

interface Props {
  type: IconType;
  className?: string;
  style?: React.CSSProperties;
  onClick?: (event?: React.MouseEvent<HTMLElement>) => void
}

const icons = {
  map: <MapIcon />,
  undo: <UndoIcon />,
  redo: <RedoIcon />,
  filter: <FilterIcon />,
  grouping: <GroupingIcon />,
  sorting: <SortingIcon />,
  lineHeight: <LineHeightIcon />,
  search: <SearchIcon />,
  settings: <SettingsIcon />,
  export: <ExportIcon />,
  share: <ShareIcon />,
  share2: <Share2Icon className={styles.fill} />,
  arrowExpand: <ArrowExpandIcon />,
  projectList: <ProjectListIcon />,
  recycleBin: <RecycleBinIcon />,
  help: <HelpIcon />,
  notification: <NotificationIcon />,
  table: <TableIcon />,
  arrowRight: <ArrowRightIcon />,
  arrowRight2: <ArrowRight2Icon />,
  arrowLeft: <ArrowLeftIcon />,
  folder: <FolderIcon />,
  layer: <LayerIcon />,
  more: <MoreIcon className={styles.fill} />,
  moreHover: <MoreOutlineIcon className={styles.hover} />,
  moreActive: <MoreOutlineIcon className={styles.active} />,
  plus: <PlusIcon className={styles.hover} />,
  plusActive: <PlusIcon className={styles.active} />,
  caretRight: <CaretRightIcon />,
  caretBottom: <CaretBottomIcon />,
  visible: <EyeIcon className={styles.fill} />,
  visibleActive: <EyeIcon className={styles.hover} />,
  invisible: <EyeCloseIcon className={styles.fill} />,
  invisibleActive: <EyeCloseIcon className={styles.hover} />,
  close: <CloseIcon />,
  closeFilled: <CloseFilledIcon />,
  infoWindowSetting: <InfoWindowSettingIcon />,
  star: <StarIcon className={styles.fill} />,
  compare: <CompareIcon className={styles.fill} />,
  gridCompare: <GridCompareIcon />,
  point: <Point />,
  line: <Line />,
  polygon: <Polygon />,
  datetime: <Datetime />,
  singleLineText: <SingleLineText />,
  multiLineText: <MultiLineText />,
  coordinate: <Coordinate />,
  number: <Number />,
  singleChoice: <SingleChoice />,
  multiChoice: <MultiChoice />,
  gallery: <Gallery />,
  attachment: <Attachment />,
  formula: <Formula />,
  doc: <DOC />,
  xls: <XLS />,
  zip: <ZIP />,
  delete: <Delete />,
  download: <Download />,
  edit: <Edit />,
  mark: <Mark />,
  info: <Info />,
  movable: <Movable />,
  config: <Config />,
  expandArrow: <ExpandArrow />,
  mapPin: <MapPin />,
  mapCenter: <MapCenter />,
  legend: <Legend />,
  feedback: <Feedback />,
  plusOperator: <PlusOperator />,
  minusOperator: <MinusOperator />,
  multiplyOperator: <MultiplyOperator />,
  divideOperator: <DivideOperator />,
  catalog: <CatalogIcon />,
  batch: <BatchIcon />,
  duplicate: <DuplicateIcon />,
  lineHeightSM: <LineHeightSMIcon />,
  lineHeightMD: <LineHeightMDIcon />,
  lineHeightLG: <LineHeightLGIcon />,
  lineHeightXL: <LineHeightXLIcon />,
  operationSaving: <OperationSavingIcon />,
  networkBroken: <NetworkBrokenIcon />,
  networkConnected: <NetworkConnectedIcon />,
  networkConnecting: <NetworkConnectingIcon />,
  members: <MembersIcon />,
  inviteMember: <InviteMemberIcon />,
  plainPlus: <PlainPlusIcon />,
  union: <UnionIcon />,
  trash: <TrashIcon />,
  table2: <TableIcon2 />,
  chart: <ChartIcon />,
  barChart: <BarChartIcon />,
  pieChart: <PieChartIcon />,
  lineChart: <LineChartIcon />,
  shrink: <ShrinkIcon />,
  checkbox: <CheckboxIcon />,
  boolean: <CheckboxIcon />,
  snapshot: <SnapshotIcon />
}

const Icon: React.FC<Props> = ({
  type,
  className,
  style,
  onClick,
  ...restProps
}) => {
  return (
    <i {...restProps} className={cx(styles.icon, className)} style={style} onClick={onClick}>
      {icons[type]}
    </i>
  )
}

export default Icon
