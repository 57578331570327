import { useCallback, useState } from 'react'
import cellSlice from 'src/state/cell/slice'
import useSelector from './useSelector'
import useDispatch from './useDispatch'

export interface Return {
  editing: boolean;
  isCurrentEditCell: (cellId: string) => boolean;
  startEdit: (cellId: string) => void;
  endEdit: () => void;
}

function useField (initialValue = false): Return {
  const dispatch = useDispatch()
  const currentEditCellId = useSelector(state => state.cell.currentEditCellId)
  const [editing, setEditing] = useState(initialValue)
  const startEdit = useCallback((cellId: string) => {
    setEditing(true)
    dispatch(cellSlice.actions.updateCurrentEditCellId(cellId))
  }, [dispatch, setEditing])

  const endEdit = useCallback(() => {
    setEditing(false)
  }, [setEditing])

  const isCurrentEditCell = useCallback((cellId: string) => {
    return currentEditCellId === cellId
  }, [currentEditCellId])

  return {
    editing,
    isCurrentEditCell,
    startEdit,
    endEdit
  }
}

export default useField
