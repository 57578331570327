import React, { useState, useEffect, useRef } from 'react'
import replace from 'lodash/replace'
import toNumber from 'lodash/toNumber'
import isEqual from 'lodash/isEqual'
import get from 'lodash/get'
import map from 'lodash/map'
import cx from 'clsx'
import filter from 'lodash/filter'
import { InputNumber, Form, Row, Col, Select, Input, Slider, Switch, Tooltip } from 'antd'
import type { FormInstance } from 'antd/lib/form/hooks/useForm'
import { QuestionCircleOutlined } from '@ant-design/icons'
import ColorPicker from 'src/components/common/ColorPicker'
import ColorRangeSelector from 'src/components/common/ColorRangeSelector'
import useSelector from 'src/hooks/common/useSelector'
import { tableNodeSelectors } from 'src/state/tableNode/slice'
import { isNumberFormula } from 'src/utils/field'
import FieldTypeSelector from 'src/components/common/FieldTypeSelector'
import { FILL_COLORS_STEP, PICKER_COLORS } from 'src/constants/colors'
import styles from './layerSettingPanel.module.scss'

interface Props {
  collapse?: boolean;
  form: FormInstance;
  onChange: () => void;
}

const SingleChoiceTooltipContainer: React.FC<{
  children: JSX.Element,
  show: boolean,
  title?: React.ReactNode
}> = ({
  children,
  show,
  title = '使用单选字段填充时不可修改'
}) => {
  return show ? <Tooltip title={title}>
    {children}
  </Tooltip> : children
}

const FillSetting: React.FC<Props> = ({
  collapse = false,
  form,
  onChange
}) => {
  const [isCollapse, setCollapse] = useState(collapse)
  const [field, setField] = useState<string | undefined>()
  const initialField = form.getFieldValue('fillFieldID')
  const length = form.getFieldValue('fillConfig.colors')?.length
  const [step, setStep] = useState(length >= 3 ? length : 6)
  const options = useSelector(state => {
    const tableNode = tableNodeSelectors.selectById(state, form.getFieldValue('tableNodeID'))
    return filter(tableNode?.columns, c => {
      return c.type === 'number' || c.type === 'singleChoice' || isNumberFormula(c)
    })
  })

  const currentOption = options.find(o => o.id === form.getFieldValue('fillFieldID'))
  const isFillWithSingleChoice = currentOption?.type === 'singleChoice'
  useEffect(() => {
    setField(initialField)
  }, [initialField])

  function handleToggleClick () {
    setCollapse(!isCollapse)
  }

  function handleColorChange (color: string) {
    if (!isEqual(form.getFieldValue('fillConfig.colors'), [color])) {
      form.setFieldsValue({
        'fillConfig.colors': [color]
      })
      onChange()
    }
  }

  function handleFieldChange (option: string) {
    setField(option)
    if (!option) {
      form.setFieldsValue({
        'fillConfig.colors': [get(form.getFieldValue('fillConfig.colors'), '[0]')]
      })
      onChange()
    } else {
      onChange()
    }
  }

  function handleColorStepChange (value: number) {
    setStep(value)
  }

  const handleColorRangeChange = useRef((colors: string[]) => {
    const fillColors = form.getFieldValue('fillConfig.colors')
    if (!isEqual(fillColors, colors)) {
      form.setFieldsValue({
        'fillConfig.colors': colors
      })
      onChange()
    }
  })

  return (
    <div className={styles.config}>
      <div className={styles.configHeader}>
        <div>填充样式</div>
        <div className={styles.configToggle} onClick={handleToggleClick}>
          {isCollapse ? '展开' : '收起'}
        </div>
      </div>
      <div className={cx(styles.container, { [styles.collapse]: isCollapse })}>
        <Row gutter={[8, 8]}>
          <Form.Item name="fillConfig.colors" hidden>
            <Input />
          </Form.Item>
          {
            field ?
              <>
                <Col span={12}>
                  <div className={styles.box}>
                    <div className={styles.configLabel}>颜色数量</div>
                    <SingleChoiceTooltipContainer show={isFillWithSingleChoice}>
                      <Select
                        onChange={handleColorStepChange} defaultValue={step} disabled={isFillWithSingleChoice}
                      >
                        {
                          map(FILL_COLORS_STEP, i => <Select.Option key={i} value={i}>{i}</Select.Option>)
                        }
                      </Select>
                    </SingleChoiceTooltipContainer>
                  </div>
                </Col>
                <Col span={12}>
                  <div className={styles.box}>
                    <div className={styles.configLabel}>填充方式
                      <Tooltip
                        title={<div>等分依据数值大小均分填充<br />
                          等比依据 POI 数量均分填充</div>}>
                        <QuestionCircleOutlined className={styles.configLabelTooltip} />
                      </Tooltip>
                    </div>
                    <SingleChoiceTooltipContainer show={isFillWithSingleChoice}>

                      <Form.Item name="fillConfig.fillMethod">
                        <Select
                          disabled={isFillWithSingleChoice}
                        >
                          <Select.Option key="quantize" value="quantize">等分</Select.Option>
                          <Select.Option key="quantile" value="quantile">等比</Select.Option>
                        </Select>
                      </Form.Item>
                    </SingleChoiceTooltipContainer>
                  </div>
                </Col>
                <Col span={24}>
                  <SingleChoiceTooltipContainer
                    show={isFillWithSingleChoice}
                    title={<div>填充颜色与数据表中单选选项颜色一致<br />如需修改请在数据表中调整</div>}>
                    <div className={styles.box}>
                      {isFillWithSingleChoice ?
                        <ColorRangeSelector
                          disabled
                          step={currentOption?.typeOptions?.choices.length}
                          colors={map(currentOption?.typeOptions?.choices, c => PICKER_COLORS[c.color])}
                          onChange={handleColorRangeChange.current} />
                        :
                        <ColorRangeSelector
                          step={step}
                          colors={form.getFieldValue('fillConfig.colors')}
                          onChange={handleColorRangeChange.current} />
                      }
                    </div>
                  </SingleChoiceTooltipContainer>
                </Col>
              </>
              : <Col span={8}>
                <div className={styles.box}>
                  <div className={styles.configLabel}>颜色</div>
                  <ColorPicker
                    color={get(form.getFieldValue('fillConfig.colors'), '[0]')}
                    onChange={handleColorChange}
                  />
                </div>
              </Col>
          }
          {
            field &&
            <Col span={8}>
              <div className={styles.box}>
                <div className={styles.configLabel}>倒序</div>
                <SingleChoiceTooltipContainer show={isFillWithSingleChoice}>
                  <Form.Item name="fillConfig.reverse" valuePropName="checked">
                    <Switch disabled={isFillWithSingleChoice} />
                  </Form.Item>
                </SingleChoiceTooltipContainer>
              </div>
            </Col>
          }
          <Col span={16}>
            <div className={cx(styles.box, styles.opacitySetting)}>
              <div className={styles.configLabel}>不透明度</div>
              <Row gutter={4}>
                <Col span={16}>
                  <Form.Item name="fillConfig.fillOpacity">
                    <Slider
                      min={0}
                      max={1}
                      step={0.1}
                    />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item name="fillConfig.fillOpacity">
                    <InputNumber
                      size="small"
                      min={0}
                      max={1}
                      formatter={value => `${toNumber(value) * 100}%`}
                      parser={value => toNumber(replace(value ?? '', '%', '')) / 100 as 0 | 1}
                    />
                  </Form.Item>
                </Col>
              </Row>
            </div>
          </Col>
          <Col span={24}>
            <div className={styles.box}>
              <div className={styles.configLabel}>依据以下字段填充</div>
              <Form.Item name="fillFieldID">
                <FieldTypeSelector
                  placeholder={'请选择字段'}
                  onChange={handleFieldChange}
                  options={options}
                  allowClear
                />
              </Form.Item>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  )
}

export default FillSetting
