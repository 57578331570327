import findIndex from 'lodash/findIndex'
import get from 'lodash/get'
import sortBy from 'lodash/sortBy'
import find from 'lodash/find'
import filter from 'lodash/filter'
import { 
  moveTableNode,
  MoveTableNodeNamePayload,
  undoMoveTableNode, 
  redoMoveTableNode
} from 'src/state/tableNode/actions'
import { projectSelectors } from 'src/state/project/slice'
import type { UndoRedo } from 'src/features/undoRedo/undoRedo'
import type { RootState } from 'src/store'

const updateTableNodeNameUndoRedo = {
  action: moveTableNode,
  undoAction: undoMoveTableNode,
  redoAction: redoMoveTableNode,
  getUndoPayload: (arg: MoveTableNodeNamePayload, state: RootState) => {
    const { id, moveInfo } = arg
    const { projectID } = moveInfo
    const project = projectSelectors.selectById(state, projectID)
    const tableNode = find(project?.tableNodes, t => t.id === id)
    const tableNodesSorted = sortBy(filter(project?.tableNodes, t => t.parentID === tableNode?.parentID), 'order')
    const index = findIndex(tableNodesSorted, t => t.id === tableNode?.id)

    return {
      id,
      moveInfo: {
        projectID,
        parentID: tableNode?.parentID,
        prevID: get(tableNodesSorted, `${index -1}.id`)
      }
    }
  },
  getRedoPayload: (arg: MoveTableNodeNamePayload) => {
    return arg
  }
} as UndoRedo

export default updateTableNodeNameUndoRedo