import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import map from 'lodash/map'
import filter from 'lodash/filter'
import { Row, Col, Card, Button, Dropdown, Descriptions, Menu } from 'antd'
import { PlusOutlined, EllipsisOutlined } from '@ant-design/icons'
import { useDialogState, useDeleteConfirmDialog } from 'src/hooks/common/useDialog'
import CreateProjectModal from 'src/components/workspace/CreateProjectModal'
import EditProjectModal from 'src/components/workspace/EditProjectModal'
import { useParameters } from 'src/hooks/common/useParameters'
import { formatDate } from 'src/utils/moment'
import { Project } from 'src/typings/project'
import { deleteProject } from 'src/state/project/actions'
import { useWorkspaces } from 'src/hooks/workspace/useWorkspaces'
import type { AppDispatch } from 'src/store'
import PageContainer from 'src/components/common/PageContainer'
import { useWorkspaceRoles } from 'src/hooks/workspace/useWorkspaceRoles'
import { ProjectPermission, OrgPermission } from 'src/constants/permissions'
import { OrgPermissionWrapper } from 'src/components/common/PermissionWrapper'
import { ROLE_ENTITY } from 'src/typings/workspace'
import { useDocumentTitle, getWorkspaceTitle } from 'src/hooks/common/useDocumentTitle'
import DuplicationModal from 'src/components/common/DuplicationModal'
import styles from './projects.module.scss'

interface Props {
  projects?: Project[],
  workspaceType?: string
}

const Projects: React.FC<Props> = (props) => {
  const dispatch: AppDispatch = useDispatch()
  const history = useHistory()
  const createProjectDialog = useDialogState()
  const duplicateProjectDialog = useDialogState()
  const editProjectDialog = useDialogState()
  const [editingProject, setEditingProject] = useState<Project>()
  const [projectDuplicate, setProjectDuplicate] = useState<Project>()
  const { workspaceId, workspaceType } = useParameters()
  const { workspace } = useWorkspaces(workspaceType, workspaceId)
  const { checkProjectPermission } = useWorkspaceRoles()
  const confirmDialog = useDeleteConfirmDialog({
    messageIds: {
      title: 'project.delete.title',
      content: 'project.delete.content'
    }
  })

  useDocumentTitle('项目列表', getWorkspaceTitle(workspace))

  function handleCardClick (id: number) {
    history.push(`/${workspaceType}/${workspace?.id}/p/${id}/t/`)
  }

  function handleGotoMapClick (id: number) {
    history.push(`/${workspaceType}/${workspace?.id}/p/${id}/map`)
  }

  function handleMemberClick (id: number) {
    history.push(`/${workspaceType}/${workspace?.id}/p/${id}/member`)
  }

  function handleProjectSettingClick (project: Project) {
    setEditingProject(project)
    editProjectDialog.open()
  }

  function handleDeleteProjectClick (projectId: number, projectName: string) {
    confirmDialog({
      messageValues: {
        name: projectName
      }
    }).then(() => {
      dispatch(deleteProject(projectId))
    })
  }

  function handleDuplicateProjectClick (project: Project) {
    setProjectDuplicate(project)
    duplicateProjectDialog.open()
  }

  const cardDropdownMenu = (project: Project) => (
    <Menu className={styles.cardDropdownMenu}>
      {checkProjectPermission(project.id, ProjectPermission.ProjectRename) &&
      <Menu.Item onClick={() => handleProjectSettingClick(project)}>修改名称</Menu.Item>}
      {checkProjectPermission(project.id, ProjectPermission.Collaborator) &&
      <Menu.Item onClick={() => handleMemberClick(project.id)}>协作成员</Menu.Item>}
      {checkProjectPermission(project.id, ProjectPermission.ProjectDuplicate) &&
      <Menu.Item onClick={() => handleDuplicateProjectClick(project)}>复制项目</Menu.Item>}
      {checkProjectPermission(project.id, ProjectPermission.ProjectDelete) &&
      <Menu.Item danger onClick={() => handleDeleteProjectClick(project.id, project.name)}>删除</Menu.Item>}
    </Menu>
  )

  const getTableCount = (tableNodes: Project['tableNodes']) => {
    return filter((tableNodes || []), node => node.type === 'table').length
  }

  return (
    <PageContainer
      title={'项目列表'}
      actions={<>
        {workspace?.type === 'org' && <OrgPermissionWrapper
          id={workspaceId}
          role={OrgPermission.ProjectCreate}
          type={ROLE_ENTITY.org}
        >
          <Button icon={<PlusOutlined />} type="primary" onClick={createProjectDialog.open}>新建项目</Button>
        </OrgPermissionWrapper>}
        {workspace?.type === 'user' &&
        <Button icon={<PlusOutlined />} type="primary" onClick={createProjectDialog.open}>新建项目</Button>}
        <CreateProjectModal dialog={createProjectDialog} type={props.workspaceType} />
        <EditProjectModal dialog={editProjectDialog} initProject={editingProject} />
      </>}>
      <div className={styles.list}>
        <Row gutter={[16, 16]}>
          {
            map(props.projects, project =>
              <Col key={project.id} sm={24} md={12} lg={12} xl={8} xxl={6}>
                <Card
                  hoverable className={styles.card}>
                  <Descriptions
                    className={styles.cardDescriptions}
                    colon={false}
                    title={<div className={styles.cardTitle}>
                      {project.name}
                    </div>}
                    column={1}
                    extra={
                      <Dropdown overlay={cardDropdownMenu(project)} placement="bottomRight" trigger={['click']}>
                        <Button className={styles.cardDropdownButton} icon={<EllipsisOutlined />} />
                      </Dropdown>}>
                    <Descriptions.Item label="数据表">
                      {getTableCount(project.tableNodes)} 张
                    </Descriptions.Item>
                    <Descriptions.Item label="创建时间">
                      {
                        `${formatDate(project.createTime)}`
                      }
                    </Descriptions.Item>
                  </Descriptions>
                  <div className={styles.cardActions}>
                    <Button type="primary" onClick={() => handleGotoMapClick(project.id)}>进入地图</Button>
                    <Button onClick={() => handleCardClick(project.id)}>数据表格</Button>
                  </div>
                </Card>
              </Col>
            )
          }
        </Row>
      </div>
      <DuplicationModal
        type="project"
        dialog={duplicateProjectDialog}
        duplicate={projectDuplicate}
      />
    </PageContainer>
  )
}

export default Projects
