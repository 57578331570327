import React from 'react'
import type { GridApi } from 'ag-grid-community'
import { message } from 'antd'
import debounce from 'lodash/debounce'
import filter from 'lodash/filter'
import { Filter as FilterType } from 'src/constants/filter'
import { useOverlayEditor } from 'src/hooks/map/useMap'
import ActionButton from 'src/components/project/ActionButton'
import Filter from 'src/components/project/Filter'
import SmallDivider from 'src/components/common/SmallDivider'
import useMapFilter from 'src/hooks/map/useMapFilter'
import { TablePermissionWrapper, ProjectPermissionWrapper } from 'src/components/common/PermissionWrapper'
import { TableNodePermisson, ProjectPermission } from 'src/constants/permissions'
import BatchActions from 'src/components/common/BatchActions'
import { PROJECT_BATCH_MENU } from 'src/constants/menu'
import { useRowCompare } from 'src/hooks/common/useRowCompare'
import { useParameters } from 'src/hooks/common/useParameters'
import Search from 'src/components/map/Search'

interface Props {
  onFilterChange?: (filters: FilterType[]) => void
  selectedRows?: string[]
  gridApi?: GridApi
}

export const MapActions: React.FC<Props> = () => {
  const {
    isEditorOpen,
    enable,
    setIsEditorOpen,
    selectedMapLayer
  } = useOverlayEditor()
  const { projectId } = useParameters()
  const { filtersWithId, updateMapFilters, tableNodeId } = useMapFilter(selectedMapLayer?.layerNodeID)
  const { mapCompare, gotoComparePage } = useRowCompare()

  const handleAddFilter = () => {
    message.error('未选中图层或选中图层被隐藏')
  }

  const handleAddPOI = () => {
    if (enable) {
      setIsEditorOpen(!isEditorOpen)
    } else {
      message.error('未选中图层或选中图层被隐藏')
    }
  }

  const handleFilterChange = debounce((filters: FilterType[]) => {
    updateMapFilters(filters)
  }, 400)

  const handleBatchMenuItemClick = (action: string) => {
    switch (action) {
    case 'compare': 
      return gotoComparePage(mapCompare.tableNodeId as number, mapCompare.list)
    }
  }

  // TODO: support more actions in the future
  const actions = filter(PROJECT_BATCH_MENU, p => p.key === 'compare')

  return (
    <>
      <BatchActions 
        tableNodeId={tableNodeId as number}
        onActionClick={handleBatchMenuItemClick}
        actions={actions}
        selectedText={`已选中 ${mapCompare.list?.length} 条数据`}
        selectedItems={mapCompare.list}
      />
      <TablePermissionWrapper id={tableNodeId} role={TableNodePermisson.MapFilter}>
        {!enable ? <ActionButton
          icontype={'filter'}
          text={'筛选'}
          onClick={handleAddFilter}
        /> : (filtersWithId && <Filter
          onChange={handleFilterChange}
          filtersWithId={filtersWithId}
          tableNodeId={tableNodeId}
          layerNodeId={selectedMapLayer?.layerNodeID}
        />)}
      </TablePermissionWrapper>
      <ProjectPermissionWrapper id={projectId} role={ProjectPermission.MapSearch}>
        <Search projectId={projectId} />
      </ProjectPermissionWrapper>
      {/* <TablePermissionWrapper id={tableNodeId} role={TableNodePermisson.MapNearBy}>
        <ActionButton
          icontype={'sorting'}
          text={'周边分析'}
        />
      </TablePermissionWrapper> */}
      <TablePermissionWrapper id={tableNodeId} role={TableNodePermisson.DataEdit}>
        <SmallDivider light mh15 />
        <ActionButton
          nobackground={true}
          icontype={'mapPin'}
          text={'添加数据'}
          active={true}
          onClick={handleAddPOI}
        />
      </TablePermissionWrapper>
    </>
  )
}

export default MapActions
