import React, { useImperativeHandle } from 'react'
import { Form, Select } from 'antd'
import map from 'lodash/map'
import type { FormInstance } from 'antd/lib/form/hooks/useForm'
import { NUMBER_PRECISIONS, NUMBER_FORMATS } from 'src/constants/field'

interface Props {
  form?: React.Ref<FormInstance>;
  config?: Record<string, unknown>;
}

export const NumberConfig: React.FC<Props> = ({
  form,
  config
}) => {
  const [innerForm] = Form.useForm()

  const initialValues = {
    format: NUMBER_FORMATS[0].value,
    precision: NUMBER_PRECISIONS[0].value,
    ...config
  }

  useImperativeHandle(form, () => innerForm)

  return (
    <Form
      name="number"
      form={innerForm}
      labelAlign="left"
      labelCol={{ span: 7 }}
      wrapperCol={{ span: 17 }}
      initialValues={initialValues}
    >
      <Form.Item
        label="格式化"
        name="format"
      >
        <Select>
          {
            map(NUMBER_FORMATS, o => <Select.Option key={o.value} value={o.value}>{o.label}</Select.Option>)
          }
        </Select>
      </Form.Item>
      <Form.Item
        label="精度"
        name="precision"
      >
        <Select>
          {
            map(NUMBER_PRECISIONS, o => <Select.Option key={o.value} value={o.value}>{o.label}</Select.Option>)
          }
        </Select>
      </Form.Item>
    </Form>
  )
}
