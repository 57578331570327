import React, { useState } from 'react'
import { Form, Input, Row, Button, message, Upload } from 'antd'
import type { UploadRequestOption } from 'rc-upload/lib/interface'
import { updateTeam, uploadTeamAvatar } from 'src/state/org/actions'
import { AppDispatch } from 'src/store'
import { Workspace } from 'src/typings/workspace'
import { OrgAvatar } from 'src/components/common/Avatar'
import useDispatch from 'src/hooks/common/useDispatch'
import PageContainer from 'src/components/common/PageContainer'
import { useDocumentTitle, getWorkspaceTitle } from 'src/hooks/common/useDocumentTitle'
import styles from './teamSetting.module.scss'

interface Props {
  workspace?: Workspace
}

const Projects: React.FC<Props> = ({ workspace }) => {
  const [form] = Form.useForm()
  const dispatch: AppDispatch = useDispatch()
  const [avatarUrl, setAvatarUrl] = useState(workspace?.avatar)

  useDocumentTitle('团队设置', getWorkspaceTitle(workspace))

  async function handleFinished () {
    await dispatch(updateTeam({
      id: workspace?.id as number,
      name: form.getFieldValue('name'),
      avatar: avatarUrl
    }))
    message.success('保存成功')
  }

  async function handleUpload (options: UploadRequestOption) {
    const { payload } = await dispatch(uploadTeamAvatar({
      id: workspace?.id as number,
      avatar: options.file as File
    }))
    setAvatarUrl(payload as string)
  }

  return <PageContainer title={'团队设置'}>
    <Row>
      <Form
        form={form}
        requiredMark={false}
        colon={false}
        initialValues={{ name: workspace?.name }}
        onFinish={handleFinished}
      >
        <Form.Item
          className={styles.upload}
        >
          <div className={styles.uploadField}>
            <OrgAvatar title={workspace?.name} size={64} src={avatarUrl} />
            <div className={styles.uploadLabel}>
              团队头像
              <Upload
                customRequest={handleUpload}
                showUploadList={false}
              >
                <Button type="link">{avatarUrl ? '重新上传' : '上传图片'}</Button>
              </Upload>
            </div>
          </div>
        </Form.Item>
        <Form.Item
          label="团队名称"
          name="name"
          rules={[{ required: true, message: '请输入团队名称' }]}
        >
          <Input />
        </Form.Item>
        {
          // TODO: edit team subdomain
          false &&
          <Form.Item
            label="团队地址"
          >
            <Form.Item
              name="subdomain" noStyle
              rules={[{ required: true, message: '请输入团队地址' }]}
            >
              <Input className={styles.subdomainInput} />
            </Form.Item>
            <span className={styles.domain}> .maptable.com</span>
          </Form.Item>
        }
        <Button type="primary" htmlType="submit" className={styles.saveButton}>
          保存
        </Button>
      </Form>
    </Row>
  </PageContainer>
}

export default Projects
