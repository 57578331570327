import React from 'react'
import { Form } from 'antd'
import { InfoCircleOutlined } from '@ant-design/icons'
import FieldTypeSelector from 'src/components/common/FieldTypeSelector'
import { Column } from 'src/typings/tableNode'
import { useMessages } from 'src/hooks/chart/useChart'

interface Props {
  columns?: Omit<Column, 'isPrimary'>[],
  name: string;
}

const GroupByFormItem: React.FC<Props> = ({
  name, 
  columns
}) => {
  const { label, tooltip } = useMessages(name)

  return <Form.Item
    name={name}
    label={label}
    tooltip={{ title: tooltip, icon: <InfoCircleOutlined /> }}
  >
    <FieldTypeSelector
      options={columns}
    />
  </Form.Item>
}

export default GroupByFormItem