import React, { useMemo, useState } from 'react'
import { useDeepCompareEffect } from 'react-use'
import find from 'lodash/find'
import isEqual from 'lodash/isEqual'
import map from 'lodash/map'
import { Menu, Dropdown } from 'antd'
import { COLOR_PALETTE_WITH_STEP } from 'src/constants/colors'
import styles from './colorRangeSelector.module.scss'

interface Props {
  disabled?: boolean;
  step?: number;
  colors?: string[];
  onChange?: (colors: string[]) => void;
}

function getColorScheme (colorPalette: Record<string, string[]>, colors?: string[]) {
  return find(Object.keys(colorPalette), (key) => isEqual(colorPalette[key], colors)) 
}

function getDefaultColorScheme (colorPalette: Record<string, string[]>) {
  return Object.keys(colorPalette)[0]
}

const ColorRangeSelector: React.FC<Props> = ({
  disabled,
  onChange,
  colors,
  step = 6
}) => {
  const colorPalette = useMemo(() => {
    return COLOR_PALETTE_WITH_STEP[step] ?? {}
  }, [step])
  const [
    selected, 
    setSelected
  ] = useState<string>(getColorScheme(colorPalette, colors) ?? getDefaultColorScheme(colorPalette))

  useDeepCompareEffect(() => {
    if(!getColorScheme(colorPalette, colors)) {
      setSelected(getDefaultColorScheme(colorPalette))
    } else {
      setSelected(getColorScheme(colorPalette, colors) ?? getDefaultColorScheme(colorPalette))
    }
  }, [colors, colorPalette])

  function handleClick (colorScheme: string) {
    setSelected(colorScheme)
    onChange?.(colorPalette[colorScheme])
  }

  const menu = (
    <Menu className={styles.overlay}>
      {
        map(Object.keys(colorPalette), (key) => {
          return (
            <Menu.Item key={key} onClick={() => handleClick(key)}>
              <div className={styles.block}>
                {
                  map(colorPalette[key], c => <span
                    key={c} className={styles.color} 
                    style={{ backgroundColor: c,
                      width: `${100 / step}%` }} />)
                }
              </div>
            </Menu.Item>
          )
        })
      }
    </Menu>
  )

  return (
    <Dropdown overlay={menu} trigger={['click']} disabled={disabled}>
      <div className={styles.menu}>
        {
          map(disabled ? colors : colorPalette[selected], c =>
            <span
              key={c} className={styles.color} style={{ backgroundColor: c,
                width: `${100 / step}%` }} />
          )
        }
      </div>
    </Dropdown>
  )
}

export default ColorRangeSelector
