import AppConfig from 'src/config'
import { createInstance } from 'src/utils/fetch'

import { APIResponse } from 'src/typings/common'
import { MapConfig } from 'src/typings/project'
import { TableNode } from 'src/typings/mapTableNode'

const fetch = createInstance({
  baseURL: `${AppConfig.baseApiURL}/projects/`
})

export interface ProjectDuplicateRequestData {
  id: number;
  includeData: boolean;
  includePermission?: boolean;
  name: string;
}

const ProjectAPI = {
  async create (projectName: string, organizationId?: number): Promise<APIResponse['detail']> {
    const { data } = await fetch.post('', {
      name: projectName,
      orgID: organizationId
    })

    return data
  },
  async update (
    projectId: number,
    name?: string,
    roleInherited?: boolean,
    mapConfig?: MapConfig
  ): Promise<APIResponse['detail']> {
    const { data } = await fetch.patch(`${projectId}/`, {
      name,
      roleInherited,
      mapConfig
    })

    return data
  },
  async getLayerNodes (projectID: number): Promise<APIResponse> {
    const { data } = await fetch.get(`${projectID}/layernodes/`)

    return data
  },
  async getLayerTables (projectID: number): Promise<APIResponse<TableNode[]>> {
    const { data } = await fetch.get(`${projectID}/tables/`)

    return data
  },
  async delete (projectID: number): Promise<APIResponse> {
    const { data } = await fetch.delete(`${projectID}/`)

    return data
  },
  async deleteRecycle (projectID: number): Promise<APIResponse> {
    const { data } = await fetch.delete(`/recycle/${projectID}/`)

    return data
  },
  async recover (projectID: number): Promise<APIResponse> {
    const { data } = await fetch.post(`/recycle/${projectID}/recover/`)

    return data
  },
  async invite (projectID: number, members: { phone: string, role: string }[]): Promise<APIResponse> {
    const { data } = await fetch.post(`${projectID}/members/invitations/`, { members })

    return data
  },
  async getMembers (projectID: number): Promise<APIResponse> {
    const { data } = await fetch.get(`${projectID}/members/`)

    return data
  },
  async updateMember (projectID: number, members: { id: number, role: string }[]): Promise<APIResponse> {
    const { data } = await fetch.put(`${projectID}/members/`, { members })

    return data
  },
  async deleteMember (projectID: number, memberID: number): Promise<APIResponse> {
    const { data } = await fetch.delete(`${projectID}/members/${memberID}/`)

    return data
  },
  async copy (
    projectID: number, 
    body: ProjectDuplicateRequestData
  ): Promise<APIResponse> {
    const { data } = await fetch.post(`${projectID}/copies/`, {
      ...body
    })

    return data
  }
}

export default ProjectAPI
