import React, { useCallback, useEffect, useState } from 'react'
import { Switch, Route, useRouteMatch, Redirect } from 'react-router-dom'
import flowRight from 'lodash/flowRight'
import { useDispatch } from 'react-redux'
import { AppDispatch } from 'src/store'
import withLayout from 'src/hoc/withLayout'
import { useProject } from 'src/hooks/project/useProject'
import { useParameters } from 'src/hooks/common/useParameters'
import { useWorkspaces, useWorkspacesEffect } from 'src/hooks/workspace/useWorkspaces'
import withWebSocket from 'src/hoc/withWebSocket'
import withCollaboratorWebSocket from 'src/hoc/withCollaboratorWebSocket'

import ProjectHeader from 'src/components/project/ProjectHeader'
import { CoordinateEditorModal } from 'src/components/field/Coordinate'
import Map from 'src/pages/Map'
import Table from 'src/pages/Table'
import ProjectRecycleBin from 'src/pages/ProjectRecycleBin'
import ProjectMember from 'src/pages/ProjectMember'
import Compare from 'src/pages/Compare'
import { getWorkspaceDetail } from 'src/state/workspace/actions'
import useSelector from 'src/hooks/common/useSelector'
import { useInfiniteRenderDebug } from 'src/hooks/common/useDebug'
import { useConfirmDialog } from 'src/hooks/common/useDialog'
import { actions as undoRedoActions } from 'src/state/undoRedo/slice'
import styles from './project.module.scss'

const Project: React.FC = () => {
  const dispatch: AppDispatch = useDispatch()
  const { projectId, workspaceId, workspaceType } = useParameters()
  const { workspace } = useWorkspaces(workspaceType, workspaceId)
  const { project } = useProject(workspace, projectId)
  const { path } = useRouteMatch()
  const [projectInitialized, setProjectInitialized] = useState(false)
  const workspaceRoleChanged = useSelector(state => state.workspace.workspaceRoleChanged)
  useEffect(() => {
    if (workspace && workspaceRoleChanged) {
      dispatch(getWorkspaceDetail(workspace))
    }
  }, [dispatch, workspace, workspaceRoleChanged])
  const confirmDialog = useConfirmDialog()
  useWorkspacesEffect()

  const returnToWorkspace = useCallback(() =>{
    confirmDialog({
      title: '无法访问该项目',
      content: '管理员删除了项目或修改了您在项目中的权限设置，无法继续访问该项目。',
      closable: false
    }).then(() => {
      window.location.replace(`/${workspaceType}/${workspaceId}`)
    })
  }, [workspaceId, workspaceType, confirmDialog])
  useEffect(() => {

    if (project) {
      setProjectInitialized(true)
    }
    else if(projectInitialized) {
      returnToWorkspace()
    }
  }, [project, projectInitialized, returnToWorkspace])

  useEffect(() => {
    return () => {
      dispatch(undoRedoActions.clear())
    }
  }, [projectId, dispatch])

  useInfiniteRenderDebug('Project')

  return (
    <div className={styles.page}>
      <ProjectHeader
        project={project}
      />
      <Switch>
        <Route path={`${path}/t/:tableNodeId?`} exact>
          <Table />
        </Route>
        <Route path={`${path}/map`} exact>
          <Map />
        </Route>
        <Route path={`${path}/recycle`} exact>
          <ProjectRecycleBin />
        </Route>
        <Route path={`${path}/member`} exact>
          <ProjectMember />
        </Route>
        <Route path={`${path}/compare`}>
          <Compare />
        </Route>
        <Redirect to={`${path}/t/`} />
      </Switch>
      <CoordinateEditorModal />
    </div>
  )
}

export default flowRight(
  withLayout('full'),
  withWebSocket,
  withCollaboratorWebSocket
)(Project)
