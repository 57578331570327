import { useState, useEffect } from 'react'
import map from 'lodash/map'
import concat from 'lodash/concat'
import sortBy from 'lodash/sortBy'
import groupBy from 'lodash/groupBy'
import join from 'lodash/join'
import mapValues from 'lodash/mapValues'
import compact from 'lodash/compact'
import first from 'lodash/first'
import min from 'lodash/min'
import max from 'lodash/max'
import some from 'lodash/some'
import type {
  ColumnResizedEvent,
  GetContextMenuItemsParams,
  RowNode,
  MenuItemDef,
  ValueSetterParams,
  GridApi,
  ColDef,
  BodyScrollEvent
} from 'ag-grid-community'
import useDispatch from 'src/hooks/common/useDispatch'
import { useParameters } from 'src/hooks/common/useParameters'
import {
  updateViewColumn,
  deleteColumn,
  createColumn,
  createRow,
  deleteRows,
  updateCells,
  updateColumn as updateColumnAction,
  deleteCells
} from 'src/state/tableNode/actions'
import type { View, TableNode, ColumnData } from 'src/typings/tableNode'
import { ADD_ROW_ID, INDEX_COLUMN_ID } from 'src/constants/grid'

import type { Props as SelectProps } from 'src/components/field/Select/SelectRenderer'
import type { Field } from 'src/components/grid/GridFieldConfig/GridFieldConfig'
import { useTableNodesInProject } from 'src/hooks/common/useTableNode'

interface Return {
  resizeColumn: (e: ColumnResizedEvent, view: View) => void;
  removeColumn: (columnId: string) => void;
  insertColumn: (prevColumnId?: string) => void;
  insertRow: (tableNodeId: number, prevRowId?: string) => void;
  updateCell: (params: ValueSetterParams, id: number) => boolean;
  exportExcel: (gridApi?: GridApi) => void;
  clearSelectionAndFocus: (gridApi?: GridApi | null) => void;
  addColumn: (prevColumnId: string, field: Field) => void;
  updateColumn: (columnId: string, field: Field) => void;
  scroll: (e: BodyScrollEvent) => void;
}

const MIN_COL_WIDTH = 50
const MAX_COL_WIDTH = 2000

export function useGrid (gridWrap?: React.RefObject<HTMLDivElement | null>): Return {
  const dispatch = useDispatch()
  const { tableNodeId, projectId } = useParameters()
  const { tableNode } = useTableNodesInProject(projectId, tableNodeId)

  function resizeColumn (e: ColumnResizedEvent, view: View) {
    if (e.finished && e.column && e.column.getId() !== INDEX_COLUMN_ID) {
      let width = e.column.getActualWidth()
      if (width < MIN_COL_WIDTH) {
        width = MIN_COL_WIDTH
      }
      if (width > MAX_COL_WIDTH) {
        width = MAX_COL_WIDTH
      }
      // FIXME: find out why updateViewColumn called infinitly in some cases
      const oldWidth = view.columns.find(c => c.id === e.column?.getId())?.width
      if(oldWidth !== width) {
        dispatch(updateViewColumn({
          id: tableNodeId,
          viewId: view.id,
          columnId: e.column.getId(),
          form: { width: width }
        }))
      }
    }
  }

  function insertRow (tableNodeId: number, prevRowId?: string) {
    dispatch(createRow({
      id: tableNodeId,
      prevRowId
    }))
  }
  function removeColumn (columnId: string) {
    dispatch(deleteColumn({
      id: tableNodeId,
      columnId
    }))
  }

  function insertColumn (prevColumnId?: string) {
    dispatch(createColumn({
      id: tableNodeId,
      name: '未命名',
      type: 'singleLineText',
      prevColumnId: prevColumnId
    }))
  }

  function updateColumn (columnId: string, field: Field) {
    dispatch(updateColumnAction({
      id: tableNodeId,
      columnId,
      name: field.name,
      type: field.type,
      typeOptions: field.typeOptions
    }))
  }

  function updateCell (params: ValueSetterParams, id: number) {
    if (params.newValue === undefined) return true

    const rowId = params.data.id
    const fieldId = params.colDef.field as string
    const newValue = params.newValue === '' ? null : params.newValue
    params.data[fieldId] = newValue
    dispatch(updateCells({
      id,
      rowId,
      cells: {
        [fieldId]: newValue
      }
    }))
    return true
  }

  function exportExcel (gridApi?: GridApi) {
    const cols = (gridApi?.getColumnDefs() as ColDef[]).filter(c => c.field && c.field.indexOf('#') === -1)
    gridApi?.exportDataAsExcel({
      sheetName: 'sheet',
      fileName: tableNode?.name,
      columnKeys: cols?.map(c => c.field as string),
      shouldRowBeSkipped: (params) => {
        return params.node.id === ADD_ROW_ID
      },
      processCellCallback: (params) => {
        const value = params.value.realValue
        const colDef = params.column.getColDef()
        const type = colDef.headerComponentParams?.type
        if (type === 'singleChoice' || type === 'multiChoice') {
          const choices = (colDef.headerComponentParams as SelectProps).typeOptions.choices
          if (typeof value === 'string') {
            return choices.find(c => c.id === value)?.name
          } else {
            return value?.map((v: string) => choices.find(c => c.id === v)?.name)
          }
        }
        if (type === 'attachment') {
          return join(map(value, v => v.url))
        }
        return value
      }
    })
  }

  function clearSelectionAndFocus (gridApi?: GridApi | null) {
    gridApi?.clearRangeSelection()
    gridApi?.clearFocusedCell()
  }

  function addColumn (prevColumnId: string, field: Field) {
    dispatch(createColumn({
      id: tableNodeId,
      prevColumnId,
      ...field
    }))
  }

  function scroll (e: BodyScrollEvent) {
    if (e.direction === 'horizontal') {
      if (e.left > 0) {
        gridWrap?.current?.classList.add('mt-grid-scroll')
      } else {
        gridWrap?.current?.classList.remove('mt-grid-scroll')
      }
    }
  }

  return {
    resizeColumn,
    removeColumn,
    insertColumn,
    updateCell,
    exportExcel,
    clearSelectionAndFocus,
    addColumn,
    updateColumn,
    insertRow,
    scroll
  }
}

interface UseGridDataReturn {
  columnData: ColumnData[];
  rowData: View['rows'];
}

// TODO: normalize data in the store
export function useGridData (data: TableNode, view: View, editable?: boolean): UseGridDataReturn {
  const rowsById = mapValues(groupBy(data.rows, 'id'), r => r[0])
  const columnsById = mapValues(groupBy(data.columns, 'id'), r => r[0])
  const viewColumnsSorted = sortBy(view.columns, 'order')
  const viewRowsSorted = sortBy(view.rows, 'order')

  const columnData = compact(map(viewColumnsSorted, c => {
    return columnsById[c.id] ? {
      ...c,
      ...columnsById[c.id]
    } : undefined
  }))
  const rowData = compact(concat(
    map(viewRowsSorted, r => {
      return rowsById[r.id] ? {
        ...r,
        ...rowsById[r.id].cells
      } : undefined
    }),
    editable ? { id: ADD_ROW_ID, order: -1 } : undefined
  ))

  return {
    columnData,
    rowData
  }
}


export function isFullWidthCell (rowNode: RowNode): boolean {
  return rowNode.data.id === ADD_ROW_ID
}

interface UseGridContextMenuReturn {
  deleteSelectedCells: (gridApi?: GridApi | null) => boolean;
  getContextMenuItems: (params: GetContextMenuItemsParams) => (string | MenuItemDef)[];
}

export function useGridContextMenu (): UseGridContextMenuReturn {
  const { tableNodeId } = useParameters()
  const { clearSelectionAndFocus } = useGrid()
  const dispatch = useDispatch()

  function deleteSelectedCells (gridApi?: GridApi | null) {
    const cellRange = first(gridApi?.getCellRanges())
    if(some(cellRange?.columns, c => { return c.getColDef()?.cellEditorParams?.type === 'formula'})) {
      return false
    }
    const destRowIDs = []
    const startRowIndex = min([cellRange?.startRow?.rowIndex, cellRange?.endRow?.rowIndex]) ?? 0
    const endRowIndex = max([cellRange?.startRow?.rowIndex, cellRange?.endRow?.rowIndex]) ?? 0
    const destColumnIDs = map(cellRange?.columns, c => {
      return c.getColId()
    })
    for (let index = startRowIndex; index <= endRowIndex; index++) {
      const row = gridApi?.getDisplayedRowAtIndex(index)
      if (row?.id) {
        destRowIDs.push(row?.id)
      }
    }
    dispatch(deleteCells({
      id: tableNodeId,
      params: {
        destColumnIDs,
        destRowIDs
      }
    }))
    return true
  }

  function getContextMenuItems (params: GetContextMenuItemsParams) {
    if (!params.node || !params.column || isFullWidthCell(params.node)) return []
    const cellRange = first(params.api?.getCellRanges())
    
    const isFormulaCellsSelected = some(cellRange?.columns, c => {
      return c.getColDef()?.cellEditorParams?.type === 'formula'
    })

    return [
      {
        name: '在上方插入行',
        action () {
          const rowIndex = params.node.rowIndex as number
          // FIXME: 上方插入一行后，插入行对应列的单元格会被选中，但没有触发「选中」协同api，
          //  因此UI上选中单元格和数据库的数据不匹配。
          //  所以此处先将UI选中的单元格手动清除。
          //  但是手动清除会与「选中列」冲突，清除后，列选中也将被清除。
          clearSelectionAndFocus(params.api)
          const previousRow = params.api?.getDisplayedRowAtIndex(rowIndex - 1)
          dispatch(createRow({
            id: tableNodeId,
            prevRowId: previousRow?.data.id
          }))
        }
      },
      {
        name: '在下方插入行',
        action () {
          const rowIndex = params.node.rowIndex as number
          const previousRow = params.api?.getDisplayedRowAtIndex(rowIndex)
          dispatch(createRow({
            id: tableNodeId,
            prevRowId: previousRow?.data.id
          }))
        }
      },
      {
        name: '复制行',
        action () {
          const rowIndex = params.node.rowIndex as number
          const previousRow = params.api?.getDisplayedRowAtIndex(rowIndex)
          dispatch(createRow({
            id: tableNodeId,
            prevRowId: previousRow?.data.id,
            cells: previousRow?.data
          }))
        }
      },
      {
        name: '清除数据',
        disabled: isFormulaCellsSelected,
        tooltip: isFormulaCellsSelected ? '公式字段不可清空' : undefined,
        action () {
          deleteSelectedCells(params.api)
        }
      },
      // {
      //   name: '复制单元格'
      // },
      'separator',
      {
        name: '删除行',
        cssClasses: ['danger'],
        action () {
          params.api?.applyTransaction({ remove: [params.node.data] })
          // FIXME: 删除一行后，后一行对应列的单元格会被选中，但没有触发「选中」协同api，
          //  因此UI上选中单元格和数据库的数据不匹配。
          //  所以此处先将UI选中的单元格手动清除。
          //  但是手动清除会与「选中列」冲突，清除后，列选中也将被清除。
          clearSelectionAndFocus(params.api)
          dispatch(deleteRows({
            id: tableNodeId,
            rowIDs: [params.node.data.id]
          }))
        }
      }
    ]
  }

  return {
    deleteSelectedCells,
    getContextMenuItems
  }
}

export function useIndexColumnWidth (rowData: unknown[]): { indexColumnWidth: number } {
  const [indexColumnWidth, setColumnWidth] = useState<number>(70)
  useEffect(() => {
    if (rowData && rowData.length) {
      setColumnWidth(60 + (rowData.length - 1).toString().length * 3)
    }
  }, [rowData])

  return {
    indexColumnWidth
  }
}
