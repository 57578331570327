import React from 'react'
import cx from 'clsx'
import { Tooltip } from 'antd'
import { useHistory } from 'react-router-dom'
import { CheckOutlined } from '@ant-design/icons'
import type { Notification } from 'src/api/notification'
import Avatar from 'src/components/common/Avatar'
import { compileNotificationMessage, getRedirectPage } from 'src/utils/notification'
import { formatDateTimeToLocal } from 'src/utils/moment'
import styles from './notification.module.scss'

interface Props {
  item: Notification;
  className?: string;
  onMarkAsReadClick: (id: number) => void
}
export const NotificationItem: React.FC<Props> = ({
  item,
  className,
  onMarkAsReadClick
}) => {
  const history = useHistory()
  const content = compileNotificationMessage(item)

  function handleMarkAsReadClick (e: React.MouseEvent) {
    e.stopPropagation()

    onMarkAsReadClick(item.id)
  }

  function handleItemClick () {
    if (item.page === 'noOp') return

    const pagePath = getRedirectPage(item)

    if (pagePath) {
      onMarkAsReadClick(item.id)
      history.push(pagePath)
    }
  }

  return (
    <div className={styles.wrap} onClick={handleItemClick}>
      <Avatar src={item.payload.senderAvatar} />
      <div
        className={cx(styles.item, { [styles.read]: item.read }, className)} 
      >
        <div
          className={styles.content}
          key={item.id} 
          dangerouslySetInnerHTML={{ __html: content }} 
        />
        <div className={styles.createTime}>{formatDateTimeToLocal(item.createTime)}</div>
      </div>
      {
        !item.read && <Tooltip title="标记已读">
          <div className={styles.action} onClick={handleMarkAsReadClick}>
            <CheckOutlined />
          </div>
        </Tooltip>
      }
    </div>
  )
}

export default NotificationItem
