import React from 'react'
import { Checkbox, Tooltip } from 'antd'
import isNil from 'lodash/isNil'
import filter from 'lodash/filter'
import concat from 'lodash/concat'
import isEmpty from 'lodash/isEmpty'
import includes from 'lodash/includes'
import size from 'lodash/size'
import { CheckboxChangeEvent } from 'antd/es/checkbox'
import cx from 'clsx'
import useSelector from 'src/hooks/common/useSelector'
import { selectCompareList } from 'src/selectors/compareListSelector'
import useDispatch from 'src/hooks/common/useDispatch'
import { actions as mapActions } from 'src/state/map/slice'
import { COMPARE_BUTTON_DIFF_LAYERS_TOOPTIP, COMPARE_BUTTON_MAX_TOOPTIP } from 'src/constants/tooltip'
import { MAXIMUM_COMPARE_COUNT } from 'src/components/common/BatchActions/BatchActions'
import styles from './compareButton.module.scss'

interface Props {
  className?: string;
  layerNodeId: string | null;
  rowId: string;
  tableNodeId: number;
}
const CompareButton: React.FC<Props> = ({
  className,
  layerNodeId,
  rowId,
  tableNodeId
}) => {
  const dispatch = useDispatch()
  const compare = useSelector(state => state.map.compare)
  const compareList = useSelector(state => selectCompareList(state, layerNodeId))
  const isDifferentLayer = !isNil(compare.layerNodeId) && compare.layerNodeId !== layerNodeId
  const isExceed = size(compareList) >= MAXIMUM_COMPARE_COUNT
  const checked = includes(compareList, rowId)
  const disabled = (isDifferentLayer || isExceed) && !checked
  let disabledTooltip = null
  if (isExceed && disabled) {
    disabledTooltip = COMPARE_BUTTON_MAX_TOOPTIP
  }
  if (isDifferentLayer && disabled) {
    disabledTooltip = COMPARE_BUTTON_DIFF_LAYERS_TOOPTIP
  }

  function handleChange (e: CheckboxChangeEvent) {
    const params = {
      layerNodeId,
      tableNodeId,
      list: compareList || []
    }
    
    if (e.target.checked) {
      params.list = concat(params.list, rowId)
    } else {
      params.list = filter(params.list, l => l !== rowId)
    
      if (isEmpty(params.list)) {
        params.layerNodeId = null
      }
    }

    dispatch(mapActions.updateCompare(params))
  }

  return (
    <div className={cx(styles.button, className)}>
      <Tooltip title={disabledTooltip}>
        <Checkbox disabled={disabled} checked={checked} onChange={handleChange}>
          {checked ? '已加入' : '对比'}
        </Checkbox>
      </Tooltip>
    </div>
  )
}

export default CompareButton
