import { useEffect } from 'react'
import compact from 'lodash/compact'
import join from 'lodash/join'
import type { Workspace } from 'src/typings/workspace'

const BASE_TITLE = 'MapTable'

export function useDocumentTitle (...args: Array<string | null | undefined>): void {
  const title = join(compact(args), ' - ')

  useEffect(() => {
    if (title) {
      const formattedTitle = `${title} - ${BASE_TITLE}`
      document.title = formattedTitle
    }
  }, [title])
}

export function getWorkspaceTitle (workspace?: Workspace): string | undefined {
  return workspace?.type === 'user' ? '个人桌面' : workspace?.name
}
