import axios, { AxiosInstance, AxiosRequestConfig } from 'axios'
import { message } from 'antd'
import Cookies from 'js-cookie'
import qs from 'qs'
import merge from 'lodash/merge'
import toNumber from 'lodash/toNumber'
import toString from 'lodash/toString'
import get from 'lodash/get'
import AppConfig from 'src/config'
import { isDev } from 'src/utils/env'
import { IS_AUTH, REFRESH_TOKEN, TOKEN, LAST_REFRESH_TOKEN_TIMESTAMP } from 'src/constants/storage'
import { AuthError, NetworkError } from 'src/utils/errors'
import { DEFAULT_TIMEOUT, DEFAULT_TOKEN_EXPIRES } from 'src/constants/network'

declare module 'axios' {
  export interface AxiosRequestConfig {
    ignoreError?: boolean
  }
}

const isRefreshTokenHandler = (url?: string) => {
  return url === 'token/refresh/'
}

class RefreshToken {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private static instance: any

  static create () {
    if (!this.instance) {
      this.instance = this.fetchToken()
    }

    return this.instance
  }

  private static async fetchToken () {
    return createInstance({
      baseURL: `${AppConfig.baseApiURL}/`
    }).post('token/refresh/', {
      refreshToken: Cookies.get(REFRESH_TOKEN)
    }).then((res) => {
      if (res?.data) {
        Cookies.set(TOKEN, res.data.extra.token, { expires: DEFAULT_TOKEN_EXPIRES })
        Cookies.set(REFRESH_TOKEN, res.data.extra.refreshToken, { expires: DEFAULT_TOKEN_EXPIRES })
        Cookies.set(IS_AUTH, 'true', { expires: DEFAULT_TOKEN_EXPIRES })
      }

      this.instance = null
    }).catch(error => {
      this.instance = null
      return Promise.reject(new AuthError(error))
    })
  }

  static dailyRefresh () {
    if (Cookies.get(IS_AUTH) !== 'true' || !Cookies.get(TOKEN) || !Cookies.get(REFRESH_TOKEN)) {
      return false
    }

    const currentTime = Date.now()
    const lastRefreshTimestamp = toNumber(Cookies.get(LAST_REFRESH_TOKEN_TIMESTAMP) || 0)
    if ((currentTime - lastRefreshTimestamp) > 24 * 60 * 60 * 1000) {
      Cookies.set(LAST_REFRESH_TOKEN_TIMESTAMP, toString(currentTime))
      this.fetchToken()
    }
  }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const handleRefreshToken = (callback: any) => {
  return RefreshToken.create().then(callback)
}

export function createInstance (config: AxiosRequestConfig): AxiosInstance {
  const instance = axios.create(merge({}, {
    baseURL: AppConfig.baseApiURL,
    timeout: DEFAULT_TIMEOUT,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    paramsSerializer: function (params: any) {
      return qs.stringify(params, { arrayFormat: 'repeat' })
    }
  }, config))

  instance.interceptors.request.use((config) => {
    RefreshToken.dailyRefresh()

    config.params = {
      ...config.params,
      ...isDev() && AppConfig.DEV_PARAMS
    }

    config.headers.Authorization = Cookies.get(TOKEN)

    return config
  }, (error) => {
    return Promise.reject(error)
  })

  instance.interceptors.response.use((res) => {
    const config = res?.config
    if (res?.data && config) {
      res.data.__method = config.method
    }

    return res
  }, (error) => {
    if (!axios.isCancel(error)) {
      if (get(error, 'message') === 'Network Error') {
        return Promise.reject(new NetworkError(error))
      } else if (!get(error, 'response.config.ignoreError')) {
        message.error(get(error, 'response.data.message', '请求异常'))
      }
    }

    if (get(error, 'response.status') === 401 || get(error, 'response.data.code') === 401) {
      const config = error?.config
      if (isRefreshTokenHandler(config?.url)) {
        return Promise.reject(new AuthError(error))
      }

      return handleRefreshToken(() => {
        config.headers.Authorization = Cookies.get(TOKEN)
        return instance(config)
      })
    }

    return Promise.reject(error)
  })

  return instance
}


