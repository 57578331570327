import React, { useState } from 'react'
import { Button } from 'antd'
import { Controls, QUMap, Zoom } from 'qumap/packages/react'
import { useMap } from 'src/hooks/map/useMap'
import { useParameters } from 'src/hooks/common/useParameters'
import Icon from 'src/components/common/Icon'
import { MAPOPTIONS } from 'src/constants/map'
import styles from './pickUpMapCenter.module.scss'

// FIXME: use qumap type
type DragEndEventResp = {
  info: {
    center: string
  }
}

export interface Props {
  center?: string
  onCancel?: () => void
  onOk?: (center: string) => void
}

const PickUpMapCenter: React.FC<Props> = (props) => {
  const { onCancel, onOk } = props
  const { projectId } = useParameters()
  const {
    mapOptions,
    quMap,
    handleMapLoad
  } = useMap(projectId)
  const [currCenter, setCenter] = useState(mapOptions?.options?.center?.join(','))

  function handleOk () {
    onOk && onOk(currCenter || '')
  }

  function handleActionEnd (ev: DragEndEventResp) {
    setCenter(ev.info.center)
  }

  return (
    <div className={styles.pickUpMapModal}>
      <div className={styles.mapContainer}>
        <QUMap
          {...(mapOptions ?? MAPOPTIONS)} quMapRef={quMap} onLoad={handleMapLoad} onZoomEnd={handleActionEnd}
          onDragEnd={handleActionEnd}>
          <Controls>
            <Zoom />
          </Controls>
        </QUMap>
        <Icon type={'mapCenter'} className={styles.mapCenterIcon} />
      </div>
      <div className={styles.footer}>
        <Button size="large" onClick={onCancel}>
          {'取消'}
        </Button>
        <Button size="large" type="primary" onClick={handleOk}>
          {'选择'}
        </Button>
      </div>
    </div>
  )
}

export default PickUpMapCenter
