import storage from 'store2'
import type { PayloadAction } from '@reduxjs/toolkit'
import isEmpty from 'lodash/isEmpty'
import type { State, Editor, PoiDetail, Compare } from 'src/state/map/slice'
import { HIDDEN_LAYER_IDS, MAP_FILTERS } from 'src/constants/storage'
import { Filter } from 'src/constants/filter'

/**
 * @ignore
 */
function updateEditor (state: State, action: PayloadAction<Partial<Editor>>): void {
  Object.assign(state.editor, action.payload)
}

/**
 * @ignore
 */
function updateHiddenLayers (
  state: State,
  action: PayloadAction<{ ids: string[], hidden: boolean, projectId: number }>
): void {
  const { ids, hidden, projectId } = action.payload
  if (!state.hiddenLayerIds[projectId]) {
    state.hiddenLayerIds[projectId] = []
  }
  const hiddenLayers = storage.get(HIDDEN_LAYER_IDS, {})
  hiddenLayers[projectId] = [...state.hiddenLayerIds[projectId]]

  ids.forEach(id => {
    const index = hiddenLayers[projectId].findIndex((layerId: string) => layerId === id)

    if (hidden && index === -1) {
      hiddenLayers[projectId].push(id)
    } else if (index !== -1) {
      hiddenLayers[projectId].splice(index, 1)
    }
  })

  state.hiddenLayerIds[projectId] = [...hiddenLayers[projectId]]
  storage.set(HIDDEN_LAYER_IDS, hiddenLayers)
}

/**
 * @ignore
 */
function updatePoiDetail (state: State, action: PayloadAction<Partial<PoiDetail>>): void {
  Object.assign(state.poiDetail, action.payload)
}

/**
 * @ignore
 */
function clearPoiDetail (state: State): void {
  Object.assign(state.poiDetail, {
    visible: false,
    tableNodeId: undefined,
    rowId: undefined,
    id: undefined,
    labelFieldId: undefined
  })
}

/**
 * @ignore
 */
function updateMapFilters (state: State, action: PayloadAction<{ id: string, filters: Filter[] }>): void {
  const { id, filters } = action.payload

  if (isEmpty(filters)) {
    delete state.mapFilters[id]
  } else {
    state.mapFilters[id] = filters
  }

  storage.set(MAP_FILTERS, state.mapFilters)
}

/**
 * @ignore
 */
function updateCompare (state: State, action: PayloadAction<Compare>): void {
  state.compare = action.payload
}

/**
 * @ignore
 */
export const reducers = {
  updateEditor,
  updateHiddenLayers,
  updatePoiDetail,
  clearPoiDetail,
  updateMapFilters,
  updateCompare
}
