import isEmpty from 'lodash/isEmpty'
import {
  ICellRendererComp,
  ICellRendererParams
} from 'ag-grid-community/dist/lib/rendering/cellRenderers/iCellRenderer'
import type { ColumnType, TypeOptions } from 'src/typings/tableNode'

export interface Params extends ICellRendererParams {
  realValue?: unknown;
  typeOptions?: TypeOptions;
  type?: ColumnType;
  name?: string;
  inGrid?: boolean;
  tableNodeId?: string;
  viewId?: string;
  collaborator?: {
    name: string[];
    color: string;
  },
  editable?: boolean
}

export default abstract class CellRenderer implements ICellRendererComp {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  value?: any
  eGui!: HTMLElement
  typeOptions?: TypeOptions
  params?: Params

  abstract init? (p: ICellRendererParams): void;

  abstract getGui (): HTMLElement;

  abstract refresh (): boolean;

  destroy? (): void;

  createEL (): HTMLElement {
    const el = document.createElement('div')
    el.className = 'mt-grid-cell-container'

    return el
  }

  startEditing = (): void => {
    this.params?.api.startEditingCell({ colKey: this.params?.column.getColId(), rowIndex:this.params.rowIndex })
  }

  withCollaborators (el: HTMLElement, collaborator?: { name: string[], color: string }): HTMLElement {
    if (collaborator && !isEmpty(collaborator?.name)) {
      const { name = [], color = 'transparent' } = collaborator
      const collaboratorContainer = document.createElement('div')
      collaboratorContainer.setAttribute('data-collaborator-cell', 'true')
      collaboratorContainer.style.borderColor = color
      collaboratorContainer.className = 'ag-grid-collaborator-cell'
      const collaboratorName = document.createElement('span')
      collaboratorName.style.backgroundColor = color
      collaboratorName.innerText = name.join(',')
      collaboratorContainer.append(collaboratorName)

      const cellContainer = document.createElement('div')
      cellContainer.style.height = '100%'
      cellContainer.append(collaboratorContainer)
      cellContainer.append(el)
      return cellContainer
    } else {
      return el
    }
  }
}
