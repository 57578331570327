import { createAsyncThunk } from '@reduxjs/toolkit'
import isNumber from 'lodash/isNumber'
import WorkspaceAPI from 'src/api/workspaces'

import { actions as projectActions } from 'src/state/project/slice'
import { Workspace } from 'src/typings/workspace'
import type { RootState } from 'src/store'
import * as notificationActions from 'src/state/notification/actions'

export const getWorkspaces = createAsyncThunk(
  'workspace/get',
  async () => {
    return await WorkspaceAPI.get()
  }
)

export const getWorkspaceDetail = createAsyncThunk(
  'workspace/getDetail',
  async (payload: Workspace, thunkAPI) => {
    const { type, id } = payload
    const { project } = thunkAPI.getState() as RootState
    if (project.currentWorkspace?.type !== type || project.currentWorkspace.id !== id) {
      thunkAPI.dispatch(projectActions.setProjects({ workspace: payload, projects: [] }))
    }

    const workspaceDetail = await WorkspaceAPI.getDetail(type, id)

    thunkAPI.dispatch(projectActions.setProjects({
      workspace: payload,
      projects: workspaceDetail.detail.projects || []
    }))

    if (!isNumber(workspaceDetail.detail.unreadNotificationsCount)) {
      thunkAPI.dispatch(notificationActions.getNotifications({
        page: 1,
        mode: 'newestFirst'
      }))
    }

    // FIXME: 后端需要补充返回 workspaceId
    return { ...workspaceDetail, id }
  }
)

export const getWorkspaceRoles = createAsyncThunk(
  'workspace/getWorkspaceRoles',
  async (payload: Workspace) => {
    const { type, id } = payload
    const { detail } = await WorkspaceAPI.getWorkspaceRoles(type, id)

    return detail
  }
)
