import React, { useEffect, useCallback, useState } from 'react'
import { useDialogState } from 'src/hooks/common/useDialog'
import { previewAttachmentEmitter } from 'src/utils/emitter'
import type { FileParams } from 'src/api/tablenodes'
import AttachmentPreview from './AttachmentPreview'


export const AttachmentPreviewGlobal: React.FC = () => {
  const dialog = useDialogState(false)
  const [attachments, setAttachments] = useState<FileParams[]>([])
  const [currentPreviewId, setCurrentPreviewId] = useState<string>()

  const handlePreview = useCallback((args) => {
    setCurrentPreviewId(args.currentPreviewId)
    setAttachments(args.attachments)
    dialog.open()
  }, [dialog])

  useEffect(() => {
    previewAttachmentEmitter.on(handlePreview)

    return () => previewAttachmentEmitter.off(handlePreview)
  }, [handlePreview])

  return (
    <AttachmentPreview 
      dialog={dialog} 
      attachments={attachments} 
      currentPreviewId={currentPreviewId}
      onClose={() => setCurrentPreviewId(undefined)}
    />
  )
}

export default AttachmentPreviewGlobal
