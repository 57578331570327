import React from 'react'
import cx from 'clsx'
import { Layout } from 'antd'
import useSelector from 'src/hooks/common/useSelector'
import styles from './sider.module.scss'

interface Props {
  isFloat?: boolean;
}

const Sider: React.FC<Props> = (props) => {
  const { isFloat } = props
  const siderVisible = useSelector(state => state.project.siderVisible)

  return (
    <div className={cx(styles.wrap, isFloat ? styles.isFloat : '', siderVisible ? '' : styles.collapseSide)}>
      <Layout.Sider className={cx(styles.sider)} width={240}>
        <div className={styles.siderBody}>
          {props.children}
        </div>
      </Layout.Sider>
    </div>
  )
}

export default Sider
