import { useState, useEffect, useCallback, useMemo } from 'react'
import { useHistory } from 'react-router'
import find from 'lodash/find'
import { RoleType } from 'src/constants/permissions'
import { updateTableNode, getMembers, deleteMember, updateMember } from 'src/state/tableNode/actions'
import useDispatch from 'src/hooks/common/useDispatch'
import { useParameters } from 'src/hooks/common/useParameters'
import { AppDispatch } from 'src/store'
import { useWorkspaces } from 'src/hooks/workspace/useWorkspaces'
import { TableNode } from 'src/typings/mapTableNode'
import useSelector from 'src/hooks/common/useSelector'
import { projectSelectors } from 'src/state/project/slice'

export interface Member {
  id: number;
  role: RoleType;
  name?: string;
  phone: string;
  avatar?: string;
  inherited: boolean
}

interface Return {
  tableNode?: TableNode
  currentUser?: Member
  members: Member[]
  isOrgWorkspace: boolean
  fetchMembers: () => void
  roleInherited?: boolean
  changeRoleInherited: (v: boolean) => void
  changeMemberRole: (members: Member[]) => void
  removeMember: (member: Member) => void
  isSameUser: (member: Member) => boolean
}

export function useTableMember (tableNodeId: number): Return {
  const { workspaceId, projectId, workspaceType } = useParameters()
  const { userWorkspaceId: currentUserId } = useWorkspaces('uw')
  const tableNode = useSelector(state =>
    find(projectSelectors.selectById(state, projectId)?.tableNodes, tn => tn.id === tableNodeId))

  const [roleInherited, setRoleInherited] = useState<boolean>()
  const history = useHistory()

  const dispatch: AppDispatch = useDispatch()
  const [members, setMembers] = useState<Member[]>([])

  const isOrgWorkspace = workspaceType === 'w'
  const fetchMembers = useCallback(async () => {
    if (tableNodeId) {
      const { payload } = await dispatch(getMembers(tableNodeId))
      setMembers(payload as Member[])
    }
  }, [dispatch, tableNodeId])

  const currentUser = useMemo(
    () => find(members, member => member.id === currentUserId), [currentUserId, members]
  )
  const isSameUser = (member: Member) => member.id === currentUserId
  useEffect(() => {
    setRoleInherited(tableNode?.roleInherited)
  }, [tableNode?.roleInherited])

  async function changeRoleInherited (roleInherited: boolean) {
    setRoleInherited(roleInherited)
    await dispatch(updateTableNode({
      id: tableNodeId,
      tableNode: {
        projectID: projectId,
        roleInherited
      } as Partial<TableNode>
    }))

    await fetchMembers()
  }

  async function removeMember (member: Member) {
    await dispatch(deleteMember({
      id: tableNodeId,
      memberId: member.id
    }))
    if (isSameUser(member)) {
      history.push(`/${workspaceType}/${workspaceId}`)
    } else {
      await fetchMembers()
    }
  }

  async function changeMemberRole (members: Member[]) {
    await dispatch(updateMember({
      id: tableNodeId,
      members
    }))
    await fetchMembers()
  }

  return {
    isOrgWorkspace,
    tableNode,
    roleInherited,
    changeRoleInherited,
    currentUser,
    members,
    fetchMembers,
    removeMember,
    changeMemberRole,
    isSameUser
  }
}
