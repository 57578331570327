import React, { useEffect } from 'react'
import { Form, Tooltip } from 'antd'
import cx from 'clsx'
import { Cell } from 'src/typings/tableNode'
import useField from 'src/hooks/common/useField'
import { isFormula } from 'src/utils/formula'
import Label from 'src/components/detailPanel/Label'
import FieldRender from 'src/components/detailPanel/FieldRender'
import FieldEditor from 'src/components/detailPanel/FieldEditor'
import styles from './fieldItem.module.scss'

interface Props {
  editable?: boolean;
  drawerId: number;
  item: Cell;
  drawerVisible: boolean;
}
function isFullWidth (item: Cell) {
  return item.type === 'table' || item.type === 'multiLineText'
}
const { Item } = Form

const FieldItem: React.FC<Props> = (props) => {
  const { item, drawerVisible, drawerId, editable } = props
  const tooltip = isFormula(item.type) ? '此列为自动计算，可前往列的配置修改' : '点击修改'
  const { endEdit, startEdit, editing, isCurrentEditCell } = useField(false)
  const isFullWidthItem = isFullWidth(item)
  useEffect(() => {
    if (!drawerVisible) {
      endEdit()
    }
  }, [drawerVisible, endEdit])

  useEffect(() => {
    endEdit()
  }, [drawerId, endEdit])

  useEffect(() => {
    if (!isCurrentEditCell(item.id)) {
      endEdit()
    }
  }, [item, endEdit, isCurrentEditCell])

  const handleFieldClick = () => {
    if(!editable || item.type === 'table' || item.type === 'boolean') {
      return
    }
    if (!isFormula(item.type)) {
      startEdit(item.id)
    }
  }

  return (
    <Item
      wrapperCol={isFullWidthItem ? { span: 24 } : undefined}
      className={cx(styles.item, isFullWidthItem && styles.fullWidthItem)} 
      label={<Label type={item.type} name={item.name} />}
    >
      {!editing &&
      <Tooltip title={tooltip}>
        <FieldRender className={styles.fieldRender} item={item} onClick={handleFieldClick} />
      </Tooltip>
      }
      {editing && <FieldEditor item={item} onEndEdit={endEdit} />}
    </Item>
  )
}

export default FieldItem
