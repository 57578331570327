import React, { useState, useRef, useEffect, useCallback, useLayoutEffect } from 'react'
import dayjs from 'dayjs'
import findIndex from 'lodash/findIndex'
import map from 'lodash/map'
import compact from 'lodash/compact'
import filezie from 'filesize'
import type { CarouselRef } from 'antd/lib/carousel'
import type { FileParams } from 'src/api/tablenodes'
import { checkAbleToPreview, inlinePreview } from 'src/utils/attachment'

interface Column {
  key: string;
  dataIndex: string;
  title: string;
}

interface UseAttachmentTableReturn {
  columns: Column[];
  dataSource: Record<string, unknown>[];
}

export function useAttachmentTable (attachments: FileParams[]): UseAttachmentTableReturn {
  const dataSource = map(compact(attachments), (a, index) => {
    return {
      key: index,
      name: a.fileName,
      size: filezie(a.fileSize),
      createTime: dayjs(a.createTime).format('YYYY/MM/DD HH:mm'),
      id: a.fileID,
      url: a.url
    }
  })

  const columns = [
    {
      title: '文件名',
      dataIndex: 'name',
      key: 'name'
    },
    {
      title: '文件大小',
      dataIndex: 'size',
      key: 'size'
    },
    {
      title: '上传时间',
      dataIndex: 'createTime',
      key: 'createTime'
    }
  ]

  return {
    columns,
    dataSource
  }
}

interface UseAttachmentPreviewReturn {
  carouselWrap: React.RefObject<HTMLDivElement>;
  carousel: React.RefObject<CarouselRef>;
  currentAttachment?: FileParams;
  index: number;
  setIndex: (index: number) => void
}

export function useAttachmentPreview (
  attachments: FileParams[],
  previewSelector: string,
  currentPreviewId?: string
): UseAttachmentPreviewReturn {
  const [index, setIndex] = useState(-1)
  const carousel = useRef<CarouselRef>(null)
  const carouselWrap = useRef<HTMLDivElement>(null)
  const viewer = useRef<Viewer>()

  const setPreviewer = useCallback((attachment: FileParams, index: number) => {
    destroyViewer()
    setIndex(index)

    if (attachment && checkAbleToPreview(attachment.fileExt) && carouselWrap.current) {
      inlinePreview(index, previewSelector, carouselWrap.current).then(v => {
        viewer.current = v
      })
    }
  }, [previewSelector])

  function destroyViewer () {
    if (viewer.current) {
      viewer.current?.destroy()
    }
  }

  useEffect(() => {
    const index = findIndex(attachments, a => a.fileID === currentPreviewId)
    const attachment = attachments[index]

    if (attachment) {
      setIndex(index)
    }
  }, [currentPreviewId, attachments])

  useLayoutEffect(() => {
    const attachment = attachments[index]

    if (attachment && carousel.current) {
      setPreviewer(attachment, index)
      carousel.current.goTo(index)
    }
  }, [attachments, setPreviewer, index])

  return {
    carouselWrap,
    carousel,
    currentAttachment: attachments[index],
    index,
    setIndex
  }
}
