import AppConfig from 'src/config'
import { createInstance } from 'src/utils/fetch'

import { Workspace, WorkspaceDetail, WorkspaceRole } from 'src/typings/workspace'

import { APIResponse } from 'src/typings/common'

const fetch = createInstance({
  baseURL: `${AppConfig.baseApiURL}/workspaces/`
})

const WorkspaceAPI = {
  async get (): Promise<Workspace[]> {
    const { data } = await fetch.get('')

    return data.detail
  },
  async getDetail (type: string, id: number): Promise<WorkspaceDetail> {
    const { data } = await fetch.get(`${type}/${id}/`)

    return data.detail
  },
  async getWorkspaceRoles (type: WorkspaceDetail['type'], id: number): Promise<APIResponse<WorkspaceRole[]>> {
    const { data } = await fetch.get(`${type}/${id}/roles/`)

    return data
  }
}

export default WorkspaceAPI
