import { RouteProps } from 'react-router-dom'
import Home from 'src/pages/Home'
import Login from 'src/pages/Login'
import Workspace from 'src/pages/Workspace'
import User from 'src/pages/User'
import NotFound from 'src/pages/NotFound'
import Project from 'src/pages/Project'
import Signup from 'src/pages/Signup'
import Setup from 'src/pages/Setup'
import Notification from 'src/pages/Notification'

export interface CustomRouteProps extends RouteProps {
  meta?: {
    noAuth?: boolean
  }
}

const routes = [
  {
    path: '/',
    component: Home,
    exact: true,
    meta: {
      noAuth: true
    }
  },
  {
    path: '/user/',
    exact: true,
    component: User
  },
  {
    path: '/:workspaceType(uw|w)/:workspaceId/p/:projectId',
    component: Project
  },
  {
    path: '/:workspaceType(uw|w)/:workspaceId?',
    component: Workspace
  },
  {
    path: '/notification',
    exact: true,
    component: Notification
  },
  {
    path: '/login',
    component: Login,
    meta: {
      noAuth: true
    }
  },
  {
    path: '/signup',
    component: Signup,
    meta: {
      noAuth: true
    }
  },
  {
    path: '/setup',
    component: Setup
  },
  {
    path: '*',
    component: NotFound,
    meta: {
      noAuth: true
    }
  }
] as CustomRouteProps[]

export default routes
