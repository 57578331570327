import React, { useRef } from 'react'
import cx from 'clsx'
import { Button } from 'antd'
import truncate from 'lodash/truncate'
import Icon from 'src/components/common/Icon'
import styles from './multiLineText.module.scss'

interface Props {
  value?: string;
  inGrid?: boolean;
  truncateLength?: number;
  isPlain?: boolean;
}

export const MultiLineTextRenderer: React.FC<Props> = (props) => {
  const { inGrid } = props
  const value = props.value
  const spanRef = useRef<HTMLSpanElement>(null)

  function handleSwitchExpand (e: React.MouseEvent<HTMLElement>) {
    const expandElement = e.target as HTMLElement
    const multilineElement = expandElement.closest('[data-multiline="MultiLineText"]')
    const isExpanded = multilineElement?.classList.contains(styles.expand)
    isExpanded ? multilineElement?.classList.remove(styles.expand) : multilineElement?.classList.add(styles.expand)
    e?.stopPropagation()
  }

  function checkShowExpanded () {
    if (props.value && !props.inGrid) {
      setTimeout(() => {
        const showExpanded = spanRef.current && spanRef.current.scrollHeight > 40
        if (spanRef.current) {
          const multilineElement = spanRef.current.closest('[data-multiline="MultiLineText"]')
          if (showExpanded) {
            multilineElement?.classList.add(styles.showExpanded)
          } else {
            multilineElement?.classList.remove(styles.showExpanded)
          }
        }
      }, 0)
    }
  }

  checkShowExpanded()

  return (
    <div data-multiline={'MultiLineText'} className={cx(styles.renderer, { [styles.expand]: props.isPlain })}>
      <span className={cx(styles.text, { [styles.noContent]: !value && !inGrid })} ref={spanRef}>
        {props.truncateLength ? truncate(value, { length: props.truncateLength }) : value}
        {!value && !inGrid ? '未填写' : ''}
      </span>
      {
        (props?.inGrid || props.isPlain) ?
          <div
            data-multiline={'openModal'}
            className={cx(styles.button, styles.rendererButton)}
          >
            <Icon type="expandArrow" />
          </div>
          :
          <Button className={styles.expandButton} type="link" size="small" onClick={handleSwitchExpand}>
            <span className={styles.collapseBtnText}>收起</span> <span className={styles.expandBtnText}>展开</span>
          </Button>
      }
    </div>
  )
}
