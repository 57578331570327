import React from 'react'
import { Skeleton } from 'antd'
import random from 'lodash/random'
import cx from 'clsx'
import styles from './table.module.scss'

function generateWidths (num: number){
  return [...new Array(num)].map(_ => (random(100, 150, false)))
}
const ROWS = 10
const WIDTHS = generateWidths(ROWS)
const TableSkelton: React.FC = () => {
  return (
    <div className={styles.skeltonWrapper}>
      {[...new Array(4)].map((_, index) => 
        <div className={styles.col} key={index}>
          <Skeleton
            title={false} paragraph={{ rows: 1, width: 120 }} 
            active className={cx(styles.skeleton, styles.skeletonHeader)} />
          <Skeleton
            title={false} paragraph={{ rows: ROWS, width: WIDTHS }} 
            active className={styles.skeleton} />
        </div>
      )
      }
    </div>
  )
}

export default TableSkelton
