import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { Switch, Route, useRouteMatch, Redirect, useHistory, useLocation } from 'react-router-dom'
import flowRight from 'lodash/flowRight'
import findIndex from 'lodash/findIndex'
import includes from 'lodash/includes'
import { Layout } from 'antd'
import Projects from 'src/components/workspace/Projects'
import TeamSetting from 'src/components/workspace/TeamSetting'
import RecycleBin from 'src/components/workspace/RecycleBin'
import WorkspaceHeader from 'src/components/workspace/WorkspaceHeader'
import useSelector from 'src/hooks/common/useSelector'
import { useWorkspaces, useWorkspacesEffect } from 'src/hooks/workspace/useWorkspaces'
import { useParameters } from 'src/hooks/common/useParameters'
import { useProject } from 'src/hooks/project/useProject'
import withWebSocket from 'src/hoc/withWebSocket'
import withLayout from 'src/hoc/withLayout'
import WorkspaceSider from 'src/components/workspace/Sider'
import WorkspaceMembers from 'src/pages/WorkspaceMembers'
import WorkspaceInvitations from 'src/pages/WorkspaceInvitations'
import { getWorkspaceDetail } from 'src/state/workspace/actions'
import { AppDispatch } from 'src/store'
import styles from './workspace.module.scss'

const { Content } = Layout

// TODO: figure out better way to show active menu
const activeMenus = ['settings', 'recycle', 'members', 'invitations', 'projects']

function getActiveMenu (pathname: string) {
  const index = findIndex(activeMenus, item => includes(pathname, item))
  if (index !== -1) return activeMenus[index]

  return 'projects'
}

const WorkspacePage: React.FC = () => {
  const dispatch: AppDispatch = useDispatch()
  const { workspaceId, workspaceType } = useParameters()
  const { workspace } = useWorkspaces(workspaceType, workspaceId)
  const { projects, currentWorkspace } = useProject(workspace)
  const { pathname } = useLocation()
  const history = useHistory()
  const { path } = useRouteMatch()
  const [activeMenu, setActiveMenu] = useState(getActiveMenu(pathname))
  const username = useSelector(state => state.user.username)

  const workspaceRoleChanged = useSelector(state => state.workspace.workspaceRoleChanged)

  useEffect(() => {
    if (workspace && workspaceRoleChanged) {
      dispatch(getWorkspaceDetail(workspace))
    }
  }, [dispatch, workspace, workspaceRoleChanged])

  useWorkspacesEffect()

  function handleGoHomeClick () {
    if (workspaceType === 'w') {
      history.push(`/${workspaceType}/${workspaceId}`)
    } else {
      history.push('/uw')
    }
    setActiveMenu('projects')
  }

  return (
    <Layout className={styles.layout}>
      <WorkspaceSider activeMenu={activeMenu} onMenuChange={(menu) => setActiveMenu(menu)} />
      <Layout className={styles.main}>
        <Layout.Header>
          <WorkspaceHeader username={username} onGoHome={handleGoHomeClick} />
        </Layout.Header>
        <Content className={styles.content}>
          <Switch>
            <Route path={`${path}/recycle`} exact>
              <RecycleBin type={workspace?.type} recycleProjects={currentWorkspace?.detail.recycleProjects} />
            </Route>
            <Route path={`${path}/settings`} exact>
              <TeamSetting workspace={workspace} />
            </Route>
            <Route path={`${path}/members`} exact>
              <WorkspaceMembers workspace={workspace} />
            </Route>
            <Route path={`${path}/invitations`} exact>
              <WorkspaceInvitations />
            </Route>
            <Route path={path}>
              <Projects projects={projects} workspaceType={workspace?.type} />
            </Route>
            <Redirect to={path} />
          </Switch>
        </Content>
      </Layout>
    </Layout>
  )
}

export default flowRight(
  withLayout('full'),
  withWebSocket
)(WorkspacePage)
