import { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import forEach from 'lodash/forEach'
import useSelector from 'src/hooks/common/useSelector'
import { tableNodeSelectors } from 'src/state/tableNode/slice'
import { useParameters } from 'src/hooks/common/useParameters'
import { Column, View, ViewColumn, OrderType } from 'src/typings/tableNode'
import { updateView } from 'src/state/tableNode/actions'
import { ADD_ROW_ID } from 'src/constants/grid'

import { AppDispatch } from 'src/store'

interface Return {
  view?: View
  columns?: Column[]
  viewColumns?: ViewColumn[]
  orders: Record<string, { order: string, orderIndex: number }>
  hasOrder: boolean
  onSortingChanged: (orders: OrderType[]) => void
}

export default function useSorting (): Return {
  const { tableNodeId } = useParameters()
  const dispatch: AppDispatch = useDispatch()
  const [view, columns, viewColumns, orders, hasOrder] = useSelector(state => {
    const tableNode = tableNodeSelectors.selectById(state, tableNodeId)
    const orders: Record<string, { order: string, orderIndex: number }> = {
      [ADD_ROW_ID]: {
        order: 'asc',
        orderIndex: 0
      }
    }
    let hasOrder = false
    forEach(tableNode?.views[0]?.orders, (order, idx) => {
      hasOrder = true
      orders[order.columnID] = {
        order: order.order,
        orderIndex: idx + 1
      }
    })

    return [
      // TODO: use default view temporarily
      tableNode?.views[0],
      tableNode?.columns,
      tableNode?.views[0].columns,
      orders,
      hasOrder
    ]
  })

  const onSortingChanged = useCallback((orders: OrderType[]) => {
    dispatch(updateView({
      id: tableNodeId,
      viewId: view?.id as string,
      params: {
        filterCondition: null,
        filters: null,
        groups: null,
        name: view?.name,
        orders
      }
    }))
  }, [view?.id, view?.name, tableNodeId, dispatch])

  return {
    view,
    columns,
    viewColumns,
    orders,
    hasOrder,
    onSortingChanged
  }
}
