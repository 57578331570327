import React, { useState, forwardRef, useEffect, useImperativeHandle } from 'react'
import cx from 'clsx'
import map from 'lodash/map'
import isEqual from 'lodash/isEqual'
import debounce from 'lodash/debounce'
import { Form, Select, Button, Row, Col, message } from 'antd'
import { useParameters } from 'src/hooks/common/useParameters'
import { useTableNodesForLayerCreate } from 'src/hooks/common/useTableNode'
import { LabelSetting } from 'src/components/map/LayerSettingPanel/LabelSetting'
import FillSetting from 'src/components/map/LayerSettingPanel/FillSetting'
import OutlineSetting from 'src/components/map/LayerSettingPanel/OutlineSetting'
import RadiusSetting from 'src/components/map/LayerSettingPanel/RadiusSetting'
import { getCSSVariable } from 'src/utils/dom'
import { useLayerSetting } from 'src/hooks/map/useLayerSetting'
import FieldTypeSelector from 'src/components/common/FieldTypeSelector'
import Icon from 'src/components/common/Icon'
import type { MapInfo } from 'src/typings/mapTableNode'
import styles from './layerSettingPanel.module.scss'

interface Props {
  visible: boolean;
  siderVisible: boolean;
  layerId: string;
  onClose: () => void;
  onChange: (cancelChange?: boolean) => void;
}

const { Option } = Select

function generateInitialValues (mapInfo: MapInfo | undefined) {
  return {
    tableNodeID: mapInfo?.tableNodeID,
    coordinateFieldID: mapInfo?.coordinateFieldID,
    labelFieldID: mapInfo?.labelFieldID,
    'labelConfig.fillColor': mapInfo?.labelConfig.fillColor || getCSSVariable('--primary-color'),
    'labelConfig.fontSize': mapInfo?.labelConfig.fontSize ?? 13,
    'labelConfig.direction': mapInfo?.labelConfig.direction || 'top',
    'labelConfig.visible': mapInfo?.labelConfig.visible,
    'fillConfig.colors': mapInfo?.fillConfig.colors,
    'fillConfig.fillOpacity': mapInfo?.fillConfig.fillOpacity ?? 0,
    'fillConfig.fillMethod': mapInfo?.fillConfig.fillMethod ?? 'quantize',
    'fillConfig.reverse': mapInfo?.fillConfig.reverse ?? false,
    fillFieldID: mapInfo?.fillFieldID,
    'outlineConfig.strokeColor': mapInfo?.outlineConfig.strokeColor || getCSSVariable('--primary-color'),
    'outlineConfig.strokeWeight': mapInfo?.outlineConfig.strokeWeight ?? 1,
    'outlineConfig.strokeOpacity': mapInfo?.outlineConfig.strokeOpacity ?? 0,
    'radiusConfig.radius': mapInfo?.radiusConfig.radius ?? 60,
    'radiusConfig.radiusRange': mapInfo?.radiusConfig.radiusRange ?? [0, 60],
    radiusFieldID: mapInfo?.radiusFieldID
  }
}

export default forwardRef(({
  visible,
  siderVisible,
  onClose,
  layerId,
  onChange
}: Props, ref) => {
  const { projectId } = useParameters()
  const [form] = Form.useForm()
  const {
    tableNodes,
    coordinateOptions,
    labelOptions,
    setSelectedTableId
  } = useTableNodesForLayerCreate(projectId)

  const [changed, setChanged] = useState(false)
  const { layerNode, updateSetting, cancelPreviewSetting, getPreviewMapInfo } = useLayerSetting(layerId)
  const mapInfo = getPreviewMapInfo() || layerNode?.mapInfo
  const initialValues = generateInitialValues(mapInfo)

  useImperativeHandle(ref, () => {
    return {
      handleIgnoreChanges,
      handleFormSubmit
    }
  })

  useEffect(() => {
    if (!mapInfo) return

    if (mapInfo.tableNodeID) {
      setSelectedTableId(mapInfo.tableNodeID)
    }

    return () => form.resetFields()
  }, [mapInfo, form, setSelectedTableId])

  async function handleTableSelect (tableId: number) {
    setSelectedTableId(tableId)
    await form.setFieldsValue({
      coordinateFieldID: undefined,
      labelFieldID: undefined,
      fillFieldID: undefined,
      radiusFieldID: undefined
    })
    await handleValuesChange()
  }

  async function handleFormSubmit () {
    await form.validateFields()
    const newFieldsValue = form.getFieldsValue()
    updateSetting(layerId, newFieldsValue)
    message.success('保存成功')
    setChanged(false)
    onChange?.(true)
  }

  async function handleValuesChange () {
    const newFieldsValue = form.getFieldsValue()
    if (!isEqual(getPreviewMapInfo() || initialValues, newFieldsValue)) {
      setChanged(true)
      updateSetting(layerId, newFieldsValue, true)
      onChange?.()
    }
  }

  async function handleIgnoreChanges () {
    await form.setFieldsValue(initialValues)
    await cancelPreviewSetting(layerId)
    onChange?.(true)
  }

  async function handleCancel () {
    await handleIgnoreChanges()
    onClose?.()
  }

  return (
    <div
      className={cx(styles.wrap, { [styles.visible]: visible, [styles.hide]: !siderVisible })}
    >
      <div className={styles.header}>
        <h3 className={styles.title}>图层设置</h3>
        <Icon type="close" className={styles.closeIcon} onClick={onClose} />
      </div>
      <Form
        className={styles.form}
        name="layerSetting"
        layout="vertical"
        labelAlign="left"
        requiredMark={false}
        form={form}
        initialValues={initialValues}
        onFinish={handleFormSubmit}
        onValuesChange={debounce(handleValuesChange, 200)}
      >
        <div className={styles.body}>
          <Form.Item
            name="tableNodeID"
            label="数据来源"
            tooltip="选择一张数据表作为地图数据来源"
            rules={[
              {
                required: true,
                message: '请选择数据来源'
              }
            ]}
          >
            <Select placeholder="请选择数据表" onSelect={handleTableSelect}>
              {
                map(tableNodes, t => (
                  <Option key={t.id} value={t.id}>{t.name}</Option>
                ))
              }
            </Select>
          </Form.Item>
          <Form.Item
            name="coordinateFieldID"
            label="POI 坐标"
            tooltip="选择一个字段作为 POI 坐标"
            rules={[
              {
                required: true,
                message: '请选择 POI 坐标'
              }
            ]}
          >
            <FieldTypeSelector
              placeholder="请选择字段"
              onChange={handleValuesChange}
              options={coordinateOptions}
            />
          </Form.Item>
          <Form.Item
            name="labelFieldID"
            label="POI 标签"
            tooltip="选中一个字段作为 POI 标签"
            rules={[
              {
                required: true,
                message: '请选择 POI 标签'
              }
            ]}
          >
            <FieldTypeSelector
              placeholder="请选择字段"
              onChange={handleValuesChange}
              options={labelOptions}
            />
          </Form.Item>
          <LabelSetting collapse form={form} onChange={handleValuesChange} />
          <FillSetting collapse form={form} onChange={handleValuesChange} />
          <OutlineSetting collapse form={form} onChange={handleValuesChange} />
          <RadiusSetting collapse form={form} onChange={handleValuesChange} />
        </div>
        <div className={styles.footer}>
          <Row gutter={16}>
            <Col span={12}>
              <Button block type="primary" htmlType="submit" disabled={!changed}>保存</Button>
            </Col>
            <Col span={12}>
              <Button block onClick={handleCancel}>取消</Button>
            </Col>
          </Row>
        </div>
      </Form>
    </div>
  )
})
