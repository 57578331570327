import React from 'react'
import storage from 'store2'
import { Checkbox, message, Popover } from 'antd'
import { CheckboxChangeEvent } from 'antd/es/checkbox'
import isEqual from 'lodash/isEqual'
import { GridApi } from 'ag-grid-community'
import GridFieldConfig, { Field } from 'src/components/grid/GridFieldConfig/GridFieldConfig'
import { useConfirmDialog, DialogStateReturn as DialogInterface } from 'src/hooks/common/useDialog'
import BorderedText from 'src/components/common/BorderedText'
import Icon from 'src/components/common/Icon'
import { HIDDEN_CHANGE_FIELD_TYPE_CONFIRM_MODAL } from 'src/constants/storage'
import { FIELD_TYPES } from 'src/constants/fieldType'
import type { ColumnType } from 'src/typings/tableNode'
import styles from './changeFieldType.module.scss'

export interface Props {
  colId?: string;
  fieldConfig: Field;
  dialog: DialogInterface;
  gridApi: GridApi;
  onColumnUpdate: (columnId: string, field: Partial<Field>) => void;
  enableFieldTypes?: ColumnType[];
  ignoreChangeConfirm?: boolean;
}

const ChangeFieldType: React.FC<Props> = (props) => {
  const { 
    colId, 
    fieldConfig, 
    dialog, 
    gridApi, 
    onColumnUpdate,
    enableFieldTypes,
    ignoreChangeConfirm
  } = props
  const confirmContent = <div className={styles.confirmContent}>
    <div>转换为此字段类型可能会清除表中每个记录的某些单元格数据。如果转换导致问题，您可以按 <BorderedText>ctrl</BorderedText>
      <BorderedText>Z</BorderedText> 或者 <Icon type={'undo'} />
      撤销
    </div>
    <Checkbox onChange={handleChangeDisplayConfirmModal}>不再提示此消息</Checkbox>
  </div>
  const confirmDialog = useConfirmDialog({
    className: styles.updateFieldConfirmModal,
    title: '您确定要转换为此字段类型吗？',
    okText: '转换',
    content: confirmContent,
    closable: false
  })

  function handleChangeDisplayConfirmModal (val: CheckboxChangeEvent) {
    storage.set(HIDDEN_CHANGE_FIELD_TYPE_CONFIRM_MODAL, val.target.checked)
  }

  function updateFieldType (field: Field) {
    if (!field.name) {
      return message.error('请输入字段名称')
    }

    if (!field.type) {
      return message.error('请选择字段类型')
    }

    if (colId) {
      onColumnUpdate(colId, {
        name: field.name,
        type: field.type === fieldConfig.type ? undefined : field.type,
        typeOptions: field.typeOptions
      })
    }
  }

  function handleSubmit (field: Field) {
    const isChanged = !isEqual(field, fieldConfig)
    const needConfirm = field.type !== fieldConfig.type && FIELD_TYPES[field.type].needConfirm
    if (isChanged && needConfirm && !ignoreChangeConfirm) {
      if (storage.get(HIDDEN_CHANGE_FIELD_TYPE_CONFIRM_MODAL)) {
        updateFieldType(field)
      } else {
        confirmDialog().then(async () => {
          updateFieldType(field)
        })
      }
    } else {
      updateFieldType(field)
    }

    dialog.close()
  }

  return (
    <Popover
      placement="bottom"
      content={
        <GridFieldConfig
          colId={colId}
          fieldConfig={fieldConfig}
          gridApi={gridApi}
          onSubmit={handleSubmit}
          onCancel={dialog.close}
          enableFieldTypes={enableFieldTypes}
        />
      }
      trigger="click"
      visible={dialog.visible}
      onVisibleChange={visible => visible ? dialog.open() : dialog.close()}
    />
  )
}

export default ChangeFieldType
