import React, { useCallback, useState, useEffect } from 'react'
import { arrayMove, List as MovableList } from 'react-movable'
import filter from 'lodash/filter'
import map from 'lodash/map'
import compact from 'lodash/compact'
import find from 'lodash/find'
import isEqual from 'lodash/isEqual'
import forEach from 'lodash/forEach'
import { MAX_SORTING_COUNT } from 'src/constants/sorting'
import ActionContainer from 'src/components/project/ActionContainer'
import SortingItem from 'src/components/project/SortingItem'
import useSorting from 'src/hooks/project/useSorting'
import { OrderType, SortingType } from 'src/typings/tableNode'
import styles from './sorting.module.scss'

interface SortingProps {
  onChange?: (filters: SortingType[]) => void;
}

const Sorting: React.FC<SortingProps> = () => {
  const { view, columns, onSortingChanged } = useSorting()
  const [sortingItems, setSortingItems] = useState<SortingType[]>([])
  const [savedItems, setSavedItems] = useState<OrderType[]>([])
  const [selectedFieldIds, setSelectedFieldIds] = useState<Record<string, boolean>>({})

  const handleViewChanged = useCallback((items: OrderType[]) => {
    if (!isEqual(items, savedItems)) {
      onSortingChanged(items)
      setSavedItems(items)
    }
  }, [onSortingChanged, savedItems])

  useEffect(() => {
    const items: SortingType[] = compact(map(view?.orders, (order, idx) => {
      const col = find(columns, column => order.columnID === column.id)
      if (col) {
        return {
          id: idx,
          field: {
            id: order.columnID,
            type: col.type,
            name: col.name
          },
          sorting: order.order as 'asc' | 'desc'
        }
      }
    }))

    setSavedItems(view?.orders || [])
    setSortingItems(items)
  }, [view, columns])

  useEffect(() => {
    const selectedIds: Record<string, boolean> = {}
    const items: OrderType[] = []
    forEach(sortingItems, item => {
      if (item.field?.id) {
        selectedIds[item.field.id] = true
        items.push({
          columnID: item.field.id,
          order: item.sorting
        })
      }
    })

    setSelectedFieldIds(selectedIds)
    handleViewChanged(items)
  }, [sortingItems, handleViewChanged])

  const handleAddSorting = () => {
    const items = [...sortingItems]
    const count = items.length
    if (count < MAX_SORTING_COUNT) {
      items.push({
        id: Date.now(),
        sorting: 'asc'
      })

      setSortingItems(items)
    }
  }

  const handleClearSorting = () => {
    setSortingItems([])
  }

  const handleDragEnd = (meta: { oldIndex: number, newIndex: number }) => {
    const { oldIndex, newIndex } = meta
    setSortingItems(arrayMove(sortingItems, oldIndex, newIndex))
  }

  const handleRemoveSorting = useCallback((item: SortingType) => {
    setSortingItems([...filter(sortingItems, o => o.id !== item.id)])
  }, [sortingItems])

  const handleChangeSorting = (item: SortingType) => {
    setSortingItems(map(sortingItems, (sort: SortingType) => {
      if (sort.id === item.id) {
        return item
      } else {
        return sort
      }
    }))
  }

  return (
    <>
      <ActionContainer
        name={'排序'}
        type={'sorting'}
        count={sortingItems.length}
        maxCount={MAX_SORTING_COUNT}
        onAdd={handleAddSorting}
        onClear={handleClearSorting}
      >
        <MovableList
          lockVertically
          values={sortingItems}
          onChange={handleDragEnd}
          renderList={({ children, props }) => <ul {...props}>{children}</ul>}
          renderItem={({ value, props }) => <li {...props} className={styles.movableItem}>
            <SortingItem
              sorting={value}
              key={value.id}
              selectedFieldIds={selectedFieldIds}
              onRemove={handleRemoveSorting}
              onChange={handleChangeSorting}
            />
          </li>}
        />
      </ActionContainer>
    </>
  )
}

export default Sorting
