import React from 'react'
import { FIELD_TYPES } from 'src/constants/fieldType'
import Icon, { IconType } from 'src/components/common/Icon'
import styles from './label.module.scss'

export interface LabelProps {
  type?: string;
  name?: string;
}

const Label: React.FC<LabelProps> = (props) => {
  const { type, name } = props
  return (
    <div className={styles.label}>
      {type && <Icon type={FIELD_TYPES[type].icon as IconType} />} <span>{name}</span>
    </div>
  )
}

export default Label
