import React, { useEffect, useState } from 'react'
import { Form, Select, Radio, Row, Col, Popover } from 'antd'
import { useDispatch } from 'react-redux'
import map from 'lodash/map'
import merge from 'lodash/merge'
import range from 'lodash/range'
import Dialog from 'src/components/common/Dialog'
import { DialogStateReturn as DialogInterface } from 'src/hooks/common/useDialog'
import { useParameters } from 'src/hooks/common/useParameters'
import useSelector from 'src/hooks/common/useSelector'
import amapStandard from 'src/assets/images/amap-standard.jpg'
import amapLight from 'src/assets/images/amap-light.jpg'
import amapDark from 'src/assets/images/amap-dark.jpg'
import amapSatellite from 'src/assets/images/amap-satellite.jpg'
import googleSatellite from 'src/assets/images/google-satellite.jpg'
import googlePureSatellite from 'src/assets/images/google-pure-satellite.jpg'
import * as ProjectActions from 'src/state/project/actions'
import { BaseMap, Project } from 'src/typings/project'
import { projectSelectors } from 'src/state/project/slice'
import { AppDispatch } from 'src/store'
import Icon from 'src/components/common/Icon'
import PickUpMapCenter from 'src/components/map/PickUpMapCenter'

import styles from './mapSettingModal.module.scss'

const { Option } = Select

interface Props {
  dialog: DialogInterface;
  project?: Project
}

type TileLayer = {
  icon: string;
  label: string;
}

// TODO: refactor
// 使用 qu-amap 中提供的底图设置
const BASE_MAP_STYLES: Record<keyof typeof BaseMap, TileLayer> = {
  AmapStandard: {
    icon: amapStandard,
    label: '高德标准图'
  },
  AmapLight: {
    icon: amapLight,
    label: '高德浅色地图'
  },
  AmapDark: {
    icon: amapDark,
    label: '高德深色地图'
  },
  AmapSatellite: {
    icon: amapSatellite,
    label: '高德卫星图'
  },
  GoogleSatellite: {
    icon: googleSatellite,
    label: '谷歌卫星图'
  },
  GooglePureSatellite: {
    icon: googlePureSatellite,
    label: '谷歌卫星图 (纯净版)'
  }
}

const zooms = range(2, 21)

const MapSettingModal: React.FC<Props> = ({
  dialog,
  project
}) => {
  const dispatch: AppDispatch = useDispatch()
  const { projectId } = useParameters()
  const [form] = Form.useForm()
  const [mapVisible, setMapVisible] = useState(false)
  const mapConfig = useSelector(state => {
    return projectSelectors.selectById(state, projectId)?.mapConfig
  })
  const [mapCenter, setCenter] = useState('')

  const initialValues = merge({}, {
    zoomLevel: 11,
    positioning: true,
    ranging: true,
    baseMap: BaseMap.AmapStandard
  }, mapConfig)

  useEffect(() => {
    if (dialog.visible) {
      form.resetFields()
    }
  }, [dialog, form])

  useEffect(() => {
    if (mapConfig) {
      setCenter(mapConfig.center)
    }
  }, [mapConfig])

  function handleSetMapCenter () {
    setMapVisible(true)
  }

  function handleMapCenterChanged (center: string) {
    form.setFieldsValue({
      center: center
    })
    setCenter(center)
    setMapVisible(false)
  }

  async function handleSubmitClick () {
    setMapVisible(false)
    await form.validateFields()

    const fieldsValue = form.getFieldsValue()

    dispatch(ProjectActions.editProject({
      projectId,
      projectName: project?.name as string,
      mapConfig: fieldsValue
    }))

    dialog.close()
  }

  return (
    <Dialog
      width="400px"
      okText="保存"
      title="地图设置"
      visible={dialog.visible}
      onCancel={() => {
        setMapVisible(false)
        setTimeout(() => {
          dialog.close()
        })
      }}
      onOk={handleSubmitClick}
    >
      <div className={styles.summary}>
        欢迎访问项目地图，您可以将整理好的数据导入地图，查看数据空间分布以及自定义可视化效果。 <br /><br />
        您可以在下方对地图进行基本配置。
      </div>
      <Form
        colon={false}
        labelAlign="left"
        labelCol={{ span: 8 }}
        requiredMark={false}
        form={form}
        initialValues={initialValues}
        className={styles.settingForm}
      >
        <Form.Item
          name="center"
          label="地图中心点"
          rules={[
            {
              required: true,
              message: '请选择地图中心点'
            }
          ]}
        >
          <div className={styles.mapCenter}>
            {mapCenter}
            <Popover
              overlayClassName={styles.mapPopOver}
              placement="bottomRight"
              content={<PickUpMapCenter onOk={handleMapCenterChanged} onCancel={() => setMapVisible(false)} />}
              trigger="click"
              visible={mapVisible}
            >
              <span className={styles.selectMapWrapper}>
                <span className={styles.selectMap} onClick={handleSetMapCenter}>
                  <Icon type={'mapPin'} />
                  选择
                </span>
              </span>
            </Popover>
          </div>
        </Form.Item>
        <Form.Item
          name="zoomLevel"
          label="默认缩放等级"
        >
          <Select>
            {
              map(zooms, zoom => <Option key={zoom} value={zoom}>{zoom}</Option>)
            }
          </Select>
        </Form.Item>
        <Form.Item
          name="baseMap"
          label="默认底图样式"
          labelCol={{ span: 24 }}
        >
          <Radio.Group className={styles.basemap}>
            <Row>
              {
                map(BaseMap, m => (
                  <Col key={m} span={8} className={styles.basemapItem}>
                    <Radio value={m}>
                      <div className={styles.basemapRadio}>
                        <div className={styles.basemapIcon}>
                          <img src={BASE_MAP_STYLES[m].icon} alt={m} />
                        </div>
                        <div>{BASE_MAP_STYLES[m].label}</div>
                      </div>
                    </Radio>
                  </Col>
                ))
              }
            </Row>
          </Radio.Group>
        </Form.Item>
        {/* <Form.Item
         name="positioning"
         label="定位功能"
         >
         <Radio.Group>
         <Radio value={true}>打开</Radio>
         <Radio value={false}>关闭</Radio>
         </Radio.Group>
         </Form.Item> */}
        <Form.Item
          name="ranging"
          label="测距功能"
        >
          <Radio.Group>
            <Radio value={true}>打开</Radio>
            <Radio value={false}>关闭</Radio>
          </Radio.Group>
        </Form.Item>
      </Form>
    </Dialog>
  )
}

export default MapSettingModal

