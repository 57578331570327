import React, { useState } from 'react'
import { Input, Select, Button, Form, Space, message } from 'antd'
import { MinusCircleOutlined } from '@ant-design/icons'
import map from 'lodash/map'
import forEach from 'lodash/forEach'
import keys from 'lodash/keys'
import size from 'lodash/size'
import Icon from 'src/components/common/Icon'
import { default as PageContainer, PageContainerTabs } from 'src/components/common/PageContainer'
import { useParameters } from 'src/hooks/common/useParameters'
import { useWorkspaces } from 'src/hooks/workspace/useWorkspaces'
import { ROLE_NAME } from 'src/constants/permissions'
import { inviteMember } from 'src/state/org/actions'
import useDispatch from 'src/hooks/common/useDispatch'
import { Member } from 'src/api/orgs'
import { validatePhoneNumber } from 'src/utils/validators'
import InvitationsTable from 'src/components/common/Invitations'
import { useDocumentTitle, getWorkspaceTitle } from 'src/hooks/common/useDocumentTitle'
import styles from './workspaceInvitations.module.scss'

const { Option } = Select
const { List } = Form
const INVITE_LIMIT = 10
const checkMembers = (members: Partial<Member>[]) => {
  const phones: Record<string, unknown> = {}
  forEach(members, member => {
    if (member.phone) {
      phones[member.phone] = true
    }
  })

  if (size(keys(phones)) !== size(members)) {
    message.error('请勿提交重复的手机号')
    return false
  }

  return true
}

const WorkspaceInvitations: React.FC = () => {
  const dispatch = useDispatch()
  const [form] = Form.useForm()
  const [appendable, setAppendable] = useState(true)
  const [currentTab, setCurrentTab] = useState('invite')
  const initialValues = {
    members: [{
      phone: ''
    }]
  }

  const { workspaceId, workspaceType } = useParameters()
  const { workspace } = useWorkspaces(workspaceType, workspaceId)

  useDocumentTitle('邀请成员', getWorkspaceTitle(workspace))

  const onFinish = async () => {
    const values = form.getFieldsValue()
    if (values.members && values.members.length && checkMembers(values.members)) {
      await dispatch(inviteMember({
        id: workspaceId,
        members: values.members
      }))

      message.success('邀请已发送')
      form.resetFields()
    }
  }

  const handleCheckAppendable = () => {
    const { members } = form.getFieldsValue()
    setAppendable(members?.length < INVITE_LIMIT)
  }

  return (
    <PageContainer
      title={<PageContainerTabs
        currentTab={currentTab}
        onTabClick={setCurrentTab}
        tabs={[
          { title: '邀请成员', key: 'invite' },
          { title: '待加入成员', key: 'members' }
        ]} />}
    >
      {currentTab === 'invite' && <>
        <div className={styles.hint}>
          您可以通过手机号或邮件邀请成员加入您的团队。<br />
          发送邀请后成员将收到邀请短信或邮件，成员注册后将自动加入“{workspace?.name}”团队。
        </div>
        <Form
          form={form}
          className={styles.inviteList}
          onFinish={onFinish}
          initialValues={initialValues}
        >
          <List name={'members'}>
            {(fields, { add, remove }) => (<>
              {fields.map(({
                key,
                name,
                fieldKey,
                ...restField
              }) => (
                <Space key={key} className={styles.item}>
                  <Form.Item
                    {...restField}
                    name={[name, 'phone']}
                    fieldKey={[fieldKey, 'phone']}
                    validateTrigger={'onBlur'}
                    rules={[{
                      validator (_, val) {
                        return validatePhoneNumber(val) ? Promise.resolve() : Promise.reject(new Error('请输入11位有效的手机号码'))
                      }
                    }]}
                  >
                    <Input placeholder="请输入成员手机号码" />
                  </Form.Item>
                  <Form.Item
                    {...restField}
                    name={[name, 'role']}
                    fieldKey={[fieldKey, 'role']}
                    rules={[{ required: true, message: '请选择权限' }]}
                  >
                    <Select placeholder={'请选择权限'}>
                      {
                        map(ROLE_NAME, (val, key) => <Option key={key} value={key}>{val}</Option>)
                      }
                    </Select>
                  </Form.Item>
                  {key > 0 && <MinusCircleOutlined
                    onClick={() => {
                      remove(name)
                      handleCheckAppendable()
                    }} />}
                </Space>
              ))}
              {appendable && <Form.Item>
                <div
                  className={styles.inviteBtn}
                  onClick={() => {
                    add()
                    handleCheckAppendable()
                  }}><Icon type={'plainPlus'} />添加成员
                </div>
              </Form.Item>}
            </>)}
          </List>
          <div className={styles.sendInvitation}>
            <Button type={'primary'} htmlType={'submit'}>发送邀请</Button>
          </div>
        </Form>
      </>}
      {currentTab === 'members' && <InvitationsTable type="org" />}
    </PageContainer>
  )
}

export default WorkspaceInvitations
