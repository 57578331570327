import React, { useState, useRef, useEffect } from 'react'
import { useIntl } from 'react-intl'
import trim from 'lodash/trim'
import debounce from 'lodash/debounce'
import type { GridApi } from 'ag-grid-community'
import { Popover, Input } from 'antd'
import ActionButton from 'src/components/project/ActionButton'
import { useSearch, Navigate } from 'src/modules/grid/hooks/useSearch'
import Icon from 'src/components/common/Icon'
import useDispatch from 'src/hooks/common/useDispatch'
import { actions as tableNodeActions, SearchResult } from 'src/state/tableNode/slice'
import styles from './search.module.scss'


interface SearchInputProps {
  onPressEnter: (e: React.KeyboardEvent<HTMLInputElement>) => void;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void; 
  onClose: () => void;
  onNav: Navigate<void>;
  results: SearchResult[];
  position: number;
}
const SearchInput:React.FC<SearchInputProps> = ({
  onPressEnter,
  onChange,
  onNav,
  onClose,
  results,
  position
}) => {
  const inputRef = useRef<Input>(null)
  const intl = useIntl()
  const positionToShow = results.length ? position + 1 : position

  function handleClose () {
    inputRef.current?.setValue('')
    onClose()
  }

  useEffect(() => {
    inputRef.current?.focus()
  }, [])

  return (
    <div className={styles.inputWrap}>
      <Input 
        ref={inputRef} 
        onPressEnter={onPressEnter} 
        onChange={debounce(onChange, 600)} 
        className={styles.input} 
        placeholder={intl.formatMessage({ id: 'search.grid.placeholder' })}
      />
      <Icon type="close" className={styles.iconClose} onClick={handleClose} />
      <div className={styles.nav}>
        <Icon type="arrowLeft" className={styles.iconNav} onClick={() => onNav('prev')} />
        <span className={styles.navInfo}>
          {positionToShow}
          <span className={styles.slash}>/</span>
          {results.length}
        </span>
        <Icon type="arrowLeft" className={styles.iconNavRight} onClick={() => onNav('next')} />
      </div>
    </div>
  )
}

interface Props {
  tableNodeId: number;
  gridApi?: GridApi;
}
export const Search:React.FC<Props> = ({
  tableNodeId,
  gridApi
}) => {
  const [visible, setVisible] = useState(false)
  const { 
    search, 
    results, 
    nav, 
    position,
    initialSearchIndex,
    clear
  } = useSearch(tableNodeId)
  const dispatch = useDispatch()

  function focusCell (currentPosition: number, cb?: () => void) {
    const highlightResult = results[currentPosition]

    if (highlightResult) {
      gridApi?.ensureIndexVisible(highlightResult.rowIndex)
      gridApi?.ensureColumnVisible(highlightResult.colId)
      gridApi?.setFocusedCell(highlightResult.rowIndex, highlightResult.colId)
      cb?.()
    }
  }

  function handlePressEnter (e: React.KeyboardEvent<HTMLInputElement>) {
    const nextPosition = nav('next')

    focusCell(nextPosition, () => {
      e.currentTarget.focus()
    })
  }

  function handleChange (e: React.ChangeEvent<HTMLInputElement>) {
    const query = trim(e.target.value)
    if (query) {
      search(query)
    } else {
      clear()
    }
  }

  const handleNav: Navigate<void> = (n) => {
    const nextPosition = nav(n)

    focusCell(nextPosition)
  }

  function handleOpen () {
    initialSearchIndex() 
    setVisible(true)
  }

  function handleClose () {
    dispatch(tableNodeActions.updateSearchResults([]))
    setVisible(false)
    clear()
    gridApi?.refreshCells({
      force: true
    })
  }

  useEffect(() => {
    if (!visible) return
    
    dispatch(tableNodeActions.updateSearchResults(results))

    gridApi?.refreshCells({
      force: true
    })
  }, [results, visible, gridApi, dispatch])

  return (
    <Popover
      placement="bottomLeft"
      content={
        <SearchInput 
          onPressEnter={handlePressEnter} 
          onChange={handleChange} 
          onClose={handleClose} 
          onNav={handleNav}
          results={results}
          position={position}
        />
      }
      trigger="click"
      visible={visible}
      destroyTooltipOnHide
    >
      
      <ActionButton
        icontype="search"
        onClick={handleOpen}
      >
        搜索
      </ActionButton>
    </Popover>
  )
}

export default Search