import React from 'react'
import { Redirect } from 'react-router-dom'

import styles from './home.module.css'

const HomePage: React.FC = () => {
  return (
    <div className={styles.page}>
      <Redirect to="/uw/" />
    </div>
  )
}

export default HomePage
