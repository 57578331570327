import { createSlice, EntityState } from '@reduxjs/toolkit'
import { CollaboratorType, CellCollaboratorType } from 'src/typings/collaborator'
import { RootState } from 'src/store'
import { actions as appActions } from 'src/state/app/slice'
import { WSReadyState } from 'src/state/app/reducers'
import { reducers } from './reducers'
import collaboratorAdapter from './entityAdapter'

export type State = EntityState<CollaboratorType> & {
  sessionID: string;
  cellCollaborators: Record<string, CellCollaboratorType[]>;
  otherCollaborators: Record<number, CollaboratorType>;
}

const initialState = collaboratorAdapter.getInitialState({
  sessionID: '',
  cellCollaborators: {},
  otherCollaborators: {}
})

const collaboratorSlice = createSlice({
  name: 'collaborator',
  initialState,
  reducers,
  extraReducers (builder) {
    builder.addCase(appActions.updateCollaboratorReadyState, (state, action) => {
      if (action.payload !== WSReadyState.OPEN) {
        state.sessionID = ''
        state.cellCollaborators = {}
        state.otherCollaborators = {}
      }
    })
  }
})

export default collaboratorSlice
export const actions = collaboratorSlice.actions
export const collaboratorSelectors = collaboratorAdapter.getSelectors<RootState>(state => state.collaborator)
