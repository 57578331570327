import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import pick from 'lodash/pick'
import { Menu, Button } from 'antd'
import { TeamOutlined } from '@ant-design/icons'
import { useDispatch } from 'react-redux'
import Tree from 'src/components/common/Tree'
import useTree from 'src/hooks/common/useTree'
import Sider from 'src/components/common/Sider'
import { useParameters } from 'src/hooks/common/useParameters'
import { TreeNode } from 'src/typings/tree'
import { AppDispatch } from 'src/store'
import { createTableNode, moveTableNode, deleteTableNode, updateTableNodeName } from 'src/state/tableNode/actions'
import { TableNode } from 'src/typings/mapTableNode'
import { Project } from 'src/typings/project'
import DataImportModal from 'src/components/project/DataImportModal'
import TableMemberModal from 'src/components/project/TableMemberModal'
import { useDialogState } from 'src/hooks/common/useDialog'
import { createTableNodeEmitter } from 'src/utils/emitter'
import Icon from 'src/components/common/Icon'
import { ROLE_ENTITY } from 'src/typings/workspace'
import { ProjectPermission } from 'src/constants/permissions'
import { useWorkspaceRoles } from 'src/hooks/workspace/useWorkspaceRoles'
import DuplicationModal from 'src/components/common/DuplicationModal'
import styles from './sider.module.scss'

interface Props {
  project?: Project;
  activeMenuItems?: string[];
}

const TableSider: React.FC<Props> = ({
  project,
  activeMenuItems = []
}) => {
  const { projectId, setTableNodeId, workspaceType, workspaceId } = useParameters()
  const { checkProjectPermission } = useWorkspaceRoles()
  const dispatch: AppDispatch = useDispatch()
  const history = useHistory()
  const { treeData, setTreeData, formatDataToTreeNode } = useTree()
  const tableNodes = project?.tableNodes
  const dialog = useDialogState()
  const tableMemberDialog = useDialogState()
  const [selectedItem, setSelectedItem] = useState<TreeNode>()
  const [tableNodeDuplicate, setTableNodeDuplicate] = useState<TreeNode>()
  const { checkPermission } = useWorkspaceRoles()
  const duplicateDialog = useDialogState()

  useEffect(() => {
    if (tableNodes) {
      setTreeData(formatDataToTreeNode(projectId, tableNodes))
    }
  }, [formatDataToTreeNode, tableNodes, setTreeData, projectId])

  function handleNodeSelect (menu: TreeNode) {
    if (menu.type === 'table' && menu.id) {
      setTableNodeId(menu.id)
    }
  }

  async function handleNodeCreate (treeNode: TreeNode) {
    const tableNode = pick(treeNode, ['name', 'projectID', 'type', 'parentID'])
    await dispatch(createTableNode(tableNode as TableNode))
  }

  async function handleNodeUpdate (treeNode: TreeNode) {
    const tableNode = pick(treeNode, ['name', 'projectID'])

    await dispatch(updateTableNodeName({ id: treeNode.id as number, ...tableNode }))
  }

  async function handleNodeDelete (treeNode: TreeNode) {
    await dispatch(deleteTableNode({
      id: treeNode.id,
      projectID: treeNode.projectID
    } as TableNode))
  }

  async function handleDND (fromNode: TreeNode, toNode?: TreeNode, parentNode?: TreeNode) {
    await dispatch(moveTableNode({
      id: fromNode.id as number,
      moveInfo: {
        projectID: projectId,
        parentID: parentNode?.id,
        prevID: toNode?.id
      }
    }))
  }

  async function handleMenuClick (action: string, node?: TreeNode) {
    switch (action) {
    case 'import':
      dialog.open()
      break
    case 'editPermission':
      if (node) {
        await setSelectedItem(node)
      }
      tableMemberDialog.open()
      break
    }
  }

  function handleNavigationMenuClick (key: React.ReactText) {
    history.push(`/${workspaceType}/${workspaceId}/p/${projectId}/${key}`)
  }

  function handleDuplicateClick (node: TreeNode) {
    setTableNodeDuplicate(node)
    duplicateDialog.open() 
  }

  return (
    <Sider>
      <div className={styles.tree}>
        <Tree
          type="table"
          title="数据表"
          data={treeData}
          roleData={{
            id: projectId,
            type: ROLE_ENTITY.project,
            role: ProjectPermission.TableSiderPlusButton
          }}
          onSelect={handleNodeSelect}
          onUpdate={handleNodeUpdate}
          onCreate={handleNodeCreate}
          onDelete={handleNodeDelete}
          onDuplicateClick={handleDuplicateClick}
          onDND={handleDND}
          onMenuClick={handleMenuClick}
          empty={
            <div>
              <div className={styles.tip}>未创建数据表，点击按钮新建</div>
              <Button
                type='primary' onClick={() => createTableNodeEmitter.emit()}
                disabled={!checkPermission(projectId, ProjectPermission.NewTable, ROLE_ENTITY.project)}
              >新建数据表</Button>
            </div>
          }
          isNoSelection={activeMenuItems.length > 0}
        />
      </div>
      <div className={styles.sideBottom}>
        <div className={styles.divider} />
        <Menu
          selectable={false}
          selectedKeys={activeMenuItems}
          className={styles.menu}
          onClick={info => handleNavigationMenuClick(info.key)}
        >
          {/* <Menu.Item icon={<Icon type="settings" />} key="setting">
            项目设置
          </Menu.Item> */}
          {checkProjectPermission(projectId, ProjectPermission.ProjectRecycleBinPage) &&
          <Menu.Item icon={<Icon type="recycleBin" />} key="recycle">
            回收站
          </Menu.Item>}
          {checkProjectPermission(projectId, ProjectPermission.Collaborator) &&
          <Menu.Item icon={<TeamOutlined />} key="member">
            协作成员
          </Menu.Item>}
          {/* {checkProjectPermission(projectId, ProjectPermission.SiderHelp) &&
          <Menu.Item icon={<Icon type="help" />} key="help">
            使用帮助
          </Menu.Item>} */}
        </Menu>
      </div>
      <DataImportModal dialog={dialog} />
      {workspaceType === 'w' && selectedItem && <TableMemberModal node={selectedItem} dialog={tableMemberDialog} />}
      <DuplicationModal 
        type="table"
        dialog={duplicateDialog} 
        duplicate={tableNodeDuplicate}
        projectID={projectId}
      />
    </Sider>
  )
}

export default TableSider
