import React, { useRef } from 'react'
import { LicenseManager } from 'ag-grid-enterprise'
import { AgGridReact } from 'ag-grid-react'
import cx from 'clsx'
import map from 'lodash/map'
import concat from 'lodash/concat'
import type {
  GridApi,
  GridReadyEvent,
  RowDragEndEvent,
  RowNode,
  SelectionChangedEvent,
  ValueSetterParams,
  ColumnMovedEvent
} from 'ag-grid-community'
import { useTableGridColumns } from 'src/hooks/field/useTableGrid'
import { tableGridCellRendererSelector } from 'src/modules/grid/cellRendererSelector'
import { SingleLineTextCellRenderer } from 'src/components/field/SingleLineText'
import { NumberCellRenderer } from 'src/components/field/Number'
import { DatetimeCellRenderer } from 'src/components/field/Datetime'
import type { TypeOptions } from 'src/typings/tableNode'
import type { Field } from 'src/components/grid/GridFieldConfig/GridFieldConfig'

import 'src/styles/grid.scss'
import {
  createColumnAdderColumn,
  createColumn,
  createIndexColumn
} from 'src/modules/grid/colDefs'
import {
  isFullWidthCell,
  useIndexColumnWidth
} from 'src/hooks/project/useGrid'
import RowAdder from 'src/components/grid/GridRowAdder'
import { ADD_ROW_ID, INDEX_COLUMN_ID } from 'src/constants/grid'
import CellEditorContainer from '../CellEditorContainer'

LicenseManager.setLicenseKey(process.env.REACT_APP_AG_GRID_LICENSE_KEY as string)

const enableFieldTypes = ['singleLineText', 'number', 'datetime']

interface Props {
  columnEditable?: boolean;
  cellEditable?: boolean;
  onGridReady?: (gridApi: GridApi) => void;
  columnData: TypeOptions['columns'];
  rowData: Record<string, unknown>[];
  onColumnDataChange?: (columnData: TypeOptions['columns']) => void;
  onRowDataChange?: (rowData: Record<string, unknown>[]) => void;
  onSelectionChange?: (rowIndexs: number[]) => void
  className?: string;
}

const TableGrid: React.FC<Props> = ({
  columnEditable,
  cellEditable,
  columnData = [],
  rowData = [],
  className,
  onGridReady,
  onColumnDataChange,
  onRowDataChange,
  onSelectionChange
}) => {
  const wrapperEl = useRef<HTMLDivElement>(null)
  const { 
    addColumn, 
    updateColumn, 
    transformToStandardTypeOptions, 
    insertColumn,
    getColumns,
    removeColumn,
    moveColumn
  } = useTableGridColumns(columnData)
  const { indexColumnWidth } = useIndexColumnWidth(rowData)
  const gridApi = useRef<GridApi>()

  function handleColumnAdd (prevColumnId: string, field: Field) {
    addColumn(prevColumnId, field)
    onColumnDataChange?.(getColumns())
  }

  function handleColumnUpdate (columnId: string, field: Field) {
    updateColumn(columnId, field)
    onColumnDataChange?.(getColumns())
  }

  function handleColumnInsert (prevColumnId?: string) {
    insertColumn(prevColumnId)
    onColumnDataChange?.(getColumns())
  }

  function handleColumnRemove (columnId: string) {
    removeColumn(columnId)
    onColumnDataChange?.(getColumns())
  }
  function handleRowDataUpdate () {
    const newRowData : Record<string, unknown>[] = []
    gridApi.current?.forEachNodeAfterFilterAndSort(node => !isFullWidthCell(node) && newRowData.push(node.data))
    onSelectionChange?.([])
    onRowDataChange?.(newRowData)
  }
  function handleRowAdd () {
    gridApi.current?.applyTransaction({ add: [{}], addIndex: gridApi.current?.getDisplayedRowCount() -1 })
    handleRowDataUpdate()
  }
  function handleSelectionChanged (e: SelectionChangedEvent) {
    onSelectionChange?.(e.api.getSelectedNodes().map(node => node.rowIndex ?? -1))
  }

  function handleColumnMoved (e: ColumnMovedEvent) {
    moveColumn(e.column?.getColId() as string, e.toIndex as number)
    onColumnDataChange?.(getColumns())
  }
  let columnDefs = map(columnData, c => {
    const cellEditableOptions = cellEditable ? {
      editable: true,
      cellEditorFramework: CellEditorContainer,
      cellEditorParams: {
        typeOptions: transformToStandardTypeOptions(c),
        type: c.type,
        inGrid: true
      },
      valueSetter: (params: ValueSetterParams) => {
        params.data[params.column.getColId()] = params.newValue ? params.newValue.toString() : params.newValue
        return true
      }
    } : {}
    return createColumn(c, {
      headerComponentParams: {
        enableFieldTypes,
        editable: columnEditable,
        type: c.type,
        name: c.name,
        ignoreChangeConfirm: true,
        typeOptions: transformToStandardTypeOptions(c),
        onColumnUpdate: handleColumnUpdate,
        onColumnLeftInsert: handleColumnInsert,
        onColumnRightInsert: handleColumnInsert,
        onColumnRemove: handleColumnRemove
      },
      minWidth: 100,
      initialWidth: 100,
      resizable: true,
      cellRendererSelector: tableGridCellRendererSelector,
      ...cellEditableOptions
    })
  })
  if (cellEditable) {
    const columnIndex = createIndexColumn({
      width: indexColumnWidth - 20
    })
    columnDefs = concat(columnIndex, columnDefs)
  }
  if (columnEditable) {
    const columnAdder = createColumnAdderColumn({
      headerComponentParams: {
        onColumnAdd: handleColumnAdd,
        enableFieldTypes
      }
    })
    columnDefs = concat(columnDefs, columnAdder)
  }

  const handleGridReady = (params: GridReadyEvent) => {
    gridApi.current = params.api
    onGridReady?.(params.api)
    params.columnApi.autoSizeAllColumns()
  }
  const handleRowDragEnd = (params: RowDragEndEvent) => {
    const indexColumn = params.columnApi.getColumn(INDEX_COLUMN_ID)
    if (indexColumn) {
      params.api.refreshCells({
        columns: [indexColumn],
        force: true
      })
    }
    handleRowDataUpdate()
  }
  const handlePostSort = (rowNodes: RowNode[]) => {
    const addRowIndex = rowNodes.findIndex(node => isFullWidthCell(node))
    if (addRowIndex !== -1) {
      rowNodes.push(rowNodes.splice(addRowIndex, 1)[0])
    }
  }

  return (
    <div
      className={cx('ag-theme-alpine', className )}
      style={{ height: '100%' }}
      ref={wrapperEl}
    >
      <AgGridReact
        columnDefs={columnDefs}
        rowData={cellEditable ? rowData.concat({ id: ADD_ROW_ID }) : rowData}
        components={{
          singleLineTextCellRenderer: SingleLineTextCellRenderer,
          numberCellRenderer: NumberCellRenderer,
          datetimeCellRenderer: DatetimeCellRenderer
        }}
        rowBuffer={30}
        suppressMovableColumns={!columnEditable}
        applyColumnDefOrder={true}
        suppressScrollOnNewData={true}
        animateRows={true}
        suppressMoveWhenRowDragging={true}
        enableRangeSelection={true}
        suppressMultiRangeSelection={true}
        suppressRowClickSelection={true}
        rowSelection="multiple"
        enableMultiRowDragging={true}
        rowDragManaged={true}
        onSelectionChanged={handleSelectionChanged}
        onGridReady={handleGridReady}
        onRowDragEnd={handleRowDragEnd}
        onColumnMoved={handleColumnMoved}
        isFullWidthCell={isFullWidthCell}
        isRowSelectable={r => !isFullWidthCell(r)}
        fullWidthCellRendererFramework={RowAdder}
        fullWidthCellRendererParams={
          { onRowAdd: handleRowAdd }
        }
        postSort={handlePostSort}
        processCellForClipboard={(params) => params.value.realValue}
        // TODO: support context menu
        suppressContextMenu={true}
      />
    </div>
  )
}

export default TableGrid
