import React from 'react'
import ReactDOM from 'react-dom/server'
import toNumber from 'lodash/toNumber'
import CellRenderer from 'src/components/field/CellRenderer/CellRenderer'
import { actions as projectActions } from 'src/state/project/slice'
import { MultiLineTextRenderer } from './MultiLineTextRenderer'

export class MultiLineTextCellRenderer extends CellRenderer {
  private openModalElement: Element | null = null

  init (p: CellRenderer['params']): void {
    this.value = p?.realValue
    this.params = p

    const el = this.createEL()
    el.innerHTML = ReactDOM.renderToStaticMarkup(
      <MultiLineTextRenderer 
        value={this.value} 
        inGrid={true} 
        truncateLength={200} 
      />
    )

    this.openModalElement = el.querySelector('[data-multiline="openModal"]')

    if (this.openModalElement) {
      this.openModalElement.addEventListener('click', this.openModalHandler)
    }

    this.eGui = this.withCollaborators(el, this.params?.collaborator)
  }

  getGui (): HTMLElement {
    return this.eGui
  }

  refresh (): boolean {
    return false
  }

  destroy (): void {
    if (this.openModalElement) {
      this.openModalElement.removeEventListener('click', this.openModalHandler)
    }
  }

  openModalHandler = (): void => {
    const dispatch = this.params?.context?.store.dispatch
    const id = toNumber(this.params?.tableNodeId)
    const rowId = this.params?.data.id
    const cellId = this.params?.colDef?.field as string
    dispatch(projectActions.updateMultiLineTextEditor({
      visible: true,
      trigger: 'open',
      openFrom: 'renderer',
      data: {
        id,
        rowId,
        cellId,
        value: this.value
      }
    }))
  }
}
