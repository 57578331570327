import React, { useState, useRef, useEffect } from 'react'
import { Modal, Button } from 'antd'
import type { RadioChangeEvent } from 'antd/lib/radio/interface'
import Draggable, { DraggableEventHandler } from 'react-draggable'
import { QUMap as QUMapClass } from 'qumap/packages/core'
import { QUMap, Controls, Zoom, Editor } from 'qumap/packages/react'
import { Props as EditorProps } from 'qumap/packages/react/types/editor/Editor'
import { useMap } from 'src/hooks/map/useMap'
import { useParameters } from 'src/hooks/common/useParameters'
import useSelector from 'src/hooks/common/useSelector'
import useDispatch from 'src/hooks/common/useDispatch'
import { actions as projectActions } from 'src/state/project/slice'
import OverlayTypeSelector from 'src/components/common/OverlayTypeSelector'
import useCoordinateEditor, { getCoordinatesValue } from 'src/hooks/project/useCoordinateEditor'
import Icon from 'src/components/common/Icon'
import SearchPOI, { SearchPOIType } from 'src/components/common/SearchPOI'
import type { Coordinate } from 'src/utils/wkt'
import { MARKER_EDITOR_HINT, POLYLINE_EDITOR_HINT, POLYGON_EDITOR_HINT } from 'src/constants/hint'
import { useDraggableBounds } from 'src/hooks/common/useDraggable'
import styles from './coordinate.module.scss'

interface HeaderProps {
  onClose: () => void;
  onMouseOver: () => void;
  onMouseOut: () => void;
  mapInstance: React.RefObject<QUMapClass>;
  overlayType: string;
  onOverlayTypeChange: (e: RadioChangeEvent) => void;
  onSearchPOISelected: (poi: SearchPOIType) => void;
}

const Header: React.FC<HeaderProps> = ({
  onClose,
  onMouseOut,
  onMouseOver,
  overlayType,
  mapInstance,
  onOverlayTypeChange,
  onSearchPOISelected
}) => {
  const coordinateEditor = useSelector(state => state.project.coordinateEditor)
  return (
    <div className={styles.header} onMouseOver={onMouseOver} onMouseOut={onMouseOut}>
      <div className={styles.headerLeft}>
        <h3 className={styles.title}>标记</h3>
        <OverlayTypeSelector onChange={onOverlayTypeChange} value={overlayType} />
      </div>
      <div className={styles.headerRight}>
        <div className={styles.searchPOI}>
          {coordinateEditor.visible && <SearchPOI mapInstance={mapInstance} onSelected={onSearchPOISelected} />}
        </div>
        <Icon type='close' onClick={onClose} className={styles.iconClose} />
      </div>
    </div>
  )
}

interface FooterProps {
  onCancel: () => void;
  onSubmit: () => void;
  coordinates?: string | unknown;
  onClear: () => void;
}

const Footer: React.FC<FooterProps> = ({
  onCancel,
  onSubmit,
  onClear,
  coordinates
}) => {
  return (
    <div className={styles.footer}>
      <Button danger onClick={onClear} ><Icon type="trash" className={styles.iconTrash}/>清空绘制</Button>
      <div>
        <Button onClick={onCancel}>取消</Button>
        <Button type="primary" onClick={onSubmit}>标记</Button>
      </div>
    </div>
  )
}

interface WrapperProps {
  mapInstance: React.RefObject<QUMapClass>
  editorType: string;
  onEditorTypeChange: (t: string) => void;
  onSubmit: (close: () => void) => void;
  onClear: (close: () => void) => void;
  onSearchPOISelected: (poi: SearchPOIType) => void;
}

const CoordinateEditorWrapper: React.FC<WrapperProps> = ({
  mapInstance,
  editorType,
  onEditorTypeChange,
  onSubmit,
  onClear,
  onSearchPOISelected,
  children
}) => {
  const dispatch = useDispatch()
  const { bounds, updateBounds } = useDraggableBounds()
  const [dragDisabled, setDragDisabled] = useState(true)
  const [tipCount, setTipCount] = useState(1)
  const dragRef = useRef<HTMLDivElement>(null)
  const coordinateEditor = useSelector(state => state.project.coordinateEditor)
  const visible = coordinateEditor.visible
  const WKTCoordinates = getCoordinatesValue(coordinateEditor.data)

  function handleModalClose () {
    dispatch(projectActions.updateCoordinateEditor({
      visible: false,
      trigger: 'close'
    }))
  }

  function handleOverlayTypeChange (e: RadioChangeEvent) {
    onEditorTypeChange(e.target.value)
    setTipCount(1)
  }

  function handleSubmit () {
    onSubmit(handleModalClose)
  }

  function handleClear () {
    onClear(handleModalClose)
  }

  const handleDragStart: DraggableEventHandler = (_, uiData) => {
    updateBounds(window.document.documentElement, uiData, dragRef?.current)
  }

  return (
    <Modal
      visible={visible}
      closable={false}
      mask={false}
      title={
        <Header
          mapInstance={mapInstance}
          onClose={handleModalClose}
          onMouseOver={() => setDragDisabled(false)}
          onMouseOut={() => setDragDisabled(true)}
          onOverlayTypeChange={handleOverlayTypeChange}
          overlayType={editorType}
          onSearchPOISelected={onSearchPOISelected}
        />
      }
      footer={
        <Footer
          onCancel={handleModalClose}
          onSubmit={handleSubmit}
          onClear={handleClear}
          coordinates={WKTCoordinates}
        />
      }
      className={styles.modal}
      width={620}
      modalRender={modal => (
        <Draggable
          disabled={dragDisabled}
          bounds={bounds}
          onStart={handleDragStart}
        >
          <div className={styles.content} ref={dragRef}>
            {modal}
          </div>
        </Draggable>
      )}
    >
      {children}
      {
        tipCount !== 0 && <div className={styles.tips}>
          提示：
          {
            editorType === 'marker' && MARKER_EDITOR_HINT
          }
          {
            editorType === 'polyline' && POLYLINE_EDITOR_HINT
          }
          {
            editorType === 'polygon' && POLYGON_EDITOR_HINT
          }
          <div className={styles.tipConfirm} onClick={() => setTipCount(() => 0)}>知道了</div>
        </div>
      }
    </Modal>
  )
}

export const CoordinateEditorModal = React.memo(() => {
  const { projectId } = useParameters()
  const {
    mapOptions,
    quMap,
    handleMapLoad
  } = useMap(projectId)
  const {
    updateCoordindate,
    editor,
    editorType,
    preData,
    changeEditorType,
    setPreData,
    clearCoordinate
  } = useCoordinateEditor(quMap.current)

  const coordinateEditor = useSelector(state => state.project.coordinateEditor)

  useEffect(() => {
    if (!coordinateEditor.visible) {
      setPreData(null)
    }
  }, [coordinateEditor.visible, setPreData])

  const handleSearchPOISelected = (selectedPOI: SearchPOIType) => {
    if (selectedPOI) {
      changeEditorType('marker')
      setPreData([selectedPOI.location.lng, selectedPOI.location.lat] as Coordinate)
      quMap?.current?.amap.setCenter([selectedPOI.location.lng, selectedPOI.location.lat])
    } else {
      setPreData(null)
    }
  }

  async function handleSubmit (closeModal: () => void) {
    const overlayGeoJSON = editor?.current?.getOverlaysGeoJSON()

    if (!overlayGeoJSON) {
      clearCoordinate()
    }
    else {
      updateCoordindate(overlayGeoJSON)
    }

    closeModal()
  }

  function handleClear (closeModal: () => void) {
    editor.current?.reset()
  }
  function handleEdtiorFinish () {
    editor.current?.next()
  }
  return (
    <CoordinateEditorWrapper
      mapInstance={quMap}
      editorType={editorType}
      onEditorTypeChange={changeEditorType}
      onSubmit={handleSubmit}
      onClear={handleClear}
      onSearchPOISelected={handleSearchPOISelected}
    >
      <QUMap {...mapOptions} plugins={['AMap.PlaceSearch']} quMapRef={quMap} onLoad={handleMapLoad}>
        <Controls>
          <Zoom />
        </Controls>
        <Editor
          preData={preData}
          ref={editor}
          type={editorType as EditorProps['type']}
          onFinish={handleEdtiorFinish}
        />
      </QUMap>
    </CoordinateEditorWrapper>
  )
})
