import React, { useEffect, useState, useRef } from 'react'
import { useDispatch } from 'react-redux'
import merge from 'lodash/merge'
import split from 'lodash/split'
import map from 'lodash/map'
import find from 'lodash/find'
import last from 'lodash/last'
import dropRight from 'lodash/dropRight'
import get from 'lodash/get'
import { QUMap as QUMapClass } from 'qumap/packages/core'
import type { Props as QUMapProps } from 'qumap/packages/react/types/quMap/QUMap'
import { BaseMap, BaseMapStyle, MapConfig } from 'src/typings/project'
import useSelector from 'src/hooks/common/useSelector'
import { MAPOPTIONS } from 'src/constants/map'
import { actions as MapActions, Editor } from 'src/state/map/slice'
import { useParameters } from 'src/hooks/common/useParameters'
import { AppDispatch } from 'src/store'
import * as TableNodeActions from 'src/state/tableNode/actions'
import { Geometry, stringifyGeoJSON } from 'src/utils/wkt'
import { GeoJSON } from 'src/typings/common'
import { tableNodeSelectors } from 'src/state/tableNode/slice'
import { projectSelectors } from 'src/state/project/slice'
import { updateCells } from 'src/state/tableNode/actions'
import { InfoWindowPOIType } from 'src/typings/map'
import { actions as InfoWindowActions } from 'src/state/infoWindow/slice'
import { OVERLAY_EDITOR_TYPES_MAPPING } from 'src/constants/overlay'
import usePoiDetailPanel from './usePoiDetailPanel'

interface UseMapReturn {
  mapOptions?: QUMapProps;
  quMap: React.RefObject<QUMapClass>;
  mapConfig?: MapConfig;
  handleMapLoad: () => void;
  isMapReady: boolean;
}

// TODO: need refactor
// 1. 地图组件没有响应 options 的变化
// 2. 地图组件需要提供一个实例 ref 给父组件使用
// 3. 底图的类型没有在地图组件和控制组件直接同步
export function useMap (projectId: number): UseMapReturn {
  const [mapOptions, setMapOptions] = useState<QUMapProps | undefined>(undefined)
  const [isMapReady, setMapReady] = useState(false)
  const quMap = useRef<QUMapClass>(null)
  const mapConfig = useSelector(state => projectSelectors.selectById(state, projectId)?.mapConfig)

  useEffect(() => {
    
    if (!isMapReady) return

    quMap.current?.amap.setZoomAndCenter(
      mapConfig?.zoomLevel as number,
      map(split(mapConfig?.center, ','), p => parseFloat(p)) as [number, number],
      true
    )
  }, [mapConfig?.center, mapConfig?.zoomLevel, mapConfig?.baseMap, isMapReady])

  useEffect(() => {
    setMapOptions((prevState) => merge(prevState ?? MAPOPTIONS, {
      options: {
        center: split(mapConfig?.center, ','),
        zoom: mapConfig?.zoomLevel,
        mapStyle: BaseMapStyle[mapConfig?.baseMap ?? BaseMap.AmapStandard]
      }
    }))
  }, [mapConfig?.baseMap, mapConfig?.center, mapConfig?.zoomLevel])

  useEffect(() => {
    if (!isMapReady) return

    quMap.current?.changeTileLayer(mapConfig?.baseMap)
  }, [mapConfig?.baseMap, isMapReady])

  function handleMapLoad () {
    setMapReady(true)
  }

  return {
    mapOptions,
    quMap,
    mapConfig,
    handleMapLoad,
    isMapReady
  }
}

interface UseOverlayEditorReturn {
  selectedMapLayer: Editor;
  editorType: string;
  setEditorType: (value: string) => void;
  isEditorOpen?: boolean;
  setIsEditorOpen: (value: boolean, poi?: InfoWindowPOIType) => void;
  resetEditor: () => void;
  updateOverlay: (GeoJSON: GeoJSON) => Promise<void>;
  isNewPOI?: boolean
  enable: boolean;
  preData?: Geometry['coordinates'] | null;
}

export function useOverlayEditor (): UseOverlayEditorReturn {
  const dispatch: AppDispatch = useDispatch()
  const {
    handleDetailPanelClose
  } = usePoiDetailPanel()
  const [editorType, setEditorType] = useState('marker')
  const editorStatus = useSelector(state => state.map.editor)

  useEffect(() => {
    dispatch(MapActions.updateEditor({
      type: editorType
    }))
  }, [editorType, dispatch])

  function resetEditor () {
    dispatch(MapActions.updateEditor({
      preData: null,
      type: editorStatus.type
    }))
  }
  async function setIsEditorOpen (val: boolean, poi?: InfoWindowPOIType) {
    const isNew = !poi
    const editorPOI = { ...poi } as InfoWindowPOIType
    const type = get(OVERLAY_EDITOR_TYPES_MAPPING, editorPOI.overlayType as string, 'marker')
    if (isNew) {
      await dispatch(MapActions.updateEditor({
        isOpen: val,
        enable: !!editorStatus.layerNodeID,
        isNew,
        poi: undefined,
        preData: null,
        type: editorStatus.type || 'marker'
      }))
    } else {
      const preData = (editorPOI.overlayType === 'Polygon' ?
        dropRight(editorPOI.coordinates[0] as number[]) :
        editorPOI.coordinates) as Geometry['coordinates'] | null
      await dispatch(MapActions.updateEditor({
        isOpen: val,
        isNew,
        enable: true,
        poi: editorPOI,
        preData,
        type
      }))
    }

    if (val) {
      dispatch(InfoWindowActions.clear())
      handleDetailPanelClose()
    }
  }

  async function updateOverlay (overlayGeoJSON: GeoJSON) {

    const cell = editorStatus.poi?.extData
    if (cell && cell.rowID && cell.tableNodeID) {
      const cells = {
        [cell.cellID as string]: stringifyGeoJSON(overlayGeoJSON)
      }
      await dispatch(updateCells({
        id: cell.tableNodeID as number,
        rowId: cell.rowID,
        cells
      }))

      dispatch(MapActions.updateEditor({
        isOpen: false
      }))
    }
  }

  return {
    selectedMapLayer: editorStatus,
    editorType: editorStatus.type || editorType,
    setEditorType,
    isEditorOpen: editorStatus.isOpen,
    setIsEditorOpen,
    resetEditor,
    enable: editorStatus.enable,
    isNewPOI: editorStatus.isNew,
    preData: editorStatus.preData,
    updateOverlay
  }
}

interface UseOverlayReturn {
  addOverlay: (GeoJSON: GeoJSON) => Promise<void>;
}

export function useOverlay (): UseOverlayReturn {
  const dispatch: AppDispatch = useDispatch()
  const { projectId } = useParameters()
  const layerNode = useSelector((state) => {
    return find(state.project.layerNodes[projectId], (val) => val.id === state.map.editor.layerNodeID)
  })
  const lastRowId = useSelector((state) => {
    if (layerNode?.mapInfo?.tableNodeID) {
      const tableNode = tableNodeSelectors.selectById(state, layerNode?.mapInfo?.tableNodeID)

      return last(tableNode?.rows)?.id
    }
  })

  async function addOverlay (overlayGeoJSON: GeoJSON) {

    if ((lastRowId !== undefined) && (layerNode?.mapInfo?.tableNodeID !== undefined)) {
      const cells = {
        [layerNode.mapInfo.coordinateFieldID]: stringifyGeoJSON(overlayGeoJSON)
      }

      await dispatch(TableNodeActions.createRow({
        id: layerNode?.mapInfo?.tableNodeID,
        prevRowId: lastRowId,
        cells
      }))

      dispatch(MapActions.updateEditor({
        isOpen: false
      }))
    }
  }

  return {
    addOverlay
  }
}
