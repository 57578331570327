import React, { useState, useEffect } from 'react'
import { InputNumber, Form, Row, Col, Slider } from 'antd'
import type { FormInstance } from 'antd/lib/form/hooks/useForm'
import cx from 'clsx'
import filter from 'lodash/filter'
import useSelector from 'src/hooks/common/useSelector'
import { tableNodeSelectors } from 'src/state/tableNode/slice'
import { isNumberFormula } from 'src/utils/field'
import FieldTypeSelector from 'src/components/common/FieldTypeSelector'

import styles from './layerSettingPanel.module.scss'

interface Props {
  collapse?: boolean;
  form: FormInstance;
  onChange?: () => void;
}

const MIN_RADIUS = 0
const MAX_RADIUS = 60

const RadiusSetting: React.FC<Props> = ({
  onChange,
  collapse,
  form
}) => {
  const [isCollapse, setCollapse] = useState(collapse)
  const [field, setField] = useState<string | undefined>()
  const [range, setRange] = useState<[number, number]>(form.getFieldValue('radiusConfig.radiusRange'))
  const options = useSelector(state => {
    const tableNode = tableNodeSelectors.selectById(state, form.getFieldValue('tableNodeID'))
    return filter(tableNode?.columns, c => {
      return c.type === 'number' || isNumberFormula(c)
    })
  })
  const initialField = form.getFieldValue('radiusFieldID')

  useEffect(() => {
    setField(initialField)
  }, [initialField])

  function handleToggleClick () {
    setCollapse(!isCollapse)
  }

  function handleFieldChange (option: string) {
    setField(option)
  }

  // FIXME: parent form onValuesChange not fired
  function handleRadiusChange (range: [number, number]) {
    setRange(range)
    onChange?.()
  }

  useEffect(() => {
    form.setFieldsValue({
      'radiusConfig.radiusRange': range
    })
  }, [range, form])

  return (
    <div className={styles.config}>
      <div className={styles.configHeader}>
        <div>填充半径</div>
        <div className={styles.configToggle} onClick={handleToggleClick}>
          {isCollapse ? '展开' : '收起'}
        </div>
      </div>
      <div className={cx(styles.container, { [styles.collapse]: isCollapse })}>
        <Row gutter={[8, 8]}>
          <Col span={24}>
            <div className={styles.box}>
              <div className={styles.configLabel}>半径</div>
              <div className={cx({ hide: !field })}>
                <Slider
                  min={MIN_RADIUS}
                  max={MAX_RADIUS}
                  range
                  value={range}
                  onChange={handleRadiusChange}
                />
                <Form.Item name="radiusConfig.radiusRange">
                  <div className={styles.range}>
                    <InputNumber
                      min={MIN_RADIUS}
                      max={range[1]}
                      size="small"
                      value={range[0]}
                      onChange={val => {
                        if (val && val < range[1]) {
                          setRange((prev) => [val as number, prev[1]])
                        } else {
                          setRange((prev) => [range[1], prev[1]])
                        }
                      }}
                    />
                    <InputNumber
                      min={range[0]}
                      max={MAX_RADIUS}
                      size="small"
                      value={range[1]}
                      onChange={(val) => {
                        if (val && val > range[0]) {
                          setRange((prev) => [prev[0], val as number])
                        } else {
                          setRange((prev) => [prev[0], range[0]])
                        }
                      }}
                    />
                  </div>
                </Form.Item>
              </div>
              <Row gutter={4} className={cx({ hide: field })}>
                <Col span={16}>
                  <Form.Item name="radiusConfig.radius">
                    <Slider
                      min={MIN_RADIUS}
                      max={MAX_RADIUS}
                    />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item name="radiusConfig.radius">
                    <InputNumber
                      size="small"
                      min={MIN_RADIUS}
                      max={MAX_RADIUS}
                    />
                  </Form.Item>
                </Col>
              </Row>
            </div>
          </Col>
          <Col span={24}>
            <div className={styles.box}>
              <div className={styles.configLabel}>依据以下字段填充</div>
              <Form.Item name="radiusFieldID">
                <FieldTypeSelector
                  placeholder={'请选择字段'}
                  allowClear
                  onChange={handleFieldChange}
                  options={options}
                />
              </Form.Item>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  )
}

export default RadiusSetting
