import React from 'react'
import { Route, Redirect } from 'react-router-dom'
import useSelector from 'src/hooks/common/useSelector'

import { CustomRouteProps } from 'src/routes'

const AuthRouteContainer: React.FC<CustomRouteProps> = (props) => {
  const isAuth = useSelector(state => state.user.isAuth)
  return (
    isAuth ? <Route {...props} /> : <Redirect to="/login" />
  )
}

export default AuthRouteContainer
