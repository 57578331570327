import React from 'react'
import isNumber from 'lodash/isNumber'
import cx from 'clsx'
import { padNumberByPrecisionWithFormat } from 'src/utils/format'
import styles from './numberRenderer.module.scss'

export interface Props {
  value: string | number;
  inGrid: boolean
  typeOptions: {
    precision?: number
    format?: string
  }
}

export const NumberRenderer: React.FC<Partial<Props>> = (props) => {
  const { typeOptions } = props

  return (
    <div className={cx(props.inGrid && styles.numberInGrid)}>
      {isNumber(props.value) && padNumberByPrecisionWithFormat(
        props.value,
        typeOptions?.precision ?? 0,
        typeOptions?.format
      )}
    </div>
  )
}
