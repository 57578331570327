import React from 'react'
import cx from 'clsx'
import { Col, Row, Select, Radio, Button } from 'antd'
import { RadioChangeEvent } from 'antd/lib/radio/interface'
import { ArrowRightOutlined } from '@ant-design/icons'
import map from 'lodash/map'
import find from 'lodash/find'
import Icon, { IconType } from 'src/components/common/Icon'
import useSelector from 'src/hooks/common/useSelector'
import { useParameters } from 'src/hooks/common/useParameters'
import { TableNodeColumn, SortingType } from 'src/typings/tableNode'
import { tableNodeSelectors } from 'src/state/tableNode/slice'
import { FIELD_TYPES } from 'src/constants/fieldType'
import styles from './sortingItem.module.scss'

interface Props {
  sorting: SortingType;
  selectedFieldIds: Record<string, boolean>;
  onRemove?: (sorting: SortingType) => void;
  onChange?: (sorting: SortingType) => void;
}

const SortingItem: React.FC<Props> = (props) => {
  const { sorting, selectedFieldIds, onRemove, onChange } = props
  const { tableNodeId } = useParameters()
  const columns = useSelector(state => {
    const tableNode = tableNodeSelectors.selectById(state, tableNodeId)
    return tableNode?.columns || []
  })

  const handleFieldChanged = (val: string) => {
    onChange?.({
      ...sorting,
      field: find(columns, col => col.id === val) as SortingType['field']
    })
  }

  const handleSortChange = (val: RadioChangeEvent) => {
    onChange?.({
      ...sorting,
      sorting: val.target.value
    })
  }

  const renderFieldOptions = () => {
    return map(columns, (o: TableNodeColumn) => {
      if (FIELD_TYPES[o.type].sortType &&
        ((selectedFieldIds[o.id] && o.id === sorting.field?.id) ||
          !selectedFieldIds[o.id])) {
        return <Select.Option key={o.id} value={o.id}>
          <div className={styles.selectOption}>
            <Icon type={find(FIELD_TYPES, f => f.type === o.type)?.icon as IconType} />
            {o.name}
          </div>
        </Select.Option>
      }
    })
  }

  const renderSortType = () => {
    const sortType = sorting.field ? FIELD_TYPES[sorting.field.type]?.sortType : undefined
    switch (sortType) {
    case 'alphabetically':
      return (
        <>
          <Radio.Button value="asc">A <ArrowRightOutlined /> Z</Radio.Button>
          <Radio.Button value="desc">Z <ArrowRightOutlined /> A</Radio.Button>
        </>
      )
    case 'numeric':
      return (
        <>
          <Radio.Button value="asc">0 <ArrowRightOutlined /> 9</Radio.Button>
          <Radio.Button value="desc">9 <ArrowRightOutlined /> 0</Radio.Button>
        </>
      )
    case 'order':
      return (
        <>
          <Radio.Button value="asc">正序</Radio.Button>
          <Radio.Button value="desc">倒序</Radio.Button>
        </>
      )
    default:
      return <></>
    }
  }

  return (
    <Row className={styles.option} gutter={[16, 10]}>
      <Col span={1}>
        <div className={cx(styles.optionCell, styles.iconCell)} onClick={() => onRemove?.(sorting)}>
          <Icon
            type={'close'}
            className={styles.close} />
        </div>
      </Col>
      <Col span={10}>
        <div className={cx(styles.optionCell, styles.noPadding)}>
          <Select onChange={handleFieldChanged} value={sorting.field?.id}>
            {renderFieldOptions()}
          </Select>
        </div>
      </Col>
      <Col span={2}>
        顺序
      </Col>
      <Col span={9}>
        {sorting.field && <div className={cx(styles.optionCell, styles.bg, styles.noPadding)}>
          <Radio.Group
            defaultValue={sorting.sorting}
            buttonStyle="solid"
            className={styles.radioGroup}
            onChange={handleSortChange}
          >
            {renderSortType()}
          </Radio.Group>
        </div>}
      </Col>
      <Col span={2}>
        <Button
          className={styles.handle}
          type="text"
          size="small"
          data-movable-handle
          icon={<Icon type="movable" className={styles.icon} />}
        />
      </Col>
    </Row>
  )
}

export default SortingItem
