import { Middleware } from '@reduxjs/toolkit'
import { watchUndoRedoActions } from 'src/features/undoRedo/undoRedo'

const undoRedoMiddleware: Middleware = (api) => (next) => (action) => {

  watchUndoRedoActions(action, api)

  return next(action)
}

export default undoRedoMiddleware