import { useCallback, useEffect, useState } from 'react'
import { useDeepCompareEffect } from 'react-use'
import filter from 'lodash/filter'
import isNil from 'lodash/isNil'
import every from 'lodash/every'
import isEqual from 'lodash/isEqual'
import set from 'lodash/set'
import {
  CONDITIONS,
  Condition as ConditionType,
  Filter as FilterType, isNullableRelation
} from 'src/constants/filter'
import useSelector from 'src/hooks/common/useSelector'
import type { Column } from 'src/typings/tableNode'
import { useWorkspaces } from 'src/hooks/workspace/useWorkspaces'
import { selectColumnsForFilter } from 'src/selectors/filterSelector'

function isFilterValid (filter: FilterType) {
  if (isNullableRelation(filter.relation?.key)) {
    return !isNil(filter.field) && !isNil(filter.relation) && isNil(filter.value)
  } else {
    return !isNil(filter.field) && !isNil(filter.relation) && !isNil(filter.value)
  }
}

export interface FiltersWithIdType {
  id: number | string | undefined
  filters: FilterType[]
}

interface Return {
  filtersWithId: FiltersWithIdType;
  addFilter: () => void;
  removeFilter: (f: FilterType) => void;
  changeFilter: (f: FilterType) => void;
  clearFilters: () => void;
  columnsForFilter: Column[];
  filterOutInvalidFilter: () => void;
}

function useFilter (
  propFilters?: FiltersWithIdType,
  onChange?: (filters: FilterType[]) => void,
  tableNodeId?: number,
  layerNodeId?: string
): Return {
  const { userWorkspaceId } = useWorkspaces('uw')
  const mapLayerFilterId = layerNodeId ? `${userWorkspaceId}_${layerNodeId}` : tableNodeId
  const [filtersWithId, setFiltersWithId] = useState<FiltersWithIdType>({
    id: mapLayerFilterId,
    filters: propFilters?.filters || []
  })
  const [secondCondition, setSecondCondition] = useState<ConditionType>(CONDITIONS.OR)
  const columnsForFilter = useSelector(state => selectColumnsForFilter(state, tableNodeId as number))

  useEffect(() => {
    if (propFilters?.id === mapLayerFilterId) {
      const vfs: FilterType[] = []
      propFilters?.filters.forEach((filter: FilterType) => {
        if (filter.condition && filter.field && filter.relation) {
          if (isNullableRelation(filter.relation.key)) {
            vfs.push(filter)
          } else if (!isNil(filter.value)) {
            vfs.push(filter)
          }
        }
      })

      setFiltersWithId({
        id: mapLayerFilterId,
        filters: [...vfs]
      })
    }
  }, [propFilters, mapLayerFilterId])

  useDeepCompareEffect(() => {
    if (
      (filtersWithId.id === propFilters?.id) &&
      !isEqual(propFilters, filtersWithId) &&
      every(filtersWithId.filters, isFilterValid)
    ) {
      onChange?.(filtersWithId.filters || [])
    }
  }, [filtersWithId])

  function addFilter () {
    const isFirstFilter = filtersWithId.filters.length === 0
    setFiltersWithId((prev) => {
      return {
        id: mapLayerFilterId,
        filters: [...(prev.filters || []), {
          id: (filtersWithId.filters?.length ?? 0) + 1,
          condition: isFirstFilter ? CONDITIONS.WHEN : secondCondition,
          value: undefined
        }]
      }
    })
  }

  function removeFilter (item: FilterType) {
    const filteredItems = [...filter(filtersWithId.filters, o => o.id !== item.id)]
    if (filteredItems.length && filteredItems[0].condition) {
      const firstItem = { ...filteredItems[0] }
      firstItem.condition = CONDITIONS.WHEN
      filteredItems[0] = firstItem
    }

    setFiltersWithId({
      id: mapLayerFilterId,
      filters: filteredItems
    })

    if (filteredItems.length === 0) {
      onChange?.([])
    }
  }

  const changeFilter = useCallback((item: FilterType) => {
    let sc: ConditionType

    setFiltersWithId((prev) => {
      return {
        id: prev.id,
        filters: prev.filters.map((filter: FilterType, idx: number) => {
          if (item.id === filter.id) {
            filter = item
          }

          if (idx === 1) {
            sc = filter.condition || CONDITIONS.OR
            setSecondCondition(sc)
          }

          if (idx > 1) {
            set(filter, 'condition', sc)
          }

          return filter
        })
      }
    })
  }, [])

  function clearFilters () {
    setFiltersWithId({
      id: propFilters?.id,
      filters: []
    })
    onChange?.([])
  }

  function filterOutInvalidFilter () {
    const validFilters = filter(filtersWithId.filters, f => isFilterValid(f))

    setFiltersWithId({
      id: mapLayerFilterId,
      filters: validFilters
    })

    if (validFilters.length === 0) {
      onChange?.([])
    }
  }

  return {
    filtersWithId,
    addFilter,
    removeFilter,
    changeFilter,
    clearFilters,
    columnsForFilter,
    filterOutInvalidFilter
  }
}

export default useFilter
