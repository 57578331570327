import React from 'react'
import styles from './borderedText.module.scss'

const BorderedText: React.FC = (props) => {
  return (
    <span className={styles.borderedText}>
      {props.children}
    </span>
  )
}

export default BorderedText
