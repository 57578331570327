import { createSlice, EntityState } from '@reduxjs/toolkit'
import filter from 'lodash/filter'
import forEach from 'lodash/forEach'
import isEmpty from 'lodash/isEmpty'
import findIndex from 'lodash/findIndex'
import { RootState } from 'src/store'
import { LayerNode, MapInfo } from 'src/typings/mapTableNode'
import { actions as LayerNodeActions } from 'src/state/layerNode/slice'
import { deleteLayerNode, previewLayerNode } from 'src/state/layerNode/actions'

import { actions as TableNodeActions } from 'src/state/tableNode/slice'
import { Project } from 'src/typings/project'
import { Workspace } from 'src/typings/workspace'
import projectAdapter from './entityAdapter'

import { reducers, CoordinateEditor, MultiLineTextEditor } from './reducers'
import { createProject, editProject, deleteProject } from './actions'

export interface State extends EntityState<Project> {
  currentWorkspace?: Workspace
  layerNodes: {
    [key: string]: LayerNode[]
  };
  previewMapInfos: {
    [key: string]: MapInfo
  };
  zIndexMap: {
    [key: string]: number
  };
  coordinateEditor: CoordinateEditor;
  multiLineTextEditor: MultiLineTextEditor;
  siderVisible: boolean;
}

const initialState = projectAdapter.getInitialState({
  currentWorkspace: undefined,
  layerNodes: {},
  previewMapInfos: {},
  zIndexMap: {},
  coordinateEditor: {
    visible: false
  },
  multiLineTextEditor: {
    visible: false
  },
  siderVisible: true
} as State)

const projectSlice = createSlice({
  name: 'project',
  initialState,
  reducers,
  extraReducers (builder) {
    builder.addCase(createProject.rejected, (_, action) => {
      throw(action.error)
    }).addCase(editProject.rejected, (_, action) => {
      throw(action.error)
    }).addCase(deleteProject.rejected, (_, action) => {
      throw(action.error)
    })

    builder.addCase(TableNodeActions.create, (state, action) => {
      const { payload } = action
      const project = state.entities[payload.projectID]

      if (!project) return state

      if (project.tableNodes) {
        project.tableNodes.push(payload)
      } else {
        project.tableNodes = [payload]
      }
    }).addCase(TableNodeActions.update, (state, action) => {
      const { payload } = action
      const tableNode = state.entities[payload.projectID]
        ?.tableNodes?.find(tableNode => tableNode.id === payload.id)
      if (tableNode) {
        Object.assign(tableNode, payload.updatedFields)
      }
    }).addCase(TableNodeActions.move, (state, action) => {
      const { payload } = action
      const tableNode = state.entities[payload.projectID]
        ?.tableNodes?.find(tableNode => tableNode.id === payload.id)
      Object.assign(tableNode, { order: payload.order, parentID: payload.parentID })
    }).addCase(TableNodeActions.delete, (state, action) => {
      const { payload } = action
      const project = state.entities[payload.projectID]
      const layerNodes = state.layerNodes[payload.projectID]
      if (project?.recycleTableNodes === null) {
        project.recycleTableNodes = [payload.recycleTableNode]
      } else {
        project?.recycleTableNodes?.push(payload.recycleTableNode)
      }
      forEach(payload.ids, (id: number) => {
        const index = findIndex(project?.tableNodes, t => t.id === id)

        if (index !== -1) {
          project?.tableNodes?.splice(index, 1)
        }
      })
      forEach(payload.layerNodeIDs, id => {
        const index = findIndex(layerNodes, n => n.id === id)

        if (index !== -1) {
          layerNodes.splice(index, 1)
        }
      })
    }).addCase(TableNodeActions.deleteRecycle, (state, action) => {
      const { payload } = action
      const project = state.entities[payload.projectID]
      Object.assign(project, {
        ...project,
        recycleTableNodes: project?.recycleTableNodes?.filter(t => t.id !== payload.tableNodeID)
      })
    }).addCase(TableNodeActions.recoverRecycle, (state, action) => {
      const { payload } = action
      const project = state.entities[payload.projectID]
      if (!project) return state

      if (project.tableNodes) {
        project.tableNodes = [...project.tableNodes, ...payload.tableNodes]
      } else {
        project.tableNodes = payload.tableNodes
      }

      const layerNodes = state.layerNodes[payload.projectID]
      if (isEmpty(layerNodes)) {
        state.layerNodes[payload.projectID] = payload.layerNodes
      } else {
        forEach(payload.layerNodes, l => {
          layerNodes.push(l)
        })
      }
      Object.assign(project, {
        ...project,
        recycleTableNodes: project?.recycleTableNodes?.filter(t => t.id !== payload.recycleID)
      })
    })

    builder.addCase(LayerNodeActions.create, (state, action) => {
      const createPayload = action.payload
      const currentProjectLayerNodes: LayerNode[] = state.layerNodes[createPayload.projectID] || []
      state.layerNodes[createPayload.projectID] = [...currentProjectLayerNodes, createPayload]
    }).addCase(LayerNodeActions.update, (state, action) => {
      const updatePayload = action.payload
      const currentProjectLayerNodes: LayerNode[] = state.layerNodes[updatePayload.projectID]
      // TODO: 优化：保存图层的时候，地图上的POI会闪烁一下（预览 -> 初始效果 -> 预览（新的mapInfo））。期待：跳过2次重绘的过程。
      state.layerNodes[updatePayload.projectID] = currentProjectLayerNodes?.map(node => {
        if (node.id === updatePayload.layerNodeID) {
          return {
            ...node,
            ...updatePayload.updatedFields
          }
        }

        return node
      })
    }).addCase(LayerNodeActions.move, (state, action) => {
      const { payload } = action
      const layerNode = state.layerNodes[payload.projectID]?.find(node => node.id === payload.id)
      Object.assign(layerNode, { order: payload.order, parentID: payload.parentID })
      state.layerNodes = { ...state.layerNodes }
    }).addCase(deleteLayerNode, (state, action) => {
      const { payload } = action
      const deletedIds = payload.ids
      state.layerNodes[payload.projectID] = filter(
        state.layerNodes[payload.projectID],
        node => deletedIds.indexOf(node.id) <= -1
      )
    }).addCase(previewLayerNode, (state, action) => {
      const updatePayload = action.payload
      if (updatePayload.mapInfo) {
        state.previewMapInfos = {
          [updatePayload.id]: updatePayload.mapInfo
        }
      } else {
        delete state.previewMapInfos[updatePayload.id]
      }
    })
  }
})

export default projectSlice
export const actions = projectSlice.actions

export const projectSelectors = projectAdapter.getSelectors<RootState>(state => state.project)
