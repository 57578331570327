import { TableNode } from './mapTableNode'

export enum BaseMap {
  AmapStandard = 'AmapStandard',
  AmapLight = 'AmapLight',
  AmapDark = 'AmapDark',
  AmapSatellite = 'AmapSatellite',
  GoogleSatellite = 'GoogleSatellite',
  GooglePureSatellite = 'GooglePureSatellite'
}

export enum BaseMapStyle {
  AmapStandard = 'normal',
  AmapLight = 'whitesmoke',
  AmapDark = 'grey',
  AmapSatellite = 'normal',
  GoogleSatellite = 'normal',
  GooglePureSatellite = 'normal'
}

export interface MapConfig {
  center: string;
  isSet: boolean;
  zoomLevel: number;
  positioning: boolean;
  ranging: boolean;
  baseMap: BaseMap;
}

export interface Project {
  id: number,
  name: string,
  dataCount: number,
  createTime: string,
  updateTime: string,
  tableNodes?: TableNode[],
  recycleTableNodes?: RecycleTableNode[],
  roleInherited: boolean,
  mapConfig: MapConfig
}

export interface RecycleTableNode {
  id: number,
  name: string,
  type: 'table' | 'group'
  deletedAt: string,
  timeRemaining: number,
}

export interface RecycleProject {
  id: number,
  name: string,
  deletedAt: string,
  timeRemaining: number,
}

export interface ProjectPatch {
  projectID: number,
  updatedFields: Partial<Project>
}
