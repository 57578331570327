import React from 'react'
import noop from 'lodash/noop'
import Icon from 'src/components/common/Icon'
import styles from './actionCountAndClear.module.scss'

interface Props {
  count?: number
  onClear?: () => void
}

const ActionCountAndClear: React.FC<Props> = ({
  count = 0,
  onClear = noop
}) => {
  if (count) {
    return (
      <>
        <span className={styles.count}>{count}</span>
        <span
          className={styles.closeIcon}
          onClick={(ev: React.MouseEvent<HTMLElement>) => {
            ev.stopPropagation()
            onClear?.()
          }}
        >
          <Icon
            type={'closeFilled'}
          />
        </span>
      </>
    )
  } else {
    return <></>
  }
}

export default ActionCountAndClear
