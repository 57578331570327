import React from 'react'
import noop from 'lodash/noop'
import cx from 'clsx'
import { Button, Form, Tooltip } from 'antd'
import { RadioChangeEvent } from 'antd/lib/radio/interface'
import { QUMap as QUMapClass } from 'qumap/packages/core'
import useSelector from 'src/hooks/common/useSelector'
import OverlayTypeSelector from 'src/components/common/OverlayTypeSelector'
import SearchPOI, { SearchPOIType } from 'src/components/common/SearchPOI'
import Icon from 'src/components/common/Icon'
import { MARKER_EDITOR_HINT, POLYLINE_EDITOR_HINT, POLYGON_EDITOR_HINT } from 'src/constants/hint'
import styles from './overlayEditorPanel.module.scss'

interface Props {
  editorType?: string;
  drawerVisible?: boolean;
  isEditing?: boolean;
  isNewPOI?: boolean;
  onClose?: () => void;
  onReset?: () => void;
  onChange?: (value: string) => void;
  onFinish?: () => void;
  onSearchPOISelected?: (poi: SearchPOIType) => void;
  mapInstance?: React.RefObject<QUMapClass>;
}

const OverlayEditor: React.FC<Props> = ({
  mapInstance,
  drawerVisible = false,
  editorType = 'marker',
  isEditing = false,
  isNewPOI = true,
  onReset = noop,
  onClose = noop,
  onChange = noop,
  onFinish = noop,
  onSearchPOISelected = noop
}) => {
  const [form] = Form.useForm()
  const { isOpen } = useSelector(state => state.map.editor)
  function handleEditorTypeChange (e: RadioChangeEvent) {
    onChange(e.target.value)
  }

  function handleFormSubmit () {
    onFinish()
  }

  function handlePOISelect (poi: SearchPOIType) {
    onSearchPOISelected(poi)
  }

  return (
    <div className={cx(styles.wrap, { [styles.active]: isOpen }, drawerVisible ? styles.drawerOpen : '')}>
      <div className={styles.panel}>
        <div className={styles.header}>
          <h3 className={styles.title}>{isNewPOI ? '添加数据' : '修改位置'}</h3>
          <Icon type='close' onClick={onClose} className={styles.iconClose} />
        </div>
        {
          mapInstance && isNewPOI &&
          <div className={styles.searchPOI}>
            <SearchPOI mapInstance={mapInstance} onSelected={handlePOISelect} />
          </div>
        }
        <Form
          name="addPoi"
          form={form}
          onFinish={handleFormSubmit}
          className={styles.form}
        >
          <Form.Item
            noStyle
          >
            <OverlayTypeSelector
              onChange={handleEditorTypeChange} value={editorType}
              isEditing={isEditing} 
              disabled={isNewPOI && !isEditing} />
          </Form.Item>
          <Form.Item noStyle>
            <div className={styles.tip}>
              {
                editorType === 'marker' && MARKER_EDITOR_HINT
              }
              {
                editorType === 'polyline' && POLYLINE_EDITOR_HINT
              }
              {
                editorType === 'polygon' && POLYGON_EDITOR_HINT
              }
            </div>
          </Form.Item>
          {
            isEditing &&
            <div className={styles.actions}>
              <Tooltip title="清空已绘制图形">
                <div onClick={onReset} className={styles.trashButton}>
                  <Icon type="trash"/>
                </div>
              </Tooltip>
              <Button
                type="primary"
                htmlType="submit"
                size="large"
                className={styles.finishButton}
              >
              绘制完成
              </Button>
            </div>
          }
        </Form>
      </div>
    </div>
  )
}

export default OverlayEditor
