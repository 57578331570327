import React from 'react'
import { Link } from 'react-router-dom'
import notFound from 'src/assets/images/404.png'
import Header from 'src/components/common/Header'
import useSelector from 'src/hooks/common/useSelector'
import { useDocumentTitle } from 'src/hooks/common/useDocumentTitle'
import styles from './notFound.module.scss'

const NotFound: React.FC = () => {
  const username = useSelector(state => state.user.username)
  const isAuth = useSelector(state => state.user.isAuth)
  useDocumentTitle('404')

  return (
    <div className={styles.page}>
      {isAuth && <Header username={username} /> }
      <div className={styles.wrap}>
        <div className={styles.content}>
          <img className={styles.img} src={notFound} alt="404" />
          <div className={styles.message}>
            <p>未找到相关页面，请检查网址是否正确。</p>
            <Link to="/">回到首页</Link>
          </div>
        </div>
      </div>
    </div>
  )
}

export default NotFound
