import React, { useEffect, useImperativeHandle, useState } from 'react'
import map from 'lodash/map'
import get from 'lodash/get'
import trim from 'lodash/trim'
import { Switch, Dropdown, Menu, Form, Select } from 'antd'
import { FormInstance } from 'antd/lib/form/hooks/useForm'
import { MenuClickEventHandler } from 'rc-menu/lib/interface'
import cx from 'clsx'
import Icon, { IconType } from 'src/components/common/Icon'
import FieldSelector from 'src/components/common/FieldSelector'
import { FORMULA_OPERATORS } from 'src/constants/formula'
import { checkFormula } from 'src/state/formula/actions'
import useDispatch from 'src/hooks/common/useDispatch'
import FormulaExpressionEditor from 'src/components/field/Formula/FormulaExpressionEditor'
import { NUMBER_FORMATS, NUMBER_PRECISIONS } from 'src/constants/field'
import styles from './formulaConfig.module.scss'

interface Props {
  formula?: string;
  form?: React.Ref<FormInstance>;
  config?: Record<string, unknown>;
}

const { Option } = Select

export const FormulaConfig: React.FC<Props> = (props) => {
  const dispatch = useDispatch()
  const [innerForm] = Form.useForm()
  const { formula = '', form, config } = props
  const [isAdvance, setIsAdvance] = useState(!!formula)
  const [formulaExpression, setFormulaExpression] = useState(formula)
  const [leftOperand, setLeftOperand] = useState('')
  const [rightOperand, setRightOperand] = useState('')
  const [operator, setOperator] = useState('plus')
  const [showPrecision, setShowPrecision] = useState(config?.expectedType === 'number')

  useEffect(() => {
    let newFormula: string
    if (isAdvance) {
      newFormula = formulaExpression
    } else {
      newFormula = `${leftOperand}${FORMULA_OPERATORS[operator]}${rightOperand}`
      if (newFormula.indexOf('undefined') > -1) {
        newFormula = ''
      }
    }
    innerForm.setFieldsValue({
      formula: newFormula
    })
  }, [isAdvance, form, formulaExpression, leftOperand, rightOperand, operator, innerForm])

  const initialValues = {
    formula,
    numberFormat: config?.numberFormat ?? NUMBER_FORMATS[0].value,
    numberPrecision: config?.numberPrecision ?? NUMBER_PRECISIONS[0].value,
    expectedType: config?.expectedType
  }

  useImperativeHandle(form, () => innerForm)

  const handleSwitchChange = (checked: boolean) => {
    setIsAdvance(checked)
  }

  const handleOperatorClick: MenuClickEventHandler = ({ key }) => {
    setOperator(key as string)
  }

  function handleValuesChange (changedValues: Record<string, string>, values: Record<string, string>) {
    if (values.expectedType !== 'number') {
      setShowPrecision(false)
    } else {
      setShowPrecision(true)
    }
  }

  const renderOperators = () => {
    return (
      <Menu onClick={handleOperatorClick}>
        <Menu.Item key={'plus'}><Icon type={'plusOperator'} /></Menu.Item>
        <Menu.Item key={'minus'}><Icon type={'minusOperator'} /></Menu.Item>
        <Menu.Item key={'multiply'}><Icon type={'multiplyOperator'} /></Menu.Item>
        <Menu.Item key={'divide'}><Icon type={'divideOperator'} /></Menu.Item>
      </Menu>
    )
  }

  return (
    <Form
      name={'formula'}
      form={innerForm}
      initialValues={initialValues}
      onValuesChange={handleValuesChange}
      requiredMark={false}
      colon={false}
      labelCol={{ span: 6 }}
      labelAlign="left"
      className={styles.formulaForm}
    >
      <Form.Item
        name="formula"
        validateFirst
        rules={[
          {
            async validator (_, value) {
              if (!isAdvance && (!leftOperand|| !rightOperand)) return Promise.reject()
              return Promise.resolve()
            },
            message: '请选择需要计算的列'
          },
          {
            async validator (_, value) {
              if (isAdvance && !trim(value)) return Promise.reject()
              return Promise.resolve()
            },
            message: '请输入公式'
          },
          {
            async validator (_, value) {
              const data = await dispatch(checkFormula(value))
              const valid = get(data, 'payload.message') === 'OK'

              if (valid) {
                return Promise.resolve()
              } else {
                return Promise.reject()
              }
            },
            message: '公式不合法'
          }
        ]}
      >
        <>
          <div className={styles.advance}>
            公式高级编辑器
            <div className={styles.switch}>
              <span className={cx(styles.switchText, isAdvance ? styles.isAdvance : '')}>
                {isAdvance ? '开启' : '关闭'}
              </span>
              <Switch size={'small'} defaultChecked={isAdvance} onChange={handleSwitchChange} />
            </div>
          </div>
          {!isAdvance && <div className={styles.formulaItem}>
            <div className={styles.column}>
              <FieldSelector onChange={val => setLeftOperand(`$c_${val}`)} />
            </div>
            <div className={styles.operator}>
              <Dropdown placement="bottomCenter" overlay={renderOperators()}>
                <Icon type={`${operator}Operator` as IconType} />
              </Dropdown>
            </div>
            <div className={styles.column}>
              <FieldSelector onChange={val => setRightOperand(`$c_${val}`)} />
            </div>
          </div>}
          {isAdvance && <FormulaExpressionEditor formula={formula} onChange={val => setFormulaExpression(val)} />}
        </>
      </Form.Item>
      <Form.Item
        name="expectedType"
        label="输出类型"
        rules={[{ required: true, message: '请选择输出类型' }]}
      >
        <Select placeholder="请选择输出类型">
          <Option value="number">数字</Option>
          <Option value="singleLineText">文本</Option>
          <Option value="coordinate">坐标</Option>
        </Select>
      </Form.Item>
      {
        showPrecision && <>
          <Form.Item
            label="格式化"
            name="numberFormat"
          >
            <Select>
              {
                map(NUMBER_FORMATS, o => <Select.Option key={o.value} value={o.value}>{o.label}</Select.Option>)
              }
            </Select>
          </Form.Item>
          <Form.Item
            name="numberPrecision"
            label="精度"
          >
            <Select>
              {
                map(NUMBER_PRECISIONS, o => <Option key={o.value} value={o.value}>{o.label}</Option>)
              }
            </Select>
          </Form.Item>
        </>
      }
    </Form>
  )
}
