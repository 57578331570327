import React, { useState, useEffect } from 'react'
import cx from 'clsx'
import { Button, Dropdown, Menu, Modal } from 'antd'
import { ExclamationCircleOutlined } from '@ant-design/icons'
import keyBy from 'lodash/keyBy'
import mapValues from 'lodash/mapValues'
import cloneDeep from 'lodash/cloneDeep'
import type { DialogStateReturn as Dialog } from 'src/hooks/common/useDialog'
import type { TypeOptions } from 'src/typings/tableNode'
import Icon from 'src/components/common/Icon'
import SmallDivider from 'src/components/common/SmallDivider'
import { useDeleteConfirmDialog, useConfirmDialog } from 'src/hooks/common/useDialog'
import TableGrid from './TableGrid'
import styles from './tableEditor.module.scss'

type Columns = TypeOptions['columns']

interface HeaderProps {
  onClose?: () => void;
  onDelete?: () => void;
  isFullScreen: boolean;
  onFullScreenToggle: (isFullScreen: boolean) => void;
}

const Header: React.FC<HeaderProps> = ({ onClose, onDelete, children, isFullScreen, onFullScreenToggle }) => {
  const menu = <Menu>
    <Menu.Item danger onClick={onDelete}>删除表格</Menu.Item>
  </Menu>
  return (
    <header className={styles.header}>
      <div className={styles.title}>
        {children}
      </div>
      <div className={styles.headerActions}>
        <div className={styles.fullScreenIcon} onClick={() => onFullScreenToggle(!isFullScreen)}>
          {isFullScreen ? <Icon type="shrink" /> : <Icon type="expandArrow" />}
        </div>
        <Dropdown overlay={menu} placement="bottomRight" trigger={['click']}>
          <Icon type={'more'} className={styles.icon} />
        </Dropdown>
        <SmallDivider mh15 className={styles.divider} />
        <Icon type="close" onClick={onClose} className={styles.icon} />
      </div>
    </header>
  )
}

interface Props {
  dialog: Dialog;
  onCancel?: () => void;
  onOk: (rowsData?: Record<string, unknown>[] | null) => void;
  columns: Columns;
  rows?: Record<string, unknown>[] | null;
}

function generateInitialRows (columns: Columns) {
  const emptyRow = mapValues(keyBy(columns, c => c.id), () => null)
  return [emptyRow, { ...emptyRow }]
}

export const TableEditorModal: React.FC<Props> = ({
  dialog,
  onOk,
  columns,
  rows,
  onCancel
}) => {
  const [internalRows, setInternalRows] = useState(cloneDeep(rows) ?? generateInitialRows(columns))
  const [isFullScreen, setIsFullScreen] = useState(false)
  const [selectedRowIdxs, setSelectedRowIdxs] = useState<number[]>([])
  const [initialRows, setInitialRows] = useState(cloneDeep(internalRows))
  const deleteConfirmDialog = useDeleteConfirmDialog({
    messageIds: {
      title: 'table.delete.tableField.title',
      content: 'table.delete.tableField.content'
    }
  })
  const confirmDialog = useConfirmDialog({
    title: '提示',
    content: '您已经对此表格进行了编辑，是否需要保存？',
    okText: '保存',
    cancelText: '丢弃',
    icon: <ExclamationCircleOutlined />,
    closable: false
  })

  function handleOk () {
    onOk(internalRows)
  }

  useEffect(() => {
    if (dialog.visible) {
      const updatedRows = cloneDeep(rows) ?? generateInitialRows(columns)
      setInternalRows(updatedRows)
      setInitialRows(cloneDeep(updatedRows))
    }
  }, [rows, columns, dialog])

  async function handleClose () {
    if (JSON.stringify(initialRows) !== JSON.stringify(internalRows)) {
      confirmDialog().then(() => {
        handleOk()
      }).catch(() => {
        onCancel?.()
      })
    } else {
      onCancel?.()
    }
  }

  function handleDelete () {
    deleteConfirmDialog().then(() => {
      dialog.close()
      onOk(null)
    })
  }

  function handleRemoveSelectedRow () {
    setInternalRows(internalRows.filter((_, index) => !(selectedRowIdxs.includes(index))))
    setSelectedRowIdxs([])
  }

  function handleSelectionChange (rowIndexs: number[]) {
    setSelectedRowIdxs(rowIndexs)
  }

  function handleFullScreenToggle (fullScreen: boolean) {
    setIsFullScreen(fullScreen)
  }

  return (
    <Modal
      className={cx(styles.modal, { [styles.fullScreen]: isFullScreen })}
      visible={dialog.visible}
      width={isFullScreen ? '100%' : 800}
      closable={false}
      title={
        <Header
          onClose={handleClose}
          onDelete={handleDelete}
          isFullScreen={isFullScreen}
          onFullScreenToggle={handleFullScreenToggle}
        >
          {
            selectedRowIdxs.length ?
              <div className={styles.selection}>
                <div className={styles.selectionInfo}>{`已选中 ${selectedRowIdxs?.length} 行`}</div>
                <Button
                  size="small" type="text" onClick={handleRemoveSelectedRow} danger
                  className={styles.deleteSelectedRows}>
                  <Icon type="recycleBin" />删除
                </Button>
              </div> : undefined
          }
        </Header>
      }
      onCancel={handleClose}
      onOk={handleOk}
      destroyOnClose={true}
      maskClosable={false}
    >
      <div className={styles.content}>
        <TableGrid
          cellEditable
          columnData={columns}
          rowData={internalRows}
          onRowDataChange={rowData => setInternalRows(rowData)}
          onSelectionChange={handleSelectionChange}
          className={styles.gridInConfig}
        />
      </div>
    </Modal>
  )
}

export default TableEditorModal
