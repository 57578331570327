import React from 'react'
import cx from 'clsx'
import type { Cell, Column } from 'src/typings/tableNode'
import { DatetimeRenderer } from 'src/components/field/Datetime'
import { NumberRenderer } from 'src/components/field/Number'
import { SelectRenderer } from 'src/components/field/Select'
import { MultiLineTextRenderer } from 'src/components/field/MultiLineText'
import { AttachmentRenderer } from 'src/components/field/Attachment'
import { FormulaRenderer } from 'src/components/field/Formula'
import { SingleLineTextRenderer } from 'src/components/field/SingleLineText'
import { TableRenderer } from 'src/components/field/Table'
import { CheckboxRenderer } from 'src/components/field/Checkbox'
import useDispatch from 'src/hooks/common/useDispatch'
import { updateCells } from 'src/state/tableNode/actions'

import type { Props as DatetimeRendererProps } from 'src/components/field/Datetime/DatetimeRenderer'
import type { Props as NumberRendererProps } from 'src/components/field/Number/NumberRenderer'
import type { Props as SelectRendererProps } from 'src/components/field/Select/SelectRenderer'
import type { Props as TableRendererProps } from 'src/components/field/Table/TableRenderer'
import type { Props as CheckboxRendererProps } from 'src/components/field/Checkbox/CheckboxRenderer'
import type { FileParams } from 'src/api/tablenodes'
import { CoordinateRenderer } from 'src/components/field/Coordinate'
import styles from './fieldRender.module.scss'

export interface Item extends Cell {
  typeOptions?: Column['typeOptions'];
  isPlain?: boolean;
}

interface FieldRenderProps {
  item: Item;
  className?: string;
  withTooltip?: boolean;
  onClick?: (e: React.MouseEvent) => void;
}

const FieldRender: React.FC<FieldRenderProps> = (props) => {
  const { item, className, onClick, withTooltip } = props
  const isSelect = item.type === 'singleChoice' || item.type === 'multiChoice'
  const dispatch = useDispatch()

  const saveField = (val: unknown) => {
    if (item.tableNodeId && item.rowId && val !== undefined) {
      dispatch(updateCells({
        id: item.tableNodeId,
        rowId: item.rowId,
        cells: {
          [item.id]: val
        }
      }))
    }
  }

  function handleCheckboxChange (v: true | null) {
    saveField(v)
  }

  const renderFieldRender = () => {
    switch (item.type) {
    case 'coordinate':
      return <CoordinateRenderer value={item.value as string} isPlain={item.isPlain} />
    case 'multiLineText':
      return <MultiLineTextRenderer value={item.value as string} isPlain={item.isPlain} />
    case 'datetime':
      return <DatetimeRenderer
        typeOptions={item.typeOptions as DatetimeRendererProps['typeOptions']}
        value={item.value as string}
      />
    case 'number':
      return <NumberRenderer
        typeOptions={item.typeOptions as NumberRendererProps['typeOptions']}
        value={item.value as string}
      />
    case 'singleChoice':
    case 'multiChoice':
      return <SelectRenderer
        typeOptions={item.typeOptions as SelectRendererProps['typeOptions']}
        value={item.value as string | string[]}
      />
    case 'attachment':
      return <AttachmentRenderer value={item.value as FileParams[]} inDetailPanel={true} />
    case 'formula':
      return <FormulaRenderer value={item.value as string} typeOptions={item.typeOptions} />
    case 'singleLineText':
      return <SingleLineTextRenderer value={item.value as string} />
    case 'table':
      return <TableRenderer 
        tableNodeId={item.tableNodeId}
        columnId={item.id}
        rowId={item.rowId}
        name={item.name}
        value={item.value as TableRendererProps['value']} 
        typeOptions={item.typeOptions as TableRendererProps['typeOptions']} 
      />
    case 'boolean':
      return <CheckboxRenderer 
        value={item.value as CheckboxRendererProps['value']} 
        onChange={handleCheckboxChange}
      />
    default:
      return (
        <div>
          {item?.value as string}
        </div>
      )
    }
  }
  return (
    <div
      className={cx(styles.block, { [styles.select]: isSelect }, className)} onClick={onClick}
      title={withTooltip ? item?.value as string : ''}>
      {renderFieldRender()}
    </div>
  )
}

export default FieldRender
