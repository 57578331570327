import { useState, useEffect, useRef, useCallback } from 'react'
import { message, Modal } from 'antd'
import storage from 'store2'
import uniq from 'lodash/uniq'
import find from 'lodash/find'
import first from 'lodash/first'
import forEach from 'lodash/forEach'
import split from 'lodash/split'
import map from 'lodash/map'
import join from 'lodash/join'
import filter from 'lodash/filter'
import trim from 'lodash/trim'
import some from 'lodash/some'
import size from 'lodash/size'
import compact from 'lodash/compact'
import cloneDeep from 'lodash/cloneDeep'
import includes from 'lodash/includes'
import take from 'lodash/take'
import range from 'lodash/range'
import flowRight from 'lodash/flowRight'
import isEmpty from 'lodash/isEmpty'
import isArray from 'lodash/isArray'
import min from 'lodash/min'
import max from 'lodash/max'
import isNumber from 'lodash/isNumber'
import toNumber from 'lodash/toNumber'
import type {
  GridApi,
  CellRange
} from 'ag-grid-community'
import type { ProcessCellForExportParams } from 'ag-grid-community/dist/lib/interfaces/exportParams'
import type { PasteStartEvent } from 'ag-grid-community/dist/lib/events'
import useDispatch from 'src/hooks/common/useDispatch'
import type { Column, PasteCellsParams } from 'src/typings/tableNode'
import {
  deleteCells,
  pasteCells
} from 'src/state/tableNode/actions'
import { tableNodeSelectors } from 'src/state/tableNode/slice'
import useSelector from 'src/hooks/common/useSelector'
import { padNumberByPrecisionWithFormat } from 'src/utils/format'
import { COPY_CACHE, COPY_CACHE_RAW } from 'src/constants/storage'
import { ADD_ROW_ID, ADD_COLUMN_ID } from 'src/constants/grid'

type CopyCutActionType = 'cut' | 'copy'

interface UseCopyPasteReturn {
  processCellForClipboard: (params: ProcessCellForExportParams) => void;
  processCellFromClipboard: (params: ProcessCellForExportParams) => void;
  onPasteStart: (e: PasteStartEvent) => void;
  handleCopy: (gridApi?: GridApi) => void;
  handleCut: (gridApi?: GridApi) => void;
}

const MAX_SUPPORT_CELL_SIZE = 1000

function useCopyPaste (tableNodeId: number): UseCopyPasteReturn {
  const dispatch = useDispatch()
  const tableNode = useSelector(state => tableNodeSelectors.selectById(state, tableNodeId))
  const [destColumnIDs, setDestColumnIDs] = useState<string[]>([])
  const [destRowIDs, setDestRowIDs] = useState<string[]>([])
  const [source, setSource] = useState<PasteCellsParams['source']>([])
  const [sourceColumns, setSourceColumns] = useState<PasteCellsParams['sourceColumns']>([])
  const action = useRef<CopyCutActionType>('copy')
  const columns = useRef(tableNode?.columns)

  function processCellForClipboard (params: ProcessCellForExportParams) {
    const colId = params.column.getColId()
    const column = find(columns.current, c => c.id === colId)
    const value = params.value.realValue

    const type = column?.type
    const typeOptions = column?.typeOptions

    if (type === 'singleChoice' || type === 'multiChoice') {
      return join(map(split(value, ','), v => typeOptions?.choices.find(c => c.id === v)?.name ?? ''))
    }
    if (type === 'attachment') {
      return join(map(value, v => v.url))
    }

    if (type === 'multiLineText') {
      return trim(JSON.stringify(value), '"')
    }

    if (type === 'table') {
      return JSON.stringify(value)
    }

    if (type === 'boolean') {
      return value ? '是' : '否'
    }

    if (type === 'number') {
      return isNumber(value) ? padNumberByPrecisionWithFormat(
        value,
        typeOptions?.precision ?? 0,
        typeOptions?.format
      ) : value
    }

    if (type === 'formula' && typeOptions?.expectedType === 'number') {
      return padNumberByPrecisionWithFormat(
        toNumber(value),
        typeOptions?.numberPrecision ?? 0,
        typeOptions?.numberFormat
      )
    }

    return value ?? ''
  }

  function processCellFromClipboard (params: ProcessCellForExportParams) {
    const colId = params.column.getColId()
    const rowId = params.node?.id
    setDestColumnIDs(prev => [...prev, colId])
    setDestRowIDs(prev => [...prev, rowId as string])
  }

  async function onPasteStart (e: PasteStartEvent) {
    const clipboardText = await navigator.clipboard.readText()
    const copySourceCacheRaw = storage.get(COPY_CACHE_RAW)
    const copySourceCache: PasteCellsParams = storage.get(COPY_CACHE)
    const isPasteFromOutside = JSON.stringify(copySourceCacheRaw.text) !== JSON.stringify(clipboardText)

    const cellRange = first(e.api.getCellRanges())
    let columnsToExtend = 0
    let rowsToExtend = 0
    let paste = () => {
      dispatch(pasteCells({
        id: tableNodeId,
        params: copySourceCache
      }))
    }

    try {
      if (isPasteFromOutside) {

        copySourceCache.destColumnIDs = copySourceCache.destColumnIDs.filter(colId => 
          e.api.getColumnDef(colId)?.headerComponentParams.type !== 'attachment')
        if (size(copySourceCache.destColumnIDs) === 0 || size(copySourceCache.destRowIDs) === 0) {
          return
        }
        const sourceData: PasteCellsParams['source'] = []
        const rows = split(clipboardText, '\n')
        forEach(rows, r => {
          sourceData.push(map(split(r, '\t'), trim))
        })
        let params: PasteCellsParams = {
          ...copySourceCache,
          sourceColumns: [],
          source: sourceData
        }

        if (isCellRangeSelected(cellRange)) {
          params = reShapePasteCellsParams(params)
        } else {
          columnsToExtend = size(sourceData[0]) - size(copySourceCache.destColumnIDs)
          rowsToExtend = size(sourceData) - size(copySourceCache.destRowIDs)
        }

        paste = () => {
          dispatch(pasteCells({
            id: tableNodeId,
            params
          }))
        }
      } else {
        const destColumnLength = size(copySourceCache.destColumnIDs)
        const sourceColumnLength = size(copySourceCache.sourceColumns)
        const destRowLength = size(copySourceCache.destRowIDs)
        const sourceRowLength = size(copySourceCache.source)
        let params = copySourceCache

        if (isCellRangeSelected(cellRange)) {
          params = reShapePasteCellsParams(copySourceCache)
        } else {
          columnsToExtend = sourceColumnLength - destColumnLength
          rowsToExtend = sourceRowLength - destRowLength
        }

        paste = () => {
          dispatch(pasteCells({
            id: tableNodeId,
            params: formatDataOnPaste(params, e.api)
          }))
        }
      }

      if (columnsToExtend > 0 || rowsToExtend > 0) {
        showConfirmModal(paste, columnsToExtend, rowsToExtend)
      } else {
        paste()
        // move it to ws
        message.success('粘贴成功')
      }
    } finally {
      setDestColumnIDs([])
      setDestRowIDs([])
    }
  }

  const handleCopy = useCallback(async (gridApi?: GridApi, cut?: boolean) => {
    const cellRanges = gridApi?.getCellRanges()
    const editingCells = gridApi?.getEditingCells()
    const isEditing = size(editingCells) > 0
    const cellRange = first(cellRanges)
    
    const isFormulaCellsSelected = some(cellRange?.columns, c => {
      return c.getColDef()?.cellEditorParams?.type === 'formula'
    })

    if(isFormulaCellsSelected && cut) {
      return message.info('公式字段不支持剪切')
    }

    if (cellRange && !isEditing) {
      const startRowIndex = cellRange.startRow?.rowIndex ?? 0
      const endRowIndex = cellRange.endRow?.rowIndex ?? 0
      const minRowIndex = min([startRowIndex, endRowIndex]) ?? 0
      const maxRowIndex = max([startRowIndex, endRowIndex]) ?? 0
      const columns = getColumnsOnCopy(cellRange, tableNode?.columns)
      const colIds = map(columns, c => c.id)
      const cellSize = ((maxRowIndex - minRowIndex) + 1) * size(colIds)

      if (cellSize > MAX_SUPPORT_CELL_SIZE) {
        return message.info('超出最大单元格数量')
      }

      const sourceData = getDataOnCopy({
        minRowIndex,
        maxRowIndex,
        gridApi,
        colIds
      })
      action.current = cut ? 'cut' : 'copy'
      setSource(sourceData)
      setSourceColumns(columns)
      const clipboardText = await navigator.clipboard.readText()
      try {
        storage.set(COPY_CACHE_RAW, {
          text: clipboardText,
          pathname: window.location.pathname
        })
      } catch {
        message.info('复制内容过多，可能会导致在表格内的粘贴功能失效')
      }

      if (cut) {
        const cutSource = {
          destColumnIDs: colIds,
          destRowIDs: compact(map(range(minRowIndex, maxRowIndex + 1), i => gridApi?.getDisplayedRowAtIndex(i)?.id))
        }
        dispatch(deleteCells({
          id: tableNodeId,
          params: cutSource
        }))
      }
    }
  }, [dispatch, tableNode?.columns, tableNodeId])

  function handleCut (gridApi?: GridApi) {
    handleCopy(gridApi, true)
  }

  useUpdateDestDataEffect([destRowIDs, destColumnIDs])

  useUpdateSourceDataEffect([source, sourceColumns])

  useShowMessageEffect([source, action])

  useEffect(() => {
    columns.current = tableNode?.columns
  }, [tableNode?.columns])

  return {
    processCellForClipboard,
    processCellFromClipboard,
    onPasteStart,
    handleCopy,
    handleCut
  }
}

type UseUpdateDestDataEffectDeps = [string[], string[]]

function useUpdateDestDataEffect ([destRowIDs, destColumnIDs]: UseUpdateDestDataEffectDeps) {
  useEffect(() => {
    const destData = {
      destColumnIDs: filter(uniq(destColumnIDs), id => id !== ADD_COLUMN_ID),
      destRowIDs: filter(uniq(destRowIDs), id => id !== ADD_ROW_ID)
    }
    storage.set(COPY_CACHE, {
      ...storage.get(COPY_CACHE),
      ...destData
    })
  }, [destColumnIDs, destRowIDs])
}

type UseUpdateSourceDataEffectDeps = [PasteCellsParams['source'], PasteCellsParams['sourceColumns']]

function useUpdateSourceDataEffect ([source, sourceColumns]: UseUpdateSourceDataEffectDeps) {
  useEffect(() => {
    if (source.length && sourceColumns.length) {
      const sourceData = {
        sourceColumns,
        source
      }
      storage.set(COPY_CACHE, {
        ...storage.get(COPY_CACHE),
        ...sourceData
      })
    }
  }, [source, sourceColumns])
}

type UseShowMessageEffectDeps = [PasteCellsParams['source'], React.MutableRefObject<CopyCutActionType>]

function useShowMessageEffect ([source, action]: UseShowMessageEffectDeps) {
  useEffect(() => {
    if (source.length && source[0].length) {
      const count = source.length * source[0].length
      message.info(`已${action.current === 'copy' ? '复制' : '剪切'} ${count} 个单元格`)
    }
  }, [source, action])
}

function getColumnsOnCopy (cellRange: CellRange, columns?: Column[]) {
  const sourceColumns: PasteCellsParams['sourceColumns'] = []

  forEach(cellRange.columns, c => {
    const colId = c.getColId()
    const column = find(columns, c => c.id === colId)
    if (column) {
      sourceColumns.push(column)
    }
  })

  return sourceColumns
}

function getDataOnCopy ({
  minRowIndex,
  maxRowIndex,
  gridApi,
  colIds
}: {
  minRowIndex: number;
  maxRowIndex: number;
  gridApi?: GridApi;
  colIds: string[];
}) {
  const sourceData = []
  for (let index = minRowIndex; index <= maxRowIndex; index++) {
    const row = gridApi?.getDisplayedRowAtIndex(index)
    const values = map(colIds, colId => row?.data[colId] ?? '')
    sourceData.push(values)
  }

  return sourceData
}

function formatDataOnPaste (
  copySource: PasteCellsParams,
  gridApi?: GridApi
) {
  const { source, sourceColumns, destColumnIDs } = copySource
  const updatedSource = cloneDeep(source)
  const updatedSourceColumns = cloneDeep(sourceColumns)

  forEach(destColumnIDs, (colId, index) => {
    const destColumn = gridApi?.getColumnDef(colId)
    const { type, typeOptions } = destColumn?.headerComponentParams
    const sourceColumn = sourceColumns[index]
    const pasteToTextCell = type === 'singleLineText' || type === 'multiLineText'
    const copyFromNumber = sourceColumn.type === 'number' || sourceColumn.type === 'formula'

    // TODO: better to put this logic in backend
    if (copyFromNumber && pasteToTextCell) {
      forEach(updatedSource, (row) => {
      // TODO: extract formatters
        if (sourceColumn.type === 'number') {
          row[index] = padNumberByPrecisionWithFormat(
            toNumber(row[index]),
            sourceColumn.typeOptions?.precision ?? 0,
            sourceColumn.typeOptions?.format
          ) ?? null
        }

        if (sourceColumn.type === 'formula' && sourceColumn.typeOptions?.expectedType === 'number') {
          row[index] = padNumberByPrecisionWithFormat(
            toNumber(row[index]),
            sourceColumn.typeOptions?.numberPrecision ?? 0,
            sourceColumn.typeOptions?.numberFormat
          ) ?? null
        }
      })
      updatedSourceColumns[index].type = type
      updatedSourceColumns[index].typeOptions = typeOptions
    }
  })

  return Object.assign(copySource, {
    source: updatedSource,
    sourceColumns: updatedSourceColumns
  })
}

function showConfirmModal (onPaste: () => void, columnsToExtend: number, rowsToExtend: number): void {
  const columnText = columnsToExtend > 0 ? `${columnsToExtend}列` : ''
  const rowText = rowsToExtend > 0 ? `${rowsToExtend}行` : ''
  Modal.confirm({
    title: '您要拓展这张表格吗？',
    icon: null,
    content: `如果想成功显示您粘贴的数据，我们需要添加${columnText}${rowText}。`,
    okText: '确定',
    cancelText: '取消',
    onOk () {
      onPaste()
    }
  })
}

function repeatArray<T> (arr: T[], length: number): T[] {
  const repeatedArr = cloneDeep(arr)

  if (!isArray(arr)) return []

  if (length === 0 || isEmpty(arr)) return arr

  while (size(repeatedArr) < length) {
    repeatedArr.push(...arr)
  }

  return take(repeatedArr, length)
}

function isCellRangeSelected (cellRange?: CellRange): boolean {
  if (!cellRange) return false

  const startRowIndex = cellRange.startRow?.rowIndex ?? 0
  const endRowIndex = cellRange.endRow?.rowIndex ?? 0
  return size(cellRange?.columns) > 1 || (Math.abs(endRowIndex - startRowIndex) > 0)
}

function extendRows (copySource: PasteCellsParams): PasteCellsParams {
  const { source, destRowIDs } = copySource
  const actualSource = repeatArray(source, size(destRowIDs))

  return {
    ...copySource,
    source: actualSource
  }
}

function extendColumns (copySource: PasteCellsParams): PasteCellsParams {
  const { source, destColumnIDs, sourceColumns, destRowIDs } = copySource
  const actualSourceColumns = repeatArray(sourceColumns, size(destColumnIDs))
  const actualSource = take(map(range(size(destColumnIDs)), index => {
    return repeatArray(source[index] ?? source[0], size(destColumnIDs))
  }), size(destRowIDs))

  return {
    ...copySource,
    source: actualSource,
    sourceColumns: actualSourceColumns
  }
}

function reduceRows (copySource: PasteCellsParams): PasteCellsParams {
  const { source, destRowIDs } = copySource
  const actualSource = take(source, size(destRowIDs))

  return {
    ...copySource,
    source: actualSource
  }
}

function reduceColumns (copySource: PasteCellsParams): PasteCellsParams {
  const { source, sourceColumns, destColumnIDs } = copySource

  const actualSourceColumns = filter(sourceColumns, c => includes(destColumnIDs, c.id))
  const actualSource = map(source, r => {
    return take(r, size(destColumnIDs))
  })

  return {
    ...copySource,
    source: actualSource,
    sourceColumns: actualSourceColumns
  }
}

function reShapePasteCellsParams (copySource: PasteCellsParams): PasteCellsParams {
  const destColumnLength = size(copySource.destColumnIDs)
  const sourceColumnLength = size(copySource.sourceColumns)
  const destRowLength = size(copySource.destRowIDs)
  const sourceRowLength = size(copySource.source)
  const conditions = {
    shouldReduceColumns: sourceColumnLength > destColumnLength,
    shouldReduceRows: sourceRowLength > destRowLength,
    shouldExtendColumns: sourceColumnLength < destColumnLength,
    shouldExtendRows: sourceRowLength < destRowLength
  }

  return flowRight(
    (copySource: PasteCellsParams) => conditions.shouldReduceColumns ? reduceColumns(copySource) : copySource,
    (copySource: PasteCellsParams) => conditions.shouldReduceRows ? reduceRows(copySource) : copySource,
    (copySource: PasteCellsParams) => conditions.shouldExtendColumns ? extendColumns(copySource) : copySource,
    (copySource: PasteCellsParams) => conditions.shouldExtendRows ? extendRows(copySource) : copySource
  )(copySource)
}

export default useCopyPaste
