import find from 'lodash/find'
import { 
  updateTableNodeName,
  UpdateTableNodeNamePayload,
  undoUpdateTableNodeName, 
  redoUpdateTableNodeName
} from 'src/state/tableNode/actions'
import { projectSelectors } from 'src/state/project/slice'
import type { UndoRedo } from 'src/features/undoRedo/undoRedo'
import type { RootState } from 'src/store'

const updateTableNodeNameUndoRedo = {
  action: updateTableNodeName,
  undoAction: undoUpdateTableNodeName,
  redoAction: redoUpdateTableNodeName,
  getUndoPayload: (arg: UpdateTableNodeNamePayload, state: RootState) => {
    const { projectID, id } = arg
    const project = projectSelectors.selectById(state, projectID)

    return {
      id,
      projectID,
      name: find(project?.tableNodes, t => t.id === id)?.name
    }
  },
  getRedoPayload: (arg: UpdateTableNodeNamePayload) => {
    return arg
  }
} as UndoRedo

export default updateTableNodeNameUndoRedo