import React from 'react'
import cx from 'clsx'

import useDispatch from 'src/hooks/common/useDispatch'
import { actions as projectActions } from 'src/state/project/slice'
import useSelector from 'src/hooks/common/useSelector'
import ActionButton from 'src/components/project/ActionButton'
import styles from './projectActions.module.scss'

interface Props {
  extra?: React.ReactNode;
}

export const ProjectActions: React.FC<Props> = ({
  children,
  extra
}) => {
  const dispatch = useDispatch()
  const siderVisible = useSelector(state => state.project.siderVisible)

  const handleToggleSider = () => {
    dispatch(projectActions.updateSiderVisible(!siderVisible))
  }

  return (
    <div className={styles.actions}>
      <div className={styles.actionsLeft}>
        <div className={cx(styles.catalog, !siderVisible ? styles.collapsed : '')}>
          <ActionButton
            onClick={handleToggleSider}
            icontype={'catalog'}
            iconbutton={!siderVisible}
            active={!siderVisible}
          >
            {siderVisible && <span className={styles.catalogText}>目录</span>}
          </ActionButton>
        </div>
        {children}
      </div>
      <div className={styles.actionRight}>
        {extra}
      </div>
    </div>
  )
}

export default ProjectActions
