import React, { forwardRef, useImperativeHandle, useState, useEffect } from 'react'
import { IHeaderParams } from 'ag-grid-community'
import { InfoCircleFilled, LockOutlined } from '@ant-design/icons'
import { Menu, Dropdown, Tooltip } from 'antd'
import cx from 'clsx'
import Icon, { IconType } from 'src/components/common/Icon'
import { Field } from 'src/components/grid/GridFieldConfig/GridFieldConfig'
import ChangeFieldType from 'src/components/grid/ChangeFieldType'
import { useDialogState } from 'src/hooks/common/useDialog'
import { FIELD_TYPES } from 'src/constants/fieldType'
import { ColumnType, TypeOptions } from 'src/typings/tableNode'
import styles from './gridHeader.module.scss'

export interface Props extends IHeaderParams {
  editable: boolean;
  typeOptions: TypeOptions;
  type: string;
  onColumnUpdate?: () => void;
  onColumnLeftInsert?: (colId?: string) => void;
  onColumnRightInsert?: (colId?: string) => void;
  onColumnCopy?: () => void;
  onColumnHide?: () => void;
  onColumnRemove?: (colId: string) => void;
  enableFieldTypes?: ColumnType[];
  ignoreChangeConfirm?: boolean;
  isPrimary: boolean;
}

export default forwardRef((props: Props, ref) => {
  const { 
    columnApi, 
    column, 
    displayName, 
    api, 
    typeOptions, 
    type, 
    editable, 
    enableFieldTypes,
    onColumnUpdate,
    onColumnLeftInsert,
    onColumnRightInsert,
    onColumnCopy,
    onColumnHide,
    onColumnRemove,
    ignoreChangeConfirm,
    isPrimary
  } = props

  const [menuVisible, setMenuVisible] = useState(false)
  const [currentFieldConfig, setCurrentFieldConfig] = useState<Field>(props.column.getColDef().headerComponentParams)
  const colId = props.column.getColDef().field
  const filedConfigPanel = useDialogState(false)
  const [error, setError] = useState<string>()

  useImperativeHandle(ref, () => {
    return {
      getReactContainerClasses () {
        return [styles.container]
      }
    }
  })

  useEffect(() => {
    if (type === 'formula' && !typeOptions.valid) {
      setError('列的设置有错误，请修改此列相关配置')
    } else {
      setError(undefined)
    }
  }, [typeOptions, type])

  function handleRemoveColumn () {
    columnApi.setColumnsVisible([column], false)
    setMenuVisible(false)
    onColumnRemove?.(column.getColId())
  }

  function handleInsertColumnLeft () {
    const prevColumnId = columnApi.getDisplayedColBefore(column)?.getColId()
    onColumnLeftInsert?.(prevColumnId === 'index' ? undefined : prevColumnId)
  }

  function handleInsertColumnRight () {
    onColumnRightInsert?.(column.getColId())
  }

  function handleShowMenuClick (e: React.MouseEvent) {
    if(!editable){
      return
    }
    e.preventDefault()
    e.stopPropagation()
    api.stopEditing()
    setMenuVisible(true)
  }

  function handleVisibleChange (visible: boolean) {
    setMenuVisible(visible)
  }

  function handleHeaderClick () {
    if(!editable){
      return
    }
    const rowEndIndexWithoutRowAdder = api.getDisplayedRowCount() - 2
    const rowStartIndex = 0
    api.clearRangeSelection()
    api.addCellRange({
      rowStartIndex,
      rowEndIndex: rowEndIndexWithoutRowAdder,
      columns: [column]
    })
    api.setFocusedCell(rowStartIndex, column)
  }

  function handleUpdateField () {
    setCurrentFieldConfig({ ...currentFieldConfig })
    filedConfigPanel.open()
    setMenuVisible(false)
  }

  const menu = (
    <Menu>
      {
        (FIELD_TYPES[currentFieldConfig.type].configurable && onColumnUpdate) 
        && <Menu.Item onClick={handleUpdateField}>修改字段</Menu.Item>
      }
      {
        onColumnLeftInsert && <Menu.Item onClick={handleInsertColumnLeft}>在左侧插入列</Menu.Item>
      }
      {
        onColumnRightInsert && <Menu.Item onClick={handleInsertColumnRight}>在右侧插入列</Menu.Item>
      }
      {
        onColumnCopy && <Menu.Item>复制列</Menu.Item>
      }
      {
        onColumnHide && <Menu.Item>隐藏列</Menu.Item>
      }
      {
        onColumnRemove && <>
          <Menu.Divider />
          <Menu.Item danger onClick={handleRemoveColumn}>删除列</Menu.Item>
        </>
      }
    </Menu>
  )

  return <>
    <Dropdown
      overlayStyle={{ minWidth: 100, float: 'right' }}
      overlay={menu}
      placement="bottomRight"
      visible={menuVisible}
      onVisibleChange={handleVisibleChange}
      disabled={!editable}
      trigger={['click']}
    >
      <div
        className={styles.header}
        onContextMenu={handleShowMenuClick}
        onClick={handleHeaderClick}
      >
        <Icon type={FIELD_TYPES[currentFieldConfig.type]?.icon as IconType} className={styles.icon} />
        <div className={styles.headerText}>
          {displayName}
          {
            isPrimary && <Tooltip 
              overlayStyle={{ maxWidth: '300px' }} 
              title="索引列：用来标识每条记录，不能被删除、移动或隐藏"
            >
              <LockOutlined />
            </Tooltip>
          }
        </div>
        {
          error && <Tooltip placement="bottom" title={error}>
            <InfoCircleFilled className={styles.errorIcon} />
          </Tooltip>
        }
        {
          editable && <div className={cx(styles.caret, { [styles.active]: menuVisible })} onClick={handleShowMenuClick}>
            <Icon type='caretBottom' />
          </div>
        }
      </div>
    </Dropdown>
    { (filedConfigPanel.visible && onColumnUpdate) && <ChangeFieldType
      dialog={filedConfigPanel}
      fieldConfig={currentFieldConfig}
      colId={colId}
      gridApi={props.api} 
      onColumnUpdate={onColumnUpdate}
      enableFieldTypes={enableFieldTypes}
      ignoreChangeConfirm={ignoreChangeConfirm}
    />
    }
  </>
})
