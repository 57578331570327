import React, { forwardRef, useImperativeHandle, useState, useEffect, useRef } from 'react'
import noop from 'lodash/noop'
import type { ICellEditorParams } from 'ag-grid-community'
import { DatePicker } from 'antd'
import Picker from 'rc-picker/lib/Picker'
import moment, { Moment } from 'moment'
import { API_REQUEST_DATE_TIME_FORMAT } from 'src/constants/field'
import { useDatetimeFormat } from 'src/hooks/field/useTypeOptions'
import { useCellEditorParams } from 'src/hooks/field/useCellParams'
import { TypeOptions } from 'src/typings/tableNode'

export interface Props extends ICellEditorParams {
  typeOptions: TypeOptions;
  onBlur?: (value: string) => void;
  onChange?: (value: string | null) => void;
  inGrid?: boolean;
  inDetailPanel: boolean;
}

export const DatetimeEditor = forwardRef((props: Partial<Props>, ref) => {
  const { inGrid = false, inDetailPanel = false } = props
  const [selectedDate, setSelectedDate] = useState<Moment | null>(props.value ? moment(props.value) : null)
  const { onBlur = noop, onChange } = props
  const { typeOptions } = useCellEditorParams(props as ICellEditorParams) as unknown as Props
  const [open, setOpen] = useState(false)
  const pickerRef = useRef(null)
  const formatRule = useDatetimeFormat(typeOptions)

  useEffect(() => {
    onChange?.(selectedDate?.format(API_REQUEST_DATE_TIME_FORMAT) || null)
  }, [selectedDate, onChange])

  function handleDateChange (date: Moment | null) {
    setSelectedDate(date)
  }

  useImperativeHandle(ref, () => {
    return {
      getValue () {
        return selectedDate?.format(API_REQUEST_DATE_TIME_FORMAT) || null
      },
      isCancelAfterEnd () {
        return !selectedDate && selectedDate !== null
      },
      afterGuiAttached () {
        if (props.value) {
          setSelectedDate(moment(props.value))
        }

        (pickerRef.current as unknown as Picker<Moment>).focus()
      }
    }
  })

  function handleKeyDown (e: React.KeyboardEvent<HTMLInputElement>) {
    if (e.key === 'Enter') {
      const input = e.target as HTMLInputElement
      if (input.value === '') {
        setSelectedDate(null)
      }
    }
  }

  function handleOk () {
    setOpen(false)
    setTimeout(() => props.stopEditing?.(), 0)
  }

  function handleBlur () {
    onBlur(selectedDate?.format(API_REQUEST_DATE_TIME_FORMAT))
    setOpen(false)
    props.stopEditing?.()
  }

  return (
    <DatePicker
      ref={pickerRef}
      style={{ width: '100%' }}
      format={formatRule}
      allowClear={true}
      bordered={!inGrid}
      showTime={typeOptions?.timeFormat !== 'hidden'}
      autoFocus={inGrid || inDetailPanel}
      open={open}
      value={selectedDate}
      onChange={handleDateChange}
      onBlur={handleBlur}
      onFocus={() => setOpen(true)}
      onOk={handleOk}
      onKeyDown={handleKeyDown}
    />
  )
})
