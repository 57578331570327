import React, { forwardRef, useImperativeHandle, useState, useEffect, useRef } from 'react'
import { Modal, Upload, Table, Row, Col, Input, Button, message } from 'antd'
import {
  PlusOutlined,
  MenuOutlined,
  AppstoreOutlined,
  EditOutlined,
  DownloadOutlined,
  DeleteOutlined,
  LoadingOutlined
} from '@ant-design/icons'
import map from 'lodash/map'
import filter from 'lodash/filter'
import includes from 'lodash/includes'
import noop from 'lodash/noop'
import cx from 'clsx'
import type { ICellEditorParams } from 'ag-grid-community'
import type { UploadRequestOption } from 'rc-upload/lib/interface'
import type { RcFile } from 'antd/lib/upload/interface'
import useDispatch from 'src/hooks/common/useDispatch'
import { addAttachment, deleteAttachment, updateAttachment } from 'src/state/tableNode/actions'
import type { FileParams } from 'src/api/tablenodes'
import { useAttachmentTable } from 'src/hooks/common/useAttachment'
import { useDialogState } from 'src/hooks/common/useDialog'
import AttachmentItem from 'src/components/field/Attachment/AttachmentItem'
import AttachmentPreview from 'src/components/field/Attachment/AttachmentPreview'
import { download, getPureFileName, getFileExt, combineFileNameWithExt } from 'src/utils/attachment'
import { AttachmentRenderer } from 'src/components/field/Attachment/AttachmentRenderer'
import styles from './attachment.module.scss'

const { Dragger } = Upload
const { Column } = Table

interface Props extends ICellEditorParams {
  onBlur?: () => void;
  inGrid?: boolean;
  inDetailPanel?: boolean;
  tableNodeId: number;
}

export const AttachmentEditor = forwardRef((props: Partial<Props>, ref) => {
  const { tableNodeId, inDetailPanel } = props
  const dispatch = useDispatch()
  const previewModal = useDialogState(false)
  const editModal = useDialogState(false)
  const [editFile, setEditFile] = useState({ id: '', name: '', ext: '' })
  const [currentPreviewId, setCurrentPreviewId] = useState<string>()
  const [attachments, setAttachments] = useState<FileParams[]>(props.value || [])
  const [displayType, setDisplayType] = useState('icon')
  const [downloadingList, setDownloadingList] = useState<string[]>([])
  const [uploadingCount, setUploadingCount] = useState(0)
  const uploadFileCount = useRef(0)
  const messageHide = useRef(noop)
  const rowId = props.data.id
  const columnId = props.colDef?.field as string
  const { dataSource, columns } = useAttachmentTable(attachments)
  
  useImperativeHandle(ref, () => {
    return {
      getValue () {
        return
      },
      isCancelAfterEnd () {
        return true
      },
      afterGuiAttached () {
        return
      }
    }
  })
  
  useEffect(() => {
    // WORKAROUND: register on document.body because there is another one on document in AttachmentCellRenderer.tsx
    document.body.addEventListener('paste', handlePaste)

    return () => document.body.removeEventListener('paste', handlePaste)
  })

  useEffect(() => {
    if (uploadingCount !== 0 && (uploadingCount === uploadFileCount.current)) {
      messageHide.current = message.loading('文件上传中...', 0)
    }

    if (uploadingCount === 0) {
      messageHide.current()
      uploadFileCount.current = 0
    }
  }, [uploadingCount])

  async function upload (file: File) {
    try {
      const { payload } = await dispatch(addAttachment({
        id: tableNodeId as number,
        rowId,
        columnId,
        file: file,
        type: 'attachment'
      }))

      setAttachments((prev) => [...prev, payload] as FileParams[])
    } finally {
      setUploadingCount(prev => --prev)
    }
  }
  
  function handleUpload (options: UploadRequestOption) {
    upload(options.file as File)
  }

  function handleBeforeUpload (file: RcFile, fileList: RcFile[]): true {
    uploadFileCount.current = fileList.length
    setUploadingCount(fileList.length)

    return true
  }

  function handleAttachmentClick (id: string) {
    setCurrentPreviewId(id)
    previewModal.open()
  }

  async function handleDownloadClick (url: string, name: string) {
    setDownloadingList((prev) => [...prev, url])

    try {
      await download(url, name)
    } finally {
      setDownloadingList((prev) => filter(prev, p => p !== url))
    }
  }

  function handleEditClick (fileId: string, name: string) {
    setEditFile({
      id: fileId,
      name: getPureFileName(name),
      ext: getFileExt(name)
    })
    editModal.open()
  }

  function handleNameInputChange (e: React.ChangeEvent<HTMLInputElement>) {
    setEditFile(prev => ({
      ...prev,
      name: getPureFileName(e.target.value)
    }))
  }

  async function handleConfirmClick () {
    const name = combineFileNameWithExt(editFile.name, editFile.ext)
    await dispatch(updateAttachment({
      id: tableNodeId as number,
      rowId,
      columnId,
      fileId: editFile.id,
      name
    }))

    editModal.close()

    setAttachments((prev) => {
      return map(prev, a => {
        if (a.fileID === editFile.id) {
          return {
            ...a,
            fileName: name
          }
        }

        return a
      })
    })
  }

  async function handleDeleteClick (fileId: string) {
    await dispatch(deleteAttachment({
      id: tableNodeId as number,
      rowId,
      columnId,
      fileId
    }))

    setAttachments((prev) => filter(prev, p => p.fileID !== fileId))
  }

  function handleCancel () {
    if (props.inGrid){
      props.stopEditing?.()
    } else {
      props.onBlur?.()
    }
  }

  async function handlePaste (event: ClipboardEvent) {
    if (event.clipboardData?.files[0]) {
      uploadFileCount.current = 1
      setUploadingCount(1)
      upload(event.clipboardData.files[0])
    }
    event.stopPropagation()
  }

  return (
    <>
      <AttachmentRenderer value={attachments} inDetailPanel={inDetailPanel} />
      <Modal
        width={640}
        title="附件列表"
        visible
        onCancel={handleCancel}
        footer={null}
      >
        <Dragger
          multiple={true}
          beforeUpload={handleBeforeUpload}
          customRequest={handleUpload}
          showUploadList={false}
        >
          <div className={styles.uploadTitle}><PlusOutlined />上传附件</div>
          <p className={styles.tip}>点击上传，粘贴或拖拽文件在此处</p>
        </Dragger>
        <div className={styles.fileList}>
          <Row justify="space-between" className={styles.header}>
            <Col>
              <div className={styles.headerIcon}></div>
            </Col>
            <Col>
              <div
                className={cx(styles.headerIcon, { [styles.active]: displayType === 'table' })}
                onClick={() => setDisplayType('table')}
              >
                <MenuOutlined />
              列表
              </div>
              <div
                className={cx(styles.headerIcon, { [styles.active]: displayType === 'icon' })}
                onClick={() => setDisplayType('icon')}
              >
                <AppstoreOutlined />
              图标
              </div>
            </Col>
          </Row>
          {
            displayType === 'table'
              ? <Table
                dataSource={dataSource}
                className={styles.table}
                pagination={false}
                size="small"
                bordered
              >
                {
                  map(columns, c => <Column key={c.key} title={c.title} dataIndex={c.dataIndex} />)
                }
                <Column
                  title="操作"
                  render={(text) => {
                    return (
                      <div className={styles.actions}>
                        <EditOutlined onClick={() => handleEditClick(text.id, text.name)} />
                        {
                          includes(downloadingList, text.url)
                            ? <LoadingOutlined />
                            : <DownloadOutlined
                              onClick={() => handleDownloadClick(text.url, text.name)}
                            />
                        }
                        <DeleteOutlined
                          className={styles.deleteIcon}
                          onClick={() => handleDeleteClick(text.id)}
                        />
                      </div>
                    )
                  }} />
              </Table>
              : <Row gutter={[16, 20]}>
                {
                  map(attachments, a => {
                    return (
                      <Col key={a.fileID} span={6}>
                        <AttachmentItem
                          attachment={a}
                          onClick={handleAttachmentClick}
                          onDeleteClick={handleDeleteClick}
                          onEditClick={handleEditClick}
                        />
                      </Col>
                    )
                  })
                }
                <AttachmentPreview
                  currentPreviewId={currentPreviewId}
                  dialog={previewModal}
                  attachments={attachments}
                  onDeleteClick={handleDeleteClick}
                  onEditClick={handleEditClick}
                  onClose={() => setCurrentPreviewId(undefined)}
                />
              </Row>
          }
        </div>
        <Modal
          width={380}
          title="修改名称"
          footer={null}
          visible={editModal.visible}
          onCancel={() => editModal.close()}
        >
          <Row gutter={10}>
            <Col span={18}>
              <Input value={editFile.name} onChange={handleNameInputChange} />
            </Col>
            <Col span={6}>
              <Button type="primary" onClick={handleConfirmClick}>确定</Button>
            </Col>
          </Row>
        </Modal>
      </Modal>
    </>
  )
})
