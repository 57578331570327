import type { PayloadAction } from '@reduxjs/toolkit'
import type { Notification } from 'src/api/notification'
import { sendBrowserNotification } from 'src/utils/notification'
import type { State } from './slice'

interface NotificationPayload {
  detail: Notification;
  unreadCount: number;
}

/**
 * @name notification/arrive
 * @param state
 * @param action payload {@link NotificationPayload}
 */
export function arrive (state: State, action: PayloadAction<NotificationPayload>): void {
  const { detail, unreadCount } = action.payload
  state.unreadCount = unreadCount

  sendBrowserNotification(detail)
}

export const reducers = {
  arrive
}