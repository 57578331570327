import React, { useState, useRef, useImperativeHandle, useEffect, useMemo } from 'react'
import { Form } from 'antd'
import keys from 'lodash/keys'
import assign from 'lodash/assign'
import find from 'lodash/find'
import cloneDeep from 'lodash/cloneDeep'
import merge from 'lodash/merge'
import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'
import sortBy from 'lodash/sortBy'
import findIndex from 'lodash/findIndex'
import includes from 'lodash/includes'
import cx from 'clsx'
import { flatten } from 'flat'
import Icon from 'src/components/common/Icon'
import type { ChartOptions, ChartType } from 'src/typings/chart'
import { 
  DEFAULT_CHART_PARAMS
} from 'src/constants/chart'
import { TypeOptions } from 'src/typings/tableNode'
import ChartStyleFormItems from '../ChartStyleFormItems/ChartStyleFormItems'
import ChartBasicFormItems from '../ChartBasicFormItems/ChartBasicFormItems'
import ChartTypeSelector from '../ChartTypeSelector/ChartTypeSelector'
import styles from './chartConfigForm.module.scss'

const CHART_TYPES = ['bar', 'pie', 'line'] as ChartType[]
const SORTED_FIELDS = [
  'groupBy',
  'method',
  'fields',
  'aggregator',
  'sortMethod'
]

function getInitialInternalCharts (charts: TypeOptions['charts'], columns: TypeOptions['columns']) {
  return CHART_TYPES.reduce((acc, chartType) => {
    const params = cloneDeep(DEFAULT_CHART_PARAMS[chartType])
    const chart = cloneDeep(find(charts, c => c.type === chartType))
    const initialColumnId = columns[0].id
    const chartConfig = assign({}, {
      type: chartType,
      params
    }, chart) as ChartOptions

    if (chartType === 'pie' && isEmpty(get(chartConfig, 'params.config.groupBy'))) {
      merge(chartConfig?.params.config, {
        groupBy: initialColumnId
      })
    } 

    if ((chartType === 'bar' || chartType === 'line') && isEmpty(get(chartConfig, 'params.config.xAxis.groupBy'))) {
      merge(chartConfig?.params.config, {
        xAxis: {
          groupBy: initialColumnId
        }
      })
    }

    acc[chartType] = flatten(chartConfig, { safe: true })

    return acc
  }, {} as Record<ChartType, Record<string, unknown>>)
}

function getFields (chart: Record<string, unknown>) {
  return sortBy(keys(chart), key => findIndex(SORTED_FIELDS, f => includes(key, f)))
}

interface Props {
  typeOptions: {
    columns: TypeOptions['columns'];
    charts: TypeOptions['charts'];
    activeChart: TypeOptions['activeChart'];
  };
  onChange?: (activeChartType: ChartType, values: Record<ChartType, Record<string, unknown>>) => void;
  onClose?: () => void;
  onLoad?: (activeChartType: ChartType, values: Record<ChartType, Record<string, unknown>>) => void;
}
export const ChartConfigForm = React.forwardRef((props: Props, ref) => {
  const {
    typeOptions,
    onClose,
    onChange,
    onLoad
  } = props
  const { columns, charts } = typeOptions
  const activeChart = typeOptions.activeChart ?? 'bar'
  const internalCharts = useRef(useMemo(() => getInitialInternalCharts(charts, columns), [charts, columns]))
  const internalChartType = useRef<ChartType>(activeChart)
  const [form] = Form.useForm()
  const [activePanel, setActivePanel] = useState('config')
  const initialValues = internalCharts.current[activeChart]
  const [fields, setFields] = useState<string[]>([])

  function handleValueChange (changedValues: Record<string, unknown>) {
    // 当表单中字段值改变时，依赖字段同时也会更新，为确保所有字段都为最新值
    setTimeout(() => {
      const fieldsValue = form.getFieldsValue()
      const chartType = (changedValues.type ?? fieldsValue.type) as ChartType
      const isTypeChanged = !!changedValues.type
      const chart = internalCharts.current[chartType]
      internalChartType.current = chartType
      
      if (isTypeChanged) {
        form.resetFields()
        form.setFieldsValue(chart)
        setFields(getFields(chart))
      } else {
        Object.assign(chart, fieldsValue)
      }

      onChange?.(chartType, internalCharts.current) 
    })
  }

  useImperativeHandle(ref, () => {
    return {
      getCharts () {
        return internalCharts.current
      },
      validateFields () {
        return form.validateFields()
      }
    }
  })

  useEffect(() => {
    const initialValues = internalCharts.current[activeChart]
    setFields(getFields(initialValues))
    onLoad?.(internalChartType.current, internalCharts.current)
  }, [onLoad, activeChart])

  return (
    <div className={styles.config}>
      <Form
        className={styles.form}
        form={form}
        layout="vertical"
        onValuesChange={handleValueChange}
        initialValues={initialValues}
      >
        <div className={styles.chartType}>
          <div className={styles.sectionHeader}>
            <div>图表类型</div>
            <Icon type="close" onClick={onClose} className={styles.icon} />
          </div>
          <Form.Item name="type">
            <ChartTypeSelector />
          </Form.Item>
        </div>
        <div className={styles.divider} />
        <div className={styles.configDetail}>
          <div className={styles.sectionHeader}>
            <div
              className={cx(styles.sectionTitle, activePanel === 'config' && styles.active)}
              onClick={() => setActivePanel('config')}>基本配置
            </div>
            <div
              className={cx(styles.sectionTitle, activePanel === 'style' && styles.active)}
              onClick={() => setActivePanel('style')}>图表样式
            </div>
          </div>
          <div className={styles.optionsDetail}>
            <ChartBasicFormItems
              fields={fields}
              visible={activePanel === 'config'}
              columns={columns}
            />
            <ChartStyleFormItems
              visible={activePanel === 'style'}
            />
          </div>
        </div>
      </Form>
    </div>
  )
})


export default React.memo(ChartConfigForm)
