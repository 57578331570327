import storage from 'store2'
import { createSlice } from '@reduxjs/toolkit'
import { HIDDEN_LAYER_IDS, MAP_FILTERS } from 'src/constants/storage'
import { InfoWindowPOIType } from 'src/typings/map'
import { Geometry } from 'src/utils/wkt'
import { Filter } from 'src/constants/filter'
import { reducers } from './reducers'

export interface Editor {
  enable: boolean;
  isOpen?: boolean;
  type?: string;
  layerNodeID?: string;
  isNew?: boolean;
  preData?: Geometry['coordinates'] | null;
  poi?: InfoWindowPOIType;
}

export interface PoiDetail {
  id?: string;
  tableNodeId?: number;
  labelFieldId?: string;
  rowId?: string;
  visible: boolean;
  poi?: InfoWindowPOIType;
  layerNodeId?: string;
}

export interface Compare {
  tableNodeId?: number | null;
  layerNodeId?: string | null;
  list?: string[]
}

export interface State {
  editor: Editor;
  mapFilters: Record<string, Filter[]>;
  hiddenLayerIds: {
    [projectId: string]: string[]
  };
  poiDetail: PoiDetail;
  compare: Compare
}

const initialState: State = {
  editor: {
    enable: false
  },
  mapFilters: storage.get(MAP_FILTERS, {}),
  hiddenLayerIds: storage.get(HIDDEN_LAYER_IDS, {}),
  poiDetail: {
    visible: false
  },
  compare: {
    tableNodeId: null,
    layerNodeId: null,
    list: [] 
  }
} as State

const mapSlice = createSlice({
  name: 'map',
  initialState,
  reducers
})

export default mapSlice
export const actions = mapSlice.actions
