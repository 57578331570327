import { useEffect, useState } from 'react'
import find from 'lodash/find'
import sortBy from 'lodash/sortBy'
import type { Cell, Row, Column } from 'src/typings/tableNode'
import { tableNodeSelectors } from 'src/state/tableNode/slice'
import useSelector from 'src/hooks/common/useSelector'

interface UseTableCellsReturn {
  cells?: Cell[];
}

export default function useTableCells (tableNodeId: number, tableRowId: string): UseTableCellsReturn {
  const [cellsList, setCellsList] = useState<Cell[]>()
  const tableNode = useSelector(state => tableNodeSelectors.selectById(state, tableNodeId))

  useEffect(() => {
    if (tableRowId && tableNode) {
      const row: Row | undefined = find(tableNode.rows, row => row.id === tableRowId)
      const cells = tableNode.columns.map((col: Column) => {
        return {
          ...col,
          value: row?.cells[col.id],
          rowId: tableRowId,
          tableNodeId: tableNodeId
        }
      })

      // TODO: use default view temporarily
      const viewColumns = tableNode.views[0].columns
      const sortedCells = sortBy(cells, c => viewColumns.find(vc => vc.id === c.id)?.order)
      setCellsList(sortedCells)
    }
  }, [tableNode, tableRowId, tableNodeId])

  return {
    cells: cellsList
  }
}
