import get from 'lodash/get'
import { DATE_FORMAT, TIME_FORMAT } from 'src/constants/field'

export function useDatetimeFormat (typeOptions?: { dateFormat: string; timeFormat: string }): string {
  const dateFormat = get(typeOptions, 'dateFormat', 'year/month/day')
  const timeFormat = get(typeOptions, 'timeFormat', '12-hour-clock')
  const formatRule = `${get(DATE_FORMAT, dateFormat)} ${get(TIME_FORMAT, timeFormat)}`

  return formatRule
}
