import type {
  ColDef,
  ValueGetterParams
} from 'ag-grid-community'
import ColumnAdder from 'src/components/grid/GridColumnAdder'
import RowIndex from 'src/components/grid/GridRowIndex'
import CellEditorContainer from 'src/components/field/CellEditorContainer'
import Header from 'src/components/grid/GridHeader'
import { comparator } from 'src/modules/grid/comparator'
import { cellRendererSelector } from 'src/modules/grid/cellRendererSelector'
import { ADD_ROW_ID, ADD_COLUMN_ID, INDEX_COLUMN_ID } from 'src/constants/grid'
import { ColumnData } from 'src/typings/tableNode'

export function createColumnAdderColumn (options?: ColDef): ColDef {
  return { 
    field: ADD_COLUMN_ID,
    maxWidth: 40,
    menuTabs: [],
    suppressMenu: true,
    suppressMovable: true,
    suppressNavigable: true,
    headerComponentFramework: ColumnAdder,
    editable: false,
    cellClass: 'mt-hidden-column',
    ...options
  }  
}

export function createRowAdderColumn (options?: ColDef): ColDef {
  return {
    field: ADD_ROW_ID,
    hide: true,
    ...options
  }
}

export function createIndexColumn (options?: ColDef): ColDef {
  return {
    valueGetter (params) {
      return (params.node?.rowIndex || 0) + 1
    },
    rowDrag: true,
    suppressMenu: true,
    field: INDEX_COLUMN_ID,
    headerName: '',
    maxWidth: 300,
    editable: false,
    pinned: 'left',
    lockPinned: true,
    cellClass: 'cell-id',
    cellRenderer: RowIndex,
    suppressCellFlash: true,
    suppressMovable: true,
    suppressNavigable: true,
    checkboxSelection: true,
    headerCheckboxSelection: true,
    headerCheckboxSelectionFilteredOnly: true,
    ...options
  }
}

export function createColumn (column: Partial<ColumnData>, options?: ColDef): ColDef {
  return {
    cellClass: `${column.type} ag-customize-cell`,
    headerName: column.name,
    field: column.id,
    width: column.width,
    cellEditorFramework: CellEditorContainer,
    valueGetter (params: ValueGetterParams) {
      return {
        realValue: params.data[params.colDef.field as string],
        typeOptions: params.colDef.headerComponentParams.typeOptions,
        type: params.colDef.headerComponentParams.type
      }
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    equals (a: any, b: any) {
      return a.realValue === b.realValue && a.typeOptions === b.typeOptions
    },
    cellRendererSelector,
    sortable: true,
    comparator,
    headerComponentFramework: Header,
    ...options
  }
}