import React, { useEffect } from 'react'
import { Form, Input, message } from 'antd'
import { useDispatch } from 'react-redux'
import { editProject } from 'src/state/project/actions'
import Dialog from 'src/components/common/Dialog'
import { DialogStateReturn as DialogInterface } from 'src/hooks/common/useDialog'
import { AppDispatch } from 'src/store'
import { Project } from 'src/typings/project'

interface Props {
  dialog: DialogInterface;

  initProject?: Project
}

const CreateLayerModal: React.FC<Props> = ({
  dialog,
  initProject
}) => {
  const dispatch: AppDispatch = useDispatch()
  const [form] = Form.useForm()
  useEffect(() => {
    if (dialog.visible) {
      form.resetFields()
      if (initProject) {
        form.setFieldsValue({
          projectName: initProject.name
        })
      }
    }
  }, [dialog, form, initProject])

  async function handleSubmitClick () {
    await form.validateFields()
    await dispatch(editProject({
      projectId: initProject?.id as number,
      projectName: form.getFieldValue('projectName')
    }))
    message.success('保存成功')
    dialog.close()
  }

  return (
    <Dialog
      width="400px" okText="保存" title="设置项目"
      visible={dialog.visible} onCancel={dialog.close} onOk={handleSubmitClick}>
      <Form
        form={form}
      >
        <Form.Item
          name="projectName" label="项目名称" colon={false}
          rules={[
            {
              required: true,
              message: '请输入项目名称'
            }
          ]}>
          <Input />
        </Form.Item>
      </Form>
    </Dialog>
  )
}

export default CreateLayerModal
