import React, { useState, useEffect } from 'react'
import { useAsync } from 'react-use'
import map from 'lodash/map'
import filter from 'lodash/filter'
import { Table, Button, Form, Select } from 'antd'
import useDispatch from 'src/hooks/common/useDispatch'
import { useParameters } from 'src/hooks/common/useParameters'
import { getMembers, deleteMember, updateMember } from 'src/state/org/actions'
import Avatar from 'src/components/common/Avatar'
import { RoleType, ROLE_NAME, OrgPermission } from 'src/constants/permissions'
import type { Member } from 'src/api/orgs'
import PageContainer from 'src/components/common/PageContainer'
import Dialog from 'src/components/common/Dialog'
import { useDialogState, useConfirmDialog } from 'src/hooks/common/useDialog'
import { useWorkspaces } from 'src/hooks/workspace/useWorkspaces'
import { OrgPermissionWrapper } from 'src/components/common/PermissionWrapper'
import { useDocumentTitle, getWorkspaceTitle } from 'src/hooks/common/useDocumentTitle'
import { Workspace } from 'src/typings/workspace'
import styles from './workspaceMembers.module.scss'

const { Column } = Table
const { Option } = Select


interface Props {
  workspace?: Workspace
}
const WorkspaceMembers: React.FC<Props> = ({
  workspace
}) => {
  const dispatch = useDispatch()
  const [members, setMembers] = useState<Member[]>([])
  const [editingMember, setEditingMember] = useState<Partial<Member>>({})
  const [form] = Form.useForm<Partial<Member>>()
  const { userWorkspaceId: currentUserId } = useWorkspaces('uw')
  const { workspaceId } = useParameters()
  const dialog = useDialogState()
  const leaveTeamDialog = useConfirmDialog({
    title: '退出团队',
    className: styles.removeConfirm,
    okType: 'danger'
  })
  const removeMemberDialog = useConfirmDialog({
    title: '移除成员',
    className: styles.removeConfirm,
    okType: 'danger'
  })

  useDocumentTitle('成员列表', getWorkspaceTitle(workspace))

  function handleEditClick (member: Member) {
    dialog.open()
    setEditingMember(member)
  }

  function handleDeleteClick (member: Member, isSameMember = false) {
    (isSameMember ? leaveTeamDialog({
      content: <div className={styles.confirmContent}>是否确认退出
        <span className={styles.dialogHighlight}>{member.name}</span>团队？
        <div>退出后将无法访问团队项目。</div>
      </div>
    }) : removeMemberDialog({
      content: <div className={styles.confirmContent}> 是否确认移除
        <span className={styles.dialogHighlight}>{member.name}</span>？</div>
    })).then(async () => {
      await dispatch(deleteMember({
        id: workspaceId,
        memberId: member.id
      }))

      setMembers(prev => {
        return filter(prev, m => m.id !== member.id)
      })
    })
  }

  async function handleSaveClick () {
    const info = form.getFieldsValue()
    const memberId = editingMember.id
    await dispatch(updateMember({
      id: workspaceId,
      info: {
        id: memberId,
        ...info
      }
    }))

    setMembers(prev => {
      const member = prev.find(m => m.id === memberId)
      Object.assign(member, info)
      return prev
    })
    dialog.close()
  }

  useAsync(async () => {
    const { payload } = await dispatch(getMembers(workspaceId))

    setMembers(payload as [])
  }, [dispatch, workspaceId])

  useEffect(() => {
    form.setFieldsValue(editingMember)
  }, [editingMember, form])

  return (
    <PageContainer title={'成员列表'}>
      <Table
        dataSource={members}
        pagination={false}
        size="small"
        bordered
        scroll={{ y: 'calc(100vh - 200px)' }}
        rowKey="id"
      >
        <Column
          title="用户名"
          dataIndex="id"
          key="id"
          render={(val, record: Member) => {
            return (
              <div className={styles.user}>
                <Avatar src={record.avatar} />
                <div className={styles.name}>{record.name}</div>
              </div>
            )
          }} />
        <Column title="手机号" dataIndex="phone" key="phone" />
        <Column
          title="权限"
          dataIndex="role"
          key="role"
          render={(role: RoleType) => {
            return ROLE_NAME[role] ?? ''
          }} />
        <Column
          title="操作"
          render={(record) => {
            return (
              <div className={styles.actions}>
                <OrgPermissionWrapper id={workspaceId} role={OrgPermission.MemberEdit}>
                  <Button type="link" onClick={() => handleEditClick(record)}>编辑</Button>
                </OrgPermissionWrapper>
                {record.id !== currentUserId &&
                <OrgPermissionWrapper id={workspaceId} role={OrgPermission.MemberRemove}>
                  <Button type="link" danger onClick={() => handleDeleteClick(record)}>
                    移除
                  </Button>
                </OrgPermissionWrapper>}
                {record.id === currentUserId && record.role !== 'admin' &&
                <Button type="link" danger onClick={() => handleDeleteClick(record, true)}>
                  退出
                </Button>}
              </div>
            )
          }} />
      </Table>
      <Dialog
        title="编辑成员信息"
        width="400px"
        visible={dialog.visible}
        onCancel={() => dialog.close()}
        onOk={handleSaveClick}
        forceRender={true}
      >
        <Form
          name="memberEdit"
          labelAlign="left"
          labelCol={{ span: 4 }}
          requiredMark={false}
          form={form}
        >
          <Form.Item name="role" label="权限">
            <Select>
              {
                map(ROLE_NAME, (val, key) => <Option key={key} value={key}>{val}</Option>)
              }
            </Select>
          </Form.Item>
        </Form>
      </Dialog>
    </PageContainer>
  )
}

export default WorkspaceMembers
