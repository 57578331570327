import concat from 'lodash/concat'
import { IconType } from 'src/components/common/Icon'
import { ColumnType } from 'src/typings/tableNode'

export interface FieldType {
  type: string;
  icon: IconType;
  name: string;
  convertibleTypes: ColumnType[];
  convertible?: boolean;
  needConfirm?: boolean;
  computable?: boolean;
  configurable: boolean;
  sortType?: 'alphabetically' | 'numeric' | 'order'
}

const commonConvertibleTypes: ColumnType[] = [
  'singleLineText', 
  'multiLineText',
  'singleChoice',
  'multiChoice', 
  'attachment', 
  'datetime', 
  'number'
]

export const FIELD_TYPES: Record<string, FieldType> = {
  singleLineText: {
    type: 'singleLineText',
    icon: 'singleLineText',
    name: '文本',
    convertibleTypes: concat(commonConvertibleTypes, 'coordinate', 'boolean'),
    configurable: true,
    computable: true,
    sortType: 'alphabetically'
  },
  multiLineText: {
    type: 'multiLineText',
    icon: 'multiLineText',
    name: '长文本',
    convertibleTypes: concat(commonConvertibleTypes, 'coordinate', 'boolean'),
    configurable: true,
    computable: false,
    sortType: 'alphabetically'
  },
  coordinate: {
    type: 'coordinate',
    icon: 'coordinate',
    convertibleTypes: [],
    name: '坐标',
    configurable: true,
    computable: true
  },
  number: {
    type: 'number',
    icon: 'number',
    name: '数值',
    convertibleTypes: commonConvertibleTypes,
    needConfirm: true,
    computable: true,
    configurable: true,
    sortType: 'numeric'
  },
  singleChoice: {
    type: 'singleChoice',
    icon: 'singleChoice',
    name: '单选',
    convertibleTypes: concat(commonConvertibleTypes, 'boolean'),
    configurable: true,
    sortType: 'order'
  },
  multiChoice: {
    type: 'multiChoice',
    icon: 'multiChoice',
    name: '多选',
    convertibleTypes: concat(commonConvertibleTypes, 'boolean'),
    configurable: true,
    sortType: 'order'
  },
  datetime: {
    type: 'datetime',
    icon: 'datetime',
    name: '日期',
    convertibleTypes: commonConvertibleTypes,
    configurable: true,
    needConfirm: true,
    sortType: 'numeric'
  },
  attachment: {
    type: 'attachment',
    icon: 'attachment',
    name: '附件',
    convertibleTypes: commonConvertibleTypes,
    configurable: true,
    needConfirm: true,
    sortType: 'alphabetically'
  },
  formula: {
    type: 'formula',
    icon: 'formula',
    name: '公式',
    convertibleTypes: [],
    configurable: true,
    sortType: 'alphabetically'
  },
  table: {
    type: 'table',
    icon: 'table2',
    name: '表格',
    convertibleTypes: [],
    configurable: true 
  },
  boolean: {
    type: 'boolean',
    icon: 'checkbox',
    name: '复选框',
    convertibleTypes: ['singleLineText', 'multiLineText', 'singleChoice', 'multiChoice'],
    configurable: true
  }
}
