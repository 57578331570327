import { Middleware } from '@reduxjs/toolkit'
import Cookies from 'js-cookie'
import { actions as userActions } from 'src/state/user/slice'
import { IS_AUTH, REFRESH_TOKEN, TOKEN, LAST_REFRESH_TOKEN_TIMESTAMP } from 'src/constants/storage'
import { actions as statusActions } from 'src/state/status/slice'
import { REQUEST_ERROR_TYPE } from 'src/constants/status'

const errorHandler: Middleware = ({ dispatch }) => (next) => (action) => {
  if (action.error && action.error.name === 'NetworkError') {
    dispatch(statusActions.networkStatus(REQUEST_ERROR_TYPE.NETWORK_ERROR))
  }

  if (action.error && action.error.name === 'AuthError') {
    Cookies.set(IS_AUTH, 'false')
    Cookies.remove(TOKEN)
    Cookies.remove(REFRESH_TOKEN)
    Cookies.remove(LAST_REFRESH_TOKEN_TIMESTAMP)
    dispatch(userActions.setAuth(false))
    window.location.href = '/'

    return
  }

  return next(action)
}

export default errorHandler
