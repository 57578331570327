import { useState, useEffect, useCallback, useMemo } from 'react'
import { RoleType } from 'src/constants/permissions'
import { editProject, getMembers, deleteMember, updateMember, inviteMembers } from 'src/state/project/actions'
import useDispatch from 'src/hooks/common/useDispatch'
import { useParameters } from 'src/hooks/common/useParameters'
import { AppDispatch } from 'src/store'
import { useWorkspaces } from 'src/hooks/workspace/useWorkspaces'
import { useProject } from 'src/hooks/project/useProject'
import { Project } from 'src/typings/project'
import { Workspace } from 'src/typings/workspace'

export interface Member {
  id: number;
  role: RoleType;
  name?: string;
  phone: string;
  avatar?: string;
  inherited: boolean
}

interface Return {
  project?: Project;
  currentUser?: Member;
  members: Member[];
  isOrgWorkspace: boolean;
  fetchMembers: () => void;
  roleInherited?: boolean;
  changeRoleInherited: (v: boolean) => void;
  changeMemberRole: (members: Member[]) => void;
  inviteMembersInUserWorkspace: (members: { phone: string, role: string }[]) => void;
  removeMember: (member: Member) => void;
  isSameUser: (member: Member) => boolean;
  workspace?: Workspace;
}

export function useProjectMember (): Return {
  const { projectId, workspaceId, workspaceType } = useParameters()
  const { userWorkspaceId: currentUserId } = useWorkspaces('uw')
  const { workspace } = useWorkspaces(workspaceType, workspaceId)
  const { project } = useProject(workspace, projectId)

  const [roleInherited, setRoleInherited] = useState<boolean>()

  const dispatch: AppDispatch = useDispatch()
  const [members, setMembers] = useState<Member[]>([])

  const isOrgWorkspace = workspaceType === 'w'
  const fetchMembers = useCallback(async () => {
    const { payload } = await dispatch(getMembers(projectId))
    setMembers(payload as Member[])
  }, [dispatch, projectId])

  const currentUser = useMemo(
    () => members.find(member => member.id === currentUserId), [currentUserId, members]
  )
  const isSameUser = (member: Member) => member.id === currentUserId
  useEffect(() => {
    isOrgWorkspace && setRoleInherited(project?.roleInherited)
  }, [isOrgWorkspace, project?.roleInherited])

  async function changeRoleInherited (roleInherited: boolean) {
    setRoleInherited(roleInherited)
    await dispatch(editProject({
      projectId,
      roleInherited
    }))
    fetchMembers()
  }

  async function removeMember (member: Member) {
    await dispatch(deleteMember({
      projectId,
      memberId: member.id
    }))
    if (isSameUser(member)) {
      window.location.replace(`/${workspaceType}/${workspaceId}`)
    } else {
      fetchMembers()
    }
  }

  async function changeMemberRole (members: Member[]) {
    await dispatch(updateMember({
      projectId,
      members
    }))
    fetchMembers()
  }

  async function inviteMembersInUserWorkspace (members: { phone: string, role: string }[]) {
    await dispatch(inviteMembers({
      projectId,
      members
    }))
    await fetchMembers()
  }

  return {
    isOrgWorkspace,
    project,
    roleInherited,
    changeRoleInherited,
    currentUser,
    members,
    fetchMembers,
    removeMember,
    changeMemberRole,
    inviteMembersInUserWorkspace,
    isSameUser,
    workspace
  }
}
