import React from 'react'
import { Custom } from 'qumap/packages/react'
import { Props as CustomControlProps } from 'qumap/packages/react/types/controls/Custom'
import cx from 'clsx'
import map from 'lodash/map'
import Icon from 'src/components/common/Icon'
import type { MapLegend } from 'src/typings/map'
import styles from './legend.module.scss'

interface Props extends CustomControlProps {
  mapLegends: MapLegend[];
}

const Legend: React.FC<Props> = (props) => {
  const { mapLegends, name, onClick, active } = props

  return (
    <Custom
      name={name}
      onClick={onClick}
      active={active}
      icon={<Icon type={'legend'} />}
    >
      <div className={styles.legendPanel}>
        {map(mapLegends, (legend, idx) => {
          return (
            <div key={idx} className={styles.legendContainer}>
              <div className={styles.legendName}>{legend.name}</div>
              <ul>
                {map(legend.legends, (item, idx) => {
                  return (
                    <li key={idx}>
                      <div
                        className={cx(styles.color, styles[item.color])}
                        style={{
                          backgroundColor: item.color
                        }} />
                      {item.name}
                    </li>
                  )
                })}
              </ul>
            </div>
          )
        })}
      </div>
    </Custom>
  )
}

export default Legend
