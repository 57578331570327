import React, { forwardRef, useImperativeHandle, useState, useEffect, useRef } from 'react'
import isNil from 'lodash/isNil'
import noop from 'lodash/noop'
import type { ICellEditorParams } from 'ag-grid-community'
import { Input } from 'antd'
import Icon from 'src/components/common/Icon'
import { actions as projectActions } from 'src/state/project/slice'
import useDispatch from 'src/hooks/common/useDispatch'
import useSelector from 'src/hooks/common/useSelector'
import { useParameters } from 'src/hooks/common/useParameters'
import styles from './multiLineText.module.scss'

const { TextArea } = Input

export interface Props extends ICellEditorParams {
  onBlur?: (value: string) => void;
  onPressEnter?: (value: string) => void;
  onChange?: (value: string) => void;
  inGrid?: boolean;
  inDetailPanel: boolean;
}

export const MultiLineTextEditor = forwardRef((props: Partial<Props>, ref) => {
  const { inGrid = false, inDetailPanel = false } = props
  const [value, setValue] = useState<string>(props.value)
  const { onBlur = noop, onPressEnter = noop, onChange } = props
  const inputRef = useRef<HTMLInputElement>(null)
  const dispatch = useDispatch()
  const { tableNodeId } = useParameters()
  const rowId = props.data?.id
  const cellId = props.colDef?.field as string
  const multiLineTextEditor = useSelector(state => state.project.multiLineTextEditor)

  useEffect(() => {
    if (multiLineTextEditor.data?.value !== value) {
      setValue(multiLineTextEditor.data?.value || value)
    }
  }, [multiLineTextEditor.data?.value, value])

  useImperativeHandle(ref, () => {
    return {
      isPopup () {
        return true
      },
      getValue () {
        return !isNil(value) ? value : null
      },
      afterGuiAttached () {
        if(props.charPress){
          setValue(props.charPress)
        }
        else if (props.value) {
          setValue(value)
        }
        inputRef.current?.focus()
      }
    }
  })

  useEffect(() => {
    onChange?.(value)
  }, [value, onChange])

  function handleOpenModal () {
    dispatch(projectActions.updateMultiLineTextEditor({
      visible: true,
      trigger: 'open',
      openFrom: 'editor',
      data: {
        id: tableNodeId,
        rowId,
        cellId,
        value
      }
    }))
  }

  function handleBlur () {
    onBlur(value)
    props.stopEditing?.()
  }

  function handleKeyDown (e: React.KeyboardEvent<HTMLTextAreaElement>) {
    if (e.key === 'Enter') {
      if (e.shiftKey) {
        e.stopPropagation()
      } else {
        onPressEnter(value)
      }
    }
  }

  useEffect(() => {
    if (!multiLineTextEditor.visible && multiLineTextEditor.trigger === 'close') {
      props.stopEditing && props.stopEditing()
      dispatch(projectActions.updateMultiLineTextEditor({
        trigger: null,
        data: undefined
      }))
    }
  }, [dispatch, multiLineTextEditor, props])

  return (
    <div style={{ width: props.eGridCell?.clientWidth }}>
      <div className={styles.multiLineTextEditorWrapper}>
        <TextArea
          className={styles.textarea}
          ref={inputRef}
          bordered={!inGrid}
          autoFocus={inGrid || inDetailPanel}
          autoComplete="off"
          value={value}
          autoSize={{ maxRows: 5 }}
          onChange={e => setValue(e.target.value)}
          onBlur={handleBlur}
          onKeyDown={handleKeyDown}
          onMouseDown={ev => ev.stopPropagation()}
        />
        {
          inGrid &&
          <div className={styles.button} onClick={handleOpenModal}>
            <Icon type="expandArrow" />
          </div>
        }
      </div>
      <div className={styles.hint}>{'⏎ 提交   ⇧ + ⏎ 换行'}</div>
    </div>
  )
})
