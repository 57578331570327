import React from 'react'
import toString from 'lodash/toString'
import toNumber from 'lodash/toNumber'
import type { TypeOptions } from 'src/typings/tableNode'
import { NumberRenderer } from 'src/components/field/Number'
import { SingleLineTextRenderer } from '../SingleLineText'

export interface Props {
  value: string | number;
  typeOptions: TypeOptions;
}

export const FormulaRenderer: React.FC<Partial<Props>> = ({
  value,
  typeOptions = {}
}) => {
  const { expectedType, numberPrecision, numberFormat } = typeOptions

  return (
    <div>
      {
        expectedType === 'number'
          ? <NumberRenderer
            value={toNumber(value)}
            typeOptions={{ precision: numberPrecision ?? 0, format: numberFormat }}
          /> :
          expectedType === 'singleLineText'
            ? <SingleLineTextRenderer
              value={toString(value)}
              truncateLength={200}
            />
            : value
      }
    </div>
  )
}
