import { useCallback, useEffect, useRef, useMemo } from 'react'
import map from 'lodash/map'
import last from 'lodash/last'
import toLower from 'lodash/toLower'
import isEqual from 'lodash/isEqual'
import type {
  RowNode
} from 'ag-grid-community'
import useDispatch from 'src/hooks/common/useDispatch'
import { updateView } from 'src/state/tableNode/actions'
import useSelector from 'src/hooks/common/useSelector'
import { Filter } from 'src/constants/filter'
import { selectTableNodeFilters } from 'src/selectors/tableNodeFilterSelector'
import { filterData } from 'src/utils/filter'
import { FiltersWithIdType } from './useFilter'

function transformFilterDataForRequest (filters: Filter[]) {
  const filterCondition = toLower(last(filters)?.condition?.value === 'OR' ? 'OR' : 'AND')
  const filtersForRequest = map(filters, f => {
    return {
      columnID: f.field?.id as string,
      relation: f.relation?.key as string,
      value: f.value
    }
  })

  return {
    filterCondition,
    filters: filtersForRequest
  }
}

interface UseTableViewReturn {
  updateTableView: (tableViewId: string, filters: Filter[]) => void;
}

export function useTableView (tableNodeId: number): UseTableViewReturn {
  const dispatch = useDispatch()

  const update: UseTableViewReturn['updateTableView'] = useCallback((tableViewId, filters) => {
    dispatch(updateView({
      id: tableNodeId,
      viewId: tableViewId,
      params: transformFilterDataForRequest(filters)
    }))
  }, [dispatch, tableNodeId])

  return {
    updateTableView: update
  }
}


interface UseTableFilterReturn {
  filtersWithId: FiltersWithIdType;
  doesExternalFilterPass: (node: RowNode) => boolean;
}

export function useTableFilter (tableNodeId: number): UseTableFilterReturn {
  const filters = useSelector(state => selectTableNodeFilters(state, {
    tableNodeId
  }))
  const filtersWithId = useMemo(() => {
    return {
      id: tableNodeId,
      filters
    }
  }, [tableNodeId, filters])

  const filtersRef = useRef(filtersWithId)
  useEffect(() => {
    if (!isEqual(filtersRef.current, filtersWithId)) {
      filtersRef.current = filtersWithId
    }
  }, [filtersWithId])

  const doesExternalFilterPass = (node: RowNode) => {
    // https://www.ag-grid.com/documentation/react/filter-external/
    // TODO: use default view filters temporarily
    return filterData(node.data, filtersRef.current.filters)
  }

  return {
    filtersWithId,
    doesExternalFilterPass
  }
}
