import { createAsyncThunk } from '@reduxjs/toolkit'
import NotificationAPI, { Mode } from 'src/api/notification'

export const getNotifications = createAsyncThunk(
  'notification/get_async',
  async (payload: { page: number, mode: Mode} ) => {
    const { page, mode } = payload
    return await NotificationAPI.get(page, mode)
  }
)

export const markAsRead = createAsyncThunk(
  'notification/markAsRead_async',
  async (payload: number) => {
    return await NotificationAPI.markAsRead(payload)
  }
)

export const markAllAsRead = createAsyncThunk(
  'notification/markAllAsRead_async',
  async () => {
    return await NotificationAPI.markAsRead(0)
  }
)