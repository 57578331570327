// TODO: use ag-Grid Modules to reduce bundle size in the future
// Reference: https://www.ag-grid.com/javascript-grid-modules/
import React, { useEffect, useRef, useState, useCallback } from 'react'
import { message } from 'antd'
import map from 'lodash/map'
import compact from 'lodash/compact'
import last from 'lodash/last'
import concat from 'lodash/concat'
import cloneDeep from 'lodash/cloneDeep'
import { LicenseManager } from 'ag-grid-enterprise'
import { AgGridReact } from 'ag-grid-react'
import {
  ColumnResizedEvent,
  GridApi,
  GridReadyEvent,
  SelectionChangedEvent,
  ValueSetterParams,
  BodyScrollEvent,
  CellMouseDownEvent,
  CellEditingStoppedEvent,
  SuppressKeyboardEventParams
} from 'ag-grid-community'
import RowAdder from 'src/components/grid/GridRowAdder'
import useDrawer from 'src/hooks/common/useDrawer'
import DetailPanel from 'src/components/detailPanel/DetailPanel'
import useTableCells from 'src/hooks/project/useTableCells'
import { TableNode } from 'src/typings/tableNode'

import 'src/styles/grid.scss'
import {
  MultiLineTextCellRenderer,
  MultiLineTextEditorModal
} from 'src/components/field/MultiLineText'

import { useParameters } from 'src/hooks/common/useParameters'
import { Filter as FilterType } from 'src/constants/filter'
import useMoveRows from 'src/hooks/project/useMoveRows'
import useFieldConfig from 'src/hooks/project/useFieldConfig'
import {
  useGrid,
  useGridData,
  useGridContextMenu,
  useIndexColumnWidth,
  isFullWidthCell
} from 'src/hooks/project/useGrid'
import useCopyPaste from 'src/hooks/project/useGridCopyPaste'
import { useTableFilter } from 'src/hooks/project/useTableView'

import useSorting from 'src/hooks/project/useSorting'
import { ADD_ROW_ID, ADD_COLUMN_ID } from 'src/constants/grid'
import useCollaborator from 'src/hooks/collaborator/useCollaborator'
import { SingleLineTextCellRenderer } from 'src/components/field/SingleLineText'
import { NumberCellRenderer } from 'src/components/field/Number'
import { DatetimeCellRenderer } from 'src/components/field/Datetime'
import { CoordinateCellRenderer } from 'src/components/field/Coordinate'
import { FormulaCellRenderer } from 'src/components/field/Formula'
import { AttachmentCellRenderer } from 'src/components/field/Attachment'
import { TableCellRenderer } from 'src/components/field/Table'
import { CheckboxCellRenderer } from 'src/components/field/Checkbox'
import { store } from 'src/store'
import { SelectCellRenderer } from 'src/components/field/Select'
import { CollaboratorType } from 'src/typings/collaborator'
import AttachmentPreviewGlobal from 'src/components/field/Attachment/AttachmentPreviewGlobal'
import { 
  createColumnAdderColumn,
  createRowAdderColumn,
  createIndexColumn,
  createColumn
} from 'src/modules/grid/colDefs'
import cellClassRules from 'src/modules/grid/cellClassRules'
import type { Field } from 'src/components/grid/GridFieldConfig/GridFieldConfig'

LicenseManager.setLicenseKey(process.env.REACT_APP_AG_GRID_LICENSE_KEY as string)

interface Props {
  editable?: boolean;
  data: TableNode;
  filters?: FilterType[];
  onSelectionChange: (rowIds: string[]) => void;
  onGridReady?: (gridApi: GridApi) => void;
}

const Grid: React.FC<Props> = ({
  editable,
  data,
  filters = [],
  onSelectionChange,
  onGridReady
}) => {
  const { tableNodeId } = useParameters()
  const [gridApi, setGridApi] = useState<GridApi>()
  const gridWrapEl = useRef<HTMLDivElement>(null)

  const [prevCollaborators, setPrevCollaborators] = useState<CollaboratorType[]>([])
  // TODO: use tableNodeView other than default View
  const view = data.views[0]
  const { orders, hasOrder } = useSorting()
  const { 
    resizeColumn, 
    updateCell, 
    addColumn, 
    removeColumn, 
    insertColumn, 
    updateColumn, 
    insertRow,
    scroll
  } = useGrid(gridWrapEl)
  const {
    addGridDropZone,
    onRowDragMove,
    onRowDragEnd
  } = useMoveRows(tableNodeId)
  const { columnData, rowData } = useGridData(data, view, editable)
  const { getContextMenuItems, deleteSelectedCells } = useGridContextMenu()
  const { indexColumnWidth } = useIndexColumnWidth(rowData)
  const { doesExternalFilterPass } = useTableFilter(tableNodeId)
  const detailPanel = useDrawer(false)
  const [detailPanelId, setDetailPanelId] = useState<string>('')
  const filtersPresent = useRef(false)
  const shouldSelectNextCell = useRef(false)
  const {
    onColumnMoved
  } = useFieldConfig()
  const {
    collaborators,
    checkIsCollaboratorRow,
    onCellFocused
  } = useCollaborator(view.id)
  const {
    processCellForClipboard,
    processCellFromClipboard,
    onPasteStart,
    handleCopy,
    handleCut
  } = useCopyPaste(tableNodeId)

  const { cells } = useTableCells(tableNodeId, detailPanelId)

  function handleOpenDetail (id: string) {
    setDetailPanelId(id)
    detailPanel.open(id)
  }

  useEffect(() => {
    filtersPresent.current = filters.length > 0
    gridApi?.onFilterChanged()
  }, [filters, gridApi])

  useEffect(() => {
    gridApi?.refreshCells({
      rowNodes: compact(map([...collaborators, ...prevCollaborators], cell => {
        if (cell?.selected?.rowID) {
          return gridApi?.getRowNode(cell.selected.rowID)
        }
      })),
      columns: compact(map([...collaborators, ...prevCollaborators], cell => {
        if (cell?.selected?.columnID) {
          return cell.selected.columnID
        }
      })),
      force: true
    })

    setPrevCollaborators(collaborators)
  }, [gridApi, collaborators, prevCollaborators])

  function handleColumnResized (e: ColumnResizedEvent) {
    resizeColumn(e, view)
  }

  function handleSelectionChanged (e: SelectionChangedEvent) {
    onSelectionChange(e.api.getSelectedRows().map(r => r.id))
  }

  function handleColumnAdd (prevColumnId: string, field: Field) {
    addColumn(prevColumnId, field)
  }

  const handleKeyDown = useCallback((e: React.KeyboardEvent) => {
    if ((e.metaKey || e.ctrlKey) && e.key === 'c') {
      handleCopy(gridApi)
    }

    if ((e.metaKey || e.ctrlKey) && e.key === 'x') {
      handleCut(gridApi)
    }
  }, [gridApi, handleCopy, handleCut])

  const handleGridReady = (params: GridReadyEvent) => {
    onGridReady?.(params.api)
    setGridApi(params.api)
    addGridDropZone(params)
    onSelectionChange([])
  }

  const onCellMouseDown = (ev: CellMouseDownEvent) => {
    gridApi?.setFocusedCell(ev.rowIndex, ev.colDef.field as string)
  }

  function handleBodyScroll (e: BodyScrollEvent) {
    scroll(e)
  }
  
  function handleCellEditingStopped (e: CellEditingStoppedEvent) {
    if (shouldSelectNextCell.current) {
      const nextRow = gridApi?.getDisplayedRowAtIndex(e.rowIndex + 1)
      if (nextRow && nextRow.id !== ADD_ROW_ID) {
        gridApi?.clearRangeSelection()
        gridApi?.setFocusedCell(e.rowIndex + 1, e.column)
        gridApi?.addCellRange({ rowStartIndex: e.rowIndex + 1, rowEndIndex: e.rowIndex + 1, columns:[e.column] })
      }
      shouldSelectNextCell.current = false
    }
  }

  function handleSuppressKeyboardEvent (e: SuppressKeyboardEventParams) {
    if (e.editing && e.event.key === 'Enter' && 
    ['singleLineText','multiLineText','number'].indexOf(e.colDef.cellEditorParams.type) !== -1 ) {
      shouldSelectNextCell.current = true
    }
    if (!e.editing && (e.event.key === 'Backspace' || e.event.key === 'Delete')) {
      if(deleteSelectedCells(e.api) === false) {
        message.info('公式字段不可清空')
      }
      return true
    }
    return false
  }

  const rowAdderColumnDef = createRowAdderColumn({
    valueGetter (params) {
    /*
    * TODO: 可以使用 post-sort API 替换以下逻辑
    * https://www.ag-grid.com/javascript-grid/row-sorting/#post-sort
    * 为表格添加一个默认排序隐藏列。为确保「添加行」总是在表格最后，将该值设置为Number.MAX_SAFE_INTEGER.
    * 1. 如果没有定制的排序，则根据数据表中的order进行排序。
    * 2. 如果有定制的排序，则该隐藏列的值总是为0.
    */
      if (params.node?.id === ADD_ROW_ID) {
        return Number.MAX_SAFE_INTEGER
      }
      return hasOrder ? 0 : params.node?.data.order
    },
    sort: orders[ADD_ROW_ID]?.order,
    sortIndex: orders[ADD_ROW_ID]?.orderIndex
  })
  
  const columnAdderColumnDef = createColumnAdderColumn({
    headerComponentParams: {
      onColumnAdd: handleColumnAdd
    },
    hide: !editable
  })

  const indexColumnDef = createIndexColumn({
    width: indexColumnWidth,
    rowDrag: editable,
    cellRendererParams: { onOpenDetail: handleOpenDetail }
  })

  const dataColumnDefs = map(columnData, (c, i) => {
    return createColumn(c, {
      valueSetter: (params: ValueSetterParams) => {
        if (params.colDef.cellEditorParams.type !== 'coordinate') {
          updateCell(params, data.id)
        }
        return true
      },
      lockPinned: true,
      cellClassRules,
      cellEditorParams: {
        typeOptions: cloneDeep(c.typeOptions),
        type: c.type,
        inGrid: true,
        tableNodeId,
        viewId: view.id
      },
      sort: orders[c.id]?.order || null,
      sortIndex: orders[c.id]?.orderIndex,
      sortable: true,
      pinned: c.isPrimary && 'left',
      headerComponentParams: {
        enableFieldTypes: c.isPrimary ? ['singleLineText', 'multiLineText', 'datetime', 'number'] : undefined,
        editable,
        typeOptions: cloneDeep(c.typeOptions),
        type: c.type,
        name: c.name,
        isFirstColumn: !i,
        isPrimary: c.isPrimary,
        onColumnUpdate: updateColumn,
        onColumnLeftInsert: !c.isPrimary && insertColumn,
        onColumnRightInsert: insertColumn,
        onColumnRemove: !c.isPrimary && removeColumn
      },
      resizable: editable,
      suppressMovable: !editable || c.isPrimary,
      hide: c.isHidden,
      editable: editable
    })
  })

  const columnDefs = concat(indexColumnDef, dataColumnDefs, columnAdderColumnDef, rowAdderColumnDef)

  return (
    <div
      ref={gridWrapEl}
      className="ag-theme-alpine"
      style={{ height: '100%' }}
      onKeyDown={handleKeyDown}
    >
      <AgGridReact
        columnDefs={columnDefs}
        context={{
          store,
          tableNodeId,
          viewId: view.id
        }}
        components={{
          singleLineTextCellRenderer: SingleLineTextCellRenderer,
          selectCellRenderer: SelectCellRenderer,
          numberCellRenderer: NumberCellRenderer,
          datetimeCellRenderer: DatetimeCellRenderer,
          coordinateCellRenderer: CoordinateCellRenderer,
          formulaCellRenderer: FormulaCellRenderer,
          multiLineTextCellRenderer: MultiLineTextCellRenderer,
          attachmentCellRenderer: AttachmentCellRenderer,
          tableCellRenderer: TableCellRenderer,
          checkboxCellRenderer: CheckboxCellRenderer
        }}
        accentedSort={true}
        rowData={rowData}
        rowBuffer={30}
        isFullWidthCell={isFullWidthCell}
        isRowSelectable={r => !isFullWidthCell(r)}
        fullWidthCellRendererFramework={RowAdder}
        fullWidthCellRendererParams={{
          tableNodeId,
          onRowAdd: insertRow 
        }}
        getContextMenuItems={getContextMenuItems}
        // FIXME: after external filter presents, row dragging move animation disappear
        isExternalFilterPresent={() => filtersPresent.current}
        doesExternalFilterPass={doesExternalFilterPass}
        onGridReady={handleGridReady}
        immutableData={true}
        getRowNodeId={(row) => row.id}
        rowClassRules={{
          'ag-grid-collaborator-row': checkIsCollaboratorRow
        }}
        applyColumnDefOrder={true}
        suppressScrollOnNewData={true}
        animateRows={true}
        suppressMoveWhenRowDragging={true}
        onRowDragEnd={onRowDragEnd}
        onRowDragMove={onRowDragMove}
        onColumnResized={handleColumnResized}
        onColumnMoved={(params) => {
          const displayedColumns = params.columnApi.getAllDisplayedColumns()

          // FixMe: 因为ag-grid暂时还不支持lockPosition最后一列, 因此才去该方式, 来确保`添加字段`始终在最后一列
          // https://www.ag-grid.com/ag-grid-pipeline/  #3326
          if (last(displayedColumns)?.getColId() !== ADD_COLUMN_ID) {
            params.columnApi.moveColumns([ADD_COLUMN_ID], displayedColumns.length - 1)
          } else {
            const columnId = params.column?.getColId()
            if (columnId === ADD_COLUMN_ID) {
              return
            }

            let prevColumn = undefined
            if (params.column) {
              prevColumn = params.columnApi.getDisplayedColBefore(params.column)
            }
            onColumnMoved(columnId, prevColumn?.getColDef().field)
          }
        }}
        enableRangeSelection={true}
        suppressMultiRangeSelection={true}
        suppressRowClickSelection={true}
        rowSelection="multiple"
        enableMultiRowDragging={true}
        rowDragManaged={true}
        onSelectionChanged={handleSelectionChanged}
        processCellForClipboard={processCellForClipboard}
        processCellFromClipboard={processCellFromClipboard}
        onPasteStart={onPasteStart}
        onCellFocused={onCellFocused}
        onCellMouseDown={onCellMouseDown}
        suppressContextMenu={!editable}
        onBodyScroll={handleBodyScroll}
        tooltipShowDelay={500}
        onCellEditingStopped={handleCellEditingStopped}
        defaultColDef={
          { suppressKeyboardEvent: handleSuppressKeyboardEvent }
        }
      />
      <MultiLineTextEditorModal />
      <DetailPanel
        editable={editable}
        drawer={detailPanel}
        cells={cells}
        title={cells?.[0].value as string}
        tableNodeId={tableNodeId}
        inGrid={true}
      />
      <AttachmentPreviewGlobal />
    </div>
  )
}

export default Grid
