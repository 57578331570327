import React, { useState } from 'react'
import cx from 'clsx'
import replace from 'lodash/replace'
import toNumber from 'lodash/toNumber'
import { InputNumber, Form, Row, Col, Input, Slider } from 'antd'
import type { FormInstance } from 'antd/lib/form/hooks/useForm'
import ColorPicker from 'src/components/common/ColorPicker'

import styles from './layerSettingPanel.module.scss'

interface Props {
  collapse?: boolean;
  form: FormInstance;
  onChange?: () => void;
}

const OutlineSetting: React.FC<Props> = ({
  collapse,
  form,
  onChange
}) => {
  const [isCollapse, setCollapse] = useState(collapse)

  function handleToggleClick () {
    setCollapse(!isCollapse)
  }

  function handleColorChange (color: string) {
    if (form.getFieldValue('outlineConfig.strokeColor') !== color) {
      form.setFieldsValue({
        'outlineConfig.strokeColor': color
      })
      onChange?.()
    }
  }

  return (
    <div className={styles.config}>
      <div className={styles.configHeader}>
        <div>描边</div>
        <div className={styles.configToggle} onClick={handleToggleClick}>
          {isCollapse ? '展开' : '收起'}
        </div>
      </div>
      <div className={cx(styles.container, { [styles.collapse]: isCollapse })}>
        <Row gutter={[8, 8]}>
          <Col span={12}>
            <div className={styles.box}>
              <div className={styles.configLabel}>描边颜色</div>
              <ColorPicker color={form.getFieldValue('outlineConfig.strokeColor')} onChange={handleColorChange} />
            </div>
            <Form.Item name="outlineConfig.strokeColor" hidden>
              <Input />
            </Form.Item>
          </Col>
          <Col span={12}>
            <div className={styles.box}>
              <div className={styles.configLabel}>描边粗细</div>
              <Form.Item name="outlineConfig.strokeWeight">
                <InputNumber min={0} max={20} />
              </Form.Item>
            </div>
          </Col>
          <Col span={24}>
            <div className={cx(styles.box, styles.opacitySetting)}>
              <div className={styles.configLabel}>不透明度</div>
              <Row gutter={4}>
                <Col span={16}>
                  <Form.Item name="outlineConfig.strokeOpacity">
                    <Slider
                      min={0}
                      max={1}
                      step={0.1}
                    />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item name="outlineConfig.strokeOpacity">
                    <InputNumber
                      size="small"
                      min={0}
                      max={1}
                      formatter={value => `${toNumber(value) * 100}%`}
                      parser={value => toNumber(replace(value ?? '', '%', '')) / 100 as 0 | 1}
                    />
                  </Form.Item>
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  )
}

export default OutlineSetting
