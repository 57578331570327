import dayjs from 'dayjs'
import toString from 'lodash/toString'
import split from 'lodash/split'
import padEnd from 'lodash/padEnd'
import trim from 'lodash/trim'
import isNaN from 'lodash/isNaN'
import get from 'lodash/get'
import { DATE_FORMAT, TIME_FORMAT } from 'src/constants/field'

export function formatNumberWithPrecision (number: number, precision: number): number {
  return parseFloat(number.toPrecision(precision))
}

export function formatNumberByPrecision (number: number, precision: number): number {
  return Math.round((Math.pow(10, precision)) * number) / Math.pow(10, precision)
}

export function padNumberByPrecisionWithFormat (
  number: number, 
  precision = 0, 
  format = 'number'
): string | undefined {

  if (typeof number === 'undefined' || isNaN(number)) {
    return undefined
  }

  if (number.toString().toUpperCase().includes('E')){
    return number.toExponential(precision === 0 ? 0 : precision - 1)
  }

  const isPercentage = format === 'percentage'
  const isCommaNumber = format === 'commaNumber'
  if (isPercentage) {
    number = number * 100
  }

  const [integer, decimal = ''] = split(toString(formatNumberByPrecision(number, precision)), '.')

  let formattedNumber = integer

  if (isCommaNumber) {
    formattedNumber = formattedNumber.toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1,')
  }

  if (precision) {
    formattedNumber = `${formattedNumber}.${padEnd(decimal, precision, '0')}`
  }

  formattedNumber = `${formattedNumber}${isPercentage ? '%' : ''}`
  return formattedNumber
}

export function isValidDate (dateObject: string): boolean {
  return new Date(dateObject).toString() !== 'Invalid Date'
}

export function formatDateByRule (value: string, typeOptions?: { dateFormat?: string; timeFormat?: string }): string {
  if (!isValidDate(value)) {
    return value
  }

  const dateFormat = get(typeOptions, 'dateFormat', 'year/month/day')
  const timeFormat = get(typeOptions, 'timeFormat', '12-hour-clock')
  const hour = +dayjs(value).format('H')
  const ampm = timeFormat === '12-hour-clock' ? hour >= 0 && hour < 12 ? '上午 ' : '下午 ' : ''
  const formatRule = trim(`${get(DATE_FORMAT, dateFormat)} ${ampm}${get(TIME_FORMAT, timeFormat)}`)

  return dayjs(value).format(formatRule)
}
