import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { Router, Route, Switch } from 'react-router-dom'
import Cookies from 'js-cookie'
import AuthRoute from 'src/containers/AuthRoute'
import userSlice from 'src/state/user/slice'
import { IS_AUTH } from 'src/constants/storage'
import { AppDispatch } from 'src/store'
import routes from 'src/routes'
import Loading from 'src/components/common/Loading'
import { history } from 'src/utils/history'

const App: React.FC = () => {
  const dispatch: AppDispatch = useDispatch()
  const [isReady, setReady] = useState(false)

  // TODO: check auth status from server
  const isUserAuthenticated = Cookies.get(IS_AUTH) === 'true'
  useEffect(() => {
    dispatch(userSlice.actions.setAuth(isUserAuthenticated))
    setReady(true)
  }, [dispatch, isUserAuthenticated])

  return (
    <>
      {isReady && <div className="App">
        <Router history={history}>
          <Switch>
            {routes.map((r, i) => {
              if (r?.meta?.noAuth) {
                return (
                  <Route key={i} {...r} />
                )
              } else {
                return <AuthRoute key={i} {...r} />
              }
            })}
          </Switch>
        </Router>
      </div>}
      <Loading hide={isReady} />
    </>
  )
}

export default App
