import template from 'lodash/template'
import isNil from 'lodash/isNil'
import { ROLE_NAME } from 'src/constants/permissions'
import type { Notification } from 'src/api/notification'

export function compileNotificationMessage (notification: Notification): string {
  return template(notification.content)({
    ...notification.payload,
    role: ROLE_NAME[notification.payload.role]
  })
}

export function getRedirectPage (notification: Notification): string | null {
  const { payload, page } = notification
  const isOrgWorkspace = !isNil(payload.orgID)
  const workspaceType = isOrgWorkspace ? 'w' : 'uw'
  const workspaceId = isOrgWorkspace ? payload.orgID : payload.receiverID
  const projectId = payload.projectID
  const tableNodeId = payload.tableNodeID

  switch (page) {
  case 'help':
    // TODO: redirect to help page
    return '/'
  case 'org':
    return `/${workspaceType}/${workspaceId}`
  case 'project':
    return `/${workspaceType}/${workspaceId}/p/${projectId}`
  case 'tableNode':
    return `/${workspaceType}/${workspaceId}/p/${projectId}/t/${tableNodeId}`
  default:
    return null
  }
}

export function sendBrowserNotification (notification: Notification): void {
  if (!('Notification' in window)) return

  if (Notification.permission === 'granted') {
    new Notification('通知', {
      body: compileNotificationMessage(notification)
    })
  }

  // TODO: request permission
}
