import React, { useState, useEffect } from 'react'
import cx from 'clsx'
import filter from 'lodash/filter'
import { Tag, Modal, Radio, Tooltip, Select, Button, Table } from 'antd'
import find from 'lodash/find'
import map from 'lodash/map'
import type { DialogStateReturn as Dialog } from 'src/hooks/common/useDialog'
import Icon from 'src/components/common/Icon'
import DebounceSelect from 'src/components/common/DebounceSelect'
import { ROLE_NAME, RoleType } from 'src/constants/permissions'
import Avatar from 'src/components/common/Avatar'
import { Member } from 'src/hooks/project/useProjectMember'

import { useTableMember } from 'src/hooks/project/useTableMember'
import { useMatchUser, UserInfo } from 'src/hooks/project/useMatchUser'
import { CustomTagProps } from 'src/components/field/Select/SelectEditor'
import { useConfirmDialog } from 'src/hooks/common/useDialog'

import { TreeNode } from 'src/typings/tree'
import styles from './tableMemberModal.module.scss'

const { Option } = Select
const { Column } = Table

interface Props {
  node: TreeNode
  dialog: Dialog
}

const TableMemberModal: React.FC<Props> = ({ node, dialog }) => {
  const [selectRole, setSelectRole] = useState<RoleType>('admin')

  const {
    tableNode,
    currentUser,
    members,
    roleInherited,
    isSameUser,
    fetchMembers,
    removeMember,
    changeMemberRole,
    changeRoleInherited
  } = useTableMember(node.id as number)
  const confirmDialog = useConfirmDialog()

  const {
    // FIXME: Should use getMatchUsersInTable? when the api is ready
    getMatchUsersInOrg,
    matchedUsers,
    setMatchedUsers,
    selectedUsers,
    setSelectedUsers
  } = useMatchUser()
  useEffect(() => {
    fetchMembers()
  }, [fetchMembers])

  const validateSearch = (q: string) => q.length >= 2

  function handleSelectUser (v: number[]) {
    setSelectedUsers(v)
  }

  function handleRemoveTag (id: number) {
    setSelectedUsers(filter(selectedUsers, v => v !== id))
  }

  function handlePermissionTypeChange (roleInherited: boolean) {
    confirmDialog({
      title: '修改数据表协作权限',
      className: cx(styles.removeConfirm, styles.warning),
      okType: 'danger',
      okText: '修改权限',
      content: roleInherited ?
        <div className={styles.confirmContent}>确定将数据表权限修改为 <span className={styles.dialogHighlight}>继承项目权限</span>？
          <div>修改后将按照项目设置中的成员权限决定各成员对该数据表的访问权限。</div>
        </div>
        : <div className={styles.confirmContent}> 确定将数据表权限修改为 <span className={styles.dialogHighlight}>指定成员可见</span>？
          <div>修改后需要为该数据表设置单独的访问权限，可指定数据表对特定成员可见。</div>
        </div>
    }).then(() => {
      changeRoleInherited(roleInherited)
    })
  }

  async function handleAddButtonClick () {
    await changeMemberRole(selectedUsers.map(id => ({ id, role: selectRole } as Member)))
    setSelectedUsers([])
    setMatchedUsers([])
  }

  async function handleRemoveMember (member: Member) {
    const isSameMember = isSameUser(member)

    confirmDialog({
      className: styles.removeConfirm,
      okType: 'danger',
      title: isSameMember ? '退出数据表协作' : '移除协作成员',
      okText: isSameMember ? '退出协作' : '移除',
      content: isSameMember ?
        <div className={styles.confirmContent}>确定退出数据表
          <span className={styles.dialogHighlight}>{tableNode?.name}</span>协作？
          <div>退出后将无法访问该数据表。</div>
        </div>
        : <div className={styles.confirmContent}>确定将用户
          <span className={styles.dialogHighlight}>{member.name}</span>移除数据表协作成员列表？
          <div>移除后该用户将无法继续访问数据表，已添加的数据将会保留。</div>
        </div>
    }).then(() => {
      removeMember(member)
    })
  }

  function handleRoleChange (v: string) {
    setSelectRole(v as RoleType)
  }

  function getDisabledPermission (member: Member) {
    const isSameMember = isSameUser(member)
    if (member.role === 'admin' && member.inherited) {
      return {
        change: true,
        remove: true
      }
    }
    if (member.role === 'admin' && isSameMember) {
      return {
        change: true,
        remove: true
      }
    }
    if (currentUser && currentUser.role !== 'admin') {
      return {
        change: true,
        remove: !isSameMember
      }
    }
  }

  const customTagRenderer = (props: CustomTagProps) => {
    const id = props.value as number
    const matchedUser = find(matchedUsers, v => v.id === id)
    return (
      <Tag
        className={styles.tag}
        color="#EBECF0"
        closable={true}
        onClose={() => handleRemoveTag(id)}
      >
        <div className={styles.searchResult}>
          {matchedUser?.notRegistered ?
            <div className={styles.name}>
              {matchedUser?.phone}
            </div>
            : <>
              <Avatar src={matchedUser?.avatar} size={16} />
              <div className={styles.name}>
                {matchedUser?.name}
              </div>
            </>
          }
        </div>
      </Tag>
    )
  }

  const customOptionRenderer = (options: UserInfo[]) => {
    return options.map(item => (
      <Select.Option key={item.id} value={item.id}>
        <div className={styles.searchResult}>
          <Avatar src={item?.avatar} />
          <div className={styles.name}>
            {item?.name}
          </div>
          <div className={styles.phone}>
            {item.phone}
          </div>
        </div>
      </Select.Option>
    ))
  }

  const roleItem = (inherited = false) => {
    if (inherited) {
      return <span className={styles.permissionItem}>
              继承项目权限
        <Tooltip title={'按照项目设置中各成员权限决定对该数据表的访问权限'}>
          <Icon type="help" className={styles.help} />
        </Tooltip>
      </span>
    } else {
      return <span className={styles.permissionItem}>指定成员可见
        <Tooltip title={'指定数据表对特定成员可见'}>
          <Icon type="help" className={styles.help} />
        </Tooltip>
      </span>
    }
  }

  return (
    <Modal
      className={styles.tableMemberModal}
      onCancel={() => dialog.close()}
      visible={dialog.visible}
      title='数据表协作成员'
      closeIcon={<Icon type="close" className={styles.closeIcon} />}
      width={500}
      footer={null}
    >
      <div className={styles.header}>
        <span className={styles.title}>数据表权限</span>
        <div className={styles.permissionType}>
          {currentUser?.role === 'admin' ? <Radio.Group
            value={roleInherited}
            onChange={e => handlePermissionTypeChange(e.target.value)}>
            <Radio value={false}>
              {roleItem(false)}
            </Radio>
            <Radio value={true}>
              {roleItem(true)}
            </Radio>
          </Radio.Group> : roleItem(roleInherited)}
        </div>
      </div>
      <div className={styles.divider} />
      <div className={styles.membersHeader}>协作成员({members?.length})</div>
      {currentUser?.role === 'admin' && !roleInherited && <div className={styles.search}>
        <DebounceSelect
          className={styles.select}
          mode="multiple"
          value={selectedUsers}
          validator={validateSearch}
          fetchOptions={getMatchUsersInOrg}
          onChange={handleSelectUser}
          customOptionRenderer={customOptionRenderer}
          tagRender={customTagRenderer}
          searchNotFoundContent={() => '未找到成员，请确认用户已注册并加入改项目'}
          placeholder='请输入项目中成员姓名或手机号'
        />
        <Select onChange={handleRoleChange} value={selectRole} className={styles.roleSelect}>
          {
            map(ROLE_NAME, (val, key) => <Option key={key} value={key}>{val}</Option>)
          }
        </Select>
        <Button
          type="primary" className={styles.addButton}
          onClick={handleAddButtonClick} disabled={selectedUsers.length < 1}>添加</Button>
      </div>}
      <Table
        className={styles.table}
        scroll={{ y: 'calc(100vh - 400px)' }}
        size="small"
        rowKey="id" pagination={false}
        dataSource={members}
      >
        <Column
          width={45}
          title="成员"
          dataIndex="avatar"
          render={(v: string) => (<Avatar src={v} />)} />
        <Column
          dataIndex="name" render={(name: string, v: Member) => (
            !roleInherited ?
              <div>{name}
                {v.inherited && v.role === 'admin' &&
              <span className={styles.teamAdminLabel}>项目管理员</span>
                }
              </div> : name
          )} />
        <Column title="手机号" dataIndex="phone" width={100} />
        <Column
          width={120}
          title="权限"
          dataIndex="role"
          align="center"
          render={(role: RoleType, v: Member) => (
            roleInherited ? <Tooltip title={'继承权限下不可修改成员权限'}>{ROLE_NAME[role]}</Tooltip> :
              <Select
                value={role} className={styles.roleSelect} size="small"
                onChange={(value) => changeMemberRole([{ ...v, role: value }])} disabled={
                  getDisabledPermission(v)?.change
                }>
                {
                  map(ROLE_NAME, (val, key) => <Option key={key} value={key}>{val}</Option>)
                }
              </Select>
          )} />
        {
          roleInherited === false &&
          <Column
            width={45}
            align="center"
            title="操作"
            dataIndex="id"
            render={(id: number, v: Member) => {
              if (!getDisabledPermission(v)?.remove) {
                return <Tooltip title={isSameUser(v) ? '退出协作' : '移除成员'}>
                  <Icon type="close" className={styles.close} onClick={() => handleRemoveMember(v)} />
                </Tooltip>
              } else {
                return <Tooltip title={'项目管理员不可移除'}>
                  {currentUser?.role === 'admin' && <Icon type="close" className={cx(styles.close, styles.disabled)} />}
                </Tooltip>
              }
            }} />
        }
      </Table>
    </Modal>
  )
}

export default TableMemberModal
