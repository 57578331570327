import moment from 'moment'
import type {
  RowNode
} from 'ag-grid-community'
import get from 'lodash/get'
import toNumber from 'lodash/toNumber'
import toLower from 'lodash/toLower'
import toString from 'lodash/toString'
import forEach from 'lodash/forEach'
import isArray from 'lodash/isArray'
import sortBy from 'lodash/sortBy'
import { CONDITIONS_ENUM, Filter, RELATIONS_ENUM } from 'src/constants/filter'
import { Column, TypeOptions } from 'src/typings/tableNode'
import { DATE_FORMAT, TIME_FORMAT } from 'src/constants/field'

type ComparatorValue = string | number | Date | undefined

const formatDate = (date: ComparatorValue, typeOptions?: TypeOptions) => {
  if (typeOptions) {
    return new Date(moment(date)
      .format(`${get(DATE_FORMAT, typeOptions.dateFormat)} ${get(TIME_FORMAT, typeOptions.timeFormat)}`))
      .getTime()
  } else {
    return date
  }
}

const sortArray = (cell: ComparatorValue) => {
  if (isArray(cell)) {
    return sortBy(cell).join(',')
  }

  return cell
}

export const customComparator = (
  relation?: string,
  cell?: ComparatorValue,
  filter?: ComparatorValue,
  field?: Filter['field'] | Column
): boolean => {
  const isNumber = field && field.type === 'number'
  const isDatetime = field && field.type === 'datetime'
  cell = isNumber ?
    toNumber(cell) :
    isDatetime ?
      formatDate(cell, field?.typeOptions) :
      toLower(toString(sortArray(cell)))
  filter = isNumber ?
    toNumber(filter) :
    isDatetime ?
      formatDate(filter, field?.typeOptions) :
      toLower(toString(sortArray(filter)))

  switch (relation) {
  case RELATIONS_ENUM.equals:
    return cell === filter || filter === ''

  case RELATIONS_ENUM.notEqual:
    return cell !== filter

  case RELATIONS_ENUM.is:
    return cell === filter

  case RELATIONS_ENUM.isNot:
    return cell !== filter

  case RELATIONS_ENUM.startsWith:
    return toString(cell).indexOf(filter as string) === 0

  case RELATIONS_ENUM.endsWith:
    return toString(cell).lastIndexOf(toString(filter)) === toString(cell).length - toString(filter).length

  case RELATIONS_ENUM.lessThan:
    return toNumber(cell) < toNumber(filter)

  case RELATIONS_ENUM.lessThanOrEqual:
    return toNumber(cell) <= toNumber(filter)

  case RELATIONS_ENUM.greaterThan:
    return toNumber(cell) > toNumber(filter)

  case RELATIONS_ENUM.greaterThanOrEqual:
    return toNumber(cell) >= toNumber(filter)

  case RELATIONS_ENUM.contains:
    return toString(cell).indexOf(toString(filter)) >= 0

  case RELATIONS_ENUM.notContains:
    return toString(cell).indexOf(toString(filter)) === -1

  case RELATIONS_ENUM.isNull:
    if (isNumber) {
      return isNaN(toNumber(cell)) || typeof cell === 'undefined'
    }

    return !cell
  case RELATIONS_ENUM.isNotNull:
    if (isNumber) {
      return !isNaN(toNumber(cell)) && typeof cell !== 'undefined'
    }

    return !!cell
  default:
    return true
  }
}

export function filterData (data: RowNode['data'], filters?: Filter[]): boolean {
  let meetAllConditions = false
  forEach(filters, filter => {
    switch (filter.condition?.key) {
    case CONDITIONS_ENUM.WHEN:
      meetAllConditions = customComparator(
        filter?.relation?.key,
        data[filter?.field?.id as string],
        filter.value,
        filter.field
      )
      break
    case CONDITIONS_ENUM.OR:
      meetAllConditions = meetAllConditions || customComparator(
        filter?.relation?.key,
        data[filter?.field?.id as string],
        filter.value,
        filter.field
      )
      break
    case CONDITIONS_ENUM.AND:
      meetAllConditions = meetAllConditions && customComparator(
        filter?.relation?.key,
        data[filter?.field?.id as string],
        filter.value,
        filter.field
      )
      break
    }
  })

  return meetAllConditions || !filters?.length
}
