import { PayloadAction } from '@reduxjs/toolkit'
import { State, UndoRedo } from 'src/state/undoRedo/slice'

export const MAX_UNDO_REDO_HISTORY = 20

export function appendUndo (state: State, action: PayloadAction<UndoRedo>): void {
  if (state.undo.length >= 20) {
    state.undo.shift()
  }
  state.undo.push(action.payload)
}

export function clear (state: State): void {
  state.undo = []
  state.redo = []
}

export function clearRedo (state: State): void {
  state.redo = []
}

export const reducers = {
  appendUndo,
  clear,
  clearRedo
}