import { createSlice } from '@reduxjs/toolkit'
import isNumber from 'lodash/isNumber'
import { reducers } from 'src/state/notification/reducers'
import * as workspaceActions from 'src/state/workspace/actions'
import * as notificationActions from 'src/state/notification/actions'

export interface State {
  unreadCount: number;
}

const initialState = {
  unreadCount: 0
} as State

const notificationSlice = createSlice({
  name: 'notification',
  initialState,
  reducers,
  extraReducers (builder) {
    builder.addCase(workspaceActions.getWorkspaceDetail.fulfilled, (state, action) => {
      const { detail } = action.payload

      if (isNumber(detail.unreadNotificationsCount)) {
        state.unreadCount = detail.unreadNotificationsCount
      }
    })

    builder.addCase(notificationActions.markAsRead.fulfilled, (state, action) => {
      const { detail } = action.payload
      state.unreadCount = detail.unreadCount as number
    }).addCase(notificationActions.markAllAsRead.fulfilled, (state, action) => {
      const { detail } = action.payload
      state.unreadCount = detail.unreadCount as number
    }).addCase(notificationActions.getNotifications.fulfilled, (state, action) => {
      const { extra } = action.payload
      state.unreadCount = extra.unreadCount as number
    })
  }
})

export default notificationSlice

export const actions = notificationSlice.actions
