import React, { useEffect, useState } from 'react'
import { Button, Popover, Switch } from 'antd'
import { List as MovableList, arrayMove } from 'react-movable'
import sortBy from 'lodash/sortBy'
import filter from 'lodash/filter'
import Icon from 'src/components/common/Icon'

import { ViewColumn } from 'src/typings/tableNode'
import useFieldConfig from 'src/hooks/project/useFieldConfig'
import ActionButton from 'src/components/project/ActionButton'
import { TablePermissionWrapper } from 'src/components/common/PermissionWrapper'
import { TableNodePermisson } from 'src/constants/permissions'
import styles from './fieldConfig.module.scss'

const FieldConfig: React.FC = () => {
  const [count, setCount] = useState(0)
  const {
    columns,
    viewColumns,
    onColumnMoved,
    onColumnVisibilityChanged,
    onColumnVisibilityUnhidden
  } = useFieldConfig()
  const [movableColumns, setMovableColumns] = useState<ViewColumn[]>([])
  useEffect(() => {
    if (columns && viewColumns) {
      setMovableColumns(sortBy(
        filter(viewColumns, col => {
          const column = columns?.find(c => c.id === col.id) 

          return column !== undefined
        }),
        'order'))
    }

  }, [columns, viewColumns])
  useEffect(() => {
    const hiddenColumns = movableColumns.filter(c => c.isHidden)
    if (hiddenColumns) {
      setCount(hiddenColumns.length)
    }

  }, [movableColumns])

  const handleMove = (meta: { oldIndex: number, newIndex: number }) => {
    const { oldIndex, newIndex } = meta
    const isBeforePrimaryColumn = newIndex === 0

    if (isBeforePrimaryColumn) return 

    const prevColumn = oldIndex > newIndex ? movableColumns[newIndex - 1] : movableColumns[newIndex]
    onColumnMoved(movableColumns[oldIndex].id, prevColumn?.id)
    setMovableColumns(arrayMove(movableColumns, oldIndex, newIndex))
  }
  const renderCountAndClearIcon = () => {
    if (count) {
      return (
        <>
          <span className={styles.count}>{count}</span>
          <span
            className={styles.closeIcon}
            onClick={(ev: React.MouseEvent<HTMLElement>) => {
              ev.stopPropagation()
              onColumnVisibilityUnhidden()
            }}
          >
            <Icon
              type={'closeFilled'}
            />
          </span>
        </>
      )
    }
  }

  const renderContent = () => {
    return (
      <div className={styles.content}>
        <div className={styles.alert}>
          <Icon type={'info'} />
          你的字段配置会同步给他人。
        </div>
        <div>
          {
            movableColumns && <MovableList
              lockVertically
              values={movableColumns}
              onChange={handleMove}
              renderList={({ children, props }) => <ul {...props}>{children}</ul>}
              renderItem={({ value, props }) => {
                const column = columns?.find(c => c.id === value.id)
                return (
                  <div {...props} className={styles.movableItem}>{
                    <>
                      <Switch
                        disabled={column?.isPrimary}
                        size="small" checked={!value.isHidden}
                        onChange={(checked) => onColumnVisibilityChanged(value.id, checked)} />
                      <Icon
                        type={column?.type || 'singleLineText'}
                        className={styles.columnIcon} />
                      <div className={styles.columnName}>
                        {column?.name}
                      </div>
                      <Button
                        disabled={column?.isPrimary}
                        className={styles.handle}
                        type="text"
                        size="small"
                        data-movable-handle
                        icon={<Icon type="movable" className={styles.icon} />}
                      />
                    </>
                  }</div>
                )
              }}
            />
          }
        </div>
      </div>
    )
  }


  return (
    <TablePermissionWrapper role={TableNodePermisson.GridHideColumn}>
      <Popover
        placement="bottomLeft"
        content={renderContent()}
        trigger="click"
      >
        <ActionButton
          active={count > 0}
          icontype={'config'}
        >
        隐藏列 {renderCountAndClearIcon()}
        </ActionButton>
      </Popover>
    </TablePermissionWrapper>
  )
}

export default FieldConfig
