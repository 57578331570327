import React, { useCallback } from 'react'
import { Row, Button, Table, Layout } from 'antd'
import { AppDispatch } from 'src/store'
import useDispatch from 'src/hooks/common/useDispatch'
import { RecycleTableNode } from 'src/typings/project'
import { formatDate, convertToDays } from 'src/utils/moment'
import Icon from 'src/components/common/Icon'
import { useParameters } from 'src/hooks/common/useParameters'
import { useWorkspaces } from 'src/hooks/workspace/useWorkspaces'
import { useDeleteConfirmDialog, useConfirmDialog } from 'src/hooks/common/useDialog'
import { useProject } from 'src/hooks/project/useProject'
import { deleteRecycleTableNode, recoverTableNode } from 'src/state/tableNode/actions'
import TableSider from 'src/components/project/Sider'
import ProjectActions from 'src/components/project/ProjectActions'
import { useWorkspaceRoles } from 'src/hooks/workspace/useWorkspaceRoles'
import { TableNodePermisson } from 'src/constants/permissions'
import { useDocumentTitle } from 'src/hooks/common/useDocumentTitle'
import styles from './recycleBin.module.scss'

const { Content } = Layout

const ProjectRecycleBin: React.FC = () => {
  const { projectId, workspaceId, workspaceType } = useParameters()
  const { workspace } = useWorkspaces(workspaceType, workspaceId)
  const { project } = useProject(workspace, projectId)
  const { checkPermission } = useWorkspaceRoles()
  const dispatch: AppDispatch = useDispatch()
  const deleteFolderConfirmDialog = useDeleteConfirmDialog({
    messageIds: {
      title: 'table.delete.folder.permanently.title',
      content: 'table.delete.folder.permanently.content'
    }
  })
  const deleteItemConfirmDialog = useDeleteConfirmDialog({
    messageIds: {
      title: 'table.delete.permanently.title',
      content: 'table.delete.permanently.content'
    }
  })
  const confirmDialog = useConfirmDialog()
  const deleteConfirmDialog = useCallback(() => ({
    'delete.folder': deleteFolderConfirmDialog,
    'delete': deleteItemConfirmDialog
  }), [deleteFolderConfirmDialog, deleteItemConfirmDialog])
  useDocumentTitle('项目回收站', project?.name)

  function handleRecoverProject (tableNode: RecycleTableNode) {
    const type = tableNode.type === 'group' ? '文件夹' : '数据表'
    confirmDialog({
      title: `恢复${type}`,
      content: (<>
        <div>确定恢复{type} <span className={styles.highlight}>{tableNode.name}</span> ？</div>
        <div>恢复后{type}将放回原位置。</div>
      </>),
      closable: false
    }).then(() => {
      dispatch(recoverTableNode(tableNode.id))
    })
  }

  function handleRemoveProject (tableNode: RecycleTableNode) {
    deleteConfirmDialog()[tableNode.type === 'group' ? 'delete.folder' : 'delete']({
      messageValues: {
        name: tableNode.name
      }
    }).then(() => {
      dispatch(deleteRecycleTableNode(tableNode.id))
    })
  }

  const columns = [
    {
      title: '文件名',
      dataIndex: 'name',
      width: 100,
      render: (v: string, t: RecycleTableNode) => <div className={styles.projectName}>
        <Icon type={t.type === 'group' ? 'folder' : 'table'} className={styles.projectIcon} />
        {v}
      </div>
    },
    {
      title: '剩余天数',
      dataIndex: 'timeRemaining',
      width: 100,
      render: (v: number) => <>
        {convertToDays(v)} 天
      </>
    },
    {
      title: '删除时间',
      dataIndex: 'deletedAt',
      width: 100,
      render: (v: string) => <>
        {formatDate(v)}
      </>
    },
    {
      title: '操作',
      width: 100,
      render: (_: unknown, t: RecycleTableNode) => {
        const hasPermission = checkPermission(t.id, TableNodePermisson.ProjectRecycleBinActions, 'table')
        return <>
          <Button
            type="link" className={styles.actionButton} onClick={() => handleRecoverProject(t)}
            disabled={!hasPermission}>恢复文件</Button>
          <Button
            type="link" danger className={styles.actionButton} onClick={() => handleRemoveProject(t)}
            disabled={!hasPermission}>彻底删除</Button>
        </>
      }
    }
  ]

  return (
    <>
      <ProjectActions />
      <Layout>
        <TableSider project={project} activeMenuItems={['recycle']} />
        <Content className={styles.content}>
          <div className={styles.recycleBin}>
            <Row align="middle" justify="start">
              <h2 className={styles.title}>回收站</h2>
              <span className={styles.hint}>回收站数据将保留 30 天</span>
            </Row>
            <div className={styles.divider} />
            <div className={styles.table}>
              <Table
                columns={columns} dataSource={project?.recycleTableNodes}
                pagination={false} scroll={{ y: 'calc(100vh - 250px)' }} rowKey="id" />
            </div>
          </div>
        </Content>
      </Layout>
    </>
  )
}

export default ProjectRecycleBin
