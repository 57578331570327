import React from 'react'
import cx from 'clsx'
import { Button, ButtonProps } from 'antd'
import omit from 'lodash/omit'
import Icon, { IconType } from 'src/components/common/Icon'
import styles from './actionButton.module.scss'

interface Props extends ButtonProps {
  icontype?: string
  active?: boolean
  text?: string
  iconbutton?: boolean
  nobackground?: boolean
}

const ActionButton: React.FC<Props> = (props) => {
  const {
    active = false,
    text,
    icontype = '',
    size = 'small',
    type = 'text',
    iconbutton = false,
    nobackground = false,
    children,
    className
  } = props
  return (
    <Button
      {
        ...omit(props, ['active', 'icontype', 'iconbutton', 'nobackground', 'children'])
      }
      size={size}
      type={type}
      className={cx(
        styles.button,
        {
          [styles.active]: active,
          [styles.iconButton]: iconbutton,
          [styles.noBackground]: nobackground
        },
        className
      )}
      icon={<Icon type={icontype as IconType} />}
    >
      {text}
      {children}
    </Button>
  )
}

export default ActionButton
