import { createSlice } from '@reduxjs/toolkit'

import { reducers } from 'src/state/cell/reducers'

export interface State {
  currentEditCellId: string
}

const initialState = {
  currentEditCellId: ''
}

const cellSlice = createSlice({
  name: 'cell',
  initialState,
  reducers
})

export default cellSlice

export const actions = cellSlice.actions
