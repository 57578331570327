import { createSlice, EntityState } from '@reduxjs/toolkit'
import { RootState } from 'src/store'
import { FormulaType } from 'src/typings/formula'
import { getFormulaDocs } from './actions'
import formulaAdapter from './entityAdapter'

export type State = EntityState<FormulaType>

const initialState = formulaAdapter.getInitialState()

const formulaSlice = createSlice({
  name: 'formula',
  initialState,
  reducers: {
    clear: formulaAdapter.removeAll
  },
  extraReducers (builder) {
    builder.addCase(getFormulaDocs.fulfilled, (state, action) => {
      const { payload } = action
      formulaAdapter.removeAll(state)
      formulaAdapter.addMany(state, payload.detail)
    })
  }
})

export default formulaSlice
export const actions = formulaSlice.actions
export const formulaSelectors = formulaAdapter.getSelectors<RootState>(state => state.formula)
