import React, { useEffect, useMemo, useRef, useState } from 'react'
import { Select, Spin } from 'antd'
import { RefSelectProps, SelectProps } from 'antd/es/select'
import debounce from 'lodash/debounce'

/* eslint-disable @typescript-eslint/no-explicit-any */

interface DebounceSelectProps<ValueType = any>
  extends Omit<SelectProps<ValueType>, 'options' | 'children'> {
  fetchOptions: (search: string) => Promise<ValueType[] | undefined>;
  customOptionRenderer?: (options: ValueType[]) => React.ReactNode;
  validator?: (search: string) => boolean;
  searchNotFoundContent?: (search: string) => React.ReactNode;
  onPressEnter?: (search: string) => void;
  debounceTimeout?: number;
}
const DebounceSelect: React.FC<DebounceSelectProps> = ({ 
  fetchOptions, 
  validator, 
  searchNotFoundContent,
  customOptionRenderer,
  onPressEnter,
  debounceTimeout = 500, 
  ...props }) => {
  const [fetching, setFetching] = useState(false)
  const [currentSearch, setCurrentSearch] = useState<string>()

  const [options, setOptions] = useState<any[]>([])

  const fetchRef = useRef(0)
  const selectRef = useRef<RefSelectProps>(null)

  useEffect(() => {
    setCurrentSearch(undefined)
    // clear serach input value by blur & focus
    selectRef.current?.blur()
    selectRef.current?.focus()
  },[props.value])
  const debounceFetcher = useMemo(() => {
    const loadOptions = (value: string) => {
      setCurrentSearch(undefined)
      if(validator && !validator(value)){
        return
      } 
      fetchRef.current += 1
      const fetchId = fetchRef.current
      setFetching(true)
      setOptions([])
      fetchOptions(value).then(newOptions => {
        if (fetchId !== fetchRef.current || newOptions === undefined) {
          return
        }
        setOptions(newOptions)
        setCurrentSearch(value)
      }).finally(() => {
        setFetching(false)
      })
    }

    return debounce(loadOptions, debounceTimeout)
  }, [debounceTimeout, validator, fetchOptions])
  function handleDropdownVisibleChange () {
    setCurrentSearch(undefined)
    setOptions([])
  }
  return (
    <Select
      ref={selectRef}
      filterOption={false}
      onSearch={debounceFetcher}
      notFoundContent={
        fetching ? <Spin size="small" /> : 
          currentSearch ? searchNotFoundContent?.(currentSearch) : null}
      {...props}
      options={customOptionRenderer ? undefined : options}
      onDropdownVisibleChange={handleDropdownVisibleChange}
      onKeyDown={(ev) => ev.key === 'Enter' && onPressEnter?.(currentSearch || '')}
    >
      {!fetching && options.length > 0 && customOptionRenderer?.(options)}
    </Select>
  )
}
export default DebounceSelect