import type { PayloadAction } from '@reduxjs/toolkit'
import map from 'lodash/map'
import find from 'lodash/find'
import type { State } from 'src/state/collaborator/slice'
import { collaboratorColors } from 'src/constants/collaborator'
import { CollaboratorType, CellCollaboratorType } from 'src/typings/collaborator'
import collaboratorAdapter from './entityAdapter'


// source: https://github.com/skeeto/hash-prospector
export function int2IntHash (x: number): number {
  x ^= x >> 16
  x *= 0x7feb352d
  x ^= x >> 15
  x *= 0x846ca68b
  x ^= x >> 16
  return x
}

/**
 * @name collaborator/setCollaborators
 * @param state
 * @param action payload {@link CollaboratorType}
 */
export function setCollaborators (state: State, action: PayloadAction<{
  sessions: CollaboratorType[]
}>): void {
  collaboratorAdapter.removeAll(state)
  const colorCount = collaboratorColors.length
  const cellCollaborators: Record<string, CellCollaboratorType[]> = {}
  const otherCollaborators: Record<string, CollaboratorType> = {}
  collaboratorAdapter.addMany(state, map(action.payload.sessions, item => {
    const color = collaboratorColors[int2IntHash(item.userID) % colorCount]

    if (item.selected && !item.isCurrent) {
      const selected = item.selected
      const key = `${selected.columnID},${selected.rowID}`
      if (!cellCollaborators[key]) {
        cellCollaborators[key] = []
      }

      /**
       * 同一个用户打开多个tab并选中不同单元格时，只显示一个名字。
       */
      if (!find(cellCollaborators[key], collaborator => collaborator.userID === item.userID)) {
        cellCollaborators[key].push({
          userID: item.userID,
          columnID: selected.columnID,
          rowID: selected.rowID,
          name: item.name,
          color
        })
      }
    }

    const collaborator = {
      ...item,
      color
    }

    if (item.isCurrent) {
      state.sessionID = item.id
    } else {
      otherCollaborators[item.userID] = collaborator
    }

    return collaborator
  }))

  state.cellCollaborators = cellCollaborators
  state.otherCollaborators = otherCollaborators
}

/**
 * @ignore
 */
export const reducers = {
  setCollaborators
}
