import React, { forwardRef, useImperativeHandle, useRef, useState } from 'react'
import isNil from 'lodash/isNil'
import type { ICellEditorParams } from 'ag-grid-community'
import { Input } from 'antd'
import { TypeOptions } from 'src/typings/tableNode'
import styles from './formulaEditor.module.scss'

const { TextArea } = Input

export interface Props extends ICellEditorParams {
  typeOptions: TypeOptions;
  inGrid?: boolean;
  inDetailPanel: boolean;
}

export const FormulaEditor = forwardRef((props: Partial<Props>, ref) => {
  const { inGrid = false } = props
  const inputRef = useRef<HTMLInputElement>(null)
  const [value] = useState<number | undefined>(props.value)

  useImperativeHandle(ref, () => {
    return {
      isPopup () {
        return true
      },
      getValue () {
        return !isNil(value) ? value : null
      }
    }
  })

  return (
    <div style={{ width: props.eGridCell?.clientWidth }}>
      <TextArea
        className={styles.textarea}
        ref={inputRef}
        bordered={!inGrid}
        value={value}
        readOnly={true}
        autoSize={{ minRows: 2, maxRows: 5 }}
      />
      <div className={styles.hint}>{'此列为自动计算，可前往列的配置修改'}</div>
    </div>
  )
})
