import { Middleware } from '@reduxjs/toolkit'
import { actions as appActions } from 'src/state/app/slice'
import { requestFinish } from 'src/state/app/actions'

const fetchStatusMiddleware: Middleware = (api) => (next) => (action) => {
  if (action.payload?.requestID && !requestFinish.match(action)) {
    api.dispatch(appActions.addFetching(action.payload.requestID))
  }

  if (requestFinish.match(action)) {
    api.dispatch(appActions.removeFetching(action.payload.requestID))
  }

  return next(action)
}

export default fetchStatusMiddleware