import { PayloadAction } from '@reduxjs/toolkit'

import { DND } from 'src/typings/tree'
import { LayerNode } from 'src/typings/mapTableNode'

/**
 * @name layerNode/create
 * @param state
 * @param action payload {@link Project}
 */
export function create (state: unknown, action: PayloadAction<LayerNode>): void {
  return
}

/**
 * @name layerNode/update
 * @param state
 * @param action payload {@link Project}
 */
export function update (state: unknown, action: PayloadAction<LayerNode>): void {
  return
}

/**
 * @name layerNode/move
 * @param state
 * @param action payload {@link Project}
 */
export function move (state: unknown, action: PayloadAction<DND>): void {
  return
}

/**
 * @ignore
 */
export const reducers = {
  create,
  update,
  move
}
