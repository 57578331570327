import AppConfig from 'src/config'
import { createInstance } from 'src/utils/fetch'
import type { APIResponse } from 'src/typings/common'
import type { RoleType } from 'src/constants/permissions'

export type Mode = 'unreadFirst' | 'newestFirst' | 'unreadOnly'

export type RedirectPage = 'help' | 'org' | 'project' | 'tableNode' | 'noOp'

interface NotificationDetail {
  orgID: number | null;
  orgName: string | null;
  projectID: number;
  projectName: string;
  receiverID: number;
  receiverName: string;
  role: RoleType;
  senderAvatar: string;
  senderID: number;
  senderName: string;
  tableNodeID: number | null;
  tableNodeName: string | null;
}

export interface Notification {
  createTime: string;
  content: string;
  id: number;
  page: RedirectPage,
  status: string;
  type: string;
  read: boolean;
  payload: NotificationDetail;
}

const fetch = createInstance({
  baseURL: `${AppConfig.baseApiURL}/notifications/`
})

const NotificationAPI = {
  async get (page: number, mode: Mode): Promise<APIResponse<Notification[]>> {
    const { data } = await fetch.get('', {
      params: {
        page,
        mode
      }
    }) 

    return data
  },
  async markAsRead (id: number): Promise<APIResponse> {
    const { data } = await fetch.post(`/${id}/`)

    return data
  }
}

export default NotificationAPI
