import React, { useCallback, useEffect, useState } from 'react'
import cx from 'clsx'
import { Col, Row, Select } from 'antd'
import map from 'lodash/map'
import forEach from 'lodash/forEach'
import find from 'lodash/find'
import debounce from 'lodash/debounce'
import isNil from 'lodash/isNil'
import Icon from 'src/components/common/Icon'
import {
  CONDITIONS,
  RELATIONS,
  RELATION_KEYS,
  getFilterRelations,
  getRelationName,
  isNullableRelation,
  Condition as ConditionType,
  Relation as RelationType,
  Filter as FilterType
} from 'src/constants/filter'
import { TableNodeColumn, Column } from 'src/typings/tableNode'
import type { IconType } from 'src/components/common/Icon'
import FilterValue from 'src/components/project/Filter/FilterValue'
import { FIELD_TYPES } from 'src/constants/fieldType'
import styles from './filterItem.module.scss'

interface FilterItemProps {
  order: number;
  filter: FilterType;
  onRemove?: (filter: FilterType) => void;
  onChange?: (filter: FilterType) => void;
  columnsForFilter: Column[]
}

const FilterItem: React.FC<FilterItemProps> = (props) => {
  const { order, filter, onRemove, onChange, columnsForFilter } = props
  const [currentFilter, setCurrentFilter] = useState<FilterType>(filter)
  const [columnsObj, setColumnsObj] = useState<Record<string, TableNodeColumn>>()

  const checkValueNullable = useCallback(() => {
    if (isNullableRelation(currentFilter.relation?.key)) {
      return isNil(currentFilter.value)
    } else {
      return !isNil(currentFilter.value)
    }
  }, [currentFilter])

  useEffect(() => {
    if (filter) {
      setCurrentFilter(filter)
    }
  }, [filter])

  useEffect(() => {
    const colObj: Record<string, TableNodeColumn> = {}
    forEach(columnsForFilter, (col: Column) => colObj[col.id] = col)
    setColumnsObj(colObj)
  }, [columnsForFilter])

  useEffect(() => {
    if (
      currentFilter.field
      && currentFilter.condition
      && currentFilter.relation
      && checkValueNullable()
    ) {
      onChange?.(currentFilter)
    }
  }, [checkValueNullable, currentFilter, onChange])

  const handleFieldChanged = (val: string) => {
    setCurrentFilter((prev) => {
      return {
        ...prev,
        field: columnsObj?.[val] as FilterType['field'],
        relation: undefined,
        value: undefined
      }
    })
  }
  const handleConditionChange = (val: string) => {
    setCurrentFilter(prev => {
      return {
        ...prev,
        condition: find(Object.values(CONDITIONS), o => o.key === val)
      }
    })
  }

  const handleRelationChange = (val: string) => {
    let relation: RelationType
    RELATION_KEYS.forEach(key => {
      if (key === val) {
        relation = RELATIONS[key]

        if (relation) {
          setCurrentFilter((prev) => {
            return {
              ...prev,
              relation,
              value: isNullableRelation(relation.key) ? (null as unknown as undefined) : prev.value
            }
          })
        }
      }
    })
  }

  const handleValueChange = debounce((value: string) => {
    setCurrentFilter((prev) => {
      return {
        ...prev,
        value
      }
    })
  }, 300)

  const renderRelations = () => {
    if (columnsObj) {
      const type = columnsObj[currentFilter?.field?.id as string]?.type
      return map(getFilterRelations(type), (o: RelationType) => (
        <Select.Option key={o.key} value={o.key}>{getRelationName(type, o)}</Select.Option>
      ))
    }
  }

  return (
    <Row className={styles.option} gutter={[16, 10]}>
      <Col span={1}>
        <div className={cx(styles.optionCell, styles.iconCell)} onClick={() => onRemove?.(filter)}>
          <Icon
            type={'close'}
            className={styles.close} />
        </div>
      </Col>
      <Col span={4}>
        <div className={cx(styles.optionCell, styles.noPadding)}>
          {order === 1 &&
          <Select onChange={handleConditionChange} value={currentFilter.condition?.key} disabled={order !== 1}>
            {
              map(order ? [CONDITIONS.OR, CONDITIONS.AND] : [CONDITIONS.WHEN], (o: ConditionType) => (
                <Select.Option key={o.key} value={o.key}>{o.name}</Select.Option>
              ))
            }
          </Select>}
          {order !== 1 && <div className={styles.text}>{currentFilter.condition?.name}</div>}
        </div>
      </Col>
      <Col span={7}>
        <div className={cx(styles.optionCell, styles.noPadding)}>
          <Select onChange={handleFieldChanged} value={currentFilter.field?.id}>
            {
              map(columnsForFilter, (o: TableNodeColumn) => (
                <Select.Option key={o.id} value={o.id}>
                  <div className={styles.selectOption}>
                    <Icon type={find(FIELD_TYPES, f => f.type === o.type)?.icon as IconType} />
                    {o.name}
                  </div>
                </Select.Option>
              ))
            }
          </Select>
        </div>
      </Col>
      <Col span={5}>
        <div className={cx(styles.optionCell, styles.noPadding)}>
          <Select onChange={handleRelationChange} value={currentFilter.relation?.key}>
            {renderRelations()}
          </Select>
        </div>
      </Col>
      <Col span={7}>
        <div className={cx(styles.optionCell, styles.bg, styles.noPadding)}>
          {!isNullableRelation(currentFilter.relation?.key) && <FilterValue
            value={currentFilter.value}
            type={currentFilter.field?.type}
            onChange={handleValueChange}
            typeOptions={currentFilter.field?.typeOptions}
            relation={currentFilter.relation}
          />}
        </div>
      </Col>
    </Row>
  )
}

export default FilterItem
