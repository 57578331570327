import { useCallback } from 'react'
import { useHistory, useParams, generatePath, matchPath } from 'react-router-dom'

export type WorkspaceType = 'w' | 'uw'

interface Return {
  workspaceId: number
  setWorkspaceIdAndType: (id: number, type: WorkspaceType) => void
  projectId: number
  tableNodeId: number
  setTableNodeId: (id?: string | number) => void,
  workspaceType: WorkspaceType
}

export function useParameters (): Return {

  const history = useHistory()

  const {
    workspaceId,
    projectId,
    tableNodeId,
    workspaceType
  } = useParams<{
    workspaceId: string,
    projectId: string,
    tableNodeId: string
    workspaceType: WorkspaceType
  }>()
  const setWorkspaceIdAndType = useCallback((id: number, type: WorkspaceType = 'uw') => {
    const path = generatePath(`/${type}/:workspaceId`, {
      workspaceId: id
    })
    history.replace(path)
  }, [history])

  const setTableNodeId = useCallback((id?: string | number) => {
    const path = generatePath(`/${workspaceType}/:workspaceId/p/:projectId/t/:tableNodeId?`, {
      workspaceId,
      projectId,
      tableNodeId: id
    })
    history.replace(path)
  }, [history, projectId, workspaceId, workspaceType])

  return {
    workspaceId : parseInt(workspaceId),
    setWorkspaceIdAndType,
    projectId : parseInt(projectId),
    tableNodeId: parseInt(tableNodeId),
    setTableNodeId,
    workspaceType
  }
}

export function isOnPage (name: string, pathname: string, path: string): boolean {
  return matchPath(pathname, {
    path: `${path}/${name}`,
    exact: true
  }) !== null
}
