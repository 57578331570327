import React, { useCallback, useEffect, useState, useRef } from 'react'
import { Checkbox } from 'antd'
import type { CheckboxChangeEvent } from 'antd/lib/checkbox'
import storage from 'store2'
import dayjs from 'dayjs'
import { AppDispatch } from 'src/store'
import { actions as statusActions, statusSelectors } from 'src/state/status/slice'
import { WSReadyState } from 'src/state/app/reducers'
import useDispatch from 'src/hooks/common/useDispatch'
import useSelector from 'src/hooks/common/useSelector'
import { REQUEST_ERROR_TYPE } from 'src/constants/status'
import { HIDDEN_REFRESH_CONFIRM_MODAL } from 'src/constants/storage'
import { useConfirmDialog } from './useDialog'

interface Return {
  isSaving: boolean
  isNetworkBroken: boolean
  isNetworkReconnected: boolean
  isUserWsConnecting: boolean
  isOrgsWsConnecting: boolean
}

const SHOW_STATUS_TIME_OUT = 1000
const REFRESH_CONFIRM = 10

export function useStatus (): Return {
  const dispatch: AppDispatch = useDispatch()
  const requests = useSelector(statusSelectors.selectAll)
  const networkStatus = useSelector(state => state.status.networkStatus)
  const userWsStatus = useSelector(state => state.status.userWsStatus)
  const userWsInitialized = useSelector(state => state.status.userWsInitialized)
  const orgWsStatus = useSelector(state => state.status.orgWsStatus)
  const orgWsInitialized = useSelector(state => state.status.orgWsInitialized)
  const checkRequestExpiredTimestamp = useSelector(state => state.status.checkRequestExpiredTimestamp)
  const [offline, setOffline] = useState(false)
  const [showSaving, setShowSaving] = useState(false)
  const [showReconnected, setShowReconnected] = useState(false)
  const [showUserWsStatus, setShowUserWsStatus] = useState(false)
  const [showOrgWsStatus, setShowOrgWsStatus] = useState(false)
  const dialogVisible = useRef(false)

  const confirmDialog = useConfirmDialog()
  function handleCheckBoxChange (e: CheckboxChangeEvent) {
    if (e.target.checked) {
      storage.set(HIDDEN_REFRESH_CONFIRM_MODAL, Date.now())
    }
    else {
      storage.remove(HIDDEN_REFRESH_CONFIRM_MODAL)
    }
  }
  const showReloadConfirmDialog = useCallback(() => {
    const timestamp = storage.get(HIDDEN_REFRESH_CONFIRM_MODAL)
    const hidden = timestamp && dayjs(Date.now()).diff(timestamp, 'minute') < REFRESH_CONFIRM
    if(!hidden && !dialogVisible.current) {
      timestamp && storage.set(HIDDEN_REFRESH_CONFIRM_MODAL, Date.now())
      dialogVisible.current = true
      confirmDialog({
        title: '提示',
        okText: '刷新页面',
        content: <>
      数据与云端可能失去同步，点击刷新页面同步最新数据
          <Checkbox
            key="_"
            defaultChecked={timestamp} onChange={handleCheckBoxChange} 
            style={{ marginTop: 20 }}>十分钟内不再提示刷新</Checkbox>
        </>
      }).then(() => {
        window.location.reload()
      }).finally(() =>{
        dialogVisible.current = false
      })
    }
  }, [confirmDialog, dialogVisible])

  useEffect(() => {
    requests.length && setTimeout(() => {
      dispatch(statusActions.checkExpiredRequest())
    }, 1000)
  }, [dispatch, requests, checkRequestExpiredTimestamp])

  useEffect(() => {
    if (requests && requests.length) {
      setShowSaving(true)

      setTimeout(() => {
        setShowSaving(false)
      }, SHOW_STATUS_TIME_OUT / 2)
    }
  }, [requests])

  useEffect(() => {
    if (networkStatus === REQUEST_ERROR_TYPE.NETWORK_OFFLINE) {
      setOffline(true)
      setShowReconnected(true)
    } else {
      setOffline(false)
      if (showReconnected) {
        setTimeout(() => {
          setShowReconnected(false)
          showReloadConfirmDialog()
        }, SHOW_STATUS_TIME_OUT)
      }
    }
  }, [showReconnected, dispatch, networkStatus, showReloadConfirmDialog])

  useEffect(() => {
    if (userWsInitialized) {
      if (userWsStatus !== WSReadyState.OPEN) {
        setShowUserWsStatus(true)
      } else {
        if (showUserWsStatus) {
          setTimeout(() => {
            setShowUserWsStatus(false)
            showReloadConfirmDialog()
          }, SHOW_STATUS_TIME_OUT)
        }
      }
    }
  }, [showUserWsStatus, userWsStatus, userWsInitialized, showReloadConfirmDialog])

  useEffect(() => {
    if (orgWsInitialized) {
      if (orgWsStatus !== WSReadyState.OPEN) {
        setShowOrgWsStatus(true)
      } else {
        if (showOrgWsStatus) {
          setTimeout(() => {
            setShowOrgWsStatus(false)
            showReloadConfirmDialog()
          }, SHOW_STATUS_TIME_OUT)
        }
      }
    }
  }, [showOrgWsStatus, orgWsStatus, orgWsInitialized, showReloadConfirmDialog])

  return {
    isSaving: showSaving,
    isNetworkBroken: offline,
    isNetworkReconnected: showReconnected && !offline,
    isUserWsConnecting: showUserWsStatus,
    isOrgsWsConnecting: showOrgWsStatus
  }
}
