import type { PayloadAction } from '@reduxjs/toolkit'
import values from 'lodash/values'
import type { InfoWindowPOIType } from 'src/typings/map'
import type { State } from 'src/state/infoWindow/slice'
import infoWindowAdapter from './entityAdapter'

/**
 * @ignore
 */
export function add (state: State, action: PayloadAction<InfoWindowPOIType>): void {
  const { id } = action.payload
  let infoWindow = state.entities[id]
  state.order += 0.01
  if (infoWindow) {
    infoWindow.order = state.order

    infoWindowAdapter.updateOne(state, {
      id,
      changes: infoWindow
    })
  } else {
    infoWindow = action.payload
    infoWindow.order = state.order
    infoWindowAdapter.addOne(state, infoWindow)
  }
}

/**
 * @ignore
 */
export function updatePoi (state: State, action: PayloadAction<InfoWindowPOIType>): void {
  const { id } = action.payload
  infoWindowAdapter.updateOne(state, {
    id,
    changes: action.payload
  })
}

/**
 * @ignore
 */
function removeByLayerID (state: State, action: PayloadAction<{ ids: string[] }>): void {
  const pois = state.entities
  const ids: string[] = []
  values(pois).forEach((p) => {
    if (p && action.payload.ids.indexOf(p.extData?.layerID as string) > -1) {
      ids.push(p.id)
    }
  })

  infoWindowAdapter.removeMany(state, ids)
}

/**
 * @ignore
 */
export const reducers = {
  add,
  removeByLayerID,
  updatePoi
}
