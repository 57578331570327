import isNil from 'lodash/isNil'
import isNaN from 'lodash/isNaN'
import find from 'lodash/find'
import { createSelector } from '@reduxjs/toolkit'
import type { RootState } from 'src/store'
import type { Workspace } from 'src/typings/workspace'
import { workspaceSelectors } from 'src/state/workspace/slice'
import type { WorkspaceType } from 'src/hooks/common/useParameters'

interface SelectWorkspaceProps {
  workspaceId?: number;
  workspaceType: WorkspaceType
}
export const selectWorkspace = createSelector<RootState,
  SelectWorkspaceProps,
  Workspace[] | undefined,
  number | undefined,
  WorkspaceType,
  Workspace | undefined>(
    (state, _) => workspaceSelectors.selectAll(state),
    (_, props) => props.workspaceId,
    (_, props) => props.workspaceType,
    (workspaces, workspaceId, workspaceType) => {
      const type = workspaceType === 'uw' ? 'user' : 'org'
      return (isNil(workspaceId) || isNaN(workspaceId))
        ? find(workspaces, w => w.type === type)
        : find(workspaces, w => w.id === workspaceId && w.type === type)
    }
  )
