import React, { useEffect, useState } from 'react'
import { Layout } from 'antd'
import { QUMap as QUMapClass } from 'qumap/packages/core'
import useDispatch from 'src/hooks/common/useDispatch'
import { getLayerNodes } from 'src/state/project/actions'
import { useDialogState } from 'src/hooks/common/useDialog'
import { useParameters } from 'src/hooks/common/useParameters'
import { useWorkspaces } from 'src/hooks/workspace/useWorkspaces'
import { useProject } from 'src/hooks/project/useProject'
import { actions as infoWindowActions } from 'src/state/infoWindow/slice'
import MapSettingModal from 'src/components/map/MapSettingModal'
import MapSider from 'src/components/map/Sider'
import MapActions from 'src/components/project/MapActions'
import DetailPanel from 'src/components/detailPanel/DetailPanel'
import usePoiDetailPanel from 'src/hooks/map/usePoiDetailPanel'
import MapContainer from 'src/containers/Map'
import { actions as mapActions } from 'src/state/map/slice'
import ProjectActions from 'src/components/project/ProjectActions'
import ActionButton from 'src/components/project/ActionButton'
import { useOverlayEditor } from 'src/hooks/map/useMap'
import useMapFilter from 'src/hooks/map/useMapFilter'
import { PermissionPages, TableNodePermisson } from 'src/constants/permissions'
import { TablePermissionWrapper } from 'src/components/common/PermissionWrapper'
import { useWorkspaceRoles } from 'src/hooks/workspace/useWorkspaceRoles'
import { ROLE_ENTITY } from 'src/typings/workspace'
import usePagePermission from 'src/hooks/workspace/usePagePermission'
import { useDocumentTitle, getWorkspaceTitle } from 'src/hooks/common/useDocumentTitle'
import { MapContext } from 'src/context/mapContext'
import styles from './map.module.scss'

const { Content } = Layout
const Map: React.FC = () => {
  const dispatch = useDispatch()
  const [quMap, setQuMap] = useState<QUMapClass | null>(null)
  const { projectId, workspaceId, workspaceType } = useParameters()
  const mapSettingModal = useDialogState(false)
  const { workspace } = useWorkspaces(workspaceType, workspaceId)
  const { project } = useProject(workspace, projectId)
  const { selectedMapLayer } = useOverlayEditor()
  const { tableNodeId } = useMapFilter(selectedMapLayer?.layerNodeID)
  const { checkPermission } = useWorkspaceRoles()

  useDocumentTitle(project?.name, getWorkspaceTitle(workspace))

  usePagePermission(PermissionPages.map)

  const {
    poiDetail,
    detailPanel,
    handleDetailPanelClose,
    handleDelete
  } = usePoiDetailPanel(true)

  useEffect(() => {
    if (projectId) {
      dispatch(getLayerNodes(projectId))
    }

    return () => {
      dispatch(infoWindowActions.clear())
      dispatch(mapActions.updateEditor({
        enable: false,
        layerNodeID: undefined,
        isOpen: false,
        poi: undefined,
        preData: null
      }))
      dispatch(mapActions.updateCompare({
        layerNodeId: null,
        list: []
      }))
    }
  }, [projectId, dispatch])

  return (
    <MapContext.Provider value={{ quMap, setQuMap }}>
      <ProjectActions
        extra={
          <div className="hide">
            <TablePermissionWrapper id={tableNodeId} role={TableNodePermisson.MapExport}>
              <ActionButton
                icontype={'export'}
                text={'导出'}
              />
            </TablePermissionWrapper>
            <TablePermissionWrapper id={tableNodeId} role={TableNodePermisson.MapShare}>
              <ActionButton
                icontype={'share'}
                text={'分享'}
              />
            </TablePermissionWrapper>
          </div>
        }
      >
        <MapActions />
      </ProjectActions>
      <Layout className={styles.contentContainer}>
        <MapSider project={project} />
        <Content className={styles.content}>
          <MapContainer 
            drawerVisible={detailPanel.visible} 
          />
          <DetailPanel
            editable={checkPermission(poiDetail.tableNodeId, TableNodePermisson.DataEdit, ROLE_ENTITY.table)}
            tableNodeId={poiDetail.tableNodeId as number}
            drawer={detailPanel}
            cells={poiDetail.cells}
            title={poiDetail.title}
            layerNodeId={poiDetail.layerNodeId}
            rowId={poiDetail.rowId}
            onClose={handleDetailPanelClose}
            onDelete={handleDelete}
          />
        </Content>
        <MapSettingModal
          dialog={mapSettingModal}
          project={project}
        />
      </Layout>
    </MapContext.Provider>
  )
}
export default Map
