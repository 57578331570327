import React, { forwardRef, useImperativeHandle } from 'react'
import { PlusOutlined } from '@ant-design/icons'
import { ICellRendererParams } from 'ag-grid-community'
import styles from './girdRowAdder.module.scss'

interface Props extends ICellRendererParams {
  onRowAdd?: (tableNodeId: number, prevRowId?: string) => void;
  tableNodeId: number;
}

export default forwardRef((props: Props, ref) => {
  function handleClick () {
    const rowIndex = props.node.rowIndex as number
    const previousRow = props.api.getDisplayedRowAtIndex(rowIndex - 1)
    props.onRowAdd?.(props.tableNodeId, previousRow?.data.id)
  }

  useImperativeHandle(ref, () => ({}))

  return <div className={styles.row} onClick={handleClick}>
    <PlusOutlined className={styles.button} />
  </div>
})
