import React from 'react'
import { message } from 'antd'
import ReactDOM from 'react-dom/server'
import get from 'lodash/get'
import forEach from 'lodash/forEach'
import CellRenderer from 'src/components/field/CellRenderer/CellRenderer'
import { previewAttachmentEmitter } from 'src/utils/emitter'
import { getFileExt } from 'src/utils/attachment'
import TableNodesAPI from 'src/api/tablenodes'
import UploadAPI from 'src/api/upload'
import { uploadToCOS } from 'src/utils/upload'
import { AttachmentRenderer } from './AttachmentRenderer'

const getFile = (items: DataTransferItemList) => {
  let file = items[0]
  forEach(items, item => {
    if (item.kind === 'file') {
      file = item
    }
  })

  return file.getAsFile()
}

export class AttachmentCellRenderer extends CellRenderer {
  private attachmentListEL?: Element | null
  private addButtonEl?: Element | null

  init (p: CellRenderer['params']): void {
    this.value = p?.realValue
    this.params = p

    const el = this.createEL()
    el.innerHTML = ReactDOM.renderToStaticMarkup(<AttachmentRenderer value={this.value} editable={p?.editable} />)

    this.attachmentListEL = el.querySelector('[data-attachment="list"]')
    this.addButtonEl = el.querySelector('[data-attachment="add"]')
    this.attachmentListEL?.addEventListener('click', this.handlePreviewClick)
    this.addButtonEl?.addEventListener('click', this.startEditing)

    this.eGui = this.withCollaborators(el, this.params?.collaborator)
    if (p?.editable) {
      // FIXME: 在25.0..0版本中，粘贴clipboard内的图片到「附件」单元格时，无法触发「onpaste」事件，因此，将该事件绑定到document。
      //  该操作会导致一个paste触发所有render「附件」单元格时所绑定到document上的onpaste事件。
      //  但是在上传的时候做了限制，只上传到选中的「附件」单元格内。
      document.addEventListener('paste', this.handlePaste)
      this.eGui?.addEventListener('dragover', this.handleDragOver)
      this.eGui?.addEventListener('drop', this.handleDrop)
    }
  }

  private uploadAttachment = async (params: CellRenderer['params'], file: File) => {
    const tableNodeId = params?.tableNodeId
    const columnId = params?.column.getColId()
    const rowId = params?.data.id
    const focusedCell = params?.api.getFocusedCell()
    if (focusedCell && focusedCell.column.getColId() === columnId) {
      const focusedCellRowId = params?.api.getDisplayedRowAtIndex(focusedCell.rowIndex)?.id
      if (focusedCellRowId === rowId) {
        if (tableNodeId && columnId) {
          const loading = message.loading('文件上传中...', 0)
          try {
            const { detail } = await UploadAPI.getInfo('attachment')
            const { url } = await uploadToCOS(file, detail)

            const fileParams = {
              fileID: detail.fileID,
              fileName: file.name,
              fileSize: file.size,
              fileExt: getFileExt(file.name),
              fileType: file.type || 'unknown',
              url
            }

            await TableNodesAPI.addAttachment(tableNodeId as unknown as number, rowId, columnId, fileParams)
            message.success('文件上传成功')
          }
          finally {
            loading()
          }
        }
      }
    }
  }

  private handlePaste = (event: ClipboardEvent) => {
    event.clipboardData?.files[0] && this.uploadAttachment(this.params, event.clipboardData.files[0])
  }

  private handleDrop = (event: DragEvent) => {
    event.preventDefault()
    if (event.dataTransfer && event.dataTransfer.items.length > 0) {
      const file = getFile(event.dataTransfer.items)
      file && this.uploadAttachment(this.params, file)
    }
  }

  private handleDragOver = (event: DragEvent) => {
    event.preventDefault()
  }

  handlePreviewClick = (e: Event): void => {
    const currentPreviewId = get(e.target, 'dataset.id')
    if (currentPreviewId) {
      previewAttachmentEmitter.emit({
        currentPreviewId,
        attachments: this.value
      })
    }
  }

  getGui (): HTMLElement {
    return this.eGui
  }

  refresh (): boolean {
    return false
  }

  destroy (): void {
    this.attachmentListEL?.removeEventListener('click', this.handlePreviewClick)
    this.addButtonEl?.removeEventListener('click', this.startEditing)
    document.removeEventListener('paste', this.handlePaste)
    this.eGui?.removeEventListener('dragover', this.handleDragOver)
    this.eGui?.removeEventListener('drop', this.handleDrop)
  }
}
