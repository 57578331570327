import { useEffect, useState } from 'react'
import concat from 'lodash/concat'
import sortBy from 'lodash/sortBy'
import filter from 'lodash/filter'
import compact from 'lodash/compact'
import { arrayMove } from 'react-movable'
import { ViewColumn } from 'src/typings/tableNode'

interface Return {
  movableColumns: ViewColumn[]
  handleMove: (meta: { oldIndex: number, newIndex: number }) => void
  handleSwitchVisibility: (viewColumn: ViewColumn) => void
}

export default function useFieldList
(viewColumns: ViewColumn[], visibleIdsSorted?: string[], maxVisibleCount = 5): Return {
  const [movableColumns, setMovableColumns] = useState<ViewColumn[]>([])
  useEffect(() => {
    setMovableColumns(() => {
      const columnsSorted = sortBy(viewColumns, 'order')
      if(visibleIdsSorted && visibleIdsSorted.length > 0) {
        const visibleColumns = compact(visibleIdsSorted.map(id =>
          columnsSorted.find(c => c.id === id)))
          .map(c => ({ ...c, isHidden: false }))
        const hiddenColumns = filter(columnsSorted, c => visibleIdsSorted.indexOf(c.id) === -1)
          .map(c => ({ ...c, isHidden: true }))
        return concat(visibleColumns, hiddenColumns)
      }
      else {
        return columnsSorted.map(
          (c, index) => ({ ...c, isHidden: index >= maxVisibleCount })
        )
      }
    })
  }, [maxVisibleCount, viewColumns, visibleIdsSorted])
  function handleMove (meta: { oldIndex: number, newIndex: number }) {
    const { oldIndex } = meta
    let { newIndex } = meta
    if(movableColumns[newIndex].isHidden) {
      newIndex = movableColumns.findIndex(c => c.isHidden) - 1
    }
    setMovableColumns(arrayMove(movableColumns, oldIndex, newIndex))
  }
  function handleSwitchVisibility (viewColumn: ViewColumn) {
    setMovableColumns((movableColumns) => {
      const oldIndex = movableColumns.findIndex(c => c.id === viewColumn.id)
      const visibelCount = movableColumns.filter(c => !c.isHidden).length
      if(viewColumn.isHidden){
        if(visibelCount === maxVisibleCount) {
          return movableColumns
        }
        let newIndex = movableColumns.findIndex(c => c.isHidden)
        newIndex = newIndex === -1 ? 0 : newIndex
        movableColumns = arrayMove(movableColumns, oldIndex, newIndex)
      }
      else {
        if(visibelCount === 1) {
          return movableColumns
        }
        let newIndex = movableColumns.findIndex(c => c.isHidden)
        newIndex = newIndex === -1 ? movableColumns.length - 1 : newIndex - 1
        movableColumns = arrayMove(movableColumns, oldIndex, newIndex)
      }
      return movableColumns.map(c => (
        c.id === viewColumn.id ? { ...c, isHidden: !c.isHidden } : c
      ))
    }
    )
  }

  return {
    movableColumns,
    handleMove,
    handleSwitchVisibility
  }
}
