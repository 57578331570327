import React, { useState } from 'react'
import cx from 'clsx'
import { InputNumber, Form, Row, Col, Select, Input, Switch } from 'antd'
import type { FormInstance } from 'antd/lib/form/hooks/useForm'
import ColorPicker from 'src/components/common/ColorPicker'
import styles from './layerSettingPanel.module.scss'

interface Props {
  collapse?: boolean;
  form: FormInstance;
  onChange?: () => void;
}

export const LabelSetting: React.FC<Props> = ({
  collapse = false,
  form,
  onChange
}) => {
  const [isCollapse, setCollapse] = useState(collapse)

  function handleToggleClick () {
    setCollapse(!isCollapse)
  }

  function handleColorChange (color: string) {
    form.setFieldsValue({
      'labelConfig.fillColor': color
    })
    onChange?.()
  }

  return (
    <div className={styles.config}>
      <div className={styles.configHeader}>
        <div>标签样式</div>
        <div className={styles.configToggle} onClick={handleToggleClick}>
          {isCollapse ? '展开' : '收起'}
        </div>
      </div>
      <div className={cx(styles.container, { [styles.collapse]: isCollapse })}>
        <Row gutter={[8, 8]}>
          <Col span={12}>
            <div className={styles.box}>
              <div className={styles.configLabel}>是否展示</div>
              <Form.Item name="labelConfig.visible" valuePropName="checked">
                <Switch />
              </Form.Item>
            </div>
          </Col>
          <Col span={12}>
            <div className={styles.box}>
              <div className={styles.configLabel}>字体颜色</div>
              <ColorPicker color={form.getFieldValue('labelConfig.fillColor')} onChange={handleColorChange} />
            </div>
            <Form.Item name="labelConfig.fillColor" hidden>
              <Input />
            </Form.Item>
          </Col>
          <Col span={12}>
            <div className={styles.box}>
              <div className={styles.configLabel}>字号</div>
              <Form.Item name="labelConfig.fontSize">
                <InputNumber min={10} max={30} />
              </Form.Item>
            </div>
          </Col>
          <Col span={12}>
            <div className={styles.box}>
              <div className={styles.configLabel}>标签位置</div>
              <Form.Item name="labelConfig.direction">
                <Select>
                  <Select.Option value="top">上方</Select.Option>
                  <Select.Option value="right">右方</Select.Option>
                  <Select.Option value="bottom">下方</Select.Option>
                  <Select.Option value="left">左方</Select.Option>
                  <Select.Option value="center">居中</Select.Option>
                </Select>
              </Form.Item>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  )
}
