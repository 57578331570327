import { Project, RecycleProject } from 'src/typings/project'
import { RoleType } from 'src/constants/permissions'

export enum ROLE_ENTITY {
  org = 'org',
  project = 'project',
  table = 'table'
}

export type RoleEntityType = keyof typeof ROLE_ENTITY

export type Workspace = {
  id: number,
  name: string,
  avatar?: string,
  type: 'org' | 'user'
}
export type WorkspaceDetail = {
  id: number,
  type: 'org' | 'user',
  detail: {
    projects: Project[];
    recycleProjects?: RecycleProject[];
    unreadNotificationsCount: number;
  }
}

export interface WorkspaceRole {
  id: number
  type: RoleEntityType
  role: RoleType
}
