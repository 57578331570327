import { useEffect,useState } from 'react'
import storage from 'store2'
import dayjs from 'dayjs'
import { LOGIN_VERIFICATION_CODE } from 'src/constants/storage'

const VERIFICATION_CODE_PERIOD = 60

interface Return {
  count: number;
  isRequestCodeClick: boolean;
  setCount: (p: number) => void;
  setIsRequestCodeClick: (p: boolean) => void;
}

export default function useLogin (): Return {
  const [count, setCount] = useState(0)
  const [isRequestCodeClick, setIsRequestCodeClick] = useState(false)

  useEffect(() => {
    const now = Date.now()
    const loginVerificationCodeTimestamp = storage.get(LOGIN_VERIFICATION_CODE)
    const secondsToReSend = VERIFICATION_CODE_PERIOD - dayjs(now).diff(loginVerificationCodeTimestamp, 'second')
    if (secondsToReSend > 0) {
      setCount(secondsToReSend)
    }
  }, [])

  useEffect(() => {
    const timer = setTimeout(() => {
      setCount(count - 1)
    }, 1000)

    if (count === 0) {
      clearTimeout(timer)
    }

    return () => clearTimeout(timer)
  }, [count])

  return {
    count,
    setCount,
    isRequestCodeClick,
    setIsRequestCodeClick
  }
}
