import { 
  deleteTableNode, 
  undoDeleteTableNode, 
  redoDeleteTableNode
} from 'src/state/tableNode/actions'
import type { UndoRedo } from 'src/features/undoRedo/undoRedo'

const updateCellsUndoRedo = {
  action: deleteTableNode,
  undoAction: undoDeleteTableNode,
  redoAction: redoDeleteTableNode,
  getUndoPayload: (arg: { id: number }) => {
    const { id } = arg

    return id
  },
  getRedoPayload: (arg: { id: number }) => {
    return {
      id: arg.id
    }
  }
} as UndoRedo

export default updateCellsUndoRedo