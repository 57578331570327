import { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import set from 'lodash/set'
import startsWith from 'lodash/startsWith'
import forEach from 'lodash/forEach'
import some from 'lodash/some'
import { useParameters } from 'src/hooks/common/useParameters'
import useSelector from 'src/hooks/common/useSelector'
import * as LayerNodeActions from 'src/state/layerNode/actions'
import type { AppDispatch } from 'src/store'
import type { Column } from 'src/typings/tableNode'
import type { LayerNode, MapInfo } from 'src/typings/mapTableNode'
import { tableNodeSelectors } from 'src/state/tableNode/slice'
import { store } from 'src/store'

interface UseLayerSettingReturn {
  layerNode?: LayerNode;
  updateSetting: (layerId: string, params: Record<string, string>, isPreview?: boolean) => void;
  cancelPreviewSetting: (layerId: string) => void;
  getPreviewMapInfo: () => MapInfo
}

const PREFIX_KEYS = ['labelConfig', 'fillConfig', 'outlineConfig', 'radiusConfig']

export function useLayerSetting (layerId: string): UseLayerSettingReturn {
  const { projectId } = useParameters()
  const dispatch: AppDispatch = useDispatch()
  const layer = useSelector(state => {
    return state.project.layerNodes[projectId].find(l => l.id === layerId)
  })

  const updateSetting = useCallback(async (
    layerId: string,
    params: Record<string, unknown>,
    isPreview = false
  ) => {
    const mapInfo: Partial<MapInfo> = {
      tableNodeID: params.tableNodeID as number,
      labelFieldID: params.labelFieldID as string,
      coordinateFieldID: params.coordinateFieldID as string,
      fillFieldID: params.fillFieldID as string,
      radiusFieldID: params.radiusFieldID as string
    }

    forEach(params, (val, key) => {
      if (some(PREFIX_KEYS, k => startsWith(key, k))) {
        const [configName, propertyName] = key.split('.')
        set(mapInfo, `${configName}.${propertyName}`, val)
      }
    })

    const layerNodeParams = {
      id: layerId,
      projectID: projectId,
      name: layer?.name,
      mapInfo
    }

    if (isPreview) {
      await dispatch(LayerNodeActions.previewLayerNode(layerNodeParams as LayerNode))
    } else {
      await dispatch(LayerNodeActions.update(layerNodeParams as LayerNode))
      dispatch(LayerNodeActions.previewLayerNode({ id: layerId } as LayerNode))
    }
  }, [dispatch, projectId, layer?.name])

  const cancelPreviewSetting = useCallback(async (
    layerId: string
  ) => {
    await dispatch(LayerNodeActions.previewLayerNode({ id: layerId } as LayerNode))
  }, [dispatch])

  const getPreviewMapInfo = useCallback(() => store.getState().project.previewMapInfos[layerId], [layerId])

  return {
    layerNode: layer,
    updateSetting,
    cancelPreviewSetting,
    getPreviewMapInfo
  }
}

export function useFieldByType (tableId: number, columnType: string): Column[] | undefined {
  return useSelector(state => {
    const tableNode = tableNodeSelectors.selectById(state, tableId)
    return tableNode?.columns.filter((c: Column) => c.type === columnType)
  })
}
