/* eslint-disable @typescript-eslint/no-explicit-any */
import divide from 'lodash/divide'
import find from 'lodash/find'
import groupBy from 'lodash/groupBy'
import map from 'lodash/map'
import maxBy from 'lodash/maxBy'
import minBy from 'lodash/minBy'
import orderBy from 'lodash/orderBy'
import sumBy from 'lodash/sumBy'
import filter from 'lodash/filter'
import every from 'lodash/every'
import values from 'lodash/values'
import omitBy from 'lodash/omitBy'
import pick from 'lodash/pick'
import isEmpty from 'lodash/isEmpty'
import round from 'lodash/round'
import forEach from 'lodash/forEach'
import includes from 'lodash/includes'
import pickBy from 'lodash/pickBy'
import first from 'lodash/first'
import curry from 'lodash/curry'
import { flatten, unflatten } from 'flat'
import { ChartType, ChartOptions } from 'src/typings/chart'
import { TypeOptions } from 'src/typings/tableNode'
import { ChartOption } from 'src/components/common/Chart/Chart'
import { formatDateByRule, padNumberByPrecisionWithFormat } from 'src/utils/format'

type LabelType = {
  show: boolean
}

type LegendType = {
  show: boolean
  bottom?: number
  left?: string
  top?: string
}

const CHART_OPTION_BASE: ChartOption = {
  grid: {
    top: 30,
    bottom: 60,
    left: 50,
    right: 0
  },
  tooltip: { show: true },
  xAxis: { type: 'category' },
  yAxis: { type: 'value' }
}

const getFieldValueByForm = curry((form: Record<string, any>, fieldIdentifier: string) => {
  return first(values(pickBy(form, (_, key) => includes(key, fieldIdentifier))))
})

export function transformTypeOptionsToChartOptions (
  typeOptions: Pick<TypeOptions, 'columns' | 'charts' | 'activeChart'>,
  value: Record<string, any>
): ChartOption {
  const activeChart = typeOptions.activeChart ?? first(typeOptions.charts)?.type ?? 'bar'
  const chart = find(typeOptions.charts, chart => chart.type === activeChart)

  return transformFormToOption(flatten(chart ?? {}, { safe: true }), value, typeOptions.columns, activeChart)
}

export function transformToTypeOptionCharts (
  flattenCharts?: Record<ChartType, Record<string, unknown>>
): ChartOptions[] {
  return map(flattenCharts, (value) => unflatten(value))
}

const getLineData = (
  form: Record<string, any>,
  groupedSource: Record<string, any>,
  columns: TypeOptions['columns'],
  label: LabelType,
  legend: LegendType
) => {
  let xAxisData
  let yAxisData: Record<string, any> = {}
  const getFieldValue = getFieldValueByForm(form)
  const statsMethod = getFieldValue('stats.method')
  const statsFields = getFieldValue('stats.fields')
  const statsAggregator = getFieldValue('stats.aggregator')
  const sortMethod = getFieldValue('sortMethod')
  const type = getFieldValue('type')

  if (statsMethod === 'count') {
    const data = map(groupedSource, (v, k) => ({ key: k, val: v.length }))
    const sorted = sort(data, sortMethod)

    xAxisData = map(sorted, d => d.key)
    yAxisData = {
      name: '数量',
      data: map(sorted, d => d.val)
    }
  }

  if (statsMethod === 'singleField') {
    const data = map(groupedSource, (v, k) =>
      ({
        key: k,
        val: calc(v, statsFields[0], statsAggregator)
      })
    )

    const sorted = sort(data, sortMethod)

    xAxisData = map(sorted, d => d.key)
    yAxisData = {
      name: find(columns, c => c.id === statsFields[0])?.name,
      data: map(sorted, d => d.val)
    }
  }

  if (statsMethod === 'multiField') {
    xAxisData = sort(Object.keys(groupedSource), sortMethod)
    yAxisData = map(statsFields, field => {
      return {
        name: find(columns, c => c.id === field)?.name,
        data: sort(map(groupedSource, v => calc(v, field, statsAggregator)), sortMethod)
      }
    })
  }

  const series = statsMethod !== 'multiField' ?
    [{
      ...yAxisData,
      label,
      type
    }] :
    map(yAxisData, data => {
      return {
        ...data,
        label,
        type
      }
    })

  return {
    ...CHART_OPTION_BASE,
    color: getFieldValue('colors'),
    legend,
    series,
    xAxis: {
      type: 'category',
      data: xAxisData
    }
  } as ChartOption
}

const getPieSeries = (
  pieData: { name: string, value: number | undefined }[],
  label: LabelType,
  type: string
) => {
  return {
    data: pieData,
    // FIXME: check pie series name property
    // [ECharts] `[object Object]` is invalid id or name. Must be a string or number.
    // name: label,
    radius: '50%',
    center: ['50%', '40%'],
    type
  }
}

const getPieData = (
  form: Record<string, any>,
  groupedSource: Record<string, any>,
  columns: TypeOptions['columns'],
  label: LabelType,
  legend: LegendType
) => {
  let pieData: { name: string, value: number | undefined }[] = []
  const getFieldValue = getFieldValueByForm(form)
  const statsMethod = getFieldValue('stats.method')
  const statsFields = getFieldValue('stats.fields')
  const statsAggregator = getFieldValue('stats.aggregator')
  const type = getFieldValue('type')

  if (statsMethod === 'count') {
    pieData = orderBy(map(groupedSource, (v, k) => ({ name: k, value: v.length })), ['value'], ['desc'])
  } else if (statsMethod === 'singleField') {
    pieData = orderBy(map(groupedSource, (v, k) => ({
      name: k,
      value: calc(v, statsFields, statsAggregator)
    })), ['value'], ['desc'])
  }

  if (legend.show) {
    legend = {
      ...legend,
      left: 'center',
      top: 'bottom'
    } as LegendType
  }

  const series: any = [{
    ...getPieSeries(
      pieData,
      label,
      type
    ),
    label: {
      formatter:
        (a: any) => {
          return `${a.name}${label.show ? (' ' + round(a.value, 2)) : ''}`
        }
    }
  }]

  if (getFieldValue('enableShowPercentage')) {
    series.push({
      ...getPieSeries(
        pieData,
        label,
        type
      ),
      label: {
        position: 'inner',
        formatter: (a: any) => {
          return parseFloat(a.percent).toFixed(2) + '%'
        }
      }
    })
  }

  return {
    tooltip: CHART_OPTION_BASE.tooltip,
    color: getFieldValue('colors'),
    legend,
    series
  }
}

export function transformFormToOption (
  form: Record<string, unknown>,
  value: Record<string, any>,
  columns: TypeOptions['columns'],
  type: ChartType
): ChartOption {
  const getFieldValue = getFieldValueByForm(form)
  const chartValue = map(value, v => {
    const formattedValue = { ...v }

    forEach(columns, col => {
      switch (col.type) {
      case 'datetime':
        formattedValue[col.id] = formatDateByRule(v[col.id], {
          dateFormat: col.datetimeDateFormat as string,
          timeFormat: col.datetimeTimeFormat as string
        })
        break
      case 'number':
        formattedValue[col.id] = padNumberByPrecisionWithFormat(
          v[col.id],
          col.numberPrecision ?? 0,
          col.numberFormat
        )
        break
      }
    })

    return formattedValue
  })

  let groupedSource = groupBy(chartValue, getFieldValue('groupBy'))

  delete groupedSource['undefined']

  if (getFieldValue('stats.method') !== 'count' && !getFieldValue('enableShowNullAndZeroValue')) {
    groupedSource = omitBy(groupedSource, (sources) => {
      return every(sources, source => {
        return isEmpty(values(pick(source, getFieldValue('stats.fields'))))
      })
    })
  }

  const label = getFieldValue('enableShowValue') ? { show: true } : { show: false }
  const legend = getFieldValue('enableShowPanelExample') ? { show: true, bottom: 10 } : { show: false }

  switch (type) {
  case 'pie':
    return getPieData(form, groupedSource, columns, label, legend)
  case 'line':
  case 'bar':
    return getLineData(form, groupedSource, columns, label, legend)
  default:
    return {}
  }
}

function sort (data: any[], method: string) {
  if (method === 'listDesc') {
    return [...data].reverse()
  }
  if (method === 'yAxisAsc') {
    return orderBy(data, 'val', 'asc')
  }
  if (method === 'yAxisDesc') {
    return orderBy(data, 'val', 'desc')
  }
  return data
}

function calc (list: any[], key: string, method: string) {
  const filtered = filter(list, x => x[key] !== undefined)
  if (filtered.length === 0) {
    return undefined
  }
  const iteratee = (x: any) => {
    return round(parseFloat(x[key]), 2)
  }
  if (method === 'sum' || method === 'avg') {
    const total = sumBy(filtered, iteratee)
    return round(method === 'sum' ? total : divide(total, filtered.length), 2)
  }
  if (method === 'max') {
    return round(maxBy(filtered, iteratee)[key], 2)
  }
  if (method === 'min') {
    return round(minBy(filtered, iteratee)[key], 2)
  }

  return NaN
}
