import { useEffect, useState } from 'react'
import forEach from 'lodash/forEach'
import sortBy from 'lodash/sortBy'
import { FillConfig } from 'qumap/packages/core/types/types'
import { useLegendTableNodes } from 'src/hooks/common/useTableNode'
import { GroupLayers, MapLegend, MapLegendItem } from 'src/typings/map'
import getLegends from 'src/utils/legend'

interface LegendReturn {
  mapLegends: MapLegend[]
}

export default function useLegend (groupLayers: GroupLayers): LegendReturn {
  const { columns } = useLegendTableNodes()
  const [mapLegends, setMepLegends] = useState<MapLegend[]>([])
  useEffect(() => {
    if (groupLayers) {
      const legends: MapLegend[] = []
      forEach(groupLayers.data, (layer,index) => {
        let legendName = layer.name
        let legendItems : MapLegendItem[] = []
        if(layer.mapInfo.fillFieldID) {
          const column = columns?.[layer.mapInfo.fillFieldID]
          legendName = column?.name ?? ''
          const fillConfig = groupLayers.config.layers?.[index]?.fillConfig as FillConfig
          if(column?.type === 'singleChoice'){
            legendItems = getLegends(layer, fillConfig, true, column.typeOptions)
          }
          else {
            legendItems = getLegends(layer, fillConfig)
          }
        }
        else {
          legendItems = getLegends(layer)
        }
        legends.push({
          legends: legendItems,
          zIndex: layer.zIndex,
          name: legendName
        })
      })

      setMepLegends(sortBy(legends, 'zIndex').reverse())
    }
  }, [groupLayers, columns])

  return {
    mapLegends
  }
}
