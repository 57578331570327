import React, { useCallback, useState } from 'react'
import isEmpty from 'lodash/isEmpty'
import omit from 'lodash/omit'
import merge from 'lodash/merge'
import cx from 'clsx'
import { useIntl } from 'react-intl'
import { Modal } from 'antd'
import { ModalFuncProps } from 'antd/lib/modal'
import Icon from 'src/components/common/Icon'
import styles from 'src/components/common/Dialog/dialog.module.scss'

export interface DialogStateReturn {
  visible: boolean;
  open: () => void;
  close: () => void;
}

export function useDialogState (initialValue = false): DialogStateReturn {
  const [visible, setVisible] = useState(initialValue)
  const open = useCallback(() => {
    setVisible(true)
  }, [setVisible])

  const close = useCallback(() => {
    setVisible(false)
  }, [setVisible])

  return {
    visible,
    open,
    close
  }
}

interface MessageIds {
  title?: string;
  content?: string;
  okText?: string;
  cancelText?: string;
}

function useMessages (props: ConfirmDialogProps) {
  const intl = useIntl()
  const { messageIds } = props 
  const defaultOkText = intl.formatMessage({ id: 'confirm' })
  const defaultCancelText = intl.formatMessage({ id: 'cancel' }) 
  const title = messageIds?.title ? intl.formatMessage({ id: messageIds?.title }) : props.title
  const content = messageIds?.content ? intl.formatMessage({ id: messageIds?.content }) : props.content
  const okText = messageIds?.okText 
    ? intl.formatMessage({ id: messageIds?.okText, defaultMessage: defaultOkText }) 
    : props.okText ?? defaultOkText
  const cancelText = messageIds?.cancelText 
    ? intl.formatMessage({ id: messageIds?.cancelText, defaultMessage: defaultCancelText }) 
    : props.cancelText ?? defaultCancelText

  return {
    title,
    content,
    okText,
    cancelText
  }
}
export interface ConfirmDialogProps extends ModalFuncProps {
  messageIds?: MessageIds,
  messageValues?: Record<string, string>
}

type ConfirmDialogReturn = (props?: ConfirmDialogProps) => Promise<unknown>
export function useConfirmDialog (options: ConfirmDialogProps = {}): ConfirmDialogReturn {
  const intl = useIntl()
  const {
    title,
    content,
    okText,
    cancelText
  } = useMessages(options)

  const confirmDialog = useCallback((props: ConfirmDialogProps = {}) => {
    const messageValues = merge({}, options.messageValues, props.messageValues)
    let updatedContent = content
    if (!isEmpty(messageValues) && options.messageIds?.content) {
      updatedContent = intl.formatMessage({ id: options.messageIds?.content }, merge({}, messageValues, {
        highlight: (s: string) => <strong>{s}</strong>
      })) 
    }
    
    const renderContent = () => {
      return updatedContent && <div className={styles.content}>{updatedContent}</div>
    }

    return new Promise((resolve, reject) => {
      const modal = Modal.confirm(
        merge(
          {}, 
          {
            className: cx(options.className, props.className),
            title,
            content: renderContent(),
            okText: okText,
            closable: true,
            closeIcon: <Icon type="close" />,
            icon: undefined,
            okButtonProps: {
              type: 'primary'
            },
            cancelText: cancelText,
            cancelButtonProps: {
              className: 'gray-btn'
            },
            onOk: () => {
              resolve(true)
              modal.destroy()
            },
            onCancel: () => {
              reject('cancel')
              modal.destroy()
            }
          }, 
          omit(options, ['className']),
          omit(props, ['className'])
        )
      )
    })
  }, [title, okText, cancelText, content, options, intl])

  return confirmDialog
}

export function useDeleteConfirmDialog (props: ConfirmDialogProps): ConfirmDialogReturn {
  return useConfirmDialog(merge({}, {
    className: styles.deleteConfirmDialog,
    okButtonProps: {
      danger: true
    },
    messageIds: {
      okText: 'delete'
    }
  }, props))
}