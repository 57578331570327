import range from 'lodash/range'
import keyBy from 'lodash/keyBy'
import mapValues from 'lodash/mapValues'
import pickBy from 'lodash/pickBy'

// eslint-disable-next-line @typescript-eslint/no-var-requires
const colorbrewer = require('colorbrewer').default

const { sequential, singlehue, diverging, qualitative } = colorbrewer.schemeGroups
const COLOR_SCHEMES = [...singlehue,...sequential, ...diverging,...qualitative]
export const FILL_COLORS_STEP = range(3, 12)

export const COLOR_PALETTE_WITH_STEP = mapValues(keyBy(FILL_COLORS_STEP), step => {
  return pickBy(mapValues(keyBy(COLOR_SCHEMES), schemeName => colorbrewer[schemeName][step as number]))
})


export const PICKER_COLORS = {
  redLight: '#F5B6B6',
  salmonLight: '#F6C6BB',
  organeLight: '#FDDCBE',
  yellowLight: '#FFEFB8',
  greenLight: '#C7EADB',
  cyanLight: '#B8EBF4',
  blueLight: '#B8D4FF',
  purpleLight: '#D4CFEE',
  lilacLight: '#EFCFFB',
  greyLight: '#E1E4EA',
  redLighter: '#FADADA',
  salmonLighter: '#FBE7E2',
  orangeLighter: '#FEF0E3',
  yellowLighter: '#FFF8E0',
  greenLighter: '#E7F6F0',
  cyanLighter: '#E0F6FB',
  blueLighter: '#E0EDFF',
  purpleLighter: '#EDEAF8',
  lilacLighter: '#F6E5FC',
  greyLighter: '#EDEEF2'
   
}

export type Color = keyof typeof PICKER_COLORS | undefined
