import React from 'react'
import truncate from 'lodash/truncate'
import styles from './singleLineText.module.scss'

interface Props {
  value?: string;
  truncateLength?: number;
}

export const SingleLineTextRenderer: React.FC<Props> = ({
  value = '',
  truncateLength
}) => {
  return (
    <div className={styles.renderer}>
      <div className={styles.text}>{truncateLength ? truncate(value, { length: truncateLength }) : value}</div>
    </div>
  )
}
