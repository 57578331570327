import React from 'react'
import ReactDOM from 'react-dom/server'
import CellRenderer from 'src/components/field/CellRenderer/CellRenderer'
import { TablePreviewCellRenderer } from './TablePreviewCellRenderer'

export class TableCellRenderer extends CellRenderer {
  private expandButton?: Element | null
  init (p: CellRenderer['params']): void {
    this.value = p?.realValue
    this.typeOptions = p?.typeOptions
    this.params = p
    const el = this.createEL()

    el.innerHTML = ReactDOM.renderToStaticMarkup(
      <TablePreviewCellRenderer
        value={this.value}
        typeOptions={this.typeOptions ?? { columns:[], charts: null, activeChart: null }} />)

    this.expandButton = el.querySelector('[data-table="openModal"]')
    this.expandButton?.addEventListener('click', this.startEditing)

    this.eGui = this.withCollaborators(el, this.params?.collaborator)
  }
  getGui (): HTMLElement {
    return this.eGui
  }

  refresh (): boolean {
    return false
  }

  destroy (): void {
    this.expandButton?.removeEventListener('click', this.startEditing)
  }
}
