import AppConfig from 'src/config'
import { createInstance } from 'src/utils/fetch'
import type { APIResponse } from 'src/typings/common'

export interface RefreshTokenResponse {
  refreshToken: string
  token: string
}

interface User {
  username: string;
  isAuth: boolean;
}

const fetch = createInstance({
  baseURL: `${AppConfig.baseApiURL}/auth/`
})

const AuthAPI = {
  async login (phoneNumber: string, code: string, registrationCode?: string): Promise<User & RefreshTokenResponse> {
    const { data } = await fetch.post('loginWithPhone/', {
      phoneNumber,
      code,
      registrationCode
    })

    return {
      ...data.detail,
      token: data.extra.token,
      refreshToken: data.extra.refreshToken,
      requestID: data.requestID
    }
  },
  async getCode (phoneNumber: string): Promise<unknown> {
    const { data } = await fetch.post('requestPhoneCode/', {
      phoneNumber
    })

    return data
  },
  async validateInviteCode (code: string): Promise<APIResponse<boolean>> {
    const { data } = await fetch.get('validateRegistrationCode/', {
      params: {
        code
      }
    })

    return data
  }
}

export default AuthAPI
