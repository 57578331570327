import { createAsyncThunk } from '@reduxjs/toolkit'
import CollaboratorAPI from 'src/api/collaborator'
import { CollaboratorParams } from 'src/typings/collaborator'

export const update = createAsyncThunk(
  'collaborator/update_async',
  async (params: CollaboratorParams) => {
    const { projectID, selected, sessionID } = params
    return await CollaboratorAPI.update(projectID, selected, sessionID)
  }
)
