/**
 * @description
 * 获取 infowindow 初始的位置
 * infowindow 开启时向左向上做偏移
 * 避免打开的 infowindow 直接覆盖在点击的元素上
 * 同时将 infowindow 打开的位置控制在地图显示范围内
 * 280 为手动调整的数字
 * 156 为 infowindow 宽度 1/2
 * 240 为左侧菜单栏宽度
 */
import type { LngLat } from 'qumap/packages/core/types/types'
import { QUMap as QUMapClass } from 'qumap/packages/core'
import { POIPosition } from 'src/typings/map'

export function getInfoWindowPosition (pixel = { x: 156, y: 280 }): {
  left: number;
  top: number;
} {
  const x = pixel.x - 160
  const y = pixel.y - 280
  return {
    left: x < 0 ? 0 : x,
    top: y < 0 ? 0 : y
  }
}

export function transformToTopLeft (element: HTMLDivElement): { top: number; left: number } {
  const style = window.getComputedStyle(element)
  const matrix = new DOMMatrixReadOnly(style.transform)
  return {
    top: matrix.m42 + parseInt(style.top),
    left: matrix.m41 + parseInt(style.left)
  }
}

/**
 * @description
 * 获取 infowindow 与地图连线的位置
 * 88 为地图上边缘到浏览器窗口上边缘的距离（header的高度）
 */
export function getInfoWindowAnchorPosition (
  position: POIPosition,
  el: HTMLElement,
  map: QUMapClass['amap']
): LngLat {
  const offsetLeft = window.innerWidth - map.getSize().width

  return map.containerToLngLat([
    (position.left - offsetLeft) + el.offsetWidth / 2,
    position.top + el.offsetHeight - 88
  ])
}
