import find from 'lodash/find'
import isEmpty from 'lodash/isEmpty'
import toNumber from 'lodash/toNumber'
import map from 'lodash/map'
import compact from 'lodash/compact'
import join from 'lodash/join'
import isNil from 'lodash/isNil'
import sortBy from 'lodash/sortBy'
import { TypeOptions, ColumnType } from 'src/typings/tableNode'

function stringComparator (a: string, b: string): number {
  if (a === b) {
    return 0
  } else {
    return (a > b) ? 1 : -1
  }
}

function numberComparator (a?: number | string | null, b?: number | string | null): number {
  if (isNil(a) && isNil(b)) return 0

  if (isNil(a)) return -1

  if (isNil(b)) return 1
  
  return toNumber(a) - toNumber(b)
}

function arrayComparator (a?: null | Record<string, unknown>[], b?: null | Record<string, unknown>[]): number {
  if ((isNil(a) && isNil(b)) || (isEmpty(a) && isEmpty(b))) return 0

  if (isNil(a) || isEmpty(a)) return -1

  if (isNil(b) || isEmpty(b)) return 1

  return 1
}

interface ComparatorValue {
  realValue?: string | number | string[] | Record<string, unknown>[] | null;
  typeOptions: TypeOptions;
  type: ColumnType;
}
export function comparator (a: ComparatorValue, b: ComparatorValue): number {
  let aValue = a.realValue 
  let bValue = b.realValue
  const aTypeOptions = a.typeOptions
  const bTypeOptions = b.typeOptions
  const type = a.type
  const isFormula = type === 'formula'
  const isNumberFormula = isFormula && aTypeOptions.expectedType === 'number'
  const isStringFormula = isFormula && aTypeOptions.expectedType === 'singleLineText'
    
  if (type === 'singleLineText' || type === 'multiLineText' || isStringFormula) {
    aValue = aValue ?? ''
    bValue = bValue ?? ''
    return stringComparator(aValue as string, bValue as string)
  }

  if (type === 'number' || isNumberFormula) {
    return numberComparator(aValue as number, bValue as number)
  }

  if (type === 'singleChoice') {
    const aChoice = find(aTypeOptions.choices, c => c.id === aValue)?.name ?? ''
    const bChoice = find(bTypeOptions.choices, c => c.id === bValue)?.name ?? ''

    return stringComparator(aChoice, bChoice)
  }

  if (type === 'multiChoice') {
    const aChoices = join(
      sortBy(compact(map(aValue as string[], v => find(aTypeOptions.choices, c => c.id === v)?.name)))
    )
    const bChoices = join(
      sortBy(compact(map(bValue as string[], v => find(bTypeOptions.choices, c => c.id === v)?.name)))
    )

    return stringComparator(aChoices, bChoices) 
  }

  if (type === 'datetime') {
    const aDateTime = aValue && new Date(aValue as string).getTime()
    const bDateTime = bValue && new Date(bValue as string).getTime()

    return numberComparator(aDateTime, bDateTime)
  }

  if (type === 'attachment') {
    return arrayComparator(aValue as [], bValue as [])
  }

  return 0
}