
import { useState } from 'react'
import concat from 'lodash/concat'
import useDispatch from 'src/hooks/common/useDispatch'
import { useParameters } from 'src/hooks/common/useParameters'
import { AppDispatch } from 'src/store'
import { matchUser, searchUsers } from 'src/state/user/actions'

export interface UserInfo {
  id: number;
  name?: string;
  phone: string;
  avatar?: string;
  notRegistered?: boolean
}

interface Return {
  matchedUsers: UserInfo[]
  setMatchedUsers: (v: UserInfo[]) => void
  getMatchUser: (phone: string) => Promise<unknown[]>
  getMatchUsersInOrg: (query: string) => Promise<[]>
  selectedUsers: number[]
  setSelectedUsers: (v: number[]) => void
}
export function useMatchUser (): Return {
  const { workspaceId } = useParameters()
  const dispatch: AppDispatch = useDispatch()
  const [matchedUsers, setMatchedUsers] = useState<UserInfo[]>([])
  const [selectedUsers, setSelectedUsers] = useState<number[]>([])
  async function getMatchUser (phone: string) {
    const { payload } = await dispatch(matchUser(phone)) 
    if(payload) {
      const user = { ...(payload as UserInfo), phone }
      setMatchedUsers(concat(matchedUsers, user))
    }
    return payload ? [payload] : []
  }
  async function getMatchUsersInOrg (query: string) {
    const { payload } = await dispatch(searchUsers({
      query,
      orgId: workspaceId
    })) 
    if(payload) {
      setMatchedUsers(concat(matchedUsers, payload as UserInfo))
    }
    return payload as []
  }

  return {
    selectedUsers,
    setSelectedUsers,
    matchedUsers,
    setMatchedUsers,
    getMatchUser,
    getMatchUsersInOrg
  }
}
