import React from 'react'
import truncate from 'lodash/truncate'
import Icon from 'src/components/common/Icon'
import styles from './coordinate.module.scss'

interface Props {
  value?: string;
  truncateLength?: number;
  isPlain?: boolean;
}

export const CoordinateRenderer: React.FC<Props> = (props) => {
  const { value, truncateLength, isPlain } = props
  return (
    <div className={styles.renderer}>
      <div className={styles.text}>{
        value 
          ? truncateLength ? truncate(value, { length: truncateLength }) : value
          : <span className={styles.placeholder}>{!isPlain && '点击标记'}</span>}
      </div>
      {!isPlain && <Icon type="mapPin" className={styles.mapPin} />}
    </div>
  )
}
