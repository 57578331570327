import { createSlice, EntityState } from '@reduxjs/toolkit'
import { RootState } from 'src/store'
import { TableNode } from 'src/typings/tableNode'
import { reducers } from './reducers'
import {
  getTableNodeDetails,
  createRow,
  updateCells,
  createTableNode,
  updateTableNode,
  moveTableNode,
  addAttachment,
  deleteTableNode
} from './actions'
import tableNodeAdapter from './entityAdapter'

export interface SearchResult {
  rowIndex: number,
  colId: string;
}

export interface State extends EntityState<TableNode> {
  searchResults: SearchResult[]
}

const initialState = tableNodeAdapter.getInitialState<State>({ 
  ids: [],
  entities: {},
  searchResults: []
})

const tableNodeSlice = createSlice({
  name: 'tableNode',
  initialState,
  reducers: {
    clear: tableNodeAdapter.removeAll,
    ...reducers
  },
  extraReducers (builder) {
    builder.addCase(getTableNodeDetails.rejected, (_, action) => {
      throw(action.error)
    }).addCase(createRow.rejected, (_, action) => {
      throw(action.error)
    }).addCase(updateCells.rejected, (_, action) => {
      throw(action.error)
    }).addCase(createTableNode.rejected, (_, action) => {
      throw(action.error)
    }).addCase(updateTableNode.rejected, (_, action) => {
      throw(action.error)
    }).addCase(moveTableNode.rejected, (_, action) => {
      throw(action.error)
    }).addCase(addAttachment.rejected, (_, action) => {
      throw(action.error)
    }).addCase(deleteTableNode.rejected, (_, action) => {
      throw(action.error)
    })
  }
})

export default tableNodeSlice
export const actions = tableNodeSlice.actions
export const tableNodeSelectors = tableNodeAdapter.getSelectors<RootState>(state => state.tableNode)
