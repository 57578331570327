import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { Button, Dropdown, Input, Menu, message } from 'antd'
import { PlusOutlined, CaretDownOutlined } from '@ant-design/icons'
import filter from 'lodash/filter'
import cx from 'clsx'
import Dialog from 'src/components/common/Dialog'
import { DialogStateReturn as DialogInterface } from 'src/hooks/common/useDialog'
import { ViewColumn, Column } from 'src/typings/tableNode'
import useSelector from 'src/hooks/common/useSelector'
import { tableNodeSelectors } from 'src/state/tableNode/slice'
import { AppDispatch } from 'src/store'
import { updateTableNode } from 'src/state/tableNode/actions'
import { useParameters } from 'src/hooks/common/useParameters'
import { projectSelectors } from 'src/state/project/slice'
import { TabConfig } from 'src/typings/mapTableNode'
import useDispatch from 'src/hooks/common/useDispatch'
import styles from './fieldSettingModal.module.scss'
import { FieldList } from './FieldList'

interface TabProps {
  title?: string
  selected: boolean
  isCreating?: boolean
  onChangeTitle?: (title: string) => void
  onClick?: () => void
  onDelete?: () => void
}

const Tab: React.FC<TabProps> = ({ title, isCreating, selected, onChangeTitle, onClick, onDelete }) => {
  const [value, setValue] = useState(title)
  const [isEditing, setIsEditing] = useState(false)
  const inputRef = useRef<Input>(null)
  useEffect(() => {
    if(isEditing || isCreating){
      inputRef.current?.focus()
    }
  },[isCreating, isEditing])
  function handleStartEdit () {
    setIsEditing(true)
  }
  function handleStopEdit () {
    setIsEditing(false)
    if(value) {
      onChangeTitle?.(value)
    }
    else if(isCreating) {
      onDelete?.()
    }
  }
  function handleDelete () {
    onDelete?.()
  }

  function handleClick (e: React.MouseEvent<HTMLElement>) {
    if(e.type !== 'caretClick') {
      e.stopPropagation()
      onClick?.()
    }
  }

  function handleCaretClick (e: React.MouseEvent<HTMLElement>){
    e.type = 'caretClick'
    return
  }
  
  const menu = (
    <Menu>
      <Menu.Item onClick={handleStartEdit}>
        修改名称
      </Menu.Item>
      <Menu.Item danger onClick={handleDelete}>
        删除分组
      </Menu.Item>
    </Menu>
  )
  return (
    <div className={styles.tab}>
      {
        isEditing || isCreating ?
          <Input
            ref={inputRef}
            className={styles.tabInput} 
            onPressEnter={handleStopEdit}
            onBlur={handleStopEdit}
            value={value} onChange={e => setValue(e.target.value)}></Input>
          :
          <Dropdown overlay={menu} placement="bottomCenter" trigger={['click']}>
            <div>
              <Button
                onClick={handleClick}
                type="text" 
                className={cx(styles.tabButton, selected && styles.selectedButton)}>
                <div className={styles.buttonText}> {title} </div>
                <CaretDownOutlined className={styles.caretIcon} onClick={handleCaretClick} />         
              </Button>
            </div>
          </Dropdown>
      }
    </div>
  )
}

interface TabsProps {
  columns: Column[]
  viewColumns: ViewColumn[]
  tabConfigs?: TabConfig[]
  onTabsUpdate?: (tabs: TabConfig[]) => void
}

const Tabs: React.FC<TabsProps> = ({ columns, viewColumns, tabConfigs, onTabsUpdate }) => {
  const MAX_TABS_COUNT = 10
  const [tabs, setTabs] = useState(tabConfigs || [])
  const [selectIndex, setSelectIndex] = useState(tabs.length > 0 ? 0 : undefined)
  const [currentVisibleIds, setCurrentVisibleIds] = useState(tabs[selectIndex ?? NaN]?.columnIDs)
  useEffect(() =>{
    onTabsUpdate?.(tabs)
  },[onTabsUpdate, tabs])
  function handleCreateTab (){
    setTabs(tabs => {
      return tabs.concat({ creating: true })
    })
    setSelectIndex(undefined)
    setCurrentVisibleIds(undefined)
  }
  function handleChangeTitle (title: string, index: number){
    if(tabs[index].creating) {
      setSelectIndex(index)
    }
    setTabs(tabs => {
      return tabs.map((tab,i) => {
        return {
          columnIDs: tab.columnIDs,
          name: i === index ? title : tab.name
        }
      })
    })
  }

  function handleDelete (index: number){
    const newTabs = tabs.filter((t,i) => i !== index)
    setTabs(newTabs)
    if(newTabs.length > 0 && selectIndex !== undefined){
      let newIndex = 0
      if(selectIndex > index) {newIndex = selectIndex - 1}
      if(selectIndex < index) {newIndex = selectIndex}
      setSelectIndex(newIndex)
      setCurrentVisibleIds(newTabs?.[newIndex]?.columnIDs)
    }
    else {
      setSelectIndex(undefined)
      setCurrentVisibleIds(undefined)
    }
  }

  const handleColumnsUpdate = useCallback((c: string[]) => {
    setTabs(tabs => tabs.map((tab,i) => {
      return {
        columnIDs:  i === selectIndex ? c : tab.columnIDs,
        name: tab.name
      }
    })
    )
  }, [selectIndex, setTabs])

  function handleSelect (index: number) {
    setSelectIndex(index)
    setCurrentVisibleIds(tabs?.[index]?.columnIDs)
  }

  return (
    <>
      <div className={styles.tabs}>
        {
          tabs.map((tab,index) => {
            return <Tab
              title={tab.name}
              isCreating={tab.creating}
              selected={selectIndex === index}
              key={index} 
              onChangeTitle={(title) => handleChangeTitle(title, index)} 
              onClick={() => handleSelect(index)}
              onDelete={() => handleDelete(index)}/>
          })
        }
        {
          tabs.length < MAX_TABS_COUNT &&
        <Button
          type="text"
          onClick={handleCreateTab} 
          className={cx(styles.plusButton, tabs.length > 0 && styles.iconOnly)}>
          <PlusOutlined />{tabs.length === 0 && '新建分组'}
        </Button>
        }
      </div>
      {
        selectIndex !== undefined && 
        <FieldList
          columns={columns} viewColumns={viewColumns}
          visibleIdsSorted={currentVisibleIds}
          onColumnsUpdate={handleColumnsUpdate}
          maxVisibleCount={viewColumns.length}
        />
      }
    </>
  )
}

interface DetailPanelSettingModalProps {
  tableNodeId?: number,
  dialog: DialogInterface;
}
export const DetailPanelSettingModal: React.FC<DetailPanelSettingModalProps> = ({ dialog, tableNodeId }) => {
  const dispatch: AppDispatch = useDispatch()
  const { projectId } = useParameters()
  const [columns, viewColumns] = useSelector(state => {
    const tableNode = tableNodeSelectors.selectById(state, tableNodeId as number)
    return [
      tableNode?.columns,
      tableNode?.views[0].columns
    ]
  })
  const tabConfigs = useSelector(state => {
    const project = projectSelectors.selectById(state, projectId)
    return project?.tableNodes?.find(t => t.id === tableNodeId)?.detailPanelConfig?.tabs
  })

  const [tabs, setTabs] = useState<TabConfig[]>(tabConfigs || [])
  const viewColumnsFiltered = useMemo(() =>
    filter(viewColumns, col => columns?.find(c => c.id === col.id) !== undefined)
  ,[columns, viewColumns])

  const handleTabsUpdate = useCallback((tabs: TabConfig[]) => {
    setTabs(tabs)
  },[setTabs])
  async function handleSubmitClick () {
    await dispatch(updateTableNode(
      { 
        id: tableNodeId as number,
        tableNode: { 
          projectID: projectId,
          detailPanelConfig: {
            tabs
          }
        }
      } 
    ))
    message.success('保存成功')
    dialog.close()
  }
  return (
    <Dialog
      destroyOnClose={true}
      width="400px" okText="保存"
      title='配置详情页展示内容'
      visible={dialog.visible} onCancel={dialog.close} onOk={handleSubmitClick}>
      <div>
      详情页展示内容支持自定义配置，您可以根据需要创建分组，存放不同字段。
        <b>配置将会对项目中所有 POI 详情页生效。</b>
      </div>
      {columns && viewColumns && 
        <Tabs
          columns={columns} viewColumns={viewColumnsFiltered} 
          onTabsUpdate={handleTabsUpdate} tabConfigs={tabConfigs}/>
      }
    </Dialog>
  )
}
