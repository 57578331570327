export const OVERLAY_TYPES_MAPPING = {
  Point: 'CircleMarker',
  MultiPoint: 'Points',
  Polygon: 'Polygon',
  MultiPolygon: 'Polygons',
  LineString: 'Polyline',
  MultiLineString: 'Polylines',
  Circle: 'Circle'
}

// TODO: simplify types, make it consistent
export const OVERLAY_EDITOR_TYPES_MAPPING = {
  Point: 'marker',
  Points: 'marker',
  MultiPoint: 'marker',
  Polygon: 'polygon',
  Polygons: 'polygon',
  Polyline: 'polyline',
  Polylines: 'polyline',
  MultiPolygon: 'polygon',
  LineString: 'polyline',
  MultiLineString: 'polyline',
  Circle: 'circle'
}

export const OVERLAY_ICON_TYPES_MAPPING = {
  Point: 'point',
  Points: 'point',
  MultiPoint: 'point',
  Polygon: 'polygon',
  Polygons: 'polygon',
  Polyline: 'line',
  Polylines: 'line',
  MultiPolygon: 'polygon',
  LineString: 'line',
  MultiLineString: 'line'
}