import { createSelector } from '@reduxjs/toolkit'
import includes from 'lodash/includes'
import type { RootState } from 'src/store'
import type { LayerNode } from 'src/typings/mapTableNode'

export const selectLayerNodes = createSelector<RootState, number, string[], LayerNode[], LayerNode[]>(
  (state, projectId) => state.map.hiddenLayerIds[projectId],
  (state, projectId) => state.project.layerNodes[projectId],
  (hiddenLayerIds, layerNodes) => {
    return layerNodes?.filter(l => {
      return l.type === 'map' && (!includes(hiddenLayerIds, l.id) && !includes(hiddenLayerIds, l.parentID))
    })
  }
)
