import { useState, useRef, useCallback } from 'react'
import map from 'lodash/map'
import cloneDeep from 'lodash/cloneDeep'
import filter from 'lodash/filter'
import includes from 'lodash/includes'
import find from 'lodash/find'
import findIndex from 'lodash/findIndex'
import forEach from 'lodash/forEach'
import split from 'lodash/split'
import join from 'lodash/join'
import compact from 'lodash/compact'
import flatMap from 'lodash/flatMap'
import isEmpty from 'lodash/isEmpty'
import sortBy from 'lodash/sortBy'
import toString from 'lodash/toString'
import isNumber from 'lodash/isNumber'
import toNumber from 'lodash/toNumber'
import concat from 'lodash/concat'
import useSelector from 'src/hooks/common/useSelector'
import { tableNodeSelectors, SearchResult } from 'src/state/tableNode/slice'
import { padNumberByPrecisionWithFormat } from 'src/utils/format'
import type { ColumnType, Row, Column } from 'src/typings/tableNode'
// eslint-disable-next-line @typescript-eslint/no-var-requires
const { Document } = require('flexsearch')

const SUPPORTED_SEARCH_COLUMN_TYPE = [
  'singleLineText',
  'multiLineText',
  'singleChoice',
  'multiChoice',
  'number',
  'formula'
] as ColumnType[]

interface RawSearchResult {
  field: string; 
  result: string[];
}

function transformToSearchResults (results?: RawSearchResult[], rows?: Row[], sortedRowIds?: string[]): SearchResult[] {
  return sortBy(flatMap(results, r => {
    const [, colId] = split(r.field, ':')
    
    return map(r.result, rowId => {
      const rowIndex = findIndex(sortedRowIds, id => id === rowId)

      return {
        colId,
        rowIndex
      }
    })
  }), ['rowIndex'])
}

function transformToSearchRow (
  row: Row, 
  singleChoiceColumns: Column[], 
  multiChoiceColumns: Column[],
  numberColumns: Column[],
  formulaColumns: Column[]
): Row {
  const cells = cloneDeep(row.cells)

  forEach(singleChoiceColumns, col => {
    const rawValue = cells[col.id]
    cells[col.id] = find(col.typeOptions?.choices, c => c.id === rawValue)?.name
  })

  forEach(multiChoiceColumns, col => {
    const rawValue = cells[col.id]
    cells[col.id] = join(map(rawValue as string[], v => {
      return find(col.typeOptions?.choices, c => c.id === v)?.name
    }), ' ')
  })

  forEach(numberColumns, col => {
    const rawValue = cells[col.id]
    const typeOptions = col.typeOptions
    cells[col.id] = toString(
      isNumber(rawValue) 
        ? padNumberByPrecisionWithFormat(rawValue, typeOptions?.precision, typeOptions?.format)
        : rawValue
    )
  })

  forEach(formulaColumns, col => {
    const rawValue = cells[col.id]
    const typeOptions = col.typeOptions
    cells[col.id] = toString(
      typeOptions?.expectedType === 'number'
        ? padNumberByPrecisionWithFormat(
          toNumber(rawValue), 
          typeOptions?.numberPrecision ?? 0, 
          typeOptions?.numberFormat
        )
        : rawValue
    )
  })

  return {
    id: row.id,
    cells
  }
}

export type Navigate<T> = (n: 'prev' | 'next') => T;

interface Return {
  search: (query: string) => void;
  nav: Navigate<number>;
  initialSearchIndex: () => void;
  clear: () => void;
  results: SearchResult[];
  position: number;
}

export function useSearch (tableNodeId: number): Return {
  const [results, setResults] = useState<SearchResult[]>([])
  const searchIndex = useRef<{ searchAsync: (q: string, limit?: number) => Promise<RawSearchResult[]> }>()
  const [position, setPosition] = useState(0)
  const tableNode = useSelector(state => tableNodeSelectors.selectById(state, tableNodeId))
  const defaultView = tableNode?.views[0]
  const sortedRowIds = map(sortBy(defaultView?.rows, 'order'), 'id')

  const initialSearchIndex = useCallback(() => {
    const searchAvaliableColumns = compact(map(
      filter(defaultView?.columns, c => !c.isHidden), c => {
        return find(tableNode?.columns, col => col.id === c.id && includes(SUPPORTED_SEARCH_COLUMN_TYPE, col.type))
      }
    ))
    const searchIndexs = map(searchAvaliableColumns, c => `cells:${c?.id}`)
    const index = new Document({
      index: searchIndexs,
      tokenize: 'full',
      // more options https://github.com/nextapps-de/flexsearch#index-options
      // https://github.com/nextapps-de/flexsearch#cjk-word-break-chinese-japanese-korean
      encode: (str: string) => {
        // eslint-disable-next-line no-control-regex
        const cjk = str.replace(/[\x00-\x7F]/g, '').split('')
        const latin = str.toLowerCase().split(/[\p{Z}\p{S}\p{P}\p{C}]+/u)

        return concat(cjk, latin)
      }
    })
    const singleChoiceColumns = filter(tableNode?.columns, c => c.type === 'singleChoice')
    const multiChoiceColumns = filter(tableNode?.columns, c => c.type === 'multiChoice')
    const numberColumns = filter(tableNode?.columns, c => c.type === 'number')
    const formulaColumns = filter(tableNode?.columns, c => c.type === 'formula')

    forEach(tableNode?.rows, r => {
      index.addAsync(transformToSearchRow(r, singleChoiceColumns, multiChoiceColumns, numberColumns, formulaColumns))
    })

    searchIndex.current = index
  }, [tableNode, defaultView?.columns])

  const search = async (query: string) => {
    const results = await searchIndex?.current?.searchAsync(query)

    setPosition(0)
    setResults(transformToSearchResults(results, tableNode?.rows, sortedRowIds))
  }

  const nav: Navigate<number> = (n) => {
    if (isEmpty(results)) return 0

    let nextPosition = position    

    if (n === 'next') {
      const next = position + 1

      if (next > results.length - 1) {
        nextPosition = 0
      } else {
        nextPosition = next
      }
    }

    if (n === 'prev') {
      const prev = position - 1

      if (prev < 0) {
        nextPosition = results.length - 1
      } else {
        nextPosition = prev
      }
    }

    setPosition(nextPosition)

    return nextPosition
  }

  const clear = useCallback(() => {
    setPosition(0)
    setResults([])
  }, [])

  return {
    initialSearchIndex,
    clear,
    search,
    results,
    position,
    nav
  }
}