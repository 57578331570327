import { createAsyncThunk } from '@reduxjs/toolkit'
import InvitationAPI from 'src/api/invitations'
import ProjectAPI, { ProjectDuplicateRequestData } from 'src/api/projects'
import { MapConfig } from 'src/typings/project'

export const createProject = createAsyncThunk(
  'project/create_async',
  async (payload: { projectName: string, organizationId?: number }) => {
    const { projectName, organizationId } = payload
    return await ProjectAPI.create(projectName, organizationId)
  }
)

export const editProject = createAsyncThunk(
  'project/update_async',
  async (payload: { projectId: number, projectName?: string, mapConfig?: MapConfig, roleInherited?: boolean }) => {
    const { projectId, projectName, mapConfig, roleInherited } = payload
    return await ProjectAPI.update(projectId, projectName, roleInherited, mapConfig)
  }
)

export const getLayerNodes = createAsyncThunk(
  'project/getLayerNodes_async',
  async (projectId: number) => {
    return await ProjectAPI.getLayerNodes(projectId)
  }
)

export const getLayerTables = createAsyncThunk(
  'project/getLayerTables_async',
  async (projectId: number) => {
    return await ProjectAPI.getLayerTables(projectId)
  }
)

export const deleteProject = createAsyncThunk(
  'project/delete_async',
  async (projectId: number) => {
    return await ProjectAPI.delete(projectId)
  }
)
export const deleteRecycleProject = createAsyncThunk(
  'project/deleteRecycle_async',
  async (projectId: number) => {
    return await ProjectAPI.deleteRecycle(projectId)
  }
)

export const recoverProject = createAsyncThunk(
  'project/recover_async',
  async (projectId: number) => {
    return await ProjectAPI.recover(projectId)
  }
)

export const inviteMembers = createAsyncThunk(
  'project/inviteMembers',
  async (payload: { projectId: number, members: { phone: string, role: string }[] }) => {
    const { projectId, members } = payload
    return await ProjectAPI.invite(projectId, members)
  }
)
export const getMembers = createAsyncThunk(
  'project/getMembers',
  async (projectId: number) => {
    return (await ProjectAPI.getMembers(projectId)).detail
  }
)

export const updateMember = createAsyncThunk(
  'project/updateMember',
  async (payload: { projectId: number, members: { id: number, role: string }[] }) => {
    const { projectId, members } = payload
    return (await ProjectAPI.updateMember(projectId, members)).detail
  }
)
export const deleteMember = createAsyncThunk(
  'project/deleteMember',
  async (payload: { projectId: number, memberId: number }) => {
    const { projectId, memberId } = payload
    return (await ProjectAPI.deleteMember(projectId, memberId)).detail
  }
)

export const getInvitations = createAsyncThunk(
  'project/getInvitations',
  async (id: number) => {
    const { detail } = await InvitationAPI.getInvitations(id, 'project')
    return detail
  }
)
export const deleteInvitation = createAsyncThunk(
  'project/deleteInvitation',
  async (payload: { id: number, invitationID: number }) => {
    const { id, invitationID } = payload

    return await InvitationAPI.deleteInvitation(id, 'project', invitationID)
  }
)

export const resendInvitation = createAsyncThunk(
  'project/resendInvitation',
  async (payload: { id: number, invitationID: number }) => {
    const { id, invitationID } = payload

    return await InvitationAPI.resendInvitation(id, 'project', invitationID)
  }
)

export const duplicateProject = createAsyncThunk(
  'project/duplicate_async',
  async (payload: { data: ProjectDuplicateRequestData }) => {
    const { data } = payload

    return await ProjectAPI.copy(data.id, data)
  }
)