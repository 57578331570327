import get from 'lodash/get'
import find from 'lodash/find'
import mapValues from 'lodash/mapValues'
import { tableNodeSelectors } from 'src/state/tableNode/slice'
import { 
  updateCells, 
  UpdateCellsPayload, 
  undoUpdateCells, 
  redoUpdateCells
} from 'src/state/tableNode/actions'
import type { UndoRedo } from 'src/features/undoRedo/undoRedo'
import type { RootState } from 'src/store'

function getPayload (arg: UpdateCellsPayload, state: RootState): UpdateCellsPayload {
  const { id, rowId, cells } = arg
  const tableNode = tableNodeSelectors.selectById(state, id)
  const row = find(tableNode?.rows, r => r.id === rowId)
  const prevCells = mapValues(cells, (_, key) => {
    return get(row?.cells, key, null)
  })

  return {
    id,
    rowId,
    cells: prevCells 
  }
}

const updateCellsUndoRedo = {
  action: updateCells,
  undoAction: undoUpdateCells,
  redoAction: redoUpdateCells,
  getRedoPayload: getPayload,
  getUndoPayload: getPayload
} as UndoRedo

export default updateCellsUndoRedo