import React from 'react'
import moment, { Moment } from 'moment'
import split from 'lodash/split'
import toString from 'lodash/toString'
import sortBy from 'lodash/sortBy'
import isArray from 'lodash/isArray'
import { Input, InputNumber, DatePicker, Select, Tag } from 'antd'
import type { TypeOptions, ColumnType } from 'src/typings/tableNode'
import { useDatetimeFormat } from 'src/hooks/field/useTypeOptions'
import { PICKER_COLORS } from 'src/constants/colors'
import type { CustomTagProps } from 'src/components/field/Select/SelectEditor'
import type { Relation } from 'src/constants/filter'
import styles from './filterValue.module.scss'

interface Props {
  value?: string | number;
  type?: ColumnType;
  onChange: (v: string) => void;
  typeOptions?: TypeOptions;
  relation?: Relation;
}

const TextInput: React.FC<Props> = ({
  onChange,
  value
}) => {
  function handleChange (e: React.ChangeEvent<HTMLInputElement>) {
    onChange(e.target.value)
  }

  return <Input onChange={handleChange} defaultValue={value} />
}

const NumberInput: React.FC<Props> = ({
  onChange,
  typeOptions,
  value
}) => {
  function handleChange (v: string) {
    onChange(v)
  }

  return <InputNumber
    defaultValue={value as string}
    style={{ width: '100%' }}
    bordered={false}
    precision={typeOptions?.precision}
    autoComplete="off"
    onChange={handleChange}
  />
}

const DatetimeInput: React.FC<Props> = ({
  onChange,
  typeOptions,
  value
}) => {
  const formatRule = useDatetimeFormat(typeOptions)

  function handleChange (value: Moment | null) {
    if (value) {
      onChange(value.toString())
    }
  }

  return <DatePicker
    defaultValue={value ? moment(value) : undefined}
    style={{ width: '100%' }}
    format={formatRule}
    allowClear={true}
    bordered={false}
    showTime={typeOptions?.timeFormat !== 'hidden'}
    onChange={handleChange}
  />
}

const SelectInput: React.FC<Props> = ({
  onChange,
  typeOptions,
  type,
  relation,
  value
}) => {
  const mode = relation?.key === 'contains' || relation?.key === 'notContains' || type === 'multiChoice'
    ? 'multiple'
    : undefined
  let defaultValue = value as string | string[]

  function tagRender (props: CustomTagProps) {
    const { value, closable, onClose } = props
    const choice = typeOptions?.choices?.find(c => c.id === value)

    if (choice?.name) {
      return (
        <Tag
          className={styles.tag}
          color={choice?.color ? PICKER_COLORS[choice.color] : undefined}
          closable={closable}
          onClose={onClose}
        >
          {choice.name}
        </Tag>
      )
    } else {
      return <></>
    }
  }

  function handleChange (value: string | string[]) {
    onChange(toString(isArray(value) ? sortBy(value) : value))
  }

  if (mode === 'multiple') {
    defaultValue = defaultValue ? split(defaultValue as string, ',') : []
  }
  return (
    <Select
      className={styles.select}
      bordered={false}
      placeholder={type === 'multiChoice' ? '搜索' : '请选择'}
      filterOption={(input, option) => {
        return option?.title && (option.title as string).indexOf(input) !== -1
      }}
      showSearch={type === 'multiChoice'}
      tagRender={tagRender}
      mode={mode}
      onChange={handleChange}
      notFoundContent="无数据"
      getPopupContainer={triggerNode => triggerNode.parentElement}
      defaultValue={defaultValue}
    >
      {typeOptions?.choices.map(item => (
        <Select.Option key={item.id} value={item.id} title={item.name}>
          <div className={styles.option}>
            <Tag
              className={styles.tag}
              color={item.color ? PICKER_COLORS[item.color] : undefined}>
              {item.name}
            </Tag>
          </div>
        </Select.Option>
      ))}
    </Select>
  )
}

const FilterValue: React.FC<Props> = (props) => {
  const { type } = props

  return (
    <>
      {type === 'singleLineText' && <TextInput {...props} />}
      {type === 'multiLineText' && <TextInput {...props} />}
      {type === 'number' && <NumberInput {...props} />}
      {type === 'datetime' && <DatetimeInput {...props} />}
      {type === 'singleChoice' && <SelectInput {...props} />}
      {type === 'multiChoice' && <SelectInput {...props} />}
    </>
  )
}

export default FilterValue
