import { createAction, createAsyncThunk } from '@reduxjs/toolkit'
import LayerNodesAPI from 'src/api/layernodes'
import { DeleteNode, LayerNode } from 'src/typings/mapTableNode'
import { DND } from 'src/typings/tree'

/**
 * @name layerNode/delete
 * @param action payload {@link LayerNode}
 */
export const deleteLayerNode = createAction<{ ids: string[], projectID: number }>('layerNode/delete')

/**
 * @name layerNode/preview
 * @param action payload {@link LayerNode}
 */
export const previewLayerNode = createAction<LayerNode>('layerNode/preview')

export const create = createAsyncThunk(
  'layerNode/create_async',
  async (params: LayerNode) => {
    return await LayerNodesAPI.create(params)
  }
)

export const move = createAsyncThunk(
  'layerNode/move_async',
  async (params: DND) => {
    return await LayerNodesAPI.move(params)
  }
)

export const update = createAsyncThunk(
  'layerNode/update_async',
  async (params: Partial<LayerNode>) => {
    return await LayerNodesAPI.update(params)
  }
)

export const deleteNode = createAsyncThunk(
  'layerNode/deleteNode_async',
  async (params: DeleteNode) => {
    return await LayerNodesAPI.deleteNode(params)
  }
)

export const getDetail = createAsyncThunk(
  'layerNode/getDetail_async',
  async (id: string) => {
    return await LayerNodesAPI.getDetail(id)
  }
)
