import { useDispatch } from 'react-redux'
import { GridApi } from 'ag-grid-community'
import includes from 'lodash/includes'
import debounce from 'lodash/debounce'
import toString from 'lodash/toString'
import map from 'lodash/map'
import forEach from 'lodash/forEach'
import isNil from 'lodash/isNil'
import { Field } from 'src/components/grid/GridFieldConfig'
import { ChoiceItem } from 'src/components/field/Select/SelectConfig'
import { PICKER_COLORS } from 'src/constants/colors'
import useSelector from 'src/hooks/common/useSelector'
import { tableNodeSelectors } from 'src/state/tableNode/slice'
import { useParameters } from 'src/hooks/common/useParameters'
import { Column, TypeOptions, View, ViewColumn } from 'src/typings/tableNode'
import { moveViewColumn, unhideViewColumn, updateViewColumn } from 'src/state/tableNode/actions'
import { AppDispatch } from 'src/store'
import type { FileParams } from 'src/api/tablenodes'
import { padNumberByPrecisionWithFormat, formatDateByRule } from 'src/utils/format'
import { ADD_ROW_ID } from 'src/constants/grid'

interface Return {
  view?: View
  columns?: Column[]
  viewColumns?: ViewColumn[]
  getFieldChoices: (type: string) => ChoiceItem[] | undefined
  onColumnMoved: (columnId?: string, prevColumnId?: string) => void
  onColumnVisibilityChanged: (columnId: string, visible: boolean) => void
  onColumnVisibilityUnhidden: () => void
}

const PickerColorsArray = Object.keys(PICKER_COLORS)
const PickerColorsLength = PickerColorsArray.length
const isChoice = (type?: string) => {
  return includes(['singleChoice', 'multiChoice'], type)
}
export default function useFieldConfig (
  colId?: string,
  type?: string,
  fieldConfig?: Field,
  gridApi?: GridApi
): Return {
  const { tableNodeId } = useParameters()
  const dispatch: AppDispatch = useDispatch()
  const [view, columns, viewColumns] = useSelector(state => {
    const tableNode = tableNodeSelectors.selectById(state, tableNodeId)
    return [
      // TODO: use default view temporarily
      tableNode?.views[0],
      tableNode?.columns,
      tableNode?.views[0].columns
    ]
  })

  const formatChoiceData = (
    type?: string,
    value?: string | number | FileParams[],
    typeOptions?: TypeOptions
  ): string[] | undefined => {
    switch (type) {
    case 'number':
      return [
        padNumberByPrecisionWithFormat(value as number, typeOptions?.precision as number, typeOptions?.format) as string
      ]
    case 'datetime':
      return [formatDateByRule(toString(value), typeOptions as {
        dateFormat: string;
        timeFormat: string;
      }) as string]
    case 'multiLineText':
      return [toString(value)?.replace(/[\n\r\t]/g, '')]
    case 'attachment':
      return map(value as FileParams[], attachment => `${attachment.fileName}(${attachment.url})`)
    case 'boolean':
      return ['是', '否']
    default:
      return [value as string]
    }
  }

  function getFieldChoices (fieldType: string) {
    if (colId && isChoice(fieldType)) {
      if (isChoice(type)) {
        return undefined
      }

      if (isChoice(fieldConfig?.type)) {
        return fieldConfig?.typeOptions?.choices as ChoiceItem[]
      }

      const typeOptions: Record<string, ChoiceItem> = {}

      let colIdx = 0
      gridApi?.forEachNode(node => {
        if (node.data.id !== ADD_ROW_ID) {
          const nodeData = formatChoiceData(fieldConfig?.type, node.data[colId], fieldConfig?.typeOptions)
          if (nodeData) {
            forEach(nodeData, item => {
              if (!isNil(item) && item.trim() !== '' && !typeOptions[item]) {
                typeOptions[item] = {
                  name: toString(item),
                  color: PickerColorsArray[colIdx % PickerColorsLength]
                }
                colIdx++
              }
            })
          }
        }
      })

      return Object.values(typeOptions)
    }
  }

  function onColumnMoved (columnId?: string, prevColumnId?: string) {
    if (columnId) {
      dispatch(moveViewColumn({
        id: tableNodeId,
        viewId: view?.id as string,
        columnId,
        prevColumnId
      }))
    }
  }

  function onColumnVisibilityChanged (columnId: string, visible: boolean) {
    dispatch(updateViewColumn({
      id: tableNodeId,
      viewId: view?.id as string,
      columnId: columnId,
      form: {
        isHidden: !visible
      }
    }))
  }

  function onColumnVisibilityUnhidden () {
    dispatch(unhideViewColumn({
      id: tableNodeId,
      viewId: view?.id as string
    }))
  }

  return {
    view,
    columns,
    viewColumns,
    getFieldChoices,
    onColumnMoved: debounce(onColumnMoved, 500, {
      maxWait: 1000
    }),
    onColumnVisibilityChanged,
    onColumnVisibilityUnhidden
  }
}
