import { GeoJSON } from 'src/typings/common'

// eslint-disable-next-line @typescript-eslint/no-var-requires
const wkt = require('wkt')

export function stringifyGeoJSON (geojson: GeoJSON): string {
  if (geojson.features.length === 1) {
    return stringify(geojson.features[0].geometry)
  }
  // 仅支持 MultiPoint, MultiLineString, MultiPolygon
  const type = geojson.features[0].geometry.type.toUpperCase() + ' '
  const fragments = geojson.features.map(feature => stringify(feature.geometry))
  const multiPart = fragments.join(',').replaceAll(type, '')

  return `MULTI${type}(${multiPart})`
}
export function stringify (geometry: Geometry): string {
  // FIXME: 当为 polygon 时，目前使用的 wkt 转换函数转换出来的结果没有首尾相连无法通过后端验证，先手动处理，之后试试看其它的库
  if (geometry.type === 'Polygon') {
    const firstCoordinate = (geometry.coordinates[0] as Coordinates)[0]
    return wkt.stringify({
      ...geometry,
      coordinates: [[...(geometry.coordinates[0] as Coordinates), firstCoordinate]]
    })
  }

  if (geometry.type === 'Circle') {
    return `CIRCLE (${geometry.coordinates[0]} ${geometry.coordinates[1]}, ${geometry.coordinates[2]})`
  }
  return wkt.stringify(geometry)
}


export function parse (wkt: string): Geometry {
  const s = new Scanner(wkt)
  return s.scanGeometry()
}

export type Coordinate = [number, number]
type Coordinates = Array<Coordinate>
type Coordinatess = Array<Array<Coordinate>>
type Coordinatesss = Array<Array<Array<Coordinate>>>
type CoordinateAndRadius = [number, number, number]

export type Geometry = {
  type: 'Point' | 'MultiPoint' | 'Polygon' | 'MultiPolygon' | 'LineString' | 'MultiLineString' | 'Circle'
  coordinates: Coordinate | Coordinates | Coordinatess | Coordinatesss | CoordinateAndRadius
}

class Scanner {
  raw: string
  i = 0

  constructor (raw: string) {
    this.raw = raw
  }

  peek (): string {
    if (this.i > this.raw.length) {
      throw new Error('unexpected EOF')
    }
    return this.raw[this.i]
  }

  skipWs (): void {
    if (this.i >= this.raw.length) {
      return
    }

    for (let i = this.i; i < this.raw.length; i++) {
      const c = this.raw.charAt(i)
      if (isWs(c)) {
        this.i += 1
        continue
      }
      return
    }
  }

  scanStart (): void {
    this.skipWs()
    const c = this.peek()
    if (c !== '(') {
      throw new Error(`expect '(' got ${c}`)
    }
    this.i++
  }

  scanContinue (): boolean {
    this.skipWs()
    const c = this.peek()
    const comma = c === ','
    if (!comma && c !== ')') {
      throw new Error(`expect ',' or ')' got ${c}`)
    }
    this.i++
    return comma
  }

  scanCircle (): CoordinateAndRadius {
    this.scanStart()
    const [c, comma] = this.scanCoordinate()
    if (!comma) {
      const cur = this.peek()
      throw new Error(`expect ',' got ${cur}`)
    }
    this.i++
    this.skipWs()
    const radius = this.scanNumber()

    const cur = this.peek()
    if (cur !== ')') {
      throw new Error(`expect ')' got ${cur}`)
    }
    this.i++

    return [c[0], c[1], radius]
  }

  scanNumber (): number {
    this.skipWs()
    let numberStr = ''
    let c = this.peek()
    while (isNumberParts(c)) {
      this.i += 1
      numberStr += c
      c = this.peek()
    }

    const result = Number(numberStr)
    if (Number.isNaN(result)) {
      throw new Error(`number parse error ${numberStr}`)
    }

    return result
  }

  scanCoordinate (): [Coordinate, boolean] {
    this.skipWs()
    if (this.i > this.raw.length) {
      throw new Error('unexpected EOF')
    }
    const first = this.scanNumber()
    this.skipWs()
    const second = this.scanNumber()
    this.skipWs()

    const b = this.peek()
    const comma = b === ','
    if (comma || b === ')') {
      this.i++
    } else {
      throw new Error(`expect ',' or ')' got ${b}`)
    }
    return [[first, second], comma]
  }

  scanCoordinates (multi: boolean): Coordinates {
    this.scanStart()
    if (multi) {
      try {
        this.scanStart()
      } catch (_) {
        multi = false
      }
    }

    const cs: Coordinates = []
    // eslint-disable-next-line no-constant-condition
    while (true) {
      const [coordinate, comma] = this.scanCoordinate()
      cs.push(coordinate)
      if (comma) {
        if (multi) {
          throw new Error(`expect ')' got ','`)
        }
        continue
      }
      if (multi) {
        const comma = this.scanContinue()
        if (comma) {
          this.scanStart()
          continue
        }
      }
      return cs
    }
  }

  scanPolyData (checkRings: boolean): Coordinatess {
    this.scanStart()
    const poly: [number, number][][] = []
    // eslint-disable-next-line no-constant-condition
    while (true) {
      const cs = this.scanCoordinates(false)
      if (checkRings) {
        if (cs.length < 4) {
          throw new Error(`a polygon ring must have at least 4 points, got ${cs.length}`)
        }
        if (cs[0][0] !== cs[cs.length - 1][0] || cs[0][1] !== cs[cs.length - 1][1]) {
          throw new Error('a polygon ring must be closed')
        }
      } else {
        if (cs.length < 2) {
          throw new Error(`must have at least 2 points, got ${cs.length}`)
        }
      }

      poly.push(cs)

      if (this.scanContinue()) {
        continue
      }

      return poly
    }
  }

  scanMultiPolyData (): Coordinatesss {
    this.scanStart()
    const multi: Coordinatesss = []

    // eslint-disable-next-line no-constant-condition
    while (true) {
      const poly = this.scanPolyData(true)
      multi.push(poly)
      if (this.scanContinue()) {
        continue
      }

      return multi
    }
  }

  scanIndent (): string {
    this.skipWs()
    if (this.i >= this.raw.length) {
      throw new Error('unexpected EOF')
    }

    let indent = ''
    let i = 0
    for (i = this.i; i < this.raw.length; i++) {
      const c = this.raw.charAt(i)
      if (isLetter(c)) {
        indent += c.toUpperCase()
        this.i += 1
        continue
      }
      break
    }

    if (indent.length === 0) {
      throw new Error('indent not found')
    }

    return indent
  }

  scanGeometry (): Geometry {
    const indent = this.scanIndent()
    let g: Geometry

    switch (indent) {
    case 'CIRCLE': {
      const cr = this.scanCircle()
      g = { type: 'Circle', coordinates: cr }
      break
    }
    case 'POINT': {
      const cs = this.scanCoordinates(false)
      if (cs.length !== 1) {
        throw new Error(`expect 1 got ${cs.length} points`)
      }
      g = { type: 'Point', coordinates: cs[0] }
      break
    }
    case 'MULTIPOINT': {
      g = { type: 'MultiPoint', coordinates: this.scanCoordinates(true) }
      break
    }
    case 'LINESTRING': {
      const cs = this.scanCoordinates(false)
      if (cs.length < 2) {
        throw new Error(`must have at least 2 points, got ${cs.length}`)
      }
      g = { type: 'LineString', coordinates: cs }
      break
    }
    case 'POLYGON': {
      g = { type: 'Polygon', coordinates: this.scanPolyData(true) }
      break
    }
    case 'MULTIPOLYGON': {
      g = { type: 'MultiPolygon', coordinates: this.scanMultiPolyData() }
      break
    }
    case 'MULTILINESTRING': {
      g = { type: 'MultiLineString', coordinates: this.scanPolyData(false) }
      break
    }
    default:
      throw new Error(`unknown geometry ${indent}`)
    }

    if (this.i + 1 <= this.raw.length) {
      throw new Error('expect EOF')
    }

    return g
  }
}

function isWs (c: string): boolean {
  return c.length === 1 && [' ', '\n', '\t', '\r'].includes(c)
}

function isNumberParts (c: string): boolean {
  return ['.', '-', '0', '1', '2', '3', '4', '5', '6', '7', '8', '9'].includes(c)
}

function isLetter (c: string) {
  return c.length === 1 && c.match(/[a-zA-Z]/i)
}
