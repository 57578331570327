import { createSelector } from '@reduxjs/toolkit'
import find from 'lodash/find'
import map from 'lodash/map'
import toUpper from 'lodash/toUpper'
import { tableNodeSelectors } from 'src/state/tableNode/slice'
import type { RootState } from 'src/store'
import type { View, Column } from 'src/typings/tableNode'
import { Filter, CONDITIONS, RELATIONS } from 'src/constants/filter'

export const selectTableNodeFilters = createSelector<RootState,
  {
    tableNodeId: number;
    viewId?: string;
  },
  Column[] | undefined,
  View[] | undefined,
  string | undefined,
  Filter[] | []
>(
  (state, props) => tableNodeSelectors.selectById(state, props.tableNodeId)?.columns,
  (state, props) => tableNodeSelectors.selectById(state, props.tableNodeId)?.views,
  (_, props) => props.viewId,
  (columns, views = [], viewId) => {
    if (!columns) return []
    const view = find(views, v => v.id === viewId) || views[0]

    return map(view.filters, (f, index) => {
      const isFirstCondition = index === 0
      const condition = toUpper(isFirstCondition ? 'WHEN' : view.filterCondition || 'AND')
      const field = find(columns, c => c.id === f.columnID) as Filter['field']
      return {
        id: index,
        condition: {
          ...CONDITIONS[condition]
        },
        relation: {
          ...RELATIONS[f.relation]
        },
        field,
        value: f.value
      }
    })
  }
)
