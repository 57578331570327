import { useEffect, useState, useRef } from 'react'
import mapValues from 'lodash/mapValues'
import values from 'lodash/values'
import dropRight from 'lodash/dropRight'
import MarkerEditor from 'qumap/packages/core/types/markerEditor'
import PolylineEditor from 'qumap/packages/core/types/polylineEditor'
import PolygonEditor from 'qumap/packages/core/types/polygonEditor'
import type { QUMap } from 'qumap/packages/core'
import { getCenter } from 'qumap/packages/core'
import useSelector from 'src/hooks/common/useSelector'
import useDispatch from 'src/hooks/common/useDispatch'
import { parse, Geometry, stringifyGeoJSON } from 'src/utils/wkt'
import { updateCells } from 'src/state/tableNode/actions'
import { OVERLAY_EDITOR_TYPES_MAPPING, OVERLAY_TYPES_MAPPING } from 'src/constants/overlay'
import { actions as projectActions } from 'src/state/project/slice'
import type { GeoJSON } from 'src/typings/common'
import type { CoordinateEditor } from 'src/state/project/reducers'

interface Return {
  updateCoordindate: (g: GeoJSON) => void;
  preData: Geometry['coordinates'] | null;
  editorType: string;
  editor: React.RefObject<MarkerEditor | PolylineEditor | PolygonEditor>;
  changeEditorType: (t: string) => void;
  setPreData: (preData: Geometry['coordinates'] | null) => void;
  clearCoordinate: () => void;
}

export function getCoordinatesValue (data: CoordinateEditor['data']): string | undefined | unknown {
  return values(data?.cells)[0]
}

export default function useCoordinateEditor (quMap: QUMap | null): Return {
  const dispatch = useDispatch()
  const editor = useRef<MarkerEditor | PolylineEditor | PolygonEditor>(null)
  const [editorType, setEditorType] = useState('marker')
  const [preData, setPreData] = useState<Geometry['coordinates'] | null>(null)
  const coordinateEditor = useSelector(state => state.project.coordinateEditor)
  const coordinateEditorData = coordinateEditor.data

  function reset (type: string) {
    setEditorType(type)
    setPreData(null)
    editor.current?.reset()
  }

  function updateCoordindate (overlayGeoJSON: GeoJSON) {

    if (coordinateEditorData) {
      dispatch(updateCells({
        ...coordinateEditorData,
        cells: mapValues(coordinateEditorData.cells, _ => stringifyGeoJSON(overlayGeoJSON))
      }))

    }
  }

  function clearCoordinate () {
    if (coordinateEditorData) {
      dispatch(updateCells({
        ...coordinateEditorData,
        cells: mapValues(coordinateEditorData.cells, _ => null)
      }))
    }
  }

  function changeEditorType (type: string) {
    reset(type)

    if (coordinateEditorData) {
      dispatch(projectActions.updateCoordinateEditor({
        data: {
          ...coordinateEditorData,
          cells: mapValues(coordinateEditorData.cells, _ => '')
        }
      }))
    }
  }

  useEffect(() => {
    const coordinatesValue = getCoordinatesValue(coordinateEditor.data)

    if (coordinateEditor.visible && coordinateEditor.trigger === 'open' && coordinatesValue) {
      const geometry = parse(coordinatesValue as string)
      if (geometry.type === 'Polygon') {
        // FIXME: 标准的 wkt polygon 首尾相连，高德 PolygonEditor 渲染这类数据时候会报错，暂时丢弃最后一个元素
        setPreData(dropRight(geometry.coordinates[0] as []))
      } else {
        setPreData(geometry.coordinates)
      }
      const overlayEditorType = OVERLAY_EDITOR_TYPES_MAPPING[geometry.type]
      const overlayType = OVERLAY_TYPES_MAPPING[geometry.type]
      setEditorType(overlayEditorType)

      const center = getCenter(geometry.coordinates, overlayType) as [number, number]
      quMap?.amap.setCenter(center, true)
    }

    if (!coordinateEditor.visible && coordinateEditor.trigger === 'close') {
      reset('marker')
    }
  }, [coordinateEditor, quMap])

  return {
    updateCoordindate,
    preData,
    editorType,
    editor,
    changeEditorType,
    setPreData,
    clearCoordinate
  }
}
