import React, { useState, useRef, useEffect } from 'react'
import trim from 'lodash/trim'
import map from 'lodash/map'
import find from 'lodash/find'
import includes from 'lodash/includes'
import get from 'lodash/get'
import pick from 'lodash/pick'
import isEmpty from 'lodash/isEmpty'
import { Input, Row, Col, Button } from 'antd'
import { FormInstance } from 'antd/lib/form/hooks/useForm'
import { CaretRightOutlined } from '@ant-design/icons'
import { GridApi } from 'ag-grid-community'
import cx from 'clsx'
import Icon from 'src/components/common/Icon'
import { DatetimeConfig } from 'src/components/field/Datetime'
import { NumberConfig } from 'src/components/field/Number'
import { SelectConfig } from 'src/components/field/Select'
import { FormulaConfig } from 'src/components/field/Formula'
import { TableConfig } from 'src/components/field/Table'
import { FIELD_TYPES } from 'src/constants/fieldType'
import type { IconType } from 'src/components/common/Icon'
import useFieldConfig from 'src/hooks/project/useFieldConfig'
import { ChoiceItem } from 'src/components/field/Select/SelectConfig'
import { isFormula } from 'src/utils/formula'
import type { TypeOptions, ColumnType } from 'src/typings/tableNode'

import styles from './gridFieldConfig.module.scss'

export interface Field {
  name: string;
  type: string;
  typeOptions: TypeOptions;
}

export interface Props {
  colId?: string;
  fieldConfig?: Field;
  gridApi?: GridApi;
  onSubmit: (filed: Field) => void;
  onCancel: () => void;
  enableFieldTypes?: ColumnType[];
}

export interface FieldConfigRef {
  reset: () => void;
}

const DEFAULT_NAME = '新字段'
const DEFAULT_TYPE = 'singleLineText'
const GridFieldConfig :React.FC<Props> =({
  colId,
  fieldConfig,
  gridApi,
  onCancel,
  onSubmit,
  enableFieldTypes
}) => {
  const [name, setName] = useState(DEFAULT_NAME)
  const [type, setType] = useState('')
  const [expanded, setExpanded] = useState(true)
  const configForm = useRef<FormInstance | null>(null)
  const [isNewColumn, setIsNewColumn] = useState(true)
  const [selectOptions, setSelectOptions] = useState<ChoiceItem[]>([])
  const [formulaExpression, setFormulaExpression] = useState('')
  const inputRef = useRef<Input>(null)
  const {
    getFieldChoices
  } = useFieldConfig(colId, type, fieldConfig, gridApi)
  const fieldTypes = enableFieldTypes ? pick(FIELD_TYPES, enableFieldTypes) : FIELD_TYPES
  const convertibleTypes = get(fieldTypes, `${fieldConfig?.type}.convertibleTypes`, [])

  useEffect(() => {
    if (fieldConfig) {
      setIsNewColumn(false)
      setName(fieldConfig.name)
      setType(fieldConfig.type)
      if (fieldConfig.typeOptions) {
        setSelectOptions(fieldConfig.typeOptions.choices as ChoiceItem[])
        if (isFormula(fieldConfig.type)) {
          setFormulaExpression(fieldConfig.typeOptions.formula as string)
        }
      }
      setExpanded(false)
    } else {
      setIsNewColumn(true)
      setType(DEFAULT_TYPE)
      inputRef.current?.select()

    }
  }, [fieldConfig])

  function handleNameChange (e: React.ChangeEvent<HTMLInputElement>) {
    setName(trim(e.target.value))
  }

  function handleCancelClick () {
    onCancel()
  }

  async function handleSubmitClick () {
    await configForm.current?.validateFields()

    const typeOptions = configForm.current?.getFieldsValue()

    onSubmit({
      name,
      type,
      typeOptions
    })
  }

  function handleTypeClick (fieldType: string) {
    const fieldChoices = getFieldChoices(fieldType)
    if (fieldChoices) {
      setSelectOptions(fieldChoices)
    }
    setType(fieldType)
    setExpanded(false)
  }

  return (
    <div className={styles.wrap}>
      <div className={styles.block}>
        <label>字段名称</label>
        <Input
          ref={inputRef}
          value={name}
          placeholder="输入字段名称"
          autoComplete="off"
          onChange={handleNameChange}
        />
      </div>
      <div className={styles.block}>
        <label>字段类型</label>
        {
          !expanded && <div
            className={cx(styles.current, { [styles.disabled]: isEmpty(convertibleTypes) && !isNewColumn })}
            onClick={() => setExpanded(true)}
          >
            <div className={styles.selected}>
              <Icon type={find(fieldTypes, f => f.type === type)?.icon as IconType} />
              {find(fieldTypes, f => f.type === type)?.name}
            </div>
            <CaretRightOutlined />
          </div>
        }
      </div>
      {
        expanded
          ? <Row gutter={[12, 12]} className={styles.select}>
            {map(fieldTypes, f => {
              const disabled = !isNewColumn && !includes(convertibleTypes as [], f.type)

              return (
                <Col 
                  span={8} 
                  key={f.type} 
                  className={cx({ 
                    [styles.disabled]: disabled
                  })}
                >
                  <div
                    className={cx(styles.selectItem, type === f.type ? styles.active : '')}
                    onClick={() => handleTypeClick(f.type)}
                  >
                    <Icon type={f.icon as IconType} />
                    {f.name}
                  </div>
                </Col>
              )
            })}
          </Row>
          : <div className={styles.config}>
            {type === 'datetime' && <DatetimeConfig form={configForm} config={fieldConfig?.typeOptions} />}
            {type === 'number' && <NumberConfig form={configForm} config={fieldConfig?.typeOptions} />}
            {(type === 'singleChoice' || type === 'multiChoice') &&
            <SelectConfig selectOptions={selectOptions} form={configForm} />}
            {isFormula(type) && <FormulaConfig
              formula={formulaExpression}
              form={configForm}
              config={fieldConfig?.typeOptions}
            />}
            {type === 'table' && <TableConfig 
              form={configForm} 
              config={fieldConfig?.typeOptions} 
              isNewColumn={isNewColumn}
            />}
          </div>
      }
      <Row gutter={18}>
        <Col span={12}>
          <Button
            type='primary'
            block
            onClick={handleSubmitClick}
          >确定</Button>
        </Col>
        <Col span={12}>
          <Button
            className={cx(styles.actionBtn, 'gray-btn')}
            block
            onClick={handleCancelClick}
          >取消</Button>
        </Col>
      </Row>
    </div>
  )
}

export default GridFieldConfig
