export enum ROLE_KEY {
  admin = 'admin',
  editable = 'editable',
  readonly = 'readonly'
}

export const ROLE_NAME = {
  admin: '管理员',
  editable: '编辑',
  readonly: '只读'
}

export type RoleType = keyof typeof ROLE_NAME;

export enum PermissionPages {
  table = 'table',
  map = 'map'
}

export type PermissionPagesType = keyof typeof PermissionPages

export enum OrgPermission {
  ProjectPage = 'readonly',
  ProjectCreate = 'editable',
  MemberPage = 'readonly',
  MemberEdit = 'admin',
  MemberRemove = 'admin',
  MemberQuit = 'readonly', // 程序内已处理
  MemberInvite = 'admin',
  SettingPage = 'admin',
  RecycleBinPage = 'readonly',
  RecycleBinRecover = 'admin',
  RecycleBinDelete = 'admin',
  HelpPage = 'readonly'
}

export enum ProjectPermission {
  ProjectPage = 'readonly',
  ProjectRename = 'admin',
  Collaborator = 'readonly',
  CollaboratorEdit = 'admin',
  CollaboratorInvite = 'admin',
  CollaboratorRemove = 'admin', // 程序内已处理
  CollaboratorQuit = 'readonly', // 程序内已处理
  ProjectDuplicate = 'admin',
  ProjectDelete = 'admin',
  ProjectRecycleBinPage = 'readonly',
  RecycleBinRecover = 'admin',
  RecycleBinDelete = 'admin',
  TableSiderPlusButton = 'editable',
  NewTable = 'editable',
  NewTableFromTemplate = 'editable',
  NewFolder = 'editable',
  ImportData = 'editable',
  TableFolderRename = 'editable',
  TableFolderDelete = 'editable',
  MapSiderPlusButton = 'editable',
  NewMapLayer = 'editable',
  NewMapFolder = 'editable',
  MapSetting = 'editable',
  MapFolderRename = 'editable',
  MapFolderDelete = 'editable',
  MapLayerRename = 'editable',
  MapLayerSetting = 'editable',
  MapLayerViewTable = 'readonly',
  MapLayerDelete = 'admin',
  SiderHelp = 'readonly',
  TreeItemDraggable = 'editable',
  MapSearch = 'readonly'
}

export enum TableNodePermisson {
  TablePage = 'readonly',
  MemeberInvite = 'admin',
  ProjectRecycleBinActions = 'admin',
  MenuItemRename = 'editable',
  MenuItemMember = 'readonly',
  MenuItemDuplicate = 'admin',
  MenuItemShare = 'editable',
  MenuItemDelete = 'admin',
  GridHideColumn = 'editable',
  GridFitler = 'editable',
  GridSorting = 'editable',
  GridGrouping = 'editable',
  GridRowHeight = 'editable',
  GridSearch = 'readonly',
  GridExport = 'editable',
  GridSnapshot = 'editable',
  GridShare = 'editable',
  LayerControl = 'readonly',
  MapFilter = 'readonly',
  MapNearBy = 'readonly',
  MapExport = 'editable',
  MapShare = 'editable',
  InfoWindowConfig = 'admin',
  DetailPanelConfig = 'admin',
  DetailPanelShare = 'editable',
  DataEdit = 'editable'

}
