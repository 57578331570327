import Cookies from 'js-cookie'
import isEqual from 'lodash/isEqual'
import dayjs from 'dayjs'
import { store } from 'src/store'
import { actions as appActions } from 'src/state/app/slice'
import { actions as statusActions } from 'src/state/status/slice'
import { WSReadyState } from 'src/state/app/reducers'
import { TOKEN } from 'src/constants/storage'

export interface WorkspaceDataType {
  workspace: {
    type: 'user' | 'org'
    id?: number
  }
}

interface CollaboratorDataType {
  projectID: number
}

type InitialDataType = WorkspaceDataType | CollaboratorDataType

const TIME_TO_RE_CONNECT = 30 * 1000
const UNUSUAL_TIME_TO_RE_CONNECT = 3 * 1000
const APP_CLOSE_CODE = 4999
const NO_AUTH_CLOSE_CODE = 4401
function wsCreator (
  wsURL: string, 
  setReadyState: (number: WSReadyState) => void, 
  getReadyState: () => WSReadyState | null
) {
  let ws: WebSocket
  let timeoutCounter: NodeJS.Timeout
  let savedInitialData: InitialDataType

  function setState (state: WSReadyState = ws.readyState) {
    setReadyState(state)
  }

  function setReconnector (websocket: WebSocket, timeout = TIME_TO_RE_CONNECT) {
    timeoutCounter && clearTimeout(timeoutCounter)
    timeoutCounter = setTimeout(() => {
      if (websocket.readyState === WSReadyState.CONNECTING || !window.navigator.onLine) {
        setReconnector(websocket, timeout)
      } else {
        if (websocket.readyState === WSReadyState.CLOSED) {
          ws = createWebSocket(savedInitialData)
        } else {
          setReadyState(WSReadyState.CLOSING)
          websocket.close(APP_CLOSE_CODE)
        }
      }
    }, timeout)
  }

  function close () {
    timeoutCounter && clearTimeout(timeoutCounter)
    setReadyState(WSReadyState.CLOSING)
    ws.close(APP_CLOSE_CODE)
  }

  function createWebSocket (initialData: InitialDataType): WebSocket {
    if (getReadyState() === WSReadyState.OPEN || getReadyState() === WSReadyState.CONNECTING) return ws
    
    const websocket = new WebSocket(wsURL)

    websocket.addEventListener('close', (ev) => {
      // eslint-disable-next-line no-console
      console.log('close: ', ev, dayjs().format('YYYY-MM-DD HH:mm:ss'))

      // 前端主动关闭或鉴权失败导致后端关闭，不需要触发重连
      if (ev.code === APP_CLOSE_CODE || ev.code === NO_AUTH_CLOSE_CODE) {
        timeoutCounter && clearTimeout(timeoutCounter)
      }
      else {
        setReconnector(websocket, UNUSUAL_TIME_TO_RE_CONNECT)  
      }
      
      if(ev.code === NO_AUTH_CLOSE_CODE) {
        setState(WSReadyState.NO_AUTH)
      }
      else {
        setState()
      }
    })
    websocket.addEventListener('error', (ev) => {
      // eslint-disable-next-line no-console
      console.log('error: ', ev, dayjs().format('YYYY-MM-DD HH:mm:ss'))

      setReconnector(websocket, UNUSUAL_TIME_TO_RE_CONNECT)

      // TODO: error handler
      setState()
    })
    websocket.addEventListener('open', () => {
      savedInitialData = initialData

      websocket.send(JSON.stringify({
        jwt: Cookies.get(TOKEN),
        ...initialData
      }))

      setState()
    })
    websocket.addEventListener('message', (e) => {
      const res = JSON.parse(e.data)

      if (res.type === 'b') {
        setReconnector(websocket)
        return
      }

      const workspace = (initialData as WorkspaceDataType)?.workspace
      if (workspace) {
        store.dispatch({
          type: res.type,
          payload: {
            ...res.payload,
            wsWorkspace: workspace
          }
        })
      } else {
        store.dispatch({
          type: res.type,
          payload: res.payload
        })
      }
    })

    setReconnector(websocket)

    return websocket
  }

  return (initialData: InitialDataType) => {
    if (ws && ws.readyState === WSReadyState.OPEN && isEqual(initialData, savedInitialData)) {
      return {
        ws,
        close
      }
    }

    if (ws && ws.readyState === WSReadyState.OPEN) {
      close()
    }

    ws = createWebSocket(initialData)

    return {
      ws,
      close
    }
  }
}
const APP_WS_URL = process.env.REACT_APP_WS_URL as string
export const createUserWorkspaceWS = wsCreator(APP_WS_URL, (readyState: WSReadyState) => {
  store.dispatch(appActions.updateUserReadyState(readyState))
  store.dispatch(statusActions.updateUserWsStatus(readyState))
}, () => store.getState().app.userReadyState)

export const createOrganizationWorkspaceWS = wsCreator(APP_WS_URL, (readyState: WSReadyState) => {
  store.dispatch(appActions.updateOrganizationReadyState(readyState))
  store.dispatch(statusActions.updateOrgWsStatus(readyState))
}, () => store.getState().app.organizationReadyState)

export const createCollaboratorWS = 
wsCreator(process.env.REACT_APP_COLLABORATOR_WS_URL as string, (readyState: WSReadyState) => {
  store.dispatch(appActions.updateCollaboratorReadyState(readyState))
}, () => store.getState().app.collaboratorReadyState)
