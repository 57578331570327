import { Action, configureStore, ThunkAction } from '@reduxjs/toolkit'

import userSlice from 'src/state/user/slice'
import projectSlice from 'src/state/project/slice'
import appSlice from 'src/state/app/slice'
import workspaceSlice from 'src/state/workspace/slice'
import tableNodeSlice from 'src/state/tableNode/slice'
import layerNodesSlice from 'src/state/layerNode/slice'
import infoWindowSlice from 'src/state/infoWindow/slice'
import mapSlice from 'src/state/map/slice'
import orgSlice from 'src/state/org/slice'
import collaboratorSlice from 'src/state/collaborator/slice'
import formulaSlice from 'src/state/formula/slice'
import statusSlice from 'src/state/status/slice'
import cellSlice from 'src/state/cell/slice'
import notificationSlice from 'src/state/notification/slice'
import errorHandler from 'src/middlewares/errorHandler'
import responseHandler from 'src/middlewares/responseHandler'
import undoRedoMiddleware from 'src/middlewares/undoRedoMiddleware'
import fetchStatusMiddleware from 'src/middlewares/fetchStatusMiddleware'
import undoRedo from 'src/state/undoRedo/slice'

export const store = configureStore({
  reducer: {
    app: appSlice.reducer,
    user: userSlice.reducer,
    project: projectSlice.reducer,
    workspace: workspaceSlice.reducer,
    tableNode: tableNodeSlice.reducer,
    layerNode: layerNodesSlice.reducer,
    infoWindow: infoWindowSlice.reducer,
    map: mapSlice.reducer,
    org: orgSlice.reducer,
    collaborator: collaboratorSlice.reducer,
    formula: formulaSlice.reducer,
    status: statusSlice.reducer,
    cell: cellSlice.reducer,
    notification: notificationSlice.reducer,
    undoRedo: undoRedo.reducer
  },
  middleware: (getDefaultMiddleware) => 
    getDefaultMiddleware({
      immutableCheck: {

      },
      serializableCheck: {
        ignoredPaths: ['undoRedo'],
        ignoredActionPaths: ['payload.undoAction', 'payload.redoAction', 'meta.arg']
      }
    })
      .concat(errorHandler)
      .concat(responseHandler)
      .concat(undoRedoMiddleware)
      .concat(fetchStatusMiddleware)
})

export type RootState = ReturnType<typeof store.getState>
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType,
  RootState,
  unknown,
  Action<string>>
export type AppDispatch = typeof store.dispatch
export type GetState = () => RootState