import React, { useEffect, useState } from 'react'
import { Modal, Input } from 'antd'
import Icon from 'src/components/common/Icon'
import useSelector from 'src/hooks/common/useSelector'
import useDispatch from 'src/hooks/common/useDispatch'
import { actions as projectActions } from 'src/state/project/slice'
import { updateCells } from 'src/state/tableNode/actions'
import styles from './multiLineText.module.scss'

const { TextArea } = Input

export const MultiLineTextEditorModal: React.FC = () => {
  const dispatch = useDispatch()
  const multiLineTextEditor = useSelector(state => state.project.multiLineTextEditor)
  const [value, setValue] = useState('')
  useEffect(() => {
    setValue(multiLineTextEditor.data?.value || '')
  }, [multiLineTextEditor.data?.value])

  const handleBlur = () => {
    if (multiLineTextEditor.data) {
      dispatch(projectActions.updateMultiLineTextEditor({
        data: {
          ...multiLineTextEditor.data,
          value
        }
      }))
    }
    if (multiLineTextEditor.openFrom === 'renderer' && multiLineTextEditor.data) {
      const { id, rowId, cellId } = multiLineTextEditor.data
      dispatch(updateCells({
        id,
        rowId,
        cells: {
          [cellId]: value
        }
      }))
    }
  }

  const handleValueChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setValue(e.target.value)
  }

  function handleModalClose () {
    dispatch(projectActions.updateMultiLineTextEditor({
      visible: false,
      trigger: 'close'
    }))
  }


  return (
    <Modal
      visible={multiLineTextEditor.visible}
      footer={null}
      className={styles.modal}
      width={450}
      onCancel={handleModalClose}
      maskClosable={false}
      closeIcon={<Icon type="close" />}
    >
      <TextArea
        className={styles.textarea}
        onBlur={handleBlur}
        onChange={handleValueChange}
        value={value}
      />
    </Modal>
  )

}
