import pick from 'lodash/pick'
import AppConfig from 'src/config'
import { createInstance } from 'src/utils/fetch'

import { APIResponse } from 'src/typings/common'
import { TableNode } from 'src/typings/mapTableNode'
import { DND } from 'src/typings/tree'
import { TypeOptions, UpdateViewPayload, PasteCellsParams } from 'src/typings/tableNode'

const fetch = createInstance({
  baseURL: `${AppConfig.baseApiURL}/tablenodes/`
})

export type TableNodeForRequest = Pick<TableNode, 'name' | 'projectID' | 'parentID' | 'type'>

export interface ImportProps {
  importOption: {
    firstRowAsHeader: boolean
    csvCommaType?: 'comma' | 'tab'
  },
  name: string,
  projectID: number,
  type: string,
  file?: File,
  url?: string
}

export interface ImportTableNodeForRequest extends Pick<TableNode, 'name' | 'projectID'> {
  type: string
  importOption?: {
    firstRowAsHeader: boolean
  }
  url?: string
}

export interface FileParams {
  fileID: string;
  fileName: string;
  fileSize: number;
  fileExt: string;
  fileType: string;
  createTime: string;
  url: string;
  uploaderID?: number;
}

export interface TableNodeDuplicateRequestData {
  id: number;
  includeData: boolean;
  includePermission?: boolean;
  name: string;
  projectID: number;
}

const TableNodesAPI = {
  async create (params: TableNodeForRequest): Promise<APIResponse> {
    const { data } = await fetch.post('', params)

    return data
  },
  async importData (params: ImportTableNodeForRequest): Promise<APIResponse> {
    const { data } = await fetch.post('import/', params)

    return data
  },
  async move (id: number, params: DND): Promise<APIResponse> {
    const { data } = await fetch.patch(`${id}/move/`, pick(params, ['parentID', 'prevID', 'projectID']))

    return data
  },
  async update (id: number, params: Partial<TableNode>): Promise<APIResponse> {
    const { data } = await fetch.patch(`${id}/`, pick(params, [
      'detailPanelConfig',
      'infoWindowConfig',
      'name',
      'projectID',
      'roleInherited'
    ]))

    return data
  },
  async deleteNode (id: number): Promise<APIResponse> {
    const { data } = await fetch.delete(`${id}/`)

    return data
  },
  async deleteRecycle (id: number): Promise<APIResponse> {
    const { data } = await fetch.delete(`recycle/${id}/`)

    return data
  },
  async recover (id: number): Promise<APIResponse> {
    const { data } = await fetch.post(`recycle/${id}/recover/`)

    return data
  },
  async getDetail (id: number): Promise<APIResponse> {
    const { data } = await fetch.get(`/${id}/`)

    return data
  },
  async getRows (id: number, rowIds: string[]): Promise<APIResponse> {
    const { data } = await fetch.get(`/${id}/rows/`, {
      params: {
        id,
        rowID: rowIds
      }
    })

    return data
  },
  async postRow (id: number, prevRowId?: string, cells: unknown = {}): Promise<APIResponse> {
    const { data } = await fetch.post(`${id}/rows/`, {
      cells,
      prevRowId
    })

    return data
  },
  async moveViewRow (id: number, viewId: string, rowIds: string[], prevRowId?: string): Promise<APIResponse> {
    const { data } = await fetch.patch(`${id}/views/${viewId}/rows/move/`, {
      prevRowId,
      rowIDs: rowIds
    })

    return data
  },
  async deleteRows (id: number, rowIDs: string[]): Promise<APIResponse> {
    const { data } = await fetch.delete(`${id}/rows/`, {
      data: { rowIDs }
    })

    return data
  },
  async createColumn (
    id: number,
    name: string,
    type: string,
    prevColumnId?: string,
    typeOptions?: Partial<TypeOptions>
  ): Promise<APIResponse> {
    const { data } = await fetch.post(`${id}/columns/`, {
      name,
      type,
      prevColumnID: prevColumnId,
      typeOptions
    })

    return data
  },
  async updateViewColumn (
    id: number,
    viewId: string,
    columnId: string,
    form: unknown
  ): Promise<APIResponse> {
    const { data } = await fetch.patch(`${id}/views/${viewId}/columns/${columnId}/`, pick(form, ['isHidden', 'width']))

    return data
  },
  async unhideViewColumn (
    id: number,
    viewId: string
  ): Promise<APIResponse> {
    const { data } = await fetch.patch(`${id}/views/${viewId}/columns/unhide/`)

    return data
  },
  async moveViewColumn (id: number, viewId: string, columnId: string, prevColumnId?: string): Promise<APIResponse> {
    const { data } = await fetch.patch(`${id}/views/${viewId}/columns/${columnId}/move/`, {
      prevColumnID: prevColumnId
    })

    return data
  },
  async updateColumn (
    id: number,
    columnId: string,
    name?: string,
    type?: string,
    typeOptions?: Partial<TypeOptions>
  ): Promise<APIResponse> {
    const { data } = await fetch.patch(`${id}/columns/${columnId}/`, {
      name,
      type,
      typeOptions
    })

    return data
  },
  async deleteColumn (
    id: number,
    columnId: string
  ): Promise<APIResponse> {
    const { data } = await fetch.delete(`${id}/columns/${columnId}/`)

    return data
  },
  async patchCell (id: number, rowId: string, cells: unknown = {}): Promise<APIResponse> {
    const { data } = await fetch.patch(`${id}/rows/${rowId}/cells/`, {
      cells
    })

    return data
  },
  async updateView (id: number, viewId: string, params: UpdateViewPayload['updatedFields']): Promise<APIResponse> {
    const { data } = await fetch.patch(`${id}/views/${viewId}/`, pick(params, [
      'filterCondition',
      'filters',
      'groups',
      'name',
      'orders'
    ]))

    return data
  },
  async addAttachment (
    id: number,
    rowId: string,
    columnId: string,
    fileParams: Omit<FileParams, 'createTime'>
  ): Promise<APIResponse> {
    const { data } = await fetch.post(`${id}/rows/${rowId}/cells/${columnId}/attachments`, fileParams)

    return data
  },
  async deleteAttachment (
    id: number,
    rowId: string,
    columnId: string,
    fileId: string
  ): Promise<APIResponse> {
    const { data } = await fetch.delete(`${id}/rows/${rowId}/cells/${columnId}/attachments/${fileId}/`)

    return data
  },
  async updateAttachment (
    id: number,
    rowId: string,
    columnId: string,
    fileId: string,
    name: string
  ): Promise<APIResponse> {
    const { data } = await fetch.patch(`${id}/rows/${rowId}/cells/${columnId}/attachments/${fileId}/`, {
      name
    })

    return data
  },
  async pasteCells (id: number, params: PasteCellsParams): Promise<APIResponse> {
    const { data } = await fetch.post(`${id}/pasteCells/`, params)

    return data
  },
  async getMembers (id: number): Promise<APIResponse> {
    const { data } = await fetch.get(`${id}/members/`)

    return data
  },
  async updateMember (id: number, members: { id: number, role: string }[]): Promise<APIResponse> {
    const { data } = await fetch.put(`${id}/members/`, { members })

    return data
  },
  async deleteMember (id: number, memberID: number): Promise<APIResponse> {
    const { data } = await fetch.delete(`${id}/members/${memberID}/`)

    return data
  },
  async copy (id: number, body: TableNodeDuplicateRequestData): Promise<APIResponse> {
    const { data } = await fetch.post(`${id}/copies/`, { ...body })

    return data
  },
  async getSnapshots (id: number, projectID: number): Promise<APIResponse> {
    const { data } = await fetch.get(`${id}/snapshots/`, { 
      params: { 
        projectID 
      } 
    })

    return data
  },
  async createSnapshot (id: number, params: { name: string, projectID: number }): Promise<APIResponse> {
    const { data } = await fetch.post(`${id}/snapshots/`, params)

    return data
  },
  async restoreSnapshot (id: number, projectID: number, snapshotID: number): Promise<APIResponse> {
    const { data } = await fetch.post(`${id}/snapshots/${snapshotID}/restore/`, { projectID })

    return data
  }
}

export default TableNodesAPI
