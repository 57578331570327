import find from 'lodash/find'
import { WorkspaceRole, RoleEntityType, ROLE_ENTITY } from 'src/typings/workspace'
import useSelector from 'src/hooks/common/useSelector'
import { useParameters } from 'src/hooks/common/useParameters'

import { ROLE_KEY, RoleType } from 'src/constants/permissions'


interface Return {
  workspaceRoles?: WorkspaceRole[]
  checkPermission: (id?: number, role?: RoleType, entity?: RoleEntityType) => boolean
  checkOrgPermission: (id?: number, role?: RoleType) => boolean
  checkProjectPermission: (id?: number, role?: RoleType) => boolean
}

export function useWorkspaceRoles (): Return {
  const { projectId, workspaceType } = useParameters()
  const workspaceRoles = useSelector(state => state.workspace.workspaceRoles)

  /**
   * TODO: consider better way to handle isTablePermissionInUserWorkspace
   * @description 根据 type 和 id 查询当前角色的角色，于 expectedRole 对比，权限足够则返回 true，不足返回 false
   * 如果调用时处于用户工作区并且查询的 type 是 table，则直接查询当前页面项目的角色 (id = projectId, type = project)
   * @param id 查询指定的 id
   * @param expectedRole 期望的角色，支持 "admin" | "editable" | "readonly", 通常情况下需要在 src/constants/permissons.ts 定义便于统一管理
   * @param type 查询的类型，支持 "org" | "project" | "table"
   * @returns boolean
   */
  const checkPermission = (id?: number, expectedRole?: RoleType, type?: RoleEntityType) => {
    if (!expectedRole) {
      // eslint-disable-next-line no-console
      console.warn(`${expectedRole} is not valid expectedRole, checkPermission will always return false`)
      return false
    }
    const isTablePermissionInUserWorkspace = workspaceType === 'uw' && type === 'table'
    const targetType = isTablePermissionInUserWorkspace ? 'project' : type
    const targetId = isTablePermissionInUserWorkspace ? (projectId ?? id) : id
    const currentRole = find(workspaceRoles, (r: WorkspaceRole) => r.id === targetId && r.type === targetType)

    switch (currentRole?.role) {
    case ROLE_KEY.readonly:
      return expectedRole === ROLE_KEY.readonly
    case ROLE_KEY.editable:
      return expectedRole === ROLE_KEY.readonly || expectedRole === ROLE_KEY.editable
    case ROLE_KEY.admin:
      return expectedRole === ROLE_KEY.readonly || expectedRole === ROLE_KEY.editable || expectedRole === ROLE_KEY.admin
    default:
      return false
    }
  }

  return {
    workspaceRoles,
    checkPermission,
    checkOrgPermission: (id?: number, role?: RoleType) => checkPermission(id, role, ROLE_ENTITY.org),
    checkProjectPermission: (id?: number, role?: RoleType) => checkPermission(id, role, ROLE_ENTITY.project)
  }
}
