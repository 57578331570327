import React, { useEffect } from 'react'
import { nanoid } from '@reduxjs/toolkit'
import { Modal, Input, Button, Switch, Form, Tooltip } from 'antd'
import { useIntl } from 'react-intl'
import type { DialogStateReturn as Dialog } from 'src/hooks/common/useDialog'
import Icon from 'src/components/common/Icon'
import useDispatch from 'src/hooks/common/useDispatch'
import type { Project } from 'src/typings/project'
import type { TreeNode } from 'src/typings/tree'
import { duplicateProject } from 'src/state/project/actions'
import { duplicateTableNode } from 'src/state/tableNode/actions'
import { useParameters } from 'src/hooks/common/useParameters'
import type { ProjectDuplicateRequestData } from 'src/api/projects'
import type { TableNodeDuplicateRequestData } from 'src/api/tablenodes'
import styles from './duplicationModal.module.scss'

interface Props {
  dialog: Dialog;
  duplicate?: Project | TreeNode;
  type: 'project' | 'table';
  projectID?: number;
}
export const DuplicationModal: React.FC<Props> = ({
  dialog,
  duplicate,
  type,
  projectID
}) => {
  const [form] = Form.useForm()
  const intl = useIntl()
  const dispatch = useDispatch()
  const { workspaceType } = useParameters()
  const isOrgWorkspace = workspaceType === 'w'
  const isDuplicateTable = type === 'table'

  function handleCancelClick () {
    dialog.close()
  }

  function handleSubmit (values: unknown) {
    if (isDuplicateTable) {
      dispatch(duplicateTableNode({
        data: values as TableNodeDuplicateRequestData
      }))
    } else {
      dispatch(duplicateProject({
        data: values as ProjectDuplicateRequestData
      }))
    }

    dialog.close()
  }

  function handleReset () {
    form.resetFields() 
  }

  useEffect(() => {
    if (duplicate) {
      form.setFieldsValue({
        id: duplicate.id,
        name: `${duplicate.name}副本`,
        includeData: true,
        includePermission: isOrgWorkspace ? isOrgWorkspace : undefined,
        projectID: isDuplicateTable ? projectID : undefined
      })
    }
  }, [duplicate, form, isOrgWorkspace, isDuplicateTable, projectID])

  return (
    <Modal
      visible={dialog.visible}
      afterClose={handleReset}
      width={400}
      onCancel={() => dialog.close()}
      title={null}
      footer={null}
      closable={false}
      forceRender
    >
      <div>
        <header className={styles.header}>
          {intl.formatMessage({ id: `${type}.duplicate.title` })}
        </header>
        <Form
          name={`projectDuplication_${nanoid()}`}
          onFinish={handleSubmit}
          onReset={handleReset}
          colon={false}
          requiredMark={false}
          form={form}
        >
          <Form.Item
            name="id"
            hidden
          >
            <Input />
          </Form.Item>
          {
            isDuplicateTable &&
            <Form.Item hidden name="projectID">
              <Input />
            </Form.Item>
          }
          <Form.Item
            label={intl.formatMessage({ id: `${type}.name` })}
            name="name"
            labelAlign="left"
            labelCol={{ span: 6 }}
            className={styles.item}
            rules={[
              {
                required: true,
                message: '请输入名称'
              }
            ]}
          >
            <Input allowClear autoComplete="off" />
          </Form.Item>
          <Form.Item className={styles.item}>
            <div className={styles.row}>
              <Form.Item
                name="includeData"
                valuePropName="checked"
                noStyle
              >
                <Switch size="small" className={styles.switch} />
              </Form.Item>
              <span>{ intl.formatMessage({ id: `${type}.duplicate.data` }) }</span>
              <Tooltip title={intl.formatMessage({ id: `${type}.duplicate.data.tooltip` })}>
                <Icon type="info" className={styles.icon} />
              </Tooltip>
            </div>
          </Form.Item>
          {
            isOrgWorkspace &&
            <Form.Item className={styles.item}>
              <div className={styles.row}>
                <Form.Item
                  name="includePermission"
                  valuePropName="checked"
                  noStyle
                >
                  <Switch size="small" className={styles.switch} />
                </Form.Item>
                <span>{ intl.formatMessage({ id: `${type}.duplicate.permissions` }) }</span>
                <Tooltip title={intl.formatMessage({ id: `${type}.duplicate.permissions.tooltip` })}>
                  <Icon type="info" className={styles.icon} />
                </Tooltip>
              </div>
            </Form.Item>
          }
          <Form.Item className={styles.footer}>
            <Button type="primary" htmlType="submit" size="large">
              { intl.formatMessage({ id: 'confirm' })}
            </Button>
            <Button size="large" className="gray-btn" htmlType="reset" onClick={handleCancelClick}>
              { intl.formatMessage({ id: 'cancel' })}
            </Button>
          </Form.Item>
        </Form>
      </div>
    </Modal>
  )
}

export default DuplicationModal