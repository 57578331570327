import React, { forwardRef, useImperativeHandle, useState, useEffect, useRef } from 'react'
import toNumber from 'lodash/toNumber'
import isNumber from 'lodash/isNumber'
import isNaN from 'lodash/isNaN'
import isNil from 'lodash/isNil'
import noop from 'lodash/noop'
import type { ICellEditorParams } from 'ag-grid-community'
import { InputNumber, Tooltip } from 'antd'
import { useCellEditorParams } from 'src/hooks/field/useCellParams'
import { TypeOptions } from 'src/typings/tableNode'

export interface Props extends ICellEditorParams {
  typeOptions: TypeOptions;
  onBlur?: () => void;
  onPressEnter?: () => void;
  onChange?: (value: number | undefined) => void;
  inGrid?: boolean;
  inDetailPanel: boolean;
}

function validator (val: number | string | undefined) {
  return val === undefined || (isNumber(val) && !isNaN(val))
}

export const NumberEditor = forwardRef((props: Partial<Props>, ref) => {
  const { inGrid = false, inDetailPanel = false } = props
  const [value, setValue] = useState<number | undefined>(props.value)
  const [error, setError] = useState<string | null>(null)
  const { onBlur = noop, onPressEnter = noop, onChange } = props
  const { typeOptions } = useCellEditorParams(props as ICellEditorParams) as unknown as Props
  const inputRef = useRef<HTMLInputElement>(null)
  const [isFocusStepper, setIsFocusStepper] = useState(false)

  useImperativeHandle(ref, () => {
    return {
      getValue () {
        return !isNil(value) ? toNumber(value) : null
      },
      isCancelAfterEnd () {
        if (!validator(value)) return true
      },
      isCancelBeforeStart () {
        return props.charPress && ('1234567890'.indexOf(props.charPress) === -1)
      },
      afterGuiAttached () {
        if(props.charPress){
          setValue(parseInt(props.charPress))
        }
        else if (props.value) {
          setValue(value)
        }
        inputRef.current?.focus()
      }
    }
  })

  useEffect(() => {
    onChange?.(value)
  }, [value, onChange])

  function handleValueChange (val: number | string | null | undefined) {
    if (val === '') {
      setValue(undefined)
      return
    }
    const num = toNumber(val)
    if (!isNil(val) && isNumber(num) && !isNaN(num)) {
      setError(null)
      setValue(num)
    } else {
      setError('数据格式错误')
    }
  }

  /**
   * WORKAROUND: focus input again after stepping to allow input blur event fire
   */
  function handleStep () {
    setIsFocusStepper(true)
    setTimeout(() => {
      inputRef.current?.focus()
    }, 500)
  }

  /**
   * WORKAROUND: using stepper will blur input in grid, use isFocusStepper flag to check
   */
  function handleBlur () {
    if (validator(value)) {
      onBlur(value)
      !isFocusStepper && props.stopEditing?.()
    }
    setIsFocusStepper(false)
  }

  function handlePressEnter () {
    if (validator(value)) {
      onPressEnter()
    }
  }

  return (
    <Tooltip title={error} visible={!!error} placement="topLeft">
      <InputNumber
        ref={inputRef}
        style={{ width: '100%' }}
        autoFocus={inGrid || inDetailPanel}
        bordered={!inGrid}
        precision={typeOptions?.precision + ((typeOptions?.precision && typeOptions?.format === 'percentage') ? 2 : 0)}
        autoComplete="off"
        value={value}
        onStep={handleStep}
        onBlur={handleBlur}
        onPressEnter={handlePressEnter}
        onChange={handleValueChange}
        onMouseDown={ev => ev.stopPropagation()}
      />
    </Tooltip>
  )
})
