import { ConfigProvider as AntdConfigProvider, message } from 'antd'
import zhCN from 'antd/lib/locale/zh_CN'
import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { IntlProvider } from 'react-intl'
import { actions as statusActions } from 'src/state/status/slice'
import { REQUEST_ERROR_TYPE } from 'src/constants/status'
import messages from 'src/messages'
import App from './App'
import config from './config'
import { store } from './store'

import 'src/styles/variables.css'
import 'src/styles/global.css'
import 'src/styles/antd.extend.scss'
import 'qumap/packages/core/src/styles/index.scss'
import 'viewerjs/dist/viewer.css'

if (process.env.NODE_ENV === 'development' && process.env.REACT_APP_ENABLE_MOCK) {
  // eslint-disable-next-line @typescript-eslint/no-var-requires
  const { worker } = require('./mocks/msw/browser')
  worker.start()
}

message.config({
  prefixCls: config.antdPrefix + '-message',
  maxCount: 3
})

AntdConfigProvider.config({ prefixCls: config.antdPrefix })

window.addEventListener('load', function () {
  function updateOnlineStatus (event: Event) {
    if (event.type === 'offline') {
      store.dispatch(statusActions.networkStatus(REQUEST_ERROR_TYPE.NETWORK_OFFLINE))
    } else {
      store.dispatch(statusActions.clearNetworkStatus())
    }
  }

  window.addEventListener('online', updateOnlineStatus)
  window.addEventListener('offline', updateOnlineStatus)
})

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <AntdConfigProvider prefixCls={config.antdPrefix} autoInsertSpaceInButton={false} locale={zhCN}>
        <IntlProvider messages={messages} locale="zh-cn">
          <App />
        </IntlProvider>
      </AntdConfigProvider>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
)
