import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { Dropdown, Menu, Badge } from 'antd'
import cx from 'clsx'
import Icon from 'src/components/common/Icon'
import Avatar from 'src/components/common/Avatar'
import { useWorkspaces } from 'src/hooks/workspace/useWorkspaces'
import SmallDivider from 'src/components/common/SmallDivider'
import useRouter from 'src/hooks/common/useRouter'
import NotificationPanel from 'src/components/common/NotificationPanel'
import useSelector from 'src/hooks/common/useSelector'
import styles from './header.module.scss'

interface Props {
  username?: string
  className?:string
  headerLeft?: JSX.Element
  headerCenter?: JSX.Element
  headerRight?: JSX.Element
}

const Header: React.FC<Props> = ({ className, headerLeft, headerCenter, headerRight }) => {
  const { workspace } = useWorkspaces('uw')
  const { logout } = useRouter()
  const [notificationVisible, setNotificationVisible] = useState(false)
  const unreadCount = useSelector(state => state.notification.unreadCount)

  function handleNotificationVisibilityChange (visible: boolean) {
    setNotificationVisible(visible)
  }

  const menu = (
    <Menu>
      <Menu.Item>
        <Link to="/user/">
        个人设置
        </Link>
      </Menu.Item>
      <Menu.Item danger onClick={logout}>
        退出登录
      </Menu.Item>
    </Menu>
  )
  return (
    <header className={cx(styles.header, className)}>
      <div className={styles.left}>
        {headerLeft}
      </div>
      <div className={styles.center}>
        {headerCenter}
      </div>
      <div className={styles.right}>
        <div className={styles.navs}>
          {headerRight}
          {/* <div className={styles.navItem}>
            <Tooltip placement="bottom" title="搜索">
              <Icon type="search" className={styles.icon} />
            </Tooltip>
          </div> */}
          <Dropdown 
            overlay={<NotificationPanel visible={notificationVisible} />} 
            trigger={['click']}
            onVisibleChange={handleNotificationVisibilityChange}
          >
            <div className={cx(styles.navItem, styles.notiItem)}>
              <Badge count={unreadCount} className={styles.notiBadge} offset={[-3, 6]}>
                <Icon
                  type="notification"
                  className={styles.icon} />
              </Badge>
            </div>
          </Dropdown>
          <div className={styles.navItem}><SmallDivider /></div>
          <Dropdown overlay={menu} trigger={['click']}>
            <div className={styles.navItem}>
              <Avatar src={workspace?.avatar} />
            </div>
          </Dropdown>
        </div>
      </div>
    </header>
  )
}

export default Header
