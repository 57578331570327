import React, { useState, useEffect } from 'react'
import cx from 'clsx'
import { useLocation } from 'react-router-dom'
import toNumber from 'lodash/toNumber'
import split from 'lodash/split'
import map from 'lodash/map'
import sortBy from 'lodash/sortBy'
import find from 'lodash/find'
import filter from 'lodash/filter'
import get from 'lodash/get'
import FieldRender, { Item } from 'src/components/detailPanel/FieldRender/FieldRender'
import { FIELD_TYPES } from 'src/constants/fieldType'
import type { Column, Row, View } from 'src/typings/tableNode'
import Icon, { IconType } from 'src/components/common/Icon'
import AttachmentPreviewGlobal from 'src/components/field/Attachment/AttachmentPreviewGlobal'
import { previewAttachmentEmitter } from 'src/utils/emitter'
import useDispatch from 'src/hooks/common/useDispatch'
import { getRows } from 'src/state/tableNode/actions'
import styles from './compare.module.scss'

const Compare: React.FC = () => {
  const location = useLocation()
  const dispatch = useDispatch()
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [tableData, setTableData] = useState<any[]>([])
  const searchParams = new URLSearchParams(location.search)
  const tableNodeId = toNumber(searchParams.get('t'))
  const rowIdsString = searchParams.get('r')

  useEffect(() => {
    dispatch(getRows({
      id: tableNodeId,
      rowIds: split(rowIdsString, ',')
    })).then(res => {
      const payload = res.payload as {
        columns: Column[];
        views: View[];
        rows: Row[]
      }
      const columns = payload.columns
      const rows = payload.rows
      const views = payload.views
      const defaultView = views?.[0]

      const sortedColumn = map(filter(sortBy(defaultView?.columns, c => c.order), c => !c.isHidden), c => {
        return find(columns, column => column.id === c.id)
      })

      const data = map(sortedColumn, (c: Column) => {
        return {
          id: c.id,
          name: c.name,
          type: c.type,
          typeOptions: c.typeOptions,
          value: map(rows, r => {
            return r.cells[c.id]
          })
        }
      })

      setTableData(data)

    })
  }, [dispatch, tableNodeId, rowIdsString])

  function handleClick (item: Item, e: React.MouseEvent) {
    const currentPreviewId = get(e.target, 'dataset.id')
    if (item.type === 'attachment' && currentPreviewId) {
      previewAttachmentEmitter.emit({
        currentPreviewId,
        attachments: item.value
      })
    }
  }
  
  return (
    <div className={styles.wrap}>
      <div className={styles.table}>
        {
          map(tableData, (row) => {
            return <div key={row.id} className={styles.row}>
              <div className={cx(styles.cell, styles.name)}>
                <Icon className={styles.icon} type={FIELD_TYPES[row.type]?.icon as IconType} />
                {row.name}
              </div>
              {
                map(row.value, (value, index) => {
                  const item = {
                    id: row.id, 
                    name: row.name, 
                    value,
                    type: row.type, 
                    typeOptions: row.typeOptions,
                    isPlain: true
                  }
                  return <div key={index} className={styles.cell}>
                    <FieldRender 
                      onClick={(e) => handleClick(item, e)}
                      item={item} />
                  </div>
                })
              }
            </div>
          })
        }
      </div>
      <AttachmentPreviewGlobal />
    </div>
  )
}

export default Compare