import React from 'react'
import map from 'lodash/map'
import cx from 'clsx'
import { PlusOutlined } from '@ant-design/icons'
import { checkAbleToPreview } from 'src/utils/attachment'
import { getThumbnailUrl } from 'src/utils/image'
import type { FileParams } from 'src/api/tablenodes'
import styles from './attachment.module.scss'
import iconStyles from './icons.module.scss'

interface Props {
  value?: FileParams[];
  inDetailPanel?: boolean;
  editable?: boolean;
}

export const AttachmentRenderer: React.FC<Props> = (props) => {
  const { value, inDetailPanel = false, editable } = props

  return (
    <div 
      className={cx(styles.list, { [styles.inDetialPanel]: inDetailPanel })}
      data-attachment="list"
    >
      {
        editable && <div className={styles.addButton} data-attachment="add">
          <PlusOutlined />
        </div>
      }
      {
        map(value, v => {
          const ableToView = checkAbleToPreview(v.fileExt)
          const thumbnail = getThumbnailUrl(v.url)

          return (
            ableToView
              ? <div
                key={v.fileID}
                title={v.fileName}
                className={styles.imageWrap}
              >
                <div 
                  className={styles.image} 
                  style={{ backgroundImage: `url(${thumbnail})` }} 
                  data-id={v.fileID}
                />
              </div>
              : <div key={v.fileID} className={styles.file}>
                <div
                  key={v.fileID}
                  title={v.fileName}
                  data-id={v.fileID}
                  className={cx(iconStyles.fileIcon, iconStyles[v.fileExt] ?? iconStyles.unknown)}
                />
              </div>
          )
        })
      }
    </div>
  )
}
