import axios from 'axios'
import { InfoResponse } from 'src/api/upload'
import { CosAuth, camSafeUrlEncode } from 'src/utils/cosAuth'

interface Return {
  url: string;
}

export async function uploadToCOS (file: File, info: InfoResponse['detail']): Promise<Return> {
  const { filePath, credentials, prefix } = info
  const uploadPath = prefix + camSafeUrlEncode(filePath).replace(/%2F/g, '/')

  await axios.put(uploadPath, file, {
    headers: {
      'x-cos-security-token': credentials.Token,
      'Content-Type': file.type,
      Authorization: CosAuth({
        SecretId: credentials.TmpSecretId,
        SecretKey: credentials.TmpSecretKey,
        Method: 'PUT',
        Pathname: '/' + filePath
      })
    }
  })

  return {
    url: uploadPath
  }
}
