import React from 'react'
import { PlusOutlined } from '@ant-design/icons'
import filter from 'lodash/filter'
import DropdownMenu from 'src/components/common/DropdownMenu'
import {
  CATALOG__MAP_GROUP_NEW,
  CATALOG__MAP_GROUP_MENU,
  CATALOG__MAP_ITEM_MENU,
  CATALOG__TABLE_GROUP_NEW,
  CATALOG__TABLE_GROUP_MENU,
  CATALOG__TABLE_ITEM_MENU
} from 'src/constants/menu'
import { useParameters } from 'src/hooks/common/useParameters'
import Icon from 'src/components/common/Icon'
import { TreeNode } from 'src/typings/tree'
import { WorkspaceRole } from 'src/typings/workspace'
import styles from './menu.module.scss'

export enum Catalog {
  MAP_GROUP_MENU,
  MAP_GROUP_NEW,
  MAP_ITEM_MENU,
  TABLE_GROUP_MENU,
  TABLE_GROUP_NEW,
  TABLE_ITEM_MENU,
}

export interface MenuProps {
  type: Catalog;
  node?: TreeNode;
  roleData?: Partial<WorkspaceRole>
  onMenuClick?: (action: string, menu?: TreeNode) => void;
  trigger?: ('contextMenu' | 'click' | 'hover')[];
}

const Menu: React.FC<MenuProps> = (props) => {
  const { type, node, roleData, onMenuClick, trigger } = props
  const { workspaceType } = useParameters()

  const handleMenuItemClick = async (action: string) => {
    onMenuClick && onMenuClick(action, node)
  }

  const getMenu = () => {
    switch (type) {
    case Catalog.MAP_GROUP_MENU:
      return CATALOG__MAP_GROUP_MENU
    case Catalog.MAP_GROUP_NEW:
      return CATALOG__MAP_GROUP_NEW
    case Catalog.MAP_ITEM_MENU:
      return CATALOG__MAP_ITEM_MENU
    case Catalog.TABLE_GROUP_MENU:
      return CATALOG__TABLE_GROUP_MENU
    case Catalog.TABLE_GROUP_NEW:
      return CATALOG__TABLE_GROUP_NEW
    case Catalog.TABLE_ITEM_MENU:
      if (workspaceType === 'uw') {
        return filter(CATALOG__TABLE_ITEM_MENU, item => item.action !== 'editPermission')
      } else {
        return CATALOG__TABLE_ITEM_MENU
      }
    default:
      return []
    }
  }
  const handleIconClick = (ev: React.MouseEvent) => {
    ev.stopPropagation()
  }
  const getNewIcon = () => {
    if (type === Catalog.MAP_GROUP_NEW || type === Catalog.TABLE_GROUP_NEW) {
      return <PlusOutlined style={{ color: 'white' }} />
    } else {
      return <span className={styles.iconMore} onClick={handleIconClick}><Icon type={'more'} /></span>
    }
  }
  const renderMenu = () => {
    return (
      <DropdownMenu
        onClick={handleMenuItemClick}
        roleData={roleData}
        actions={getMenu()}
        trigger={trigger}
      >
        {getNewIcon()}
      </DropdownMenu>
    )
  }
  return (
    <>
      {renderMenu()}
    </>
  )
}

export default Menu
