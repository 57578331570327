import { useEffect } from 'react'
import find from 'lodash/find'
import findIndex from 'lodash/findIndex'
import isNil from 'lodash/isNil'
import isNaN from 'lodash/isNaN'
import { useHistory } from 'react-router-dom'
import { Project } from 'src/typings/project'
import { Workspace, WorkspaceDetail } from 'src/typings/workspace'
import useSelector from 'src/hooks/common/useSelector'
import { getWorkspaceDetail } from 'src/state/workspace/actions'
import { projectSelectors } from 'src/state/project/slice'
import useDispatch from 'src/hooks/common/useDispatch'
import { useParameters } from 'src/hooks/common/useParameters'

interface Return {
  projects?: Project[];
  project?: Project;
  currentWorkspace?: WorkspaceDetail;
}

function notFoundRedirect (redirect: () => void, projects: Project[], projectId?: number) {
  if (isNil(projectId) || isNaN(projectId)) return

  const isProjectExist = findIndex(projects, p => p.id === projectId) !== -1

  if (!isProjectExist) {
    redirect()
  }
}

export function useProject (workspace?: Workspace, projectId?: number): Return {
  const dispatch = useDispatch()
  const history = useHistory()
  const { workspaceType } = useParameters()
  const projects = useSelector(projectSelectors.selectAll)
  const project = find(projects, project => project.id === projectId)
  const workspaceDetail = useSelector(state => state.workspace.workspace)
  
  useEffect(() => {
    if (workspace && (workspace.id !== workspaceDetail?.id || workspace?.type !== workspaceDetail?.type)) {
      dispatch(getWorkspaceDetail(workspace)).then(res => {
        const projects = (res.payload as WorkspaceDetail).detail.projects
        notFoundRedirect(() => {
          history.replace(`/${workspaceType}/${workspace?.id}`)
        }, projects, projectId)
      })
    }
  }, [dispatch, workspace, workspaceDetail?.id, workspaceDetail?.type, projectId, history, workspaceType])

  return {
    projects,
    project,
    currentWorkspace: workspaceDetail
  }
}
