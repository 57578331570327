import axios from 'axios'
import toLower from 'lodash/toLower'
import includes from 'lodash/includes'
import Viewer from 'viewerjs'

const fileTypeToPreivew = ['png', 'jpeg', 'jpg', 'gif']

export function getFileExt (fileName: string): string {
  const index = fileName.lastIndexOf('.')

  return toLower((index < 1) ? 'unknown' : fileName.substr(index + 1))
}

export function getPureFileName (fileName: string): string {
  const index = fileName.lastIndexOf('.')

  return (index < 1) ? fileName : fileName.slice(0, index)
}

export function combineFileNameWithExt (fileName: string, fileExt: string): string {
  if (fileExt === 'unknown') return fileName

  return `${fileName}.${fileExt}`
}

export function checkAbleToPreview (fileExt: string): boolean {
  return includes(fileTypeToPreivew, fileExt)
}

export function inlinePreview (index: number, selector: string, el: HTMLElement | null): Promise<Viewer | undefined> {
  return new Promise((resolve) => {
    if (!el) return resolve(undefined)

    const image = el.querySelectorAll(`.${selector}`)[index]?.querySelector('img')

    if (image) {
      image.onload = () => {
        resolve(
          new Viewer(image, {
            inline: true,
            backdrop: false,
            button: false,
            navbar: false,
            title: false,
            minWidth: el.offsetWidth,
            minHeight: el.offsetHeight,
            toolbar: {
              oneToOne: 3,
              zoomIn: 3,
              zoomOut: 3,
              rotateLeft: 3,
              rotateRight: 3,
              flipHorizontal: 3,
              flipVertical: 3,
              reset: 3,
              prev: false,
              next: false,
              play: false
            }
          })
        )
      }

      image.src = image.dataset.src as string
    }
  })
}

export async function download (url: string, name: string): Promise<void> {
  const { data } = await axios.get(url, {
    headers: {
      'Access-Control-Allow-Origin': '*'
    },
    responseType: 'blob'
  })
  const link = document.createElement('a')

  link.style.display = 'none'
  link.download = name
  link.href = URL.createObjectURL(data)
  document.body.appendChild(link)
  link.click()
  URL.revokeObjectURL(link.href)
  document.body.removeChild(link)
}
