import React from 'react'

interface Props {
  visible?: boolean
}

function withVisible<T> (Comp: React.FC<T>): React.FC<T & Props > {
  const ComponentWithVisible: React.FC<T & Props> = (props) => {

    return <div style={{ display: props.visible ? 'block' : 'none', minHeight: 0 }}>
      <Comp {...props} />
    </div>
  }

  return ComponentWithVisible
}

export default withVisible
