import React from 'react'
import size from 'lodash/size'
import map from 'lodash/map'
import isEmpty from 'lodash/isEmpty'
import cx from 'clsx'
import DropdownMenu from 'src/components/common/DropdownMenu'
import type { ActionItemType } from 'src/components/common/DropdownMenu/DropdownMenu'
import ActionButton from 'src/components/project/ActionButton'
import SmallDivider from 'src/components/common/SmallDivider'
import { ROLE_ENTITY } from 'src/typings/workspace'
import { COMPARE_BUTTON_MAX_TOOPTIP } from 'src/constants/tooltip'
import styles from './batchActions.module.scss'

export const MINIMUM_COMPARE_COUNT = 2
export const MAXIMUM_COMPARE_COUNT = 6

interface Props {
  tableNodeId: number;
  onActionClick: (action: string) => void;
  actions: ActionItemType[];
  selectedText: string;
  selectedItems?: unknown[];
}

const BatchActions: React.FC<Props> = ({
  tableNodeId,
  actions,
  selectedText,
  selectedItems,
  onActionClick
}) => {

  const updatedActions = map(actions, m => {
    const isExceed = size(selectedItems) > MAXIMUM_COMPARE_COUNT
    const isCompareDisabled = size(selectedItems) < MINIMUM_COMPARE_COUNT || isExceed
    if (isCompareDisabled && m.key === 'compare') {
      m.disabled = true
      m.disabledTooltip = isExceed ? COMPARE_BUTTON_MAX_TOOPTIP : null
    } else {
      m.disabled = false
      m.disabledTooltip = null
    }

    return m
  })

  return (
    <div className={cx(styles.action, { [styles.visible]: !isEmpty(selectedItems) })}>
      <div className={styles.selectionInfo}>{selectedText}</div>
      <DropdownMenu
        roleData={{
          id: tableNodeId,
          type: ROLE_ENTITY.table
        }}
        trigger={['hover']}
        onClick={onActionClick}
        actions={updatedActions}>
        <ActionButton
          icontype={'batch'}
          text={'批量操作'}
        />
      </DropdownMenu>
      <SmallDivider light mh15 />
    </div>
  )
}

export default BatchActions
