import { useCallback, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import map from 'lodash/map'
import filter from 'lodash/filter'
import isNaN from 'lodash/isNaN'
import forEach from 'lodash/forEach'
import find from 'lodash/find'
import get from 'lodash/get'
import compact from 'lodash/compact'
import uniq from 'lodash/uniq'
import size from 'lodash/size'
import useSelector from 'src/hooks/common/useSelector'
import { getTableNodeDetails, getAllTableNodes } from 'src/state/tableNode/actions'
import { getLayerTables } from 'src/state/project/actions'
import { Column, TableNode } from 'src/typings/tableNode'
import { tableNodeSelectors } from 'src/state/tableNode/slice'
import { useParameters } from 'src/hooks/common/useParameters'
import useDispatch from 'src/hooks/common/useDispatch'
import { findFirstDataNode } from 'src/hooks/common/useTree'
import { TreeNode } from 'src/typings/tree'
import { MapTableNode, TableNode as ProjectTableNode } from 'src/typings/mapTableNode'
import {
  isCoordinateFormula,
  isStringFormula,
  isNumberFormula
} from 'src/utils/field'

import { projectSelectors } from 'src/state/project/slice'
import { selectLayerNodes } from 'src/selectors/layerNodeSelector'

interface UseLegendTableNodeReturn {
  tableNodes?: TableNode[]
  columns?: Record<string, Column>
}

interface UseTableNodeReturn {
  tableNode?: TableNode
  columns?: Column[]
}

interface UseTableNodesInProjectReturn {
  tableNodes: ProjectTableNode[]
  tableNode?: ProjectTableNode
  getTableNode: (id: number) => ProjectTableNode | undefined
}

interface UseColumnReturn {
  column?: Column
}

interface UseTableNodesForLayerCreateReturn {
  tableNodes: ProjectTableNode[];
  coordinateOptions: TableNode['columns'];
  labelOptions: TableNode['columns'];
  setSelectedTableId: (id: number) => void;
}

export function useLegendTableNodes (): UseLegendTableNodeReturn {
  const tableNodes = useSelector(tableNodeSelectors.selectAll)
  const [columns, setColumns] = useState<Record<string, Column>>({})
  useEffect(() => {
    const currentColumns: Record<string, Column> = {}
    forEach(tableNodes, node => {
      forEach(node.columns, column => {
        currentColumns[column.id] = column
      })
    })

    setColumns(currentColumns)
  }, [tableNodes])

  return {
    tableNodes,
    columns
  }
}

export function useColumn (
  tableNodeId: number,
  columnId?: string
): UseColumnReturn {
  const column = useSelector(state => {
    const tableNode = tableNodeSelectors.selectById(state, tableNodeId)
    return find(tableNode?.columns, col => col.id === columnId)
  })

  return {
    column
  }
}

export function useTableNodesInProject (
  projectId: number,
  tableNodeId?: number
): UseTableNodesInProjectReturn {
  const tableNodes = useSelector(state => {
    return filter(projectSelectors.selectById(state, projectId)?.tableNodes, t => t.type === 'table')
  })

  const getTableNode = useCallback((id) => {
    return find(tableNodes, node => node.id === id)
  }, [tableNodes])

  return {
    tableNodes,
    tableNode: find(tableNodes, node => node.id === tableNodeId),
    getTableNode
  }
}

export function useTableNode (
  tableNodeId: number
): UseTableNodeReturn {
  const dispatch = useDispatch()
  const history = useHistory()
  const { workspaceType, workspaceId } = useParameters()
  const tableNode = useSelector(state => tableNodeSelectors.selectById(state, tableNodeId))

  useEffect(() => {
    if (!isNaN(tableNodeId)) {
      dispatch(getTableNodeDetails(tableNodeId)).catch(() => {
        history.replace(`/${workspaceType}/${workspaceId}`)
      })
    }
  }, [dispatch, tableNodeId, workspaceId, workspaceType, history])

  return {
    tableNode,
    columns: tableNode?.columns
  }
}

export function useTableNodesForMapView (projectId: number): void {
  const dispatch = useDispatch()
  const layers = useSelector(state => selectLayerNodes(state, projectId))

  useEffect(() => {
    const tableNodeIds = uniq(compact(map(layers, l => l.mapInfo?.tableNodeID)))
    if (tableNodeIds.length) {
      dispatch(getAllTableNodes(tableNodeIds))
    }
  }, [dispatch, layers])
}

export function useTableNodesForLayerCreate (projectId: number): UseTableNodesForLayerCreateReturn {
  const dispatch = useDispatch()
  const [tableNodes, setTableNodes] = useState<ProjectTableNode[]>([])

  const [selectedTableId, setSelectedTableId] = useState<number | null>(null)
  const tableNode = useSelector(state => {
    return tableNodeSelectors.selectById(state, selectedTableId || '')
  })

  useEffect(() => {
    if (!tableNode && selectedTableId) {
      dispatch(getTableNodeDetails(selectedTableId))
    }
  }, [dispatch, tableNode, selectedTableId])

  useEffect(() => {
    (async () => {
      const { payload } = await dispatch(getLayerTables(projectId))
      setTableNodes(get(payload, 'detail'))
    })()
  }, [dispatch, projectId])

  const coordinateOptions = useSelector(state => {
    const tableNode = tableNodeSelectors.selectById(state, selectedTableId || '')
    return filter(tableNode?.columns, c => {
      return c.type === 'coordinate' || isCoordinateFormula(c)
    })
  })

  const labelOptions = useSelector(state => {
    const tableNode = tableNodeSelectors.selectById(state, selectedTableId || '')
    return filter(tableNode?.columns, c => {
      const type = c.type
      return type === 'singleLineText'
        || type === 'number'
        || type === 'singleChoice'
        || isNumberFormula(c)
        || isStringFormula(c)
    })
  })

  return {
    tableNodes,
    coordinateOptions,
    labelOptions,
    setSelectedTableId
  }
}

export function useUpdateTableNodeIdEffect (treeData: TreeNode[], plainData?: MapTableNode[]): void {
  const { tableNodeId, setTableNodeId } = useParameters()

  useEffect(() => { 
    const firstNode = findFirstDataNode(treeData)
    const currentTableNodeIsNotExist = !tableNodeId || plainData?.findIndex(t => t.id === tableNodeId) === -1

    if (size(plainData) === 0 && tableNodeId) {
      setTableNodeId()
    } else if (currentTableNodeIsNotExist && firstNode) {
      setTableNodeId(firstNode.id)
    }
  }, [setTableNodeId, tableNodeId, treeData, plainData])
}
