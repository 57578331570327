import React from 'react'
import { Link, useHistory, useLocation, useRouteMatch } from 'react-router-dom'
import Icon from 'src/components/common/Icon'
import { useParameters, isOnPage } from 'src/hooks/common/useParameters'
import type { Project } from 'src/typings/project'
import Collaborator from 'src/components/project/Collaborator'
import SmallDivider from 'src/components/common/SmallDivider'
import DropdownMenu from 'src/components/common/DropdownMenu'
import Header from 'src/components/common/Header'
import { PROJECT_SETTING_MENU } from 'src/constants/menu'
import Status from 'src/components/common/Status'
import { ROLE_ENTITY } from 'src/typings/workspace'
import useDispatch from 'src/hooks/common/useDispatch'
import { deleteProject } from 'src/state/project/actions'
import EditProjectModal from 'src/components/workspace/EditProjectModal'
import { useDialogState, useDeleteConfirmDialog } from 'src/hooks/common/useDialog'
import DuplicationModal from 'src/components/common/DuplicationModal'
import styles from './projectHeader.module.scss'

interface Props {
  project?: Project;
}

const ProjectHeader: React.FC<Props> = (props) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const project = props.project
  const { pathname } = useLocation()
  const { path } = useRouteMatch()
  const isOnMapPage = isOnPage('map', pathname, path)
  const isOnComparePage = isOnPage('compare', pathname, path)
  const editProjectDialog = useDialogState()
  const duplicateProjectDialog = useDialogState()
  const deleteConfirmDialog = useDeleteConfirmDialog({
    messageIds: {
      title: 'project.delete.title',
      content: 'project.delete.content'
    }
  })

  const { workspaceId, projectId, workspaceType } = useParameters()

  const renderNavigateButton = () => (
    <>
      {isOnMapPage ? <div className={styles.navigateButton}>
        <Link to={`/${workspaceType}/${workspaceId}/p/${projectId}/t/`}>
          数据
        </Link> <SmallDivider className={styles.divider} />
        <span>
          地图
        </span>
      </div> : <div className={styles.navigateButton}>
        <span>
          数据
        </span> <SmallDivider className={styles.divider} />
        <Link to={`/${workspaceType}/${workspaceId}/p/${projectId}/map`}>
          地图
        </Link>
      </div>}
    </>
  )

  function handleMenuClick (action: string) {
    if (action === 'rename') {
      editProjectDialog.open()
    }
    if (action === 'cooperator') {
      history.push(`/${workspaceType}/${workspaceId}/p/${projectId}/member`)
    }
    if (action === 'delete') {
      deleteConfirmDialog({
        messageValues: {
          name: project?.name as string
        }
      }).then(() => {
        dispatch(deleteProject(projectId))
        history.replace(`/${workspaceType}/${workspaceId}`)
      })
    }
    if (action === 'duplicate') {
      duplicateProjectDialog.open()
    }
  }

  const renderHeaderLeft = () => (
    <>
      <Link to={`/${workspaceType}/${workspaceId}`} className={styles.backButton}>
        <Icon type={'arrowLeft'} />
      </Link>
      {
        isOnComparePage
          ? '数据对比'
          : project &&
          <div className={styles.projectName}>
            {project?.name}
            <DropdownMenu
              trigger={['hover']}
              actions={PROJECT_SETTING_MENU}
              roleData={{
                id: projectId,
                type: ROLE_ENTITY.project
              }}
              onClick={handleMenuClick}
            >
              <span>
                <Icon type="caretBottom" className={styles.icon} />
              </span>
            </DropdownMenu>
          </div>
      }
      <Status />
    </>
  )
  return (
    <>
      <Header
        className={styles.header}
        headerLeft={renderHeaderLeft()}
        headerCenter={!isOnComparePage ? renderNavigateButton() : undefined}
        headerRight={<Collaborator />}
      />
      <EditProjectModal dialog={editProjectDialog} initProject={project} />
      <DuplicationModal
        type="project"
        dialog={duplicateProjectDialog}
        duplicate={project}
      />
    </>
  )
}

export default ProjectHeader
