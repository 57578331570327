import React, { ComponentType, useEffect, useRef } from 'react'
import { createCollaboratorWS } from 'src/ws'
import { useParameters } from 'src/hooks/common/useParameters'
import { useInfiniteRenderDebug } from 'src/hooks/common/useDebug'

function withCollaboratorWebSocket (Comp: ComponentType): ComponentType {
  const ComponentWithCollaboratorWebSocket: ComponentType = (props) => {
    const { projectId } = useParameters()
    const wsClose = useRef<() => void>()

    useEffect(() => {
      if (projectId) {
        const { close } = createCollaboratorWS({
          projectID: projectId
        })

        wsClose.current = close
      }

      return () => {
        wsClose.current?.()
      }
    }, [projectId])

    useInfiniteRenderDebug('ComponentWithCollaboratorWebSocket')

    return <Comp {...props} />
  }

  return ComponentWithCollaboratorWebSocket
}

export default withCollaboratorWebSocket
