import React from 'react'
import { Modal, Button } from 'antd'
import { ModalProps } from 'antd/lib/modal'
import styles from './dialog.module.scss'

interface Props extends ModalProps {
  onCancel: (e: React.MouseEvent<HTMLElement>) => void;
  visible: boolean
}

const Dialog: React.FC<Props> = (props) => {
  return (
    <Modal
      {...props}
      visible={props.visible}
      closable={false}
      footer={null}
      className={styles.dialog}
      wrapClassName={styles.dialog}
    >
      <div className={styles.body}>
        {props.children}
      </div>
      {props.footer ? props.footer :
        <div className={styles.footer}>
          <Button size="large" type="primary" onClick={props.onOk} {...props.okButtonProps}>
            {props.okText || '确定'}
          </Button>
          <Button size="large" onClick={props.onCancel} {...props.cancelButtonProps}>
            {props.cancelText || '取消'}
          </Button>
        </div>}
    </Modal>
  )
}

export default Dialog
