import { createSlice } from '@reduxjs/toolkit'
import find from 'lodash/find'
import { getWorkspaces } from 'src/state/workspace/actions'
import { reducers, IsAuth } from 'src/state/user/reducers'
import { login, getCode, matchUser } from './actions'

export interface State {
  username?: string;
  userId?: number;
  isAuth: IsAuth
}

const initialState = {
  isAuth: false
} as State

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers,
  extraReducers (builder) {
    builder.addCase(getCode.rejected, (_, action) => {
      throw(action.error)
    })
    builder.addCase(login.fulfilled, (state, action) => {
      // TODO:
      state.isAuth = true
      state.username = 'test user'
    }).addCase(login.rejected, (state, action) => {
      throw(action.error)
    }).addCase(matchUser.rejected, (state, action) => {
      // FIXME: 使用正确的手机号，后端没有找到用户时，接口应该不返回 HTTP 400 
    })

    builder.addCase(getWorkspaces.fulfilled, (state, action) => {
      const user = find(action.payload, w => w.type === 'user')
      state.username = user?.name
      state.userId = user?.id
    })
  }
})

export default userSlice
export const actions = userSlice.actions