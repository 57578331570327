import { createSelector } from '@reduxjs/toolkit'
import type { RootState } from 'src/store'
import { Compare } from 'src/state/map/slice'

export const selectCompareList = createSelector<RootState, string | null, Compare, string | null, Compare['list']>(
  (state, _) => state.map.compare,
  (_, layerNodeId) => layerNodeId,
  (compare, layerNodeId) => {
    if (compare.layerNodeId === layerNodeId) return compare.list

    return undefined
  }
)