import { createSlice } from '@reduxjs/toolkit'
import { reducers } from 'src/state/org/reducers'
import * as orgActions from 'src/state/org/actions'

const initialState = {}

const orgSlice = createSlice({
  name: 'org',
  initialState,
  reducers,
  extraReducers (builder) {
    builder.addCase(orgActions.deleteMember.rejected, (_, action) => {
      throw(action.error)
    }).addCase(orgActions.updateMember.rejected, (_, action) => {
      throw(action.error)
    }).addCase(orgActions.inviteMember.rejected, (_, action) => {
      throw(action.error)
    }).addCase(orgActions.getInvitations.rejected, (_, action) => {
      throw(action.error)
    }).addCase(orgActions.deleteInvitation.rejected, (_, action) => {
      throw(action.error)
    })
  }
})

export default orgSlice

export const actions = orgSlice.actions
