import React, { useCallback, useState } from 'react'
import noop from 'lodash/noop'
import map from 'lodash/map'
import {
  MAX_FILTER_COUNT,
  Filter as FilterType
} from 'src/constants/filter'
import ActionContainer from 'src/components/project/ActionContainer'
import FilterItem from 'src/components/project/Filter/FilterItem'
import useFilter, { FiltersWithIdType } from 'src/hooks/project/useFilter'


interface FilterProps {
  onChange?: (filters: FilterType[]) => void;
  filtersWithId?: FiltersWithIdType
  tableNodeId?: number
  layerNodeId?: string
}

const Filter: React.FC<FilterProps> = (props) => {
  const { onChange = noop, tableNodeId, layerNodeId } = props
  const {
    filtersWithId,
    addFilter,
    removeFilter,
    changeFilter,
    clearFilters,
    columnsForFilter,
    filterOutInvalidFilter
  } = useFilter(props.filtersWithId, onChange, tableNodeId, layerNodeId)

  const [showFilterItem, setShowFilterItem] = useState(false)

  const handleFilterAdd = () => {
    if (filtersWithId?.filters?.length >= MAX_FILTER_COUNT) return

    addFilter()
  }
  const handleFilterRemove = (item: FilterType) => {
    removeFilter(item)
  }

  const handleFilterChange = useCallback((item: FilterType) => {
    changeFilter(item)
  }, [changeFilter])

  const handleFiltersClear = () => {
    clearFilters()
  }

  function handleFilterClose () {
    filterOutInvalidFilter()
    setShowFilterItem(false)
  }

  function handleFilterOpen () {
    setShowFilterItem(true)
  }

  return (
    <>
      <ActionContainer
        name={'筛选'}
        type={'filter'}
        count={filtersWithId?.filters.length}
        maxCount={MAX_FILTER_COUNT}
        onAdd={handleFilterAdd}
        onClear={handleFiltersClear}
        onClose={handleFilterClose}
        onOpen={handleFilterOpen}
      >
        {showFilterItem && map(filtersWithId?.filters, (filter: FilterType, idx: number) => {
          return (
            <FilterItem
              order={idx}
              filter={filter}
              key={filter.id}
              columnsForFilter={columnsForFilter}
              onRemove={handleFilterRemove}
              onChange={handleFilterChange}
            />
          )
        })}
      </ActionContainer>
    </>
  )
}

export default Filter
