export function isAndroid (): boolean {
  return navigator.userAgent.match(/Android/i) !== null
}

export function isIOS (): boolean{
  return navigator.userAgent.match(/iPhone|iPad|iPod/i) !== null
}

export function isMobile (): boolean {
  return isAndroid() || isIOS()
}

export function isMiniApp (): boolean {
  return navigator.userAgent.includes('miniProgram')
}

export function isProd (): boolean {
  return process.env.NODE_ENV === 'production'
}

export function isDev (): boolean {
  return process.env.NODE_ENV === 'development'
}
