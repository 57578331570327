/**
 * @property {string} antdPrefix - should cooperated with src/styles/antd-variables.less
 */
const config = {
  antdPrefix: 'mt',
  baseApiURL: process.env.REACT_APP_BASE_API_URL,
  DEV_PARAMS: {
    // eslint-disable-next-line camelcase
    cors_disable_token: process.env.REACT_APP_CORS_DISABLE_TOKEN
  }
}

export default config
