import React from 'react'
import cx from 'clsx'
import { TreeNode } from 'src/typings/tree'
import Icon from 'src/components/common/Icon'
import styles from './treeNodeIcon.module.scss'

const customIcon: React.ReactNode = ({ selected, type }: { selected: boolean, type: TreeNode['type'] }) => {
  if (type === 'group') {
    return <Icon
      className={cx(styles.normal, selected ? styles.active : '')}
      type="folder" />
  }
  if (type === 'table') {
    return <Icon
      className={cx(styles.normal, selected ? styles.active : '')}
      type="table" />
  }
  if (type === 'map') {
    return <Icon
      className={cx(styles.normal, selected ? styles.active : '')}
      type="layer" />
  }
}

export default customIcon
