import { useIntl } from 'react-intl'

interface UseMessagesReturn {
  label: string;
  tooltip: string;
}
export function useMessages (prefix: string): UseMessagesReturn {
  const intl = useIntl()

  return {
    label: intl.formatMessage({ id: `${prefix}.label` }),
    tooltip: intl.formatMessage({ id: `${prefix}.tooltip` })
  }
}