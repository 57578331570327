import React, { useState } from 'react'
import { useAsync } from 'react-use'
import { Button, message, Table, Modal } from 'antd'
import filter from 'lodash/filter'

import { useParameters } from 'src/hooks/common/useParameters'
import { ROLE_NAME, RoleType } from 'src/constants/permissions'
import * as OrgActions from 'src/state/org/actions'
import * as ProjectActions from 'src/state/project/actions'

import useDispatch from 'src/hooks/common/useDispatch'
import { Invitation } from 'src/api/invitations'
import { formatDate } from 'src/utils/moment'
import styles from './invitationsTable.module.scss'

const { Column } = Table

const InvitationsTable: React.FC<{ type: 'org' | 'project' }> = ({ type }) => {
  const dispatch = useDispatch()
  const [invitations, setInvitations] = useState<Invitation[]>([])
  const { workspaceId, projectId } = useParameters()
  const isOrg = type === 'org'
  const { deleteInvitation, getInvitations, resendInvitation } = (isOrg ? OrgActions : ProjectActions)
  const id = isOrg ? workspaceId : projectId

  const retrieveInvitations = async () => {
    const { payload } = await dispatch(getInvitations(id))

    setInvitations(payload as [])
  }

  useAsync(async () => {
    await retrieveInvitations()
  }, [dispatch, id])


  const handleDeleteInvitation = (invitationID: number) => {
    Modal.confirm({
      title: '取消邀请',
      content: <div>是否确认取消邀请？取消邀请后对方将无法通过邀请链接加入到{isOrg ? '团队' : '项目协作'}。</div>,
      okButtonProps: {
        danger: true
      },
      okText: '确认取消',
      async onOk () {
        const { payload } = await dispatch(deleteInvitation({
          id,
          invitationID
        }))

        if (payload) {
          setInvitations(prev => {
            return filter(prev, m => m.id !== invitationID)
          })
        }
      }
    })
  }

  const handleResendInvitation = async (invitationID: number) => {
    const { payload } = await dispatch(resendInvitation({
      id,
      invitationID
    }))

    if (payload) {
      message.success('邀请发送成功，请提醒成员查看短信')
    }
  }

  return (
    <Table
      dataSource={invitations}
      pagination={false}
      size="small"
      rowKey="id"
    >
      <Column title="手机号" dataIndex="phone" key="phone" />
      <Column
        title="权限"
        dataIndex="role"
        key="role"
        render={(role: RoleType) => {
          return ROLE_NAME[role] ?? ''
        }}
      />
      <Column
        title="邀请时间"
        dataIndex="createTime"
        key="createTime"
        render={(createTime: string) => {
          return formatDate(createTime)
        }}
      />
      <Column
        title="状态"
        dataIndex="status"
        key="status"
        render={() => {
          return '已发送邀请，等待用户加入'
        }}
      />
      <Column
        title="操作"
        render={(record) => {
          return (
            <div className={styles.actions}>
              <Button type="link" onClick={() => handleResendInvitation(record.id)}>重新发送</Button>
              <Button type="link" danger onClick={() => handleDeleteInvitation(record.id)}>取消邀请</Button>
            </div>
          )
        }} />
    </Table>
  )
}

export default InvitationsTable
