import React from 'react'
import cx from 'clsx'
import styles from './pageContainer.module.scss'

interface Props {
  title?: string | React.ReactNode
  actions?: React.ReactNode
  className?: string
}

const PageContainer: React.FC<Props> = ({ title, className, actions, children }) => {
  return (
    <div className={className}>
      <div className={styles.header}>
        <div className={styles.title}>{title}</div>
        <div className={styles.actions}>{actions}</div>
      </div>
      <div className={styles.content}>
        {children}
      </div>
    </div>
  )
}
interface TabsProps {
  currentTab: string
  tabs: {title: string, key: string}[]
  onTabClick?: (tabName: string) => void
}

export const PageContainerTabs: React.FC<TabsProps> = ({ currentTab, tabs, onTabClick }) => {

  return (
    <ul className={styles.tabs}>
      {tabs.map((tab,index) => {
        return (
          <li
            key={index}
            className={cx({ [styles.active]: currentTab === tab.key })}
            onClick={() => onTabClick?.(tab.key)}
          >
            {tab.title}
          </li>
        )
      })}
    </ul>
  )
}


export default PageContainer
