import { useHistory } from 'react-router'
import { useParameters } from 'src/hooks/common/useParameters'

import { PermissionPagesType, PermissionPages, ProjectPermission, TableNodePermisson } from 'src/constants/permissions'
import useSelector from 'src/hooks/common/useSelector'
import { useWorkspaceRoles } from './useWorkspaceRoles'

export default function usePagePermission (page: PermissionPagesType): void {
  const workspaceRoles = useSelector(state => state.workspace.workspaceRoles)
  const roleChanged = useSelector(state => state.workspace.workspaceRoleChanged)
  const { checkPermission, checkProjectPermission } = useWorkspaceRoles()
  const { projectId, workspaceType, tableNodeId, workspaceId } = useParameters()
  let accessible = true
  const history = useHistory()
  
  if(!roleChanged) {
    switch (page) {
    case PermissionPages.table:
      accessible = checkPermission(tableNodeId, TableNodePermisson.TablePage, 'table')
      if (workspaceRoles && tableNodeId && !accessible) {
        history.push(`/${workspaceType}/${workspaceId}`)
      }
      return
    case PermissionPages.map:
      accessible = checkProjectPermission(projectId, ProjectPermission.ProjectPage)
      if (workspaceRoles && projectId && !accessible) {
        history.push(`/${workspaceType}/${workspaceId}`)
      }
      return
    }
  }
}
